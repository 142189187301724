import React from "react";
import ConsolidatedReportView from "./ConsolidatedReportView";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { ConsolidatedMonthlyReportProjectwiseAction } from "../../../stores/actions/reportsAction";
import { ProjectwiseDetailsMonthlyReport } from "../../../models/reportsModel";
import { useForm } from "react-hook-form";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import { GetAllCustomerAction, GetAllProjectAction } from "../../../stores/actions/customerAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";

function ConsolidatedReport({
  ConsolidatedMonthlyReportProjectwiseAction,
  projectwise_details_monthly_report,
  project_list,
  GetAllProjectAction,
  GetAllCustomerAction,
  customer_list,
  SidenavAutoCloseOpen
}: ConsolidatedReportProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    GetAllCustomerAction();
    GetAllProjectAction({ organization_id: null, customer_id: null, all_flag: 1 });
    SidenavAutoCloseOpen(true)
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);

  const FilterSubmit = (data: any) => {
    ConsolidatedMonthlyReportProjectwiseAction(data);
  };
  // console.log(
  //   "projectwise_details_monthly_report",
  //   projectwise_details_monthly_report
  // );

  return (
    <ConsolidatedReportView
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      project_list={project_list}
      FilterSubmit={FilterSubmit}
      projectwise_details_monthly_report={projectwise_details_monthly_report}
      customer_list={customer_list}
      // GetAllProjectAction={GetAllProjectAction}
    />
  );
}
const mapStateToProps = (state: StoreState) => {
  return {
    projectwise_details_monthly_report:
      state.reports.projectwise_details_monthly_report,
    project_list: state.customer.project_list,
    customer_list: state.customer.customer_list,
  };
};

const mapDispatchToProps = {
  ConsolidatedMonthlyReportProjectwiseAction,
  GetAllProjectAction,
  GetAllCustomerAction,
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidatedReport);
interface ConsolidatedReportProps {
  ConsolidatedMonthlyReportProjectwiseAction?: any;
  projectwise_details_monthly_report?: ProjectwiseDetailsMonthlyReport;
  project_list: ProjectList[];
  GetAllProjectAction?: any;
  GetAllCustomerAction?: any;
  SidenavAutoCloseOpen?: any;
  customer_list: CustomerList[];
}
