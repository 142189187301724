import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Paper,
  styled,
  Table,
  TableBody,
  tableCellClasses,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  TableCell,
  Tooltip,
  TextField,
  Button,
  ButtonGroup,
  TableContainer,
  TablePagination,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CreateEmpOnMonth,
  CurrentMonthBirthday,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";

function DashboardMainView({
  employee_type_list,
  currentMonthBirthday,
  createEmpOnMonth,
  setselectedEmpType,
  selectedEmpType,
  user,
}: DashboardMainViewProps) {
  const styles = {
    marginRight: "50px",
  };
  const [page, setPage] = React.useState(0);
  // const [selectedEmpType, setselectedEmpType] = React.useState(Number);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<CurrentMonthBirthday[]>([]);
  const [rows2, setRows2] = useState<CreateEmpOnMonth[]>([]);

  const columns1: any[] = [
    "Employee Name",
    "Employee ID",
    "Mobile No.",
    "DOB",
    "Employee Type",
  ];
  const columns2: any[] = [
    "Employee Name",
    "Employee ID",
    "Mobile No.",
    "Joining Date",
    "Employee Type",
  ];
  const handleChangeRowsPerPage2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage2 = (event: unknown, newPage2: number) => {
    setPage2(newPage2);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!currentMonthBirthday
      ? currentMonthBirthday.filter((row: CurrentMonthBirthday) => {
          return row?.employee_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };
  const requestSearch2 = (searchedVal: string) => {
    const filteredRows = !!createEmpOnMonth
      ? createEmpOnMonth.filter((row: CreateEmpOnMonth) => {
          return row?.employee_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows2(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const cancelSearch2 = () => {
    setSearched("");
    requestSearch2("");
  };
  useEffect(() => {
    if (!!currentMonthBirthday) {
      cancelSearch();
    }
  }, [currentMonthBirthday]);
  useEffect(() => {
    if (!!createEmpOnMonth) {
      cancelSearch2();
    }
  }, [createEmpOnMonth]);
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };


  return (
    <Box sx={{ display: "flex", padding: 5 }}>
      <Container>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={8} lg={9}>
          </Grid>
          <Grid item xs={12} md={4} lg={3}
          >
          </Grid> */}
          <Grid item xs={12}>
            {!!user &&
              environment.global_veriable.officialEmployee.includes(
                user.designation
              ) && (
                <Grid container alignItems={"center"}>
                  <Grid item lg={5} xs={0} md={2}></Grid>
                  <Grid item lg={1} xs={3}>
                    <Typography
                      sx={{
                        color: "#132437",
                        fontWeight: 600,
                        fontFamily: "Alef, sans-serif",
                        fontSize: 20
                      }}
                    >
                      Filter 
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      // variant="contained"
                      aria-label="outlined primary button group"
                      style={{display: "flex", flexWrap: "wrap"}}
                    >
                      <Button
                        onClick={() => setselectedEmpType(null)}
                        style={
                          selectedEmpType === null || selectedEmpType === 0
                            ? {
                                backgroundColor: "rgb(19, 36, 55)",
                                color: "white",
                                border: "1px solid rgb(19, 36, 55)"
                              }
                            : {
                                color: "rgb(19, 36, 55)",
                                backgroundColor: "white",
                                border: "1px solid rgb(19, 36, 55)",
                              }
                        }
                      >
                        All
                      </Button>
                      {!!employee_type_list &&
                        employee_type_list.map((m) => (
                          <Button
                            key={m.employee_type_id}
                            onClick={() =>
                              setselectedEmpType(m.employee_type_id)
                            }
                            style={
                              selectedEmpType === m.employee_type_id
                                ? {
                                    backgroundColor: "rgb(19, 36, 55)",
                                    color: "white",
                                    border: "1px solid rgb(19, 36, 55)"
                                  }
                                : {
                                    color: "rgb(19, 36, 55)",
                                    backgroundColor: "white",
                                    border: "1px solid rgb(19, 36, 55)"
                                  }
                            }
                          >
                            {m.employee_type_name}
                          </Button>
                        ))}
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  margin: 1,
                }}
                elevation={5}
              >
                <Grid
                  container
                  justifyContent={"start"}
                  //alignItems={""}
                  direction={"row"}
                  paddingBottom={1}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        // fontWeight: "700",
                        // fontSize: 18,
                        // fontFamily: "Poppins, sans-serif",
                        // textAlign: "left",
                        // color: "#626974",
                        color: "#132437",
                        fontWeight: 600,
                        fontFamily: "Alef, sans-serif",
                        fontSize: 20
                        //mb: 10,
                      }}
                    >
                      Birthday Reminder
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer sx={{ borderRadius: 1 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    //   style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {columns1.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.employee_id}
                            >
                              <StyledTableCell align="left" key={columns1[0]}>
                                {row.employee_name}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[1]}>
                                {/* <Link
                                  onClick={() =>
                                    navigate(
                                      `/employment/manage-profile/${row.employee_id}`
                                    )
                                  }
                                > */}
                                {row.employee_id}
                                {/* </Link> */}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[2]}>
                                {row.employee_mob_no}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[3]}>
                                {moment(row.employee_dob).format("DD-MM-yyyy")}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[4]}>
                                {row.employee_type_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      {!!rows && rows.length <= 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5}>
                            <div style={{ color: "red", textAlign: "center" }}>
                              No Data Found{" "}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  style={styles}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  margin: 1,
                }}
                elevation={5}
              >
                <Grid
                  container
                  justifyContent={"start"}
                  //alignItems={""}
                  direction={"row"}
                  paddingBottom={1}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        // fontWeight: "700",
                        // fontSize: 18,
                        // fontFamily: "Poppins, sans-serif",
                        // textAlign: "left",
                        // color: "#626974",
                        color: "#132437",
                        fontWeight: 600,
                        fontFamily: "Alef, sans-serif",
                        fontSize: 20
                        //mb: 10,
                      }}
                    >
                      Work Anniversary Reminder
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer sx={{ borderRadius: 1 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    //   style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {columns2.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows2 &&
                        rows2
                          .slice(
                            page2 * rowsPerPage2,
                            page2 * rowsPerPage2 + rowsPerPage2
                          )
                          .map((row) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.employee_id}
                            >
                              <StyledTableCell align="left" key={columns1[0]}>
                                {row.employee_name}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[1]}>
                                {/* <Link
                                  onClick={() =>
                                    navigate(
                                      `/employment/manage-profile/${row.employee_id}`
                                    )
                                  }
                                > */}
                                {row.employee_id}
                                {/* </Link> */}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[2]}>
                                {row.employee_mob_no}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[3]}>
                                {moment(row.created_on).format("DD-MM-yyyy")}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={columns1[4]}>
                                {row.employee_type_name}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      {!!rows && rows.length <= 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={5}>
                            <div style={{ color: "red", textAlign: "center" }}>
                              No Data Found{" "}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  style={styles}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows2.length}
                  rowsPerPage={rowsPerPage2}
                  page={page2}
                  onPageChange={handleChangePage2}
                  onRowsPerPageChange={handleChangeRowsPerPage2}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default DashboardMainView;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface DashboardMainViewProps {
  employee_type_list: EmployeeTypeList[];
  currentMonthBirthday: CurrentMonthBirthday[];
  createEmpOnMonth: CreateEmpOnMonth[];
  setselectedEmpType?: any;
  selectedEmpType?: any;
  user?: userDetails;
}
