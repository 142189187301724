import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  DocumentsDetail,
  EmployeeAllDetail,
} from "../../../models/employeeModel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { baseUrl, environment } from "../../../environment";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";
import moment from "moment";
const FileDownload = require("js-file-download");

function EmployeeBasicDetailsView({
  employeeDetails,
  documents_details,
  user
}: EmployeeBasicDetailsViewProps) {
  console.log("documents_details : ", documents_details);
  const { enqueueSnackbar } = useSnackbar();
  const downloadDoc = (type: string) => {
    if (
      !!documents_details &&
      documents_details &&
      documents_details.find((m) => m.document_type === type)
    ) {
      let doc = documents_details.find(
        (m) => m.document_type === type
      );
      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: "NEW_CAD",
          fileName: doc?.document_image,
        },
      })
        .then((response) => {
          FileDownload(response.data, doc?.document_image);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };
  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
      >
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#DEFCF9",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Employee Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.employee_name}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Employee ID
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.employee_id}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Joining On
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {!!employeeDetails?.created_on ? moment.utc(employeeDetails?.created_on).format("DD-MM-yyyy") : "N/A"}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Father's Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.father_name}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Email ID
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {!!employeeDetails?.employee_email
                  ? employeeDetails?.employee_email
                  : "N/A"}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Mobile No.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.employee_mob_no}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.employee_sex}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Marital Status
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.employee_marital_status}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Blood Group
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {!!employeeDetails?.blood_group ? employeeDetails?.blood_group : "N/A"}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Religion
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.religion}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {moment(employeeDetails?.employee_dob).format("DD-MM-YYYY ").toUpperCase()}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Department
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {employeeDetails?.department_name}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Base Location
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                {!!employeeDetails?.base_location
                  ? employeeDetails.base_location
                  : "N/A"}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Aadhar No.
              </Typography>
            </Grid>
            <Grid container direction={"row"}>
              <Grid item xs={10} >
                <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                  {
                    documents_details.find((m) => m.document_type === "AADHAR")
                      ?.document_no
                  }
                </Typography>
              </Grid>
              {!!documents_details.find((m) => m.document_type === "AADHAR")?.document_image && (
                <Grid item xs={2} marginTop={-2}>
                  <Tooltip
                    title="Download Aadhar"
                    onClick={() => downloadDoc("AADHAR")}
                  >
                    <IconButton>
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                PAN No.
              </Typography>
            </Grid>
            <Grid container direction={"row"}>
              <Grid item xs={10}>
                <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                  {!!documents_details.find((m) => m.document_type === "PAN")
                    ?.document_no
                    ? documents_details.find((m) => m.document_type === "PAN")
                      ?.document_no
                    : "N/A"}
                </Typography>
              </Grid>
              {!!documents_details.find((m) => m.document_type === "PAN")?.document_image && (
                <Grid item xs={2} marginTop={-2}>
                  <Tooltip
                    title="Download PAN"
                    onClick={() => downloadDoc("PAN")}
                  >
                    <IconButton>
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          paddingTop={1}
          paddingBottom={2}
        >
          <Paper
            elevation={0}
            sx={{
              width: "90%",
              bgcolor: "#F1F6F5",
              pt: "1%",
              pb: "1%",
              borderRadius: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  pl: "5%",
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                EPIC No.
              </Typography>
            </Grid>
            <Grid container direction={"row"}>
              <Grid item xs={10}>
                <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                  {!!documents_details.find((m) => m.document_type === "VOTAR")
                    ?.document_no
                    ? documents_details.find((m) => m.document_type === "VOTAR")
                      ?.document_no
                    : "N/A"}
                </Typography>
              </Grid>
              {!!documents_details.find((m) => m.document_type === "VOTAR")?.document_image && (
                <Grid item xs={2} marginTop={-2}>
                  <Tooltip
                    title="Download EPIC"
                    onClick={() => downloadDoc("VOTAR")}
                  >
                    <IconButton>
                      <SaveAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Divider orientation="horizontal" variant="fullWidth" flexItem />
      </Grid>
    </>
  );
}

export default EmployeeBasicDetailsView;

interface EmployeeBasicDetailsViewProps {
  employeeDetails?: EmployeeAllDetail;
  documents_details: DocumentsDetail[];
  user?: userDetails;
}
