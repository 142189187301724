import React, {
  FC,
  useState,
  useRef,
  useEffect,
  forwardRef,
  RefAttributes,
  ForwardRefExoticComponent,
} from "react";

import colorGenerator from "../../../utility/colorGenerator";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Paper,
  styled,
  Table,
  TableBody,
  tableCellClasses,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  TableCell,
  Tooltip,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// import {
//   BarChart,
//   Bar,
//   Cell,
//   XAxis,
//   PieChart,
//   PieLabel,
//   YAxis,
//   CartesianGrid,
//   Legend,
//   ResponsiveContainer,
//   Pie,
// } from "recharts";
import { Carousel } from "react-responsive-carousel";
import Chart from "react-apexcharts";
import DivisionCardCaraousel from "./DivisionCardCaraousel";
import LevelHierachy from "./LevelHierachy";
import {
  IEmpDetails,
  IProjectDashboardDetails,
} from "./../../../interfaces/projectDashboard.interface";
import ProjectDetails from "./ProjectDetails";
import EmployeeList from "./EmployeeList";
import { attendenceData } from "../../../assets/tempData";
import ManagerList from "./ManagerList";
import MonthWiseAttendenceGraph from "./MonthWiseAttendenceGraph";
import PostList from "./PostList";
import dayjs, { Dayjs } from "dayjs";
import {
  AttendenceStatus,
  AvailableEmpList,
  IBatchDetails,
} from "../../../models/customerModel";
import DesignationList from "./DesignationList";
import { IEmployeePayroll } from "./../../../models/customerModel";
import BatchDetails from "./BatchDetails";
import EmployeePayroll from "./EmployeePayroll";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
import ProjectDocumentsModal from "./ProjectDocumentsModal";

type Ref = HTMLDivElement | null;

const ProjectDashboardView: ForwardRefExoticComponent<
  IProjectDashboardView & RefAttributes<Ref>
> = forwardRef(
  (
    {
      setCurrentYear,
      projectDashbaordData,
      year,
      dateForAttendence,
      attendenceData,
      setDateForAttendence,
      setCurrentMonthForBatch,
      setCurrentMonthForEmpPayroll,
      dashboardBatchDetails,
      employeePayrollDetails,
      currentMonthForBatch,
      currentMonthForEmpPayroll,
      editAssociationStatus,
      projectId,
      setBatchCount,
      batchCount,
      Setdocument,
      document,
      downloadImage,
      user,
      accessToken,
      setSearchedEmp,
      searchedEmp,
      available_emp_list,
      Submit,
      onDownloadPDF,
    },
    ref
  ) => {
    const [docsDialogOpen, setDocsDialogOpen] = useState<boolean>(false);

    // useEffect(() => {
    //   if (
    //     !!projectDashbaordData &&
    //     projectDashbaordData?.projectDetails.document &&
    //     projectDashbaordData?.projectDetails.document.document_image
    //   ) {
    //     Setdocument(
    //       projectDashbaordData.projectDetails.document.document_image
    //     );
    //   }
    // }, [projectDashbaordData]);
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#708238", "#FF8042"];
    const [tabValue, setTabValue] = useState<string>("1");
    let renderLabel = function (entry: any) {
      return entry.name + " (" + entry.value + ")";
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };
    console.log("month : ", dayjs(dateForAttendence).format("DD-MM-YYYY"));

    const options2: any = {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Active Employees", "In-Active Employees"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    };
    const options3: any = {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: !!attendenceData
        ? attendenceData.map((self) => self.attendence_status)
        : [],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    };
    if (projectDashbaordData)
      console.log(
        "projectDashbaordData.projectDetails.attendenceData.monthWiseData : ",
        projectDashbaordData?.attendenceData?.monthWiseData
      );
    const handleChange = (newValue: Dayjs | null) => {
      setDateForAttendence(newValue);
      console.log(dayjs(newValue).format("DD-MM-YYYY"));
    };
    const handleCurrentYearForDashboard = (newValue: Dayjs | null) => {
      setCurrentYear(newValue);
      setCurrentMonthForBatch(newValue);
      setCurrentMonthForEmpPayroll(newValue);
      setDateForAttendence(newValue);
      console.log("current year", dayjs(newValue).format("DD-MM-YYYY"));
    };
    const calendarRef: any = useRef();
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box pl={4} display="flex" alignItems="center">
          <ProjectDocumentsModal
            documents={projectDashbaordData?.projectDetails?.document || []}
            isOpen={docsDialogOpen}
            setIsOpen={setDocsDialogOpen}
            downloadDocument={downloadImage}
            document={document}
          />
          <Box display="flex" alignItems="center" px={2} mt={1} width="22rem">
            <Typography
              sx={{ color: "#A2A2A2", fontSize: "16", mr: 2 }}
              variant="body2"
            >
              Select Year
            </Typography>
            {!!projectDashbaordData?.projectDetails ? (
              <DesktopDatePicker
                inputFormat="YYYY"
                InputProps={{
                  sx: { width: "8rem", outline: "none", border: "none" },
                }}
                views={["year"]}
                maxDate={dayjs(new Date())}
                value={year}
                onChange={handleCurrentYearForDashboard}
                renderInput={(params) => <TextField {...params} />}
              />
            ) : (
              <></>
            )}
          </Box>
          <Box color="" width="50%">
            <Typography
              textAlign="center"
              color="#132437"
              variant="h5"
              fontFamily={"Alef, sans-serif"}
              fontWeight={"600"}
            >
              Project Details of{" "}
              {projectDashbaordData?.projectDetails.project_name} for{" "}
              {dayjs(year).format("DD-MM-YYYY").split("-")[2]}
            </Typography>
          </Box>
        </Box>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, pl: 4 }} ref={ref}>
          <ProjectDetails
            details={projectDashbaordData?.projectDetails}
            payrollData={projectDashbaordData?.payrollData}
            downloadImage={downloadImage}
            onDownloadPDF={onDownloadPDF}
            setDocsDialogOpen={setDocsDialogOpen}
            user={user}
          />

          <Grid
            container
            justifyContent="space-between"
            mt={2}
            spacing={2}
            sx={{ height: "auto" }}
          >
            <Grid item xs={12} maxHeight="auto" sx={{ height: "auto" }}>
              <LevelHierachy
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                levelDetails={projectDashbaordData?.levels ?? []}
                levelMaster={projectDashbaordData?.levelMaster}
              />
            </Grid>
            {/* {!!projectDashbaordData &&
          projectDashbaordData.projectDetails?.attendenceData
            ?.totalPresentDays &&
          projectDashbaordData.projectDetails?.attendenceData
            ?.totalAbsentDays ? ( */}
          </Grid>
          <Grid container spacing={2} justifyContent="space-between" mt={2}>
            <Grid item xs={12} sm={12}>
              {!!projectDashbaordData?.projectDetails ? (
                <EmployeeList
                  empList={projectDashbaordData.projectDetails.employeeList}
                  managerList={
                    projectDashbaordData.projectDetails.projectmanager_id
                  }
                  allEmp={projectDashbaordData.projectDetails.employeeList}
                  posts={
                    projectDashbaordData.projectDetails.applicable_post_details
                  }
                  editAssociationStatus={editAssociationStatus}
                  projectId={Number(projectId)}
                  year={year}
                  setSearchedEmp={setSearchedEmp}
                  searchedEmp={searchedEmp}
                  available_emp_list={available_emp_list}
                  levelDetails={projectDashbaordData?.levels ?? []}
                  levelMaster={projectDashbaordData?.levelMaster}
                  projectDashbaordData={projectDashbaordData}
                  Submit={Submit}
                  user={user}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container display="flex" justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={12}
              md={3.8}
              lg={3.8}
              display="flex"
              flexDirection={"column"}
              alignItems="center"
            >
              <Typography textAlign="center" color="#0060FF">
                Post Details
              </Typography>
              {!!projectDashbaordData &&
              !!projectDashbaordData.projectDetails.applicable_post_details &&
              Array.isArray(
                projectDashbaordData.projectDetails.applicable_post_details
              ) &&
              projectDashbaordData.projectDetails.applicable_post_details
                .length > 0 ? (
                projectDashbaordData.projectDetails.applicable_post_details.map(
                  (self, index: number) => (
                    <PostList
                      postDetail={self}
                      key={index}
                      noOfEmployee={
                        !!projectDashbaordData.postCount
                          ? projectDashbaordData.postCount
                          : undefined
                      }
                    />
                  )
                )
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} md={3.8} lg={3.8}>
              <Typography variant="body1" textAlign={"center"} color="#0060FF">
                Manager List
              </Typography>
              <div
                className="managerWrapper"
                style={{
                  overflow: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!!projectDashbaordData &&
                Array.isArray(projectDashbaordData?.managerList) &&
                projectDashbaordData?.managerList.length > 0 ? (
                  projectDashbaordData.managerList.map(
                    (manager: IEmpDetails, index: number) => (
                      <ManagerList manager={manager} key={index} user={user} />
                    )
                  )
                ) : (
                  <Typography variant="body2" textAlign="center">
                    Manager Not Exists
                  </Typography>
                )}
              </div>
            </Grid>
            {!!projectDashbaordData ? (
              <Grid item xs={12} md={3.8} lg={3.8}>
                <Typography textAlign="center" color="#0060FF">
                  Designation Details
                </Typography>
                <DesignationList
                  designationList={
                    projectDashbaordData.projectDetails
                      .applicable_designation_details
                  }
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          {(!!projectDashbaordData &&
            projectDashbaordData.attendenceData?.monthWiseData.length > 0) ||
          (!!attendenceData && attendenceData.length > 0) ? (
            <Grid container spacing={2} justifyContent="space-between" mt={2}>
              {!!projectDashbaordData &&
              !!projectDashbaordData.attendenceData?.monthWiseData &&
              Array.isArray(
                projectDashbaordData.attendenceData.monthWiseData
              ) &&
              projectDashbaordData.attendenceData.monthWiseData.length > 0 ? (
                <Grid item sm={12} md={5.9} lg={5.9} xl={5.9}>
                  <MonthWiseAttendenceGraph
                    monthWiseAttendence={
                      projectDashbaordData.attendenceData.monthWiseData
                    }
                    year={year}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid
                item
                sm={12}
                md={5.9}

                // md={
                //   projectDashbaordData.attendenceData.monthWiseData.length === 0
                //     ? 12
                //     : projectDashbaordData.attendenceData.monthWiseData.length < 5
                //     ? 4
                //     : 12
                // }
                // lg={
                //   projectDashbaordData.attendenceData.monthWiseData.length === 0
                //     ? 12
                //     : projectDashbaordData.attendenceData.monthWiseData.length < 5
                //     ? 4
                //     : 12
                // }
                // xl={
                //   projectDashbaordData.attendenceData.monthWiseData.length === 0
                //     ? 12
                //     : projectDashbaordData.attendenceData.monthWiseData.length < 5
                //     ? 4
                //     : 12
                // }
              >
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: 380,
                    alignItems: "center",
                    paddingBottom: 4,
                  }}
                >
                  <Box width="100%">
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={2}
                      width="100%"
                      py={1}
                    >
                      <Box width="60%" display="flex" justifyContent="flex-end">
                        <Typography
                          variant="body1"
                          textAlign={"center"}
                          color="#0060FF"
                          mt={2}
                          mb={3}
                        >
                          Attedance Status ,{" "}
                          {dayjs(dateForAttendence).format("MMM-YYYY")}
                        </Typography>
                      </Box>
                      <Box width="39%" display="flex" justifyContent="flex-end">
                        <DesktopDatePicker
                          ref={calendarRef}
                          inputFormat="MMM"
                          InputProps={{
                            sx: {
                              width: "10rem",
                              outline: "none",
                              border: "none",
                              ml: 3,
                            },
                          }}
                          views={["month"]}
                          maxDate={dayjs(new Date())}
                          value={dateForAttendence}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {!!attendenceData && attendenceData.length > 0 ? (
                    <Chart
                      options={options3}
                      height={300}
                      // series={[
                      //   projectDashbaordData.projectDetails.attendenceData
                      //     .totalPresentDays,
                      //   projectDashbaordData.projectDetails.attendenceData
                      //     .totalAbsentDays,
                      // ]}
                      series={attendenceData.map((self) => self.status_count)}
                      type="pie"
                      width={300}
                    />
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                      color="text.secondary"
                    >
                      <Typography textAlign="center" variant="body2">
                        Attendence data not exists
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {user?.designation &&
          environment.global_veriable.superAdminLevel.includes(
            user.designation
          ) ? (
            <Grid container spacing={2} justifyContent="space-between" mt={2}>
              <BatchDetails
                dashboardBatchDetails={dashboardBatchDetails}
                currentMonthForBatch={currentMonthForBatch}
                setCurrentMonthForBatch={setCurrentMonthForBatch}
                setBatchCount={setBatchCount}
                batchCount={batchCount}
                accessToken={accessToken}
                user={user}
              />

              <EmployeePayroll
                empPayroll={employeePayrollDetails}
                currentMonthForEmpPayroll={currentMonthForEmpPayroll}
                setCurrentMonthForEmpPayroll={setCurrentMonthForEmpPayroll}
                accessToken={accessToken}
                user={user}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Container>
      </Box>
    );
  }
);

interface IProjectDashboardView {
  setCurrentYear: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
  projectDashbaordData?: IProjectDashboardDetails;
  year: dayjs.Dayjs | null;
  dateForAttendence: dayjs.Dayjs | null;
  setDateForAttendence: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
  attendenceData?: AttendenceStatus[];
  setCurrentMonthForBatch: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
  setCurrentMonthForEmpPayroll: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
  currentMonthForBatch: dayjs.Dayjs | null;
  currentMonthForEmpPayroll: dayjs.Dayjs | null;
  dashboardBatchDetails: IBatchDetails[];
  employeePayrollDetails: IEmployeePayroll[];
  editAssociationStatus?: any;
  projectId: number;
  setBatchCount: React.Dispatch<React.SetStateAction<number>>;
  batchCount: number;
  Setdocument: any;
  document: any;
  downloadImage: any;
  user?: userDetails;
  accessToken?: string;
  setSearchedEmp?: any;
  searchedEmp?: any;
  available_emp_list: AvailableEmpList[];
  Submit: any;
  onDownloadPDF: any;
}

export default ProjectDashboardView;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    // color: "#ffff",
    // fontWeight: 600,
    // fontSize: 14,
    // padding: "5px",
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: { md: 14, sm: 12, xs: 8 },
    // padding: "5px",
    // color: "#363A44",
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

function createData(
  id: number,
  project_name: string,
  division_name: string,
  no_of_emp: number,
  total_salary: string,
  total_salary_til_date: string
) {
  return {
    id,
    project_name,
    division_name,
    no_of_emp,
    total_salary,
    total_salary_til_date,
  };
}

const rows = [
  createData(0, "Meter Reader", "Bankura", 52, "5,20,000", "15,60,000"),
  createData(1, "Meter Reader", "N 24 Parganas", 40, "8,20,000", "24,60,000"),
];
