import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ViewProject } from "../../../models/customerModel";

const AdditionalFields = ({
  AddNewAdditionalTypes,
  fieldsMaster,
  DeleteMaster,
  editField,
  editFlags,
  project,
}: AdditionalFieldProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [editIndex, setEditIndex] = useState<number | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    Settype("");
    Setfieldname(null);
  };
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const AddAdditionalFields = (data: any) => {
    if (!fieldname) {
      return enqueueSnackbar(`Field Name is required`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    if (!type) {
      return enqueueSnackbar(`Field Type is required`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    // console.log({ addTypeOptionFields });
    // console.log(addTypeOptionFields.filter((self) => self));
    if (
      type === "Option" &&
      addTypeOptionFields.length > 0 &&
      addTypeOptionFields.filter((self) => self.trim() === "").length > 0
    ) {
      return enqueueSnackbar(`every option  is required`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }

    if (isEdit && !!fieldsMaster && Number(editIndex) >= 0) {
      const existingData = fieldsMaster.filter(
        (self, index) => index !== editIndex
      );
      editField([
        ...existingData,
        {
          field_name: fieldname,
          type: type,
          option: addTypeOptionFields,
          isMandatory: !!checked && checked == true ? 1 : 0,
          uploadRequired: !!checked2 && checked2 == true ? 1 : 0,
        },
      ]);
    } else {
      AddNewAdditionalTypes({
        field_name: fieldname,
        type: type,
        option: addTypeOptionFields,
        isMandatory: !!checked && checked == true ? 1 : 0,
        uploadRequired: !!checked2 && checked2 == true ? 1 : 0,
      });
    }
    setEditIndex(-100);
    setIsEdit(false);
    Setfieldname(null);
    setaddOptionTypesFields([]);
    handleClose();
  };
  const typeName: any[] = [
    {
      key: "Number",
      value: "Number",
    },
    {
      key: "String",
      value: "String",
    },
    {
      key: "Option",
      value: "Option",
    },
  ];
  const [fieldname, Setfieldname] = useState<string | null>();
  const [type, Settype] = useState<string>();
  const [addTypeOptionFields, setaddOptionTypesFields] = useState<string[]>([]);

  const addTypes = () => {
    setaddOptionTypesFields([...addTypeOptionFields, ""]);
  };
  const OnChangeType = (value: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addTypeOptionFields));
    Temp[index] = value;
    setaddOptionTypesFields(Temp);
  };
  const DeleteTypes = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addTypeOptionFields));
    setaddOptionTypesFields(Temp.filter((m: any, ind: number) => index != ind));
  };
  const editOptionalFields = (
    event: React.MouseEvent<HTMLButtonElement>,
    fieldIndex: number
  ): void => {
    // console.log({ fieldIndex, fieldsMaster });
    if (!((editFlags && !project) || (!editFlags && !!project)) ) return;
    setAnchorEl(event.currentTarget);
    // console.log(fieldsMaster?.length);
    setIsEdit(true);
    setEditIndex(fieldIndex);
    if (Array.isArray(fieldsMaster) && fieldsMaster.length >= fieldIndex) {
      // console.log({ fieldIndex });

      Settype(fieldsMaster[fieldIndex].type);
      Setfieldname(fieldsMaster[fieldIndex].field_name);
      if (
        fieldsMaster[fieldIndex]?.option &&
        Array.isArray(fieldsMaster[fieldIndex].option)
      ) {
        setaddOptionTypesFields(fieldsMaster[fieldIndex]?.option);
      }
      if(fieldsMaster[fieldIndex].isMandatory){
        setChecked(true)
      }else{
        setChecked(false)
      }
      if(fieldsMaster[fieldIndex].uploadRequired){
        setChecked2(true)
      }else{
        setChecked2(false)
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="flex-start" my={1}>
            {!!fieldsMaster &&
              fieldsMaster?.map((m, index) => (
                <Box sx={{ position: "relative" }}>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (!((editFlags && !project) || (!editFlags && !!project) )) return;
                      editOptionalFields(event, index);
                    }}
                    sx={{ marginX: 1 }}
                  >
                    {m.field_name}
                  </Button>
                  {(editFlags && !project) || (!editFlags && !!project) ? (
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "-24%",
                        right: "-1%",
                        color: "red",
                      }}
                    >
                      <HighlightOffIcon
                        sx={{ fontSize: 16 }}
                        onClick={(e: any) => {
                          if (!((editFlags && !project) || (!editFlags && !!project)) ) return;
                          DeleteMaster(index);
                        }}
                      />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
              ))}
          </Grid>
        </Grid>
        {((editFlags && !project) || (!editFlags && !!project)) && (
          <Button
            size="medium"
            variant="outlined"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleClick}
            sx={{ marginX: 1 }}
          >
            Add
          </Button>
        )}
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>
              Additional Field Name{" "}
            </Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid container justifyContent="start" alignItems="center">
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                  }}
                >
                  Field Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={fieldname}
                  onChange={(event) => Setfieldname(event.target.value)}
                  type={"text"}
                  style={{ width: "100%" }}
                  className={"smaller"}
                  size="small"
                  placeholder={"Field Name"}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                  }}
                >
                  Type
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocompletes
                  hasInfo={false}
                  option={typeName}
                  keys={"key"}
                  label={"value"}
                  placeholder={"Type"}
                  value={type}
                  onChange={(newValue: string) => (
                    Settype(newValue),
                    newValue == "Option" && setaddOptionTypesFields([""])
                  )}
                />
              </Grid>
            </Grid>
            {!!type && type == "Option"
              ? !!addTypeOptionFields &&
                addTypeOptionFields.map((m: any, index: number) => (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={2}
                    key={index}
                  >
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                        }}
                      >
                        Option {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "115%" }}
                        size="small"
                        onChange={(event) =>
                          OnChangeType(event.target.value, index)
                        }
                        value={m}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      {addTypeOptionFields.length == index + 1 && (
                        <Tooltip title="Add" onClick={() => addTypes()}>
                          <IconButton color="primary" size="small">
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      {addTypeOptionFields.length > 1 && (
                        <Tooltip
                          title="Delete"
                          onClick={() => DeleteTypes(index)}
                        >
                          <IconButton color="error" size="small">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                ))
              : ""}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Checkbox
                // disabled={!!organization ? edit_flag : !edit_flag}
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography style={{ color: "#A2A2A2" }}>
                Is this field mandatory?
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Checkbox
                // disabled={!!organization ? edit_flag : !edit_flag}
                checked={checked2}
                onChange={(event) => setChecked2(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography style={{ color: "#A2A2A2" }}>
                File Upload Required?
              </Typography>
            </Grid>
            {((editFlags && !project) || (!editFlags && !!project)) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end ",
                  //   marginRight: "2rem",
                  marginRight: "0px",
                  marginTop: "13px",
                  marginBottom: 1,
                }}
              >
                <Button
                  size="small"
                  sx={{
                    marginLeft: 1,
                  }}
                  variant="outlined"
                  onClick={AddAdditionalFields}
                >
                  Add
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default AdditionalFields;

interface AdditionalFieldProps {
  AddNewAdditionalTypes?: any;
  fieldsMaster?: any[];
  DeleteMaster?: any;
  editField: any;
  editFlags: boolean;
  project?: ViewProject;
}
