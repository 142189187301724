import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { connect } from "react-redux";
import { AllLeaveList, Datum, ILeaveType } from "../../../models/leaveModel";
import { OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllLeaveListAction,
  GetAllLeaveTypeAction,
} from "../../../stores/actions/LeaveAction";
import { GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import AllLeaveView from "./AllLeaveView";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";

function AllLeave({
  GetAllOrganizationAction,
  organization_list,
  leave_types,
  all_leave_list,
  GetAllLeaveListAction,
  GetAllLeaveTypeAction,
  total_count,
  searchByNameEmpAction,
  employeeList,
}: AllLeaveProps) {
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllLeaveTypeAction();
  }, []);
  React.useEffect(() => {
    GetAllLeaveListAction({
      page_index: pageindex,
      page_size: pagesize,
      employee_id: null,
      leave_type_id: null,
      leave_status: null,
      organization_id: null,
    });
  }, [pageindex, pagesize]);
  const Submit = (data: any) => {
    GetAllLeaveListAction({
      page_index: pageindex,
      page_size: pagesize,
      ...data,
    });
  };
  const onClear = () => {
    GetAllLeaveListAction({
      page_index: pageindex,
      page_size: pagesize,
      employee_id: null,
      leave_type_id: null,
      leave_status: null,
      organization_id: null,
    });
  };
  //   console.log("total_count", total_count, all_leave_list);

  return (
    <AllLeaveView
      organization_list={organization_list}
      leave_types={leave_types}
      Submit={Submit}
      all_leave_list={all_leave_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      onClear={onClear}
      total_count={total_count}
      searchByNameEmpAction={searchByNameEmpAction}
      employeeList={employeeList}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    leave_types: state.leave.leave_types,
    all_leave_list: state.leave.all_leave_list,
    total_count: state.leave.total_count,
    employeeList: state.timingSheet.employeeList,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllLeaveTypeAction,
  GetAllLeaveListAction,
  searchByNameEmpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllLeave);

interface AllLeaveProps {
  GetAllOrganizationAction?: any;
  organization_list?: OrganizationALlList[];
  leave_types: ILeaveType[];
  all_leave_list?: Datum[];
  GetAllLeaveListAction?: any;
  GetAllLeaveTypeAction?: any;
  //   pagesize: number;
  //   pageindex: number;
  //   rowperpage: number[];
  total_count?: number;
  employeeList: IEmployeeList[];
  searchByNameEmpAction: any;
}
