import { baseUrl, environment } from "../environment";
import {
  AvailableEmployeePayload,
  GeneratePayBatch,
  GetBatchListPayload,
} from "../models/payrollModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const PayrollAvailableEmployee = (data: AvailableEmployeePayload) => {
  return serviceClient.post(environment.urls.payrollAvalibleEmployeeUrl, data);
};
export const GeneratePayRollBatch = (data: GeneratePayBatch) => {
  return serviceClient.post(environment.urls.generatePayrollBatchUrl, data);
};
export const GetBatchList = (data: GetBatchListPayload) => {
  return serviceClient.post(environment.urls.batchListUrl, data);
};
export const PayBatchDetail = (data: string) => {
  return serviceClient.post(environment.urls.PaymentBatchDetailUrl, {
    batch_id: data,
  });
};
export const GetCalcualculatedSalaryComponenet = (data: any) => {
  return serviceClient.post(environment.urls.GetSalaryCalculatedComponet, data);
};
export const DiscardEmployeeFromBatch = (data: any) => {
  return serviceClient.post(environment.urls.releaseBatchUrl, data);
};
export const PayrollComponenetUpdate = (data: any) => {
  return serviceClient.post(environment.urls.UpdatePayrollComponent, data);
};
export const batchUpdateService = (data: any) => {
  return serviceClient.post(environment.urls.BatchUpdateUrl, data);
};

export const BatchByDeg = (data: any) => {
  return serviceClient.post(environment.urls.BatchByDegUrl, data);
};
