import {
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { watch } from "fs";
import React from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import ImageUploader from "./ImageUploader";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddressDetails from "./AddressDetails";
import BankingDetail from "./BankingDetail";
import BasicDetails from "./BasicDetails";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";
import { ProjectLevels, StateList, ViewProject } from "../../../models/customerModel";
import {
  bankList,
  ReportingManagerList,
  ViewEmployee,
} from "../../../models/employeeModel";
import {
  SalaryStructureList,
  SalaryStructureEditDetails,
  DomainList,
} from "../../../models/organizationModels";
import PfDetails from "./PfDetails";
import OrganizationalDetails from "./OrganizationalDetails";
import { userDetails } from "../../../models/userModels";
import ProfessionalQualification from "./ProfessionalQualification";
function FormFields({
  applicabledeg,
  control,
  errors,
  handleSubmit,
  onSubmit,
  designation_list,
  documents,
  watch,
  fileHandleEvent,
  addEduDetail,
  post_list,
  project_list,
  department_list,
  addExpDetail,
  customer_list,
  employee_type_list,
  employee_timing_list,
  organization_list,
  OrganizationSelected,
  ReportingManagerList,
  attendence_salary_type,
  salary_structure_list,
  OnChangeCustomer,
  OnprojectSelect,
  SetDocument,
  SlaryStructureChange,
  project_detail,
  salary_structure,
  setaddEduDetail,
  setaddExpDetail,
  handleClickOpen,
  getValues,
  setValue,
  bank_list,
  project_levels,
  user,
  state_list,
  addProfessionalQualification,
  setAddProfessionalQualification,
  AddlfileHandleEvent,
  downloadImage,
}: fromFieldsProps) {
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item style={{ marginInline: 0, width: "100%" }}>
          {/* <Grid
            container
            justifyContent="end"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 30, marginLeft: "-4%" }}
          >
            <Button
              variant="outlined"
              component="label"
              color="primary"
              // onClick={handleClose}
              onClick={handleClickOpen}
              startIcon={<CloudUploadIcon />}
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Bulk Upload
              </Typography>
            </Button>
          </Grid> */}
          {/* )} */}
          <BasicDetails
            documents={documents}
            control={control}
            errors={errors}
            fileHandleEvent={fileHandleEvent}
            SetDocument={SetDocument}
          />

          <AddressDetails
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            state_list={state_list}
          />

          <PfDetails control={control} errors={errors} />
          <BankingDetail
            control={control}
            errors={errors}
            bank_list={bank_list}
          />
          <EducationDetails
            addEduDetail={addEduDetail}
            setaddEduDetail={setaddEduDetail}
          />
          <ProfessionalQualification
            addProfessionalQualification={addProfessionalQualification}
            setAddProfessionalQualification={setAddProfessionalQualification}
          />
          <ExperienceDetails
            addExpDetail={addExpDetail}
            setaddExpDetail={setaddExpDetail}
          />
          {/* <ExpectedCtc control={control} errors={errors} /> */}
          <OrganizationalDetails
            ReportingManagerList={ReportingManagerList}
            applicabledeg={applicabledeg}
            department_list={department_list}
            designation_list={designation_list}
            employee_timing_list={employee_timing_list}
            employee_type_list={employee_type_list}
            organization_list={organization_list}
            salary_structure_list={salary_structure_list}
            OrganizationSelected={OrganizationSelected}
            control={control}
            errors={errors}
            watch={watch}
            customer_list={customer_list}
            OnChangeCustomer={OnChangeCustomer}
            project_list={project_list}
            OnprojectSelect={OnprojectSelect}
            project_detail={project_detail}
            post_list={post_list}
            SlaryStructureChange={SlaryStructureChange}
            salary_structure={salary_structure}
            attendence_salary_type={attendence_salary_type}
            project_levels={project_levels}
            user={user}
            setValue={setValue}
            state_list={state_list}
            AddlfileHandleEvent={AddlfileHandleEvent}
            documents={documents}
            SetDocument={SetDocument}
            downloadImage={downloadImage}
            getValues={getValues}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        {/* {((edit_flag && !department) || (!edit_flag && !!department)) && ( */}
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#1565C0",
            paddingX: 4,
            marginRight: 10,
          }}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          size="large"
        >
          Submit Application
        </Button>
      </Grid>
    </>
  );
}

export default FormFields;

interface fromFieldsProps {
  handleSubmit?: any;
  onSubmit?: any;
  errors?: any;
  control?: any;
  applicabledeg?: any;
  documents: any[];
  designation_list: any[];
  watch?: any;
  fileHandleEvent?: any;
  addEduDetail?: any;
  post_list: any[];
  project_list: any[];
  department_list: any[];
  addExpDetail: any[];
  employee_type_list: any[];
  customer_list: any[];
  employee_timing_list: any[];
  organization_list: any[];
  OrganizationSelected?: any;
  ReportingManagerList: ReportingManagerList[];
  salary_structure_list: SalaryStructureList[];
  setaddEduDetail?: any;
  SetDocument?: any;
  setaddExpDetail?: any;
  OnChangeCustomer?: any;
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  handleClickOpen?: any;
  getValues?: any;
  setValue?: any;
  bank_list: bankList[];
  project_levels?: ProjectLevels;
  user?: userDetails;
  state_list: StateList[];
  addProfessionalQualification?: any;
  setAddProfessionalQualification?: any;
  AddlfileHandleEvent?: any;
  downloadImage?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
