
import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from 'notistack';
import { EmployeeAllDetail, ProfessionalDetail1 } from "../../../models/employeeModel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EmployeeEditProfessionalDetailsPopover from "./EmployeeEditProfessionalDetailsPopover";

const EmployeeProfessionalDetails = ({
    professional_details,
    Submit,
    employeeDetails,
}: EmployeeProfessionalDetailsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [addProDetail, setaddProDetail] = useState<any>([
        {
            course_name: null,
            start_date: null,
            end_date: null,
            remarks: null,
        },
    ]);
    const addprofessionalDetails = () => {
        if (professional_details.length === 0) {
            let Temp: any = JSON.parse(JSON.stringify(addProDetail));
            Temp = [
                ...Temp,
                {
                    course_name: null,
                    start_date: null,
                    end_date: null,
                    remarks: null,
                },
            ];
            setaddProDetail(Temp);
        } else if (
            !addProDetail[addProDetail.length - 1].course_name ||
            !addProDetail[addProDetail.length - 1].start_date ||
            !addProDetail[addProDetail.length - 1].end_date ||
            !addProDetail[addProDetail.length - 1].remarks
        ) {
            enqueueSnackbar("Please fill add all detail before adding new row.", {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        } else {
            let Temp: any = JSON.parse(JSON.stringify(addProDetail));
            Temp = [
                ...Temp,
                {
                    course_name: null,
                    start_date: null,
                    end_date: null,
                    remarks: null,
                },
            ];
            setaddProDetail(Temp);
        }
    };
    const OnChangeProcation = (value: string, row: string, index: number) => {
        let Temp: any = JSON.parse(JSON.stringify(addProDetail));
        Temp[index][row] = value;
        setaddProDetail(Temp);
    };
    const DeleteProAction = (index: number) => {
        let Temp: any = JSON.parse(JSON.stringify(addProDetail));
        let ProDet: any[] = [];
        Temp.forEach((element: any, indexs: any) => {
            if (index != indexs) {
                ProDet.push(JSON.parse(JSON.stringify(element)));
            }
        });
        setaddProDetail([]);
    setTimeout(() => {
        setaddProDetail(ProDet);
    }, 200);
    };
    const onSubmit = (data: any) => {
        console.log(data);
        let payload: any = {
            professional_details:
                addProDetail.length == 1
                    ? !!addProDetail[0].course_name
                        ? addProDetail
                        : []
                    : addProDetail.length > 1
                        ? addProDetail
                        : [],
            employee_id: employeeDetails?.employee_id,
        };
        Submit(payload);
    };

return (
    <>
        <Accordion
            elevation={0}
            disableGutters
            sx={{
                "&:before": {
                    display: "",
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid
                    container
                    justifyContent={"start"}
                    //alignItems={""}
                    direction={"row"}
                //paddingTop={1}
                >
                    <Grid item xs={10}>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: 18,
                                fontFamily: "Poppins, sans-serif",
                                textAlign: "left",
                                color: "#626974",
                                //mb: 10,
                            }}
                        >
                            Professional Qualification
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justifyContent="end" alignItems="center" padding={0}>
                    <Tooltip title="Edit">
                        <IconButton onClick={handleClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <EmployeeEditProfessionalDetailsPopover
                        anchorEl={anchorEl}
                        professional_details={professional_details}
                        setAnchorEl={setAnchorEl}
                        SubmitPopData={onSubmit}
                        addProDetail={addProDetail}
                        setaddProDetail={setaddProDetail}
                        OnChangeProcation={OnChangeProcation}
                        DeleteProAction={DeleteProAction}
                        addprofessionalDetails={addprofessionalDetails}
                    />
                </Grid>
                {!!professional_details &&
                    professional_details.map((row) => (
                        <Card
                            sx={{ minWidth: 275, maxWidth: "90%", mb: 2, mt: -1 }}
                            key={row.professional_id}
                        >
                            <CardContent>
                                <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    paddingTop={1}
                                >
                                    <Grid item xs={5.5}>
                                        <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                                            Course Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <Typography sx={{ fontSize: 14 }} color="#2E3840">
                                            {!!row.course_name ? row.course_name : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    paddingTop={1}
                                >
                                    <Grid item xs={5.5}>
                                        <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                                            Start Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="#2E3840"
                                            gutterBottom
                                        >
                                            {!!row?.start_date ? row?.start_date : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    paddingTop={1}
                                >
                                    <Grid item xs={5.5}>
                                        <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                                            End Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="#2E3840"
                                            gutterBottom
                                        >
                                            {!!row?.end_date ? row?.end_date : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="start"
                                    alignItems="center"
                                    paddingTop={1}
                                >
                                    <Grid item xs={5.5}>
                                        <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                                            Remarks
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="#2E3840"
                                            gutterBottom
                                        >
                                            {!!row?.remarks ? row?.remarks : "N/A"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
            </AccordionDetails>
        </Accordion>
    </>
)
};


export default EmployeeProfessionalDetails

interface EmployeeProfessionalDetailsProps {
    professional_details: ProfessionalDetail1[];
    Submit?: any;
    employeeDetails?: EmployeeAllDetail;
}

