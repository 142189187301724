import { TimingSheetMain } from "../../models/timingSheetModel";
import { TimingSheetActionTypes } from "../actions/timingSheetAction";
import InitialState from "./initialState";

const initialState: TimingSheetMain = InitialState.timingSheet;
export default function TimingSheetReducer(
  state: TimingSheetMain = initialState,
  action: any
) {
  switch (action.type) {
    case TimingSheetActionTypes.Get_Timing_Sheet_List_Success_Action:
      return { ...state, timing_sheet_list: action.payload };
    case TimingSheetActionTypes.Get_Timing_Sheet_Pending_Approval_List_Success_Action:
      return { ...state, time_sheet_pending_approvalList: action.payload };
    case TimingSheetActionTypes.Clear_Time_Sheet_Action:
      return { ...state, timing_sheet_list: undefined };
    case TimingSheetActionTypes.Go_From_WorkList_Action:
      console.log("reducer of worklist hit");
      return { ...state, worksheet: action.payload };
    case TimingSheetActionTypes.Clear_From_worklist_Action:
      return { ...state, worksheet: undefined };
    case TimingSheetActionTypes.Search_By_Name_Or_Id_Action:
      return { ...state, employeeList: action.payload };
      case TimingSheetActionTypes.Time_Sheet_History_Success_Action:
        return { ...state, timesheet_history: action.payload };
    default:
      return state;
  }
}
