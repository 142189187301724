import { baseUrl, environment } from "../environment";
import {
  AddDesignationPayload,
  AddEmployeeTimingPayload,
  DepartmentAddPayload,
  DepartmentAllPayload,
  EmployeeTimingListPayload,
  DesignationtAllPayload,
  OrganizationAddPayload,
  ServiceOfferPayload,
  AddEmployeeTypePayload,
  EmployeeTypeListPayload,
  DepartmentEditPayload,
  DesignationEditPayload,
  EmployeeTypeEditPayload,
  EmployeeTimingEditPayload,
  DepartmentDeletePayload,
  DesignationDeletePayload,
  OrganizationEditPayload,
  EmployeeTimingDeletePayload,
  EmployeeTypeDeletePayload,
  DomainPayload,
  AddSalaryStructurePayload,
  SalaryStructureList,
  SalaryStructureListPayload,
  SalaryStructureComponentPayload,
  SalaryStructureGetByIDPayload,
  AddPostPayload,
  AddSalaryComponentPayload,
  DeleteSalaryComponentPayload,
  SalaryComponentEditPayload,
  PostDeletePayload,
  EditPostPayload,
  MainDashboardPayload,
  SyncEmpPayload,
  SalaryComponentThresholdPayload,
} from "../models/organizationModels";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});

export const OrganizationAddService = (data: OrganizationAddPayload) => {
  return serviceClient.post(environment.urls.createOrganization, data);
};

export const DepartmentAddService = (data: DepartmentAddPayload) => {
  return serviceClient.post(environment.urls.createDepartment, data);
};

export const GetAllOrganizationService = () => {
  return serviceClient.get(environment.urls.getAllOrganization);
};
export const GetAllDepartmentService = (data: DepartmentAllPayload) => {
  return serviceClient.post(environment.urls.getAllDepartment, data);
};
export const GetAllServiceOfferdService = () => {
  return serviceClient.get(environment.urls.getAllServices);
};
export const EmployeeTimingAddService = (data: AddEmployeeTimingPayload) => {
  return serviceClient.post(environment.urls.createEmployeeTiming, data);
};
export const EmployeeDesignationAddService = (data: AddDesignationPayload) => {
  return serviceClient.post(environment.urls.createEmployeeDesignation, data);
};
export const GetAllDesignationService = (data: DesignationtAllPayload) => {
  return serviceClient.post(environment.urls.getAllDesignation, data);
};
export const GetAllEmployeeTimimgService = (
  data: EmployeeTimingListPayload
) => {
  return serviceClient.post(environment.urls.getAllEmployeeTimingList, data);
};
export const EmployeeTypeAddService = (data: AddEmployeeTypePayload) => {
  return serviceClient.post(environment.urls.createEmployeeType, data);
};
export const OrganizationEditService = (data: OrganizationEditPayload) => {
  return serviceClient.post(environment.urls.editOrganizationList, data);
};
export const GetAllEmployeeTypeService = (data: EmployeeTypeListPayload) => {
  return serviceClient.post(environment.urls.getAllEmployeeTypeList, data);
};
export const DepartmentEditService = (data: DepartmentEditPayload) => {
  return serviceClient.post(environment.urls.editDepartmentList, data);
};
export const DesignationEditService = (data: DesignationEditPayload) => {
  return serviceClient.post(environment.urls.editDesignationList, data);
};
export const EmployeeTypeEditService = (data: EmployeeTypeEditPayload) => {
  return serviceClient.post(environment.urls.editEmployeeTypeList, data);
};
export const EmployeeTimingEditService = (data: EmployeeTimingEditPayload) => {
  return serviceClient.post(environment.urls.editEmployeeTimingList, data);
};
export const DepartmentDeleteService = (data: DepartmentDeletePayload) => {
  return serviceClient.post(environment.urls.DeleteDepertment, data);
};
export const DesignationDeleteService = (data: DesignationDeletePayload) => {
  return serviceClient.post(environment.urls.DeleteDesignation, data);
};
export const EmployeeTypeDeleteService = (data: EmployeeTypeDeletePayload) => {
  return serviceClient.post(environment.urls.DeleteEmployeeType, data);
};
export const EmployeeTimingDeleteService = (
  data: EmployeeTimingDeletePayload
) => {
  return serviceClient.post(environment.urls.DeleteEmployeeTiming, data);
};
export const DomainGetAllService = (data: DomainPayload) => {
  return serviceClient.post(environment.urls.AllDomainType, data);
};
export const GetAllDomainListService = () => {
  return serviceClient.get(environment.urls.getSalaryStructure);
};
export const AddSalaryStructureService = (data: AddSalaryStructurePayload) => {
  return serviceClient.post(environment.urls.AddSalaryStructure, data);
};
export const GetAllSalaryStructureService = (
  data: SalaryStructureListPayload
) => {
  return serviceClient.post(environment.urls.GetAllSalaryStructureList, data);
};
export const GetByIDListService = (data: SalaryStructureGetByIDPayload) => {
  return serviceClient.post(environment.urls.GetSalaryStructureByID, data);
};
// POST
export const PostAddService = (data: AddPostPayload) => {
  return serviceClient.post(environment.urls.AddPost, data);
};

export const SalaryComponentGetAll = () => {
  return serviceClient.get(environment.urls.SalarycomponentGetAllurl);
};
export const SalaryComponentAddService = (data: AddSalaryComponentPayload) => {
  return serviceClient.post(environment.urls.AddSalaryComponent, data);
};
export const GetAllPostService = () => {
  return serviceClient.get(environment.urls.getAllPost);
};
export const SalaryComponentDeleteService = (
  data: DeleteSalaryComponentPayload
) => {
  return serviceClient.post(environment.urls.DeleteSalaryComponent, data);
};
export const SalaryComponentEditService = (
  data: SalaryComponentEditPayload
) => {
  return serviceClient.post(environment.urls.editSalaryComponent, data);
};
export const PostDeleteService = (data: PostDeletePayload) => {
  return serviceClient.post(environment.urls.deletePost, data);
};
export const PostEditService = (data: EditPostPayload) => {
  return serviceClient.post(environment.urls.editPost, data);
};
export const SalaryStructureUpdate = (data: any) => {
  return serviceClient.post(environment.urls.SalaryStructureUpdate, data);
};
export const SampleSalaryDownload = (data: any) => {
  return serviceClient.post(environment.urls.sampleSalarydownload, data);
};
export const GetMainDashBoardService = (data: MainDashboardPayload) => {
  return serviceClient.post(environment.urls.getMainDashboard, data);
};
export const SyncAllEmpService = (data: SyncEmpPayload) => {
  return serviceClient.post(environment.urls.syncSalaryStructureForEmp, data);
};
export const SalaryComponentThresholdService = (data: SalaryComponentThresholdPayload) => {
  return serviceClient.post(environment.urls.thresholdParameter, data);
};