import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { TerminationInitiatedEmployeeList } from "../../../models/terminationModel";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TerminationApprovalSection from "./TerminationApprovalSection";

function PendingApprovalTerminationView({
  termination_initiated_emp_list,
  FinalApprovedSubmit
}: PendingApprovalTerminationViewProps) {
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<TerminationInitiatedEmployeeList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const column1: any[] = [
    "Employee Name",
    "Project",
    "Initiated By",
    "Initiated On",
    "Last Working Date",
    "Status",
    "Action",
  ];
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!termination_initiated_emp_list
      ? termination_initiated_emp_list.filter(
          (row: TerminationInitiatedEmployeeList) => {
            return (
              row?.employee_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.project_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.created_by_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          }
        )
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  // useEffect(() => {
  //   if (!!termination_initiated_emp_list) {
  //     setRows(termination_initiated_emp_list);
  //   }
  // }, [termination_initiated_emp_list]);
  useEffect(() => {
    if (!!termination_initiated_emp_list) {
      cancelSearch();
    }
  }, [termination_initiated_emp_list]);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openTerminationPopup, setOpenTerminationPopup] = React.useState(false);
  const [openTerminationSelectedList, setOpenTerminationSelectedList] = useState("");
  const AcceptPendingTermination = (data: any) => {
    setOpenTerminationPopup(true);
    // ListofPendingTermination(data)
    setOpenTerminationSelectedList(data)
  };
  return (
    <>
      <Paper
        elevation={3}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        {/* <Dialog
        open={final_flag}
        onClose={() => setfinalFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              submit_flaga == 1 ? "reject" : "approve"
            } this attendance?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setfinalFlag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (
              AprrovedPromotionAction(approvePayload),
              setfinalFlag(false),
              setOpenPromotionPopup(false),
              SetapprovalList([])
            )}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.employee_termination_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" key={column1[1]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {row.initiatedBy +
                                " (" +
                                row.initiated_employee_name +
                                ")"}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[4]}>
                              {row.created_on
                                ? moment(row.created_on)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[5]}>
                              {row.termination_date
                                ? moment(row.termination_date)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center" key={column1[6]}>
                              {row.eventStatus}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[7]}>
                              <Tooltip
                                title=" Edit & View"
                                onClick={() => AcceptPendingTermination(row)}
                              >
                                <IconButton>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <TerminationApprovalSection
        open={openTerminationPopup}
        setOpen={setOpenTerminationPopup}
        openTerminationSelectedList={openTerminationSelectedList}
        FinalApprovedSubmit={FinalApprovedSubmit}
      />
    </>
  );
}

export default PendingApprovalTerminationView;
interface PendingApprovalTerminationViewProps {
  termination_initiated_emp_list: TerminationInitiatedEmployeeList[];
  FinalApprovedSubmit?: any
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
