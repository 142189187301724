import { log } from "console";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { environment } from "../../../environment";
import { CandidateList } from "../../../models/employeeModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { getAllCandidateAction } from "../../../stores/actions/employeeAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import AllCandidateView from "./AllCandidateView";

function AllCandidate({
  getAllCandidateAction,
  candidate_list,
  candidate_count,
  SidenavAutoCloseOpen
}: AllCandidateProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  const filterData = (props: any) => {
    getAllCandidateAction({
      enrolment_id:
        !!props.enrolment_id && props.enrolment_id != ""
          ? props.enrolment_id
          : null,
      candidate_name:
        !!props.candidate_name && props.candidate_name != ""
          ? props.candidate_name
          : null,
          mobile_no:
        !!props.mobile_no && props.mobile_no != ""
          ? props.mobile_no
          : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const Clear = () => {
    getAllCandidateAction({
      employee_id: null,
      employee_name: null,
      employee_mob_no: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  React.useEffect(() => {
    getAllCandidateAction({
      candidate_name: !!getValues("candidate_name") ? getValues("candidate_name") : null,
      enrolment_id: !!getValues("enrolment_id") ? getValues("enrolment_id") : null,
      mobile_no: !!getValues("mobile_no") ? getValues("mobile_no") : null,
      page_size: pagesize,
      page_index: pageindex
  });
  }, [pageindex, pagesize]);
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);

  return (
    <AllCandidateView
      candidate_list={candidate_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      candidate_count={candidate_count}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      filterData={filterData}
      Clear={Clear}
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    candidate_list: state.employee.candidate_list,
    candidate_count: state.employee.candidate_count,
  };
};
const mapDispatchToProps = {
  getAllCandidateAction,
  SidenavAutoCloseOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCandidate);

interface AllCandidateProps {
  getAllCandidateAction?: any;
  candidate_list: CandidateList[];
  candidate_count?: number;
  SidenavAutoCloseOpen?: any;
}
