import { Grid } from "@mui/material";
import React from "react";

const Payloaddashboard = () => {
  return (
    <Grid
      container
      justifyContent={"space-evenly"}
      alignItems={"center"}
      overflow={"hidden"}
      style={{ backgroundColor: "#F6F7F9", height: "100%" }}
    >
      <img src={require("../../../assets/maintenance.jpg")} />
    </Grid>
  );
};

export default Payloaddashboard;
