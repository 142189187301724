import { Grid, TextField, Tooltip, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DomainList } from "../../../models/organizationModels";
import { EachComponent } from "./AddSalarystructureView";
import CalculationRule from "./CalculationRule";
import DeleteIcon from "@mui/icons-material/Delete";
const EachRow = ({
  item,
  index,
  onChangeValue,
  EvaluateRule,
  DeleteComponent,
  calculation_type,
  operator,
  component_master,
  global_key,
  operator_tye,
  edit_flag,
}: rachRowProps) => {
  const [calculationflag, Setcalculation] = useState<boolean>(false);
  const ClickRule = (item: any) => {
    Setcalculation(true);
  };

  return (
    <Draggable
      key={item.compent_id}
      draggableId={item.seq_no.toString()}
      index={index}
      isDragDisabled={edit_flag}
    >
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <CalculationRule
            Setcalculation={Setcalculation}
            calculationflag={calculationflag}
            calculation_type={calculation_type}
            operator={operator}
            component_master={component_master.filter(
              (m) =>
                m.compent_id != item.compent_id &&
                ((m.component_type == item.component_type &&
                  +m.seq_no < +item.seq_no) ||
                  (m.component_type != item.component_type &&
                    m.component_type < item.component_type))
            )}
            global_key={global_key}
            operator_tye={operator_tye}
            Submit={(calculation: any) =>
              onChangeValue(calculation, item.compent_id, "calculation")
            }
            calculationObj={item.calculation}
          />
          <Grid
            container
            direction={"row"}
            marginY={3}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid
              item
              xs={2}
              style={{
                marginTop: 5,
              }}
            >
              <TextField
                type={"text"}
                id="standard-required"
                variant="standard"
                className={"smaller"}
                style={{ width: "100%" }}
                name={"component_name"}
                // label={"Component Name"}
                inputProps={{ readOnly: true }}
                size="small"
                value={item.component_name}
                placeholder={"Component Name"}
              />
            </Grid>
            <Grid item xs={2} style={{ marginTop: 5, paddingLeft: 4 }}>
              <TextField
                type={"text"}
                id="standard-required"
                variant="standard"
                className={"smaller"}
                style={{ width: "100%" }}
                name={"salary_priod"}
                // label={"Component Name"}
                inputProps={{ readOnly: true }}
                size="small"
                value={item.salary_priod_name}
                placeholder={"Salary Priod"}
              />
            </Grid>
            <Grid item xs={2} style={{ paddingLeft: 4, marginTop: 5 }}>
              <TextField
                type={"text"}
                id="standard-required"
                variant="standard"
                className={"smaller"}
                style={{ width: "100%" }}
                name={"attendace_dependancy"}
                // label={"Component Name"}
                inputProps={{ readOnly: true }}
                size="small"
                value={item.attendace_dependancy ? "Yes" : "No"}
                placeholder={"Attendance Dependency"}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                paddingLeft: 4,
                marginTop: 4,
              }}
            >
              <TextField
                type={"number"}
                id="standard-required"
                variant="standard"
                className={"smaller"}
                style={{ width: "100%" }}
                name={"default_value"}
                size="small"
                placeholder={"Default Value"}
                // label={"Default Value"}
                value={item.default_value}
                inputProps={{ readOnly: edit_flag }}
                onChange={(event: any) =>
                  onChangeValue(
                    +event.target.value,
                    item.compent_id,
                    "default_value"
                  )
                }
              />
            </Grid>
            <Grid item xs={3} style={{ paddingLeft: 4, marginTop: 5 }}>
              <TextField
                type={"text"}
                id="standard-required"
                variant="standard"
                className={"smaller"}
                style={{ width: "100%" }}
                name={"calculation"}
                onClick={() => !edit_flag && ClickRule(item)}
                // label={"Component Name"}
                inputProps={{ readOnly: true }}
                size="small"
                value={
                  !!item.calculation
                    ? EvaluateRule(item.calculation)
                    : "No Rule Applied"
                }
                placeholder={"Apply Rule"}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                paddingLeft: -2,
                textAlign: "center",
              }}
            >
              {!item.default && !edit_flag && (
                <Tooltip title="Delete">
                  <IconButton>
                    <DeleteIcon
                      color="error"
                      sx={{ cursor: "pointer" }}
                      onClick={() => DeleteComponent(item.compent_id)}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </Draggable>
  );
};
interface rachRowProps {
  item: EachComponent;
  index: any;
  onChangeValue: any;
  EvaluateRule: any;
  DeleteComponent: any;
  calculation_type: DomainList[];
  operator: DomainList[];
  operator_tye: DomainList[];
  global_key: DomainList[];
  component_master: EachComponent[];
  edit_flag?: boolean;
}

export default EachRow;
