import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import Axios from "axios";
import { baseUrl, environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import { useSnackbar } from "notistack";
import { InvoiceList, InvoiceListResponse } from "../../../models/invoiceModel";
import moment from "moment";

function CreateInvoiceView({
  user,
  id,
  CreateInvoice,
  accessToken,
  invoice_details,
  UpdateInvoice,
}: CreateInvoiceViewProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  console.log("invoice_list_details", invoice_details);

  const FileDownload = require("js-file-download");
  const { enqueueSnackbar } = useSnackbar();
  const [edit_flag, SetEditFlag] = React.useState<boolean>(true);
  const [documents, SetDocument] = React.useState<any>("");
  // const fileRef = React.useRef<any>();
  const onSubmit = (data: any) => {
    if (!!invoice_details) {
      let payload: any = {
        ...invoice_details,
        ...data,
        documents: !!documents ? documents : null,
      };
      UpdateInvoice(payload);
    } else {
      let payload: any = {
        ...data,
        documents: !!documents ? documents : null,
      };
      CreateInvoice(payload);
    }
  };
  const fileHandleEvent = (event: any) => {
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${
        event.target.files[0].name
      }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("project_id", id);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response: any) => {
          console.log("response", response.status);

          if (response.status == 200) {
            SetDocument(file_name);
            CreateCandidateSuccessAction();
            enqueueSnackbar("Successfully uploaded...", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
  };
  // console.log("documents", documents);
  const handleClickOpenDownload = (file_name: any) => {
    Axios({
      url: baseUrl + environment.urls.downloadFileUrl,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        fileName: file_name,
      },
    })
      .then(async (response) => {
        // await FileDownload(response.data, file_name);
        var file = new Blob([response.data], {
          type: getMimeTypeFromFileName(file_name),
        });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  };
  function getMimeTypeFromFileName(fileName: any) {
    // Extract the file extension
    var extension = fileName.split(".").pop().toLowerCase();

    // Define MIME types based on file extensions
    var mimeTypes: any = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      pdf: "application/pdf",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // Add more file extensions and their corresponding MIME types here
    };

    // Look up the MIME type based on the file extension
    var mimeType = mimeTypes[extension] || "application/octet-stream";

    return mimeType;
  }
  React.useEffect(() => {
    if (!!invoice_details) {
      setValue(
        "payment_date",
        moment.utc(invoice_details.invoice_date).format("yyyy-MM-DD")
      );
      setValue("invoice_no", invoice_details.invoice_no);
      setValue("invoice_amount", invoice_details.invoice_amount);
      setValue("remarks", invoice_details.remarks);
      SetDocument(invoice_details.invoice_file);
    }
  }, [invoice_details]);
  return (
    <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            Invoice
            {/* {!!project_payment
            ? "Payment Details"
            : `Add Payment Information of ${project_list.find((m)=>m.project_id===+id)?.project_name}`} */}
          </Typography>
        </Grid>
      </Grid>
      <form>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ marginInline: 10, width: "90%" }}>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
            {!!invoice_details && (
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <Switch
                  value={edit_flag}
                  onChange={(event: any) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>
                  Edit Invoice Details
                </Typography>
              </Grid>
            )}

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Invoice No.
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"invoice_no"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        readOnly: !!invoice_details ? edit_flag : !edit_flag,
                      }}
                      // InputProps={{ inputProps: { min: 1 } }}
                      placeholder={"Invoice No."}
                      error={!!errors["invoice_no"]}
                      helperText={
                        errors.invoice_no &&
                        `${errors.invoice_no.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Invoice Amount
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"invoice_amount"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        readOnly: !!invoice_details ? edit_flag : !edit_flag,
                      }}
                      // InputProps={{ inputProps: { min: 1 } }}
                      placeholder={"Invoice Amount"}
                      error={!!errors["invoice_amount"]}
                      helperText={
                        errors.invoice_amount &&
                        `${errors.invoice_amount.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Invoice Date
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"payment_date"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"date"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        readOnly: !!invoice_details ? edit_flag : !edit_flag,
                      }}
                      // InputProps={{ inputProps: { min: 1 } }}
                      placeholder={"Invoice Date)"}
                      error={!!errors["payment_date"]}
                      helperText={
                        errors.payment_date &&
                        `${errors.payment_date.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Remarks
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"remarks"}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextareaAutosize
                      style={{
                        width: "96%",
                        height: 100,
                        resize: "none",
                        fontSize: "15px",
                        padding: "2%",
                        outline: "none",
                        border: "1px solid rgba(19,36,55,0.5)",
                        borderRadius: "5px",
                      }}
                      className={"smaller"}
                      placeholder={"Remarks"}
                      minRows={2}
                      value={value}
                      readOnly={!!invoice_details ? edit_flag : !edit_flag}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Upload Invoice
                </Typography>
              </Grid>
              {!documents ? (
                <Grid item xs={5.05}>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ mb: 2, ml: 1, mt: 2 }}
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    disabled={!!invoice_details ? edit_flag : !edit_flag}
                  >
                    <input
                      hidden
                      type="file"
                      // value={documents}
                      onChange={fileHandleEvent}
                      // ref={fileInputRef}
                    />
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        // color: "#FFFF",
                        paddingLeft: 10,
                        // textAlign: "center"
                      }}
                    >
                      Upload
                    </Typography>
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={5.05}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        // color: "#FFFF",
                        paddingLeft: 10,
                        marginRight: 10,
                        // textAlign: "center"
                      }}
                    >
                      {documents}
                    </Typography>
                    <Tooltip
                      title="Download Documents"
                      onClick={() => handleClickOpenDownload(documents)}
                    >
                      <IconButton>
                        <CloudDownloadIcon />
                      </IconButton>
                    </Tooltip>
                    {((edit_flag && !invoice_details) ||
                      (!edit_flag && !!invoice_details)) && (
                      <Tooltip title="Edit" onClick={() => SetDocument("")}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            {((edit_flag && !invoice_details) ||
              (!edit_flag && !!invoice_details)) && (
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                <b>{!!invoice_details ? "Update" : "Add"}</b>
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
export default CreateInvoiceView;
interface CreateInvoiceViewProps {
  user?: userDetails;
  id?: any;
  CreateInvoice?: any;
  accessToken?: string;
  invoice_details?: InvoiceList | undefined;
  UpdateInvoice?: any;
}
