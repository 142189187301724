import React, { useEffect, useState } from "react";
import { SalaryDetail1 } from "../../../models/employeeModel";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import moment from "moment";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { baseUrl, environment } from "../../../environment";
const FileDownload = require("js-file-download");

function EmployeeSalaryDetails({ SalaryDetail,accessToken }: EmployeeSalaryDetailsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const empwiseSalarySlip = (data:any, k: any, index: number) => {
    // console.log("data", data, "k", k);    
    Axios({
      url: baseUrl + environment.urls.downloadEmpSalarySlip,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        employee_id: data,
        batch_id: k,
        bonus: index === 1 ? false : true
      },
    })
      .then((response) => {
        if (response.status == 200) {
          FileDownload(response.data,  `${data}-salaryslip.pdf`);
        } else {
          enqueueSnackbar(
            !!response?.data?.message
              ? response?.data?.message
              : "Something went wrong...",
            {
              variant: "warning",
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error?.data?.message
            ? error?.data?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<SalaryDetail1[]>([]);
  const columns: any[] = [
    "Month",
    "Total Income",
    "Total Deduction",
    "Total Contribution",
    "Net Payable",
    "Action",
  ];
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!SalaryDetail
      ? SalaryDetail.filter((row: SalaryDetail1) => {
          return row?.bank_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!SalaryDetail) {
      cancelSearch();
    }
  }, [SalaryDetail]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <>
      <Paper sx={{ mb: 1.4, padding: 2, mt: 2 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={3}>
            {/* <Button
          size="medium"
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add New
        </Button> */}
          </Grid>
          <Grid item xs={9}>
            {/* <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          /> */}
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.emp_monthly_payroll_id}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {monthNames[row.month - 1]}
                            {","}
                            {row.year}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.total_income}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[2]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#b32d00",
                              }}
                            >
                              &#8377; {row.total_deduction}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[3]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                              }}
                            >
                              &#8377; {row.total_contribution}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[4]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.net_payable}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[5]}>
                            <Tooltip
                              title="Download Salary Slip"
                              onClick={() =>
                                empwiseSalarySlip(
                                  row.employee_id,
                                  row.batch_id,
                                  1
                                )
                              }
                            >
                              <IconButton>
                                <SaveAltIcon />
                              </IconButton>
                            </Tooltip>
                            {/* <Tooltip
                              title="Download Incentive Slip"
                              onClick={()=>empwiseSalarySlip(row.employee_id, row.batch_id,2)}
                            >
                              <IconButton>
                                <SaveAltIcon />
                              </IconButton>
                            </Tooltip> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={6}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default EmployeeSalaryDetails;

interface EmployeeSalaryDetailsProps {
  SalaryDetail?: SalaryDetail1[];
  accessToken?: string;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
