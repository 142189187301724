import { Cookies } from "react-cookie";
import {
  CreateInvoiceService,
  EditInvoiceService,
  InvoiceListService,
} from "../../services/invoiceService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
import {
  CreateInvoicePayload,
  InvoiceListPayload,
} from "../../models/invoiceModel";

export enum InvoiceActionTypes {
  Invioce_List_Success_Action = "[INVOICE] Invoice List Success Action",
  Create_Invioce_Success_Action = "[INVOICE] Create Invoice Success Action",
  Edit_Invioce_Success_Action = "[INVOICE] Edit Invoice Success Action",
}

export const InvoiceListAction = (payload: InvoiceListPayload) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Invoice List. Please Wait...",
        type: 2,
      })
    );
    return InvoiceListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(InvoiceListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const InvoiceListSuccessAction = (data: any) => {
  return {
    type: InvoiceActionTypes.Invioce_List_Success_Action,
    payload: data,
  };
};

export const CreateInvoiceAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Invoice Creating. Please Wait...",
        type: 2,
      })
    );
    return CreateInvoiceService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CreateInvoiceSuccessAction());
          payload.navigateTo(`/customer/invoice-data/${payload.pageID}`);
          payload.enqueueSnackbar("Invoice Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(InvoiceListAction(payload.data2));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateInvoiceSuccessAction = () => {
  return {
    type: InvoiceActionTypes.Create_Invioce_Success_Action,
  };
};

export const EditInvoiceAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Invoice Updating. Please Wait...",
        type: 2,
      })
    );
    return EditInvoiceService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(EditInvoiceSuccessAction());
          payload.navigateTo(`/customer/invoice-data/${payload.pageID}`);
          payload.enqueueSnackbar("Invoice Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(InvoiceListAction(payload.data2));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditInvoiceSuccessAction = () => {
  return {
    type: InvoiceActionTypes.Create_Invioce_Success_Action
  };
};

