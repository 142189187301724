import { Typography } from "@mui/material";
import { Handle, Position } from "reactflow";
import "./Organization.css";
const CustomeNode = ({ data }: any) => {
  return (
    <div
      className="node"
      id={data.level}
      style={{
        height: "160px",
        width: "400px",
        border: "2px solid #B1B1B7",
        borderRadius: 5,
        backgroundColor: "#ffff",
      }}
    >
      <Handle type="target" position={Position.Top} />
      <div
        style={{
          textAlign: "center",
          padding: 5,
          //backgroundColor: "#4EA6DF",
          backgroundColor: "#0060FF"
        }}
      >
        <Typography style={{ color: "#fff", fontSize: 18 }}>
          {data.organization_name}
        </Typography>
      </div>
      <div
        style={{
          padding: 5,
        }}
      >
        <Typography style={{ fontSize: 18 }}>
          <b>CIN : </b> {data.organization_cin_no}
        </Typography>
        <Typography style={{ fontSize: 18 }}>
          <b>PAN : </b> {data.organization_pan}
        </Typography>
        <Typography style={{ fontSize: 18 }}>
          <b>GST : </b> {data.organization_gst}
        </Typography>
        <Typography style={{ fontSize: 18 }}>
          <b>Location : </b> {data.city}
        </Typography>
      </div>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
};
export default CustomeNode;
