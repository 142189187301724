import {
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  Button,
  AccordionDetails,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  CompletedProjectList,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

function CompletedProjectView({
  control,
  errors,
  getValues,
  handleSubmit,
  setValue,
  watch,
  ClearAllFilter,
  FilterSubmit,
  customer_list,
  completed_project_list,
}: CompletedProjectViewProps) {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<CompletedProjectList[]>([]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const onSubmit = (data: any) => {
    FilterSubmit(data);
  };
  const ClearFilter = () => {
    setValue("customer_id", null);
    setValue("prj_end_date", null);
    setValue("prj_start_date", null);
    ClearAllFilter();
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!completed_project_list
      ? completed_project_list.filter((row: CompletedProjectList) => {
          return (
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.customer_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!completed_project_list) {
      cancelSearch();
    }
  }, [completed_project_list]);
  useEffect(() => {
    if (!!completed_project_list) {
      setRows(completed_project_list);
    }
  }, [completed_project_list]);
  const column1: any[] = [
    "Customer Name",
    "Project Name",
    "Work Order No.",
    "Project Start Date",
    "Expected End Date",
    // "Action",
  ];
  const styles = {
    marginRight: "50px",
  };
  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  // backgroundColor: "#0060FF",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "white",
                      // color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{padding:0, margin:0}}>
              <form>
                <Grid container justifyContent={"flex-start"} mt={1.5}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Customer Name
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"customer_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={customer_list}
                              //   option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"customer_id"}
                              label={"customer_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Customer Name"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Start Date
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"prj_start_date"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"date"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100.9%" }}
                              onBlur={onBlur}
                              size="small"
                              value={!!value ? value : ""}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Start Date"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          End Date
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -6 }}>
                        <Controller
                          control={control}
                          name={"prj_end_date"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"date"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100.9%" }}
                              onBlur={onBlur}
                              size="small"
                              value={!!value ? value : ""}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"End Date"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    padding={3}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={ClearFilter}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper
        elevation={5}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, paddingTop: 3 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.project_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              {row.customer_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[1]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[2]}>
                              {row.work_order_no}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {row.start_date
                                ? moment(row.start_date)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[4]}>
                              {row.end_date
                                ? moment(row.end_date)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" key={column1[5]}>
                              <Tooltip
                                title="View"
                                onClick={() =>
                                  navigate(
                                    `/customer/project-report/${row.project_id}`
                                  )
                                }
                              >
                                <IconButton>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell> */}
                          </StyledTableRow>
                        ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default CompletedProjectView;
interface CompletedProjectViewProps {
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  watch?: any;
  FilterSubmit?: any;
  ClearAllFilter?: any;
  customer_list: CustomerList[];
  completed_project_list: CompletedProjectList[];
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    // backgroundColor: "#e6f0ff",
    // // color: "#ffff",
    // color: "#010101",
    // fontWeight: 600,
    // fontSize: 14,
    // padding: "3px",
    // paddingLeft: "5px",
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // padding: "0px 1px 0px 5px",
    // // color: "#363A44",
    // color: "#010101",
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
