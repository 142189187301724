import {
  CandidateList,
  CheckActionStatusPayload,
} from "../../../models/employeeModel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import ActionStatusPopover from "../ActionStatusPopover";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
function AllCandidateView({
  pagesize,
  pageindex,
  rowperpage,
  candidate_count,
  SetpageSize,
  Setpageindex,
  candidate_list,
  filterData,
  Clear,
  control,
  handleSubmit,
  reset,
}: AllCandidateViewProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, Setopen] = useState<boolean>(false);
  const [popoverPayload, SetpopoverPayload] = useState<
    CheckActionStatusPayload | undefined
  >(undefined);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<CandidateList[]>([]);

  const columns: any[] = [
    "Candidate Name",
    "DOB",
    "Mobile No.",
    "Enrollment ID",
    "Designation",
    "Employee Type",
    "Project",
    "Employee Status",
    "Joining On",
    "Created By",
    "Action",
  ];
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const filterDatas = (data: any) => {
    filterData(data);
    //handleClose();
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!candidate_list
      ? candidate_list.filter((row: CandidateList) => {
          return (
            row?.candidate_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.enrolment_id
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.designation_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_type_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!candidate_list) {
      cancelSearch();
    }
  }, [candidate_list]);
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  return (
    <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={12} paddingBottom={3}>
          <Accordion
            disableGutters
            elevation={1}
            expanded={expanded === "panel1"}
            onChange={handleChange1("panel1")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "4%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid style={{ margin: 5 }}>
                <form>
                  <Grid
                    container
                    direction={"row"}
                    // padding={2}
                    // justifyContent={"center"}
                    // alignItems={"center"}
                    // paddingLeft={"6%"}
                    // marginBottom={"-2%"}
                    // marginTop={"-3%"}
                  >
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"start"}
                      paddingTop={0}
                    >
                      <Grid container direction={"row"} margin={1}>
                        <Grid item xs={4} alignItems="center">
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Enrolment ID
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"enrolment_id"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    //label={"Employee Id"}
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Enrolment ID"}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Candidate Name
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"candidate_name"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Candidate Name"}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Mobile No.
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"mobile_no"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"number"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Mobile No."}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end ",
                      marginRight: "2rem",
                      //marginRight: "0px",
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={() => (Clear(), reset())}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(filterDatas)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      filter
                    </Button>
                  </Box>
                </form>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={3}>
          {/* <Button
          size="medium"
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Add New
        </Button> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>
        <ActionStatusPopover
          open={open}
          setOpen={Setopen}
          payload={popoverPayload}
        />
        <Grid item xs={12} marginTop={2}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              //   style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows.map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.enrolment_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.candidate_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {moment.utc(row.date_of_birth).format("DD-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.mobile_no}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.enrolment_id}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.designation_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.employee_type_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        {row.project_name !=
                        environment.global_veriable.ho_project_name
                          ? row.project_name
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[7]}>
                        {row.status}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[8]}>
                        {moment(row.created_at).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[9]}>
                        {row.created_by}
                        <br/>
                          ({row.initiated_employee_name})
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[10]}>
                        <Tooltip
                          title="View"
                          onClick={() =>
                            navigate(
                              `/employment/candidate_details/${row.enrolment_id}/0`
                            )
                          }
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Show Approve Status"
                          onClick={() => (
                            SetpopoverPayload({
                              employeeId: row.enrolment_id,
                              employeeTypeId: row.employee_type_id,
                              eventType: 1,
                            }),
                            Setopen(true)
                          )}
                        >
                          <IconButton>
                            <ContentPasteSearchIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={11}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems={"center"} justifyContent="end">
            <Grid xs={2} textAlign="right">
              Rows per page:
            </Grid>
            <Grid xs={1} textAlign="center">
              <NativeSelect
                value={pagesize.toString()}
                onChange={(event) => SetpageSize(+event.target.value)}
              >
                {rowperpage.map((p) => (
                  <option key={p} value={p} onChange={(value: any) => value(p)}>
                    {p}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                onClick={() => Setpageindex(pageindex - 1)}
                color="primary"
                size="small"
                disabled={pageindex <= 0}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography>
                {pageindex * pagesize + 1} -{" "}
                {!!candidate_count &&
                candidate_count <= (pageindex + 1) * pagesize
                  ? candidate_count
                  : (pageindex + 1) * pagesize}
                {" of "}
                {candidate_count}
              </Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                color="primary"
                size="small"
                onClick={() => Setpageindex(pageindex + 1)}
                disabled={
                  (!!candidate_count &&
                    candidate_count <= (pageindex + 1) * pagesize) ||
                  candidate_count === 0
                }
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AllCandidateView;

interface AllCandidateViewProps {
  candidate_list: CandidateList[];
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  candidate_count?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  filterData?: any;
  Clear?: any;
  control?: any;
  handleSubmit?: any;
  reset?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
