import { PayloadMain } from "../../models/payrollModel";
import { PayrollActionTypes } from "../actions/payrollActions";
import InitialState from "./initialState";

const initialState: PayloadMain = InitialState.payroll;
export default function PayrollReducer(
  state: PayloadMain = initialState,
  action: any
) {
  switch (action.type) {
    case PayrollActionTypes.Payroll_Available_Employee_List_Success_Action:
      return { ...state, ApplicableEmployeeList: action.payload };
    case PayrollActionTypes.Get_Batch_List_Success_Action:
      return { ...state, batch_list: action.payload };
    case PayrollActionTypes.Get_Batch_Detail_Success_Action:
      return {
        ...state,
        batch_detail: action.payload.employee_list,
        batch_details: action.payload.batch_detail,
      };
    case PayrollActionTypes.Get_Calculated_Salary_Componenet_Success_Action:
      return { ...state, CalculatedComponenet: action.payload };
    case PayrollActionTypes.Get_Batch_List_BY_DEG_Success_Action:
      return { ...state, BatchByDeg: action.payload };
    default:
      return state;
  }
}
