import { baseUrl, environment } from "../environment";
import { CreateInvoicePayload, EditInvoicePayload, InvoiceListPayload } from "../models/invoiceModel";
import RestService from "./rest";

export const serviceClient = new RestService({
    baseURL: baseUrl,
  });
  export const InvoiceListService = (data: InvoiceListPayload) => {
    return serviceClient.post(environment.urls.invoiceList, data);
  };
  export const CreateInvoiceService = (data: CreateInvoicePayload) => {
    return serviceClient.post(environment.urls.createInvoice, data);
  };
  export const EditInvoiceService = (data: EditInvoicePayload) => {
    return serviceClient.post(environment.urls.editInvoice, data);
  };