import {
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  Button,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import moment from "moment";
import { TimeSheetHistoryList } from "../../../models/timingSheetModel";
import HistoryCardView from "./HistoryCardView";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
function TimeSheetHistoryView({
  Submit,
  timesheet_history,
  goToTimingSheetFromWorklistAction,
}: TimeSheetHistoryViewProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  // const formControl = useForm();
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const onSubmit = (data: any) => {
    // formControl.reset();
    data.year = moment(data.month).format("YYYY").toString();
    data.month = moment(data.month).format("MM").toString();
    Submit(data);
  };
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumDevice = useMediaQuery(theme.breakpoints.between('md', 'xl'));
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={10} xl={4.5}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10,}}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={10}
                      xl={4}
                      sx={{ marginLeft: isSmallDevice? 1:(isMediumDevice ? 2 : 20), marginTop: isSmallDevice? 0: -4 }}
                      className="datepicker"
                    >
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            inputFormat="MM-YYYY"
                            value={value}
                            InputProps={{
                              onBlur: onBlur,
                              size: "small",
                              style: {
                                width: "100%",
                              },
                            }}
                            onChange={(newValue) => {
                              onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        )}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 4,
                      
                    }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="primary"
                    size={isSmallDevice ? 'small' : 'large'}
                  >
                    View
                  </Button>
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ marginTop: 3, marginLeft: isSmallDevice? "10%": "5%", width: isSmallDevice? "80%": "90%"}}>
        <Grid container direction={"row"}  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {!!timesheet_history &&
            timesheet_history.map((m) => (
              <HistoryCardView
                m={m}
                key={Math.random()}
                selectedMonth={getValues("month")}
                goToTimingSheetFromWorklistAction={
                  goToTimingSheetFromWorklistAction
                }
              />
            ))}
        </Grid>
      </Paper>
    </>
  );
}

export default TimeSheetHistoryView;
interface TimeSheetHistoryViewProps {
  Submit?: any;
  timesheet_history: TimeSheetHistoryList[];
  goToTimingSheetFromWorklistAction?: any;
}
