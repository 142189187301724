import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { environment } from "../../../environment";
import {
  AllProjectBudgetList,
  ProjectUnbudgetList,
} from "../../../models/customerModel";
import { ReportingManagerList } from "../../../models/employeeModel";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateBudgetingAction,
  EditProjectBudgetingAction,
  GetProjectBudgetListAction,
  GetProjectUnbudgetListAction,
} from "../../../stores/actions/customerAction";
import { getReportingManagerList } from "../../../stores/actions/employeeAction";
import AddProjectBudgetView from "./CreateBudgetingView";

function CreateBudgeting({
  GetProjectUnbudgetListAction,
  project_unbuget_list,
  getReportingManagerList,
  ReportingManagerList,
  project_budget_list,
  CreateBudgetingAction,
  EditProjectBudgetingAction,
}: CreateBudgetingProps) {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [orgId, setOrgId] = React.useState<number>();
  const [projectId, setprojectId] = React.useState<number>();
  React.useEffect(() => {
    if (!!id && +id == 0) {
      GetProjectUnbudgetListAction({
        project_id: null,
      });
    } else if (!!id && +id != 0) {
      GetProjectUnbudgetListAction({
        project_id: +id,
      });
    }
  }, []);
  React.useEffect(() => {
    if (!!orgId && !!projectId) {
      getReportingManagerList({
        organization_id: orgId,
        employee_type_id: environment.global_veriable.ho_employees_type_id,
        project_id: projectId,
      });
    }
  }, [orgId, projectId]);
  // React.useEffect(() => {
  //   GetProjectBudgetListAction();
  // }, []);
  const Submit = (data: any) => {
    console.log(data);
    CreateBudgetingAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData = (data: any) => {
    EditProjectBudgetingAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <AddProjectBudgetView
      project_unbuget_list={project_unbuget_list}
      project_budget={project_budget_list.find(
        (m) => !!id && m.project_id == +id
      )}
      ReportingManagerList={ReportingManagerList}
      setOrgId={setOrgId}
      setprojectId={setprojectId}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    project_unbuget_list: state.customer.project_unbuget_list,
    ReportingManagerList: state.employee.ReportingManagerList,
    project_budget_list: state.customer.project_budget_list,
  };
};
const mapDispatchToProps = {
  GetProjectUnbudgetListAction,
  getReportingManagerList,
  GetProjectBudgetListAction,
  CreateBudgetingAction,
  EditProjectBudgetingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBudgeting);
interface CreateBudgetingProps {
  GetProjectUnbudgetListAction: any;
  getReportingManagerList: any;
  project_unbuget_list: ProjectUnbudgetList[];
  ReportingManagerList: ReportingManagerList[];
  GetProjectBudgetListAction: any;
  project_budget_list: AllProjectBudgetList[];
  CreateBudgetingAction?: any;
  EditProjectBudgetingAction?: any;
}
