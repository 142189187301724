import { FC, useState, useEffect, Dispatch } from "react";
import CloseIcon from "@mui/icons-material/Close";

import ModalUnstyled from "@mui/base/ModalUnstyled";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Grid,
  TextField,
  Checkbox,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";
import { ILeaveType } from "../../../models/leaveModel";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const LeaveTypeActionModal: FC<ILeaveTypeActionModal> = ({
  open,
  setOpen,
  setIsEditing,
  isEditing,
  EditLevelTypeAction,
  createLeaveTypeAction,
  user,
  setEditablePayload,
  selectedOrganizationLeave,
  editablePayload,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isApproveChecked, setIsApproveChecked] = useState<boolean>(false);
  const [isEncashmentchecked, setIsEncashmentchecked] =
    useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!open) {
      setEditablePayload(null);
      if (isChecked) {
        console.log("reset 1");
        reset({
          leave_type_name: "",
          auto_approved: false,
          carry_forward: false,
          max_carry_forward_limit: "",
        });
        // setValue("leave_type_name", "");
        // setValue("encashment", false);
        // setValue("auto_approved", false);
        // setValue("carry_forward", false);
        // setValue("max_carry_forward_limit", false);
        // setIsChecked(false);
        // setIsApproveChecked(false);
        // setIsEncashmentchecked(false);
      } else {
        console.log("reset 2");
        reset({
          leave_type_name: "",
          auto_approved: false,
          carry_forward: false,
        });
      }
      setIsChecked(false);
      setIsApproveChecked(false);
      setIsEncashmentchecked(false);
    }
  }, [open, setOpen, editablePayload]);
  console.log("editable payload : ", editablePayload);
  console.log("formState : ", formState);

  const onSubmit = (formData: any) => {
    console.log("formData : ", formData);
    const payload = {
      formData: {
        ...formData,
        encashment: false,
        max_carry_forward_limit: formData?.max_carry_forward_limit
          ? Number(formData.max_carry_forward_limit)
          : 0,
        organization_id: selectedOrganizationLeave
          ? selectedOrganizationLeave
          : Number(user?.employee_detail.organization_id),
      },
      enqueueSnackbar,
    };
    if (isEditing) {
      payload.formData.leave_type_id = Number(editablePayload?.leave_type_id);
      setEditablePayload(null);
      EditLevelTypeAction(payload);
    } else {
      createLeaveTypeAction(payload);
    }
    handleClose();
    setIsEditing(false);
  };

  useEffect(() => {
    if (!!editablePayload) {
      setValue("leave_type_name", editablePayload.leave_type_name);
      setValue("auto_approved", editablePayload.auto_approved);
      setValue("carry_forward", editablePayload.carry_forward);
      setValue(
        "max_carry_forward_limit",
        editablePayload.max_carry_forward_limit
      );
      setIsChecked(Boolean(editablePayload.carry_forward));
      setIsApproveChecked(Boolean(editablePayload.auto_approved));
      setIsEncashmentchecked(Boolean(editablePayload.encashment));
    }
  }, [editablePayload, open]);

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                background: "#0060FF",
                p: 2,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {isEditing ? "Edit Leave Type" : "Add Leave Type"}
              </Typography>
              <IconButton onClick={() => handleClose()} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ py: 3, px: 5 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"leave_type_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          size="small"
                          label={
                            <span>
                              Leave Type Name
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          onChange={onChange}
                          value={value}
                          placeholder={"Leave Type Name"}
                          error={!!errors["leave_type_name"]}
                          helperText={
                            errors.leave_type_name &&
                            `${errors.leave_type_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Controller
                        control={control}
                        name={"auto_approved"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={isApproveChecked}
                            onChange={(event) => (
                              setIsApproveChecked(event.target.checked),
                              setValue(
                                "auto_approved",
                                Boolean(event.target.checked)
                              )
                            )}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />
                      <Typography>Auto Approved</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Controller
                        control={control}
                        name={"carry_forward"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={isChecked}
                            onChange={(event) => (
                              setIsChecked(event.target.checked),
                              setValue(
                                "carry_forward",
                                Boolean(event.target.checked)
                              )
                            )}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />
                      <Typography>Carry Forward</Typography>
                    </Box>
                  </Grid>
                  {watch("carry_forward") ? (
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={"max_carry_forward_limit"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            onBlur={onBlur}
                            style={{ width: "100%" }}
                            size="small"
                            label={
                              <span>
                                Max Carry Forward Limit
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            }
                            onChange={onChange}
                            value={value}
                            placeholder={"Max Carry Forward Limit"}
                            error={!!errors["max_carry_forward_limit"]}
                            helperText={
                              errors.max_carry_forward_limit &&
                              `${errors.max_carry_forward_limit.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button variant="outlined" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

interface ILeaveTypeActionModal {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  setIsEditing: Dispatch<React.SetStateAction<boolean>>;
  EditLevelTypeAction?: any;
  createLeaveTypeAction?: any;
  user?: userDetails;
  setEditablePayload: Dispatch<React.SetStateAction<ILeaveType | null>>;
  selectedOrganizationLeave?: number;
  editablePayload: ILeaveType | null;
}
export default LeaveTypeActionModal;
