import { Box, Button, Grid, Popover, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { EmployeeAllDetail } from '../../../models/employeeModel'
import { Controller, useForm } from 'react-hook-form';
import Autocompletes from '../../../library/AutoComplete';

const EditEmergencyContactInformation = ({
    anchorEl,
    setAnchorEl,
    SubmitPopData,
    employeeDetails
  }: EditEmergencyContactInformationProps) => {
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };
    const {
      control,
      register,
      handleSubmit,
      formState,
      setValue,
      getValues,
      setError,
      clearErrors,
      reset,
      formState: { errors },
    } = useForm();
    useEffect(() => {
      if (!!employeeDetails ) {
        setValue("emergency_contact", employeeDetails.emergency_contact);
        setValue("relation", employeeDetails.relation);
      }
    }, [employeeDetails]);
    const onSubmit =(data: any)=>{
      handleClose()
      SubmitPopData(data)
    }
  return (
    <>
    <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Emergency Contact Information</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                 Contact No.
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"emergency_contact"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Contact No."}
                      error={!!errors["emergency_contact"]}
                      helperText={
                        errors.emergency_contact &&
                        `${errors.emergency_contact.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                Relationship
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"relation"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Relationship"}
                      error={!!errors["relation"]}
                      helperText={
                        errors.relation &&
                        `${errors.relation.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default EditEmergencyContactInformation

interface EditEmergencyContactInformationProps {
    employeeDetails?: EmployeeAllDetail;
    anchorEl?: any;
    setAnchorEl?: any;
    onSubmit?: any;
    SubmitPopData?: any;
  }
  