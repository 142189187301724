import React, { useEffect, useState, useRef } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid, { Column, DataGridProps, textEditor } from "react-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  UploadedEmployeeAttendanceList,
  AttandanceDetailPayload,
  AttendenceField,
} from "../../../models/attendanceModel";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import "./AttendanceDetailsView.css";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useLocation } from "react-router-dom";

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;
const AttendanceDataGrid = ({
  employee_list,
  attendance_payload,
  updateAttendanceExcel,
  id,
  pagesize,
  pageindex,
  rowperpage,
  total_count,
  SetpageSize,
  Setpageindex,
  attendence_fields,
  DeleteAction,
  user,
}: AttendanceDataGridProps) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [editDetails, setEditDetails] = useState<number>(id);
  const [delete_flag, setdeleteFlag] = useState<boolean>(false);
  const [delete_payload, SetdeletePayload] = useState<any>();
  const [dynamicColumns, setdynamicColumns] = useState<any[]>([]);
  const [updatedRows, setupdatedRows] = useState<any[]>([]);
  const [attendenceFieldForPDF, setAttendenceFieldForPDF] = useState<any[]>([]);
  const [attendenceFieldValueForPDF, setAttendenceFieldValueForPDF] = useState<
    any[]
  >([]);
  console.log(id);
  console.log("updatedRows : ", updatedRows);
  const { state } = useLocation();
  console.log("state month : ", state);
  useEffect(() => {
    let header: any[] = [
      {
        key: "batch_id",
        name: "Action",
        frozen: true,
        formatter: (props: any) => (
          <>
            {!!employee_list &&
            employee_list.find(
              (t) =>
                t.attendence_status == "GENERATED" ||
                t.attendence_status == "PAID"
            ) ? (
              <Tooltip title="Generated/Paid">
                <IconButton>
                  <CheckCircleOutlineIcon color="success" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Delete from batch">
                <IconButton
                  onClick={(event: any) =>
                    DeleteEmployeeAttendance(
                      employee_list.find((k) => k.employee_attendance_id)
                    )
                  }
                >
                  <DeleteIcon color="warning" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ];
    if (!!attendence_fields && attendence_fields.length > 0) {
      attendence_fields.forEach((m, index) => {
        if(m.template_db_field_name!=null){   
        header.push({
          key: m.template_db_field_name,
          name: m.template_field_name,
          frozen: !!m.fixed_column,
          editor: textEditor,
          // editable:
          //   m.editable == 1 && !!
          //   !!editDetails &&
          //   editDetails == 1 &&
          //   !!user &&
          //   environment.global_veriable.officialEmployee.includes(
          //     user?.designation
          //   )
          //     ? true
          //     : false,
          editable:
            m.editable == 1 &&
            !!employee_list &&
            employee_list.find(
              (t) =>
                t.attendence_status == "UPLOADED" ||
                t.attendence_status == "APPROVED"
            ) &&
            !!editDetails &&
            (editDetails == 1 ||
              (!!user &&
                environment.global_veriable.officialEmployee.includes(
                  user?.designation
                )))
              ? true
              : false,
        });
      }
      });
    }
    setdynamicColumns(header);
    if (!!employee_list && employee_list.length > 0) {
      let temp = JSON.parse(JSON.stringify(employee_list));
      setupdatedRows(temp);
    } else {
      setupdatedRows([]);
    }
  }, [attendence_fields, employee_list]);

  const setSelectedRows = async (event: any, column: any) => {
    if (event[column.indexes[0]].attendence_status != "INACTIVE") {
      updateAttendanceExcel(event[column.indexes[0]]);
    } else {
      await enqueueSnackbar("Inactive row cann't be alter.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  const getColor = (row: UploadedEmployeeAttendanceList) => {
    return row.attendence_status == "INACTIVE"
      ? "reds"
      : // : "greens";
      row.employee_attendance_id % 2 === 0
      ? "greens"
      : "whites";
  };
  const DeleteEmployeeAttendance = (event: any) => {
    // console.log("ev", event.employee_attendance_id);
    setdeleteFlag(true);
    SetdeletePayload({
      employee_attendance_id: event.employee_attendance_id,
    });
  };
  console.log("dynamicColumns : ", dynamicColumns);
  // useEffect(() => {
  //   if (Array.isArray(dynamicColumns) && dynamicColumns.length > 0 && Array.isArray(updatedRows) && updatedRows.length > 0) {
  //     const tData: any[] = [];
  //     dynamicColumns.forEach((self) => {
  //       tData.push(self.name);
  //     });
  //     setAttendenceFieldForPDF(tData);
  //   }
  // }, [dynamicColumns]);
  useEffect(() => {
    const saveData = async () => {
      if (
        Array.isArray(updatedRows) &&
        updatedRows.length > 0 &&
        Array.isArray(dynamicColumns) &&
        dynamicColumns.length > 0
      ) {
        const kData: any[] = [];
        const vData: any[] = [];
        for await (let uData of updatedRows) {
          const _vData: any[] = [];
          const _kData: any[] = [];
          Object.entries(uData).forEach(([key, val], index) => {
            const findKey = dynamicColumns.find((self) => self.key === key);
            if (!!findKey) {
              _kData.push({ text: findKey.name, style: "tableHeader" });
              _vData.push({ text: val });

              kData[0] = [..._kData];
            }
          });
          vData.push([
            {
              text: !!employee_list.find((m) => m.project_name)?.project_name
                ? employee_list.find((m) => m.project_name)?.project_name
                : "N/A",
            },
            ..._vData,
          ]);
        }
        setAttendenceFieldForPDF(kData[0]);
        setAttendenceFieldValueForPDF(vData);
      }
    };
    saveData();
  }, [updatedRows]);
  console.log("attendenceFieldValueForPDF : ", attendenceFieldValueForPDF);
  const Month: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  console.log("attendenceFieldForPDF : ", attendenceFieldForPDF);
  const divElement = useRef<HTMLDivElement | null>(null);
  const onDownloadPDF = () => {
    // html2canvas(document.getElementById("target")!).then((canvas) => {
    console.log("pdf hit");
    html2canvas(divElement.current!)
      .then((canvas) => {
        const dataURI = canvas.toDataURL();
        const docDefinition = {
          pageSize: "A4",
          pageOrientation: "landscape",
          pageMargins: [2, 2, 2, 2],
          background: {
            image: dataURI,
            width: 845,
          },
          // content: [
          //   {
          //     text: "title",
          //     fontSize: 32,
          //     bold: true,
          //     margin: [50, 50, 60, 60]
          //   }
          // ]
        };
        var dd = {
          pageOrientation: "landscape",
          content: [
            {
              text: `Attendence Details ${
                !!employee_list.find((m) => m.project_name)?.project_name
                  ? ` Of ${
                      employee_list.find((m) => m.project_name)?.project_name
                    }`
                  : ""
              }  ${state ? `For ${state}` : ""}   `,
              style: "header",
            },
            {
              style: "tableExample",
              widths: ["*"],
              table: {
                body: [
                  [
                    { text: "Project Name", style: "tableHeader" },
                    ...attendenceFieldForPDF,
                  ],
                  ...attendenceFieldValueForPDF,
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
          ],
          styles: {
            header: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 10,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: 900,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: "black",
              width: 900,
              paddingHorizontal: 5,
            },
          },
          defaultStyle: {
            fontSize: 9,
            color: "black",
            // alignment: 'justify'
          },
        };
        console.log("dd  : ", dd);
        pdfMake.createPdf(dd as any).download("Attendence-report.pdf");
      })
      .catch((err) => console.log("error : ", err));
  };

  return (
    <Paper
      elevation={3}
      sx={{ marginTop: 5, marginInline: 5, paddingBottom: 5 }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        sx={{ textAlign: "center", padding: 1, backgroundColor: "#42a5f5" }}
      >
        <Grid item xs={1}>
          <IconButton
            color="inherit"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
            sx={{ color: "#fff", marginLeft: -5 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={7} display="flex" alignItems="center">
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              color: "#fff",
              textAlign: "left",
              marginLeft: -5,
            }}
          >
            Attendance Details
          </Typography>
          <Button
            // onClick={() => navigate("/organization/add-department/0")}
            onClick={onDownloadPDF}
            sx={{ color: "#fff", border: "1px solid #fff", ml: 5 }}
            size="medium"
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
          >
            Download
          </Button>
        </Grid>
        {!!employee_list && (
          <>
            <Grid item xs={4}>
              <Grid container direction={"column"} justifyContent={"flex-end"}>
                <Grid container direction={"row"} justifyContent={"flex-end"}>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "left",
                        paddingLeft: "15%",
                        paddingBottom: 5,
                      }}
                    >
                      Project Name
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "center",
                        paddingBottom: 5,
                      }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "center",
                        paddingBottom: 5,
                      }}
                    >
                      {!!employee_list.find((m) => m.project_name)?.project_name
                        ? employee_list.find((m) => m.project_name)
                            ?.project_name
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        sx={{ padding: 1, marginTop: 1 }}
        ref={divElement}
      >
        <DataGrid
          key={Math.random().toString()}
          defaultColumnOptions={{
            resizable: true,
          }}
          columns={dynamicColumns}
          // columns={
          //   !!attendence_fields && attendence_fields.length > 0 ? columns : columnsVariable
          // }
          rows={updatedRows}
          rowHeight={30}
          onRowsChange={setSelectedRows}
          rowClass={(row) => getColor(row)}
        />
      </Grid>
      <Dialog
        open={delete_flag}
        onClose={() => setdeleteFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Department?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setdeleteFlag(false)}>
            No
          </Button>
          <Button
            onClick={() => (DeleteAction(delete_payload), setdeleteFlag(false))}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container alignItems={"center"} justifyContent="end">
        <Grid item xs={2} textAlign="right">
          Rows per page:
        </Grid>
        <Grid item xs={1} textAlign="center">
          <NativeSelect
            value={pagesize.toString()}
            onChange={(event) => SetpageSize(+event.target.value)}
          >
            {rowperpage.map((p) => (
              <option key={p} value={p} onChange={(value: any) => value(p)}>
                {p}
              </option>
            ))}
          </NativeSelect>
        </Grid>
        <Grid item xs={1} textAlign="center">
          <IconButton
            onClick={() => Setpageindex(pageindex - 1)}
            color="primary"
            size="small"
            disabled={pageindex <= 0}
          >
            <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
          </IconButton>
        </Grid>
        <Grid item xs={1} textAlign="center">
          <Typography>
            {pageindex * pagesize + 1} -{" "}
            {!!total_count && total_count <= (pageindex + 1) * pagesize
              ? total_count
              : (pageindex + 1) * pagesize}
          </Typography>
        </Grid>
        <Grid item xs={1} textAlign="center">
          <IconButton
            color="primary"
            size="small"
            onClick={() => Setpageindex(pageindex + 1)}
            disabled={
              (!!total_count && total_count <= (pageindex + 1) * pagesize) ||
              total_count === 0
            }
          >
            <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AttendanceDataGrid;

interface AttendanceDataGridProps {
  employee_list: any[];
  attendance_payload?: AttandanceDetailPayload;
  updateAttendanceExcel?: any;
  id: any;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_count?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  attendence_fields: AttendenceField[];
  DeleteAction?: any;
  user?: userDetails;
}

{
  /* <Grid container direction={"row"} justifyContent={"flex-end"}>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "left",
                        paddingLeft: "15%",
                        paddingBottom: 5,
                      }}
                    >
                      Division Name
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "center",
                        paddingBottom: 5,
                      }}
                    >
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        textAlign: "center",
                        paddingBottom: 5,
                      }}
                    >
                      {!!employee_list?.find((m) => m.division_name)
                        ?.division_name
                        ? employee_list?.find((m) => m.division_name)
                            ?.division_name
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid> */
}

{
  /* <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
          marginBottom: 20,
        }}
      ></Divider> */
}
{
  /* {!!employee_list && (
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          direction={"row"}
          style={{ paddingTop: 4, paddingBottom: 4, position: "relative", backgroundColor: "#0060FF" }}
        >
          <Grid
            item
            xs={6}
            style={{
              borderRightWidth: 1,
              borderRightStyle: "dotted",
              borderColor: "#fff",
            }}
          >
            <Grid container direction={"row"} justifyContent={"flex-start"}>
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "left",
                    paddingLeft: "15%",
                    paddingBottom: 5,
                  }}
                >
                  Project Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "center",
                    paddingBottom: 5,
                  }}
                >
                  :
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "center",
                    paddingBottom: 5,
                  }}
                >
                  {!!employee_list.find((m)=>m.project_name)?.project_name
                  ? employee_list.find((m)=>m.project_name)?.project_name
                  : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              borderRightWidth: 1,
              borderRightStyle: "dotted",
              borderColor: "#626974",
            }}
          >
            <Grid container direction={"row"} justifyContent={"flex-start"}>
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "left",
                    paddingLeft: "15%",
                    paddingBottom: 5,
                  }}
                >
                  Division Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "center",
                    paddingBottom: 5,
                  }}
                >
                  :
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "400",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#fff",
                    textAlign: "center",
                    paddingBottom: 5,
                  }}
                >
                  {!!employee_list?.find((m)=>m.division_name)?.division_name ? employee_list?.find((m)=>m.division_name)?.division_name
                  : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      )} */
}
