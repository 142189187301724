import {
  Modal,
  Fade,
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { EmpMonthlySalaryProjectwise } from "../../../models/customerModel";

function EmpDetailsMonthlyPopover({
  open,
  setOpen,
  emp_salary_month_projectwise,
}: EmpDetailsMonthlyPopoverProps) {
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Project Name",
    "Month",
    "Working Hour",
    "Cost",
  ];
  console.log("emp_salary_month_projectwise",emp_salary_month_projectwise);
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Grid
            container
            style={{
              padding: 10,
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <Grid item>
              <Typography style={{ color: "#fff" }}>Month Wise Cost</Typography>
            </Grid>
          </Grid>
          <Grid container style={{ width: "100%", padding: 5 }}>
            <Grid item>
              <TableContainer sx={{}}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {columns.map((column) => (
                        <StyledTableCellS align="justify" key={column}>
                          {column}
                        </StyledTableCellS>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!emp_salary_month_projectwise &&
                      Array.isArray(emp_salary_month_projectwise) &&
                      emp_salary_month_projectwise.length > 0 &&
                      emp_salary_month_projectwise.map((row, index) => (
                        <StyledTableRowS
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCellS align="left" key={columns[0]}>
                            {row.employee_id}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                            {row.employee_name}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[2]}>
                            {row.project_name}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[3]}>
                            {moment(row.format_date).format("MMMM-YY")}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[4]}>
                            {row.work_hours}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[5]}>
                            &#8377;
                            {!!row.calculated_amount ? row.calculated_amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}
                          </StyledTableCellS>
                        </StyledTableRowS>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => setOpen(false)}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default EmpDetailsMonthlyPopover;
interface EmpDetailsMonthlyPopoverProps {
  open: boolean;
  setOpen?: any;
  emp_salary_month_projectwise: EmpMonthlySalaryProjectwise[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const StyledTableCellS: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#19A7CE",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#146C94",
  },
}));

const StyledTableRowS = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
