import React, { useEffect, useState } from "react";
import ProjectSummaryView from "./ProjectSummaryView";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  ConsolidatedReport,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import {
  ConsolidatedProjectReportAction,
  GetAllCustomerAction,
  GetAllProjectAction
} from "../../../stores/actions/customerAction";
import  Axios  from "axios";
import { baseUrl, environment } from "../../../environment";
import { useSnackbar } from "notistack";
const FileDownload = require("js-file-download");
function ProjectSummary({
  customer_list,
  GetAllCustomerAction,
  project_list,
  GetAllProjectAction,
  ConsolidatedProjectReportAction,
  consolidated_report,
  accessToken
}: ProjectSummaryProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    GetAllProjectAction({ organization_id: null, customer_id: null, all_flag: 1 });
    GetAllCustomerAction();
  }, []);
  const ViewProjectSummary = (data: any) => {
    console.log("ViewProjectSummary", data);
    ConsolidatedProjectReportAction(data);
  };
  const ReportDownload=(data: any) => {
    console.log(data);
    Axios({
      url: baseUrl + environment.urls.consolidatedReportDownload,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        project_id: data.project_id, 
        start_date: data.start_date,
        end_date: data.end_date
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          FileDownload(response.data, `ConsolidatedReport - ${data.project_id}.xlsx`);
        } else {
          enqueueSnackbar(
            !!response?.data &&
              !!JSON.parse(await response.data.text())?.message
              ? JSON.parse(await response.data.text())?.message
              : "Something went wrong!",
            {
              variant: "warning",
            }
          );
        }
      })
      .catch(async (error) => {
        enqueueSnackbar(
          !!error?.response?.data &&
            !!JSON.parse(await error.response.data.text())?.message
            ? JSON.parse(await error.response.data.text())?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  }
  return (
    <ProjectSummaryView
      customer_list={customer_list}
      project_list={project_list}
      ViewProjectSummary={ViewProjectSummary}
      consolidated_report={consolidated_report}
      ReportDownload={ReportDownload}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    consolidated_report: state.customer.consolidated_report,
    accessToken: state.user.userDetails?.accessToken,
  };
};

const mapDispatchToProps = {
  GetAllCustomerAction,
  GetAllProjectAction,
  ConsolidatedProjectReportAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSummary);
interface ProjectSummaryProps {
  customer_list: CustomerList[];
  GetAllCustomerAction?: any;
  GetAllProjectAction?: any;
  accessToken?: any
  project_list: ProjectList[];
  ConsolidatedProjectReportAction?: any;
  consolidated_report?: ConsolidatedReport;
}
