import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import OrganizationListView from "./OragnizationListView";

function OrganizationMain({
  organization_list,
  GetAllOrganizationAction,
}: OrganizationListProps) {
  React.useEffect(() => {
    GetAllOrganizationAction();
  }, []);

  return <OrganizationListView organization_list={organization_list} />;
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMain);
interface OrganizationListProps {
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
}
