import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
  withStyles,
} from "@mui/material";
import { useState } from "react";
import HRMS from "../../../assets/HRMS3.jpg";
import Des from "../../../assets/des1.jpg";
import em from "../../../assets/emdee2.png";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";

const LoginView = ({ submit, user, resetPasswordAction }: LoginViewProps) => {
  const enqueSnackbar = useSnackbar();
  const [oldPassword, setOldPassword] = useState<string>("");
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!!user && !!user.is_reset_password) {
      const newPassword = data.get("new_password");
      console.log("newPassword: ", newPassword);
      const confirmedPassword = data.get("confirm_password");
      console.log("confirmedPassword: ", confirmedPassword);
      if (newPassword !== confirmedPassword) {
        enqueSnackbar.enqueueSnackbar("confirm password not matched", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
      resetPasswordAction(
        {
          userid: data.get("userid"),
          old_password: oldPassword,
          new_password: data.get("new_password"),
        },
        enqueSnackbar
      );
    } else {
      const password = data.get("password");
      setOldPassword(password as string);
      submit({
        userid: data.get("userid"),
        password: data.get("password"),
      });
    }
  };
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      // minHeight={"100%"}
      style={{
        //  backgroundColor: "rgb(9, 53, 69)",
        //  backgroundColor: "#C5CAE9"
        //ackgroundImage: `url(${HRMS})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // backgroundPosition: "center",
        height: "100%",
        position: "absolute",
        margin: 0,
        padding: 0,
        bottom: 0,
      }}
    >
      <Grid
        item
        xs={12}
        container
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          // borderBottomColor: "#65a7bb",
          // borderWidth: 1,
          // borderStyle: "solid",
          // top: 0,
          // position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.46) 0px 12px 70px 4px",
          // flexDirection: "row",
        }}
      >
        <Grid item xs={2} className="HeaderLogoContainer">
          <img 
            // style={{
            //   // width: "220px",
            //   height: "120px",
            //   // marginLeft: "31.5%",
            //   // marginTop: "10%",
            //   // marginBottom: "2%",
            // }}
            src={require("../../../assets/MSQube_FinalLogo.jpg")}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="h6"
            align="center"
            style={{
              color: "linear-gradient(to right, #23a9d2, #3788d8)",
              // fontSize: 28,
              // marginBottom: 7,
              fontWeight: "bold",
              fontSize: "30px",
              // textAlign: "center",
              fontFamily: "Cousine, monospace",
              marginTop: "1%",
              marginBottom: "1%",
              marginLeft: 10,
            }}
          >
            {" "}
            HUMAN RESOURCE MANAGEMENT SYSTEM
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>

      <Paper sx={{ margin: 20, borderRadius: "20px" }}>
        <Grid
          container
          style={{
            width: 400,
            height: 500,
            // backgroundColor: "rgb(9, 53, 69)",
            borderRadius: "20px",
            border: "2px solid #65a7bb",
            // boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
            boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
          }}
          direction={"column"}
        >
          {/* <Box
              sx={{
                backgroundImage: `url(${Des})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "60%",
                width: "auto",
                position: "-webkit-sticky",
              }}
            ></Box> */}
          {/* <Box
              sx={{
                backgroundImage: `url(${em})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "60%",
                width: "auto",
                position: "-webkit-sticky",
              }}
            ></Box>
            <Box sx={{ marginY: 2, marginX: 1 }}>
              <Typography
                variant="h6"
                align="left"
                style={{
                  color: "#04486F",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontWeight: "800",
                }}
              >
                <b>Human Resource Management System</b>
              </Typography>
              <Typography
                // variant="h6"
                align="left"
                style={{ color: "#04486F", fontStyle: "oblique", fontSize: 12 }}
              >
                From employee data management to payroll, recruitment, benefits,
                employee engagement, and employee attendance, HRMS software
                helps HR professionals manage the modern workforce.
              </Typography>
            </Box> */}
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#ffff", borderRadius: "20px" }}
          >
            <Box
              sx={{
                mx: 5,
                dispaly: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "120px",
                  height: "120px",
                  marginLeft: "31.5%",
                  marginTop: "10%",
                  marginBottom: "2%",
                }}
                src={require("../../../assets/minimalistic-logo.png")}
              />
              {/* <img
                style={{ width: "40%", height: "50%" }}
                src={require("../../../assets/HRMS4.jpg")}
              /> */}
              <Typography
                variant="h5"
                align="center"
                style={{
                  // color: "#04486F",
                  // fontSize: 28,
                  // marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: "30px",
                  textAlign: "center",
                  fontFamily: "Cousine, monospace",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                {" "}
                {!!user && !!user.is_reset_password
                  ? `Reset Password`
                  : `SIGN IN`}
              </Typography>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <CustomTextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    label="User Name"
                    variant="outlined"
                    name="userid"
                    id="fullWidth1"
                    InputLabelProps={{
                      style: { color: "#04486F" },
                    }}
                    InputProps={{
                      style: {
                        color: "#04486F",
                      },
                      readOnly: !!user && !!user.is_reset_password,
                    }}
                    autoFocus
                    sx={{
                      // backgroundColor: "#224957",
                      borderRadius: 15,
                    }}
                  />
                </Box>
                {!user ? (
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <CustomTextField
                      margin="dense"
                      required
                      fullWidth
                      size="small"
                      label={`Password`}
                      variant="outlined"
                      name="password"
                      id="fullWidth"
                      type="password"
                      autoFocus
                      InputProps={{
                        style: {
                          color: "#04486F",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#04486F" },
                      }}
                      sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: 15,
                      }}
                    />
                  </Box>
                ) : (
                  <></>
                )}

                {!!user && !!user.is_reset_password ? (
                  <>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <CustomTextField
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        label={`New Password`}
                        variant="outlined"
                        name="new_password"
                        id="fullWidth"
                        type="password"
                        autoFocus
                        InputProps={{
                          style: {
                            color: "#04486F",
                          },
                        }}
                        InputLabelProps={{
                          style: { color: "#04486F" },
                        }}
                        sx={{
                          backgroundColor: "#ffffff",
                          borderRadius: 15,
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <CustomTextField
                        margin="dense"
                        required
                        fullWidth
                        size="small"
                        label={`Confirm Password`}
                        variant="outlined"
                        name="confirm_password"
                        id="fullWidth"
                        type="password"
                        autoFocus
                        InputProps={{
                          style: {
                            color: "#04486F",
                          },
                        }}
                        InputLabelProps={{
                          style: { color: "#04486F" },
                        }}
                        sx={{
                          backgroundColor: "#ffffff",
                          borderRadius: 15,
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    // mt: 3,
                    // mb: 2,
                    // fontWeight: "600",
                    // color: "#ffff",
                    // backgroundColor: "#02245D",
                    border: "none",
                    backgroundImage:
                      "linear-gradient(to right, #23a9d2, #3788d8)",
                    color: "white",
                    padding: "2%",
                    fontSize: "20px",
                    width: "150px",
                    marginLeft: "85px",
                    marginTop: "10%",
                  }}
                  color={"primary"}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {/* <Grid
        item
        sx={{
          width: "calc(100% - 8px)",
          // Height:"20%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflowY: "hidden",
          margin: 0,
          padding: 0,
          position: "absolute",
          bottom: 0,
        }}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={require("../../../assets/wave5.png")}
        /> 
      </Grid> */}
    </Grid>
  );
};

export default LoginView;

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: 15,
    borderColor: "gray",
  },
  "& label.Mui-focused": {
    color: "#808080",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#808080",
      color: "#808080",
    },
    "&:hover fieldset": {
      borderColor: "#808080",
    },
  },
}));
interface LoginViewProps {
  submit?: any;
  user: userDetails | undefined;
  resetPasswordAction?: any;
}
