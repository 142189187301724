import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { TimeSheetPendingApprovalList, UpdateTimingSheetStatusPayload } from "../../../models/timingSheetModel";
import { userDetails } from "../../../models/userModels";
import {
  ClearTimingSheetFromWorklistAction,
  GetTimingSheetPendingApprovalListAction,
  UpdateApproveTimingSheetStatusAction,
  UpdateMonthlyTimeSheetAction,
  goToTimingSheetFromWorklistAction,
} from "../../../stores/actions/timingSheetAction";
import TimingSheetApproveView from "./TimingSheetApproveView";

const TimingSheetApprove = ({
  GetTimingSheetPendingApprovalListAction,
  time_sheet_pending_approvalList,
  user,
  UpdateApproveTimingSheetStatusAction,
  ClearTimingSheetFromWorklistAction,
  UpdateMonthlyTimeSheetAction,
  goToTimingSheetFromWorklistAction,
}: TimingSheetApproveProps) => {
  React.useEffect(() => {
    GetTimingSheetPendingApprovalListAction({
      approverDesigId: user?.designation,
      eventType: 10,
    });
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const ApprovedAction = (payload: UpdateTimingSheetStatusPayload) => {
    let payloads = {
      data1: payload,
      data2: {
        approverDesigId: user?.designation,
        eventType: 10,
      },
      enqueueSnackbar: enqueueSnackbar,
    };
    UpdateApproveTimingSheetStatusAction(payloads);
    console.log(payloads);
  };
  console.log(time_sheet_pending_approvalList);
  return (
    <TimingSheetApproveView
      time_sheet_pending_approvalList={time_sheet_pending_approvalList}
      ApprovedAction={ApprovedAction}
      UpdateMonthlyTimeSheetAction={UpdateMonthlyTimeSheetAction}
      ClearTimingSheetFromWorklistAction={ClearTimingSheetFromWorklistAction}
      goToTimingSheetFromWorklistAction={goToTimingSheetFromWorklistAction}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    time_sheet_pending_approvalList:
      state.timingSheet.time_sheet_pending_approvalList,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  GetTimingSheetPendingApprovalListAction,
  UpdateApproveTimingSheetStatusAction,
  UpdateMonthlyTimeSheetAction,
  ClearTimingSheetFromWorklistAction,
  goToTimingSheetFromWorklistAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimingSheetApprove);

interface TimingSheetApproveProps {
  GetTimingSheetPendingApprovalListAction: any;
  time_sheet_pending_approvalList?: TimeSheetPendingApprovalList[];
  user?: userDetails;
  UpdateApproveTimingSheetStatusAction: any;
  UpdateMonthlyTimeSheetAction?: any;
  ClearTimingSheetFromWorklistAction?: any;
  goToTimingSheetFromWorklistAction?: any;
}
