import { baseUrl, environment } from "../environment";
import { IResetPassword } from "../interfaces/projectDashboard.interface";
import { loginPayload } from "../models/userModels";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});

export const LoginService = (data: loginPayload) => {
  return serviceClient.post(environment.urls.loginUrl, data);
};

export const resetPasswordService = (data: IResetPassword) => {
  return serviceClient.post(environment.urls.changePassword, data);
};
