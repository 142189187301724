import React from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomerList, ProjectList } from '../../../models/customerModel';
import { StoreState } from '../../../models/reduxModels';
import { GetAllProjectAction } from '../../../stores/actions/customerAction';
import CustomerDetailsView from './CustomerDetailsView';
import { userDetails } from '../../../models/userModels';

const CustomerDetails = ({
  customer_list,
  GetAllProjectAction,
  project_list,
  user
}: CustomerDetailsProps) => {
  const { id } = useParams();
  React.useEffect(() => {
    if(!!id){
      GetAllProjectAction({ organization_id: null, customer_id: +id});
    }
  }, []);
  console.log("project_list",project_list);
  
  return (
    <CustomerDetailsView
      customer={customer_list.find((m) => !!id && m.customer_id == +id)}
      project_list={project_list}
      user={user}
    />
  )
}

//export default CustomerDetails;
const mapStateToProps = (state: StoreState) => {
  return {
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    user: state.user.userDetails,
  }
};
const mapDispatchToProps = {
  GetAllProjectAction
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);

interface CustomerDetailsProps {
  customer_list: CustomerList[];
  project_list: ProjectList[];
  GetAllProjectAction?: any;
  user?: userDetails;
}