import { LoadingState } from "../../models/loadingModels";
import { ELeaveActionTypes } from "../actions/LeaveAction";
import { ApiStatusActionTypes } from "../actions/apiStatusActions";
import { AttendanceActionTypes } from "../actions/attendanceAction";
import { CustomerActionTypes } from "../actions/customerAction";
import { EmployeeActionTypes } from "../actions/employeeAction";
import { OrganizationActionTypes } from "../actions/organizationActions";
import { PayrollActionTypes } from "../actions/payrollActions";
import { UserActionTypes } from "../actions/userAction";
import { UserManagementActionTypes } from "../actions/userManagementAction";
import InitialState from "./initialState";
import { TimingSheetActionTypes } from "../actions/timingSheetAction";
import { ReportsActionsTypes } from "../actions/reportsAction";
import { PromotionActionTypes } from "../actions/promotionAction";
import { TerminationActionTypes } from "../actions/terminationAction";
import { InvoiceActionTypes } from "../actions/invoiceAction";

const initialState: LoadingState = InitialState.loading;

export default function LoadingReducer(
  state: LoadingState = initialState,
  action: any
) {
  switch (action.type) {
    case ApiStatusActionTypes.Begin_Api_Call:
      return {
        ...state,
        count: state.count + action.payload.count,
        message: action.payload.message,
        type: action.payload.type,
      };
    case ApiStatusActionTypes.Api_Call_Error:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserActionTypes.User_Login_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Organization_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Department_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Filter_Organization_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Department_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Service_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Employee_Timing_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Employee_Designation_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Designation_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Employee_Timing_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Employee_Type_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Employee_Type_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Organization_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Department_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Designation_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Employee_Type_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Employee_Timing_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Service_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Designation_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Employee_Timing_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Employee_Type_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Department_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Designation_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Employee_Type_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Employee_Timing_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Department_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Designation_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Employee_Type_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Employee_Timing_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Domain_Get_All_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Customer_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Customer_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Edit_Customer_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Add_Salary_Structure_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Salary_Structure_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Add_Candidate_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Salary_Structure_Edit_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };

    // POST
    case OrganizationActionTypes.Post_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Salary_Component_GetAll_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Add_Salary_Component_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Salary_Component_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Dashboard_Attendence_status_Success_action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Project_Add_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Salary_Component_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Salary_Calculation_Load_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Post_Delete_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Edit_Post_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Edit_Project_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Dashboard_Batch_Details_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Dashboard_Employee_Payroll_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Division_Get_All_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Division_Get_All_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Attendance_Dashboard_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_All_Event_Staus_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Post_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_All_Refferal_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_Employee_View_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Pending_Approval_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Uploaded_Attendance__View_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_Reporting_Manager_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Candidate_Approve_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_All_Candidate_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_Attendence_Salary_type_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_All_Employee_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_Action_Status_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Uploaded_Attendance__View_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Project_View_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Update_Attendance_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Attendance_Dashboard_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Approve_Attendance_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Payroll_Available_Employee_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Payroll_Generate_Batch_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Get_Batch_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Get_Batch_Detail_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Attendance_Approve_Status_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Get_Calculated_Salary_Componenet_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };

    case EmployeeActionTypes.Get_Employee_All_Details_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.DisCard_Employee_From_Batch_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Payroll_Componet_Update_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Update_Batch_Status_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PayrollActionTypes.Get_Batch_List_BY_DEG_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Get_All_Bank_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Attendance_Template_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Levels_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Project_Dashboard_Success_action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Delete_Employee_Attendance_Batch_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Edit_Candidate_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.All_Access_Control_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.Create_Role_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.Get_All_Role_Master_List_Control_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.Get_Role_By_ID_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.Update_Role_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.User_Role_Mapping_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case UserManagementActionTypes.Role_Assign_To_User_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Levels_For_Project_Create_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Levels_For_Project_Create_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Levels_Target_Rate_Value_For_Project_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.GET_BULK_UPLOAD_STATUS_ACTION:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Edit_Employee_Dashboard_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Candidate_Bulk_Upload_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_All_Leave_Type_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Leave_Type_By_Id_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Holiday_List_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Holiday_By_Id_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Available_Emp_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Leave_Management_List_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Designation_wise_Leave_count:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Designation_wise_leave_details:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Designation_wise_leave_details:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Get_All_Designation_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Employee_Details_By_Designation:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Allocate_Emp_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_All_Leave_Approval_List_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Leave_Amount_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Calculated_Leave_Days:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Leave_Details_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };

    case ELeaveActionTypes.Update_Approve_Leave_Status_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Project_Unbudget_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Project_Budget_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_All_Leave_List_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ELeaveActionTypes.Get_Leave_Details_For_Dashboard_Action:
    case CustomerActionTypes.Create_Budgeting_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Edit_Project_Budgeting_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TimingSheetActionTypes.Get_Timing_Sheet_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TimingSheetActionTypes.Get_Timing_Sheet_Pending_Approval_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TimingSheetActionTypes.Update_Approve_Timing_Sheet_Status_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Create_Project_Payment_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Edit_Payment_Details_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Employee_Payment_Details_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Project_Payment_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };

    case CustomerActionTypes.Get_All_State_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ReportsActionsTypes.PF_ESIC_Report_Lists_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Project_Budget_Details_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Sync_Salary_Structure_For_Employee_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Level_Master_Details_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_Location_Levels_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Attendance_Time_Sheet_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ReportsActionsTypes.PAYROLL_REPORT_LIST_SUCCESS_ACTION:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case AttendanceActionTypes.Upload_Attendance_Time_Sheet_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case OrganizationActionTypes.Salary_Component_Threshold_Parameter_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.GET_All_Employee_WITH_PDF:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TimingSheetActionTypes.Time_Sheet_History_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Promotion_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.View_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Promotion_Approval_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Initiate_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Update_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Delete_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Employee_Available_List_For_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case PromotionActionTypes.Activate_Salary_For_Promotion_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Consolidated__Project_Report_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Month_Wise_Employee_Salary_Budgeting_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Employee_Monthly_Salary_Projectwise_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TerminationActionTypes.Employee_List_Available_For_Termination_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TerminationActionTypes.Initiate_Termination_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TerminationActionTypes.Termination_Initiated_Employee_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TerminationActionTypes.Terminated_Employee_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Intiate_Project_Closure_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Available_Project_For_Closure:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Get_All_Pending_Project_Closure_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Approval_Pending_Project_Closure_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case TerminationActionTypes.Termination_Approval_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case CustomerActionTypes.Completed_Project_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case ReportsActionsTypes.Consolidated_Monthly_Report_Projectwise_SUCCESS_ACTION:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case InvoiceActionTypes.Invioce_List_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case InvoiceActionTypes.Create_Invioce_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case InvoiceActionTypes.Edit_Invioce_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    case EmployeeActionTypes.Reporting_Manager_Success_Action:
      return {
        ...state,
        count: state.count > 0 ? state.count - 1 : 0,
        type: 0,
      };
    default:
      return state;
  }
}
