import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AttandanceDetailPayload,
  AttendenceField,
  EmployeeList,
  UploadAttendanceView,
  uploadAttendanceViewForVariable,
  UploadedEmployeeAttendanceList,
} from "../../../models/attendanceModel";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteEmployeeFromAttendanceBatchAction,
  UpdateAttendanceAction,
  UploadeAttendanceViewAction,
  UploadeAttendanceViewSuccessAction,
} from "../../../stores/actions/attendanceAction";
import AttendanceDetailsView from "./AttendanceDetailsView";
import AttendanceDetailsVariableView from "./AttendanceDetailsVariable";
import { useSnackbar } from "notistack";
import DataGrids from "./DataGrids";
import AttendanceDataGrid from "./AttendanceDataGrid";
import { useForm } from "react-hook-form";
import { userDetails } from "../../../models/userModels";

const AttendanceDetails = ({
  // upload_attendance_view,
  UploadeAttendanceViewAction,
  employee_list,
  attendance_payload,
  UpdateAttendanceAction,
  total_count,
  UploadeAttendanceViewSuccessAction,
  attendence_fields,
  DeleteEmployeeFromAttendanceBatchAction,
  user,
}: AttendanceDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  // const [editable, setEditable] = useState<number>()
  console.log(attendance_payload);

  const { id } = useParams();
  useEffect(() => {
    if (!!attendance_payload) {
      UploadeAttendanceViewAction({
        organization_id: attendance_payload.organization_id,
        employee_type_id: attendance_payload.employee_type_id,
        project_id: attendance_payload.project_id,
        attendence_salary_type_id: attendance_payload.attendence_salary_type_id,
        project_attendence_id: attendance_payload.project_attendence_id,
        division_id: attendance_payload.division_id,
        attendence_status: attendance_payload.attendence_status,
        month: attendance_payload.month,
        page_size: pagesize,
        page_index: pageindex,
      });
    }
  }, [pageindex, pagesize]);
  const updateAttendanceExcel = (data: any) => {
    if (!!attendance_payload) {
      UpdateAttendanceAction({
        update_data: {
          project_attendence_id: data.project_attendence_id,
          employee_attendance_id: data.employee_attendance_id,
          employee_id: data.employee_id,
          attendence_status: data.attendence_status,
          present_days: +data.present_days,
          extra_duty_days: +data.extra_duty_days,
          over_time_hour: +data.over_time_hour,
          absent_days: +data.absent_days,
          spot_bill: +data.spot_bill,
          manual: +data.manual,
          monthly: +data.monthly,
          pl: +data.pl,
          wrong_image: +data.wrong_image,
          cash_collection: +data.cash_collection,
          cchouse_count: +data.cchouse_count,
          manual_deduction: +data.manual_deduction,
          extra_benefits: +data.extra_benefits,
          remarks: data.remarks,
          total_days: +data.total_days,
          target_per_day: +data.target_per_day,
          rate_per_reading: +data.rate_per_reading,
          monthly_target: +data.monthly_target,
          canteen_allowance: +data.canteen_allowance,
          earn_leave_allowance: +data.earn_leave_allowance,
          steering_duty: +data.steering_duty,
          meter_reading: +data.meter_reading,
          island: +data.island,
          hill: +data.hill,
          tg: +data.tg,
          industrial: +data.industrial,
          illegible_image: +data.illegible_image,
          regeneration: +data.regeneration,
        },
        list_data: {
          organization_id: attendance_payload.organization_id,
          employee_type_id: attendance_payload.employee_type_id,
          project_id: attendance_payload.project_id,
          attendence_salary_type_id:
            attendance_payload.attendence_salary_type_id,
          project_attendence_id: attendance_payload.project_attendence_id,
          division_id: attendance_payload.division_id,
          attendence_status: attendance_payload.attendence_status,
          month: attendance_payload.month,
          page_size: pagesize,
          page_index: pageindex,
        },
        enqueueSnackbar: enqueueSnackbar,
      });
    }
  };
  // console.log(id);
  const DeleteAction = (props: number) => {
    DeleteEmployeeFromAttendanceBatchAction({
      data: props,
      list_data: {
        organization_id: attendance_payload?.organization_id,
        employee_type_id: attendance_payload?.employee_type_id,
        project_id: attendance_payload?.project_id,
        attendence_salary_type_id:
          attendance_payload?.attendence_salary_type_id,
        project_attendence_id: attendance_payload?.project_attendence_id,
        division_id: attendance_payload?.division_id,
        page_size: pagesize,
        page_index: pageindex,
      },
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    // <AttendanceDetailsView upload_attendance_view={upload_attendance_view} />

    // <AttendanceDetailsVariableView
    // employee_list={employee_list}
    // attendance_payload={attendance_payload}
    // updateAttendanceExcel={updateAttendanceExcel}
    // />
    <AttendanceDataGrid
      employee_list={employee_list}
      attendance_payload={attendance_payload}
      updateAttendanceExcel={updateAttendanceExcel}
      id={id}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_count={total_count}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      attendence_fields={attendence_fields}
      DeleteAction={DeleteAction}
      user={user}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    // upload_attendance_view: state.attendance.upload_attendance_view,
    employee_list: state.attendance.employee_list,
    attendance_payload: state.attendance.attendance_payload,
    total_count: state.attendance.total_count,
    attendence_fields: state.attendance.attendence_fields,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  UploadeAttendanceViewAction,
  UpdateAttendanceAction,
  UploadeAttendanceViewSuccessAction,
  DeleteEmployeeFromAttendanceBatchAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDetails);

interface AttendanceDetailsProps {
  UploadeAttendanceViewAction?: any;
  employee_list: any[];
  attendance_payload?: AttandanceDetailPayload;
  UpdateAttendanceAction?: any;
  total_count?: number;
  UploadeAttendanceViewSuccessAction?: any;
  attendence_fields: AttendenceField[];
  DeleteEmployeeFromAttendanceBatchAction?: any;
  user?: userDetails;
}
