import { FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  ApproveRejectPayload,
  EventList,
  ReportingManagerList,
} from "../../../models/employeeModel";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import TaskAssignment from "./TaskAssignment";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { environment } from "../../../environment";
function OnboardingRequestAppRejView({
  event_list,
  GetAllSalaryStructureAction,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  getReportingManagerList,
  ReportingManagerList,
  ApprovedAction,
  filter,
  Filterdata,
  GetAttendenceSalarytypeAction,
  attendence_salary_type,
}: OnboardingRequestAppRejViewProps) {
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<EventList[]>([]);
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const [approvalList, SetapprovalList] = useState<EventList[]>([]);
  const [open, setOpen] = React.useState(false);
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);
  const [submit_payload, SetsubmitPayload] = useState<any>();
  const [filter_sel, Setfilter] = useState<string | undefined>(undefined);
  const [approve_type, SetapprovedType] = useState<number | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const columns: any[] = [
    "Enrollment ID",
    "Candidate Name",
    "Mobile No.",
    "Project",
    "Employee Type",
    "Designation",
    "Initiated By",
    "Initiated On",
    "Action Needed",
    "Status",
    "Action",
  ];
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!event_list
      ? event_list.filter((row: EventList) => {
          return (
            row?.candidate_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.enrolment_id
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_type_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.designation_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.eventStatus
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.initiatedBy?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!event_list) {
      cancelSearch();
    }
  }, [event_list]);
  useEffect(() => {
    Filterdata(filter_sel);
  }, [filter_sel]);
  const SelectRow = (checked: boolean, row: EventList) => {
    if (checked) {
      if (
        approvalList.length > 0 &&
        JSON.stringify(
          approvalList[approvalList.length - 1].event_action_items
        ) != JSON.stringify(row.event_action_items)
      ) {
        enqueueSnackbar(`Need to select same type action.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (approvalList.length >= 100) {
        enqueueSnackbar(`max limit 100 exceed`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (
        approvalList.length > 0 &&
        approvalList[approvalList.length - 1].project_name != row.project_name
      ) {
        enqueueSnackbar(`Mutiselect is only allow for same type of project.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        let events: EventList[] = JSON.parse(JSON.stringify(approvalList));
        events.push(row);
        SetapprovalList(events);
      }
    } else {
      let events: EventList[] = JSON.parse(JSON.stringify(approvalList));
      events = events.filter((m) => m.empLifeEventId != row.empLifeEventId);
      if (events.length == 0) Set_Avpprove(false);
      SetapprovalList(events);
    }
  };
  useEffect(() => {
    const AllSelection = async () => {
      if (all_approve) {
        let appr: EventList[] = [];
        for await (const [index, ap] of rows.entries()) {
          if (index == 0) {
            appr.push(ap);
          } else {
            if (
              JSON.stringify(appr[appr.length - 1].event_action_items) ==
                JSON.stringify(ap.event_action_items) &&
              appr[appr.length - 1].project_name == ap.project_name
            ) {
              appr.push(ap);
            }
          }
          if (appr.length >= 100) {
            break;
          }
        }
        SetapprovalList(appr);
        enqueueSnackbar(
          `${
            appr.length > 100 ? "100" : appr.length
          } same type of action row selected`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        SetapprovalList([]);
      }
    };
    AllSelection();
  }, [all_approve]);
  const AcceptCandidate = (m: number) => {
    setOpen(true);
    SetapprovedType(m);
  };
  const DomValue = (st: string) => {
    return environment.event_action_items.find((m) => m.key == st)?.value;
  };
  return (
    <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Dialog
          open={submit_flag}
          onClose={() => setsubmitFlag(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Approval"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want {approve_type == 1 ? "approve" : "reject"}{" "}
              those candidate?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setsubmitFlag(false)}>
              No
            </Button>
            <Button
              autoFocus
              onClick={() => (
                ApprovedAction(submit_payload, filter_sel),
                setOpen(false),
                setsubmitFlag(false),
                SetapprovalList([])
              )}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <TaskAssignment
          approvalList={approvalList}
          open={open}
          setOpen={setOpen}
          SetapprovalList={SetapprovalList}
          GetAllSalaryStructureAction={GetAllSalaryStructureAction}
          salary_structure_list={salary_structure_list}
          SalaryComponentEditAction={SalaryComponentEditAction}
          salary_structure={salary_structure}
          DomValue={DomValue}
          getReportingManagerList={getReportingManagerList}
          ReportingManagerList={ReportingManagerList}
          GetAttendenceSalarytypeAction={GetAttendenceSalarytypeAction}
          ApprovedAction={(data: ApproveRejectPayload) => (
            SetsubmitPayload(data), setsubmitFlag(true)
          )}
          attendence_salary_type={attendence_salary_type}
          approve_type={approve_type}
        />
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={12} marginTop={2}>
          <Grid container alignItems={"center"}>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: "bold" }}>Filter</Typography>
            </Grid>
            <Grid item style={{}}>
              <Button
                size="small"
                // variant={!filter_sel ? "contained" : "outlined"}
                style={
                  !filter_sel
                    ? {
                        backgroundColor: "rgb(19, 36, 55)",
                        color: "white",
                        border: "1px solid rgb(19, 36, 55)",
                        marginLeft: "5px"
                      }
                    : {
                        color: "rgb(19, 36, 55)",
                        backgroundColor: "white",
                        border: "1px solid rgb(19, 36, 55)",
                        marginLeft: "5px"
                      }
                }
                // style={{ marginLeft: 5 }}
                onClick={() => Setfilter(undefined)}
              >
                All
              </Button>
            </Grid>
            {filter.map((item) => (
              <Grid item style={{}}>
                {!!JSON.parse(item) &&
                  ((JSON.parse(item).find((m: any) => m == "user_creation") &&
                    JSON.parse(item).length > 1) ||
                    !JSON.parse(item).find(
                      (m: any) => m == "user_creation"
                    )) && (
                    <Button
                      size="small"
                      // variant={filter_sel == item ? "contained" : "outlined"}
                      style={
                        filter_sel == item
                          ? {
                              backgroundColor: "rgb(19, 36, 55)",
                              color: "white",
                              border: "1px solid rgb(19, 36, 55)",
                              marginLeft: "5px"
                            }
                          : {
                              color: "rgb(19, 36, 55)",
                              backgroundColor: "white",
                              border: "1px solid rgb(19, 36, 55)",
                              marginLeft: "5px"
                            }
                      }
                      // style={{ marginLeft: 5 }}
                      onClick={() => Setfilter(item)}
                    >
                      {JSON.parse(item).map(
                        (m: any, index: number) =>
                          m != "user_creation" &&
                          JSON.parse(item).indexOf(m) === index &&
                          DomValue(m) + ", "
                      )}
                    </Button>
                  )}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              //   style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell align="justify" key={""}>
                    <Checkbox
                      style={{ color: "#ffff" }}
                      checked={all_approve}
                      onChange={(event) => Set_Avpprove(event.target.checked)}
                    />
                  </StyledTableCell>
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows.length > 0 &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.empLifeEventId}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          <Checkbox
                            color="primary"
                            checked={
                              !!approvalList.find(
                                (m) => m.empLifeEventId == row.empLifeEventId
                              )
                            }
                            onChange={(event) =>
                              SelectRow(event.target.checked, row)
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.empolyeeId}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          {row.candidate_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {row.mobile_no}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          <Tooltip
                            title={
                              row.level1_name !== null
                                ? row.level2_name !== null
                                  ? row.level3_name !== null
                                    ? row.level4_name !== null
                                      ? row.level5_name !== null
                                        ? row.level1_name +
                                          ">>" +
                                          row.level2_name +
                                          ">>" +
                                          row.level3_name +
                                          ">>" +
                                          row.level4_name +
                                          ">>" +
                                          row.level5_name
                                        : row.level1_name +
                                          ">>" +
                                          row.level2_name +
                                          ">>" +
                                          row.level3_name +
                                          ">>" +
                                          row.level4_name
                                      : row.level1_name +
                                        ">>" +
                                        row.level2_name +
                                        ">>" +
                                        row.level3_name
                                    : row.level1_name + ">>" + row.level2_name
                                  : row.level1_name
                                : ""
                            }
                            followCursor
                          >
                            <Box sx={{ p: 0 }}>
                              {row.project_name !=
                              environment.global_veriable.ho_project_name
                                ? row.project_name
                                : ""}
                            </Box>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[5]}>
                          {row.employee_type_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[6]}>
                          {row.designation_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[7]}>
                          <Link
                            onClick={() =>
                              navigate(
                                `/employment/manage-profile/${row.initiatedBy}`
                              )
                            }
                          >
                            {row.initiatedBy}
                          </Link>
                          <br/>
                          ({row.initiated_employee_name})
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[8]}>
                          {moment
                            .utc(row.initiatedOn)
                            .format("DD-MM-yyyy, h:mm:ss a")}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[9]}>
                          {row.event_action_items
                            .filter(
                              (item, ind) =>
                                row.event_action_items.indexOf(item) === ind
                            )
                            .map(
                              (m, index) =>
                                DomValue(m) + (!!DomValue(m) ? ", " : "")
                            )}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[10]}>
                          {row.eventStatus}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[11]}>
                          <Tooltip
                            title="View"
                            onClick={() =>
                              navigate(
                                `/employment/candidate_details/${row.enrolment_id}/1`
                              )
                            }
                          >
                            <IconButton>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        <Button
          sx={{
            color: "#fff",
            paddingX: 4,
            marginRight: 10,
          }}
          disabled={approvalList.length <= 0}
          onClick={() => AcceptCandidate(0)}
          variant="contained"
          color="error"
          size="large"
        >
          Reject
        </Button>
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#1565C0",
            paddingX: 4,
            marginRight: 10,
          }}
          disabled={approvalList.length <= 0}
          onClick={() => AcceptCandidate(1)}
          variant="contained"
          color="primary"
          size="large"
        >
          Approve
        </Button>
      </Grid>
    </Paper>
  );
}

export default OnboardingRequestAppRejView;

interface OnboardingRequestAppRejViewProps {
  event_list: EventList[];
  GetAllSalaryStructureAction?: any;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  getReportingManagerList?: any;
  ReportingManagerList: ReportingManagerList[];
  ApprovedAction?: any;
  filter: string[];
  Filterdata?: any;
  GetAttendenceSalarytypeAction?: any;
  attendence_salary_type: DomainList[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
