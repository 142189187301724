import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { blue } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentsIcon from "@mui/icons-material/Payments";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
const CustomerDetailsView = ({
  customer,
  project_list,
  user,
}: CustomerDetailsViewProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const { id } = useParams();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<ProjectList[]>([]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!project_list
      ? project_list.filter((row: ProjectList) => {
          return row?.project_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!project_list) {
      cancelSearch();
    }
  }, [project_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Project Name",
    "Work Order No.",
    "Created On",
    "Action",
  ];
  return (
    <Paper
    elevation={5}
      sx={{
        marginX: 5,
        marginTop: 3,
        marginBottom: 1.4,
        padding: 2,
        overflow: "hidden",
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        padding={0}
      >
        <Grid
          container
          style={{ padding: 0, marginTop: 2, marginBottom: 2 }}
          justifyContent={"start"}
          width={"90%"}
          alignItems={"start"}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
            //onClick={() => navigate(`/customer/project-main/`)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              textAlign: "center",
              paddingLeft: "35%",
            }}
          >
            Customer Details
          </Typography>
          <Divider
            style={{
              borderColor: "#9E9E9E",
              opacity: 0.7,
              width: "100%",
              marginTop: "10px",
            }}
          ></Divider>
        </Grid>

        {!!customer &&
          !!user &&
          environment.global_veriable.officialEmployee.includes(
            user.designation
          ) && (
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              style={{ marginTop: 2, marginBottom: 2 }}
              paddingRight={8}
            >
              <Button
                variant="outlined"
                component="label"
                color="primary"
                style={{
                  paddingLeft: 10,
                  paddingTop: 0,
                  paddingRight: 10,
                  paddingBottom: 0,
                }}
                startIcon={<EditIcon />}
                onClick={() =>
                  navigate(`/customer/add-customer/${customer?.customer_id}`)
                }
              >
                <IconButton size="small" style={{ padding: 0 }}>
                  <Typography style={{ color: "#A2A2A2", fontSize: "10" }}>
                    Edit
                  </Typography>
                </IconButton>
              </Button>
            </Grid>
          )}
        <Grid
          container
          direction={"row"}
          padding={2}
          justifyContent={"center"}
          alignItems={"center"}
          paddingLeft={"6%"}
          marginBottom={"-2%"}
          // marginTop={"-3%"}
        >
          <Grid
            container
            direction={"column"}
            alignItems={"start"}
            paddingTop={0}
          >
            <Grid container direction={"row"} margin={1}>
              <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Organization Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.organization_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Customer Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.customer_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction={"row"} margin={1}>
              {/* <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Customer Type
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    {" "}
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.customer_type}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      GST No
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.gst_no}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Address
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.address_line1}{" "}
                      {!!customer?.address_line2 ? "," : " "}
                      {customer?.address_line2},{customer?.city},
                      {customer?.district},{customer?.state},{customer?.country}
                      ,{customer?.pin}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction={"row"} margin={1}>
              <Grid item xs={5.5}>
                <Grid container direction={"row"} wrap={"nowrap"}>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      {customer?.customer_desc}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginTop: "1%",
          marginLeft: "5%",
        }}
      ></Divider>
      <Grid
        container
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
        //paddingTop="5"
        marginTop={2}
        paddingLeft={"5%"}
      >
        <Grid item xs={3}>
          {!!user &&
            environment.global_veriable.officialEmployee.includes(
              user.designation
            ) && (
              <Button
                onClick={() =>
                  //navigate(`/customer/add-project/${customer?.customer_id}`)
                  navigate(`/customer/add-project/0/${customer?.customer_id}`)
                }
                size="medium"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            )}
        </Grid>
        <Grid item xs={7}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            //value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            // InputProps={{
            //   endAdornment:
            //     !!searched && searched.length > 0 ? (
            //       <IconButton color="primary" onClick={() => cancelSearch()}>
            //         <CloseIcon />
            //       </IconButton>
            //     ) : (
            //       <IconButton color="primary">
            //         <SearchIcon />
            //       </IconButton>
            //     ),
            // }}
          />
        </Grid>
      </Grid>
      <Grid marginTop={3} paddingLeft={"5%"} paddingRight={"5%"}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            //   style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.project_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.project_name}
                        {/* Emdee */}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.work_order_no}
                        {/* IT */}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.created_on
                          ? moment(row.created_on)
                              .format("DD/MM/YYYY HH:mm:ss a")
                              .toUpperCase()
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        <Tooltip
                          title="View"
                          onClick={() =>
                            navigate(
                              `/customer/project-dashboard/${row.project_id}`
                              //`/customer/customer-details/${row.customer_id}`
                            )
                          }
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Edit"
                          onClick={() =>
                            navigate(
                              `/customer/add-project/${row.project_id}/${row.customer_id}`
                              //`/customer/customer-details/${row.customer_id}`
                            )
                          }
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {!!row.project_forecast_id && (
                          <>
                            <Tooltip
                              title="Payment Detials"
                              onClick={() =>
                                navigate(
                                  `/customer/payment-details-main/${row.project_id}`
                                )
                              }
                            >
                              <IconButton>
                                <PaymentsIcon />
                              </IconButton>
                            </Tooltip>
                            {!!user &&
                              environment.global_veriable.officialEmployee.includes(
                                user.designation
                              ) && (
                                <Tooltip
                                  title="Budget Dashboard"
                                  onClick={() =>
                                    navigate(
                                      `/customer/budget-dashboard/${row.project_id}`
                                    )
                                  }
                                >
                                  <IconButton>
                                    <DashboardIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              {!!rows && rows.length <= 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <div style={{ color: "red", textAlign: "center" }}>
                      No Data Found{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={project_list?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Paper>
  );
};

export default CustomerDetailsView;
interface CustomerDetailsViewProps {
  customer?: CustomerList;
  project_list: ProjectList[];
  user?: userDetails;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
