import { Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";

const SalaryDafination = ({
  control,
  errors,
  GetAllSalaryStructureAction,
  org_id,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  watch,
  reset,
  GetAttendenceSalarytypeAction,
  attendence_salary_type,
  setValues,
}: SalaryDafinationProps) => {
  useEffect(() => {
    GetAllSalaryStructureAction({ organization_id: org_id });
    GetAttendenceSalarytypeAction();
  }, []);
  useEffect(() => {
    if (!!salary_structure && salary_structure.component.length > 0) {
      salary_structure.component.forEach((m) => {
        if (!m.calculation) {
          setValues(m.compent_id.toString(), m.default_value);
        }
      });
    }
  }, [salary_structure]);
  return (
    <Paper
      sx={{
        flexGrow: 1,
        borderRadius: 1,
        border: "1px solid #E0E0E0",
        margin: 2,
      }}
    >
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
      >
        <Grid item xs={5} justifyContent="space-between" alignItems="start">
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            Select Salary Structure
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={5.5} style={{ marginLeft: -5 }}>
          <Controller
            control={control}
            name={"salary_structure_id"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <Autocompletes
                hasInfo={false}
                option={salary_structure_list}
                // readOnly={!!department ? edit_flag : !edit_flag}
                keys={"salary_structure_id"}
                label={"salary_stucture_name"}
                onBlur={onBlur}
                onChange={(value: any) => (
                  onChange(value),
                  !!value &&
                    SalaryComponentEditAction({ salary_structure_id: +value })
                )}
                placeholder={"Salary structure"}
                value={!!value ? value : ""}
                errors={errors.salary_structure_id}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
      >
        <Grid item xs={5} justifyContent="space-between" alignItems="start">
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            Salary Type
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={5.5} style={{ marginLeft: -5 }}>
          <Controller
            control={control}
            name={"attendence_salary_type_id"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <Autocompletes
                hasInfo={false}
                option={attendence_salary_type}
                // readOnly={!!department ? edit_flag : !edit_flag}
                keys={"domain_value"}
                label={"domain_key"}
                onBlur={onBlur}
                onChange={(value: any) => onChange(value)}
                placeholder={"Salary Type"}
                value={!!value ? value : ""}
                errors={errors.attendence_salary_type_id}
              />
            )}
          />
        </Grid>
      </Grid> */}
      {!!salary_structure &&
        salary_structure.component.length > 0 &&
        salary_structure.component.map(
          (item, index) =>
            !item.calculation &&
            !!watch("salary_structure_id") && (
              <Grid
                key={item.compent_id}
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    {item.component_name}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={item.compent_id.toString()}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={item.component_name}
                        error={!!errors[item.compent_id.toString()]}
                        helperText={
                          errors[item.compent_id.toString()] &&
                          `* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )
        )}
    </Paper>
  );
};

export default SalaryDafination;
interface SalaryDafinationProps {
  control?: any;
  errors?: any;
  GetAllSalaryStructureAction?: any;
  org_id?: number;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  watch?: any;
  reset?: any;
  GetAttendenceSalarytypeAction?: any;
  attendence_salary_type: DomainList[];
  setValues?: any;
}
