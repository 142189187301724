import { Grid, Typography, TextField, Divider, Checkbox, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { StateList } from "../../../models/customerModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddressDetails = ({
  control,
  errors,
  getValues,
  setValue,
  state_list,
}: AddressDetailsProps) => {
  const countryOptions: any[] = [
    {
      key: "India",
      value: "India",
    },
  ];
  const [checked, setChecked] = useState(false);
  const sameAs = (flag: boolean) => {
    if (flag) {
      setValue("paddress_line1", getValues("address_line1"));
      setValue("paddress_line2", getValues("address_line2"));
      setValue("pcity", getValues("city"));
      setValue("pdistrict", getValues("district"));
      setValue("pstate", getValues("state"));
      setValue("pcountry", getValues("country"));
      setValue("ppin", getValues("pin"));
    } else {
      setValue("paddress_line1", "");
      setValue("paddress_line2", "");
      setValue("pcity", "");
      setValue("pdistrict", "");
      setValue("pstate", "");
      setValue("pcountry", "");
      setValue("ppin", "");
    }
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%",
                // marginBottom: 20,
              }}
            >
              Address Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={10} marginTop={1} marginBottom={4}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 17,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "8%",
                marginBottom: 20,
                color: "#626974",
              }}
            >
              Present Address
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Flat, House No. 
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"address_line1"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Enter Flat, House No., Building, Apartment"}
                    error={!!errors["address_line1"]}
                    inputProps={{ maxLength: 100 }}
                    helperText={
                      errors.address_line1 &&
                      `${errors.address_line1.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Area, Street, Sector
                {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
              </Typography>
            </Grid>
            <Grid
              item
              xs={7.5}
              /*style={{ marginLeft: -5 }}*/ style={{ marginLeft: 5 }}
            >
              <Controller
                control={control}
                name={"address_line2"}
                defaultValue={""}
                rules={{ required: false }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    inputProps={{ maxLength: 100 }}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Enter Area, Street, Sector, Village"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid
              container
              justifyContent="start"
              alignItems="center"

            // width={"100%"}
            >
              <Grid item xs={3.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "24%",
                    // textAlign: "center"
                  }}
                >
                  City
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={7.5}
                /*style={{ marginLeft: -5 }}*/ style={{ marginLeft: 5 }}
              >
                <Controller
                  control={control}
                  name={"city"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "101.3%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{ maxLength: 40 }}
                      placeholder={"Enter City"}
                      error={!!errors["city"]}
                      helperText={
                        errors.city &&
                        `${errors.city.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"

          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",

                  // textAlign: "center"
                }}
              >
                District
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"district"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Enter District"}
                    inputProps={{ maxLength: 40 }}
                    error={!!errors["district"]}
                    helperText={
                      errors.district &&
                      `${errors.district.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid container justifyContent="start" alignItems="center">
              <Grid item xs={3.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "24%",
                    // textAlign: "center"
                  }}
                >
                  State
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={7.5} style={{ marginRight: 5 }}>
                {/* <Controller
            control={control}
            name={"state"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"State"}
                error={!!errors["state"]}
                helperText={
                  errors.state &&
                  `${errors.state.message}* This field is Required`
                }
              />
            )}
          /> */}
                <Controller
                  control={control}
                  name={"state"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={state_list}
                      style={{ width: "101.3%", marginRight: 5 }}
                      keys={"state_name"}
                      label={"state_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"State"}
                      value={!!value ? value : ""}
                      errors={!!errors["state"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Country
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5}>
              <Controller
                control={control}
                name={"country"}
                defaultValue={"India"}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={countryOptions}
                    keys={"key"}
                    label={"value"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"Country"}
                    value={!!value ? value : ""}
                    errors={errors.country}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                PIN
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"pin"}
                defaultValue={""}
                rules={{ required: true, minLength: 6, maxLength: 6 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    placeholder={"Enter the PIN code"}
                    error={!!errors["pin"]}
                    helperText={
                      errors.pin &&
                      `${errors.pin.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 17,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "8%",
                // marginBottom: 20,
                color: "#626974",
              }}
            >
              Permanent Address
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={5}></Grid>
            <Grid item xs={5.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                }}
              >
                Same as present address{" "}
                <Checkbox
                  checked={checked}
                  onChange={(event) => (
                    setChecked(event.target.checked),
                    sameAs(event.target.checked)
                  )}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Flat, House No.
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"paddress_line1"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{ maxLength: 100 }}
                    value={value}
                    placeholder={"Enter Flat, House No., Building, Apartment"}
                    error={!!errors["paddress_line1"]}
                    helperText={
                      errors.paddress_line1 &&
                      `${errors.paddress_line1.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Area, Street, Sector
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"paddress_line2"}
                defaultValue={""}
                rules={{ required: false }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    inputProps={{ maxLength: 100 }}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Enter Area, Street, Sector, Village"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                City
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"pcity"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{ maxLength: 40 }}
                    value={value}
                    placeholder={"Enter City"}
                    error={!!errors["pcity"]}
                    helperText={
                      errors.pcity &&
                      `${errors.pcity.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                District
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"pdistrict"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    inputProps={{ maxLength: 40 }}
                    onChange={onChange}
                    value={value}
                    placeholder={"Enter District"}
                    error={!!errors["pdistrict"]}
                    helperText={
                      errors.pdistrict &&
                      `${errors.pdistrict.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid
              item
              xs={3.5}
              justifyContent="space-between"
              alignItems="start"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                State
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5}>
              {/* <Controller
            control={control}
            name={"pstate"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"State"}
                error={!!errors["state"]}
                helperText={
                  errors.state &&
                  `${errors.state.message}* This field is Required`
                }
              />
            )}
          /> */}
              <Controller
                control={control}
                name={"pstate"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={state_list}
                    style={{ width: "101.3%" }}
                    keys={"state_name"}
                    label={"state_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"State"}
                    value={!!value ? value : ""}
                    errors={!!errors["pstate"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Country
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5}>
              <Controller
                control={control}
                name={"pcountry"}
                defaultValue={"India"}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={countryOptions}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"Country"}
                    value={!!value ? value : ""}
                    errors={errors.pcountry}
                    style={{ width: "101.3%" }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
          // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                PIN
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"ppin"}
                defaultValue={""}
                rules={{ required: true, minLength: 6, maxLength: 6 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    //InputProps={{ inputProps: { min: 100000, max: 999999 } }}
                    placeholder={"Enter PIN"}
                    error={!!errors["ppin"]}
                    helperText={
                      errors.ppin &&
                      `${errors.ppin.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
      <Divider orientation="horizontal" variant="middle" flexItem />
    </>
  );
};

export default AddressDetails;
interface AddressDetailsProps {
  errors?: any;
  control?: any;
  setValue?: any;
  getValues?: any;
  state_list: StateList[];
}
