import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import TimeSheetHistoryView from "./TimeSheetHistoryView";
import {
  TimingSheetHistoryAction,
  goToTimingSheetFromWorklistAction,
} from "../../../stores/actions/timingSheetAction";
import { TimeSheetHistoryList } from "../../../models/timingSheetModel";

export const TimeSheetHistory = ({
  TimingSheetHistoryAction,
  timesheet_history,
  goToTimingSheetFromWorklistAction,
}: TimeSheetHistoryProps) => {
  const Submit = (data: any) => {
    // console.log(data.year, data.month);
    TimingSheetHistoryAction({
      year: data.year,
      month: data.month,
    });
  };
  console.log(timesheet_history);

  return (
    <TimeSheetHistoryView
      Submit={Submit}
      timesheet_history={timesheet_history}
      goToTimingSheetFromWorklistAction={goToTimingSheetFromWorklistAction}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    timesheet_history: state.timingSheet.timesheet_history,
  };
};

const mapDispatchToProps = {
  TimingSheetHistoryAction,
  goToTimingSheetFromWorklistAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetHistory);
interface TimeSheetHistoryProps {
  TimingSheetHistoryAction?: any;
  timesheet_history: TimeSheetHistoryList[];
  goToTimingSheetFromWorklistAction?: any;
}
