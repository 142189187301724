import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { InvoiceListResponse } from "../../../models/invoiceModel";

function PaymentDetailsByInvoiceView({
  project_payment_list,
  project_list,
  id2,
  id,
  invoice_list_details,
}: PaymentDetailsByInvoiceViewProps) {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = useState<ProjectPaymentList[]>([]);
  const styles = {
    marginRight: "50px",
  };
  console.log('rows', rows);
  
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!project_payment_list
      ? project_payment_list.filter((row: ProjectPaymentList) => {
          return (
            row?.payment_desc
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) 
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!project_payment_list) {
      cancelSearch();
    }
  }, [project_payment_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Payment Date",
    "Description",
    "Payment Amount(With GST)",
    "Payment Amount(Without GST)",
    "Created On",
  ];
  // console.log(project_payment_list);

  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={12} paddingBottom={2}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
              color: "#626974",
            }}
          >
            Payment Information of{" "}
            {!!invoice_list_details?.invoice_list &&
              invoice_list_details?.invoice_list.length > 0 &&
              invoice_list_details?.invoice_list.find(
                (m) => m.project_payment_invoice_id === +id2
              )?.invoice_no}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
            //onClick={() => navigate(`/customer/customer-main/`)}
            // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>
      </Grid>
      <Grid marginTop={2}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.project_payment_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {moment(row.payment_date).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.payment_desc}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        <span
                          style={{
                            fontFamily: "Arial",
                            // fontWeight: "bold",
                          }}
                        >
                          &#8377; {row.project_payment_with_gst}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        <span
                          style={{
                            fontFamily: "Arial",
                            // fontWeight: "bold",
                          }}
                        >
                          &#8377; {row.project_payment_without_gst}
                        </span>
                      </StyledTableCell>

                      <StyledTableCell align="left" key={columns[4]}>
                        {row.created_on
                          ? moment(row.created_on)
                              .format("DD/MM/YYYY")
                              .toUpperCase()
                          : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              {!!rows && rows.length <= 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <div style={{ color: "red", textAlign: "center" }}>
                      No Data Found{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[50, 100, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Paper>
  );
}

export default PaymentDetailsByInvoiceView;
interface PaymentDetailsByInvoiceViewProps {
  project_payment_list: ProjectPaymentList[];
  project_list: ProjectList[];
  id?: any;
  id2?: any;
  invoice_list_details?: InvoiceListResponse;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
