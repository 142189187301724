import { Grid, Typography, TextField, Button } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";

function AdditonalFieldUploadSection({
  item,
  control,
  errors,
  downloadImage,
  AddlfileHandleEvent,
  getValues,
  watch,
  setValue,
  edit_candidate,
  edit_flag,
  id2,
  user,
}: AdditonalFieldUploadSectionProps) {
  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      style={{ marginTop: 15, marginBottom: 30 }}
    >
      <Grid item xs={5} justifyContent="space-between" alignItems="start">
        <Typography
          style={{
            fontWeight: "500",
            fontSize: 16,
            fontFamily: "Poppins, sans-serif",
            color: "#626974",
            paddingLeft: "15%",
          }}
        >
          {item.field_name}
          {!!item.isMandatory && (
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          )}
        </Typography>
      </Grid>
      <Grid item xs={3.2} style={{ marginLeft: -5 }}>
        <Controller
          control={control}
          name={item.field_name.split(" ").join("_").replace("'", "")}
          defaultValue={""}
          rules={{
            required: item.isMandatory == 1 ? true : false,
          }}
          render={({ field: { onBlur, value, onChange } }) =>
            item.type == "Option" ? (
              <Autocompletes
                hasInfo={false}
                option={item.option.map((m: any) => ({
                  key: m,
                  value: m,
                }))}
                keys={"key"}
                label={"value"}
                onBlur={onBlur}
                onChange={(value: any) => onChange(value)}
                placeholder={item.field_name}
                value={!!value ? value : ""}
                errors={
                  errors[item.field_name.split(" ").join("_").replace("'", "")]
                }
              />
            ) : (
              <TextField
                type={item.Number ? "number" : "text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                inputProps={{
                  readOnly:
                    !!id2 && +id2 == 1
                      ? false
                      : !!user &&
                        environment.global_veriable.superAdminLevel.includes(
                          user.designation
                        )
                      ? edit_flag
                      : !edit_candidate,
                }}
                value={value}
                placeholder={item.field_name}
                error={
                  errors[item.field_name.split(" ").join("_").replace("'", "")]
                }
              />
            )
          }
        />
      </Grid>
      {!!item.uploadRequired &&
        (!watch(
          item.field_name.split(" ").join("_").replace("'", "") + "_file"
        ) ? (
          <Grid item xs={3} sx={{ marginLeft: 5 }}>
            <Button
              variant="outlined"
              component="label"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              <input
                onChange={(event: any) =>
                  // console.log("iouoyijyui",event, item.field_name),
                  AddlfileHandleEvent(
                    event,
                    item.field_name.split(" ").join("_").replace("'", "") +
                      "_file"
                  )
                }
                hidden
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                type="file"
              />
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Upload
              </Typography>
            </Button>
          </Grid>
        ) : (
          <Grid item xs={3} sx={{ marginLeft: 5 }}>
            <Grid container direction={"row"} justifyContent="start">
              <Grid>
                <CheckCircleIcon color="success" />
              </Grid>
              <Grid alignItems="start">
                {" "}
                <Typography style={{ color: "#99A09C", fontWeight: "700" }}>
                  Successfully Uploaded
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems="start" textAlign={"left"}>
                <Button
                  onClick={() =>
                    downloadImage(
                      getValues(
                        item.field_name.split(" ").join("_").replace("'", "") +
                          "_file"
                      )
                    )
                  }
                  variant="text"
                >
                  Download Document
                </Button>
                <>
                  {(!!id2 && +id2 == 1
                    ? true
                    : !!user &&
                      environment.global_veriable.superAdminLevel.includes(
                        user.designation
                      )
                    ? !edit_flag
                    : edit_candidate) && (
                    <Button
                      onClick={() =>
                        setValue(
                          item.field_name
                            .split(" ")
                            .join("_")
                            .replace("'", "") + "_file",
                          undefined
                        )
                      }
                      variant="text"
                    >
                      Change Document
                    </Button>
                  )}
                </>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default AdditonalFieldUploadSection;

interface AdditonalFieldUploadSectionProps {
  item: any;
  errors?: any;
  control?: any;
  downloadImage?: any;
  addlDoc?: any[];
  SetaddlDocument?: any;
  AddlfileHandleEvent?: any;
  getValues?: any;
  watch?: any;
  setValue?: any;
  edit_flag?: boolean;
  user?: userDetails;
  id2?: number;
  edit_candidate: boolean;
}
