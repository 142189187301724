import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { bankList, EmployeeAllDetail } from "../../../models/employeeModel";

function EmployeeEditBankDetailsPopover({
  anchorEl,
  setAnchorEl,
  SubmitPopData,
  employeeDetails,
  bank_list,
}: EmployeeAddressDetailsPopoverProps) {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!employeeDetails && !!bank_list ) {
      setValue("bank_name", employeeDetails.bank_name);
      setValue("branch_name", employeeDetails.branch_name);
      setValue("account_no", employeeDetails.account_no);
      setValue("ifsc_no", employeeDetails.ifsc_no);
    }
  }, [employeeDetails,bank_list]);
  const onSubmit =(data: any)=>{
    handleClose()
    SubmitPopData(data)
  }
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Banking Details</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Bank Name
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"bank_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={bank_list}
                      keys={"bank_name"}
                      label={"bank_name"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Bank Name"}
                      value={!!value ? value : ""}
                      errors={errors.bank_name}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Branch Name
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"branch_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Branch Name"}
                      inputProps={{ maxLength: 40}}
                      error={!!errors["branch_name"]}
                      helperText={
                        errors.branch_name &&
                        `${errors.branch_name.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  A/c No.
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"account_no"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 15);
                      }}
                      placeholder={"Account No"}
                      error={!!errors["account_no"]}
                      helperText={
                        errors.account_no &&
                        `${errors.account_no.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  IFSC Code
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"ifsc_no"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{ maxLength: 11}}
                      placeholder={"IFSC Code"}
                      error={!!errors["ifsc_no"]}
                      helperText={
                        errors.ifsc_no &&
                        `${errors.ifsc_no.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default EmployeeEditBankDetailsPopover;

interface EmployeeAddressDetailsPopoverProps {
  employeeDetails?: EmployeeAllDetail;
  anchorEl?: any;
  setAnchorEl?: any;
  onSubmit?: any;
  bank_list: bankList[];
  SubmitPopData?: any;
}
