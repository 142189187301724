import { Cookies } from "react-cookie";
import { OrganizationAddPayload } from "../../models/organizationModels";
import {
  AddSalaryStructureService,
  DepartmentAddService,
  DepartmentDeleteService,
  DepartmentEditService,
  DesignationDeleteService,
  DesignationEditService,
  DomainGetAllService,
  EmployeeDesignationAddService,
  EmployeeTimingAddService,
  EmployeeTimingDeleteService,
  EmployeeTimingEditService,
  EmployeeTypeAddService,
  EmployeeTypeDeleteService,
  EmployeeTypeEditService,
  GetAllDepartmentService,
  GetAllDesignationService,
  GetAllDomainListService,
  GetAllEmployeeTimimgService,
  GetAllEmployeeTypeService,
  GetAllOrganizationService,
  GetAllPostService,
  GetAllSalaryStructureService,
  GetAllServiceOfferdService,
  GetByIDListService,
  GetMainDashBoardService,
  OrganizationAddService,
  OrganizationEditService,
  PostAddService,
  PostDeleteService,
  PostEditService,
  SalaryComponentAddService,
  SalaryComponentDeleteService,
  SalaryComponentEditService,
  SalaryComponentGetAll,
  SalaryComponentThresholdService,
  SalaryStructureUpdate,
  SyncAllEmpService,
} from "../../services/organizationService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
export enum OrganizationActionTypes {
  Organization_Add_Success_Action = "[ORGANIZATION] Organization Add Success Action",
  Department_Add_Success_Action = "[ORGANIZATION] Department Add Success Action",
  Get_All_Filter_Organization_Success_Action = "[ORGANIZATION] Get All Filter Organization Success Action",
  Get_All_Department_Success_Action = "[ORGANIZATION] Get All Department Success Action",
  Get_All_Service_List_Success_Action = "[ORGANIZATION] Get All Service List Success Action",
  Employee_Timing_Add_Success_Action = "[ORGANIZATION] Employee Timing Add Success Action",
  Employee_Designation_Add_Success_Action = "[ORGANIZATION] Employee Designation Add Success Action",
  Get_All_Designation_Success_Action = "[ORGANIZATION] Get All Designation Success Action",
  Get_All_Employee_Timing_Success_Action = "[ORGANIZATION] Get All Employee Timing Success Action",
  Employee_Type_Add_Success_Action = "[ORGANIZATION] Employee Type Add Success Action",
  Get_All_Employee_Type_Success_Action = "[ORGANIZATION] Get All Employee Type Success Action",
  Edit_Department_Success_Action = "[ORGANIZATION] Edit Department Success Action",
  Edit_Designation_Success_Action = "[ORGANIZATION] Edit Designation Success Action",
  Edit_Employee_Type_Success_Action = "[ORGANIZATION] Edit Employee Type Success Action",
  Edit_Employee_Timing_Success_Action = "[ORGANIZATION] Edit Employee Timing Success Action",
  Department_Delete_Success_Action = "[ORGANIZATION] Department Delete Success Action",
  Edit_Organization_Success_Action = "[ORGANIZATION] Edit Organization Success Action",
  Designation_Delete_Success_Action = "[ORGANIZATION] Designation Delete Success Action",
  Employee_Timing_Delete_Success_Action = "[ORGANIZATION] Employee Timing Delete Success Action",
  Employee_Type_Delete_Success_Action = "[ORGANIZATION] Employee Type Delete Success Action",
  Domain_Get_All_Success_Action = "[ORGANIZATION] Domain Get All Success Action",
  Salary_Component_Get_All_Success_Action = "[ORGANIZATION] Salary Component Get All Success Action",
  Add_Salary_Structure_Success_Action = "[ORGANIZATION] Add Salary Structure Success Action",
  Get_All_Salary_Structure_Success_Action = "[ORGANIZATION] All Salary Structure List Success Action",
  Salary_Structure_Edit_Success_Action = "[ORGANIZATION] Salary Structure Edit Success Action",
  Post_Add_Success_Action = "[ORGANIZATION] Post Add Success Action",
  Salary_Component_GetAll_Success_Action = "[ORGANIZATION] Salary Component Get All Success Action",
  Add_Salary_Component_Success_Action = "[ORGANIZATION] Add Salary Component Success Action",
  Get_All_Post_Success_Action = "[ORGANIZATION] Get All Post Success Action",
  Salary_Component_Delete_Success_Action = "[ORGANIZATION] Salary Component Delete Success Action",
  Edit_Salary_Component_Success_Action = "[ORGANIZATION] Edit Salary Component Success Action",
  Salary_Calculation_Load_Success_Action = "[ORGANIZATION] Salary Calculation Load Success Action",
  Post_Delete_Success_Action = "[ORGANIZATION] Post Delete Success Action",
  Edit_Post_Success_Action = "[ORGANIZATION] Edit Post Success Action",
  Update_Salary_Structure_Success_Action = "[ORGANIZATION] Update Salary Structure Success Action",
  Get_Main_Dashboard_Success_Action =" [ORGANIZATION] Get Main Dashboard",
  Sync_Salary_Structure_For_Employee_Success_Action ="[ORGANIZATION] Sync Salary Structure For Employee Success Action",
  Salary_Component_Threshold_Parameter_Success_Action ="[ORGANIZATION] Salary Component Threshold Parameter Success Action",
}

export const CreateOrganizationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Organization. Please Wait...",
        type: 2,
      })
    );
    return OrganizationAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/organization-main/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateOrganizationSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateOrganizationSuccessAction = () => {
  return { type: OrganizationActionTypes.Organization_Add_Success_Action };
};

export const CreateDepartmentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Department. Please Wait...",
        type: 2,
      })
    );
    return DepartmentAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/setup-department/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateDepartmentSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateDepartmentSuccessAction = () => {
  return { type: OrganizationActionTypes.Department_Add_Success_Action };
};

export const GetAllOrganizationAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Organization. Please Wait...",
        type: 2,
      })
    );
    return GetAllOrganizationService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllOrganizationSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllOrganizationSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Filter_Organization_Success_Action,
    payload: data,
  };
};

export const GetAllDepartmentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Department List. Please Wait...",
        type: 2,
      })
    );
    return GetAllDepartmentService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllDepartmentSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllDepartmentSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Department_Success_Action,
    payload: data,
  };
};

export const GetAllDesignationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Designation List. Please Wait...",
        type: 2,
      })
    );
    return GetAllDesignationService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllDesignationSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllDesignationSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Designation_Success_Action,
    payload: data,
  };
};

export const GetAllServiceOfferedAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Services. Please Wait...",
        type: 2,
      })
    );
    return GetAllServiceOfferdService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllServiceOfferedSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllServiceOfferedSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Service_List_Success_Action,
    payload: data,
  };
};

export const CreateEmployeeTimingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Employee Timing. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTimingAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/employee-timing-list/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateEmployeeTimingSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateEmployeeTimingSuccessAction = () => {
  return { type: OrganizationActionTypes.Employee_Timing_Add_Success_Action };
};

export const CreateEmployeeDesignationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Employee Designation. Please Wait...",
        type: 2,
      })
    );
    return EmployeeDesignationAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/setup-designation/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateEmployeeDesignationSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateEmployeeDesignationSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Employee_Designation_Add_Success_Action,
  };
};

export const GetAllEmployeeTimingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Employee Timing List. Please Wait...",
        type: 2,
      })
    );
    return GetAllEmployeeTimimgService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllEmployeeTimingSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllEmployeeTimingSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Employee_Timing_Success_Action,
    payload: data,
  };
};

export const CreateEmployeeTypeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Employee Type. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTypeAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/employee-type-list/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateEmployeeTypeSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateEmployeeTypeSuccessAction = () => {
  return { type: OrganizationActionTypes.Employee_Type_Add_Success_Action };
};

export const GetAllEmployeeTypeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Employee Type List. Please Wait...",
        type: 2,
      })
    );
    return GetAllEmployeeTypeService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllEmployeeTypeSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllEmployeeTypeSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Employee_Type_Success_Action,
    payload: data,
  };
};

export const EditDepartmentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Department. Please Wait...",
        type: 2,
      })
    );
    return DepartmentEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/setup-department/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditDepartmentSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditDepartmentSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Department_Success_Action,
  };
};
export const EditDesignationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Designation. Please Wait...",
        type: 2,
      })
    );
    return DesignationEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/setup-designation/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditDesignationSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditDesignationSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Designation_Success_Action,
  };
};
export const EditEmployeeTypeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Employee Type. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTypeEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/employee-type-list/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditEmployeeTypeSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const EditOrganizationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Organization. Please Wait...",
        type: 2,
      })
    );
    return OrganizationEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/organization-main/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditOrganizationSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditOrganizationSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Organization_Success_Action,
  };
};
export const EditEmployeeTypeSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Employee_Type_Success_Action,
  };
};
export const EditEmployeeTimingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Employee Timing. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTimingEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/employee-timing-list/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditEmployeeTimingSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditEmployeeTimingSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Employee_Timing_Success_Action,
  };
};

export const DeleteDepartmentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Department. Please Wait...",
        type: 2,
      })
    );
    return DepartmentDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteDepartmentSuccessAction());
          dispatch(GetAllDepartmentAction({ organization_id: null }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteDepartmentSuccessAction = () => {
  return { type: OrganizationActionTypes.Department_Delete_Success_Action };
};

export const DeleteDesignationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Designation. Please Wait...",
        type: 2,
      })
    );
    return DesignationDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteDesignationSuccessAction());
          dispatch(GetAllDesignationAction({ organization_id: null }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteDesignationSuccessAction = () => {
  return { type: OrganizationActionTypes.Designation_Delete_Success_Action };
};

export const DeleteEmployeeTypeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Employee Type. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTypeDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteEmployeeTypeSuccessAction());
          dispatch(GetAllEmployeeTypeAction({ organization_id: null }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteEmployeeTypeSuccessAction = () => {
  return { type: OrganizationActionTypes.Employee_Type_Delete_Success_Action };
};

export const DeleteEmployeeTimingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Employee Timing. Please Wait...",
        type: 2,
      })
    );
    return EmployeeTimingDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteEmployeeTimingSuccessAction());
          dispatch(GetAllEmployeeTimingAction({ organization_id: null }));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteEmployeeTimingSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Employee_Timing_Delete_Success_Action,
  };
};

export const DomainGetAllAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Domain. Please Wait...",
        type: 2,
      })
    );
    return Promise.all([
      DomainGetAllService({ domain_type: "component_type" }),
      DomainGetAllService({ domain_type: "salary_priod" }),
    ])
      .then(async (response) => {
        if (response[0].status != 200 || response[1].status != 200) {
          dispatch(ApiCallErrorAction(response[0].data));
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          // await payload.enqueueSnackbar("Successfully Deleted", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(
            DomainListGetAllSuccessAction({
              component_type: response[0].data,
              salary_priod: response[1].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DomainListGetAllSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Domain_Get_All_Success_Action,
    payload: data,
  };
};

export const CreateSalaryStructureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Salary Structure. Please Wait...",
        type: 2,
      })
    );
    return AddSalaryStructureService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/salary-structure-list/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateSalaryStructureSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateSalaryStructureSuccessAction = () => {
  return { type: OrganizationActionTypes.Add_Salary_Structure_Success_Action };
};

export const GetAllSalaryStructureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Salary Structure List. Please Wait...",
        type: 2,
      })
    );
    return GetAllSalaryStructureService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllSalaryStructureSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllSalaryStructureSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Salary_Structure_Success_Action,
    payload: data,
  };
};

export const SalaryComponentEditAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Editing Salary Structure. Please Wait...",
        type: 2,
      })
    );
    return GetByIDListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // await payload.enqueueSnackbar("Successfully Deleted", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(SalaryStructureEditSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const SalaryStructureEditSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Salary_Structure_Edit_Success_Action,
    payload: data,
  };
};

// POST
export const CreatePostAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Post. Please Wait...",
        type: 2,
      })
    );
    return PostAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/post-list/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreatePostSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreatePostSuccessAction = () => {
  return { type: OrganizationActionTypes.Post_Add_Success_Action };
};

export const SalaryStructuregetAll = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Loading structure. Please Wait...",
        type: 2,
      })
    );
    return SalaryComponentGetAll()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(SalaryComponentGetAllSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const SalaryComponentGetAllSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Salary_Component_GetAll_Success_Action,
    payload: data,
  };
};

export const CreateSalaryComponentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Salary Component. Please Wait...",
        type: 2,
      })
    );
    return SalaryComponentAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/salary-component/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateSalaryComponentSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateSalaryComponentSuccessAction = () => {
  return { type: OrganizationActionTypes.Add_Salary_Component_Success_Action };
};

export const getAllPostAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Post. Please Wait...",
        type: 2,
      })
    );
    return GetAllPostService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllPostSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
export const DeleteSalaryComponentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Salary Component. Please Wait...",
        type: 2,
      })
    );
    return SalaryComponentDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteSalaryComponentSuccessAction());
          dispatch(SalaryStructuregetAll());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllPostSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_All_Post_Success_Action,
    payload: data,
  };
};
export const DeleteSalaryComponentSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Salary_Component_Delete_Success_Action,
  };
};

export const EditSalaryComponentAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Salary Component. Please Wait...",
        type: 2,
      })
    );
    return SalaryComponentEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/salary-component/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditSalaryComponentSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditSalaryComponentSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Salary_Component_Success_Action,
  };
};

export const SalaryCalculationLoadAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Calculation Loading. Please Wait...",
        type: 2,
      })
    );
    return Promise.all([
      DomainGetAllService({ domain_type: "calculation_type" }),
      DomainGetAllService({ domain_type: "operator" }),
      DomainGetAllService({ domain_type: "operator_tye" }),
      DomainGetAllService({ domain_type: "global_key" }),
    ])
      .then(async (response) => {
        if (
          response[0].status != 200 ||
          response[1].status != 200 ||
          response[2].status != 200 ||
          response[3].status != 200
        ) {
          dispatch(ApiCallErrorAction(response[0].data));
          dispatch(ApiCallErrorAction(response[1].data));
        } else {
          dispatch(
            SalaryCalculationLoadSuccessAction({
              calculation_type: response[0].data,
              operator: response[1].data,
              operator_tye: response[2].data,
              global_key: response[3].data,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const SalaryCalculationLoadSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Salary_Calculation_Load_Success_Action,
    payload: data,
  };
};

export const DeletePostAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Post. Please Wait...",
        type: 2,
      })
    );
    return PostDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeleteSalaryComponentSuccessAction());
          dispatch(getAllPostAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeletePostSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Post_Delete_Success_Action,
  };
};

export const EditPostAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Post. Please Wait...",
        type: 2,
      })
    );
    return PostEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/post-list");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditPostSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditPostSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Edit_Post_Success_Action,
  };
};

export const EditSalaryStructureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Salary Structure. Please Wait...",
        type: 2,
      })
    );
    return SalaryStructureUpdate(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/organization/salary-structure-list/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditSalaryStructureSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditSalaryStructureSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Update_Salary_Structure_Success_Action,
  };
};

export const GetMainDashboardAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Main Dashboard. Please Wait...",
        type: 2,
      })
    );
    return GetMainDashBoardService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetMainDashboardActionSuccessAction({
              currentMonthBirthday: response.data.currentMonthBirthday,
              createEmpOnMonth: response.data.createEmpOnMonth,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetMainDashboardActionSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Get_Main_Dashboard_Success_Action,
    payload: data,
  };
};

export const SyncSalaryStructureForEmployeeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Sync in progress. Please Wait...",
        type: 2,
      })
    );
    console.log(payload);
    
    return SyncAllEmpService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Sync Successful...", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(
            SyncSalaryStructureForEmployeeSuccessAction()
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const SyncSalaryStructureForEmployeeSuccessAction = () => {
  return {
    type: OrganizationActionTypes.Sync_Salary_Structure_For_Employee_Success_Action,
  };
};

export const SalaryComponentThresholdParameterAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Sync in progress. Please Wait...",
        type: 2,
      })
    );
    // console.log(payload);
    
    return SalaryComponentThresholdService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            SalaryComponentThresholdParameterSuccessAction(response.data)
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const SalaryComponentThresholdParameterSuccessAction = (data: any) => {
  return {
    type: OrganizationActionTypes.Salary_Component_Threshold_Parameter_Success_Action,
    payload: data,
  };
};