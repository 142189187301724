import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  IEmployeeList,
  IWorkSheet,
  TimingSheetList,
} from "../../../models/timingSheetModel";
import { userDetails } from "../../../models/userModels";
import {
  clearTimeSheetAction,
  ClearTimingSheetFromWorklistAction,
  GetTimingSheetListAction,
  goToTimingSheetFromWorklistAction,
  initiateTimesheetAction,
  searchByNameEmpAction,
  UpdateMonthlyTimeSheetAction,
} from "../../../stores/actions/timingSheetAction";
import LogActivityView from "./LogActivityView";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

function LogActivity({
  GetTimingSheetListAction,
  timing_sheet_list,
  clearTimeSheetAction,
  UpdateMonthlyTimeSheetAction,
  initiateTimesheetAction,
  user,
  ClearTimingSheetFromWorklistAction,
  goToTimingSheetFromWorklistAction,
  worklist,
  employeeList,
  searchByNameEmpAction,
  apiLoading,
}: LogActivityProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isworklist = searchParams.get("isworklist");
  const employeeId = searchParams.get("emp");

  console.log("employeeID  & time : ", employeeId);

  const Submit = (props: any) => {
    console.log("index props : ", props);
    GetTimingSheetListAction({
      employee_id: props.employee_id,
      month: props.month,
    });
  };
  console.log(timing_sheet_list);

  return (
    <LogActivityView
      Submit={Submit}
      timing_sheet_list={timing_sheet_list}
      UpdateMonthlyTimeSheetAction={UpdateMonthlyTimeSheetAction}
      clearTimeSheetAction={clearTimeSheetAction}
      initiateTimesheetAction={initiateTimesheetAction}
      user={user}
      isworklist={Boolean(Number(isworklist))}
      ClearTimingSheetFromWorklistAction={ClearTimingSheetFromWorklistAction}
      goToTimingSheetFromWorklistAction={goToTimingSheetFromWorklistAction}
      worklist={worklist}
      isLogView={location.pathname.endsWith("view-time-sheet/")}
      employeeList={employeeList}
      searchByNameEmpAction={searchByNameEmpAction}
      notEditable={!!employeeId}
      notShow={!!employeeId}
      apiLoading={apiLoading}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    timing_sheet_list: state.timingSheet.timing_sheet_list,
    user: state.user.userDetails,
    worklist: state.timingSheet.worksheet,
    employeeList: state.timingSheet.employeeList,
    apiLoading: state.loading.count,
  };
};
const mapDispatchToProps = {
  GetTimingSheetListAction,
  UpdateMonthlyTimeSheetAction,
  clearTimeSheetAction,
  initiateTimesheetAction,
  ClearTimingSheetFromWorklistAction,
  goToTimingSheetFromWorklistAction,
  searchByNameEmpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogActivity);

interface LogActivityProps {
  GetTimingSheetListAction: any;
  timing_sheet_list?: TimingSheetList;
  user?: userDetails;
  UpdateMonthlyTimeSheetAction?: any;
  clearTimeSheetAction?: any;
  initiateTimesheetAction?: any;
  ClearTimingSheetFromWorklistAction?: any;
  goToTimingSheetFromWorklistAction?: any;
  worklist?: IWorkSheet;
  employeeList: IEmployeeList[];
  apiLoading: number;
  searchByNameEmpAction?: any;
}
