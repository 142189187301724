import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  OrganizationALlList,
  DomainList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateSalaryComponentAction,
  DomainGetAllAction,
  EditSalaryComponentAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import AddSalaryComponentView from "./AddSalaryComponentView";

const AddSalaryCompnent = ({
  DomainGetAllAction,
  CreateSalaryComponentAction,
  salary_priod,
  domain_list,
  EditSalaryComponentAction,
  component_list,
}: AddSalaryCompnentProps) => {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  React.useEffect(() => {
    DomainGetAllAction();
  }, []);
  const Submit = (data: any) => {
    console.log("data", data);

    CreateSalaryComponentAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData = (data: any) => {
    EditSalaryComponentAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };

  return (
    <AddSalaryComponentView
      Submit={Submit}
      component={component_list.find((m) => !!id && m.compent_id == +id)}
      id={id}
      salary_priod={salary_priod}
      domain_list={domain_list}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    component_list: state.organization.component_list,
    salary_priod: state.organization.salary_priod,
    domain_list: state.organization.domain_list,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  // GetAllOrganizationAction,
  DomainGetAllAction,
  CreateSalaryComponentAction,
  EditSalaryComponentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSalaryCompnent);

interface AddSalaryCompnentProps {
  EditSalaryComponentAction?: any;
  DomainGetAllAction?: any;
  CreateSalaryComponentAction?: any;
  salary_priod: DomainList[];
  domain_list: DomainList[];
  component_list: SalaryStuctureComponent[];
}
