import { FC, useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ILeaveType } from "../../../models/leaveModel";
import LeaveTypeActionModal from "./LeaveTypeActionModal";
import { userDetails } from "../../../models/userModels";
import DeleteLeaveDialog from "./DeleteLeaveDialog";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { environment } from "../../../environment";

const LeaveTypeTable: FC<ILeaveTypeTable> = ({
  leave_types,
  GetAllLeaveTypeAction,
  GetLeaveTypeByIdAction,
  EditLevelTypeAction,
  deleteLevelTypeAction,
  createLeaveTypeAction,
  selectedOrganizationLeave,
  user,
}): JSX.Element => {
  const enqueueSnackbar = useSnackbar();
  const styles = {
    marginRight: "50px",
  };
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  const [leaveTypeModalOpen, setLeaveTypeModalOpen] = useState<boolean>(false);
  const [delRequestId, setDeleteRequestId] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editablePayload, setEditablePayload] = useState<ILeaveType | null>(
    null
  );
  const [rows, setRows] = useState<ILeaveType[]>([]);

  console.log("leave types : ", leave_types);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const column1: string[] = [
    "Leave Type Name",
    "Carry Forward",
    "Carry Forward Limit",
    "Auto Approved",
    //"Created By",
    "Actions",
  ];

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!leave_types
      ? leave_types.filter((row: ILeaveType) => {
          return row?.leave_type_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!leave_types) {
      cancelSearch();
    }
  }, [leave_types]);
  const handleEditModalOpen = (editablePayload: ILeaveType): void => {
    setEditablePayload(editablePayload);
    setLeaveTypeModalOpen(true);
    setIsEditing(true);
  };
  useEffect(() => {
    if (!deleteDialogOpen) {
      setDeleteRequestId(0);
    }
  }, [deleteDialogOpen]);
  const handleDeleteAction = () => {
    if (!delRequestId) return;
    const payload = {
      leaveId: delRequestId,
      enqueueSnackbar,
    };
    deleteLevelTypeAction(payload);
    setDeleteDialogOpen(false);
    setDeleteRequestId(0);
  };
  const deleteActionHandler = (delId: number) => {
    setDeleteDialogOpen(true);
    setDeleteRequestId(delId);
  };
  console.log("rows : ", rows);
  useEffect(() => {
    GetAllLeaveTypeAction();
  }, [selectedOrganizationLeave]);
  const handleAddNewLeaveType = () => {
    setLeaveTypeModalOpen(true);
    setIsEditing(false);
  };
  return (
    <Paper sx={{ ml: 4, mr: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <LeaveTypeActionModal
        open={leaveTypeModalOpen}
        setOpen={setLeaveTypeModalOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        EditLevelTypeAction={EditLevelTypeAction}
        selectedOrganizationLeave={selectedOrganizationLeave}
        createLeaveTypeAction={createLeaveTypeAction}
        user={user}
        setEditablePayload={setEditablePayload}
        editablePayload={editablePayload}
      />
      <DeleteLeaveDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        delRequest={handleDeleteAction}
      />
      {/* <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid> */}
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={3} px={2}>
          <Button
            onClick={handleAddNewLeaveType}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        {/* <Grid
          item
          xs={12}
          sx={{ px: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Button
            variant="outlined"
            onClick={() => setLeaveTypeModalOpen(true)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={1}
              m={0}
            >
              <b>Add</b>
              <IconButton>
                <AddIcon sx={{ color: "#3788d8" }} />
              </IconButton>
            </Box>
          </Button>
        </Grid> */}
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.leave_type_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.leave_type_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.carry_forward ? (
                              <span style={{ color: "green" }}>Yes</span>
                            ) : (
                              <span style={{ color: "red" }}>No</span>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.max_carry_forward_limit
                              ? row.max_carry_forward_limit
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.auto_approved ? (
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                  color: "green",
                                }}
                              >
                                Yes
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                No
                              </span>
                            )}
                          </StyledTableCell>
                          {/* <StyledTableCell align="left" key={column1[5]}>
                            <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.created_by}`
                                )
                              }
                            >
                              {row.created_by}
                            </Link>
                          </StyledTableCell> */}
                          <StyledTableCell align="left" key={column1[4]}>
                            <IconButton
                              onClick={() => handleEditModalOpen(row)}
                            >
                              <EditIcon />
                            </IconButton>
                            {!environment.leave_application.non_deltable_leave.includes(
                              Number(row.leave_type_id)
                            ) ? (
                              <IconButton
                                onClick={() =>
                                  deleteActionHandler(Number(row.leave_type_id))
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={5}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={!!rows ? rows?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default LeaveTypeTable;

interface ILeaveTypeTable {
  GetAllLeaveTypeAction?: any;
  GetLeaveTypeByIdAction?: any;
  leave_type?: ILeaveType;
  leave_types: ILeaveType[];
  EditLevelTypeAction?: any;
  deleteLevelTypeAction?: any;
  createLeaveTypeAction?: any;
  selectedOrganizationLeave?: number;
  selectOrganizationAction?: any;
  user?: userDetails;
}
