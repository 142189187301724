import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { ApplicablePostDetail } from "../../../interfaces/projectDashboard.interface";
import images from "../../../assets/images.png";
import moment from "moment";
const PostList: FC<IPostList> = ({ postDetail, noOfEmployee }) => {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Card
        sx={{
          my: 1,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              src={images}
              sx={{ bgcolor: "#0060FF", border: "3px solid text.secondary" }}
              aria-label="recipe"
            ></Avatar>
          }
          title={`${postDetail.post_name}`}
          subheader={`${moment(postDetail.created_on).format("DD-MMM-YYYY")}`}
        />

        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box display="flex" justifyContent="center" flexDirection="column">
            {!!noOfEmployee && noOfEmployee[String(postDetail.post_id)] ? (
              <Typography variant="body2" color="text.secondary">
                No Of Employees : {noOfEmployee[String(postDetail.post_id)]}
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No Of Employees : 0
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
interface IPostList {
  postDetail: ApplicablePostDetail;
  noOfEmployee?: undefined | any;
}
export default PostList;
