import {
  Autocomplete,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
const AutoCompleteMulti = ({
  option,
  value,
  keys,
  label,
  onChange,
  errors,
  placeholder,
  onBlur,
  hasInfo,
  infoEvent,
  style,
  textLabel,
  inputProps,
  disable,
  extras,
  extras2,
  readOnly,
  onInputChange,
  ref,
}: AutocompleteProps) => {
  const [id, setId] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setId(2);
    }, 3000);
  }, []);
  return (
    <Autocomplete
      ref={ref}
      options={option}
      disabled={disable}
      style={style}
      onBlur={onBlur}
      readOnly={readOnly}
      onInputChange={onInputChange}
      className={"smaller"}
      multiple={true}
      value={
        !!value && !!option ? option.filter((m) => value.includes(m[keys])) : []
      }
      getOptionLabel={(option) => {
        let a =
          !!extras && !!option[extras]
            ? option[label] + "( " + option[extras] + " )"
            : !!extras2 && !!option[extras2]
            ? option[label] + " - " + option[extras2]
            : option[label];
        return a;
      }}
      onChange={(event, newValue) => {
        if (!!newValue) {
          onChange(newValue.map((m) => m[keys]));
        } else {
          onChange([]);
        }
      }}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText>
            {" "}
            {option[label]}
            {!!extras && "  ( " + option[extras] + " )"}
            {!!extras2 && " - " + option[extras2]}
          </ListItemText>
          {hasInfo && (
            <IconButton
              style={{ color: "#00AAEE" }}
              onClick={(e) => (e.stopPropagation(), infoEvent(option[keys]))}
            >
              <InfoIcon />
            </IconButton>
          )}
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ width: "100%", margin: "5px" }}
          type="text"
          variant="outlined"
          size="small"
          placeholder={placeholder}
          error={!!errors}
          onBlur={onBlur}
          label={textLabel}
          helperText={!!errors && `* This field is Required`}
        />
      )}
    />
  );
};

export default AutoCompleteMulti;

interface AutocompleteProps {
  option: any[];
  value?: any;
  onChange?: any;
  keys: any;
  label: string;
  errors?: any;
  placeholder?: string;
  onBlur?: any;
  hasInfo?: boolean;
  readOnly?: boolean;
  infoEvent?: any;
  style?: any;
  inputProps?: any;
  textLabel?: string;
  disable?: boolean;
  extras?: any;
  extras2?: any;
  onInputChange?: any;
  ref?: any;
  multiple?: any;
}
