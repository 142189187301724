import { FC } from "react";
import { ITotalPayrollSal } from "../../../models/reportsModel";
import { Paper, Card, CardContent, Typography, Grid } from "@mui/material";
import { SalaryStuctureComponent } from "../../../models/organizationModels";

const TotalSalaryComponent: FC<ITotalSalaryComponent> = ({
  totalSalCount,
  totalSize,
  accessFor,
  noBetween,
  component_list,
}): JSX.Element => {
  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent={noBetween ? "flex-start" : "space-between"}
      >
        {!!totalSize ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  No. Of Employees
                </Typography>

                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  {totalSize}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("basic") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total Basic
                </Typography>

                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount.Basic).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("hra") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total HRA
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount.HRA).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("esic_contribution") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total ESIC Contribution
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377;{" "}
                  {Number(totalSalCount["ESIC_Contribution"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}
        {accessFor.includes("esic_deduction") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total ESIC Deduction
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["ESIC_Deduction"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("ptax") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total Professional Tax
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["P_Tax"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("pf_contribution") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total PF Contribution
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["PF_Contribution"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}
        {accessFor.includes("pf_deduction") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total PF Deduction
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["PF_Deduction"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("gross_salary") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total Gross Salary
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["Total_Earning"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}
        {accessFor.includes("net_salary") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total Net Salary
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["net_salary"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}

        {accessFor.includes("ctc") ? (
          <Grid item sm={6} md={3} lg={2} height={130} width="100%">
            <Card
              sx={{
                height: "100%",
                width: "100%",
                background:
                  "linear-gradient(to right bottom, #2b5876 , #4e4376)",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 12, color: "#fff" }} gutterBottom>
                  Total CTC
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography
                  sx={{ mb: 1, fontSize: 20, fontWeight: 700, color: "#fff" }}
                >
                  &#8377; {Number(totalSalCount["CTC"]).toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  );
};

interface ITotalSalaryComponent {
  totalSalCount: any;
  totalSize?: number;
  accessFor: string[];
  noBetween?: boolean;
  component_list: SalaryStuctureComponent[];
}
export default TotalSalaryComponent;
