import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
} from "../../../models/attendanceModel";
import {
  AllDesignationList,
  OrganizationALlList,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  ApproveAttendanceListAction,
  AttendanceDetailPayloadSuccessAction,
} from "../../../stores/actions/attendanceAction";
import {
  GetAllDesignationAction,
  GetAllOrganizationAction,
  GetAllSalaryStructureAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import PromotionListView from "./PromotionListView";
import {
  ActivateSalaryForPromotionAction,
  DeletePromotionAction,
  PromotionListAction,
  ViewPromotionAction,
} from "../../../stores/actions/promotionAction";
import {
  PromotionListDet,
  ViewPromotion,
} from "../../../models/promotionModel";
import { useForm } from "react-hook-form";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function PromotionList({
  PromotionListAction,
  SidenavAutoCloseOpen,
  promotion_list,
  ViewPromotionAction,
  view_promotion,
  GetAllDesignationAction,
  designation_list,
  GetAllSalaryStructureAction,
  salary_structure_list,
  searchEmployeeList,
  searchByNameEmpAction,
  DeletePromotionAction,
  total_size,
  ActivateSalaryForPromotionAction,
}: PromotionListProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllDesignationAction({ organization_id: null });
    GetAllSalaryStructureAction({ organization_id: null });

    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  useEffect(() => {
    PromotionListAction({
      // employee_id: null,
      batch_status: null,
      // new_designation_id: null,
      // new_salary_structure_id: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pagesize, pageindex]);
  const { enqueueSnackbar } = useSnackbar();

  const Submit = (data: any) => {
    console.log("Submit", data);

    PromotionListAction({
      // employee_id: !!data.employee_id ? data.employee_id : null,
      batch_status: !!data.batch_status ? data.batch_status : null,
      // new_designation_id: !!data.new_designation_id
      //   ? data.new_designation_id
      //   : null,
      // new_salary_structure_id: !!data.new_salary_structure_id
      //   ? data.new_salary_structure_id
      //   : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const ViewPromDet = (props: any) => {
    console.log("props", props);
    navigate(`/promotion/promotion-details-view`);
    ViewPromotionAction({
      promotion_salary_batch_id: props,
    });
  };
  const FilterClear = () => {
    setValue("employee_id", null);
    setValue("employee_name", null);
    setValue("batch_status", null);
    setValue("new_designation_id", null);
    setValue("new_salary_structure_id", null);
    PromotionListAction({
      // employee_id: null,
      batch_status: null,
      // new_designation_id: null,
      // new_salary_structure_id: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const DeletePromBatch = (data: any) => {
    console.log(data);
    let payload: any = {
      data1: data,
      data2: {
        // employee_id: null,
        batch_status: null,
        // new_designation_id: null,
        // new_salary_structure_id: null,
        page_size: pagesize,
        page_index: pageindex,
      },
      enqueueSnackbar: enqueueSnackbar,
    };
    DeletePromotionAction(payload);
  };

  const ActivateSalary = () => {
    ActivateSalaryForPromotionAction();
    enqueueSnackbar("Activated New Promotion/Revision", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  return (
    <PromotionListView
      promotion_list={promotion_list}
      Submit={Submit}
      view_promotion={view_promotion}
      ViewPromDet={ViewPromDet}
      designation_list={designation_list}
      salary_structure_list={salary_structure_list}
      FilterClear={FilterClear}
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      searchEmployeeList={searchEmployeeList}
      searchByNameEmpAction={searchByNameEmpAction}
      ViewPromotionAction={ViewPromotionAction}
      DeletePromBatch={DeletePromBatch}
      pageindex={pageindex}
      pagesize={pagesize}
      rowperpage={rowperpage}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      total_size={total_size}
      ActivateSalary={ActivateSalary}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    promotion_list: state.promotion.promotion_list,
    view_promotion: state.promotion.view_promotion,
    designation_list: state.organization.designation_list,
    salary_structure_list: state.organization.salary_structure_list,
    searchEmployeeList: state.timingSheet.employeeList,
    total_size: state.promotion.promotion_list?.count,
  };
};

const mapDispatchToProps = {
  PromotionListAction,
  ViewPromotionAction,
  SidenavAutoCloseOpen,
  GetAllDesignationAction,
  GetAllSalaryStructureAction,
  searchByNameEmpAction,
  DeletePromotionAction,
  ActivateSalaryForPromotionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionList);

interface PromotionListProps {
  PromotionListAction?: any;
  SidenavAutoCloseOpen?: any;
  promotion_list?: PromotionListDet;
  ViewPromotionAction?: any;
  view_promotion: ViewPromotion[];
  GetAllDesignationAction?: any;
  GetAllSalaryStructureAction?: any;
  designation_list: AllDesignationList[];
  salary_structure_list: SalaryStructureList[];
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  DeletePromotionAction?: any;
  total_size?: number;
  ActivateSalaryForPromotionAction?: any;
}
