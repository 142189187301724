import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
  } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { EmployeeAllDetail } from '../../../models/employeeModel';
import EditEmergencyContactInformation from './EditEmergencyContactInformation';

const EmergencyContactInformation = ({employeeDetails,onSubmit3}:EmergencyContactInformationProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const onSubmit = (data: any) => {
  //   console.log(data);
  //   let payload: any = {
  //     // ...data,
  //     emergency : {
  //       employee_id: employeeDetails?.employee_id,
  //       emergency_contact: data.emergency_contact,
  //       relation: data.relation,
  //     },
  //   };
  //   Submit(payload);
  // };
  return (
    <>
     <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            //paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  //mb: 10,
                }}
              >
               Emergency Contact Information
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="end" alignItems="center" padding={0}>
            <Tooltip title="Edit">
              <IconButton onClick={handleClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <EditEmergencyContactInformation
              anchorEl={anchorEl}
              employeeDetails={employeeDetails}
              setAnchorEl={setAnchorEl}
              SubmitPopData={onSubmit3}
            />
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            marginTop={-1}
          >
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                     Contact No.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {!!employeeDetails?.emergency_contact ? employeeDetails?.emergency_contact : "N/A"}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                   Relationship
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {!!employeeDetails?.relation ? employeeDetails?.relation: "N/A"}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default EmergencyContactInformation


interface EmergencyContactInformationProps {
    employeeDetails?: EmployeeAllDetail;
    onSubmit3?: any;
  }
  