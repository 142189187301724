import { combineReducers } from "redux";
import Attendancereducer from "./attendanceReducer";
import CustomerReducer from "./customerReducer";
import EmployeeReducer from "./employeeReducer";
import ErrorReducer from "./errorReducer";
import LoadingReducer from "./loadingReducer";
import OrganizationReducer from "./oraganizationReducer";
import PayrollReducer from "./payrollReducer";
import UserManagementReducer from "./userManagementReducer";
import UserReducer from "./userReducer";
import LeaveReducer from "./leaveTypeReducer";
import TimingSheetReducer from "./timingSheetReducer";
import Reportasreducer from "./reportsReducer";
import PromotionReducer from "./promotionReducer";
import TerminationReducer from "./terminationReducer";
import InvoiceReducer from "./invoiceReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  loading: LoadingReducer,
  error: ErrorReducer,
  organization: OrganizationReducer,
  customer: CustomerReducer,
  attendance: Attendancereducer,
  employee: EmployeeReducer,
  payroll: PayrollReducer,
  userManagement: UserManagementReducer,
  leave: LeaveReducer,
  timingSheet: TimingSheetReducer,
  reports: Reportasreducer,
  promotion: PromotionReducer,
  termination: TerminationReducer,
  invoice: InvoiceReducer
});

export default rootReducer;
