import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState, version } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { environment } from "../../../environment";
import Icon from "@mui/material/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import "./SideNav.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
const SideNav = ({ user, side_nav, SidenavAutoCloseOpen }: SideNavProps) => {
  const [shortmenu, SetShortmenu] = useState<boolean>(false);
  const sideNav = environment.sideNav;
  let navigateTo = useNavigate();
  useEffect(() => {
    SidenavAutoCloseOpen(shortmenu);
  }, [shortmenu]);
  useEffect(() => {
    SetShortmenu(side_nav);
  }, [side_nav]);
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const assesCheck = (element: any) => {
    let permission = false;
    if (
      !!user?.assess_control.find((n) => n.access_control_name === element.key)
    ) {
      permission = true;
    }

    return permission;
  };
  const location = useLocation();
  return !!user?.assess_control &&
    user?.assess_control.length > 0 &&
    user.assess_control[0].access_control_name != "dummy_menu" &&
    !!sideNav.find(
      (m) => m.url.split("/")[1] === location.pathname.split("/")[1]
    ) &&
    sideNav.find((m) => m.url.split("/")[1] === location.pathname.split("/")[1])
      ?.has_submenu ? (
    <div
      style={{
        width: !shortmenu ? 200 : 50,
        background: "white",
        // "linear-gradient(0deg, rgba(250,250,255,1) 29%, rgba(255,255,255,1) 99%)",
        // backgroundImage: "linear-gradient(#23a9d2, #132437)",
        minHeight: "100%",
        padding: !shortmenu ? 20 : 5,
        paddingTop: 60,
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
        transition: "width 0.3s ease",
      }}
    >
      <Grid
        container
        justifyContent={"end"}
        style={{ borderBottom: "0.5px solid #909EAD" }}
      >
        <IconButton color="primary" onClick={() => SetShortmenu(!shortmenu)}>
          {!shortmenu ? (
            <ArrowBackIosIcon style={{ color: "#002e66", fontSize: 22 }} />
          ) : (
            <ArrowForwardIosIcon style={{ color: "#002e66", fontSize: 22 }} />
          )}
        </IconButton>
      </Grid>
      {sideNav
        .find((m) => m.url.split("/")[1] === location.pathname.split("/")[1])
        ?.menuItem.map((items, index) =>
          assesCheck(items) ? (
            !shortmenu ? (
              <Grid
                key={index}
                style={{
                  marginTop: "2vh",
                  padding: "0",
                  width: "98%",
                  paddingLeft:"2%",
                  paddingRight:"2%",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  color:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? "#002e66"
                      : "",
                  backgroundColor:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? /*"#ebecf0"*/ "#DEE0E1"
                      : "",
                  borderRight:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? /*"5px solid #4EA6DF "*/ "5px solid #002e66"
                      : "",
                }}
                className="side-nav-item"
                onClick={() => navigate(items.url)}
              >
                <div>
                  <Icon
                    style={{
                      // color: "#4EA6DF",
                      color: "#002e66",
                      fontSize: 15,
                      marginRight: 6,
                      marginTop: 10,
                    }}
                    fontSize="small"
                  >
                    {items.icon}
                  </Icon>
                </div>

                <div>
                  <div>{items.item_name}</div>
                </div>
              </Grid>
            ) : (
              <Grid
                key={index}
                style={{
                  color:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? "#002e66"
                      : "",
                  backgroundColor:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? /*"#ebecf0"*/ "#DEE0E1"
                      : "",
                  borderRight:
                    location.pathname === items.url ||
                    !!items.sub_url.find((n) => location.pathname.includes(n))
                      ? /*"5px solid #4EA6DF "*/ "5px solid #002e66"
                      : "",
                }}
                className="side-nav-item"
                onClick={() => navigate(items.url)}
              >
                <div>
                  <Icon
                    style={{
                      color: "#002e66",
                      fontSize: 25,
                    }}
                    fontSize="small"
                  >
                    {items.icon}
                  </Icon>
                </div>
              </Grid>
            )
          ) : (
            <></>
          )
        )}
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
    side_nav: state.user.sideNavClose,
  };
};
const mapDispatchToProps = {
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
interface SideNavProps {
  user?: userDetails;
  side_nav: boolean;
  SidenavAutoCloseOpen?: any;
}
