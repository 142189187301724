import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BatchByDeg,
  BatchDetailList,
  BatchList,
  CalculatedComponenet,
} from "../../../models/payrollModel";
import { StoreState } from "../../../models/reduxModels";
import BatchDetailView from "./BatchDetailView";
import {
  DiscardEmployeeFromBatchAction,
  GetBatchDetailAction,
  GetCalculatedSalaryComponent,
  PayrollComponenetUpdateAction,
  UpdateBatchAction,
} from "../../../stores/actions/payrollActions";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";

const BatchDetail = ({
  GetBatchDetailAction,
  batch_detail,
  GetCalculatedSalaryComponent,
  CalculatedComponenet,
  DiscardEmployeeFromBatchAction,
  PayrollComponenetUpdateAction,
  UpdateBatchAction,
  batch,
  BatchByDeg,
  accessToken,
  user,
}: batchDetailProps) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigateTo = useNavigate();
  const { id } = useParams();
  const { id2 } = useParams();
  useEffect(() => {
    if (!!id) {
      GetBatchDetailAction(id);
    }
  }, []);
  const DiscardEmploye = (data: any) => {
    let payload = {
      data: data,
      enqueueSnackbar: enqueueSnackbar,
    };
    DiscardEmployeeFromBatchAction(payload);
  };
  const ApprovedBatch = (data: any) => {
    let payload = {
      data: data,
      enqueueSnackbar: enqueueSnackbar,
      navigateTo: navigateTo,
    };
    UpdateBatchAction(payload);
  };
  return (
    <BatchDetailView
      batch_detail={batch_detail}
      batch={batch}
      GetCalculatedSalaryComponent={GetCalculatedSalaryComponent}
      CalculatedComponenet={CalculatedComponenet}
      DiscardEmploye={DiscardEmploye}
      PayrollComponenetUpdateAction={PayrollComponenetUpdateAction}
      ApprovedBatch={ApprovedBatch}
      edit={!!id2 ? +id2 : 0}
      BatchByDeg={
        !!id2 && +id2 == 2
          ? BatchByDeg.find((m) => m.batch_id == id)
          : undefined
      }
      accessToken={accessToken}
      user={user}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    batch: state.payroll.batch_details,
    batch_detail: state.payroll.batch_detail,
    CalculatedComponenet: state.payroll.CalculatedComponenet,
    BatchByDeg: state.payroll.BatchByDeg,
    accessToken: state.user.userDetails?.accessToken,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  GetBatchDetailAction,
  GetCalculatedSalaryComponent,
  DiscardEmployeeFromBatchAction,
  PayrollComponenetUpdateAction,
  UpdateBatchAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchDetail);
interface batchDetailProps {
  batch?: BatchList;
  GetBatchDetailAction?: any;
  batch_detail: BatchDetailList[];
  GetCalculatedSalaryComponent?: any;
  CalculatedComponenet: CalculatedComponenet[];
  DiscardEmployeeFromBatchAction?: any;
  PayrollComponenetUpdateAction?: any;
  UpdateBatchAction?: any;
  BatchByDeg: BatchByDeg[];
  accessToken?: string;
  user?: userDetails;
}
