import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
  Button,
  Divider,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProfessionalQualification = ({
  addProfessionalQualification,
  setAddProfessionalQualification
}: ProfessionalQualificationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns2: any[] = [
    "Course Name",
    "Start Date",
    "End Date",
    "Remarks",
    "Action",
  ];
  const addExperienceDetails = () => {
    if (
      !addProfessionalQualification[addProfessionalQualification.length - 1].start_date ||
      !addProfessionalQualification[addProfessionalQualification.length - 1].end_date
    ) {
      enqueueSnackbar("Please fill add all detail before adding new row.", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      let Temp: any = JSON.parse(JSON.stringify(addProfessionalQualification));
      Temp = [
        ...Temp,
        {
          course_name: null,
          start_date: null,
          end_date: null,
          remarks: null,
        },
      ];
      setAddProfessionalQualification(Temp);
    }
  };
  const OnChangeExperience = (value: string, row: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addProfessionalQualification));
    Temp[index][row] = value;
    setAddProfessionalQualification(Temp);
  };
  const DeleteExpAction = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addProfessionalQualification));
    let proDet: any[] = [];
    Temp.forEach((element: any, indexs: any) => {
      if (index != indexs) {
        proDet.push(JSON.parse(JSON.stringify(element)));
      }
    });
    setAddProfessionalQualification([]);
    setTimeout(() => {
      setAddProfessionalQualification(proDet);
    }, 200);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%",
                // marginBottom: 20,
              }}
            >
              Professional Qualification
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            item
            marginLeft={11}
            xs={10}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
              //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns2.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!addProfessionalQualification &&
                    addProfessionalQualification.map(
                      (row: any, index: number) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={columns2[0]}>
                            {/* {row.organization_name} */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "course_name",
                                    index
                                  )
                                }
                                inputProps={{ maxLength: 100 }}
                                value={row.course_name}
                                placeholder={"Course Name"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[2]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "start_date",
                                    index
                                  )
                                }
                                value={row.start_date}
                                placeholder={"Start Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[3]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "end_date",
                                    index
                                  )
                                }
                                value={row.end_date}
                                placeholder={"End Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[4]}>
                            {/* remarks */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "remarks",
                                    index
                                  )
                                }
                                inputProps={{ maxLength: 80 }}
                                value={row.remarks}
                                placeholder={"remarks"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[5]}>
                            {addProfessionalQualification.length > 1 && (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => DeleteExpAction(index)}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={3}>
              <Button
                onClick={() => addExperienceDetails()}
                size="medium"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
                style={{
                    borderColor: "#DDDDDF",
                    opacity: 0.8,
                    width: "90%",
                    marginLeft: "5%",
                }}
            ></Divider> */}
    </>
  );
}

export default ProfessionalQualification;

interface ProfessionalQualificationProps {
  addProfessionalQualification: any[];
  setAddProfessionalQualification?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));