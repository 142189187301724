import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  IAllLeaveManagement,
  IDesignationWiseEmp,
  IHolidayList,
  ILeaveAmountData,
  ILeaveDetailsData,
  ILeaveType,
  IdesignationWiseLeave,
  ILeaveCalendar,
  ILeaveTableData,
  IAddress,
  INaturalHoliday,
} from "../../../models/leaveModel";
import EmployeeLeaveCalendar from "./EmployeeLeaveCalendar";
import EmployeeLeaveApplication from "./EmployeeLeaveApplication";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";

const EmployeeLeaveManagementMain: FC<IEmployeeLeaveManagementMain> = ({
  holiday_list,
  getAllHolidaysAction,
  organization_list,
  selectOrganization,
  GetAllOrganizationAction,
  selected_organization_leave,
  designation_list,
  GetAllDesignationAction,
  allLeaveManagementDetails,
  getAllLeaveManagementData,
  getDesignationWiseLeaveAction,
  GetAllLeaveTypeAction,
  clearDesignationWiseLeave,
  user,
  leave_types,
  designationWiseLeave,
  designationWiseEmp,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  leaveAmountData,
  calculateLeaveDaysAction,
  leaveDaysCount,
  applyLeaveAction,
  getLeaveDetailsAction,
  leaveDetailsData,
  leave_calendar,
  LeaveCalendarDateAction,
  resetLeaveDaysCalculationState,
  editLeaveAction,
  cancelLeaveAction,
  getStateByAddressIdAction,
  address,
  getHolidayByEmployeeAction,
  employee_holiday_list,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}): JSX.Element => {
  const [addLeaveModal, setAddLeaveModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  return (
    <Box className="employeeLeaveManagementMain">
      <EmployeeLeaveCalendar
        holidayList={holiday_list}
        getAllHolidaysAction={getAllHolidaysAction}
        leave_calendar={leave_calendar}
        LeaveCalendarDateAction={LeaveCalendarDateAction}
        setAddLeaveModal={setAddLeaveModal}
        user={user}
        setStartDate={setStartDate}
        leaveDetailsData={leaveDetailsData}
        getStateByAddressIdAction={getStateByAddressIdAction}
        address={address}
        getHolidayByEmployeeAction={getHolidayByEmployeeAction}
        employee_holiday_list={employee_holiday_list}
        natural_holiday_list={natural_holiday_list}
        naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
      />
      <EmployeeLeaveApplication
        selected_organization_leave={selected_organization_leave}
        GetAllOrganizationAction={GetAllOrganizationAction}
        holiday_list={holiday_list}
        selectOrganization={selectOrganization}
        designation_list={designation_list}
        getAllHolidaysAction={getAllHolidaysAction}
        user={user}
        organizationList={organization_list}
        GetAllDesignationAction={GetAllDesignationAction}
        allLeaveManagementDetails={allLeaveManagementDetails}
        getAllLeaveManagementData={getAllLeaveManagementData}
        getDesignationWiseLeaveAction={getDesignationWiseLeaveAction}
        clearDesignationWiseLeave={clearDesignationWiseLeave}
        designationWiseLeave={designationWiseLeave}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        designationWiseEmp={designationWiseEmp}
        getEmployeeByDesignationAction={getEmployeeByDesignationAction}
        getLeaveAmountAction={getLeaveAmountAction}
        leaveAmountData={leaveAmountData}
        calculateLeaveDaysAction={calculateLeaveDaysAction}
        leaveDaysCount={leaveDaysCount}
        leave_types={leave_types}
        applyLeaveAction={applyLeaveAction}
        getLeaveDetailsAction={getLeaveDetailsAction}
        leaveDetailsData={leaveDetailsData}
        resetLeaveDaysCalculationState={resetLeaveDaysCalculationState}
        setAddLeaveModal={setAddLeaveModal}
        addLeaveModal={addLeaveModal}
        startDate={startDate}
        setStartDate={setStartDate}
        editLeaveAction={editLeaveAction}
        cancelLeaveAction={cancelLeaveAction}
        natural_holiday_list={natural_holiday_list}
        naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
      />
    </Box>
  );
};

interface IEmployeeLeaveManagementMain {
  holiday_list: IHolidayList[];
  getAllHolidaysAction?: any;
  user?: userDetails;
  organization_list: OrganizationALlList[];
  selectOrganization?: any;
  GetAllOrganizationAction?: any;
  selected_organization_leave?: number;
  GetAllDesignationAction?: any;
  designation_list: AllDesignationList[];
  allLeaveManagementDetails: IAllLeaveManagement[];
  getAllLeaveManagementData?: any;
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  clearDesignationWiseLeave?: any;
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getEmployeeByDesignationAction?: any;
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  applyLeaveAction?: any;
  getLeaveDetailsAction?: any;
  leaveDetailsData: ILeaveDetailsData[];
  leave_calendar: ILeaveCalendar[];
  LeaveCalendarDateAction?: any;
  resetLeaveDaysCalculationState?: any;
  editLeaveAction?: any;
  cancelLeaveAction?: any;
  getStateByAddressIdAction?: any;
  address?: IAddress;
  getHolidayByEmployeeAction?: any;
  employee_holiday_list: IHolidayList[];
  naturalLeaveDaysActionByEmployee?: any;
  natural_holiday_list: INaturalHoliday[];
}

export default EmployeeLeaveManagementMain;
