import { Reportsmain } from "../../models/reportsModel";
import { ReportsActionsTypes } from "../actions/reportsAction";
import InitialState from "./initialState";

const initialState: Reportsmain = InitialState.reports;
export default function Reportasreducer(
  state: Reportsmain = initialState,
  action: any
) {
  switch (action.type) {
    case ReportsActionsTypes.PF_ESIC_Report_Lists_Success_Action:
      return {
        ...state,
        list: action.payload.list,
        count: action.payload.count,
      };
    case ReportsActionsTypes.PAYROLL_REPORT_LIST_SUCCESS_ACTION:
      return {
        ...state,
        payrollReport: action.payload.salaryReportData,
        totalSalCount: action.payload.totalSalCount,
        count: action.payload.count,
      };
    case ReportsActionsTypes.PAYROLL_REPORT_LIST_FOR_PDF_SUCCESS_ACTION:
      return {
        ...state,
        totalSalCountForPDF: action.payload.totalSalCountForPDF,
        reportForPDF: action.payload.reportForPDF,
      };
    case ReportsActionsTypes.Consolidated_Monthly_Report_Projectwise_SUCCESS_ACTION:
      return {
        ...state,
        projectwise_details_monthly_report: action.payload,
      };
    default:
      return state;
  }
}
