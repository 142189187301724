import { FC, Fragment } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React from "react";
import { Box, Chip, Grid, useMediaQuery } from "@mui/material";
import { EmpProject } from "../../../models/timingSheetModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface AllotedProjectsModalProps {
  open: boolean;
  handleClose: any;
  projects: EmpProject[] | undefined;
  setSelectedProjectsIds: any;
  selectedProjectsIds: number[];
}

const AllotedProjectsModal: FC<AllotedProjectsModalProps> = ({
  open,
  handleClose,
  projects,
  setSelectedProjectsIds,
  selectedProjectsIds,
}) => {
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  const handleProjectSelection = (selectedProjectsId: number) => {
    if (selectedProjectsIds.includes(selectedProjectsId)) {
      setSelectedProjectsIds((prev: number[]) =>
        prev.filter((self) => self !== selectedProjectsId)
      );
    } else {
      setSelectedProjectsIds((prev: number[]) => [...prev, selectedProjectsId]);
    }
  };
  console.log("open : ", open);
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Alloted Projects
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              padding: 1,
              margin: 2,
            }}
          >
            <Grid
              container
              width={matchesMobileView ? "100%" : "800px"}
              direction="row"
              gap={matchesMobileView ? 1 : 2}
              spacing={matchesMobileView ? 0 : 2}
              sx={{ overflow: "auto", mx: "auto" }}
            >
              {!!projects &&
                Array.isArray(projects) &&
                projects.map((self, index) => (
                  <Grid
                    item
                    key={index}
                    sm={12}
                    lg={5}
                    onClick={() => handleProjectSelection(self.project_id)}
                    sx={{
                      height: "auto",
                      borderRadius: "3%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#1565C0",
                      padding: "10px 0px",
                      color: "#fff",
                      fontWeight: 700,
                      cursor: "pointer",
                      position: "relative",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      mx: "auto",

                      width: "100%",
                    }}
                  >
                    {self.project_name}
                    {selectedProjectsIds.includes(self.project_id) ? (
                      <CheckCircleIcon
                        sx={{
                          position: "absolute",
                          top: "2%",
                          right: "2%",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {/* <Chip
                      label={self.project_name}
                      variant="outlined"
                      sx={{ width: "100%", padding: "10px 0px" }}
                    /> */}
                  </Grid>
                ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AllotedProjectsModal;
