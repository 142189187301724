import React, { useEffect, useState } from "react";
import AddPromotionView from "./AddPromotionView";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";
import {
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
} from "../../../stores/actions/organizationActions";
import {
  AllDesignationList,
  EmployeeTypeList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";
import {
  EmployeeAvailableListForPromotionAction,
  InitiatePromotionAction,
} from "../../../stores/actions/promotionAction";
import { useSnackbar } from "notistack";
import { Navigate, useNavigate } from "react-router-dom";
import {
  getAllEmployeeAction,
  getEmployeeAllDetailsAction,
} from "../../../stores/actions/employeeAction";
import {
  EmpList,
  SalaryDetail1,
  SalaryStructureHistory,
} from "../../../models/employeeModel";
import AddPromotionViewNew from "./AddPromotionViewNew";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import { ProjectList } from "../../../models/customerModel";
import { useForm } from "react-hook-form";
import { EmployeeListforPromotion } from "../../../models/promotionModel";

const AddPromotion = ({
  searchEmployeeList,
  searchByNameEmpAction,
  GetAllSalaryStructureAction,
  salary_structure_list,
  user,
  GetAllDesignationAction,
  designation_list,
  SalaryComponentEditAction,
  salary_structure,
  InitiatePromotionAction,
  getEmployeeAllDetailsAction,
  SalaryHistory,
  GetAllProjectAction,
  project_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  getAllEmployeeAction,
  employee_list,
  EmployeeAvailableListForPromotionAction,
  emp_list_promotions,
  total_size,
}: AddPromotionProps) => {
  console.log(user);
  
  useEffect(() => {
    GetAllSalaryStructureAction({
      organization_id: !!user?.project_detail && user?.project_detail.length> 0 && user?.project_detail[0]?.organization_id,
    });
    GetAllDesignationAction({ organization_id: null });
    GetAllProjectAction({ organization_id: null, customer_id: null });
    GetAllEmployeeTypeAction({ organization_id: null });
  }, []);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50,100]);
  const CreatedPromotion = (data: any) => {
    console.log("CreatedPromotion", data);
    let list_payload: any = {
      employee_id: null,
      promotion_status: null,
      revision_status: null,
      new_designation_id: null,
      new_salary_structure_id: null,
    };
    let payload: any = {
      data: data,
      enqueueSnackbar: enqueueSnackbar,
      navigateTo: navigateTo,
      data2: list_payload,
    };

    InitiatePromotionAction(payload);
    // console.log("InitiatePromotionAction", payload);
  };

  useEffect(() => {
    // getAllEmployeeAction({
    //   employee_id: !!getValues("employee_id") ? getValues("employee_id") : null,
    //   employee_type_id: !!getValues("employee_type_id")
    //     ? getValues("employee_type_id")
    //     : null,
    //   employee_name: !!getValues("employee_name")
    //     ? getValues("employee_name")
    //     : null,
    //   project_id: !!getValues("project_id") ? getValues("project_id") : null,
    // });
    EmployeeAvailableListForPromotionAction({
      designation_id: !!getValues("designation_id")
        ? getValues("designation_id")
        : null,
      salary_structure_id: !!getValues("salary_structure_id")
        ? getValues("salary_structure_id")
        : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pageindex, pagesize]);
  const FilterSubmit = (props: any) => {
    // getAllEmployeeAction({
    //   employee_id: !!props.employee_id ? props.employee_id : null,
    //   employee_type_id: !!props.employee_type_id
    //     ? props.employee_type_id
    //     : null,
    //   employee_name: !!props.employee_name ? props.employee_name : null,
    //   project_id: !!props.project_id ? props.project_id : null,
    // });
    EmployeeAvailableListForPromotionAction({
      designation_id: !!props.designation_id ? props.designation_id : null,
      salary_structure_id: !!props.salary_structure_id
        ? props.salary_structure_id
        : null,
      employee_id: !!props.employee_id ? props.employee_id : null,
      employee_type_id: !!props.employee_type_id
        ? props.employee_type_id
        : null,
      project_id: !!props.project_id ? props.project_id : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const [empDet, setEmpDet] = useState("");
  const ViewPromDet = (props: any) => {
    console.log("props", props);
    setEmpDet(props);
    getEmployeeAllDetailsAction({ employee_id: props.employee_id });
  };
  const ClearAllFilter = () => {
    // getAllEmployeeAction({
    //   employee_id: null,
    //   employee_type_id: null,
    //   employee_name: null,
    //   project_id: null,
    // });
    EmployeeAvailableListForPromotionAction({
      designation_id: null,
      salary_structure_id: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };

  return (
    <>
      {/* <AddPromotionView
        searchEmployeeList={searchEmployeeList}
        searchByNameEmpAction={searchByNameEmpAction}
        salary_structure_list={salary_structure_list}
        designation_list={designation_list}
        SalaryComponentEditAction={SalaryComponentEditAction}
        salary_structure={salary_structure}
        CreatedPromotion={CreatedPromotion}
        SalaryHistory={SalaryHistory}
        getEmployeeAllDetailsAction={getEmployeeAllDetailsAction}
      /> */}
      <AddPromotionViewNew
        searchEmployeeList={searchEmployeeList}
        searchByNameEmpAction={searchByNameEmpAction}
        project_list={project_list}
        employee_type_list={employee_type_list}
        getValues={getValues}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        handleSubmit={handleSubmit}
        FilterSubmit={FilterSubmit}
        employee_list={employee_list}
        designation_list={designation_list}
        salary_structure_list={salary_structure_list}
        salary_structure={salary_structure}
        SalaryComponentEditAction={SalaryComponentEditAction}
        CreatedPromotion={CreatedPromotion}
        ViewPromDet={ViewPromDet}
        SalaryHistory={SalaryHistory}
        empDet={empDet}
        getEmployeeAllDetailsAction={getEmployeeAllDetailsAction}
        ClearAllFilter={ClearAllFilter}
        emp_list_promotions={emp_list_promotions}
        pagesize={pagesize}
        pageindex={pageindex}
        rowperpage={rowperpage}
        total_size={total_size}
        SetpageSize={SetpageSize}
        Setpageindex={Setpageindex}
      />
    </>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    searchEmployeeList: state.timingSheet.employeeList,
    salary_structure_list: state.organization.salary_structure_list,
    user: state.user.userDetails,
    designation_list: state.organization.designation_list,
    salary_structure: state.organization.salary_structure_edit,
    SalaryHistory: state.employee.SalaryHistory,
    project_list: state.customer.project_list,
    employee_type_list: state.organization.employee_type_list,
    employee_list: state.employee.employee_list,
    emp_list_promotions: state.promotion.emp_list_promotions,
    total_size: state.promotion.emp_list_promotions?.count,
  };
};

const mapDispatchToProps = {
  searchByNameEmpAction,
  GetAllSalaryStructureAction,
  GetAllDesignationAction,
  SalaryComponentEditAction,
  InitiatePromotionAction,
  getEmployeeAllDetailsAction,
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  getAllEmployeeAction,
  EmployeeAvailableListForPromotionAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPromotion);
interface AddPromotionProps {
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  GetAllSalaryStructureAction?: any;
  salary_structure_list: SalaryStructureList[];
  user?: userDetails;
  GetAllDesignationAction?: any;
  designation_list: AllDesignationList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  InitiatePromotionAction?: any;
  getEmployeeAllDetailsAction?: any;
  SalaryHistory: SalaryStructureHistory[];
  GetAllProjectAction?: any;
  project_list: ProjectList[];
  GetAllEmployeeTypeAction?: any;
  employee_type_list: EmployeeTypeList[];
  getAllEmployeeAction?: any;
  employee_list: EmpList[];
  EmployeeAvailableListForPromotionAction?: any;
  emp_list_promotions?: EmployeeListforPromotion;
  total_size?: number;
}
