import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { AvailableEmpList } from "../../../models/customerModel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  ApplicablePostDetail,
  IDetails,
  IEmpDetails,
  ILevelMaster,
  IProjectDashboardDetails,
  Level,
} from "../../../interfaces/projectDashboard.interface";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { useSnackbar } from "notistack";
function EmployeeAllocationPopover({
  anchorEl2,
  setAnchorEl2,
  setSearchedEmp,
  searchedEmp,
  available_emp_list,
  posts,
  levelDetails,
  levelMaster,
  projectDashbaordData,
  SubmitPopData,
  AllocatedEmp,
  SetAllocatedEmp,
}: EmployeeAllocationPopoverProps) {
  const { enqueueSnackbar } = useSnackbar();
  const EmpSet = (data: any) => {
    let Temp: any = JSON.parse(JSON.stringify(data));
    let Empavail: any[] = [];
    Empavail = JSON.parse(JSON.stringify(AllocatedEmp));
    if (!Empavail.find((m) => m.employee_id == data.employee_id)) {
      Empavail.push(Temp);
    }
    SetAllocatedEmp(Empavail);
  };
  const [rows, setRows] = useState<any[]>(AllocatedEmp);
  const [level2, SetLevel2] = useState<any[]>([]);
  const [level3, SetLevel3] = useState<any[]>([]);
  const [level4, SetLevel4] = useState<any[]>([]);
  const [level5, SetLevel5] = useState<any[]>([]);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const columns: string[] = ["Emp.ID", "Name", "Action"];
  const cancelSearch = () => {
    setSearchedEmp("");
  };
  const open = Boolean(anchorEl2);
  const handleClose = () => {
    setAnchorEl2(null);
    setRows([]);
    SetAllocatedEmp([]);
  };
  const removeEmp = (row: any) => {
    let temp: any = JSON.parse(JSON.stringify(AllocatedEmp));
    temp = temp.filter((m: any) => m.employee_id != row.employee_id);
    setRows(temp);
    SetAllocatedEmp(temp);
  };
  const ChangeLevelOne = (data: number) => {
    let level2 = levelDetails?.find((m) => m.level1_id == +data)?.level_two;
    if (!!level2 && !Array.isArray(level2)) {
      SetLevel2([level2]);
    } else if (!!level2) {
      SetLevel2(level2);
    }
    SetLevel3([]);
    SetLevel4([]);
    SetLevel5([]);
  };
  const SelectLevel2 = (data: number) => {
    let level3 = level2.find((m) => m.level2_id == +data)?.level_three;
    if (!!level3 && !Array.isArray(level3)) {
      SetLevel3([level3]);
    } else if (!!level3) {
      SetLevel3(level3);
    }
    SetLevel4([]);
    SetLevel5([]);
  };
  const Level3Select = (data: number) => {
    let level4 = level3.find((m) => m.level3_id == +data)?.level_four;
    if (!!level4 && !Array.isArray(level4)) {
      SetLevel4([level4]);
    } else if (!!level4) {
      SetLevel4(level4);
    }
    SetLevel5([]);
  };
  const LevelFourChange = (data: number) => {
    let level5 = level4.find((m) => m.level3_id == +data)?.level_five;
    if (!!level5 && !Array.isArray(level5)) {
      SetLevel5([level5]);
    } else if (!!level5) {
      SetLevel5(level5);
    }
  };
  useEffect(() => {
    setRows(AllocatedEmp);
  }, [AllocatedEmp]);
  const onSubmit = (data: any) => {
    handleClose();
    SubmitPopData(data);
  };
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Grid
          container
          minWidth={700}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Grid item xs={11.5}>
              <Typography style={{ color: "#ffff", paddingLeft: "40%" }}>
                Employee Allocation
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Tooltip title="Exit" onClick={() => handleClose()}>
                <IconButton
                  size="medium"
                  style={{ paddingTop: "0" ,color:"#ffffff"}}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Grid item xs={11}>
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  placeholder="Enter Employee ID or Employee Name"
                  id="fullWidth"
                  value={searchedEmp}
                  onChange={(e: any) => setSearchedEmp(e.target.value)}
                  InputProps={{
                    endAdornment:
                      !!searchedEmp && searchedEmp.length > 0 ? (
                        <IconButton
                          color="primary"
                          onClick={() => cancelSearch()}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <IconButton color="primary">
                          <SearchIcon />
                        </IconButton>
                      ),
                  }}
                />
                <Grid
                  style={{
                    maxHeight: 400,
                    overflow: "auto",
                    zIndex: 3,
                    position: "absolute",
                    width: "82%",
                  }}
                >
                  {!!available_emp_list &&
                    !!searchedEmp &&
                    searchedEmp.length > 2 &&
                    available_emp_list.map((n) => (
                      <Card
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          bgcolor: "#EEEEEE",
                        }}
                        key={n.employee_id}
                        onClick={() => (EmpSet(n), cancelSearch())}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 1,
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                          >
                            {n.employee_name}({n.employee_id})
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                </Grid>
              </Grid>
              <Grid item xs={11} paddingTop={2} style={{ zIndex: 2 }}>
                <TableContainer sx={{ borderRadius: 1 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    //   style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {columns.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows.map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.employee_id}
                          >
                            <StyledTableCell align="left" key={columns[0]}>
                              {row.employee_id}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[1]}>
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[2]}>
                              <Tooltip
                                title="Remove"
                                onClick={() => removeEmp(row)}
                              >
                                <IconButton color="error">
                                  <RemoveCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {!!rows && rows.length <= 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={6}>
                            <div style={{ color: "red", textAlign: "center" }}>
                              No Data Found{" "}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={11} paddingTop={1}>
                {!!levelMaster?.level1_key_name && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        {levelMaster?.level1_key_name}{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"level1_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={!!levelDetails ? levelDetails : []}
                            keys={"level1_id"}
                            label={"level1_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), ChangeLevelOne(+value)
                            )}
                            placeholder={levelMaster?.level1_key_name}
                            value={!!value ? value : ""}
                            errors={errors.level1_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {!!levelMaster?.level2_key_name && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        {levelMaster?.level2_key_name}{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"level2_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={level2}
                            keys={"level2_id"}
                            label={"level2_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), SelectLevel2(+value)
                            )}
                            placeholder={levelMaster?.level2_key_name}
                            value={!!value ? value : ""}
                            errors={errors.level2_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {!!levelMaster?.level3_key_name && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        {levelMaster?.level3_key_name}{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"level3_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={level3}
                            keys={"level3_id"}
                            label={"level3_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), Level3Select(+value)
                            )}
                            placeholder={levelMaster?.level3_key_name}
                            value={!!value ? value : ""}
                            errors={errors.level3_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {!!levelMaster?.level4_key_name && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        {levelMaster?.level4_key_name}{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"level2_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={level4}
                            keys={"level4_id"}
                            label={"level4_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), LevelFourChange(+value)
                            )}
                            placeholder={levelMaster?.level4_key_name}
                            value={!!value ? value : ""}
                            errors={errors.level4_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {!!levelMaster?.level5_key_name && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        {levelMaster?.level5_key_name}{" "}
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"level2_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={level5}
                            keys={"level5_id"}
                            label={"level5_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={levelMaster?.level5_key_name}
                            value={!!value ? value : ""}
                            errors={errors.level5_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Manager
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"employee_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={
                            !!projectDashbaordData?.managerList
                              ? projectDashbaordData?.managerList
                              : []
                          }
                          keys={"employee_id"}
                          label={"employee_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Manager"}
                          value={!!value ? value : ""}
                          errors={errors.employee_id}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Post
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"post_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={posts}
                          keys={"post_id"}
                          label={"post_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Post"}
                          value={!!value ? value : ""}
                          errors={errors.post_id}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  size="medium"
                  variant="contained"
                  disabled={AllocatedEmp <= 0}
                >
                  Allocate
                </Button>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default EmployeeAllocationPopover;
interface EmployeeAllocationPopoverProps {
  anchorEl2?: any;
  setAnchorEl2?: any;
  setSearchedEmp?: any;
  searchedEmp?: any;
  available_emp_list: AvailableEmpList[];
  posts: ApplicablePostDetail[];
  levelDetails?: Level[];
  levelMaster?: ILevelMaster;
  projectDashbaordData?: IProjectDashboardDetails;
  SubmitPopData?: any;
  AllocatedEmp?: any;
  SetAllocatedEmp?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2F58CD",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 12, sm: 10, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
