import React, { useEffect } from 'react'
import { ProfessionalDetail1 } from '../../../models/employeeModel';
import { useSnackbar } from 'notistack';
import { Box, Button, Grid, IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,  Tooltip, Typography, styled, tableCellClasses } from '@mui/material';
import { useForm } from 'react-hook-form';
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const EmployeeEditProfessionalDetailsPopover = ({
    anchorEl,
    setAnchorEl,
    professional_details,
    SubmitPopData,
    setaddProDetail,
    OnChangeProcation,
    DeleteProAction,
    addProDetail,
    addprofessionalDetails
  }: EmployeeEditProfessionalDetailsPopoverProps) => {

    const { enqueueSnackbar } = useSnackbar();
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };
    const columns1: any[] = [
      "Course Name",
      "Start Date",
      "End Date",
      "Remarks",
      "Action",
    ];
    const {
      control,
      register,
      handleSubmit,
      formState,
      setValue,
      getValues,
      setError,
      clearErrors,
      reset,
      formState: { errors },
    } = useForm();
    useEffect(() => {
      if (!!professional_details) {
        setaddProDetail(professional_details);
      }
    }, [professional_details]);
    const onSubmit =(data: any)=>{
      handleClose()
      SubmitPopData(data)
    }
    // console.log(setaddProDetail,"asdad");
  return (
    <>
    <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>
              {" "}
              professional Qualification
            </Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              item
              marginLeft={9}
              xs={10.5}
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  //   style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!addProDetail &&
                      addProDetail.map((row: any, index: number) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={columns1[0]}>
                            {/* {row.organization_name} */}
                            {/* Emdee */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeProcation(
                                    value.target.value,
                                    "course_name",
                                    index
                                  )
                                }
                                value={row.course_name}
                                placeholder={"Course Name"}
                                inputProps={{ maxLength: 100}}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[1]}>
                            {/* {row.department_name} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeProcation(
                                    value.target.value,
                                    "start_date",
                                    index
                                  )
                                }
                                value={row.board_name}
                                placeholder={"Start Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[2]}>
                            {/* {row.department_name} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeProcation(
                                    value.target.value,
                                    "end_date",
                                    index
                                  )
                                }
                                value={row.subject}
                                placeholder={"End Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[3]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeProcation(
                                    value.target.value,
                                    "remarks",
                                    index
                                  )
                                }
                                value={row.remarks}
                                inputProps={{ maxLength: 80}}
                                placeholder={"remarks"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[4]}>
                            {addProDetail.length > 0 && (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => DeleteProAction(index)}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={3}>
                <Button
                  onClick={() => addprofessionalDetails()}
                  size="medium"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default EmployeeEditProfessionalDetailsPopover

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#3A98B9",
      color: "#ffff",
      fontWeight: 600,
      fontSize: 14,
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: { md: 14, sm: 12, xs: 8 },
      padding: "5px",
      color: "#363A44",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F9FAFC",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  interface EmployeeEditProfessionalDetailsPopoverProps {
    anchorEl?: any;
    setAnchorEl?: any;
    professional_details:ProfessionalDetail1[];
    SubmitPopData?: any;
    setaddProDetail?: any;
    OnChangeProcation?: any;
    DeleteProAction?: any;
    addProDetail?: any;
    addprofessionalDetails?: any;
  }