import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { baseUrl, environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import {
  DocumentsDetail,
  EmployeeAllDetail,
} from "../../../models/employeeModel";
import { userDetails } from "../../../models/userModels";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import ImageUploader from "./ImageUploader";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { StateList } from "../../../models/customerModel";
const FileDownload = require("js-file-download");

function EmployeeEditBasicDetailsPopover({
  anchorEl,
  setAnchorEl,
  employeeDetails,
  documents_details,
  // fileHandleEvent,
  SetDocument,
  // downloadDoc,
  SubmitPopData,
  documents,
  user,
  state_list,
  setchangeDocAadhar,
  setchangeDocVotar,
  setchangeDocPan
}: EmployeeEditBasicDetailsPopoverProps) {
  const { enqueueSnackbar } = useSnackbar();
  const religion: any[] = [
    {
      key: "Hinduism",
      value: "Hinduism",
    },
    {
      key: "Muslims",
      value: "Muslims",
    },
    {
      key: "Sikhs",
      value: "Sikhs",
    },
    {
      key: "Christians",
      value: "Christians",
    },
    {
      key: "Buddhists",
      value: "Buddhists",
    },
    {
      key: "Zoroastrians",
      value: "Zoroastrians",
    },
  ];
  const MaritalStatus: any[] = [
    {
      key: "Married",
      value: "Married",
    },
    {
      key: "Widowed",
      value: "Widowed",
    },
    {
      key: "Separated",
      value: "Separated",
    },
    {
      key: "Divorced",
      value: "Divorced",
    },
    {
      key: "Single",
      value: "Single",
    },
  ];
  const BloodGroup: any[] = [
    {
      key: "A+",
      value: "A+",
    },
    {
      key: "A-",
      value: "A-",
    },
    {
      key: "B+",
      value: "B+",
    },
    {
      key: "B-",
      value: "B-",
    },
    {
      key: "O+",
      value: "O+",
    },
    {
      key: "O-",
      value: "O-",
    },
    {
      key: "AB+",
      value: "AB+",
    },
    {
      key: "AB-",
      value: "AB-",
    }
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!!employeeDetails && !!documents_details) {
      setValue("employee_name", employeeDetails.employee_name);
      setValue("father_name", employeeDetails.father_name);
      setValue("employee_email", employeeDetails.employee_email);
      setValue("employee_mob_no", employeeDetails.employee_mob_no);
      setValue(
        "employee_marital_status",
        employeeDetails.employee_marital_status
      );
      setValue("religion", employeeDetails.religion);
      setValue("base_location", employeeDetails.base_location);
      setValue("blood_group", employeeDetails.blood_group);
      setValue(
        "aadhar_no",
        documents_details.find((m) => m.document_type === "AADHAR")?.document_no
      );
      setValue(
        "epic_no",
        documents_details.find((m) => m.document_type === "VOTAR")?.document_no
      );
      setValue(
        "pan_no",
        documents_details.find((m) => m.document_type === "PAN")?.document_no
      );
      SetDocument(documents_details);
    }
  }, [employeeDetails, documents_details]);
  const fileHandleEvent = (event: any, type: string) => {
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${event.target.files[0].name
        }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `NEW_CAD`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            let doc = JSON.parse(JSON.stringify(documents));
            if (!!doc.find((m: any) => m.document_type == type)) {
              doc.find((m: any) => m.document_type == type).document_image =
                file_name;
            } else {
              doc.push({
                document_type: type,
                document_no: "",
                document_image: file_name,
              });
            }
            console.log("from Emp Edit basic", doc)
            SetDocument(doc);
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
  };
  const downloadDoc = (type: string) => {
    if (
      !!documents.find((m: any) => m.document_type == type).document_image
    ) {
      let doc = documents.find((m: any) => m.document_type == type);
      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: `NEW_CAD`,
          fileName: doc?.document_image,
        },
      })
        .then((response) => {
          FileDownload(response.data, doc?.document_image);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };
  const imageChange = (type: string) => {
    let docs = JSON.parse(JSON.stringify(documents));
    docs = docs.filter((m: any) => m.document_type != type);
    SetDocument(docs);
  };
  const onSubmit = (data: any) => {
    handleClose()
    SubmitPopData(data)
  }
  const [actAadhar, setactAadhar] = useState("")
  const [actVotar, setactVotar] = useState("")
  const [actPan, setactPan] = useState("")
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Basic Details</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <form>
              <Grid container justifyContent="start" alignItems="center">
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>Name <span style={{ color: "red", fontSize: 14 }}>*</span></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"employee_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{ maxLength: 100 }}
                        value={value}
                        placeholder={"Name of Employee"}
                        error={!!errors["employee_name"]}
                        helperText={
                          errors.employee_name &&
                          `${errors.employee_name.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>
                    Father's Name <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"father_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{ maxLength: 100 }}
                        value={value}
                        placeholder={"Father's Name"}
                        error={!!errors["father_name"]}
                        helperText={
                          errors.father_name &&
                          `${errors.father_name.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>Email ID</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"employee_email"}
                    defaultValue={""}
                    rules={{
                      required: false, pattern: {
                        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                        message: "Invalid Email ID"
                      }
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"email"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        inputProps={{ maxLength: 90 }}
                        placeholder={"Email ID"}
                        error={!!errors["employee_email"]}
                        helperText={
                          errors.employee_email &&
                          `${errors.employee_email.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>
                    Mobile No. <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"employee_mob_no"}
                    defaultValue={""}
                    rules={{
                      required: true,
                      minLength: 10,
                      maxLength: 12,
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        placeholder={"Mobile No"}
                        error={!!errors["employee_mob_no"]}
                        helperText={
                          errors.employee_mob_no &&
                          `${errors.employee_mob_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>
                    Marital Status <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"employee_marital_status"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={MaritalStatus}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Marital Status"}
                        value={!!value ? value : ""}
                        errors={errors.employee_marital_status}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>Religion <span style={{ color: "red", fontSize: 14 }}>*</span></Typography>
                </Grid>
                <Grid item xs={6} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"religion"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={religion}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"religion"}
                        value={!!value ? value : ""}
                        errors={errors.religion}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>Blood Group<span style={{ color: "red", fontSize: 14 }}>*</span></Typography>
                </Grid>
                <Grid item xs={6} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"blood_group"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={BloodGroup}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Blood Group"}
                        value={!!value ? value : ""}
                        errors={errors.blood_group}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>Base Location <span style={{ color: "red", fontSize: 14 }}>*</span></Typography>
                </Grid>
                <Grid item xs={6} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"base_location"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={state_list}
                        style={{ width: "100.9%" }}
                        keys={"state_name"}
                        label={"state_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Base Location"}
                        value={!!value ? value : ""}
                        errors={!!errors["base_location"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>
                    Aadhar No. <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"aadhar_no"}
                    defaultValue={""}
                    rules={{
                      required: true,
                      minLength: 12,
                      maxLength: 12,
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={(newValue) => (onChange(newValue), setactAadhar(newValue.target.value))}
                        value={value}
                        placeholder={"Aadhar No"}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        error={!!errors["aadhar_no"]}
                        helperText={
                          errors.aadhar_no &&
                          `${errors.aadhar_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {
                (!!documents_details.find((m) => m.document_type === "AADHAR")?.document_no || !!actAadhar) && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography style={{ fontWeight: 400 }}>
                        Aadhar Upload <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ImageUploader
                        documents={documents}
                        type={"AADHAR"}
                        fileHandleEvent={fileHandleEvent}
                        imageChange={imageChange}
                        downloadDoc={downloadDoc}
                        setchangeDocAadhar={setchangeDocAadhar}
                        setchangeDocPan={setchangeDocPan}
                        setchangeDocVotar={setchangeDocVotar}
                      />
                    </Grid>
                  </Grid>
                )
              }
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>PAN No.</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"pan_no"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Invalid PAN No.",
                      },
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        onChange={(newValue) => (onChange(newValue), setactPan(newValue.target.value))}
                        value={value}
                        placeholder={"PAN No"}
                        error={!!errors["pan_no"]}
                        helperText={
                          errors.pan_no &&
                          `${errors.pan_no.message}`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {
                (!!documents_details.find((m) => m.document_type === "PAN")?.document_no || !!actPan) && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography style={{ fontWeight: 400 }}>
                        PAN Upload
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ImageUploader
                        documents={documents}
                        type={"PAN"}
                        fileHandleEvent={fileHandleEvent}
                        imageChange={imageChange}
                        downloadDoc={downloadDoc}
                        setchangeDocAadhar={setchangeDocAadhar}
                        setchangeDocPan={setchangeDocPan}
                        setchangeDocVotar={setchangeDocVotar}
                      />
                    </Grid>
                  </Grid>)}
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                paddingTop={1}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>EPIC No.</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={"epic_no"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^([a-zA-Z]){3}([0-9]){7}?$/,
                        message: "Invalid EPIC No.",
                      },
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        onChange={(newValue) => (onChange(newValue), setactVotar(newValue.target.value))}
                        value={value}
                        placeholder={"EPIC No"}
                        error={!!errors["epic_no"]}
                        helperText={
                          errors.epic_no &&
                          `${errors.epic_no.message}`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {
                (!!documents_details.find((m) => m.document_type === "VOTAR")?.document_no || !!actVotar) && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography style={{ fontWeight: 400 }}>
                        EPIC Upload
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ImageUploader
                        documents={documents}
                        type={"VOTAR"}
                        fileHandleEvent={fileHandleEvent}
                        imageChange={imageChange}
                        downloadDoc={downloadDoc}
                        setchangeDocAadhar={setchangeDocAadhar}
                        setchangeDocPan={setchangeDocPan}
                        setchangeDocVotar={setchangeDocVotar}
                      />
                    </Grid>
                  </Grid>)}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  //   marginRight: "2rem",
                  marginRight: "0px",
                  marginTop: "13px",
                  marginBottom: 1,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default EmployeeEditBasicDetailsPopover;

interface EmployeeEditBasicDetailsPopoverProps {
  user?: userDetails;
  anchorEl?: any;
  setAnchorEl?: any;
  employeeDetails?: EmployeeAllDetail;
  documents_details: DocumentsDetail[];
  // fileHandleEvent?: any;
  SetDocument?: any;
  // downloadDoc?: any;
  SubmitPopData?: any;
  documents?: any;
  state_list: StateList[];
  setchangeDocAadhar: any;
  setchangeDocVotar: any;
  setchangeDocPan: any;
}
