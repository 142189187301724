import React from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import EmployeeList from "./EmployeeList";
import AddEmployee from "./AddEmployee";
import OnboardingRequestApprovalReject from "./OnboardingRequestAppRej";
import { Grid, Typography, Divider } from "@mui/material";
import { environment } from "../../environment";
import ReferralList from "./ReferralList";
import EmployeeDetails from "./EmployeeDetails";
import AllCandidate from "./AllCandidate";
import ManageProfile from "./ManageProfile";
import BulkUpload from "./BulkUpload";
import LevelsMaster from "../Customer/Levels";

const Employment = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              // color: "#50535A",
              // fontWeight: "bold",
              // fontFamily: "Poppins, sans-serif",
              // fontSize: 18,
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              // color: "#fff",
              // fontSize: 14,
              // fontFamily: "Poppins, sans-serif",
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="employee-list" element={<EmployeeList />} />
        <Route path="add-employee" element={<AddEmployee />} />
        <Route path="referral-list" element={<ReferralList />} />
        <Route
          path="candidate_details/:id/:id2"
          element={<EmployeeDetails />}
        />
        <Route path="all-candidate" element={<AllCandidate />} />
        <Route
          path="onboarding-request-app-rej"
          element={<OnboardingRequestApprovalReject />}
        />
        <Route path="manage-profile/:id" element={<ManageProfile />} />
        <Route path="bulk-upload-employee" element={<BulkUpload />} />
        <Route
          path="/"
          element={
            <Navigate to={`/${pathname.split("/")[1]}/manpower-entry`} />
          }
        />
      </Routes>
    </>
  );
};

export default Employment;
