import { Cookies } from "react-cookie";
import { ITimeSheetUpdatePayload } from "../../models/timingSheetModel";
import {
  getTimingSheetListService,
  getTimingSheetPendingApprovalListService,
  initiateTimeSheetService,
  searchNameService,
  TimeSheetHistoryService,
  UpdateMonthlyTimeSheetService,
  UpdateTimingSheetApprovalStatusService,
} from "../../services/timingSheetService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";

export enum TimingSheetActionTypes {
  Get_Timing_Sheet_List_Success_Action = "[TIMINGSHEET] Timing Sheet List Success Action",
  Get_Timing_Sheet_Pending_Approval_List_Success_Action = "[TIMINGSHEET] Timing Sheet Pending_Approval List Success Action",
  Update_Approve_Timing_Sheet_Status_Success_Action = "[TIMINGSHEET] Update Approve Timing Sheet Status Success Action",
  Clear_Time_Sheet_Action = "[TIMINGSHEET] Clear Time Sheet",
  Go_From_WorkList_Action = "[TIMINGSHEET] Go From Worklist Action",
  Clear_From_worklist_Action = "[TIMINGSHEET] Clear From Worklist Action",
  Search_By_Name_Or_Id_Action = "[TIMINGSHEET] Search By Name Or Id",
  // GET_All_Employee_WITH_PDF="[TIMINGSHEET] GET All Employee WITH PDF",
  Time_Sheet_History_Success_Action = "[TIMINGSHEET] Time Sheet History Success Action",
}

export const GetTimingSheetListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All TimingSheet List. Please Wait...",
        type: 2,
      })
    );
    return getTimingSheetListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetTimingSheetListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetTimingSheetListSuccessAction = (data: any) => {
  return {
    type: TimingSheetActionTypes.Get_Timing_Sheet_List_Success_Action,
    payload: data,
  };
};

export const GetTimingSheetPendingApprovalListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return getTimingSheetPendingApprovalListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetTimingSheetPendingApprovalListSuccessAction(response.data)
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetTimingSheetPendingApprovalListSuccessAction = (data: any) => {
  return {
    type: TimingSheetActionTypes.Get_Timing_Sheet_Pending_Approval_List_Success_Action,
    payload: data,
  };
};

export const UpdateApproveTimingSheetStatusAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Timing Update Status. Please Wait...",
        type: 2,
      })
    );
    return UpdateTimingSheetApprovalStatusService(payload.data1)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(GetTimingSheetPendingApprovalListAction(payload.data2));
          dispatch(UpdateApproveTimingSheetSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };
};

export const UpdateApproveTimingSheetSuccessAction = () => {
  return {
    type: TimingSheetActionTypes.Update_Approve_Timing_Sheet_Status_Success_Action,
  };
};

export const UpdateMonthlyTimeSheetAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Timing Update Status. Please Wait...",
        type: 2,
      })
    );
    const formData = payload.formData as ITimeSheetUpdatePayload;
    return UpdateMonthlyTimeSheetService(formData)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(
            GetTimingSheetListAction({
              employee_id: formData.employee_id,
              month: formData.month,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

export const clearTimeSheetAction = () => (dispatch: any, getState: any) => {
  dispatch({ type: TimingSheetActionTypes.Clear_Time_Sheet_Action });
};

export const initiateTimesheetAction =
  ({
    employee_id,
    month,
    enqueueSnackbar,
  }: {
    employee_id: string;
    month: string;
    enqueueSnackbar: any;
  }) =>
  (dispatch: any, getState: any) => {
    return initiateTimeSheetService({ employee_id, month })
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(
            GetTimingSheetListAction({
              employee_id: employee_id,
              month: month,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

export const goToTimingSheetFromWorklistAction =
  ({ employee_id, month }: { employee_id: string; month: string }) =>
  (dispatch: any, getState: any) => {
    console.log("action of workilist hit", employee_id, month);
    dispatch({
      type: TimingSheetActionTypes.Go_From_WorkList_Action,
      payload: { employee_id, month },
    });
  };

export const ClearTimingSheetFromWorklistAction =
  () => (dispatch: any, getState: any) => {
    dispatch({
      type: TimingSheetActionTypes.Clear_From_worklist_Action,
    });
  };

export const searchByNameEmpAction =
  (searchQuery: string, empType?: string) => (dispatch: any, getState: any) => {
    return searchNameService(searchQuery ? searchQuery : "", empType)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: TimingSheetActionTypes.Search_By_Name_Or_Id_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

  export const TimingSheetHistoryAction = (payload: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "All TimingSheet List. Please Wait...",
          type: 2,
        })
      );
      return TimeSheetHistoryService(payload)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            dispatch(TimingSheetHistoryActionSuccessAction(response.data));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            let cook = new Cookies();
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            cook.remove("user", { path: "/" });
            dispatch(LogOutAction());
          } else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          } else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  
  export const TimingSheetHistoryActionSuccessAction = (data: any) => {
    return {
      type: TimingSheetActionTypes.Time_Sheet_History_Success_Action,
      payload: data,
    };
  };