import {
  Grid,
  Typography,
  TextField,
  Divider,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import EmployeeDetails from ".";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import { ViewEmployee } from "../../../models/employeeModel";
import { userDetails } from "../../../models/userModels";
import ImageUploader from "./ImageUploader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BasicDetails = ({
  control,
  errors,
  documents,
  fileHandleEvent,
  SetDocument,
  downloadDoc,
  edit_candidate,
  user,
  edit_flag,
  SetEditFlag,
  employee_detail,
  id2,
}: BasicDetailsProps) => {
  const religion: any[] = [
    {
      key: "Hinduism",
      value: "Hinduism",
    },
    {
      key: "Muslims",
      value: "Muslims",
    },
    {
      key: "Sikhs",
      value: "Sikhs",
    },
    {
      key: "Christians",
      value: "Christians",
    },
    {
      key: "Buddhists",
      value: "Buddhists",
    },
    {
      key: "Zoroastrians",
      value: "Zoroastrians",
    },
  ];
  const MaritalStatus: any[] = [
    {
      key: "Married",
      value: "Married",
    },
    {
      key: "Widowed",
      value: "Widowed",
    },
    {
      key: "Separated",
      value: "Separated",
    },
    {
      key: "Divorced",
      value: "Divorced",
    },
    {
      key: "Single",
      value: "Single",
    },
  ];
  const genderOption: any[] = [
    {
      key: "Male",
      value: "Male",
    },
    {
      key: "Female",
      value: "Female",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  const BloodGroup: any[] = [
    {
      key: "A+",
      value: "A+",
    },
    {
      key: "A-",
      value: "A-",
    },
    {
      key: "B+",
      value: "B+",
    },
    {
      key: "B-",
      value: "B-",
    },
    {
      key: "O+",
      value: "O+",
    },
    {
      key: "O-",
      value: "O-",
    },
    {
      key: "AB+",
      value: "AB+",
    },
    {
      key: "AB-",
      value: "AB-",
    },
  ];
  const imageChange = (type: string) => {
    let docs = JSON.parse(JSON.stringify(documents));
    docs = docs.filter((m: any) => m.document_type != type);
    SetDocument(docs);
  };
  const [actAadhar, setactAadhar] = useState("");
  const [actVotar, setactVotar] = useState("");
  const [actPan, setactPan] = useState("");
  console.log(actAadhar, "asd");
  return (
    <>
      <Grid
        container
        justifyContent="end"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 15, marginLeft: "-4%" }}
      >
        {edit_candidate ||
          (!!user &&
            environment.global_veriable.superAdminLevel.includes(
              user.designation
            ) &&
            employee_detail?.status == "APPLIED" && (
              <>
                <Switch
                  value={edit_flag}
                  onChange={(event) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>
                  Edit Candidate
                </Typography>
              </>
            ))}
      </Grid>
      <Grid item xs={10} marginTop={1} marginBottom={1}>
        <Typography
          style={{
            fontWeight: "700",
            fontSize: 18,
            fontFamily: "Poppins, sans-serif",
            textAlign: "left",
            marginLeft: "8%",
            // marginBottom: 20,
          }}
        >
          Basic Details
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid item xs={2.5}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "32%",
              // textAlign: "center"
            }}
          >
            Name of Candidate
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Controller
            control={control}
            name={"candidate_name"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Name of Candidate"}
                error={!!errors["candidate_name"]}
                inputProps={{
                  readOnly:
                    !!id2 && +id2 == 1
                      ? false
                      : !!user &&
                        environment.global_veriable.superAdminLevel.includes(
                          user.designation
                        )
                      ? edit_flag
                      : !edit_candidate,
                  maxLength: 100,
                }}
                helperText={
                  errors.candidate_name &&
                  `${errors.candidate_name.message}* This field is Required`
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid
          item
          xs={2.5}
          // justifyContent="space-between"
          // alignItems="start"
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "32%",
              // textAlign: "center"
            }}
          >
            Father's Name
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Controller
            control={control}
            name={"father_name"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                inputProps={{
                  readOnly:
                    !!id2 && +id2 == 1
                      ? false
                      : !!user &&
                        environment.global_veriable.superAdminLevel.includes(
                          user.designation
                        )
                      ? edit_flag
                      : !edit_candidate,
                  maxLength: 100,
                }}
                value={value}
                placeholder={"Father's Name"}
                error={!!errors["father_name"]}
                helperText={
                  errors.father_name &&
                  `${errors.father_name.message}* This field is Required`
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}

        // width={"100%"}
      >
        <Grid item xs={6} justifyContent="space-between">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"

            // width={"100%"}

            // width={"100%"}
          >
            <Grid
              item
              xs={3}

              // justifyContent="space-between"
              // alignItems="start"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "53%",
                  // textAlign: "center"
                }}
              >
                Email ID
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                control={control}
                name={"email_id"}
                defaultValue={""}
                rules={{
                  required: false,
                  pattern: {
                    value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    message: "Invalid Email ID",
                  },
                }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"email"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "100.9%" }}
                    onBlur={onBlur}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 90,
                    }}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Email ID"}
                    error={!!errors["email_id"]}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={3} sx={{ ml: 3 }}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",

                  // textAlign: "center"
                }}
              >
                Mobile No.
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"mobile_no"}
                defaultValue={""}
                rules={{ required: true, minLength: 10, maxLength: 12 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    sx={{
                      width: "84.4%",
                      marginLeft: "0%",
                    }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 12,
                    }}
                    value={value}
                    placeholder={"Mobile No"}
                    error={!!errors["mobile_no"]}
                    helperText={
                      errors.mobile_no &&
                      `${errors.mobile_no.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "40%",
                  // textAlign: "center"
                }}
              >
                Date of Birth
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"date_of_birth"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"date"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    sx={{
                      width: "88.1%",
                      marginLeft: "12.5%",
                    }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                    }}
                    value={value}
                    placeholder={"Date of Birth"}
                    error={!!errors["date_of_birth"]}
                    helperText={
                      errors.date_of_birth &&
                      `${errors.date_of_birth.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"

            // width={"100%"}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "14%",
                  // textAlign: "center"
                }}
              >
                Gender
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ ml: 2.5 }}>
              <Controller
                control={control}
                name={"gender"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={genderOption}
                    keys={"key"}
                    label={"value"}
                    style={{
                      width: "84.0%",
                      marginLeft: "0%",
                    }}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    placeholder={"Gender"}
                    value={!!value ? value : ""}
                    errors={errors.gender}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "40%",
                  // textAlign: "center"
                }}
              >
                Marital Status
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"marital_status"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={MaritalStatus}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    placeholder={"Marital Status"}
                    value={!!value ? value : ""}
                    errors={errors.marital_status}
                    style={{
                      width: "87.8%",
                      marginLeft: "11.3%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={3} sx={{ ml: 3 }}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "0%",
                  // textAlign: "center"
                }}
              >
                Aadhar No.
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"aadhar_no"}
                defaultValue={""}
                rules={{ required: true, minLength: 12, maxLength: 12 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{
                      width: "84.7%",
                      marginLeft: "0%",
                    }}
                    onBlur={onBlur}
                    size="small"
                    onChange={(newValue) => (
                      onChange(newValue), setactAadhar(newValue.target.value)
                    )}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                    }}
                    value={value}
                    placeholder={"Aadhar No."}
                    // InputProps={{
                    //   inputProps: {  },
                    // }}
                    error={!!errors["aadhar_no"]}
                    helperText={
                      errors.aadhar_no &&
                      `${errors.aadhar_no.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {(!!employee_detail?.documents_details.find(
          (m) => m.document_type == "AADHAR"
        )?.document_no ||
          !!actAadhar) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
              width: "92.5%",
            }}
          >
            <ImageUploader
              documents={documents}
              type={"AADHAR"}
              user={user}
              fileHandleEvent={fileHandleEvent}
              imageChange={imageChange}
              downloadDoc={downloadDoc}
              edit_candidate={edit_candidate}
              edit_flag={edit_flag}
              id2={id2}
            />
          </Box>
        )}
      </Grid>

      {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
      // width={"100%"}
      >
        <Grid
          item
          xs={5}
        // justifyContent="space-between"
        // alignItems="start"
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            Aadhar Upload
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"AADHAR"}
            user={user}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
            downloadDoc={downloadDoc}
            edit_candidate={edit_candidate}
            edit_flag={edit_flag}
            id2={id2}
          />
        </Grid>
      </Grid> */}
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={4}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "40%",
                  // textAlign: "center"
                }}
              >
                Blood Group
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"blood_group"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={BloodGroup}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    placeholder={"Blood Group"}
                    value={!!value ? value : ""}
                    errors={errors.blood_group}
                    style={{
                      width: "87.7%",
                      marginLeft: "11.5%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "15%",
                  // textAlign: "center"
                }}
              >
                PAN No.
                {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Controller
                control={control}
                name={"pan_no"}
                defaultValue={""}
                rules={{
                  required: false,
                  pattern: {
                    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                    message: "Invalid PAN",
                  },
                }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="standard"
                    className={"smaller"}
                    style={{
                      width: "85.2%",
                      marginLeft: "5%",
                    }}
                    onBlur={onBlur}
                    size="small"
                    onChange={(newValue) => (
                      onChange(newValue), setactPan(newValue.target.value)
                    )}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 10,
                    }}
                    value={value}
                    label={"PAN No."}
                    error={!!errors["pan_no"]}
                    helperText={errors.pan_no && `${errors.pan_no.message}`}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {(!!employee_detail?.documents_details.find(
          (m) => m.document_type == "PAN"
        )?.document_no ||
          !!actPan) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "92.5%",
              mt: 1,
            }}
          >
            <ImageUploader
              documents={documents}
              type={"PAN"}
              user={user}
              fileHandleEvent={fileHandleEvent}
              imageChange={imageChange}
              downloadDoc={downloadDoc}
              edit_candidate={edit_candidate}
              edit_flag={edit_flag}
              id2={id2}
            />
          </Box>
        )}
      </Grid>

      {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
      // width={"100%"}
      >
        <Grid item xs={5}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            EPIC Upload
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"VOTAR"}
            user={user}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
            downloadDoc={downloadDoc}
            edit_candidate={edit_candidate}
            edit_flag={edit_flag}
            id2={id2}
          />
        </Grid>
      </Grid> */}

      {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
      // width={"100%"}
      >
        <Grid item xs={5}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            PAN Upload
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"PAN"}
            user={user}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
            downloadDoc={downloadDoc}
            edit_candidate={edit_candidate}
            edit_flag={edit_flag}
            id2={id2}
          />
        </Grid>
      </Grid> */}
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 10 }}
        // width={"100%"}
      >
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "53%",
                  // textAlign: "center"
                }}
              >
                Religion
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginLeft: 4 }}>
              <Controller
                control={control}
                name={"religion"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={religion}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"Religion"}
                    value={!!value ? value : ""}
                    errors={errors.religion}
                    style={{
                      width: "87.5%",
                      marginLeft: "23.2%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            // width={"100%"}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "15%",
                  // textAlign: "center"
                }}
              >
                EPIC No.
                {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ ml: 3 }}>
              <Controller
                control={control}
                name={"epic_no"}
                defaultValue={""}
                rules={{
                  required: false,
                  pattern: {
                    value: /^([a-zA-Z]){3}([0-9]){7}?$/,
                    message: "Invalid EPIC No.",
                  },
                }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{
                      width: "85.2%",
                      marginLeft: "0%",
                    }}
                    onBlur={onBlur}
                    size="small"
                    onChange={(newValue) => (
                      onChange(newValue), setactVotar(newValue.target.value)
                    )}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 10,
                    }}
                    value={value}
                    placeholder={"EPIC No."}
                    error={!!errors["epic_no"]}
                    helperText={errors.epic_no && `${errors.epic_no.message}`}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {(!!employee_detail?.documents_details.find(
          (m) => m.document_type == "VOTAR"
        )?.document_no ||
          !!actVotar) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 1,
              width: "92.5%",
            }}
          >
            <ImageUploader
              documents={documents}
              type={"VOTAR"}
              user={user}
              fileHandleEvent={fileHandleEvent}
              imageChange={imageChange}
              downloadDoc={downloadDoc}
              edit_candidate={edit_candidate}
              edit_flag={edit_flag}
              id2={id2}
            />
          </Box>
        )}
      </Grid>

      <Divider orientation="horizontal" variant="middle" flexItem />
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "7%",
                // marginBottom: 20,
              }}
            >
              Emergency Contact Information
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "48%",
                      // textAlign: "center"
                    }}
                  >
                    Contact No.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"emergency_contact"}
                    defaultValue={""}
                    rules={{ required: true, minLength: 10, maxLength: 12 }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "87.6%",
                          marginLeft: "22.7%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                        }}
                        value={value}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        placeholder={" Contact No."}
                        error={!!errors["emergency_contact"]}
                        helperText={
                          errors.emergency_contact &&
                          `${errors.emergency_contact.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="start" alignItems="center">
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "15%",
                      // textAlign: "center"
                    }}
                  >
                    Relationship
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"relation"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "87%",
                          marginLeft: "6%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                          maxLength: 50,
                        }}
                        onChange={onChange}
                        value={value}
                        placeholder={"Relationship"}
                        error={!!errors["relation"]}
                        helperText={
                          errors.relation &&
                          `${errors.relation.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default BasicDetails;
interface BasicDetailsProps {
  errors?: any;
  control?: any;
  documents: any[];
  fileHandleEvent?: any;
  SetDocument?: any;
  downloadDoc?: any;
  edit_candidate: boolean;
  user?: userDetails;
  edit_flag?: boolean;
  SetEditFlag?: any;
  employee_detail?: ViewEmployee;
  id2?: number;
}
