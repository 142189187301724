import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  EmployeeTimingList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteEmployeeTimingAction,
  GetAllEmployeeTimingAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import EmployeeTimingListView from "./EmployeeTimingListView";

function EmployeeTimingMain({
  GetAllOrganizationAction,
  organization_list,
  GetAllEmployeeTimingAction,
  employee_timing_list,
  DeleteEmployeeTimingAction
}: EmployeeTimingMainProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllEmployeeTimingAction({ organization_id: null });
    Clear();
  }, []);

  const filterData = (props: any) => {
     props.org_id = props.organization_id
    GetAllEmployeeTimingAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
    // console.log(props.organization_id);
  };
  const Clear = () => {
    GetAllEmployeeTimingAction({
      org_id: null,
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const DeleteAction = (props: number) => {
    // props.org_id = props.organization_id;
    DeleteEmployeeTimingAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };

  return (
    <EmployeeTimingListView
      organization_list={organization_list}
      filterData={filterData}
      Clear={Clear}
      employee_timing_list={employee_timing_list}
      DeleteAction={DeleteAction}
      reset={reset}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_timing_list: state.organization.employee_timing_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllEmployeeTimingAction,
  DeleteEmployeeTimingAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimingMain);

interface EmployeeTimingMainProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  GetAllEmployeeTimingAction?: any;
  employee_timing_list: EmployeeTimingList[];
  DeleteEmployeeTimingAction?: any;
}
