import {
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
  Card,
  CardActions,
  CardContent,
  Divider,
  Chip,
  Avatar,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  ConsolidatedProject,
  ConsolidatedReport,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import { Controller } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";

function ProjectSummaryView({
  customer_list,
  project_list,
  ViewProjectSummary,
  consolidated_report,
  ReportDownload,
}: ProjectSummaryViewProps) {
  //   const [checkedCustomer, setCheckedCustomer] = useState<boolean>(false);
  const [checkedCustomer, setCheckedCustomer] = useState<CustomerList[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);
  const [checkedProject, setCheckedProject] = useState<ProjectList[]>([]);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [reportDownloadData, setRerportDownloadData] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const selectCustomer = (checked: any, item: CustomerList) => {
    if (!!checked) {
      if (
        checkedCustomer.find((self) => self.customer_id === item.customer_id)
      ) {
        let events: CustomerList[] = JSON.parse(
          JSON.stringify(checkedCustomer)
        );
        let pr_events: ProjectList[] = JSON.parse(
          JSON.stringify(checkedProject)
        );
        pr_events = pr_events
          .filter((t) => t.customer_id != item.customer_id)
          .filter((v) => v.project_id);
        events = events.filter((m) => m.customer_id != item.customer_id);
        setCheckedCustomer(events);
        setCheckedProject(pr_events);
      } else {
        let events: CustomerList[] = JSON.parse(
          JSON.stringify(checkedCustomer)
        );
        events.push(item);
        setCheckedCustomer(events);
      }
    } else {
      let events: CustomerList[] = JSON.parse(JSON.stringify(checkedCustomer));
      events = events.filter((m) => m.customer_id != item.customer_id);
      let pr_events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      pr_events = pr_events
        .filter((t) => t.customer_id != item.customer_id)
        .filter((v) => v.project_id);
      setCheckedCustomer(events);
      setCheckedProject(pr_events);
    }
  };
  const selectedProject = (itemChecked: boolean, itemDetail: ProjectList) => {
    if (itemChecked) {
      let events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      events.push(itemDetail);
      setCheckedProject(events);
    } else {
      let events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      events = events.filter((n) => n.project_id != itemDetail.project_id);
      setCheckedProject(events);
    }
  };
  const [rows, setRows] = useState<ConsolidatedProject[]>([]);
  const column1: any[] = [
    "Project Name",
    "Employee Expenses",
    // "Bid Price(w/o GST)",
    "Additional Expenses",
    "Project Payment(With GST)",
    "Project Payment(Without GST)",
    "Bid Price(With GSt)",
    "Bid Price(Without GSt)",
    "Profit Or loss",
  ];
  const [searched, setSearched] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    console.log(consolidated_report);

    const filteredRows = !!consolidated_report
      ? consolidated_report.projects.filter((row: ConsolidatedProject) => {
          return row?.project_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!consolidated_report) {
      cancelSearch();
    }
  }, [consolidated_report]);

  const RemoveCustomer = (data: any) => {
    let events: CustomerList[] = JSON.parse(JSON.stringify(checkedCustomer));
    let pr_events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
    events = events.filter((m) => m.customer_id != data);
    pr_events = pr_events
      .filter((t) => t.customer_id != data)
      .filter((v) => v.project_id);
    setCheckedCustomer(events);
    setCheckedProject(pr_events);
  };
  const RemoveProject = (props: any) => {
    let events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
    events = events.filter((m) => m.project_id != props);
    setCheckedProject(events);
  };
  console.log("checkedProject", checkedProject);
  console.log("checkedCustomer", checkedCustomer);
  console.log(startDate);
  console.log(endDate);

  const ViewSelectedProject = () => {
    let prjID: any[] = checkedProject.map((d) => d.project_id);
    let payload: any = {
      project_id: prjID,
      start_date: !!startDate ? moment(startDate).clone()
      .startOf("month")
      .format("YYYY-MM-DD") : null,
      end_date: !!endDate ? moment(endDate).clone()
      .endOf("month")
      .format("YYYY-MM-DD") : null
    };
    console.log("payload", payload);
    ViewProjectSummary(payload);
    setOpenDetails(true);
    setRerportDownloadData(payload);
  };
  const ClearFilter = () => {
    setCheckedCustomer([]);
    setCheckedProject([]);
    setStartDate("");
    setEndDate("")
  };
  const ExcelReportDownload = () => {
    ReportDownload(reportDownloadData);
  };
  const total_loss_profit_per =
    !!consolidated_report &&
    !!consolidated_report?.budgetDetails &&
    (
      consolidated_report?.budgetDetails.payment_without_gst -
      (consolidated_report?.budgetDetails.employee_expense +
        consolidated_report?.budgetDetails.additional_expense)
    ).toFixed(2);

  return (
    <>
      <Paper
        elevation={5}
        sx={{ marginTop: 3, marginInline: 3, paddingBottom: 1 }}
      >
        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel"}
          onChange={handleChange1("panel")}
          style={{
            backgroundColor: "transparent",
            // marginTop: 15,
            // marginBottom: 15,
            width: "100%",
            borderRadius: 5,
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: 0, margin: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                // backgroundColor: "#0060FF",
                backgroundColor: "#42a5f5",
                justifyContent: "space-between",
                marginTop: -2,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
              onClick={() => handleChange1("panel1")}
            >
              <Grid
                justifyContent={"center"}
                alignSelf={"center"}
                style={{ paddingLeft: "20px" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    // color: "#626974",
                    // color: "black",
                    color: "#fff",

                    textAlign: "center",
                  }}
                >
                  Filter
                </Typography>
              </Grid>
              <Grid
                justifyContent={"flex-end"}
                alignContent={"flex-end"}
                style={{ marginRight: "2%" }}
              >
                <Button
                  variant="text"
                  endIcon={<FilterListIcon />}
                  sx={{ color: "#fff" }}
                  onClick={() => handleChange1("panel1")}
                ></Button>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0, margin: 0 }}>
            <Grid container justifyContent={"flex-start"}>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Start Date
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <TextField
                      type={"month"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      size="small"
                      placeholder={"Start Date"}
                      onChange={(event) => setStartDate(event.target.value)}
                      value={startDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      End Date
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -6 }}>
                  <TextField
                      type={"month"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      size="small"
                      placeholder={"Start Date"}
                      onChange={(event) => setEndDate(event.target.value)}
                      value={endDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ pb: 2, mx: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ borderRight: "1px solid #fff", width: "50%" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: "900",
                          borderRadius: "10px 0 0px 0",
                          backgroundColor: "#0060FF ",
                          color: "#fff",
                        }}
                      >
                        Customer Name
                      </Typography>
                    </Box>

                    <Box sx={{ borderLeft: "1px solid #fff", width: "50%" }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontWeight: "900",
                          borderRadius: "0px 10px   0px 0px",
                          backgroundColor: "#0060FF",
                          color: "#fff",
                        }}
                      >
                        Project Name
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ width: "50%" }}>
                      {!!customer_list &&
                        customer_list.map((item, index) => (
                          <Chip
                            avatar={
                              <Avatar
                                sx={{
                                  backgroundColor:
                                    !!checkedCustomer &&
                                    checkedCustomer.find(
                                      (m) => m.customer_id == item.customer_id
                                    )
                                      ? "#0a59cf"
                                      : "#682121",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      !!checkedCustomer &&
                                      checkedCustomer.find(
                                        (m) => m.customer_id == item.customer_id
                                      )
                                        ? "white"
                                        : "white",
                                  }}
                                >
                                  {!!checkedCustomer &&
                                  checkedCustomer.find(
                                    (m) => m.customer_id == item.customer_id
                                  ) ? (
                                    <IconButton sx={{ color: "white" }}>
                                      <DoneIcon />
                                    </IconButton>
                                  ) : (
                                    item.customer_name.slice(0, 1)
                                  )}
                                </Typography>
                              </Avatar>
                            }
                            label={item.customer_name}
                            variant="outlined"
                            sx={{
                              margin: 1,
                              borderColor:
                                !!checkedCustomer &&
                                checkedCustomer.find(
                                  (m) => m.customer_id == item.customer_id
                                )
                                  ? "#0a59cf"
                                  : "#682121",
                              color:
                                !!checkedCustomer &&
                                checkedCustomer.find(
                                  (m) => m.customer_id == item.customer_id
                                )
                                  ? "#0a59cf"
                                  : "#682121",
                              fontWeight:
                                !!checkedCustomer &&
                                checkedCustomer.find(
                                  (m) => m.customer_id == item.customer_id
                                )
                                  ? 600
                                  : 400,
                            }}
                            key={index}
                            onClick={(event) => (
                              selectCustomer(
                                event.currentTarget.innerText,
                                item
                              ),
                              setSelectedCustomer(item.customer_id)
                            )}
                          />
                        ))}
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                ml: 2,
                flexWrap: "wrap",
              }}
            >
              {!!checkedProject &&
                checkedProject.length > 0 &&
                checkedProject.map((row, index) => (
                  <Paper
                    elevation={3}
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      //   width: "20%",
                      p: 0.5,
                      mx: 0.5,
                      my: 0.5,
                    }}
                  >
                    <Grid container direction={"row"}>
                      <Grid item xs={8} container direction={"row"}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              // textAlign: "center",
                              fontWeight: "600",
                              fontSize: "10px",
                            }}
                          >
                            {!!row.customer_name
                              ? row.customer_name.slice(0, 8)
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              // textAlign: "center",
                              fontWeight: "400",
                              fontSize: "13px",
                            }}
                          >
                            {!!row.project_name
                              ? row.project_name.slice(0, 8)
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          color="warning"
                          size="small"
                          onClick={() => RemoveProject(row.project_id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </Box> */}
                      <Box
                        className="deptCardDetails"
                        sx={{
                          pb: 2,
                          px: 1,
                          maxHeight: "300px",
                          overflow: "auto",
                        }}
                      >
                        {!!checkedCustomer &&
                          checkedCustomer.map((m) => (
                            <Box
                              sx={{
                                pb: 2,
                                px: 1,
                                overflow: "auto",
                              }}
                            >
                              <Typography
                                sx={{
                                  padding: 0.5,
                                  marginLeft: "3%",
                                  fontWeight: 600,
                                  color: "#0a59cf",
                                }}
                              >
                                {m.customer_name}
                              </Typography>

                              <Divider
                                style={{
                                  borderColor: "#9E9E9E",
                                  opacity: 0.7,
                                  width: "94%",
                                  marginLeft: "3%",
                                }}
                              ></Divider>

                              {!!project_list &&
                                project_list
                                  .filter(
                                    (n) => n.customer_id === m.customer_id
                                  )
                                  .map((self, indes) => (
                                    <Box
                                      key={indes}
                                      sx={{
                                        my: 0.1,
                                        width: "90%",
                                        mx: "auto",
                                        border: "2px solid #F1F1F1",
                                        cursor: "pointer",
                                        borderRadius: "15px",
                                        px: 2,
                                        py: 0,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Checkbox
                                        color="primary"
                                        checked={
                                          !!checkedProject.find(
                                            (c) =>
                                              c.project_id === self.project_id
                                          )
                                        }
                                        onChange={(event) =>
                                          selectedProject(
                                            event.target.checked,
                                            self
                                          )
                                        }
                                      />
                                      <Typography>
                                        {self.project_name}
                                      </Typography>
                                    </Box>
                                  ))}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                        marginTop: 3,
                      }}
                      onClick={ClearFilter}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginTop: 3,
                      }}
                      disabled={checkedProject.length <= 0}
                      onClick={() => ViewSelectedProject()}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      View
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      {openDetails && !!consolidated_report &&(
        <Paper elevation={3} sx={{ my: 2, mx: 3, py: 2 }}>
          {/* <Grid container direction={"row"} m={2}>
            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Additional Expenses
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  &#8377;{" "}
                  {consolidated_report?.budgetDetails.additional_expense
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Employee Expenses
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  &#8377;{" "}
                  {consolidated_report?.budgetDetails.employee_expense
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Payment (Without GST)
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  &#8377;{" "}
                  {consolidated_report?.budgetDetails.payment_without_gst
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Payment (With GST)
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  &#8377;{" "}
                  {consolidated_report?.budgetDetails.gst_payment
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Total Expense
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  &#8377;{" "}
                  {consolidated_report?.budgetDetails.total_expense
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: 280,
                m: 2,
                color: "white",
                background:
                  "linear-gradient(to right bottom, #cc2b5e , #753a88)",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="white"
                >
                  Actual Profit/Loss
                </Typography>
                <Typography
                  variant="body1"
                  // color="white"
                  sx={{
                    fontWeight: "bold",
                    color:
                      !!total_loss_profit_per && +total_loss_profit_per > 0
                        ? "white"
                        : "red",
                  }}
                >
                  &#8377;{" "}
                  {total_loss_profit_per
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}

          <Grid
            container
            direction={"row"}
            justifyContent={"end"}
            alignItems={"center"}
            // style={{ textAlign: "center", paddingTop: 8 }}
          >
            <Grid item xs={3} display={"flex"} justifyContent={"center"}>
              <Button
                sx={{
                  color: "#1565C0",
                  //   backgroundColor: "#1565C0",
                  paddingX: 2,
                }}
                onClick={() => ExcelReportDownload()}
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<CloudDownloadIcon />}
              >
                Export to Excel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                style={{ width: "100%" }}
                placeholder="Search..."
                id="fullWidth"
                value={searched}
                onChange={(e: any) => (
                  requestSearch(e.target.value), setSearched(e.target.value)
                )}
                InputProps={{
                  endAdornment:
                    !!searched && searched.length > 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => cancelSearch()}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="primary">
                        <SearchIcon />
                      </IconButton>
                    ),
                }}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>

          <Grid container direction={"row"} marginTop={1}>
            <Grid item xs={12} padding={2}>
              <Grid
                container
                direction={"row"}
                width={"100%"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {column1.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows.map((row, index) => {
                          console.log(
                            "profilt",
                            row.payment_without_gst -
                              (row.employee_expense + row.additional_expense)
                          );
                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              sx={{
                                backgroundColor:
                                  Number(
                                    (
                                      row.payment_without_gst -
                                      (row.employee_expense +
                                        row.additional_expense)
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .split(",")
                                      .join("")
                                  ) > 0
                                    ? ""
                                    : "#F87171 !important",
                              }}
                            >
                              <StyledTableCell align="left" key={column1[0]}>
                                {row.project_name}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[1]}>
                                {row.employee_expense
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                              {/* <StyledTableCell align="center" key={column1[2]}>
                              {row.bid_price_without_gst
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </StyledTableCell> */}
                              <StyledTableCell align="left" key={column1[3]}>
                                {row.additional_expense
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[4]}>
                                {row.gst_payment
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>

                              <StyledTableCell align="left" key={column1[5]}>
                                {row.payment_without_gst
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[6]}>
                                {row.bid_price_with_gst
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[6]}>
                                {row.bid_price_without_gst
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                key={column1[7]}
                                sx={{
                                  color:
                                    Number(
                                      (
                                        row.payment_without_gst -
                                        (row.employee_expense +
                                          row.additional_expense)
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .split(",")
                                        .join("")
                                    ) > 0
                                      ? "green !important"
                                      : "red !important",
                                }}
                              >
                                {(+(
                                  row.payment_without_gst -
                                  (row.employee_expense +
                                    row.additional_expense)
                                ).toFixed(2))
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>

                    <TableBody sx={{ backgroundColor: "#c0ded9" }}>
                      <StyledTableRow hover role="checkbox" tabIndex={-1}>
                        <StyledTableCell
                          align="left"
                          key={column1[0]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {`Total (${consolidated_report?.projects.length})`}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          key={column1[2]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.employee_expense)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center" key={column1[3]}>
                          {rows
                            .map((m) => m.bid_price_without_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell> */}
                        <StyledTableCell
                          align="left"
                          key={column1[4]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.additional_expense)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          key={column1[5]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.gst_payment)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          key={column1[6]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.payment_without_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          key={column1[7]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.bid_price_with_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          key={column1[7]}
                          sx={{ fontSize: 16, color: "black", fontWeight: 600 }}
                        >
                          {rows
                            .map((m) => m.bid_price_without_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          key={column1[8]}
                          sx={{
                            fontSize: 16,
                            color:
                              !!total_loss_profit_per &&
                              Number(
                                total_loss_profit_per.split(",").join("")
                              ) > 0
                                ? "green !important"
                                : "red !important",
                            fontWeight: 600,
                          }}
                        >
                          {total_loss_profit_per
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                  {/* <Divider
                    style={{
                      borderColor: "#9E9E9E",
                      opacity: 0.7,
                      width: "100%",
                    }}
                  ></Divider> */}
                  {/* <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow >
                        {column1.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow hover role="checkbox" tabIndex={-1}>
                        <StyledTableCell align="left" key={column1[0]} sx={{}}>
                          {`Total (${consolidated_report?.projects.length})`}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={column1[2]}>
                          {rows
                            .map((m) => m.bid_price_with_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>
                        <StyledTableCell align="center" key={column1[3]}>
                          {rows
                            .map((m) => m.bid_price_without_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={column1[4]}>
                          {rows
                            .map((m) => m.gst_payment)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>
                        <StyledTableCell align="center" key={column1[5]}>
                        {rows
                            .map((m) => m.payment_without_gst)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>

                        <StyledTableCell align="left" key={column1[6]}>
                        {rows
                            .map((m) => m.employee_expense)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={column1[7]}>
                        {rows
                            .map((m) => m.additional_expense)
                            .reduce((partialSum, a) => partialSum + a, 0)}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={column1[8]}>
                          -
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table> */}
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default ProjectSummaryView;

interface ProjectSummaryViewProps {
  customer_list: CustomerList[];
  project_list: ProjectList[];
  ViewProjectSummary?: any;
  ReportDownload?: any;
  consolidated_report?: ConsolidatedReport;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1.5px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
