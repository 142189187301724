import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  LogOutAction,
  resetPasswordAction,
} from "../../../stores/actions/userAction";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
import { useLocation, useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import ChangePasswordModal from "../../employment/ManageProfile/ChangePasswordModal";
import "./Header.css";
import { width } from "@mui/system";
import em from "../../../assets/emdee.png";
const Header = ({ user, logoutEvent, resetPasswordAction }: HeaderProps) => {
  const location = useLocation();
  let navigateTo = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const logout = () => {
    handleClose();
    logoutEvent();
  };
  
  const navigate = (item: any) => {
    if (item.has_submenu && item.menuItem.length > 0) {
      item.menuItem.every((element: any) => {
        if (
          !!user?.assess_control.find(
            (m) => m.access_control_name === element.key
          )
        ) {
          navigateTo(element.url);
          return false;
        }
        return true;
      });
    } else {
      navigateTo(item.url);
    }
  };
  const assesCheck = (element: any) => {
    let permission = false;
    if (element.has_submenu) {
      element.menuItem.forEach((m: any) => {
        if (
          !!user?.assess_control.find((n) => n.access_control_name === m.key)
        ) {
          permission = true;
        }
      });
    } else {
      permission = !!user?.assess_control.find(
        (m) => m.access_control_name === element.key
      );
    }
    return permission;
  };
  return (
    <div
      style={{
        backgroundColor: "#ffff",
        borderBottom: "0.5px solid white"
      }}
    >
      <ChangePasswordModal
        isModalOpen={passwordModalOpen}
        setIsModalOpen={setPasswordModalOpen}
        resetPasswordAction={resetPasswordAction}
        user={user}
      />
      <Grid
        container
        direction={"row"}
        style={{ padding: 5, backgroundColor: "#132437", boxShadow: "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px" }}
      >
        <Grid style={{width: "5%", display: "flex",
          alignItems: "center",
  justifyContent: "center",}}>
        <div className="LogoContainer">
          {/* <Typography
              style={{
                color: "#003064",
                fontSize: 40,
                paddingLeft: 20,
                fontFamily: "Poppins, sans-serif",
                fontWeight: "600",
              }}
            >
              H R M S
            </Typography> */}
          <img
            src={require("../../../assets/minimalistic-logo-removebg-preview.png")}
          />
        </div>
      </Grid>


      <Grid item style={{ width: "90%", fontSize: "12px" }}>
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          height="100%"
        >
          {environment.sideNav.map((item, index) =>
            assesCheck(item) ? (
              <div
                key={index}
                style={{ padding: 10 }}
                onClick={() => navigate(item)}
              >
                <div
                  className="headerMenu"
                  style={{
                    color:
                      location.pathname.split("/")[1] ==
                        item.url.split("/")[1]
                        ? "#44c4d4"
                        : "",
                    borderBottom:
                      location.pathname.split("/")[1] ==
                        item.url.split("/")[1]
                        ? "4px solid #44c4d4"
                        : "",
                    borderRadius: "2px",
                  }}
                >
                  {item.name}
                </div>
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </Grid>
      </Grid>


      <Grid style={{width: "5%" }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
        >
          {/* <IconButton size="large" style={{ marginTop: 10, marginRight: 10 }}>
              <Badge badgeContent={17} color="error">
                <NotificationsIcon style={{ color: "#003064" }} />
              </Badge>
            </IconButton> */}
          <IconButton style={{display: "flex", justifyContent: "center", alignItems: "center"}} onClick={handleClick}>
            <Avatar
              alt={user?.employee_detail.employee_name}
              src="/static/images/avatar/2.jpg"
              style={{marginRight: "20px"}}
            />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Grid
              container
              width={300}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
            >
              <Grid
                style={{
                  height: 120,
                  backgroundColor: "#EBECF0",
                  width: "100%",
                  //backgroundImage: `url(${em})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // height: "60%",
                  // width: "auto",
                  position: "-webkit-sticky",
                }}
              ></Grid>
              <Avatar
                style={{
                  fontSize: "50px",
                  width: 70,
                  height: 70,
                  marginTop: "-40px",
                  marginLeft: 5,
                  border: "6px solid #ffff",
                }}
                alt={user?.employee_detail.employee_name}
              //src="/static/images/avatar/2.jpg"
              />
              <Grid
                style={{
                  padding: 10,
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Typography style={{ fontSize: "17px", fontWeight: "bold" }}>
                  {user?.employee_detail.employee_name}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  {user?.designation_name}
                </Typography>
                <Typography style={{ fontSize: 13 }}>
                  {user?.userName}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {user?.email}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {user?.contact}
                </Typography>
              </Grid>
              <Divider style={{ width: "80%" }} />
              <List style={{ width: "100%" }}>
                <ListItem key={12} disablePadding>
                  <ListItemButton
                    onClick={() => (
                      navigateTo("/employment/manage-profile/0"),
                      handleClose()
                    )}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon style={{ color: "#003064" }} />
                    </ListItemIcon>
                    <ListItemText
                      style={{ color: "#003064" }}
                      primary="Manage Profile"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem key={12} disablePadding>
                  <ListItemButton onClick={() => setPasswordModalOpen(true)}>
                    <ListItemIcon>
                      <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItemButton>
                </ListItem>
                <ListItem key={11} disablePadding>
                  <ListItemButton onClick={() => logout()}>
                    <ListItemIcon>
                      <LogoutIcon style={{ color: "red" }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: "red" }} primary="Logout" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
    </div >
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = { resetPasswordAction };

export default connect(mapStateToProps, mapDispatchToProps)(Header);

interface HeaderProps {
  user?: userDetails;
  logoutEvent?: any;
  resetPasswordAction?: any;
}
