/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ModalUnstyled from "@mui/base/ModalUnstyled";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Grid,
  IconButton,
  TextField,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import Autocompletes from "../../../library/AutoComplete";
import { Controller, useForm } from "react-hook-form";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";
import { ILeaveType } from "../../../models/leaveModel";
import { AllDesignationList } from "../../../models/organizationModels";
import { ILeaveManagement } from "./../../../models/leaveModel";
const LeaveManagementActionModal: FC<ILeaveManagementActionModal> = ({
  isOpen,
  setIsOpen,
  designationList,
  GetAllLeaveTypeAction,
  leave_types,
  GetAllDesignationAction,
  createLeaveManagementAction,
  user,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,

    formState: { errors },
  } = useForm();
  const handleClose = (): void => {
    setIsOpen(false);
    reset({ leave_amount: "", designation_id: "", leave_type_id: "" });
  };
  const onSubmit = (formData: any) => {
    console.log("formData : ", formData);
    const payload = {
      formData: {
        ...formData,
        leave_amount: Number(formData.leave_amount),
        organization_id: Number(user?.employee_detail.organization_id),
      },
      enqueueSnackbar,
    };
    createLeaveManagementAction(payload);
    handleClose();
  };
  useEffect(() => {
    if (isOpen) {
      GetAllLeaveTypeAction();
      GetAllDesignationAction();
    }
  }, [isOpen, setIsOpen, setValue]);
  console.log("designationList : in action modal : ", designationList);
  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Box
              sx={{
                background: "#0060FF",
                p: 2,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Leave Management
              </Typography>
              <IconButton onClick={() => handleClose()} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ py: 3, px: 5 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography ml={1}>
                      Designation <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"designation_id"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={!!designationList ? designationList : []}
                          keys={"designation_id"}
                          label={"designation_name"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder="Designation Name"
                          value={!!value ? value : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography ml={1}>
                      Leave Type <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"leave_type_id"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={!!leave_types ? leave_types : []}
                          keys={"leave_type_id"}
                          label={"leave_type_name"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder="Leave Type Name"
                          value={!!value ? value : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography ml={1}>
                      Leave Amount <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"leave_amount"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"number"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          size="small"
                          label={
                            <span>
                              leave amount
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          onChange={onChange}
                          value={value}
                          placeholder={"Leave Amount"}
                          error={!!errors["leave_amount"]}
                          helperText={
                            errors.leave_amount &&
                            `${errors.leave_amount.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button variant="outlined" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="outlined" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

interface ILeaveManagementActionModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  GetAllDesignationAction?: any;
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  designationList: AllDesignationList[];
  createLeaveManagementAction?: any;
  user?: userDetails;
}
export default LeaveManagementActionModal;
