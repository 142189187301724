import { Cookies } from "react-cookie";
import {
  AttendanceApprovalListService,
  AttendanceDashboardService,
  AttendanceTimeSheetListService,
  DeleteEmployeeFromAttendanceBatchService,
  PendingApprovalListService,
  UpdateAttendanceApprovalStatusService,
  UpdateAttendanceService,
  UploadAttendanceTimeSheetService,
  UploadedAttendanceViewService,
} from "../../services/attendanceService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";

export enum AttendanceActionTypes {
  Attendance_Dashboard_Success_Action = "[ATTENDANCE] Attendance Dashboard Success Action",
  Pending_Approval_Success_Action = "[ATTENDANCE] Pending Approval Success Action",
  Uploaded_Attendance__View_Success_Action = "[ATTENDANCE] Uploaded Attendance View Success Action",
  Attendance_Detail_Payload_Success_Action = "[ATTENDANCE] Attendance Detail Payload Success Action",
  Update_Attendance_Success_Action = "[ATTENDANCE] Update Attendance Success Action",
  Approve_Attendance_List_Success_Action = "[ATTENDANCE] Approve Attendance List Success Action",
  Attendance_options = "[ATTENDANCE] Attendance option",
  Attendance_Approve_Status_Success_Action = "[ATTENDANCE] Attendance Approve Status Success Action",
  Delete_Employee_Attendance_Batch_Success_Action = "[ATTENDANCE] Delete Employee Attendance Batch Success Action",
  Attendance_Time_Sheet_Success_Action = "[ATTENDANCE] Attendance Time Sheet Success Action",
  Upload_Attendance_Time_Sheet_Success_Action = "[ATTENDANCE] Upload Attendance Time Sheet Success Action",
}

export const AttendanceDashboardAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Department. Please Wait...",
        type: 2,
      })
    );
    return AttendanceDashboardService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(AttendanceDashboardSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const AttendanceDashboardSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Attendance_Dashboard_Success_Action,
    payload: data,
  };
};

export const GetAllPendingApprovalAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Pending Approval List. Please Wait...",
        type: 2,
      })
    );
    return PendingApprovalListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllPendingApprovalSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllPendingApprovalSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Pending_Approval_Success_Action,
    payload: data,
  };
};

export const UploadeAttendanceViewAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get Uploaded Attendance View. Please Wait...",
        type: 2,
      })
    );
    return UploadedAttendanceViewService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo(`/attendance/attendance-details`);
          dispatch(
            UploadeAttendanceViewSuccessAction({
              list: response.data.employee_list,
              count: response.data.total_count,
              attendence_fields: response.data.attendence_fields,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UploadeAttendanceViewSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Uploaded_Attendance__View_Success_Action,
    payload: data,
  };
};

export const AttendanceDetailPayloadSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Attendance_Detail_Payload_Success_Action,
    payload: data,
  };
};

export const UpdateAttendanceAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Department. Please Wait...",
        type: 2,
      })
    );
    return UpdateAttendanceService(payload.update_data)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo(`attendance/attendance-details/`);
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(UploadeAttendanceViewAction(payload.list_data));
          dispatch(UpdateAttendanceSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdateAttendanceSuccessAction = () => {
  return { type: AttendanceActionTypes.Update_Attendance_Success_Action };
};

export const ApproveAttendanceListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Attendance List. Please Wait...",
        type: 2,
      })
    );

    return AttendanceApprovalListService(payload)
    
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ApproveAttendanceListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ApproveAttendanceListSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Approve_Attendance_List_Success_Action,
    payload: data,
  };
};
export const UpdateApproveAttendanceStatusAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Attendance Update Status. Please Wait...",
        type: 2,
      })
    );
    return UpdateAttendanceApprovalStatusService(payload.data1)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(GetAllPendingApprovalAction(payload.data2));
          dispatch(UpdateApproveAttendanceStatusSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdateApproveAttendanceStatusSuccessAction = () => {
  return {
    type: AttendanceActionTypes.Attendance_Approve_Status_Success_Action,
  };
};

export const DeleteEmployeeFromAttendanceBatchAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Attendance Update Status. Please Wait...",
        type: 2,
      })
    );
    return DeleteEmployeeFromAttendanceBatchService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(UploadeAttendanceViewAction(payload.list_data));
          dispatch(DeleteAttendanceFromBatchSuccessAction());
          // dispatch(
          //   UploadeAttendanceViewSuccessAction({
          //     list: response.data.employee_list,
          //     count: response.data.total_count,
          //     // attendence_fields: response.data.attendence_fields,
          //   }))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeleteAttendanceFromBatchSuccessAction = () => {
  return {
    type: AttendanceActionTypes.Delete_Employee_Attendance_Batch_Success_Action,
  };
};

export const AttendanceTimeSheetListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Attendance From Timesheet. Please Wait...",
        type: 2,
      })
    );
    console.log(payload);
    return AttendanceTimeSheetListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(AttendanceTimeSheetListSuccessAction(response.data))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const AttendanceTimeSheetListSuccessAction = (data: any) => {
  return {
    type: AttendanceActionTypes.Attendance_Time_Sheet_Success_Action,
    payload: data,
  };
};

export const UploadAttendanceTimeSheetAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Upload Attendance From Time Sheet. Please Wait...",
        type: 2,
      })
    );
    console.log(payload);
    return UploadAttendanceTimeSheetService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Generated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          })
          dispatch(AttendanceDetailPayloadSuccessAction({
            employee_type_id: payload.data[0].employee_type_id,
            organization_id: payload.data[0].organization_id,
            project_id: payload.data[0].project_id,            
            attendence_salary_type_id:
              null,
            project_attendence_id:
              response.data.project_attendence_id,
            page_index: 0,
            page_size: 2,
            // editable: 0
          }))
          payload.navigateTo(`/attendance/attendance-details/1`)
          dispatch(UploadAttendanceTimeSheetSuccessAction())
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UploadAttendanceTimeSheetSuccessAction = () => {
  return {
    type: AttendanceActionTypes.Upload_Attendance_Time_Sheet_Success_Action
  };
};