import { baseUrl, environment } from "../environment";
import { EmployeeListAvailableForTerminationPayload, InitiateTerminationPayload, TerminatedEmployeeListPayload, TerminationApprovalPayload, TerminationInitiatedEmployeeListPayload } from "../models/terminationModel";
import RestService from "./rest";

export const serviceClient = new RestService({
    baseURL: baseUrl,
  });
  export const EmployeeListAvailableForTerminationService = (
    data: EmployeeListAvailableForTerminationPayload
  ) => {
    return serviceClient.post(environment.urls.getAllEmployeeListAvailableForTermination, data);
  };
  export const InitiateTerminationService = (
    data: InitiateTerminationPayload
  ) => {
    return serviceClient.post(environment.urls.initiateTermination, data);
  };
  export const TerminationInitiatedEmployeeListService = (
    data: TerminationInitiatedEmployeeListPayload
  ) => {
    return serviceClient.post(environment.urls.terminationInitiatedEmployeeList, data);
  };
  export const TerminatedEmployeeListService = (
    data: TerminatedEmployeeListPayload
  ) => {
    return serviceClient.post(environment.urls.terminatedEmployeeList, data);
  };
  export const TerminationApprovalService = (
    data: TerminationApprovalPayload
  ) => {
    return serviceClient.post(environment.urls.approveTermination, data);
  };