import {
  Button,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AllProjectBudgetList,
  ProjectUnbudgetList,
} from "../../../models/customerModel";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function ProjectBudgetingView({
  project_budget_list,
}: ProjectBudgetingViewProps) {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<AllProjectBudgetList[]>([]);
  const styles = {
    marginRight: "50px",
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!project_budget_list
      ? project_budget_list.filter((row: AllProjectBudgetList) => {
          return (
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.status?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!project_budget_list) {
      cancelSearch();
    }
  }, [project_budget_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Project Name",
    "Estimated Efforts(In days)",
    "Bid Price (With GST)",
    "Bid Price (Without GST)",
    "Status",
    "Created On",
    "Action",
  ];
  return (
    <>
      <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={3}>
            <Button
              onClick={() => navigate("/customer/create-budgeting/0")}
              size="medium"
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add New
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
          </Grid>
        </Grid>
        <Grid marginTop={2}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.project_forecast_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.project_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.duration}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontWeight: "bold",
                            }}
                          >
                            &#8377; {row.bid_price_with_gst}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          <span
                            style={{
                              fontFamily: "Arial",
                              fontWeight: "bold",
                            }}
                          >
                            &#8377; {row.bid_price_without_gst}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          <Typography
                            style={{
                              color: row.status === "ACTIVE" ? "green" : "red",
                              padding: 0,
                            }}
                          >
                            {row.status}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[5]}>
                          {row.created_on
                            ? moment(row.created_on)
                                .format("DD/MM/YYYY HH:mm:ss a")
                                .toUpperCase()
                            : "N/A"}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[6]}>
                          <Tooltip
                            title="View"
                            onClick={() =>
                              navigate(
                                `/customer/create-budgeting/${row.project_id}`
                              )
                            }
                          >
                            <IconButton>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Paper>
    </>
  );
}

export default ProjectBudgetingView;

interface ProjectBudgetingViewProps {
  project_budget_list: AllProjectBudgetList[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
