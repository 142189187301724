import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  CompletedProjectList,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import { StoreState } from "../../../models/reduxModels";
import {
  CompletedProjectListAction,
  GetAllCustomerAction,
} from "../../../stores/actions/customerAction";
import CompletedProjectView from "./CompletedProjectView";

function CompletedProject({
  customer_list,
  GetAllCustomerAction,
  completed_project_list,
  CompletedProjectListAction,
}: CompletedProjectProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    GetAllCustomerAction({
      organization_id: null,
    });
  }, []);
  useEffect(() => {
    CompletedProjectListAction({
      start_date: null,
      end_date: null,
      customer_id: null,
    });
  }, []);

  const FilterSubmit = (data: any) => {
    CompletedProjectListAction({
      start_date: !!data.prj_start_date ? data.prj_start_date : null,
      end_date: !!data.prj_end_date ? data.prj_end_date : null,
      customer_id: !!data.customer_id ? data.customer_id : null,
    });
  };
  const ClearAllFilter = () => {
    CompletedProjectListAction({
      start_date: null,
      end_date: null,
      customer_id: null,
    });
  };
  return (
    <CompletedProjectView
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      ClearAllFilter={ClearAllFilter}
      FilterSubmit={FilterSubmit}
      customer_list={customer_list}
      completed_project_list={completed_project_list}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    customer_list: state.customer.customer_list,
    completed_project_list: state.customer.completed_project_list,
  };
};

const mapDispatchToProps = {
  GetAllCustomerAction,
  CompletedProjectListAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompletedProject);
interface CompletedProjectProps {
  GetAllCustomerAction?: any;
  CompletedProjectListAction?: any;
  customer_list: CustomerList[];
  completed_project_list: CompletedProjectList[];
}
