import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import HrmsLightTheme from "./themes/HrmsLightTheme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ConfigureStore from "./stores/configureStore";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
export const stores = ConfigureStore();
root.render(
  <CookiesProvider>
    <Provider store={stores}>
      <BrowserRouter>
        <ThemeProvider theme={HrmsLightTheme}>
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
