import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FilterListIcon from "@mui/icons-material/FilterList";
import Autocompletes from "../../../library/AutoComplete";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { CheckBox } from "@mui/icons-material";
import { ProjectwiseDetailsMonthlyReport } from "../../../models/reportsModel";
import ChartView from "./ChartView";
import { useSnackbar } from "notistack";
import DoneIcon from "@mui/icons-material/Done";
import DateTypeSwitchTab from "./DateTypeSwitchTab";

type DateTypeSwitch = "START-END" | "YEAR-MONTH";

function ConsolidatedReportView({
  control,
  errors,
  getValues,
  handleSubmit,
  setValue,
  project_list,
  watch,
  FilterSubmit,
  projectwise_details_monthly_report,
  customer_list,
}: // GetAllProjectAction,
ConsolidatedReportViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [reportSection, setReportSection] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [checkedDimensions, setCheckedDimensions] = useState<any[]>([]);
  const [quarterTimerange, setQuarterTimerange] = useState<number>(0);
  const [checkedCustomer, setCheckedCustomer] = useState<CustomerList[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);
  const [checkedProject, setCheckedProject] = useState<ProjectList[]>([]);
  const [dateTypeSwitch, setDateTypeSwitch] =
    useState<DateTypeSwitch>("START-END");
  console.log("checkedProject", checkedProject);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const onSubmit = (data: any) => {
    console.log(data, "ssssssss");

    let selcted_year = moment(data.selected_year).format("YYYY");
    let selcted_year_start_month;
    let selcted_year_end_month;

    if (!!data.selected_year) {
      if (quarterTimerange == 1) {
        selcted_year_start_month = `${selcted_year}-01`;
        selcted_year_end_month = `${selcted_year}-03`;
      } else if (quarterTimerange == 2) {
        selcted_year_start_month = `${selcted_year}-01`;
        selcted_year_end_month = `${selcted_year}-06`;
      } else if (quarterTimerange == 3) {
        selcted_year_start_month = `${selcted_year}-01`;
        selcted_year_end_month = `${selcted_year}-09`;
      } else if (quarterTimerange == 4) {
        selcted_year_start_month = `${selcted_year}-01`;
        selcted_year_end_month = `${selcted_year}-12`;
      } else {
        enqueueSnackbar(`Need to select atleast any one quarter.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }
    // console.log("selcted_year_start_month", selcted_year_start_month);
    // console.log("selcted_year_end_month", selcted_year_end_month);

    let payload: any;
    if (!!data.prj_start_date && !!data.selected_year) {
      enqueueSnackbar(`Please select any one date range filter.`, {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setValue("prj_start_date", "");
      setValue("prj_end_date", "");
      setValue("selected_year", null);
    } else {
      if (!!checkedProject && checkedProject.length > 0) {
        if (quarterTimerange >= 1) {
          payload = {
            start_date: !!data.selected_year ? selcted_year_start_month : null,
            end_date: !!data.selected_year ? selcted_year_end_month : null,
            // project_id: data.project_id,
            project_id: !!checkedProject
              ? checkedProject.map((m) => m.project_id)
              : [],
          };
        } else {
          payload = {
            start_date: !!data.prj_start_date
              ? moment(data.prj_start_date)
                  .clone()
                  .startOf("month")
                  .format("YYYY-MM-DD")
              : null,
            end_date: !!data.prj_end_date
              ? moment(data.prj_end_date)
                  .clone()
                  .endOf("month")
                  .format("YYYY-MM-DD")
              : null,
            // project_id: data.project_id,
            project_id: !!checkedProject
              ? checkedProject.map((m) => m.project_id)
              : [],
          };
          console.log(
            "last date",
            moment(payload.end_date).clone().endOf("month").format("DD-MM-YYYY")
          );
        }
        FilterSubmit(payload);
        setReportSection(true);
        setExpanded(false);
      } else {
        enqueueSnackbar(`Please select one project atleast.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }

      console.log(payload, "*****");
    }
  };
  const ClearFilter = () => {
    setValue("prj_start_date", "");
    setValue("prj_end_date", "");
    setValue("project_id", "");
    setValue("selected_year", null);
    setCheckedCustomer([]);
    setCheckedProject([]);
  };
  useEffect(() => {
    setValue("selected_year", null);
  }, []);
  console.log("checkedDimensions", checkedDimensions);

  const handleChecked = (key: string) => {
    console.log(key, "------------------;;");
    if (checkedDimensions.find((m) => m === key)) {
      setCheckedDimensions(checkedDimensions.filter((m) => m !== key));
    } else {
      setCheckedDimensions([...checkedDimensions, key]);
    }
    // if (
    //   checkedDimensions.find((self, index) => Object.keys(self)?.[0] === key)
    // ) {
    //   setCheckedDimensions((prev: any[]) =>
    //     prev.filter((self) => Object.keys(self)?.[0] !== key)
    //   );
    // } else {
    //   setCheckedDimensions((prev: any[]) => [...prev, { [key]: value }]);
    // }
  };
  const marks = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "Q1",
    },
    {
      value: 2,
      label: "Q2",
    },
    {
      value: 3,
      label: "Q3",
    },
    {
      value: 4,
      label: "Q4",
    },
  ];
  function valuetext(value: number) {
    setQuarterTimerange(value);
    return `${value}`;
  }

  const selectCustomer = (checked: any, item: CustomerList) => {
    if (!!checked) {
      if (
        checkedCustomer.find((self) => self.customer_id === item.customer_id)
      ) {
        let events: CustomerList[] = JSON.parse(
          JSON.stringify(checkedCustomer)
        );
        let pr_events: ProjectList[] = JSON.parse(
          JSON.stringify(checkedProject)
        );
        pr_events = pr_events
          .filter((t) => t.customer_id != item.customer_id)
          .filter((v) => v.project_id);
        events = events.filter((m) => m.customer_id != item.customer_id);
        setCheckedCustomer(events);
        setCheckedProject(pr_events);
      } else {
        let events: CustomerList[] = JSON.parse(
          JSON.stringify(checkedCustomer)
        );
        events.push(item);
        setCheckedCustomer(events);
      }
    } else {
      let events: CustomerList[] = JSON.parse(JSON.stringify(checkedCustomer));
      events = events.filter((m) => m.customer_id != item.customer_id);
      let pr_events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      pr_events = pr_events
        .filter((t) => t.customer_id != item.customer_id)
        .filter((v) => v.project_id);
      setCheckedCustomer(events);
      setCheckedProject(pr_events);
    }
  };
  const selectedProject = (itemChecked: boolean, itemDetail: ProjectList) => {
    if (itemChecked) {
      let events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      events.push(itemDetail);
      setCheckedProject(events);
    } else {
      let events: ProjectList[] = JSON.parse(JSON.stringify(checkedProject));
      events = events.filter((n) => n.project_id != itemDetail.project_id);
      setCheckedProject(events);
    }
  };
  // useEffect(() => {
  //   let firstTimeChecking : any[];
  //   if (!!projectwise_details_monthly_report) {
  //     firstTimeChecking =
  //       !!projectwise_details_monthly_report &&
  //       Object.entries(projectwise_details_monthly_report).map(
  //         ([key, value], index) => key
  //       );
  //       setCheckedDimensions(firstTimeChecking)
  //   }
  // }, [projectwise_details_monthly_report]);
  const handleChangeDateTypeChange = (value: DateTypeSwitch) => {
    setDateTypeSwitch(value);
    console.log("hit66", value);
    if (value === "START-END") {
      setValue("selected_year", "");
    } else {
      setValue("prj_start_date", "");
      setValue("prj_end_date", "");
    }
  };
  console.log({ dateTypeSwitch });

  console.log({ projectwise_details_monthly_report });

  useEffect(() => {
    const keyMap: any[] = [];
    !!projectwise_details_monthly_report &&
      Object.entries(projectwise_details_monthly_report).map(
        ([key, value], index) => {
          keyMap.push(key);
        }
      );
    setCheckedDimensions([...keyMap]);
  }, [projectwise_details_monthly_report]);
  console.log({ checkedDimensions });
  !!projectwise_details_monthly_report &&
    Object.entries(projectwise_details_monthly_report).map(([key, val]) =>
      console.log({ key })
    );

  useEffect(() => {
    setIsMuted((prev) => !prev);
  }, [checkedDimensions.length]);

    
  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  // backgroundColor: "#0060FF",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      // color: "black",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
              <form>
                <DateTypeSwitchTab
                  value={dateTypeSwitch}
                  handleChange={handleChangeDateTypeChange}
                />
                <Grid container justifyContent={"flex-start"}>
                  {/* <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Customer Name
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"customer_id"}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocomplete
                              multiple
                              id="tags-standard"
                              options={!!customer_list ? customer_list : []}
                              style={{ width: "100.9%" }}
                              getOptionLabel={(option) => option.customer_name}
                              // readOnly={!!project ? edit_flag : !edit_flag}
                              onChange={(evemt, value) =>
                                onChange(
                                  !!value ? value.map((m) => m.customer_id) : []
                                )
                              }
                              value={
                                !!value
                                  ? customer_list.filter((m) =>
                                      value.includes(m.customer_id)
                                    )
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="standard-required"
                                  className={"smaller"}
                                  variant="outlined"
                                  placeholder="Customer Lists"
                                  onBlur={onBlur}
                                  size="small"
                                  error={!!errors["customer_id"]}
                                  helperText={
                                    errors.customer_id &&
                                    `${errors.customer_id.message}* This field is Required`
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {!!watch("customer_id") && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Project Name
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                          <Controller
                            control={control}
                            name={"project_id"}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocomplete
                                multiple
                                id="tags-standard"
                                options={!!project_list ? project_list : []}
                                style={{ width: "100.9%" }}
                                getOptionLabel={(option) => option.project_name}
                                // readOnly={!!project ? edit_flag : !edit_flag}
                                onChange={(evemt, value) =>
                                  onChange(
                                    !!value
                                      ? value.map((m) => m.project_id)
                                      : []
                                  )
                                }
                                value={
                                  !!value
                                    ? project_list.filter((m) =>
                                        value.includes(m.project_id)
                                      )
                                    : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="standard-required"
                                    className={"smaller"}
                                    variant="outlined"
                                    placeholder="Project Lists"
                                    onBlur={onBlur}
                                    size="small"
                                    error={!!errors["project_id"]}
                                    helperText={
                                      errors.project_id &&
                                      `${errors.project_id.message}* This field is Required`
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}

                  {dateTypeSwitch === "START-END" ? (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Start Date
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                          <Controller
                            control={control}
                            name={"prj_start_date"}
                            rules={{ required: false }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <TextField
                                type={"month"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100.9%" }}
                                onBlur={onBlur}
                                size="small"
                                value={!!value ? value : ""}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Start Date"}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {!!watch("prj_start_date") &&
                    dateTypeSwitch === "START-END" && (
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              End Date
                            </Typography>
                          </Grid>
                          <Grid item xs={5.05} style={{ marginLeft: -6 }}>
                            <Controller
                              control={control}
                              name={"prj_end_date"}
                              rules={{ required: false }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <TextField
                                  type={"month"}
                                  id="standard-required"
                                  variant="outlined"
                                  className={"smaller"}
                                  style={{ width: "100.9%" }}
                                  onBlur={onBlur}
                                  size="small"
                                  value={!!value ? value : ""}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"End Date"}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={6}>
                    {dateTypeSwitch === "YEAR-MONTH" ? (
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Select Year
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -9 }}>
                          <Controller
                            control={control}
                            name={"selected_year"}
                            rules={{ required: false }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <DatePicker
                                views={["year"]}
                                inputFormat="YYYY"
                                value={value}
                                InputProps={{
                                  onBlur: onBlur,
                                  size: "small",
                                  style: {
                                    width: "123%",
                                  },
                                }}
                                onChange={(newValue) => {
                                  onChange(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} helperText={null} />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {!!watch("selected_year") &&
                    dateTypeSwitch === "YEAR-MONTH" && (
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                marginTop: -15,
                                // textAlign: "center"
                              }}
                            >
                              Select Quarter
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={5.05}
                            style={{ marginLeft: -6, marginTop: 5 }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={1}
                              getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              step={1}
                              marks={marks}
                              min={0}
                              max={4}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  <Grid
                    direction={"column"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    width={"100%"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "97%",
                      }}
                    >
                      <Box sx={{ borderRight: "1px solid #fff", width: "50%" }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "900",
                            borderRadius: "10px 0 0px 0",
                            backgroundColor: "#0060FF ",
                            color: "#fff",
                          }}
                        >
                          Customer Name
                        </Typography>
                      </Box>

                      <Box sx={{ borderLeft: "1px solid #fff", width: "50%" }}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "900",
                            borderRadius: "0px 10px   0px 0px",
                            backgroundColor: "#0060FF",
                            color: "#fff",
                          }}
                        >
                          Project Name
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", width: "97%" }}>
                      <Box sx={{ width: "50%" }}>
                        {!!customer_list &&
                          customer_list.map((item, index) => (
                            <Chip
                              avatar={
                                <Avatar
                                  sx={{
                                    backgroundColor:
                                      !!checkedCustomer &&
                                      checkedCustomer.find(
                                        (m) => m.customer_id == item.customer_id
                                      )
                                        ? "#0a59cf"
                                        : "#682121",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color:
                                        !!checkedCustomer &&
                                        checkedCustomer.find(
                                          (m) =>
                                            m.customer_id == item.customer_id
                                        )
                                          ? "white"
                                          : "white",
                                    }}
                                  >
                                    {!!checkedCustomer &&
                                    checkedCustomer.find(
                                      (m) => m.customer_id == item.customer_id
                                    ) ? (
                                      <IconButton sx={{ color: "white" }}>
                                        <DoneIcon />
                                      </IconButton>
                                    ) : (
                                      item.customer_name.slice(0, 1)
                                    )}
                                  </Typography>
                                </Avatar>
                              }
                              label={item.customer_name}
                              variant="outlined"
                              sx={{
                                margin: 1,
                                borderColor:
                                  !!checkedCustomer &&
                                  checkedCustomer.find(
                                    (m) => m.customer_id == item.customer_id
                                  )
                                    ? "#0a59cf"
                                    : "#682121",
                                color:
                                  !!checkedCustomer &&
                                  checkedCustomer.find(
                                    (m) => m.customer_id == item.customer_id
                                  )
                                    ? "#0a59cf"
                                    : "#682121",
                                fontWeight:
                                  !!checkedCustomer &&
                                  checkedCustomer.find(
                                    (m) => m.customer_id == item.customer_id
                                  )
                                    ? 600
                                    : 400,
                              }}
                              key={index}
                              onClick={(event) => (
                                selectCustomer(
                                  event.currentTarget.innerText,
                                  item
                                ),
                                setSelectedCustomer(item.customer_id)
                              )}
                            />
                          ))}
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        <Box
                          className="deptCardDetails"
                          sx={{
                            pb: 2,
                            px: 1,
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          {!!checkedCustomer &&
                            checkedCustomer.map((m) => (
                              <Box
                                sx={{
                                  pb: 2,
                                  px: 1,
                                  overflow: "auto",
                                }}
                              >
                                <Typography
                                  sx={{
                                    padding: 0.5,
                                    marginLeft: "3%",
                                    fontWeight: 600,
                                    color: "#0a59cf",
                                  }}
                                >
                                  {m.customer_name}
                                </Typography>

                                <Divider
                                  style={{
                                    borderColor: "#9E9E9E",
                                    opacity: 0.7,
                                    width: "94%",
                                    marginLeft: "3%",
                                  }}
                                ></Divider>

                                {!!project_list &&
                                  project_list
                                    .filter(
                                      (n) => n.customer_id === m.customer_id
                                    )
                                    .map((self, indes) => (
                                      <Box
                                        key={indes}
                                        sx={{
                                          my: 0.1,
                                          width: "90%",
                                          mx: "auto",
                                          border: "2px solid #F1F1F1",
                                          cursor: "pointer",
                                          borderRadius: "15px",
                                          px: 2,
                                          py: 0,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <Checkbox
                                          color="primary"
                                          checked={
                                            !!checkedProject.find(
                                              (c) =>
                                                c.project_id === self.project_id
                                            )
                                          }
                                          onChange={(event) =>
                                            selectedProject(
                                              event.target.checked,
                                              self
                                            )
                                          }
                                        />
                                        <Typography>
                                          {self.project_name}
                                        </Typography>
                                      </Box>
                                    ))}
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    padding={3}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={ClearFilter}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      {!!reportSection && reportSection == true && (
        <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
          <Grid container direction={"column"}>
            <Grid
              item
              p={0.4}
              sx={{
                backgroundColor: "#e6f0ff",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
            >
              <Typography
                sx={{
                  // color: "#fff",
                  color: "#00000",
                  fontWeight: 600,
                  fontSize: 14,
                  textAlign: "center",
                }}
              >
                {`Project Report - ${
                  !!checkedProject &&
                  checkedProject.map((m) => m.project_name).toString()
                } `}{" "}
              </Typography>
            </Grid>

            <Grid
              item
              // xs={3}
              sx={{
                borderColor: "#DDDDDD",
                borderWidth: 2,
                borderRightStyle: "solid",
              }}
            >
              <Grid
                container
                direction={"row"}
                sx={{
                  padding: 3,
                }}
              >
                {!!projectwise_details_monthly_report &&
                  Object.entries(projectwise_details_monthly_report).map(
                    ([key, value], index) => (
                      <Box
                        key={index}
                        sx={{
                          my: 0.5,
                          width: "20%",
                          border: "2px solid #F1F1F1",
                          cursor: "pointer",
                          borderRadius: "15px",
                          px: 2,
                          mx: "auto",
                          py: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Checkbox
                          color="primary"
                          value={key}
                          checked={checkedDimensions.find(
                            (self, index) => self === key
                          )}
                          // checked={checked}
                          onChange={(event) => handleChecked(key)}
                        />
                        <Typography>
                          {key == "emp_expense"
                            ? "Employee Expenses"
                            : key == "aditional_expense"
                            ? "Additional Expenses"
                            : "Project Payment"}
                        </Typography>
                      </Box>
                    )
                  )}
              </Grid>
            </Grid>
            <Grid item xs={9} sx={{ padding: 2 }}>
              <ChartView
                checkedDimensions={!!checkedDimensions ? checkedDimensions : []}
                projectwise_details_monthly_report={
                  projectwise_details_monthly_report
                }
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default ConsolidatedReportView;
interface ConsolidatedReportViewProps {
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  watch?: any;
  project_list: ProjectList[];
  FilterSubmit?: any;
  projectwise_details_monthly_report?: ProjectwiseDetailsMonthlyReport;
  customer_list: CustomerList[];
  // GetAllProjectAction?: any;
}
