import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { ProjectDocumentsTypes } from "../../../interfaces/others.interface";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Box,
  DialogContentText,
  Button,
  DialogActions,
  Alert,
} from "@mui/material";

interface ProjectDocumentsProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  documents: any[];
  downloadDocument: any;
  document: ProjectDocumentsTypes[];
}

const ProjectDocuments: FC<ProjectDocumentsProps> = ({
  documents,
  isOpen,
  setIsOpen,
  downloadDocument,
}): JSX.Element => {
  const fileRef = useRef<any>();

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ m: 0, p: 0 }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#0060ff",
            px: 5,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 1,
          }}
        >
          <Typography sx={{ fontSize: 20 }}>
            Manage Work Order Documents
          </Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ my: 2, overflowX: "hidden" }}>
          <Grid
            container
            rowGap={4.5}
            spacing={1}
            sx={{ my: 1, ml: 1 }}
            columnGap={2.6}
          >
            {documents.map((self, index, array) => (
              <FileBlock
                document={self}
                key={index}
                downloadDocHandler={downloadDocument}
                totalCount={array.length}
              />
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

/***
 * Individual file block
 * ***/
interface FileBlockProps {
  downloadDocHandler: any;
  document: ProjectDocumentsTypes;
  totalCount: number;
}
const FileBlock: FC<FileBlockProps> = ({
  downloadDocHandler,
  document,
  totalCount,
}): JSX.Element => {
  const fileRef = useRef<any>();
  const clearVal = () => {
    if (!!fileRef && fileRef?.current) {
      fileRef.current.value = "";
    }
  };
  const [ConfirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
  return (
    <Grid
      item
      xs={12}
      md={1.5}
      sx={{
        height: "200px",
        boxShadow: "0px 1px 7px 1px rgba(0,0,0,0.5)",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <DescriptionIcon sx={{ fontSize: 80, color: "gray" }} />
      </Box>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          my: 1,
          mx: 1,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            display: "flex",
            flexWrap: "wrap",
            wordWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <p>{document.document_image}</p>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          my: 1,
          mx: 1,
          px: 1,
          position: "absolute",
          left: "0%",
          bottom: "-8%",
          borderRadius: "5px",
          width: "80.5%",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box
          onClick={() => downloadDocHandler(document.document_image)}
          sx={{
            px: 2,
            //
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 0.5,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CloudDownloadIcon sx={{ color: "#1565c0" }} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ProjectDocuments;
