import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import { StoreState } from "../../../models/reduxModels";
import {
  DeletePaymentDetailsAction,
  GetProjectPaymentListAction,
} from "../../../stores/actions/customerAction";
import PaymentDetailsView from "./PaymentDetailsView";
import { userDetails } from "../../../models/userModels";

const PaymentDetails = ({
  GetProjectPaymentListAction,
  project_payment_list,
  DeletePaymentDetailsAction,
  project_list,
  user,
}: PaymentDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  React.useEffect(() => {
    if (!!id) {
      GetProjectPaymentListAction({
        project_id: id,
        project_payment_invoice_id: null,
      });
    }
  }, []);
  const DeleteAction = (props: number) => {
    DeletePaymentDetailsAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
      data2: {
        project_id: id,
        invoice_id: null,
      },
    });
  };
  // console.log(project_payment_list);
  return (
    <PaymentDetailsView
      project_payment_list={project_payment_list.filter(
        (m: any, index: number) => !!id && m.project_id == +id
      )}
      project_list={project_list}
      DeleteAction={DeleteAction}
      id={id}
      user={user}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    project_payment_list: state.customer.project_payment_list,
    project_list: state.customer.project_list,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  GetProjectPaymentListAction,
  DeletePaymentDetailsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
interface PaymentDetailsProps {
  GetProjectPaymentListAction: any;
  project_payment_list: ProjectPaymentList[];
  DeletePaymentDetailsAction: any;
  project_list: ProjectList[];
  user?: userDetails;
}
