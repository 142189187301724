import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
  Button,
  Divider,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExperienceDetails = ({
  addExpDetail,
  setaddExpDetail,
}: ExperienceDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns2: any[] = [
    "Company Name",
    "Designation",
    "Start Date",
    "End Date",
    "Yearly CTC",
    "Remarks",
    "Action",
  ];
  const addExperienceDetails = () => {
    if (
      !addExpDetail[addExpDetail.length - 1].company_name ||
      !addExpDetail[addExpDetail.length - 1].started_at ||
      !addExpDetail[addExpDetail.length - 1].ended_at
    ) {
      enqueueSnackbar("Please fill add all detail before adding new row.", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
      Temp = [
        ...Temp,
        {
          company_name: null,
          posted_at: null,
          started_at: null,
          ended_at: null,
          ctc: null,
          reason_for_leaving: null,
        },
      ];
      setaddExpDetail(Temp);
    }
  };
  const OnChangeExperience = (value: string, row: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
    Temp[index][row] = value;
    setaddExpDetail(Temp);
  };
  const DeleteExpAction = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
    let expDet: any[] = [];
    Temp.forEach((element: any, indexs: any) => {
      if (index != indexs) {
        expDet.push(JSON.parse(JSON.stringify(element)));
      }
    });
    setaddExpDetail([]);
    setTimeout(() => {
      setaddExpDetail(expDet);
    }, 200);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%",
                // marginBottom: 20,
              }}
            >
              Experience History
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            item
            marginLeft={11}
            xs={10}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
              //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns2.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!addExpDetail &&
                    addExpDetail.map((row: any, index: number) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell align="left" key={columns2[0]}>
                          {/* {row.organization_name} */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "company_name",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 80 }}
                              value={row.company_name}
                              placeholder={"Company Name"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[2]}>
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "designation",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 80 }}
                              value={row.designation}
                              placeholder={"Designation"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[3]}>
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"date"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "started_at",
                                  index
                                )
                              }
                              value={row.started_at}
                              placeholder={"Started At"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[4]}>
                          {/* {row.created_at
                              ? moment(row.created_at)
                                  .format("DD/MM/YYYY HH:mm:ss a")
                                  .toUpperCase()
                              : "N/A"} */}
                          {/* 23.12.22 */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"date"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "ended_at",
                                  index
                                )
                              }
                              value={row.ended_at}
                              placeholder={"Ended At"}
                            // error={!!errors["project_name"]}
                            // helperText={
                            //   errors.project_name &&
                            //   `${errors.project_name.message}* This field is Required`
                            // }
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[5]}>
                          {/* 80 */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"number"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "ctc",
                                  index
                                )
                              }
                              value={row.ctc}
                              placeholder={"Yearly CTC"}
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 7);
                              }}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[6]}>
                          {/* Remarks */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeExperience(
                                  value.target.value,
                                  "remarks",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 80 }}
                              value={row.remarks}
                              placeholder={"Remarks"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns2[7]}>
                          {addExpDetail.length > 1 && (
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon
                                  color="error"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => DeleteExpAction(index)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={3}>
              <Button
                onClick={() => addExperienceDetails()}
                size="medium"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default ExperienceDetails;
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ExperienceDetailsProps {
  addExpDetail: any[];
  setaddExpDetail?: any;
}
