import React, { useState } from "react";
import { connect } from "react-redux";
import {
  CustomerList,
  ProjectList,
  ViewProject,
} from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllCustomerAction,
  GetAllProjectAction,
} from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import RegisterPayrollView from "./RegisterPayrollView";
import {
  GeneratePayrollBatch,
  PayrollAvailableEmployeeAction,
  PayrollAvailableEmployeeSuccessAction,
} from "../../../stores/actions/payrollActions";
import { GetProjectViewAction } from "../../../stores/actions/customerAction";
import { ApplicableEmployeeList } from "../../../models/payrollModel";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const RegisterPayroll = ({
  organization_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  project_list,
  GetAllProjectAction,
  GetProjectViewAction,
  PayrollAvailableEmployeeAction,
  SidenavAutoCloseOpen,
  project_detail,
  ApplicableEmployeeList,
  PayrollAvailableEmployeeSuccessAction,
  GeneratePayrollBatch,
}: RegisterPayrollProps) => {
  const [approvalList, SetapprovalList] = useState<ApplicableEmployeeList[]>(
    []
  );
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    PayrollAvailableEmployeeSuccessAction([]);
    GetAllOrganizationAction();
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllProjectAction({
      organization_id: null,
    });
  }, []);
  const onSubmit = (payload: any) => {
    payload.attendenceSalaryTypeId = null;
    PayrollAvailableEmployeeAction(payload);
  };
  const ProjectSelect = (data: number) => {
    if (!!data) {
      GetProjectViewAction(+data);
    }
  };
  const CreateBatch = (data: any) => {
    GeneratePayrollBatch({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
    // console.log("data",data);
    
  };
  return (
    <RegisterPayrollView
      organization_list={organization_list}
      employee_type_list={employee_type_list}
      project_list={project_list}
      onSubmit={onSubmit}
      ProjectSelect={ProjectSelect}
      project_detail={project_detail}
      ApplicableEmployeeList={ApplicableEmployeeList}
      approvalList={approvalList}
      SetapprovalList={SetapprovalList}
      CreateBatch={CreateBatch}
      PayrollAvailableEmployeeSuccessAction={
        PayrollAvailableEmployeeSuccessAction
      }
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_type_list: state.organization.employee_type_list,
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    project_detail: state.customer.project_detail,
    ApplicableEmployeeList: state.payroll.ApplicableEmployeeList,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllEmployeeTypeAction,
  GetAllCustomerAction,
  GetAllProjectAction,
  PayrollAvailableEmployeeAction,
  GetProjectViewAction,
  PayrollAvailableEmployeeSuccessAction,
  GeneratePayrollBatch,
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPayroll);
interface RegisterPayrollProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  project_list: ProjectList[];
  GetAllOrganizationAction?: any;
  GetAllEmployeeTypeAction?: any;
  GetAllProjectAction?: any;
  SidenavAutoCloseOpen?: any;
  PayrollAvailableEmployeeAction?: any;
  GetProjectViewAction?: any;
  project_detail?: ViewProject;
  ApplicableEmployeeList: ApplicableEmployeeList[];
  PayrollAvailableEmployeeSuccessAction?: any;
  GeneratePayrollBatch?: any;
}
