import { Cookies } from "react-cookie";
import {
  CustomerAddService,
  CustomerEditService,
  DivisionGetAll,
  GetAllCustomerService,
  GetAllProjectService,
  GetProjectViewService,
  ProjectAddService,
  ProjectAllLevelsService,
  ProjectAttendaceTemplateService,
  ProjectEditService,
  SubDivisionGetAll,
  ProjectDashboardService,
  attendanceForProjectDashboardService,
  getBatchDetailsByProjectIdService,
  getEmployeePayrollDetailsByProjectIdService,
  editAssociationStatusService,
  getLevelHierarchyService,
  getTargetRateValueService,
  getLevelDetailsService,
  GetAvailableEmpService,
  AllocateEmpService,
  ProjectBudgetListService,
  ProjectUnudgetListService,
  CreateBudgetingService,
  ProjectBudgetingEditService,
  ProjectPaymentListService,
  CreateProjectPaymentService,
  ProjectPaymentEditService,
  ProjectPaymentDeleteService,
  GetAllStateListService,
  GetBudgetDashboardDetailsService,
  GetLevelMasterService,
  GetLocationLevelsService,
  AddLevelMasterService,
  EditLevelMasterService,
  getLevelByMasterAction,
  addLevelService,
  ConsolidatedProjectReportService,
  MonthWiseEmpSalaryinBudgetingService,
  EmpMonthlySalaryProjectwiseService,
  IntiateProjectClosureService,
  GetAllAvailableProjectForClosureService,
  GetAllPendingProjectClosureListService,
  ApprovalPendingProjectClosureService,
  CompletedProjectListService,
} from "../../services/customerService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
import { ConsolidatedReportPayload, EmpMonthlySalaryProjectwisePayload, ICreateLevelMaster, MonthWiseEmpSalaryinBudgetingPayload } from "../../models/customerModel";

export enum CustomerActionTypes {
  Get_All_Customer_Success_Action = "[CUSTOMER] Get All Customer Success Action",
  Customer_Add_Success_Action = "[CUSTOMER] Customer Add Success Action",
  Edit_Customer_Success_Action = "[CUSTOMER] Customer Edit Success Action",
  Get_All_Project_Success_Action = "[CUSTOMER] Get All Project Success Action ",
  Project_Add_Success_Action = "[CUSTOMER] Project Add Success Action",
  Get_Project_View_Success_Action = "[CUSTOMER] Get Project View Success Action",
  Edit_Project_Success_Action = "[CUSTOMER] Project Edit Success Action",
  Division_Get_All_Success_Action = "[CUSTOMER] Division Get All Success Action",
  SubDivision_Get_All_Success_Action = "[CUSTOMER] SubDivision Get All Success Action",
  Get_All_Project_Attendance_Template_Success_Action = "[CUSTOMER] Get All Project Attendance Template Success Action",
  Get_All_Project_Levels_Success_Action = "[CUSTOMER] Get All Project Levels Success Action",
  Get_All_Project_Dashboard_Success_action = "[CUSTOMER] Get All Project Dashboard Success Action",
  Get_All_Project_Dashboard_Attendence_status_Success_action = "[CUSTOMER] Get All Project Dashboard Attendence Status Action",
  Get_All_Project_Dashboard_Batch_Details_Action = "[CUSTOMER] Get All Project Dashboard Batch Details Action",
  Get_All_Project_Dashboard_Employee_Payroll_Action = "[CUSTOMER] Get All Dashboard Employee Payroll Action",
  Get_All_Levels_For_Project_Create_Action = "[CUSTOMER] Get All Levels For Project Create Action",
  Clear_All_levels_For_Project_Action = "[CUSTOMER] Clear All Levels For Project Action",
  Get_Levels_Target_Rate_Value_For_Project_Action = "[CUSTOMER] Get Levels Target Rate Value For Project Action",
  CLEAR_Levels_Target_Rate_Value_For_Project_Action = "[CUSTOMER] Clear Levels Target Rate Value For Project Action",
  Get_Level_Details_Action = "[CUSTOMER] Get Level Details Action success",
  Get_All_Available_Emp_Success_Action = "[CUSTOMER] Get Available Employee Success Action",
  Allocate_Emp_Success_Action = "[CUSTOMER] Allocate Employee Success Action",
  Get_Project_Budget_List_Success_Action = "[CUSTOMER] Get Project Budget List Success Action",
  Get_Project_Unbudget_List_Success_Action = "[CUSTOMER] Get Project Unbudget List Success Action",
  Create_Budgeting_Success_Action = "[CUSTOMER] Create Budgeting Success Action",
  Edit_Project_Budgeting_Success_Action = "[CUSTOMER] Edit Project Budgeting Success Action",
  Get_Project_Payment_List_Success_Action = "[CUSTOMER] Get Project Payment List Success Action",
  Create_Project_Payment_Success_Action = "[CUSTOMER] Create Project Payment Success Action",
  Edit_Payment_Details_Success_Action = "[CUSTOMER] Edit Payment Details Success Action",
  Employee_Payment_Details_Success_Action = "[CUSTOMER] Employee Payment Details Success Action",
  Get_All_State_List_Success_Action = "[CUSTOMER] Get All State List Success Action",
  Get_Project_Budget_Details_Success_Action = "[CUSTOMER] Get Project Budget Details Success Action",
  Get_Level_Master_Details_Success_Action = "[CUSTOMER] Get Level Master Details Success Action",
  Get_Location_Levels_Success_Action = "[CUSTOMER] Get Location Levels Success Action",
  Consolidated__Project_Report_Success_Action = "[CUSTOMER] Consolidated Project Report Success Action",
  Month_Wise_Employee_Salary_Budgeting_Success_Action = "[CUSTOMER] Month Wise Employee Salary Budgeting Success Action",
  Employee_Monthly_Salary_Projectwise_Success_Action = "[CUSTOMER] Employee Monthly Salary Projectwise Success Action",
  Intiate_Project_Closure_Success_Action = "[CUSTOMER] Intiate Project Closure Success Action",
  Get_All_Available_Project_For_Closure = "[CUSTOMER] Get All Available Project For Closure Success Action",
  Get_All_Pending_Project_Closure_List_Success_Action = "[CUSTOMER] Get All Pending Project Closure List Success Action",
  Approval_Pending_Project_Closure_Success_Action = "[CUSTOMER] Approval Pending Project Closure Success Action",
  Completed_Project_List_Success_Action = "[CUSTOMER] Completed Project List Success Action",
}

export const GetAllCustomerAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Customer List. Please Wait...",
        type: 2,
      })
    );
    return GetAllCustomerService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllCustomerSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllCustomerSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Customer_Success_Action,
    payload: data,
  };
};

export const CreateCustomerAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Customer. Please Wait...",
        type: 2,
      })
    );
    return CustomerAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/customer/customer-main");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateCustomerSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateCustomerSuccessAction = () => {
  return { type: CustomerActionTypes.Customer_Add_Success_Action };
};

export const EditCustomerAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Customer. Please Wait...",
        type: 2,
      })
    );
    return CustomerEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/customer/customer-main");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditCustomerSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditCustomerSuccessAction = () => {
  return {
    type: CustomerActionTypes.Edit_Customer_Success_Action,
  };
};

export const GetAllProjectAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Project List. Please Wait...",
        type: 2,
      })
    );
    return GetAllProjectService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllProjectSuccessAction(response.data));
          // console.log(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllProjectSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Project_Success_Action,
    payload: data,
  };
};

export const CreateProjectAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Project. Please Wait...",
        type: 2,
      })
    );
    return ProjectAddService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/customer/project-main/");
          payload.navigateTo(-1);
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateProjectSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateProjectSuccessAction = () => {
  return { type: CustomerActionTypes.Project_Add_Success_Action };
};

export const GetProjectViewAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Please Wait...",
        type: 2,
      })
    );
    return GetProjectViewService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetProjectViewSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetProjectViewSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Project_View_Success_Action,
    payload: data,
  };
};

export const EditProjectAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Project. Please Wait...",
        type: 2,
      })
    );
    return ProjectEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(-1);
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditProjectSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditProjectSuccessAction = () => {
  return {
    type: CustomerActionTypes.Edit_Project_Success_Action,
  };
};

export const GetAllDivisionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Customer List. Please Wait...",
        type: 2,
      })
    );
    return DivisionGetAll()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllDivisionSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllDivisionSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Division_Get_All_Success_Action,
    payload: data,
  };
};

export const GetAllSubDivisionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Customer List. Please Wait...",
        type: 2,
      })
    );
    return SubDivisionGetAll()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllSubDivisionSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllSubDivisionSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.SubDivision_Get_All_Success_Action,
    payload: data,
  };
};

export const GetAllProjectAttendanceTemplateAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Project Attendace Template List. Please Wait...",
        type: 2,
      })
    );
    return ProjectAttendaceTemplateService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllProjectAttendanceTemplateSuccessAction(response.data));
          // console.log(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllProjectAttendanceTemplateSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Project_Attendance_Template_Success_Action,
    payload: data,
  };
};

export const GetAllProjectLevelsAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Project Levels. Please Wait...",
        type: 2,
      })
    );
    return ProjectAllLevelsService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetAllProjectLevelsSuccessAction(response.data));
          // console.log(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllProjectLevelsSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Project_Levels_Success_Action,
    payload: data,
  };
};

export const GetProjectAttendanceStatusMonthWise =
  (projectId: number, year: number, month: number) =>
  (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard Details Loading. Please Wait...",
        type: 2,
      })
    );
    return attendanceForProjectDashboardService(projectId, month, year)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_All_Project_Dashboard_Attendence_status_Success_action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
// project dashboard action
export const GetProjectDashboardAction =
  (projectId: number, year: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard Details Loading. Please Wait...",
        type: 2,
      })
    );
    return ProjectDashboardService(projectId, year)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_All_Project_Dashboard_Success_action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

// get batch details by project id for project dashboard
export const GetBatchDetailsByProjectIdAction =
  (projectId: number, year: number, month: number) =>
  (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard Details Loading. Please Wait...",
        type: 2,
      })
    );
    return getBatchDetailsByProjectIdService(projectId, year, month)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_All_Project_Dashboard_Batch_Details_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getEmployeePayrollByProjectId =
  (projectId: number, year: number, month: number) =>
  (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard Details Loading. Please Wait...",
        type: 2,
      })
    );
    console.log("service hit");
    return getEmployeePayrollDetailsByProjectIdService(projectId, year, month)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_All_Project_Dashboard_Employee_Payroll_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const editAssociationStatus =
  (payload: any) => (dispatch: any, getState: any) => {
    return editAssociationStatusService(payload.data.editIds)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(
            GetProjectDashboardAction(
              Number(payload.data.projectIds),
              Number(payload.data.year)
            )
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getLevelHierarchyAction =
  (levelKeyId: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Dashboard Details Loading. Please Wait...",
        type: 2,
      })
    );
    return getLevelHierarchyService(levelKeyId)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_All_Levels_For_Project_Create_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const ClearLevelsFromPorject = () => (dispatch: any) => {
  dispatch({
    type: CustomerActionTypes.Clear_All_levels_For_Project_Action,
  });
};

export const getLevelTargetRateValueAction =
  (projectId: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Project Details Loading. Please Wait...",
        type: 2,
      })
    );
    return getTargetRateValueService(projectId)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_Levels_Target_Rate_Value_For_Project_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const clearTargetRateValueAction =
  () => (dispatch: any, getState: any) => {
    dispatch({
      type: CustomerActionTypes.CLEAR_Levels_Target_Rate_Value_For_Project_Action,
    });
  };

// GET BULK UPLOAD STATUS

export const getLevelDetailsAction =
  (levelKey: number) => (dispatch: any, getState: any) => {
    console.log("action hit : ", levelKey);
    return getLevelDetailsService(levelKey)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: CustomerActionTypes.Get_Level_Details_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const GetAvailableEmpAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Available Employee List. Please Wait...",
        type: 2,
      })
    );
    return GetAvailableEmpService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAvailableEmpSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAvailableEmpSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Available_Emp_Success_Action,
    payload: data,
  };
};

export const AllocateEmpAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return AllocateEmpService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(AllocateEmpSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const AllocateEmpSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Allocate_Emp_Success_Action,
    payload: data,
  };
};

export const GetProjectBudgetListAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return ProjectBudgetListService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetProjectBudgetListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetProjectBudgetListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Project_Budget_List_Success_Action,
    payload: data,
  };
};

export const GetProjectUnbudgetListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return ProjectUnudgetListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetProjectUnbudgetListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetProjectUnbudgetListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Project_Unbudget_List_Success_Action,
    payload: data,
  };
};
export const CreateBudgetingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Budgeting. Please Wait...",
        type: 2,
      })
    );
    return CreateBudgetingService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/customer/project-budgeting/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateBudgetingSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateBudgetingSuccessAction = () => {
  return {
    type: CustomerActionTypes.Create_Budgeting_Success_Action,
  };
};
export const EditProjectBudgetingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Project Budgeting. Please Wait...",
        type: 2,
      })
    );
    return ProjectBudgetingEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/customer/project-budgeting/");
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditProjectBudgetingSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditProjectBudgetingSuccessAction = () => {
  return {
    type: CustomerActionTypes.Edit_Project_Budgeting_Success_Action,
  };
};

export const GetProjectPaymentListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return ProjectPaymentListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetProjectPaymentListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetProjectPaymentListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Project_Payment_List_Success_Action,
    payload: data,
  };
};

export const CreateProjectPaymentAction = (payload: any) => {
 
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Project Payment. Please Wait...",
        type: 2,
      })
    );
    return CreateProjectPaymentService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(-1);
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateProjectPaymentSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateProjectPaymentSuccessAction = () => {
  return {
    type: CustomerActionTypes.Create_Project_Payment_Success_Action,
  };
};
export const EditPaymentDetailsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Payment Details. Please Wait...",
        type: 2,
      })
    );
    return ProjectPaymentEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(-1);
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditPaymentDetailsSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditPaymentDetailsSuccessAction = () => {
  return {
    type: CustomerActionTypes.Edit_Payment_Details_Success_Action,
  };
};
export const DeletePaymentDetailsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Deleting Payment Details. Please Wait...",
        type: 2,
      })
    );
    return ProjectPaymentDeleteService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          await payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DeletePaymentDetailsSuccessAction());
          dispatch(GetProjectPaymentListAction(payload.data2));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeletePaymentDetailsSuccessAction = () => {
  return {
    type: CustomerActionTypes.Employee_Payment_Details_Success_Action,
  };
};

export const GetAllStateListAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All State Lists... Please Wait...",
        type: 2,
      })
    );
    return GetAllStateListService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllStateListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllStateListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_State_List_Success_Action,
    payload: data,
  };
};
export const GetProjectBudgetDetailsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return GetBudgetDashboardDetailsService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetProjectBudgetDetailsSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetProjectBudgetDetailsSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Project_Budget_Details_Success_Action,
    payload: data,
  };
};

export const GetLevelMasterAction = () => (dispatch: any, getState: any) => {
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "Please Wait...",
      type: 2,
    })
  );
  return GetLevelMasterService()
    .then(async (response) => {
      if (response.status !== 200) {
        dispatch(ApiCallErrorAction(response.data));
      } else {
        dispatch({
          type: CustomerActionTypes.Get_Level_Master_Details_Success_Action,
          payload: response.data,
        });
        console.log(response.data);
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        let cook = new Cookies();
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        cook.remove("user", { path: "/" });
        dispatch(LogOutAction());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

export const GetLocationLevelsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Please Wait...",
        type: 2,
      })
    );
    return GetLocationLevelsService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetLocationLevelsSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};
// add level master action
export const addLevelMasterAction =
  (formData: { formPayload: ICreateLevelMaster }) =>
  (dispatch: any, getState: any) => {
    return AddLevelMasterService(formData.formPayload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetLevelMasterAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const GetLocationLevelsSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_Location_Levels_Success_Action,
    payload: data,
  };
};

export const EditLevelAction =
  ({
    formPayload,
    enqueueSnackbar,
  }: {
    formPayload: ICreateLevelMaster;
    enqueueSnackbar: any;
  }) =>
  (dispatch: any, getState: any) => {
    return EditLevelMasterService(formPayload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetLevelMasterAction());
          enqueueSnackbar(`Level Master Updated Successfully`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getLevelDetailsById =
  (formPayload: any) => (dispatch: any, getState: any) => {
    return getLevelByMasterAction(formPayload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetLevelMasterAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const createLevelAction =
  (payload: any) => (dispatch: any, getState: any) => {
    return addLevelService(payload.formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetLocationLevelsAction({
              level_key_id: payload.formData.level_key_id,
            })
          );
          payload.enqueueSnackbar("Successfully Added", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

  export const ConsolidatedProjectReportAction =
  (payload: ConsolidatedReportPayload ) =>
  (dispatch: any, getState: any) => {
    return ConsolidatedProjectReportService(payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ConsolidatedProjectReportSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const ConsolidatedProjectReportSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Consolidated__Project_Report_Success_Action,
    payload: data,
  };
};

export const MonthWiseEmpSalaryinBudgetingAction =
  (payload: MonthWiseEmpSalaryinBudgetingPayload ) =>
  (dispatch: any, getState: any) => {
    return MonthWiseEmpSalaryinBudgetingService(payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(MonthWiseEmpSalaryinBudgetingSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const MonthWiseEmpSalaryinBudgetingSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Month_Wise_Employee_Salary_Budgeting_Success_Action,
    payload: data,
  };
};

export const EmpMonthlySalaryProjectwiseAction =
  (payload: EmpMonthlySalaryProjectwisePayload ) =>
  (dispatch: any, getState: any) => {
    return EmpMonthlySalaryProjectwiseService(payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(EmpMonthlySalaryProjectwiseSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const EmpMonthlySalaryProjectwiseSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Employee_Monthly_Salary_Projectwise_Success_Action,
    payload: data,
  };
};

//Project Closure
export const IntiateProjectClosureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Project Closure Initiating. Please Wait...",
        type: 2,
      })
    );
    return IntiateProjectClosureService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(IntiateProjectClosureSuccessAction());
          payload.enqueueSnackbar("Successfully Project Closure Initiated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(GetAllAvailableProjectForClosureAction(payload.data_list));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const IntiateProjectClosureSuccessAction = () => {
  return {
    type: CustomerActionTypes.Intiate_Project_Closure_Success_Action,    
  };
};
//Available List for Project Closure
export const GetAllAvailableProjectForClosureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Available List For Project Closure. Please Wait...",
        type: 2,
      })
    );
    return GetAllAvailableProjectForClosureService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllAvailableProjectForClosureSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllAvailableProjectForClosureSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Available_Project_For_Closure,  
    payload: data  
  };
};

// All pending project closure list 
export const GetAllPendingProjectClosureListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "List For Pending Project Closure. Please Wait...",
        type: 2,
      })
    );
    return GetAllPendingProjectClosureListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllPendingProjectClosureListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllPendingProjectClosureListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Get_All_Pending_Project_Closure_List_Success_Action,  
    payload: data  
  };
};

// All pending project closure Approval
export const ApprovalPendingProjectClosureAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Approval of Pending Project Closure. Please Wait...",
        type: 2,
      })
    );
    return ApprovalPendingProjectClosureService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ApprovalPendingProjectClosureSuccessAction());
          payload.enqueueSnackbar("Action Submitted Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(GetAllPendingProjectClosureListAction(payload.data_list))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ApprovalPendingProjectClosureSuccessAction = () => {
  return {
    type: CustomerActionTypes.Approval_Pending_Project_Closure_Success_Action
  };
};

//Completed Project List
export const CompletedProjectListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Completed Project List. Please Wait...",
        type: 2,
      })
    );
    return CompletedProjectListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CompletedProjectListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CompletedProjectListSuccessAction = (data: any) => {
  return {
    type: CustomerActionTypes.Completed_Project_List_Success_Action,
    payload: data
  };
};