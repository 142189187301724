import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  TextareaAutosize,
  Divider,
  IconButton,
  Switch,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import {
  AllDepartmentList,
  DomainList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
function AddSalaryComponentView({
  Submit,
  // component_list,
  salary_priod,
  domain_list,
  component,
  SubmitUpdatedData,
  id,
}: AddSalaryComponentViewProps) {
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const location = useLocation();

  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  console.log("location : ", location);
  const isEditing = location?.state?.isEditing;
  console.log("isEditing : ", location?.state?.isEditing);

  const onSubmit = (data: any) => {
    if (!!component) {
      console.log("editing hit");
      let payload: AllDepartmentList = {
        ...component,
        ...data,
        default: checked ? 1 : 0,
        attendace_dependancy:
          data.attendace_dependancy <= 0 ? 0 : data.attendace_dependancy,
      };
      SubmitUpdatedData(payload);
    } else {
      let payload: any = {
        default: checked ? 1 : 0,
        attendace_dependancy:
          data.attendace_dependancy <= 0 ? 0 : data.attendace_dependancy,
      };
      Submit({ ...data, ...payload });
    }
  };
  const [attandance, SetAttandance] = useState<any[]>([
    {
      key: "Yes",
      value: 1,
    },
    {
      key: "No",
      value: -1,
    },
  ]);
  const [checked, setChecked] = useState(false);
  // const [checked2, setChecked2] = useState(false);

  useEffect(() => {
    if (!!component) {
      // setValue("compent_id", component.compent_id);
      setValue("component_name", component.component_name);
      setValue("component_type", component.component_type);
      setValue("component_desc", component.component_desc);
      setValue("salary_priod", component.salary_priod);
      setValue("display_name", component.display_name);
      setValue(
        "attendace_dependancy",
        !!component.attendace_dependancy ? component.attendace_dependancy : -1
      );
      // setChecked2(component.include_gross? true: false);
      setChecked(component.default ? true : false);
    }
  }, [component]);
  return (
    <Paper elevation={5} sx={{ marginTop: 5, marginInline: 10 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/organization/salary-component/`)}
          // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              // fontWeight: "700",
              // fontSize: 18,
              // fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            {!!component ? "Salary Component Details" : "Add Salary Component"}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>

      <form>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ width: "100%" }}>
            {!!isEditing ? (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                sx={{ marginTop: 3 }}
                direction={"row"}
              >
                {/* <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 30 }}
          > */}

                <Grid
                  item
                  xs={6}
                  container
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                      }}
                    >
                      Payslip Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5} pl={0.5}>
                    <Controller
                      control={control}
                      name={"display_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "101.9%" }}
                          onBlur={onBlur}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!isEditing
                              ? false
                              : id == 0
                                ? false
                                : true,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"Display Name"}
                          error={!!errors["display_name"]}
                          helperText={
                            errors.display_name &&
                            `${errors.display_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 10 }}
                  direction={"row"}
                >
                  {/* <Grid item xs={12} container justifyContent={"space-around"}> */}
                  <Grid
                    item
                    xs={3.3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "23%",
                      }}
                    >
                      Component Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7.7}>
                    <Controller
                      control={control}
                      name={"component_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          //
                          inputProps={{
                            readOnly: !!component ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={(e) => {
                            onChange(e.target.value.trim());
                          }}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"Enter Salary Component Name"}
                          // label="Enter Salary Component Name"
                          error={!!errors["component_name"]}
                          helperText={
                            errors.component_name &&
                            `${errors.component_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                  {/* </Grid> */}

                  {/* <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            > */}
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                  direction={"row"}
                >
                  {/* <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              > */}

                  <Grid
                    item
                    xs={6}
                    container
                    alignItems={"center"}
                    justifyContent={"space-around"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                        }}
                      >
                        Payslip Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} pl={0.5}>
                      <Controller
                        control={control}
                        name={"display_name"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "101.9%" }}
                            onBlur={onBlur}
                            // disabled={!!department ? edit_flag : !edit_flag}
                            inputProps={{
                              readOnly: !!component ? edit_flag : !edit_flag,
                            }}
                            size="small"
                            onChange={onChange}
                            value={value}
                            // label={"Department Name"}
                            placeholder={"Display Name"}
                            error={!!errors["display_name"]}
                            helperText={
                              errors.display_name &&
                              `${errors.display_name.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container alignItems={"center"}>
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "10%",
                        }}
                      >
                        Component Type
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"component_type"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={!!domain_list ? domain_list : []}
                            keys={"domain_value"}
                            label={"domain_key"}
                            style={{ width: "100.9%" }}
                            placeholder={"Salary Component Type"}
                            // textLabel={"Salary Period"}
                            value={!!value ? value : ""}
                            // readOnly={!!component ? edit_flag : !edit_flag}
                            readOnly={!!id && id == 0 ? false : true}
                            onChange={(value: any) => onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                  direction={"row"}
                >
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent={"space-around"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                        }}
                      >
                        Attendance Dependancy
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"attendace_dependancy"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            style={{ width: "100.9%" }}
                            option={attandance}
                            keys={"value"}
                            label={"key"}
                            placeholder={"Attendance Dependency"}
                            value={value}
                            onChange={(val: any) => onChange(val)}
                            readOnly={!!id && id == 0 ? false : true}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} container alignItems={"center"}>
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "10%",
                        }}
                      >
                        Salary Period
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"salary_priod"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={!!salary_priod ? salary_priod : []}
                            keys={"domain_value"}
                            style={{ width: "100.9%" }}
                            label={"domain_key"}
                            placeholder={"Salary Period"}
                            readOnly={!!component ? edit_flag : !edit_flag}
                            // textLabel={"Salary Period"}
                            value={!!value ? value : ""}
                            onChange={(value: any) => onChange(value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                  direction={"row"}
                >
                  {/* <Grid item xs={6} container justifyContent={"space-around"}> */}
                  <Grid
                    item
                    xs={3.3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "23%",
                      }}
                    >
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={7.7}>
                    <Controller
                      control={control}
                      name={"component_desc"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextareaAutosize
                        style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                          onBlur={onBlur}
                          className={"smaller"}
                          // id="standard-required"
                          onChange={onChange}
                          value={value}
                          disabled={!!component ? edit_flag : !edit_flag}
                          placeholder={"Description"}
                          minRows={2}
                        />
                      )}
                    />
                  </Grid>
                  {/* </Grid> */}
                  {/* <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      direction={"row"}
                      marginLeft={"-3%"}
                      style={{ marginTop: 15, marginBottom: 20 }}
                    > */}
                </Grid>
              </>
            )}

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 5 }}
              direction={"row"}
            >
              {/* <Grid item xs={6} container justifyContent={"space-around"}> */}
              <Grid item xs={1} pl={10}>
                <Checkbox
                  disabled={
                    !!isEditing ? false : !!component ? edit_flag : !edit_flag
                  }
                  checked={checked}
                  onChange={(event) => (
                    setChecked(event.target.checked),
                    !event.target.checked && setValue("default", null)
                  )}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item xs={8} pl={2} marginLeft={"3%"}>
                <Typography style={{ color: "#A2A2A2" }}>
                  Do you want to add default
                </Typography>
              </Grid>
              {/* </Grid> */}
              {/* <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    direction={"row"}
                    marginLeft={"-3%"}
                    style={{ marginTop: 15, marginBottom: 20 }}
                  > */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          {((edit_flag && !component) ||
            (!edit_flag && !!component) ||
            !!isEditing) && (
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                <b>{!!component ? "Update" : "Add"}</b>
              </Button>
            )}
        </Grid>
      </form>
    </Paper>
  );
}

export default AddSalaryComponentView;

interface AddSalaryComponentViewProps {
  Submit?: any;
  // component_list: SalaryStuctureComponent[];
  salary_priod: DomainList[];
  domain_list: DomainList[];
  component?: SalaryStuctureComponent;
  SubmitUpdatedData?: any;
  id?: any;
}
