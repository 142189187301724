import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  EducationDetail1,
  EmployeeAllDetail,
} from "../../../models/employeeModel";
import EmployeeEditEducationalDetailsPopover from "./EmployeeEditEducationalDetailsPopover";
import { useSnackbar } from "notistack";

function EmployeeEducationalDetails({
  education_details,
  Submit,
  employeeDetails,
}: EmployeeEducationalDetailsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [addEduDetail, setaddEduDetail] = useState<any>([
    {
      course_name: null,
      board_name: null,
      // remarks: null,
      subject: null,
      passing_year: null,
      total_marks: null,
      marks_obtained: null,
      grade: null
    },
  ]);
  const addEducationalDetails = () => {
    if (education_details.length === 0) {
      let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
      Temp = [
        ...Temp,
        {
          course_name: null,
          board_name: null,
          // remarks: null,
          subject: null,
          passing_year: null,
          total_marks: null,
          marks_obtained: null,
          grade: null
        },
      ];
      setaddEduDetail(Temp);
    } else if (
      !addEduDetail[addEduDetail.length - 1].course_name ||
      !addEduDetail[addEduDetail.length - 1].board_name ||
      !addEduDetail[addEduDetail.length - 1].passing_year ||
      !addEduDetail[addEduDetail.length - 1].total_marks ||
      !addEduDetail[addEduDetail.length - 1].marks_obtained ||
      !addEduDetail[addEduDetail.length - 1].grade
    ) {
      enqueueSnackbar("Please fill add all detail before adding new row.", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
      Temp = [
        ...Temp,
        {
          course_name: null,
          board_name: null,
          // remarks: null,
          subject: null,
          passing_year: null,
          total_marks: null,
          marks_obtained: null,
          grade: null
        },
      ];
      setaddEduDetail(Temp);
    }
  };
  const OnChangeEducation = (value: string, row: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
    Temp[index][row] = value;
    setaddEduDetail(Temp);
  };
  const DeleteEduAction = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
    let eduDet: any[] = [];
    Temp.forEach((element: any, indexs: any) => {
      if (index != indexs) {
        eduDet.push(JSON.parse(JSON.stringify(element)));
      }
    });
    setaddEduDetail([]);
    setTimeout(() => {
      setaddEduDetail(eduDet);
    }, 200);
  };
  const onSubmit = (data: any) => {
    console.log(data);
    let payload: any = {
      education_details:
        addEduDetail.length == 1
          ? !!addEduDetail[0].course_name
            ? addEduDetail
            : []
          : addEduDetail.length > 1
            ? addEduDetail
            : [],
      employee_id: employeeDetails?.employee_id,
    };
    Submit(payload);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
          //paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  //mb: 10,
                }}
              >
                Educational Qualification
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="end" alignItems="center" padding={0}>
            <Tooltip title="Edit">
              <IconButton onClick={handleClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <EmployeeEditEducationalDetailsPopover
              anchorEl={anchorEl}
              education_details={education_details}
              setAnchorEl={setAnchorEl}
              SubmitPopData={onSubmit}
              addEduDetail={addEduDetail}
              setaddEduDetail={setaddEduDetail}
              OnChangeEducation={OnChangeEducation}
              DeleteEduAction={DeleteEduAction}
              addEducationalDetails={addEducationalDetails}
            />
          </Grid>
          {!!education_details &&
            education_details.map((row) => (
              <Card
                sx={{ minWidth: 275, maxWidth: "90%", mb: 2, mt: -1 }}
                key={row.education_details_id}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Course Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#2E3840">
                        {!!row.course_name ? row.course_name : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Board Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.board_name ? row?.board_name : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Subject
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.subject ? row?.subject : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Passing Year
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.passing_year ? row?.passing_year : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Total Marks
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.total_marks ? row?.total_marks : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Marks Obtained
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.marks_obtained ? row?.marks_obtained : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                      Grade
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.grade ? row?.grade : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default EmployeeEducationalDetails;

interface EmployeeEducationalDetailsProps {
  education_details: EducationDetail1[];
  Submit?: any;
  employeeDetails?: EmployeeAllDetail;
}
