import { ExpandMore } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  CardActions,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { FC } from "react";
import { IEmpDetails } from "../../../interfaces/projectDashboard.interface";
import moment from "moment";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";

const ManagerList: FC<IManagerList> = ({ manager, user }) => {
  console.log("manager : ", manager);
  return (
    <Card
      sx={{
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        my:1,
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <CardHeader
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        avatar={
          <Avatar sx={{ bgcolor: "#0060FF" }} aria-label="recipe">
            {manager.employee_name.length > 0 ? manager.employee_name[0] : "A"}
          </Avatar>
        }
        title={`${manager.employee_name}`}
        subheader={`${moment(manager.start_date).format("DD-MMM-YYYY")}`}
      />

      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" color="text.secondary" py={0.4}>
          #
          {!!user &&
          environment.global_veriable.officialEmployee.includes(
            user.designation
          ) ? (
            <Link to={`/employment/manage-profile/${manager.employee_id}`}>
              {manager.employee_id}
            </Link>
          ) : (
            manager.employee_id
          )}
        </Typography>
        {!!manager.employee_mob_no ? (
          <Typography variant="body2" color="text.secondary" py={0.4}>
            Phone Number : {manager.employee_mob_no}
          </Typography>
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};
interface IManagerList {
  manager: IEmpDetails;
  user?: userDetails;
}
export default ManagerList;
