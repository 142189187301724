import React from "react";
import { StoreState } from "../../../models/reduxModels";
import { GetProjectPaymentListAction } from "../../../stores/actions/customerAction";
import { connect } from "react-redux";
import PaymentDetailsByInvoiceView from "./PaymentDetailsByInvoiceView";
import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import { useParams } from "react-router-dom";
import { InvoiceListResponse } from "../../../models/invoiceModel";

function PaymentDetailsByInvoice({
  GetProjectPaymentListAction,
  project_payment_list,
  project_list,
  invoice_list_details,
}: PaymentDetailsByInvoiceProps) {
  const { id, id2 } = useParams();
  React.useEffect(() => {
    if(!!id && !!id2){      
      GetProjectPaymentListAction({
        project_id: id,
        project_payment_invoice_id: id2
      });
    }
  }, [id2,id]);
  console.log(project_payment_list);
  
  return (
    <PaymentDetailsByInvoiceView
      project_payment_list={project_payment_list}
      project_list={project_list}
      id={!!id ? id : 0}
      id2={!!id2 ? id2 : 0}
      invoice_list_details={invoice_list_details}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    project_payment_list: state.customer.project_payment_list,
    project_list: state.customer.project_list,
    invoice_list_details: state.invoice.invoice_list_details,
  };
};
const mapDispatchToProps = {
  GetProjectPaymentListAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsByInvoice);

interface PaymentDetailsByInvoiceProps {
  GetProjectPaymentListAction: any;
  project_payment_list: ProjectPaymentList[];
  project_list: ProjectList[];
  invoice_list_details?: InvoiceListResponse;
}
