import React from "react";
import { connect } from "react-redux";
import { ProjectList } from "../../../models/customerModel";
import {
  CandidateBulkUpload,
  IBulkUploadStatus,
} from "../../../models/employeeModel";
import {
  OrganizationALlList,
  EmployeeTypeList,
  AllDepartmentList,
  EmployeeTimingList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { AttendanceDetailPayloadSuccessAction } from "../../../stores/actions/attendanceAction";
import {
  GetAllProjectAction,
  GetAllCustomerAction,
} from "../../../stores/actions/customerAction";
import {
  GetAllCandidateBulkUploadListAction,
  getBulkUploadStatusAction,
  getUpdatedBulkStatusAction,
} from "../../../stores/actions/employeeAction";
import {
  GetAllOrganizationAction,
  GetAllEmployeeTypeAction,
  GetAllDepartmentAction,
  GetAllEmployeeTimingAction,
} from "../../../stores/actions/organizationActions";
import BulkUploadView from "./BulkUploadView";
const BulkUpload = ({
  employee_type_list,
  organization_list,
  project_list,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  GetAllProjectAction,
  user,
  accessToken,
  candidateBulkUploadList,
  GetAllCandidateBulkUploadListAction,
  getBulkUploadStatusAction,
  getUpdatedBulkStatusAction,
  department_list,
  employee_timing_list,
  GetAllDepartmentAction,
  GetAllEmployeeTimingAction,
}: BulkUploadProps) => {
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllProjectAction({
      organization_id: null,
      customer_id: null,
    });
    GetAllCustomerAction({
      organization_id: null,
    });
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllCandidateBulkUploadListAction();
    GetAllDepartmentAction({
      organization_id: null,
    });
    GetAllEmployeeTimingAction({
      organization_id: null,
    });
  }, []);

  console.log("candidateBulkUploadList1 : ", candidateBulkUploadList);

  return (
    <BulkUploadView
      organization_list={organization_list}
      project_list={project_list}
      user={user}
      employee_type_list={employee_type_list}
      accessToken={accessToken}
      candidateBulkUploadList={candidateBulkUploadList}
      getBulkUploadStatusAction={getBulkUploadStatusAction}
      getUpdatedBulkStatus={getUpdatedBulkStatusAction}
      GetAllCandidateBulkUploadListAction={GetAllCandidateBulkUploadListAction}
      department_list={department_list}
      employee_timing_list={employee_timing_list}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    project_list: state.customer.project_list,
    customer_list: state.customer.customer_list,
    employee_type_list: state.organization.employee_type_list,
    accessToken: state.user.userDetails?.accessToken,
    user: state.user.userDetails,
    candidateBulkUploadList: state.employee.candidateBulkUploadList,
    department_list: state.organization.department_list,
    employee_timing_list: state.organization.employee_timing_list,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  GetAllCandidateBulkUploadListAction,
  getBulkUploadStatusAction,
  getUpdatedBulkStatusAction,
  GetAllDepartmentAction,
  GetAllEmployeeTimingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);

interface BulkUploadProps {
  GetAllOrganizationAction?: any;
  GetAllProjectAction?: any;
  GetAllEmployeeTypeAction?: any;
  organization_list: OrganizationALlList[];
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
  accessToken?: string;
  GetAllCandidateBulkUploadListAction?: any;
  candidateBulkUploadList: IBulkUploadStatus[];
  getBulkUploadStatusAction?: any;
  getUpdatedBulkStatusAction?: any;
  department_list: AllDepartmentList[];
  employee_timing_list: EmployeeTimingList[];
  GetAllDepartmentAction?: any;
  GetAllEmployeeTimingAction?: any;
}
