import { FC } from "react";
import { connect } from "react-redux";
import {
  IAllLeaveManagement,
  IDesignationWiseEmp,
  IHolidayList,
  ILeaveAmountData,
  ILeaveDetailsData,
  ILeaveType,
  IdesignationWiseLeave,
  ILeaveCalendar,
  ILeaveTableData,
  IAddress,
  INaturalHoliday,
} from "../../../models/leaveModel";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllLeaveTypeAction,
  applyLeaveAction,
  calculateLeaveDaysAction,
  clearDesignationWiseLeave,
  getAllHolidaysAction,
  getAllLeaveManagementData,
  getDesignationWiseLeaveAction,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  getLeaveDetailsAction,
  selectOrganization,
  LeaveCalendarDateAction,
  resetLeaveDaysCalculationState,
  cancelLeaveAction,
  editLeaveAction,
  getStateByAddressIdAction,
  getHolidayByEmployeeAction,
  naturalLeaveDaysActionByEmployee,
} from "../../../stores/actions/LeaveAction";
import EmployeeLeaveApplicationMain from "./EmployeeLeaveApplicationMain";
import { userDetails } from "../../../models/userModels";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { getAllLeaveManagementService } from "../../../services/leaveService";
import {
  GetAllOrganizationAction,
  GetAllDesignationAction,
} from "../../../stores/actions/organizationActions";
import { Box } from "@mui/material";
const LeaveApplication: FC<IEmployeeLeavemanagement> = ({
  user,
  holiday_list,
  getAllHolidaysAction,
  GetAllDesignationAction,
  organization_list,
  designation_list,
  designationWiseEmp,
  selectOrganization,
  selected_organization_leave,
  allLeaveManagementDetails,
  getAllLeaveManagementData,
  clearDesignationWiseLeave,
  getDesignationWiseLeaveAction,
  GetAllLeaveTypeAction,
  getEmployeeByDesignationAction,
  designationWiseLeave,
  getLeaveAmountAction,
  leaveAmountData,
  calculateLeaveDaysAction,
  leave_types,
  leaveDaysCount,
  getLeaveDetailsAction,
  applyLeaveAction,
  leaveDetailsData,
  leave_calendar,
  LeaveCalendarDateAction,
  resetLeaveDaysCalculationState,
  editLeaveAction,
  cancelLeaveAction,
  getStateByAddressIdAction,
  address,
  employee_holiday_list,
  getHolidayByEmployeeAction,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}): JSX.Element => {
  console.log("leaveDaysCount : ", leaveDaysCount);
  console.log("leavemanagement hit");
  return (
    <Box className="main_page">
      <EmployeeLeaveApplicationMain
        holiday_list={holiday_list}
        getAllHolidaysAction={getAllHolidaysAction}
        GetAllDesignationAction={GetAllDesignationAction}
        organization_list={organization_list}
        user={user}
        selectOrganization={selectOrganization}
        designation_list={designation_list}
        selected_organization_leave={selected_organization_leave}
        allLeaveManagementDetails={allLeaveManagementDetails}
        getAllLeaveManagementData={getAllLeaveManagementData}
        getDesignationWiseLeaveAction={getDesignationWiseLeaveAction}
        designationWiseLeave={designationWiseLeave}
        clearDesignationWiseLeave={clearDesignationWiseLeave}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        designationWiseEmp={designationWiseEmp}
        getEmployeeByDesignationAction={getEmployeeByDesignationAction}
        getLeaveAmountAction={getLeaveAmountAction}
        leaveAmountData={leaveAmountData}
        leave_types={leave_types}
        calculateLeaveDaysAction={calculateLeaveDaysAction}
        leaveDaysCount={leaveDaysCount}
        getLeaveDetailsAction={getLeaveDetailsAction}
        applyLeaveAction={applyLeaveAction}
        leaveDetailsData={leaveDetailsData}
        leave_calendar={leave_calendar}
        LeaveCalendarDateAction={LeaveCalendarDateAction}
        resetLeaveDaysCalculationState={resetLeaveDaysCalculationState}
        editLeaveAction={editLeaveAction}
        cancelLeaveAction={cancelLeaveAction}
        getStateByAddressIdAction={getStateByAddressIdAction}
        address={address}
        getHolidayByEmployeeAction={getHolidayByEmployeeAction}
        employee_holiday_list={employee_holiday_list}
        natural_holiday_list={natural_holiday_list}
        naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
      />
    </Box>
  );
};

const mapDispatchToProps = {
  getAllHolidaysAction,
  GetAllOrganizationAction,
  selectOrganization,
  GetAllDesignationAction,
  getDesignationWiseLeaveAction,
  getAllLeaveManagementData,
  clearDesignationWiseLeave,
  GetAllLeaveTypeAction,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  calculateLeaveDaysAction,
  applyLeaveAction,
  getLeaveDetailsAction,
  LeaveCalendarDateAction,
  editLeaveAction,
  cancelLeaveAction,
  getStateByAddressIdAction,
  getHolidayByEmployeeAction,
  naturalLeaveDaysActionByEmployee,
};

const mapStateToProps = (state: StoreState) => {
  return {
    holiday_list: state.leave.holiday_list,
    selected_organization_leave: state.leave.selected_organization,
    user: state.user.userDetails,
    organization_list: state.organization.organization_filter_list,
    designation_list: state.organization.designation_list,
    allLeaveManagementDetails: state.leave.allLeaveManagement,
    designationWiseLeave: state.leave.designationWiseLeave,
    leave_types: state.leave.leave_types,
    designationWiseEmp: state.leave.designationWiseEmp,
    leaveAmountData: state.leave.leave_amount_data,
    leaveDaysCount: state.leave.calculated_leave_days,
    leaveDetailsData: state.leave.leave_details_data,
    leave_calendar: state.leave.leave_calendar,
    address: state.leave.address,
    employee_holiday_list: state.leave.employee_holiday_list,
    natural_holiday_list: state.leave.natural_holidays,
  };
};
interface IEmployeeLeavemanagement {
  holiday_list: IHolidayList[];
  getAllHolidaysAction?: any;
  user?: userDetails;
  organization_list: OrganizationALlList[];
  selectOrganization?: any;
  GetAllOrganizationAction?: any;
  selected_organization_leave?: number;
  GetAllDesignationAction?: any;
  designation_list: AllDesignationList[];
  allLeaveManagementDetails: IAllLeaveManagement[];
  getAllLeaveManagementData?: any;
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  clearDesignationWiseLeave?: any;
  GetAllLeaveTypeAction?: any;
  getEmployeeByDesignationAction?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  getLeaveDetailsAction?: any;
  applyLeaveAction?: any;
  leaveDetailsData: ILeaveDetailsData[];
  leave_calendar: ILeaveCalendar[];
  LeaveCalendarDateAction?: any;
  resetLeaveDaysCalculationState?: any;
  editLeaveAction?: any;
  cancelLeaveAction?: any;
  getStateByAddressIdAction?: any;
  address?: IAddress;
  getHolidayByEmployeeAction?: any;
  employee_holiday_list: IHolidayList[];
  naturalLeaveDaysActionByEmployee?: any;
  natural_holiday_list: INaturalHoliday[];
}
export default connect(mapStateToProps, mapDispatchToProps)(LeaveApplication);
