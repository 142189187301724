/* eslint-disable react-hooks/exhaustive-deps */
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Grid,
  Typography,
  IconButton,
  Fab,
  Tooltip,
  TableCell,
  styled,
  tableCellClasses,
  TextField,
  Divider,
  Popover,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { DatePicker } from "@mui/x-date-pickers";
import { watch } from "fs";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { baseUrl, environment } from "../../../environment";
import { useNavigate } from "react-router-dom";
import { ProjectList } from "../../../models/customerModel";
import {
  OrganizationALlList,
  EmployeeTypeList,
  AllDepartmentList,
  EmployeeTimingList,
} from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import BulkUploadPopUp from "./BulkUploadPopUp";
import { CandidateBulkUpload } from "../../../models/employeeModel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IBulkUploadStatus } from "./../../../models/employeeModel";
import { useSnackbar } from "notistack";
import Axios from "axios";
import Label from "./Label";
const FileDownload = require("js-file-download");

const BulkUploadView = ({
  employee_type_list,
  organization_list,
  project_list,
  user,
  accessToken,
  candidateBulkUploadList,
  getBulkUploadStatusAction,
  getUpdatedBulkStatus,
  GetAllCandidateBulkUploadListAction,
  department_list,
  employee_timing_list,
}: BulkUploadViewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns: any[] = [
    "Bulk ID",
    "Project",
    // "Created By",
    "Created At",
    "Status",
    "Action",
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  useEffect(() => {
    if (!!candidateBulkUploadList) {
      cancelSearch();
    }
  }, [candidateBulkUploadList]);

  const getBulkUploadReport = (bulkId: number) => {
    Axios({
      url: baseUrl + environment.urls.getbulkuploadreport,
      method: "POST",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        bulk_id: bulkId,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          await FileDownload(response.data, "bulk-upload-report.xlsx");
        } else {
          enqueueSnackbar(
            !!response?.data?.message
              ? response?.data?.message
              : "Something went wrong...",
            {
              variant: "warning",
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error?.data?.message
            ? error?.data?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };

  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<IBulkUploadStatus[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const requestSearch = (searchedVal: string) => {
    console.log("request search hit");
    const filteredRows = !!candidateBulkUploadList
      ? candidateBulkUploadList.filter((row: IBulkUploadStatus) => {
          return (
            row?.upload_status
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.["project.project_name"]
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.created_by?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];

    setRows(filteredRows);
  };
  console.log("bulk upload hit");
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const BulkUploadReport = (data: number) => {
    getBulkUploadReport(data);
  };
  useEffect(() => {
    if (getBulkUploadStatusAction) {
      getBulkUploadStatusAction();
    }
  }, []);
  let timer: NodeJS.Timer;
  useEffect(() => {
    timer = setInterval(() => {
      if (getUpdatedBulkStatus) {
        const uploadingIds = rows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .filter((self) => self.upload_status === "UPLOADING")
          .map((rows) => rows.bulk_id);
        uploadingIds.length > 0 && getUpdatedBulkStatus(uploadingIds);
      }
    }, 1000 * 10);
    return () => {
      clearInterval(timer);
    };
  }, [rows, page, rowsPerPage]);
  return (
    <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            Candidate Bulk Upload
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        pt={2}
        pb={2}
        pl={3}
      >
        <Grid item xs={3}>
          <Button
            onClick={handleClick}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) =>
              //   requestSearch(e.target.value),
              setSearched(e.target.value)
            }
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"md"}
      >
        <DialogTitle
          style={{
            backgroundColor: "#00AAEE",
            textAlign: "center",
            color: "#fff",
          }}
        >
          Upload Bulk Data
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            //   minWidth={1000}
            //   minHeight={150}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            p={2}
          >
            <BulkUploadPopUp
              organization_list={organization_list}
              project_list={project_list}
              user={user}
              employee_type_list={employee_type_list}
              accessToken={accessToken}
              setOpen={setOpen}
              GetAllCandidateBulkUploadListAction={
                GetAllCandidateBulkUploadListAction
              }
              department_list={department_list}
              employee_timing_list={employee_timing_list}
            />
          </Grid>
        </DialogContent>
      </Dialog>
      <TableContainer sx={{ maxHeight: 480 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.bulk_id}
                  >
                    <StyledTableCell align="left" key={columns[0]}>
                      {/* {row.reports} */}
                      {row.bulk_id ?? "N/A"}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[1]}>
                      {/* {row.reports} */}
                      {row["project.project_name"] ?? "N/A"}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" key={columns[2]}>
                      {row.created_by}
                    </StyledTableCell> */}
                    <StyledTableCell align="left" key={columns[2]}>
                      {row.created_on
                        ? moment(row.created_on)
                            .format("DD/MM/YYYY ")
                            .toUpperCase()
                        : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[3]}>
                      <Label
                        row={row}
                        candidateBulkUploadList={candidateBulkUploadList}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[4]}>
                      {row.upload_status == "UPLOADED" ? (
                        <Button
                          onClick={() => BulkUploadReport(row.bulk_id)}
                          variant="outlined"
                          sx={{ color: "#0060ff" }}
                          size="small"
                          startIcon={<CloudDownloadIcon />}
                        >
                          <b>Download Report</b>
                        </Button>
                      ) : (
                        ""
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default BulkUploadView;

interface BulkUploadViewProps {
  organization_list: OrganizationALlList[];
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
  accessToken?: string;
  candidateBulkUploadList: IBulkUploadStatus[];
  getBulkUploadStatusAction?: any;
  getUpdatedBulkStatus?: any;
  GetAllCandidateBulkUploadListAction?: any;
  department_list: AllDepartmentList[];
  employee_timing_list: EmployeeTimingList[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]:  {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "1px 2px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },  
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
