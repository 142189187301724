import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { getValue } from "@testing-library/user-event/dist/utils";
import { log } from "console";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import {
  CustomerList,
  ProjectLevels,
  ProjectList,
  StateList,
  Subdivision,
  ViewProject,
} from "../../../models/customerModel";
import { ViewEmployee } from "../../../models/employeeModel";
import {
  AllPostList,
  DomainList,
  SalaryStructureEditDetails,
} from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";
import ImageUploader from "./ImageUploader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdditonalFieldUploadSection from "./AdditonalFieldUploadSection";
import moment from "moment";

const OrganizationalDetails = ({
  control,
  errors,
  watch,
  salary_structure_list,
  department_list,
  employee_type_list,
  employee_timing_list,
  employee_detail,
  designation_list,
  applicabledeg,
  ReportingManagerList,
  organization_list,
  OrganizationSelected,
  customer_list,
  OnChangeCustomer,
  project_list,
  OnprojectSelect,
  project_detail,
  post_list,
  SlaryStructureChange,
  salary_structure,
  attendence_salary_type,
  edit_candidate,
  user,
  project_levels,
  setValue,
  edit_flag,
  getValues,
  id2,
  state_list,
  documents,
  fileHandleEvent,
  SetDocument,
  downloadDoc,
  AddlfileHandleEvent,
  downloadImage,
}: OrganizationalDetailsProps) => {
  const [level2, SetLevel2] = useState<any[]>([]);
  const [level3, SetLevel3] = useState<any[]>([]);
  const [level4, SetLevel4] = useState<any[]>([]);
  const [level5, SetLevel5] = useState<any[]>([]);
  // useEffect(() => {
  //   if (!!user) {
  //     if (
  //       !environment.global_veriable.superAdminLevel.includes(user.designation)
  //     ) {
  //       OrganizationSelected(user.employee_detail.organization_id);
  //       setValue("organization_id", user.employee_detail.organization_id);
  //       setValue("shift_id", user.employee_detail.shift_id);
  //       setValue("department_id", user.employee_detail.department_id);
  //       setValue(
  //         "employee_type_id",
  //         environment.global_veriable.emp_type_for_project_specific
  //       );
  //       if (user.project_detail.length == 1) {
  //         setValue("customer_id", user.project_detail[0].customer_id);
  //         setValue("project_id", user.project_detail[0].project_id);
  //         OnprojectSelect(user.project_detail[0].project_id);
  //         setValue("level1_id", user.project_detail[0].level1_id);
  //         setValue("level2_id", user.project_detail[0].level2_id);
  //         setValue("level3_id", user.project_detail[0].level3_id);
  //         setValue("level4_id", user.project_detail[0].level4_id);
  //         setValue("level5_id", user.project_detail[0].level5_id);
  //       }
  //     }
  //   }
  // }, [user]);
  const ChangeLevelOne = (data: number) => {
    let level2 = project_detail?.levels.find(
      (m) => m.level1_id == +data
    )?.level_two;
    if (!!level2 && !Array.isArray(level2)) {
      SetLevel2([level2]);
    } else if (!!level2) {
      SetLevel2(level2);
    }
    SetLevel3([]);
    SetLevel4([]);
    SetLevel5([]);
  };
  const SelectLevel2 = (data: number) => {
    let level3 = level2.find((m) => m.level2_id == +data)?.level_three;
    if (!!level3 && !Array.isArray(level3)) {
      SetLevel3([level3]);
    } else if (!!level3) {
      SetLevel3(level3);
    }
    SetLevel4([]);
    SetLevel5([]);
  };
  const Level3Select = (data: number) => {
    let level4 = level3.find((m) => m.level3_id == +data)?.level_four;
    if (!!level4 && !Array.isArray(level4)) {
      SetLevel4([level4]);
    } else if (!!level4) {
      SetLevel4(level4);
    }
    SetLevel5([]);
  };
  const LevelFourChange = (data: number) => {
    let level5 = level4.find((m) => m.level3_id == +data)?.level_five;
    if (!!level5 && !Array.isArray(level5)) {
      SetLevel5([level5]);
    } else if (!!level5) {
      SetLevel5(level5);
    }
  };
  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  console.log("employee_detail12 : ", employee_detail);
  useEffect(() => {
    const PatchValue = async () => {
      if (
        !!employee_detail &&
        employee_detail.projects.length > 0 &&
        !!project_detail
      ) {
        setValue("customer_id", employee_detail.projects[0].customer_id);
        setValue("project_id", employee_detail.projects[0].project_id);
        setValue("level1_id", employee_detail.projects[0].level1_id);
        setValue("employee_type_id", employee_detail.employee_type_id);
        // ChangeLevelOne(employee_detail.projects[0].level1_id);
        // await sleep(2400);
        setValue("level2_id", employee_detail.projects[0].level2_id);
        // SelectLevel2(employee_detail.projects[0].level2_id);
        // await sleep(3400);
        setValue("level3_id", employee_detail.projects[0].level3_id);
        // Level3Select(employee_detail.projects[0].level3_id);

        setValue("level4_id", employee_detail.projects[0].level4_id);
        // LevelFourChange(employee_detail.projects[0].level4_id);
        setValue(
          "created_at",
          moment.utc(employee_detail.created_at).format("yyyy-MM-DD")
        );
        setValue("level5_id", employee_detail.projects[0].level5_id);
        setValue("post_id", employee_detail.projects[0].post_id);
        if (employee_detail.additional_field.length > 0) {
          employee_detail.additional_field.forEach((m: any) => {
            setValue(Object.keys(m)[0], m[Object.keys(m)[0]]);
            setValue(
              Object.keys(m)[0] + "_file",
              m[Object.keys(m)[0] + "_file"]
            );
          });
        }
      }
    };
    PatchValue();
  }, [employee_detail, project_detail]);
  console.log("employee project : ", employee_detail?.projects[0]);
  console.log("project details : ", project_detail);
  // useeffect for getting level 2 data
  useEffect(() => {
    if (
      !!employee_detail &&
      employee_detail.projects.length > 0 &&
      !!project_detail
    ) {
      ChangeLevelOne(employee_detail.projects[0].level1_id);
    }
  }, [employee_detail, project_detail]);
  // useeffect for getting level 3 data

  useEffect(() => {
    if (
      !!employee_detail &&
      employee_detail.projects.length > 0 &&
      !!project_detail &&
      !!level2 &&
      level2.length > 0
    ) {
      SelectLevel2(employee_detail.projects[0].level2_id);
    }
  }, [employee_detail, project_detail, level2]);
  console.log("level 3 data : ", level3);
  console.log("level3 id : ", getValues("level3_id"));
  // useeffect for level4 data

  useEffect(() => {
    if (
      !!employee_detail &&
      employee_detail.projects.length > 0 &&
      !!project_detail &&
      !!level3 &&
      level3.length > 0
    ) {
      Level3Select(employee_detail.projects[0].level3_id);
    }
  }, [employee_detail, project_detail, level3]);

  // useeffect for level5 data
  useEffect(() => {
    if (
      !!employee_detail &&
      employee_detail.projects.length > 0 &&
      !!project_detail &&
      !!level4 &&
      level4.length > 0
    ) {
      LevelFourChange(employee_detail.projects[0].level4_id);
    }
  }, [employee_detail, project_detail, level4]);
  const imageChange = (type: string) => {
    let docs = JSON.parse(JSON.stringify(documents));
    docs = docs.filter((m: any) => m.document_type != type);
    SetDocument(docs);
  };
  const [actAdditionalUpload, setactAdditionalUpload] = useState("");

  console.log("gffd", project_detail);

  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "7%",
                // marginBottom: 20,
              }}
            >
              Organizational Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "15%",
                  // textAlign: "center"
                }}
              >
                Organization
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <Controller
                control={control}
                name={"organization_id"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    readOnly={true}
                    option={organization_list}
                    keys={"organization_id"}
                    label={"organization_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => (
                      onChange(value), OrganizationSelected(value)
                    )}
                    placeholder={"Organization"}
                    value={!!value ? value : ""}
                    errors={errors.organization_id}
                  />
                )}
              />
            </Grid>
          </Grid>
          {!!watch("organization_id") && (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "15%",
                    // textAlign: "center"
                  }}
                >
                  Employee Timing
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"shift_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      readOnly={
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate
                      }
                      option={employee_timing_list.filter(
                        (m) => m.org_id == +watch("organization_id")
                      )}
                      keys={"shift_id"}
                      label={"shift_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Employee Timing"}
                      value={!!value ? value : ""}
                      errors={errors.shift_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {!!watch("organization_id") && (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "15%",
                    // textAlign: "center"
                  }}
                >
                  Department
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"department_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      readOnly={
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate
                      }
                      option={department_list.filter(
                        (m) => m.organization_id == +watch("organization_id")
                      )}
                      keys={"department_id"}
                      label={"department_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Department"}
                      value={!!value ? value : ""}
                      errors={errors.department_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {!!watch("organization_id") && (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "15%",
                    // textAlign: "center"
                  }}
                >
                  Employee Type
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"employee_type_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      readOnly={true}
                      option={employee_type_list.filter(
                        (m) => m.org_id == +watch("organization_id")
                      )}
                      keys={"employee_type_id"}
                      label={"employee_type_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Search Employee Type"}
                      value={!!value ? value : ""}
                      errors={errors.employee_type_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {environment.global_veriable.ho_employees_type_id.includes(
            +watch("employee_type_id")
          ) &&
            !!watch("organization_id") && (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "15%",
                      // textAlign: "center"
                    }}
                  >
                    Base Location
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.5}>
                  {/* <Controller
                control={control}
                name={"base_location"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "100.9%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Base Location"}
                    error={!!errors["base_location"]}
                    helperText={
                      errors.base_location &&
                      `${errors.base_location.message}* This field is Required`
                    }
                  />
                )}
              /> */}
                  <Controller
                    control={control}
                    name={"base_location"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        readOnly={
                          !!id2 && +id2 == 1
                            ? false
                            : !!user &&
                              environment.global_veriable.superAdminLevel.includes(
                                user.designation
                              )
                            ? edit_flag
                            : !edit_candidate
                        }
                        option={state_list}
                        style={{ width: "100.9%" }}
                        keys={"state_name"}
                        label={"state_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Base Location"}
                        value={!!value ? value : ""}
                        errors={!!errors["base_location"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          {!!watch("organization_id") &&
            !!watch("employee_type_id") &&
            !environment.global_veriable.ho_employees_type_id.includes(
              +watch("employee_type_id")
            ) && (
              <>
                <Grid item xs={10} marginTop={1} marginBottom={4}>
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      marginLeft: "8%",
                      marginBottom: 20,
                      color: "#676974",
                    }}
                  >
                    Associate Project
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        // textAlign: "center"
                      }}
                    >
                      Customer Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"customer_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          readOnly={true}
                          option={customer_list}
                          keys={"customer_id"}
                          label={"customer_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), OnChangeCustomer(+value)
                          )}
                          placeholder={"Customer Name"}
                          value={!!value ? value : ""}
                          errors={errors.customer_id}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {!!watch("customer_id") && (
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "15%",
                          // textAlign: "center"
                        }}
                      >
                        Project Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"project_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            readOnly={true}
                            option={project_list}
                            keys={"project_id"}
                            label={"project_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value), OnprojectSelect(+value)
                            )}
                            placeholder={"Project Name"}
                            value={!!value ? value : ""}
                            errors={errors.project_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        // textAlign: "center"
                      }}
                    >
                      Expected Joining Date
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5} style={{ marginLeft: 5 }}>
                    <Controller
                      control={control}
                      name={"created_at"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"date"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"Expected Joining Date"}
                          error={!!errors["created_at"]}
                          helperText={
                            errors.created_at &&
                            `${errors.created_at.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {!!watch("project_id") &&
                  !!watch("employee_type_id") &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) &&
                  !!user &&
                  (!!environment.global_veriable.superAdminLevel.includes(
                    user.designation
                  ) ||
                    environment.global_veriable.managerLevelDeg.includes(
                      user.designation
                    )) &&
                  !!project_detail &&
                  !!project_levels?.data.find(
                    (m) => m.level_key_id == project_detail.level_key_id
                  ) && (
                    <>
                      {project_levels?.data.find(
                        (m) => m.level_key_id == project_detail.level_key_id
                      )?.level1_key && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 15, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "15%",
                                // textAlign: "center"
                              }}
                            >
                              {
                                project_levels?.data.find(
                                  (m) =>
                                    m.level_key_id ==
                                    project_detail.level_key_id
                                )?.level1_key_name
                              }
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                            <Controller
                              control={control}
                              name={"level1_id"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={project_detail.levels}
                                  keys={"level1_id"}
                                  readOnly={
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate
                                  }
                                  label={"level1_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    onChange(value), ChangeLevelOne(+value)
                                  )}
                                  placeholder={
                                    project_levels?.data.find(
                                      (m) =>
                                        m.level_key_id ==
                                        project_detail.level_key_id
                                    )?.level1_key_name
                                  }
                                  value={!!value ? value : ""}
                                  errors={errors.level1_id}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {project_levels?.data.find(
                        (m) => m.level_key_id == project_detail.level_key_id
                      )?.level2_key && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 15, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "15%",
                                // textAlign: "center"
                              }}
                            >
                              {
                                project_levels?.data.find(
                                  (s) =>
                                    s.level_key_id ==
                                    project_detail.level_key_id
                                )?.level2_key_name
                              }
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                            <Controller
                              control={control}
                              name={"level2_id"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={level2}
                                  readOnly={
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate
                                  }
                                  keys={"level2_id"}
                                  label={"level2_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    onChange(value), SelectLevel2(+value)
                                  )}
                                  placeholder={
                                    project_levels?.data.find(
                                      (s) =>
                                        s.level_key_id ==
                                        project_detail.level_key_id
                                    )?.level2_key_name
                                  }
                                  value={!!value ? value : ""}
                                  errors={errors.level2_id}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {project_levels?.data.find(
                        (m) => m.level_key_id == project_detail.level_key_id
                      )?.level3_key && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 15, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "15%",
                                // textAlign: "center"
                              }}
                            >
                              {
                                project_levels?.data.find(
                                  (m) =>
                                    m.level_key_id ==
                                    project_detail.level_key_id
                                )?.level3_key_name
                              }
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                            <Controller
                              control={control}
                              name={"level3_id"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  readOnly={
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate
                                  }
                                  option={level3}
                                  keys={"level3_id"}
                                  label={"level3_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    onChange(value), Level3Select(+value)
                                  )}
                                  placeholder={
                                    project_levels?.data.find(
                                      (m) =>
                                        m.level_key_id ==
                                        project_detail.level_key_id
                                    )?.level3_key_name
                                  }
                                  value={!!value ? value : ""}
                                  errors={errors.level3_id}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {project_levels?.data.find(
                        (m) => m.level_key_id == project_detail.level_key_id
                      )?.level4_key && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 15, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "15%",
                                // textAlign: "center"
                              }}
                            >
                              {
                                project_levels?.data.find(
                                  (m) =>
                                    m.level_key_id ==
                                    project_detail.level_key_id
                                )?.level4_key_name
                              }
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                            <Controller
                              control={control}
                              name={"level4_id"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={level4}
                                  readOnly={
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate
                                  }
                                  keys={"level4_id"}
                                  label={"level4_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => (
                                    onChange(value), LevelFourChange(value)
                                  )}
                                  placeholder={
                                    project_levels?.data.find(
                                      (m) =>
                                        m.level_key_id ==
                                        project_detail.level_key_id
                                    )?.level4_key_name
                                  }
                                  value={!!value ? value : ""}
                                  errors={errors.level4_id}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {project_levels?.data.find(
                        (m) => m.level_key_id == project_detail.level_key_id
                      )?.level5_key && (
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 15, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "15%",
                                // textAlign: "center"
                              }}
                            >
                              {
                                project_levels?.data.find(
                                  (m) =>
                                    m.level_key_id ==
                                    project_detail.level_key_id
                                )?.level5_key_name
                              }
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                            <Controller
                              control={control}
                              name={"level5_id"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={level5}
                                  keys={"level5_id"}
                                  readOnly={!edit_candidate}
                                  label={"level5_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={
                                    project_levels?.data.find(
                                      (m) =>
                                        m.level_key_id ==
                                        project_detail.level_key_id
                                    )?.level5_key_name
                                  }
                                  value={!!value ? value : ""}
                                  errors={errors.level5_id}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                {!!watch("project_id") &&
                  !!watch("employee_type_id") &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) && (
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 15, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "15%",
                            // textAlign: "center"
                          }}
                        >
                          Post
                        </Typography>
                      </Grid>
                      <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"post_id"}
                          defaultValue={""}
                          //   rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              readOnly={
                                !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                                  ? edit_flag
                                  : !edit_candidate
                              }
                              option={post_list.filter(
                                (m) =>
                                  !!project_detail?.applicable_post &&
                                  !!project_detail?.applicable_post.includes(
                                    m.post_id
                                  )
                              )}
                              keys={"post_id"}
                              label={"post_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Post"}
                              value={!!value ? value : ""}
                              errors={errors.post_id}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
              </>
            )}
          {!!watch("organization_id") &&
            !!watch("employee_type_id") &&
            !environment.global_veriable.ho_employees_type_id.includes(
              +watch("employee_type_id")
            ) && <></>}
          {!!watch("organization_id") && (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "15%",
                    // textAlign: "center"
                  }}
                >
                  Designation
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"designation_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      readOnly={
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate
                      }
                      option={designation_list.filter((m) =>
                        m.org_id == +watch("organization_id") &&
                        !!watch("employee_type_id") &&
                        environment.global_veriable.ho_employees_type_id.includes(
                          +watch("employee_type_id")
                        )
                          ? m
                          : applicabledeg.length <= 0 ||
                            !!applicabledeg.includes(m.designation_id)
                      )}
                      keys={"designation_id"}
                      label={"designation_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Designation"}
                      value={!!value ? value : ""}
                      errors={errors.designation_id}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          {!!watch("project_id") &&
            !!project_detail &&
            project_detail.project_fields &&
            project_detail.project_fields.length > 0 && (
              <>
                <Grid item xs={10} marginTop={1} marginBottom={4}>
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      marginLeft: "8%",
                      //marginLeft: !noMargin ? "10%" : "8%",
                      marginBottom: 10,
                      color: "#676974",
                    }}
                  >
                    Additional Detail
                  </Typography>
                </Grid>

                {project_detail.project_fields.map((item, index) => (
                  <AdditonalFieldUploadSection
                    key={index}
                    item={item}
                    AddlfileHandleEvent={AddlfileHandleEvent}
                    control={control}
                    downloadImage={downloadImage}
                    errors={errors}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                    edit_candidate={edit_candidate}
                    edit_flag={edit_flag}
                    id2={id2}
                    user={user}
                  />
                ))}
              </>
            )}
          {!!watch("organization_id") &&
            !!employee_detail?.projects &&
            employee_detail.projects.length > 0 &&
            !!employee_detail.projects[0]?.employee_supervisor_id && (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "15%",
                      // textAlign: "center"
                    }}
                  >
                    Reporting Manager
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"reporting_manager"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        readOnly={
                          !!id2 && +id2 == 1
                            ? false
                            : !!user &&
                              environment.global_veriable.superAdminLevel.includes(
                                user.designation
                              )
                            ? edit_flag
                            : !edit_candidate
                        }
                        option={ReportingManagerList}
                        keys={"employee_id"}
                        label={"employee_id"}
                        extras={"employee_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Reporting Manager"}
                        value={!!value ? value : ""}
                        errors={errors.reporting_manager}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          {!!employee_detail?.salary_detail &&
            employee_detail.salary_detail.length > 0 && (
              <>
                <Divider
                  style={{
                    borderColor: "#DDDDDF",
                    opacity: 0.8,
                    width: "90%",
                    marginLeft: "5%",
                  }}
                ></Divider>
                <Grid item xs={10} marginTop={1} marginBottom={4}>
                  <Typography
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      marginLeft: "8%",
                      marginBottom: 20,
                    }}
                  >
                    Salary Details
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        // textAlign: "center"
                      }}
                    >
                      Salary Structure Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.5}>
                    <Controller
                      control={control}
                      name={"salary_structure_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          readOnly={
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate
                          }
                          option={salary_structure_list}
                          keys={"salary_structure_id"}
                          label={"salary_stucture_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), SlaryStructureChange(value)
                          )}
                          placeholder={"Salary Structure Name"}
                          value={!!value ? value : ""}
                          errors={errors.salary_structure_id}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Salary Type
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"attendence_salary_type_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      readOnly={!edit_candidate}
                      option={attendence_salary_type}
                      keys={"domain_value"}
                      label={"domain_key"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Salary Type"}
                      value={!!value ? value : ""}
                      errors={errors.attendence_salary_type_id}
                    />
                  )}
                />
              </Grid>
            </Grid> */}
                {salary_structure?.component.map(
                  (m, index) =>
                    !m.calculation && (
                      <Grid
                        key={index}
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 15, marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid item xs={5}>
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "15%",
                              // textAlign: "center"
                            }}
                          >
                            {m.component_name}
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.5}>
                          <Controller
                            control={control}
                            name={m.compent_id.toString()}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <TextField
                                type={"number"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100.9%" }}
                                onBlur={onBlur}
                                size="small"
                                inputProps={{
                                  readOnly:
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate,
                                }}
                                onChange={onChange}
                                value={value}
                                placeholder={m.component_name}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </>
            )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OrganizationalDetails;
interface OrganizationalDetailsProps {
  errors?: any;
  control?: any;
  watch?: any;
  salary_structure_list: any[];
  department_list: any[];
  employee_type_list: any[];
  employee_timing_list: any[];
  employee_detail?: ViewEmployee;
  designation_list: any[];
  applicabledeg: any[];
  ReportingManagerList: any[];
  organization_list: any[];
  OrganizationSelected?: any;
  customer_list: CustomerList[];
  OnChangeCustomer?: any;
  project_list: ProjectList[];
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  post_list: AllPostList[];
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  edit_candidate: boolean;
  user?: userDetails;
  project_levels?: ProjectLevels;
  setValue?: any;
  edit_flag?: boolean;
  getValues: any;
  id2?: number;
  state_list: StateList[];
  documents: any[];
  fileHandleEvent?: any;
  SetDocument?: any;
  downloadDoc?: any;
  AddlfileHandleEvent?: any;
  downloadImage?: any;
}
