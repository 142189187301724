import { IMonthOptions } from "../interfaces/others.interface";

export const monthOptions: IMonthOptions[] = [
  { key: "All", val: 13 },
  { key: "Jan", val: 1 },
  { key: "Feb", val: 2 },
  { key: "Mar", val: 3 },
  { key: "Apr", val: 4 },
  { key: "May", val: 5 },
  { key: "Jun", val: 6 },
  { key: "Jul", val: 7 },
  { key: "Aug", val: 8 },
  { key: "Sep", val: 9 },
  { key: "Oct", val: 10 },
  { key: "Nov", val: 11 },
  { key: "Dec", val: 12 },
];

export const MonthNames: any = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};