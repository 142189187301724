import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  AllDepartmentList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteSalaryComponentAction,
  SalaryStructuregetAll,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import SalaryComponentView from "./SalaryComponentView";

function SalaryComponent({
  SalaryStructuregetAll,
  component_list,
  DeleteSalaryComponentAction,
  SidenavAutoCloseOpen,
}: // DeleteDepartmentAction
SalaryComponentProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    SalaryStructuregetAll();
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const { enqueueSnackbar } = useSnackbar();

  const filterData = (props: any) => {
    SalaryStructuregetAll({
      compent_id:
        !!props.compent_id && props.compent_id != "" ? props.compent_id : null,
    });
  };
  const Clear = () => {
    SalaryStructuregetAll();
  };
  const DeleteAction = (props: number) => {
    DeleteSalaryComponentAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <SalaryComponentView
      // organization_list={organization_list}
      component_list={component_list}
      filterData={filterData}
      Clear={Clear}
      // Delete={Delete}
      DeleteAction={DeleteAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    // organization_list: state.organization.organization_filter_list,
    component_list: state.organization.component_list,
  };
};
const mapDispatchToProps = {
  SalaryStructuregetAll,
  DeleteSalaryComponentAction,
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryComponent);

interface SalaryComponentProps {
  //organization_list?: OrganizationALlList[];
  component_list: SalaryStuctureComponent[];
  SalaryStructuregetAll?: any;
  DeleteSalaryComponentAction?: any;
  SidenavAutoCloseOpen?: any;
}
