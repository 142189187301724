import { FC, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";

import { useSnackbar } from "notistack";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { userDetails } from "../../../models/userModels";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
};
const styledHeader = {
  width: "100%",
  background: "#1976d2",
  color: "#fff",
};

const ChangePasswordModal: FC<IChangePassword> = ({
  isModalOpen,
  setIsModalOpen,
  resetPasswordAction,
  user,
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (formData: any) => {
    console.log("formData : ", formData);
    if (formData.confirm_password !== formData.new_password) {
      enqueueSnackbar("confirm password not matched", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const payload = {
      enqueueSnackbar: enqueueSnackbar,
      ...formData,
    };
    if (payload?.confirm_password) delete payload.confirm_password;
    resetPasswordAction(payload);
  };

  useEffect(() => {
    if (!isModalOpen) reset({ userid: "", new_password: "", old_password: "" });
  }, [isModalOpen]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  console.log("user : ", user);
  useEffect(() => {
    setValue("userid", user?.employeeId);
  }, [user,isModalOpen]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={isModalOpen}>
        <Box sx={style}>
          <Box sx={styledHeader}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              p={2}
            >
              Change Your Password
            </Typography>
          </Box>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                my={2}
                display="flex"
                flexDirection={"column"}
              >
                <Grid item xs={10} sx={{ marginLeft: "3.4rem" }}>
                  <Controller
                    control={control}
                    name={"userid"}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        inputProps={{ readOnly: true }}
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Username"}
                        label={
                          <span>
                            Enter user name
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        error={!!errors["userid"]}
                        helperText={
                          errors.userid &&
                          `${errors.userid.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10} sx={{ marginLeft: "3.4rem" }}>
                  <Controller
                    control={control}
                    name={"old_password"}
                    rules={{ required: true }}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"password"}
                        id="standard-required"
                        variant="outlined"
                        autoComplete="off"
                        className={"smaller"}
                        style={{ width: "100%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Old password"}
                        label={
                          <span>
                            Enter old password
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        error={!!errors["old_password"]}
                        helperText={
                          errors.old_password &&
                          `${errors.old_password.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10} sx={{ marginLeft: "3.4rem" }}>
                  <Controller
                    control={control}
                    name={"new_password"}
                    rules={{ required: true }}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"password"}
                        id="standard-required"
                        autoComplete="off"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"New password"}
                        label={
                          <span>
                            Enter new password
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        error={!!errors["new_password"]}
                        helperText={
                          errors.new_password &&
                          `${errors.new_password.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ marginLeft: "3.4rem", position: "relative" }}
                >
                  <Controller
                    control={control}
                    name={"confirm_password"}
                    rules={{ required: true }}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={showPassword ? "text" : "password"}
                        id="standard-required"
                        variant="outlined"
                        autoComplete="off"
                        className={"smaller"}
                        style={{ width: "100%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Confirm password"}
                        // inputProps={{
                        //   endAdornment: showPassword ? (
                        //     <IconButton
                        //       color="primary"
                        //       onClick={handleClickShowPassword}
                        //     >
                        //       <VisibilityIcon />
                        //     </IconButton>
                        //   ) : (
                        //     <IconButton
                        //       color="primary"
                        //       onClick={handleClickShowPassword}
                        //     >
                        //       <VisibilityOffIcon />
                        //     </IconButton>
                        //   ),
                        // }}
                        label={
                          <span>
                            Confirm password
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        error={!!errors["confirm_password"]}
                        helperText={
                          errors.confirm_password &&
                          `${errors.confirm_password.message}* This field is Required`
                        }
                      />
                    )}
                  />
                  <IconButton
                    color="primary"
                    onClick={handleClickShowPassword}
                    sx={{ position: "absolute", top: "26%", right: "0%" }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sx={{ marginLeft: "3.4rem" }}
                  justifyContent="space-between"
                  display="flex"
                >
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="outlined">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

interface IChangePassword {
  isModalOpen: boolean;
  setIsModalOpen: any;
  resetPasswordAction?: any;
  user?: userDetails;
}

export default ChangePasswordModal;
