import { Divider, Grid, Typography } from '@mui/material';
import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { environment } from '../../environment';
import LogActivity from './LogActivity';
import TimingSheetApprove from './TimingSheetApprove';
import ViewTimesheet from "./TimingSheetApprove/ViewTimesheet/index";
import TimeSheetHistory from './TimeSheetHistory';

function TimingSheet() {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {" "}
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              // color: "#D4D7DD",
              // fontSize: 14,
              // fontFamily: "Poppins, sans-serif",
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="log-activity" element={<LogActivity />} />
        <Route path="timing-sheet-approve" element={<TimingSheetApprove />} />
        <Route path="view-time-sheet" element={<ViewTimesheet />} />
        <Route path="time-sheet-history" element={<TimeSheetHistory />} />

        <Route
          path="/"
          element={<Navigate to={`/${pathname.split("/")[1]}/log-activity`} />}
        />
      </Routes>
    </>
  );
}

export default TimingSheet