import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Grid,
  Typography,
  Divider,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableBody,
  TablePagination,
  Tooltip,
  TableCell,
  styled,
  tableCellClasses,
  Slide,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import { batch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { environment } from "../../../environment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { ViewPromotion } from "../../../models/promotionModel";
import PromotionBatchHeaderDetails from "./PromotionBatchHeaderDetails";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

function PromotionBatchDetailView({
  view_promotion,
  ViewPromotionAction,
  DiscardEmploye
}: PromotionBatchDetailViewProps) {
  let navigateTo = useNavigate();
  // const { id } = useParams();

  const divElement = useRef<HTMLDivElement | null>(null);
  const [dynamicTableHeader, setDynamicTableHeader] = useState<any[]>([]);

  useEffect(() => {
    if (
      !!view_promotion &&
      Array.isArray(view_promotion) &&
      view_promotion.length > 0 &&
      !!view_promotion[0].components &&
      Array.isArray(view_promotion[0].components) &&
      view_promotion[0].components.length > 0
    ) {
      const dynamicHeader = view_promotion[0].components;
      setDynamicTableHeader(dynamicHeader);
    }
  }, [view_promotion]);

  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Effective Working Days",
    ...dynamicTableHeader.map((self) => `${self.display_name}`),
    "Action",
  ];
  const [rows, setRows] = useState<ViewPromotion[]>([]);
  const [discardItem, SetdiscradItem] = useState<ViewPromotion[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);

  const DiscardEmployeeAction = () => {
    setsubmitFlag(false);
    SetdiscradItem([]);
    DiscardEmploye({
      promotion_salary_batch_id:
        !!view_promotion &&
        view_promotion.length > 0 &&
        view_promotion[0]?.batch_id,
      promotion_revision_id: discardItem.map((m) => m.promotion_revision_id),
    });
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows =
      !!view_promotion &&
      Array.isArray(view_promotion) &&
      view_promotion.length > 0
        ? view_promotion.filter((row: any) => {
            return (
              row?.employee_id
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.employee_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          })
        : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!view_promotion && view_promotion.length > 0) {
      cancelSearch();
    }
  }, [view_promotion]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const styles = {
    marginRight: "50px",
  };

  return (
    <Paper
      ref={divElement}
      elevation={3}
      sx={{ marginTop: 5, marginInline: 5, marginBottom: 10 }}
    >
      <Dialog
        open={submit_flag}
        onClose={() => setsubmitFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Remove Employee"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want remove those employee from this batch??
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setsubmitFlag(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => DiscardEmployeeAction()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="label"
        style={{ marginBottom: -70, marginLeft: 20 }}
        onClick={() => navigateTo(-1)}
      >
        <ArrowBackIcon />
      </IconButton>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item xs={10} marginTop={1} justifyContent={"center"}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
            }}
          >
            Promotion Details
          </Typography>
        </Grid>
      </Grid>
      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      <Grid
        container
        style={{
          height: 40,
          width: 250,
          backgroundColor: "#FD6300",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          clipPath: "polygon(100% 0, 90% 50%, 100% 100%, 0 99%, 0 0)",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px"
        }}
      >
        <Typography style={{ color: "#fff", fontWeight: 700 }}>
          Promotion Details
        </Typography>
      </Grid>
      <PromotionBatchHeaderDetails view_promotion={view_promotion} />
      <DicardCard
        Close={() => SetdiscradItem([])}
        discardItem={discardItem}
        DiscardEmployeeAction={() => setsubmitFlag(true)}
      />
      <Grid
        container
        style={{
          height: 40,
          width: 250,
          backgroundColor: "#FD6300",
          justifyContent: "center",
          alignItems: "center",
          clipPath: "polygon(100% 0, 90% 50%, 100% 100%, 0 99%, 0 0)",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px"
        }}
      >
        <Typography style={{ color: "#fff", fontWeight: 700 }}>
          Employee List
        </Typography>
      </Grid>
      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 1 }}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!view_promotion &&
                    Array.isArray(view_promotion) &&
                    view_promotion.length > 0 &&
                    view_promotion
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.employee_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            <Link
                              onClick={() =>
                                navigateTo(
                                  `/employment/manage-profile/${row.employee_id}`
                                )
                              }
                            >
                              {row.employee_id}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.employee_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {!!row.components.find(
                              (n) => n.employee_id === row.employee_id
                            )?.effective_working_days &&
                            row.components.find(
                              (n) => n.employee_id === row.employee_id
                            )?.effective_working_days === 99
                              ? "Full Month"
                              : row.components.find(
                                  (n) => n.employee_id === row.employee_id
                                )?.effective_working_days}
                          </StyledTableCell>

                          {dynamicTableHeader.map((self, index) => (
                            <StyledTableCell
                              align="left"
                              key={column1[2 + index]}
                            >
                              &#8377;
                              {
                                row.components.find(
                                  (t) => t.display_name === self.display_name
                                )?.componet_calculated_value
                              }
                            </StyledTableCell>
                          ))}

                          <StyledTableCell
                            align="left"
                            key={column1[column1.length - 1]}
                          >
                            {(row.promotion_status !== 5 ||
                              row.revision_status !== 5) && (
                              <Tooltip title="Remove From Batch">
                                <IconButton
                                  onClick={() =>
                                    SetdiscradItem([...discardItem, row])
                                  }
                                >
                                  <DoDisturbOnIcon
                                    style={{ color: "#cc2900" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PromotionBatchDetailView;
interface PromotionBatchDetailViewProps {
  view_promotion?: ViewPromotion[];
  ViewPromotionAction?: any;
  DiscardEmploye?: any;
  //   setopenModal?: any;
  //   openModal: boolean;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DicardCard = ({
  discardItem,
  DiscardEmployeeAction,
  Close,
}: DicardCardProps) => {
  return (
    <Slide direction="left" in={!!discardItem && discardItem.length > 0}>
      <Paper
        elevation={10}
        style={{
          height: "45%",
          width: !!discardItem && discardItem.length > 0 ? 500 : 0,
          position: "absolute",
          top: 100,
          right: 15,
          overflow: "auto",
          zIndex: 999,
        }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item xs={10} marginTop={1} justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                // textAlign: "center",
              }}
            >
              Remove Employee from Batch
            </Typography>
          </Grid>
        </Grid>
        {/* <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "90%",
            marginLeft: "5%",
          }}
        ></Divider> */}
        <Grid
          container
          direction={"row"}
          justifyContent={"space-evenly"}
          style={{ backgroundColor: "#42a5f5" }}
        >
          <Grid item>
            <Typography style={{ padding: 5, paddingLeft: 30, color: "#fff" }}>
              Employee ID
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ padding: 5, paddingLeft: 30, color: "#fff" }}>
              Employee Name
            </Typography>
          </Grid>
          <Divider
            style={{
              borderColor: "#9E9E9E",
              opacity: 0.7,
              width: "90%",
            }}
          ></Divider>
        </Grid>
        {discardItem.map((item, index) => (
          <>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-evenly"}
              style={{ backgroundColor: "#FBFBFB" }}
            >
              <Grid item>
                <Typography style={{ padding: 5, paddingLeft: 30 }}>
                  {item.employee_id}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ padding: 5, paddingLeft: 30 }}>
                  {item.employee_name}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
          </>
        ))}
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
          style={{ position: "absolute", bottom: 10 }}
        >
          <Grid item>
            <Button variant="contained" color={"error"} onClick={() => Close()}>
              Close
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              color={"error"}
              onClick={() => DiscardEmployeeAction()}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
};

interface DicardCardProps {
  discardItem: ViewPromotion[];
  DiscardEmployeeAction?: any;
  Close?: any;
}
