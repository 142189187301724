import { Box } from "@mui/material";
import { FC } from "react";
import { connect } from "react-redux";
import {
  ILeaveDesignation,
  ILeaveManagement,
  ILeaveType,
  ILeaveTypeDataDesignationWise,
} from "../../../models/leaveModel";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  deleteLeaveManagementAction,
  editLeaveManagementAction,
  createLeaveManagementAction,
  getAllLeaveCountDesignationAction,
  GetAllLeaveTypeAction,
  getLeaveDetailsDesignationwiseAction,
  selectOrganization,
} from "../../../stores/actions/LeaveAction";
import {
  GetAllDesignationAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import OrganizationAutoComplete from "../OrganizationAutoComplete";
import LeaveManagementMain from "./LeaveManagementMain";

const LeaveManagement: FC<InLeaveManagement> = ({
  user,
  leave_designation,
  createLeaveManagementAction,
  editLeaveManagementAction,
  getAllLeaveCountDesignationAction,
  deleteLeaveManagementAction,
  GetAllDesignationAction,
  designation_list,
  leave_types,
  GetAllLeaveTypeAction,
  leave_type_designation_wise,
  getLeaveDetailsDesignationwiseAction,
  selected_organization_leave,
  GetAllOrganizationAction,
  selectOrganization,
  organization_list,
}): JSX.Element => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <OrganizationAutoComplete
          organization_list={organization_list}
          user={user}
          selectOrganization={selectOrganization}
          selected_organization_leave={selected_organization_leave}
          GetAllOrganizationAction={GetAllOrganizationAction}
        />
      </Box>
      <LeaveManagementMain
        user={user}
        leaveDesignation={leave_designation}
        createLeaveManagementAction={createLeaveManagementAction}
        editLeaveManagementAction={editLeaveManagementAction}
        getAllLeaveCountDesignationAction={getAllLeaveCountDesignationAction}
        deleteLeaveManagementAction={deleteLeaveManagementAction}
        selected_organization_leave={selected_organization_leave}
        GetAllDesignationAction={GetAllDesignationAction}
        designationList={designation_list}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        leave_types={leave_types}
        getLeaveDetailsDesignationwiseAction={
          getLeaveDetailsDesignationwiseAction
        }
        leaveTypeDesignationWise={leave_type_designation_wise}
      />
    </Box>
  );
};
const mapDispatchToProps = {
  deleteLeaveManagementAction,
  editLeaveManagementAction,
  createLeaveManagementAction,
  getAllLeaveCountDesignationAction,
  GetAllDesignationAction,
  GetAllLeaveTypeAction,
  getLeaveDetailsDesignationwiseAction,
  selectOrganization,
  GetAllOrganizationAction,
};
const mapStateToProps = (state: StoreState) => {
  return {
    leave_designation: state.leave.leave_designation,
    user: state.user.userDetails,
    designation_list: state.organization.designation_list,
    leave_types: state.leave.leave_types,
    leave_type_designation_wise: state.leave.leave_type_designation_wise,
    selected_organization_leave: state.leave.selected_organization,
    organization_list: state.organization.organization_filter_list,
  };
};

interface InLeaveManagement {
  leave_designation: ILeaveDesignation[];
  user?: userDetails;
  deleteLeaveManagementAction?: any;
  editLeaveManagementAction?: any;
  createLeaveManagementAction?: any;
  getAllLeaveCountDesignationAction?: any;
  GetAllDesignationAction?: any;
  designation_list: AllDesignationList[];
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  leave_type_designation_wise: ILeaveTypeDataDesignationWise[];
  getLeaveDetailsDesignationwiseAction?: any;
  selected_organization_leave?: number;
  organization_list: OrganizationALlList[];
  selectOrganization?: any;
  GetAllOrganizationAction?: any;
}
export default connect(mapStateToProps, mapDispatchToProps)(LeaveManagement);
