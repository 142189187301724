import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AllPostList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  CreatePostAction,
  EditPostAction,
} from "../../../stores/actions/organizationActions";
import AddPostView from "./AddPostView";

const AddPost = ({
  CreatePostAction,
  EditPostAction,
  post_list,
}: AddPostProps) => {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const Submit = (data: any) => {
    CreatePostAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData = (data: any) => {
    EditPostAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  // React.useEffect(() => {
  //   GetAllOrganizationAction();
  // }, []);
  return (
    <AddPostView
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
      post_list={post_list}
      post={post_list.find((m) => !!id && m.post_id == +id)}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    post_list: state.organization.post_list,
  };
};
const mapDispatchToProps = {
  CreatePostAction,
  EditPostAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPost);

interface AddPostProps {
  CreatePostAction?: any;
  EditPostAction?: any;
  post_list: AllPostList[];
}
