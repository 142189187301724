import {
  Button,
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { baseUrl, environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
import { InvoiceList, InvoiceListResponse } from "../../../models/invoiceModel";
import { ProjectList } from "../../../models/customerModel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Axios from "axios";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSnackbar } from "notistack";
function ProjectInvoiceView({
  user,
  id,
  invoice_list_details,
  project_list,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
  UpdateInvoice,
  accessToken,
}: ProjectInvoiceViewProps) {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const FileDownload = require("js-file-download");
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState<InvoiceList[]>([]);
  const styles = {
    marginRight: "50px",
  };
  const columns: any[] = [
    "Invoice No.",
    "Invoice Date",
    "Invoice Amount",
    "Remarks",
    "Status",
    "Created On",
    "Action",
  ];
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows =
      !!invoice_list_details?.invoice_list &&
      invoice_list_details?.invoice_list.length > 0
        ? invoice_list_details.invoice_list.filter((row: InvoiceList) => {
            console.log("row", row);

            return (
              row?.remarks?.toLowerCase().includes(searchedVal.toLowerCase()) ||
              row?.status?.toLowerCase().includes(searchedVal.toLowerCase())
            );
          })
        : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  React.useEffect(() => {
    if (!!invoice_list_details?.invoice_list) {
      cancelSearch();
    }
  }, [invoice_list_details]);
  const statusUpdate = (status: any, row: any) => {
    UpdateInvoice({ ...row, status: status });
  };

  const handleClickOpenDownload = (file_name: any) => {
    Axios({
      url: baseUrl + environment.urls.downloadFileUrl,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        fileName: file_name,
      },
    })
      .then(async (response) => {
        // await FileDownload(response.data, file_name);
        var file = new Blob([response.data], {
          type: getMimeTypeFromFileName(file_name),
        });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  };

  function getMimeTypeFromFileName(fileName: any) {
    // Extract the file extension
    var extension = fileName.split(".").pop().toLowerCase();

    // Define MIME types based on file extensions
    var mimeTypes: any = {
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      pdf: "application/pdf",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // Add more file extensions and their corresponding MIME types here
    };

    // Look up the MIME type based on the file extension
    var mimeType = mimeTypes[extension] || "application/octet-stream";

    return mimeType;
  }
  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={12} paddingBottom={2}>
          <Grid
            container
            style={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Grid></Grid>
            <Grid>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  color: "#626974",
                }}
              >
                Invoice Details of{" "}
                {!!project_list &&
                  project_list.length > 0 &&
                  project_list?.find((m) => m?.project_id === +id)
                    ?.project_name}
              </Typography>
            </Grid>
            <Grid style={{}}>
              <Tooltip title="Project Dashboard">
                <IconButton
                  style={{ color: "#1565C0" }}
                  onClick={() =>
                    navigateTo(`/customer/project-dashboard/${+id}`)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show All Payment">
                <IconButton
                  style={{ color: "#1565C0" }}
                  onClick={() =>
                    navigateTo(`/customer/payment-details-main/${+id}`)
                  }
                >
                  <PaymentsIcon />
                </IconButton>
              </Tooltip>
              {!!user &&
                environment.global_veriable.officialEmployee.includes(
                  user.designation
                ) && (
                  <Tooltip
                    title="Budget Dashboard"
                    onClick={() =>
                      navigateTo(`/customer/budget-dashboard/${+id}`)
                    }
                  >
                    <IconButton>
                      <DashboardIcon style={{ color: "#1565C0" }} />
                    </IconButton>
                  </Tooltip>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {/* {!!user &&
            environment.global_veriable.officialEmployee.includes(
              user.designation
            ) && ( */}
          <Button
            onClick={() => navigate(`/customer/invoice-create/${+id}/0`)}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
          {/* )} */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>
      </Grid>
      <Grid marginTop={2}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: InvoiceList) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.project_payment_invoice_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.invoice_no}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {moment(row.invoice_date).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                          }}
                        >
                          &#8377; {row.invoice_amount}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.remarks}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        <Typography
                        // style={{
                        //   color: row.status === "ACTIVE" ? "green" : "red",
                        //   padding: 0,
                        // }}
                        >
                          {row.status}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.created_on
                          ? moment(row.created_on)
                              .format("DD/MM/YYYY")
                              .toUpperCase()
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        <Tooltip
                          title="View Payment Details"
                          onClick={() =>
                            navigate(
                              `/customer/invoice-payment_details/${row.project_id}/${row.project_payment_invoice_id}`
                              //`/customer/customer-details/${row.customer_id}`
                            )
                          }
                        >
                          <IconButton>
                            <PaymentsIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Edit Invoice Details"
                          onClick={() =>
                            navigate(
                              `/customer/invoice-create/${+id}/${
                                row.project_payment_invoice_id
                              }`
                            )
                          }
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Download Documents"
                          onClick={() =>
                            handleClickOpenDownload(row.invoice_file)
                          }
                        >
                          <IconButton>
                            <CloudDownloadIcon />
                          </IconButton>
                        </Tooltip>
                        {row.status == "DRAFT" && (
                          <Tooltip
                            title="Raised"
                            onClick={() => statusUpdate("RAISED", row)}
                          >
                            <IconButton>
                              <MoveUpIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {row.status == "RAISED" && (
                          <Tooltip
                            title="Paid"
                            onClick={() => statusUpdate("PAID", row)}
                          >
                            <IconButton>
                              <CheckCircleIcon style={{ color: "green" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              {/* {!!rows && rows.length <= 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <div style={{ color: "red", textAlign: "center" }}>
                      No Data Found{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          style={styles}
          rowsPerPageOptions={[50, 100, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <Grid
          container
          alignItems={"center"}
          justifyContent="end"
          color={"black"}
        >
          <Grid xs={2} textAlign="right">
            Rows per page:
          </Grid>
          <Grid xs={1} textAlign="center">
            <NativeSelect
              value={pagesize.toString()}
              onChange={(event) => SetpageSize(+event.target.value)}
            >
              {rowperpage.map((p) => (
                <option key={p} value={p} onChange={(value: any) => value(p)}>
                  {p}
                </option>
              ))}
            </NativeSelect>
          </Grid>
          <Grid xs={1} textAlign="center">
            <IconButton
              onClick={() => Setpageindex(pageindex - 1)}
              color="primary"
              size="small"
              disabled={pageindex <= 0}
            >
              <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
            </IconButton>
          </Grid>
          <Grid xs={1} textAlign="center">
            <Typography>
              {pageindex * pagesize + 1} -{" "}
              {!!total_size && total_size <= (pageindex + 1) * pagesize
                ? total_size
                : (pageindex + 1) * pagesize}
              {" of "}
              {total_size}
            </Typography>
          </Grid>
          <Grid xs={1} textAlign="center">
            <IconButton
              color="primary"
              size="small"
              onClick={() => Setpageindex(pageindex + 1)}
              disabled={
                (!!total_size && total_size <= (pageindex + 1) * pagesize) ||
                total_size === 0
              }
            >
              <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProjectInvoiceView;

interface ProjectInvoiceViewProps {
  user?: userDetails;
  id?: any;
  invoice_list_details?: InvoiceListResponse;
  project_list: ProjectList[];
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  UpdateInvoice?: any;
  accessToken?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
