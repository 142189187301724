import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProjectList } from "../../../models/customerModel";
import { EmpList } from "../../../models/employeeModel";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  EmployeeTypeList,
  AllDesignationList,
} from "../../../models/organizationModels";
import { IEmployeeList } from "../../../models/timingSheetModel";
import {
  TerminatedEmployeeDetails,
  TerminatedEmployeeListing,
} from "../../../models/terminationModel";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";

function TerminatedEmployeeListView({
  control,
  errors,
  getValues,
  handleSubmit,
  setValue,
  watch,
  designation_list,
  employee_list,
  employee_type_list,
  project_list,
  searchEmployeeList,
  getEmployeeAllDetailsAction,
  searchByNameEmpAction,
  ClearAllFilter,
  FilterSubmit,
  terminated_emp_list,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
}: TerminatedEmployeeListViewProps) {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log("searchVal", searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, "");
    }, 2000);
  };
  useEffect(() => {
    searchByNameEmpAction("", "");
  }, []);
  useEffect(() => {
    if (!!getValues("employee_name")) {
      setValue("employee_id", getValues("employee_name"));
    }
  }, [watch("employee_name")]);
  useEffect(() => {
    if (!!getValues("employee_id")) {
      console.log("employee_id", getValues("employee_id"));
      getEmployeeAllDetailsAction({ employee_id: getValues("employee_id") });
    }
  }, [watch("employee_id")]);
  const ClearFilter = () => {
    // setValue("employee_type_id", null);
    setValue("employee_id", null);
    setValue("employee_name", null);
    // setValue("project_id", null);
    setValue("designation_id", null);
    setValue("salary_structure_id", null);
    ClearAllFilter();
  };
  const onSubmit = (data: any) => {
    FilterSubmit(data);
  };

  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<TerminatedEmployeeDetails[]>([]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!terminated_emp_list
      ? terminated_emp_list.data.filter((row: TerminatedEmployeeDetails) => {
          return (
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.designation_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_id?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!terminated_emp_list) {
      setRows(terminated_emp_list.data);
    }
  }, [terminated_emp_list]);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation Name",
    "Project Name",
    "Terminated Date",
    // "Action",
  ];
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <form>
                <Grid container justifyContent={"flex-start"} mt={1.5}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee ID
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100.9%" }}
                              onBlur={onBlur}
                              size="small"
                              value={!!value ? value : ""}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Employee ID"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Name
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"employee_name"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={searchEmployeeList}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_id"}
                              label={"employee_name"}
                              onInputChange={(e: any) => {
                                if (e?.target?.value) GetCall(e.target.value);
                              }}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Employee"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Designation
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"designation_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={designation_list}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"designation_id"}
                              label={"designation_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Designation"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), setValue("project_id", null)
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Project Name
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"project_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={project_list}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"project_id"}
                              label={"project_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Project Name"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Last Working Date
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"termination_date"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"date"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100.9%" }}
                              onBlur={onBlur}
                              size="small"
                              value={!!value ? value : ""}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Last Working Date"}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    padding={3}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={ClearFilter}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.employee_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {/* <Link
                              onClick={() =>
                                navigateTo(
                                  `/employment/manage-profile/${row.employee_id}`
                                )
                              }
                            > */}
                              {row.employee_id}
                            {/* </Link> */}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.employee_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.designation_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.project_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            {moment(row.termination_date).format("DD-MM-YYYY")}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container alignItems={"center"} justifyContent="end">
                <Grid xs={2} textAlign="right">
                  Rows per page:
                </Grid>
                <Grid xs={1} textAlign="center">
                  <NativeSelect
                    value={pagesize.toString()}
                    onChange={(event) => SetpageSize(+event.target.value)}
                  >
                    {rowperpage.map((p) => (
                      <option
                        key={p}
                        value={p}
                        onChange={(value: any) => value(p)}
                      >
                        {p}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    onClick={() => Setpageindex(pageindex - 1)}
                    color="primary"
                    size="small"
                    disabled={pageindex <= 0}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <Typography>
                    {pageindex * pagesize + 1} -{" "}
                    {!!total_size && total_size <= (pageindex + 1) * pagesize
                      ? total_size
                      : (pageindex + 1) * pagesize}
                    {" of "}
                    {total_size}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => Setpageindex(pageindex + 1)}
                    disabled={
                      (!!total_size &&
                        total_size <= (pageindex + 1) * pagesize) ||
                      total_size === 0
                    }
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default TerminatedEmployeeListView;
interface TerminatedEmployeeListViewProps {
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  watch?: any;
  getEmployeeAllDetailsAction?: any;
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  employee_list: EmpList[];
  designation_list: AllDesignationList[];
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  FilterSubmit?: any;
  ClearAllFilter?: any;
  terminated_emp_list?: TerminatedEmployeeListing;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
