import { Container, Grid, Paper } from "@mui/material";
import { log } from "console";
import React from "react";
import { connect } from "react-redux";
import { CreateEmpOnMonth, CurrentMonthBirthday, EmployeeTypeList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { GetAllEmployeeTypeAction, GetMainDashboardAction } from "../../../stores/actions/organizationActions";
import DashboardMainView from "./DashboardMainView";


const DashboardMain = ({GetAllEmployeeTypeAction,GetMainDashboardAction,employee_type_list,currentMonthBirthday,createEmpOnMonth, user}:DashboardMainProps) => {
  const [selectedEmpType, setselectedEmpType] = React.useState(Number);
  React.useEffect(() => {
    GetAllEmployeeTypeAction({organization_id: null});
    GetMainDashboardAction({
      employee_type_id: !!selectedEmpType?selectedEmpType:null
    })
  }, [selectedEmpType]);
  return (
    // <Grid
    //   container
    //   justifyContent={"space-evenly"}
    //   alignItems={"center"}
    //   overflow={"hidden"}
    //   style={{ backgroundColor: "#F6F7F9", height: "100%" }}
    // >
    //   <img src={require("../../../assets/maintenance.jpg")} />
    // </Grid>
    <DashboardMainView employee_type_list={employee_type_list}
    currentMonthBirthday={currentMonthBirthday}
    createEmpOnMonth={createEmpOnMonth}
    setselectedEmpType={setselectedEmpType}
    selectedEmpType={selectedEmpType}
    user={user}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    employee_type_list: state.organization.employee_type_list,
    currentMonthBirthday: state.organization.currentMonthBirthday,
    createEmpOnMonth: state.organization.createEmpOnMonth,
    user: state.user.userDetails
  };
};
const mapDispatchToProps = {
  GetAllEmployeeTypeAction,
  GetMainDashboardAction
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardMain);

interface DashboardMainProps {
  GetAllEmployeeTypeAction?: any;
  employee_type_list : EmployeeTypeList[]
  GetMainDashboardAction: any;
  currentMonthBirthday: CurrentMonthBirthday[];
  createEmpOnMonth: CreateEmpOnMonth[];
  user?: userDetails;
}
