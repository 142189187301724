const memoize = (callback: any) => {
  let cache: any = {};
  return (...args: any) => {
    let n = args[0];
    if (n in cache) {
      return cache[n];
    } else {
      let result = callback(n, args[1], args[2]);
      cache[n] = result;
      return result;
    }
  };
};
const colorGenerator = memoize((cabId: string, min: number, max: number) => {
  let red: number = +Math.floor(Math.random() * (max - min + 1)) + min;
  let green: number = +Math.floor(Math.random() * (max - min + 1)) + min;
  let blue: number = +Math.floor(Math.random() * (max - min + 1)) + min;
  return `rgb(${red},${green},${blue})`;
});

// const colorGenerator=(min:number,max:number)=>{
//     let red:number=+Math.floor(Math.random() * (max - min + 1)) + min;
//     let green:number=+Math.floor(Math.random() * (max - min + 1)) + min;
//     let blue:number=+Math.floor(Math.random() * (max - min + 1)) + min;
//     return `rgb(${red},${green},${blue})`
// }
export default colorGenerator;
