import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Box,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocompletes from "../../../library/AutoComplete";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { baseUrl, environment } from "../../../environment";
import Axios from "axios";
import { useSnackbar } from "notistack";
import moment from "moment";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import {
  DownloadErrorResponse,
  UploadErrorResponse,
} from "../../../models/attendanceModel";
import { userDetails } from "../../../models/userModels";
const FileDownload = require("js-file-download");

const AttendanceUploadView = ({
  organization_list,
  project_list,
  customer_list,
  employee_type_list,
  accessToken,
  user,
  AttendanceDetailPayloadSuccessActions,
}: AttendanceUploadViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    console.log(url);

    navigateTo(url);
  };
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const [errorModal, seterrorModal] = useState<boolean>(false);
  // const [errorModal2, seterrorModal2] = useState<boolean>(false);
  const handleErrorModalClose = () => {
    seterrorModal(false);
  };
  // const handleErrorModalClose2 = () => {
  //   seterrorModal2(false);
  // };
  const columns: any[] = ["Employee No.", "Message"];
  // const columns2: any[] = ["Message"];
  const [errorUploaded, seterrorUploaded] = useState<UploadErrorResponse[]>([]);
  const [uploadField, setuploadField] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!!user) {
      if (
        !environment.global_veriable.ho_employees_type_id.includes(
          +user.employee_detail.employee_type_id
        ) ||
        environment.global_veriable.managerLevelDeg.includes(user.designation)
      ) {
        setValue("organization_id", +user.employee_detail.organization_id);
      }
      if (
        !environment.global_veriable.officialEmployee.includes(user.designation)
      ) {
        setValue("employee_type_id", user.employee_detail.employee_type_id);
        if (user.project_detail.length >= 1) {
          setValue("project_id", user.project_detail[0].project_id);
        }
      }
    }
  }, [user]);
  const handleClickOpenDownload = () => {
    if (
      !!getValues("organization_id") &&
      !!getValues("employee_type_id") &&
      (environment.global_veriable.ho_employees_type_id.includes(
        +getValues("employee_type_id")
      ) ||
        (!environment.global_veriable.ho_employees_type_id.includes(
          +getValues("employee_type_id")
        ) &&
          !!getValues("project_id")))
    ) {
      Axios({
        url: baseUrl + environment.urls.downloadAttendance,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          organization_id: +getValues("organization_id"),
          employee_type_id: +getValues("employee_type_id"),
          project_id: !!getValues("project_id")
            ? +getValues("project_id")
            : null,
          month:
            moment(selectedDate).format("YYYY").toString() +
            "-" +
            moment(selectedDate).format("MM"),
        },
      })
        .then(async (response) => {
          if (response.status == 200) {
            FileDownload(response.data, "attendance.xlsx");
          } else {
            enqueueSnackbar(
              !!response?.data &&
                !!JSON.parse(await response.data.text())?.message
                ? JSON.parse(await response.data.text())?.message
                : "Something went wrong!",
              {
                variant: "warning",
              }
            );
          }
        })
        .catch(async (error) => {
          enqueueSnackbar(
            !!error?.response?.data &&
              !!JSON.parse(await error.response.data.text())?.message
              ? JSON.parse(await error.response.data.text())?.message
              : "Something went wrong!",
            {
              variant: "error",
            }
          );
        });
    } else {
      enqueueSnackbar("Please Select All The Field...", {
        variant: "error",
      });
    }
  };
  const handleClickUpload = (event: any) => {
    if (
      !!accessToken &&
      !!getValues("organization_id") &&
      !!getValues("employee_type_id") &&
      (environment.global_veriable.ho_employees_type_id.includes(
        +getValues("employee_type_id")
      ) ||
        (!environment.global_veriable.ho_employees_type_id.includes(
          +getValues("employee_type_id")
        ) &&
          !!getValues("project_id")))
    ) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${event.target.files[0].name
        }`;
      const month: any = new Date(
        moment(selectedDate).format("YYYY").toString() +
        "-" +
        moment(selectedDate).format("MM")
      );
      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("fileName", file_name);
      formData.append("organization_id", +getValues("organization_id"));
      formData.append(
        "project_id",
        !!getValues("project_id") ? +getValues("project_id") : null
      );
      formData.append("employee_type_id", +getValues("employee_type_id"));
      formData.append("month", month);

      BeginApiCallAction({
        count: 1,
        message: "Uploading File. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.attendenceUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then(async (response) => {
          if (response.status == 200) {
            if (
              !!response.data &&
              !!response.data?.code &&
              response.data.code == "001"
            ) {
              AttendanceDetailPayloadSuccessActions({
                organization_id: +getValues("organization_id"),
                employee_type_id: +getValues("employee_type_id"),
                project_id: +getValues("project_id"),
                project_attendence_id: response.data.project_attendence_id,
                page_index: 0,
                page_size: 2,
              });
              navigate(`/attendance/attendance-details/1`);
              await enqueueSnackbar("Successfully uploaded...", {
                variant: "success",
              });
            } else {
              seterrorUploaded(response.data.error);
              seterrorModal(true);
              enqueueSnackbar(
                !!response?.data &&
                  !!JSON.parse(await response.data.text())?.message
                  ? JSON.parse(await response.data.text())?.message
                  : "Something went wrong!",
                {
                  variant: "warning",
                }
              );
            }
          } else {
            enqueueSnackbar(
              !!response?.data &&
                !!JSON.parse(await response.data.text())?.message
                ? JSON.parse(await response.data.text())?.message
                : "Something went wrong!",
              {
                variant: "warning",
              }
            );
          }
          CreateCandidateSuccessAction();
        })
        .catch(async (error) => {
          enqueueSnackbar(
            !!error?.response?.data &&
              !!JSON.parse(await error.response.data.text())?.message
              ? JSON.parse(await error.response.data.text())?.message
              : "Something went wrong!",
            {
              variant: "error",
            }
          );
          CreateCandidateSuccessAction();
        });

      setuploadField(null);
      if (fileInputRef.current) fileInputRef.current.value = "";
    }
  };
  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 5, marginInline: 10 }}>
        <Grid
          container
          direction={"row"}
          // justifyContent={"center"}
          // alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item xs={10} marginTop={1}>
            <Typography
              style={{
                // fontWeight: "700",
                // fontSize: 18,
                // fontFamily: "Poppins, sans-serif",
                // textAlign: "center",
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20
              }}
            >
              Attendance Upload/Download
            </Typography>
          </Grid>
        </Grid>

        <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "90%",
            marginLeft: "5%",
          }}
        ></Divider>

        <Grid container justifyContent={"flex-start"}>
          {!!user &&
            environment.global_veriable.officialEmployee.includes(
              user.designation
            ) && (
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 8, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                      }}
                    >
                      Organization
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"organization_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={organization_list}
                          //   readOnly={!!department ? edit_flag : !edit_flag}
                          keys={"organization_id"}
                          label={"organization_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Search Organization"}
                          value={!!value ? value : ""}
                          errors={!!errors["organization_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          {!!user &&
            environment.global_veriable.ho_employees_type_id.includes(
              user.employee_detail.employee_type_id
            ) &&
            !!watch("organization_id") && (
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Employee Type
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"employee_type_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={employee_type_list.filter(
                            (m) =>
                              m.org_id == +watch("organization_id") &&
                              ((environment.global_veriable.managerLevelDeg.includes(
                                user.designation
                              ) &&
                                !environment.global_veriable.ho_employees_type_id.includes(
                                  m.employee_type_id
                                )) ||
                                !environment.global_veriable.managerLevelDeg.includes(
                                  user.designation
                                ))
                          )}
                          //   readOnly={!!department ? edit_flag : !edit_flag}
                          keys={"employee_type_id"}
                          label={"employee_type_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), setValue("project_id", null)
                          )}
                          placeholder={"Search Employee Type"}
                          value={!!value ? value : ""}
                          errors={!!errors["employee_type_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          {!!user &&
            !!watch("employee_type_id") &&
            environment.global_veriable.ho_employees_type_id.includes(
              user.employee_detail.employee_type_id
            ) &&
            !environment.global_veriable.ho_employees_type_id.includes(
              +watch("employee_type_id")
            ) && (
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Project
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"project_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={project_list}
                          //   readOnly={!!department ? edit_flag : !edit_flag}
                          keys={"project_id"}
                          label={"project_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Search Project"}
                          value={!!value ? value : ""}
                          errors={!!errors["project_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

          <Grid item xs={6}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 8, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Select Month
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"month"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <DatePicker
                      views={["year", "month"]}
                      value={value}
                      InputProps={{
                        onBlur: onBlur,
                        size: "small",
                        sx: {
                          width: "112%",
                          marginLeft: 1,
                        },
                      }}
                      onChange={
                        (newValue) => (
                          onChange(newValue), setSelectedDate(newValue)
                        )
                        // PayrollAvailableEmployeeSuccessAction([]),
                        // SetapprovalList([])
                      }
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!!user &&
          user.designation !=
          environment.global_veriable.managerLevelDeg.find((m) => +m) &&
          user.designation !=
          environment.global_veriable.officialEmployee.find((n) => +n)}
        {(!!watch("organization_id") ||
          user?.project_detail.find((r) => r.organization_id)
            ?.organization_id) &&
          !!watch("month") &&
          (!!watch("employee_type_id") ||
            user?.employee_detail.employee_type_id ||
            !!watch("project_id")) && (
            <Grid container justifyContent={"center"} direction={"row"}>
              <Grid item xs={6}>
                <Grid container direction={"column"}>
                  {/* <Grid
                    item
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ backgroundColor: "#0060FF", borderTopLeftRadius: 5 }}
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        // color: "#626974",
                        // paddingLeft: "20%",
                        padding: 12,
                      }}
                    >
                      Download Template
                    </Typography>
                  </Grid> */}
                  <Grid item sx={{ mt: 2 }}>
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      style={{ marginTop: 15, marginBottom: 30 }}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "0%",
                          }}
                        >
                          Download Template
                          {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          variant="outlined"
                          component="label"
                          color="primary"
                          // onClick={handleClose}
                          startIcon={<CloudDownloadIcon />}
                          onClick={handleClickOpenDownload}
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              // color: "#FFFF",
                              paddingLeft: 10,
                              // textAlign: "center"
                            }}
                          >
                            Download
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction={"column"}>
                  {/* <Grid
                    item
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ backgroundColor: "#0060FF", borderTopRightRadius: 5 }}
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",
                        // color: "#626974",
                        padding: 12,
                      }}
                    >
                      Upload Attendance
                    </Typography>
                  </Grid> */}

                  <Grid item sx={{ mt: 2 }}>
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      style={{ marginTop: 15, marginBottom: 30 }}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            padding: 12,
                          }}
                        >
                          Upload Attendance
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Button
                          // type="submit"
                          variant="outlined"
                          component="label"
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                        >
                          <input
                            hidden
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            multiple
                            type="file"
                            value={uploadField}
                            onChange={handleClickUpload}
                            ref={fileInputRef}
                          />
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              // color: "#FFFF",
                              paddingLeft: 10,
                              // textAlign: "center"
                            }}
                          >
                            Upload
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid
          container
          justifyContent="start"
          alignItems="center"
          style={{ marginTop: 15, marginBottom: 30 }}
        >
          <Grid item xs={5} justifyContent="space-between" alignItems="start">
            <Typography
              style={{
                fontWeight: "500",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#626974",
                paddingLeft: "20%",
              }}
            >
              Upload Attendance

            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Button
              // type="submit"
              variant="outlined"
              component="label"
              color="primary"
              // onClick={handleClose}
              // onClick={(event:any,data: any)=>(handleClickUpload(event,
              //   data.organization_id
              //   ))}
              // onClick={handleSubmit(handleClick)}
              startIcon={<CloudUploadIcon />}
            >
              <input
                hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple
                type="file"
                onChange={(event: any) => handleClickUpload(event)}
              />
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Upload
              </Typography>
            </Button>
          </Grid>
        </Grid> */}
            </Grid>
          )}
      </Paper>
      <Modal
        open={errorModal}
        onClose={handleErrorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper
          elevation={2}
          sx={{
            marginTop: 10,
            marginInline: 15,
            marginBottom: 10,
            minWidth: "50%",
            minHeight: "40%",
            maxHeight: "90%",
            overflowY: "scroll",
            // position: "absolute",
            // marginLeft: `calc(40% - ${Card}px)`,
          }}
        >
          <Grid
            container
            direction={"column"}
            display={"flex"}
          // justifyContent={"center"}
          //style={{ padding: 1, margin: 2 }}
          >
            <Grid
              container
              style={{
                padding: 10,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Grid item>
                <Typography style={{ color: "#fff" }}>Error Details</Typography>
              </Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: 1, paddingBottom: 4 }}>
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {columns.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!errorUploaded &&
                      errorUploaded.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.row_no}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {row.employee_id}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            {row.message}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => handleErrorModalClose()}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      {/* <Modal
        open={errorModal2}
        onClose={handleErrorModalClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper
          elevation={2}
          sx={{
            marginTop: 10,
            marginInline: 15,
            marginBottom: 10,
            minWidth: "50%",
            minHeight: "30%",
            // position: "absolute",
            // marginLeft: `calc(40% - ${Card}px)`,
          }}
        >
          <Grid
            container
            direction={"column"}
            display={"flex"}
            // justifyContent={"center"}
            //style={{ padding: 1, margin: 2 }}
          >
            <Grid
              container
              style={{
                padding: 10,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Grid item>
                <Typography style={{ color: "#fff" }}>Error Details</Typography>
              </Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: 1, paddingBottom: 4 }}>
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {columns2.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!errorUploaded2 &&
                      // errorUploaded2.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          // key={row.errorCode}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {errorUploaded2}
                          </StyledTableCell>
                        </StyledTableRow>
                      // ))
                      }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => handleErrorModalClose2()}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal> */}
    </>
  );
};

export default AttendanceUploadView;

interface AttendanceUploadViewProps {
  organization_list: OrganizationALlList[];
  project_list: ProjectList[];
  customer_list: CustomerList[];
  employee_type_list: EmployeeTypeList[];
  accessToken?: string;
  user?: userDetails;
  AttendanceDetailPayloadSuccessActions?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
