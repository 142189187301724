import { StoreState } from "../../models/reduxModels";

const InitialState: StoreState = {
  user: {
    userDetails: undefined,
    sideNavClose: false,
  },
  error: {
    error: undefined,
  },
  loading: {
    count: 0,
    message: "",
    type: 0,
  },
  organization: {
    organization_filter_list: [],
    department_list: [],
    service_list: [],
    designation_list: [],
    employee_timing_list: [],
    employee_type_list: [],
    component_list: [],
    domain_list: [],
    salary_priod: [],
    salary_structure_list: [],
    salary_structure_edit: undefined,
    post_list: [],
    calculation_type: [],
    operator: [],
    global_key: [],
    operator_tye: [],
    currentMonthBirthday: [],
    createEmpOnMonth: [],
    salary_compo_threshold: [],
  },
  customer: {
    customer_list: [],
    project_list: [],
    division_list: [],
    subdivision_list: [],
    project_detail: undefined,
    project_attendance_template: [],
    project_levels: undefined,
    project_dashboard_details: undefined,
    attendence_status: [],
    project_dashboard_batch_details: [],
    project_employee_payroll_details: [],
    level_hierachy: undefined,
    set_level_value: [],
    level_tree: undefined,
    available_emp_list: [],
    project_budget_list: [],
    project_unbuget_list: [],
    project_payment_list: [],
    state_list: [],
    budget_details: undefined,
    level_master: [],
    location_levels: [],
    levels_details: [],
    consolidated_report: undefined,
    month_wise_emp_salary_Budgeting: [],
    emp_salary_month_projectwise: [],
    pending_project_closure: [],
    completed_project_list: []
  },
  employee: {
    event_list: [],
    refferal_list: [],
    refferal_count: undefined,
    employee_detail: undefined,
    ReportingManagerList: [],
    filter: [],
    candidate_list: [],
    candidate_count: undefined,
    attendence_salary_type: [],
    employee_list: [],
    employee_count: undefined,
    action_event_list: [],
    //employee_all_details: [],
    bank_list: [],
    employeeDetails: undefined,
    present_address: undefined,
    permanent_address: undefined,
    documents_details: [],
    education_details: [],
    experience_details: [],
    ProjectAssociationWithLevelName: [],
    designation_name_with_details: [],
    EmployeeAttendanceReport: [],
    SalaryDetail: [],
    SalaryHistory: [],
    candidateBulkUploadList: [],
    professional_details: [],
    employee_pdf_list: [],
    reporting_manager_details: []
  },
  attendance: {
    // attendance_dashboard: undefined,
    attendance_dashboard: [],
    pending_approval: [],
    upload_attendance_view: [],
    employee_list: [],
    total_count: undefined,
    attendance_approve_list: [],
    attendence_fields: [],
    attendance_timesheet_list: [],
  },
  payroll: {
    ApplicableEmployeeList: [],
    batch_list: [],
    batch_detail: [],
    CalculatedComponenet: [],
    BatchByDeg: [],
  },
  userManagement: {
    access_control_master: [],
    all_role_master: [],
    role_get_by_id: undefined,
    user_role_mapping_list: undefined,
  },
  leave: {
    leave_type: undefined,
    leave_types: [],
    holiday_list: [],
    holiday: undefined,
    leave_designation: [],
    leave_type_designation_wise: [],
    selected_organization: undefined,
    allLeaveManagement: [],
    designationWiseLeave: [],
    designationWiseEmp: [],
    leave_amount_data: undefined,
    calculated_leave_days: undefined,
    leave_details_data: [],
    leave_approval_list: [],
    leave_calendar: [],
    total_count: undefined,
    all_leave_list: [],
    leaveDetails: [],
    address: undefined,
    employee_holiday_list: [],
    natural_holidays: [],
  },
  timingSheet: {
    timing_sheet_list: undefined,
    employeeList: [],
    timesheet_history: [],
    time: [],
  },
  reports: {
    list: [],
    payrollReport: [],
    totalSalCount: undefined,
    reportForPDF: [],
    totalSalCountForPDF: undefined,
    count: undefined,
  },
  promotion: {
    promotion_list: undefined,
    view_promotion: [],
    pending_promtions: [],
    emp_list_promotions: undefined
  },
  termination: {
    emp_available_for_termination: undefined,
    termination_initiated_emp_list: [],
    terminated_emp_list: undefined,
  },
  invoice:{
    invoice_list_details: undefined,
  }
};

export default InitialState;
