import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  bankList,
  ReportingManagerList,
  ViewEmployee,
} from "../../../models/employeeModel";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import { baseUrl, environment } from "../../../environment";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import { userDetails } from "../../../models/userModels";
import Axios from "axios";
import FormFields from "./FormFields";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import {
  ProjectLevels,
  StateList,
  ViewProject,
} from "../../../models/customerModel";
import { getValue } from "@mui/system";
const FileDownload = require("js-file-download");
function EmployeeDetailsView({
  edit_candidate,
  employee_detail,
  organization_list,
  designation_list,
  employee_type_list,
  department_list,
  employee_timing_list,
  customer_list,
  project_list,
  post_list,
  OrganizationSelected,
  user,
  Submit,
  GetReportingManager,
  ReportingManagerList,
  salary_structure_list,
  OnChangeCustomer,
  OnprojectSelect,
  project_detail,
  SlaryStructureChange,
  salary_structure,
  attendence_salary_type,
  bank_list,
  project_levels,
  id2,
  state_list,
}: EmployeeDetailsViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [applicabledeg, setapplicatbleDeg] = useState<number[]>([]);
  const [documents, SetDocument] = useState<any[]>([]);
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);
  const [submit_payload, SetsubmitPayload] = useState<any>();

  const [addEduDetail, setaddEduDetail] = useState<any>([
    {
      course_name: null,
      board_name: null,
      // remarks: null,
      subject: null,
      passing_year: null,
      total_marks: null,
      marks_obtained: null,
    },
  ]);
  const [addProfessionalDetails, setAddProfessionalDetails] = useState<any>([
    {
      course_name: null,
      start_date: null,
      end_date: null,
      remarks: null,
    },
  ]);
  const [addExpDetail, setaddExpDetail] = useState<any>([
    {
      company_name: null,
      project_name: null,
      designation: null,
      started_at: null,
      ended_at: null,
      ctc: null,
      remarks: null,
    },
  ]);

  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const onSubmit = (data: any) => {
    let payload: any = {
      ...employee_detail,
      ...data,
      present_address: {
        ...employee_detail?.present_address,
        address_id: employee_detail?.present_address.address_id,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        city: data.city,
        district: data.district,
        state: data.state,
        country: data.country,
        pin: data.pin,
      },
      permanent_address: {
        ...employee_detail?.permanent_address,
        address_id: employee_detail?.permanent_address.address_id,
        address_line1: data.paddress_line1,
        address_line2: data.paddress_line2,
        city: data.pcity,
        district: data.pdistrict,
        state: data.pstate,
        country: data.pcountry,
        pin: data.ppin,
      },
      experience:
        addExpDetail.length == 1
          ? !!addExpDetail[0].company_name
            ? addExpDetail
            : []
          : addExpDetail.length > 1
          ? addExpDetail
          : [],
      education:
        addEduDetail.length == 1
          ? !!addEduDetail[0].course_name
            ? addEduDetail
            : []
          : addEduDetail.length > 1
          ? addEduDetail
          : [],
      professional_details:
        addProfessionalDetails.length == 1
          ? !!addProfessionalDetails[0].course_name
            ? addProfessionalDetails
            : []
          : addProfessionalDetails.length > 1
          ? addProfessionalDetails
          : [],
      documents_details: [],
    };
    delete payload.address_line1;
    delete payload.address_line2;
    delete payload.city;
    delete payload.district;
    delete payload.state;
    delete payload.country;
    delete payload.pin;
    delete payload.paddress_line1;
    delete payload.paddress_line2;
    delete payload.pcity;
    delete payload.pdistrict;
    delete payload.pstate;
    delete payload.pcountry;
    delete payload.ppin;
    if (!!data.aadhar_no && data.aadhar_no != "") {
      payload.documents_details.push({
        document_id: employee_detail?.documents_details.find(
          (m) => m.document_type === "AADHAR"
        )?.document_id,
        document_type: "AADHAR",
        document_no: data.aadhar_no,
        document_image: "",
      });
    }
    if (!!data.epic_no && data.epic_no != "") {
      payload.documents_details.push({
        document_id: employee_detail?.documents_details.find(
          (m) => m.document_type === "VOTAR"
        )?.document_id,
        document_type: "VOTAR",
        document_no: data.epic_no,
        document_image: "",
      });
    }
    if (!!data.pan_no && data.pan_no != "") {
      payload.documents_details.push({
        document_id: employee_detail?.documents_details.find(
          (m) => m.document_type === "PAN"
        )?.document_id,
        document_type: "PAN",
        document_no: data.pan_no,
        document_image: "",
      });
    }
    payload.documents_details.forEach((m: any) => {
      m.document_image = !!documents.find(
        (n) => m.document_type == n.document_type
      )
        ? documents.find((n) => m.document_type == n.document_type)
            .document_image
        : "";
    });
    // if (!!payload.aadhar_no) {
    //   payload.documents_details.push({
    //     document_id: employee_detail?.documents_details.find(
    //       (m) => m.document_type === "AADHAR"
    //     )?.document_id,
    //     document_type: "AADHAR",
    //     document_no: payload.aadhar_no,
    //     document_image: "",
    //     candidate_id: +payload.candidate_id,
    //   });
    // }
    // if (!!payload.epic_no) {
    //   payload.documents_details.push({
    //     document_id: employee_detail?.documents_details.find(
    //       (m) => m.document_type === "VOTAR"
    //     )?.document_id,
    //     document_type: "VOTAR",
    //     document_no: payload.epic_no,
    //     document_image: "",
    //     candidate_id: +payload.candidate_id,
    //   });
    // }
    // if (!!payload.pan_no) {
    //   payload.documents_details.push({
    //     document_id: employee_detail?.documents_details.find(
    //       (m) => m.document_type === "PAN"
    //     )?.document_id,
    //     document_type: "PAN",
    //     document_no: payload.pan_no,
    //     document_image: "",
    //     candidate_id: +payload.candidate_id,
    //   });
    // }
    // payload.documents_details.forEach((m: any) => {
    //   m.document_image = !!documents.find(
    //     (m) => m.document_type == m.document_type
    //   )
    //     ? documents.find((m) => m.document_type == m.document_type)
    //         .document_image
    //     : "";
    // });
    delete payload.aadhar_no;
    delete payload.epic_no;
    delete payload.pan_no;
    if (!!salary_structure?.component) {
      let salary_detail: any = {};

      salary_structure?.component.map((m) => {
        if (!m.calculation) {
          salary_detail[m.compent_id] = payload[m.compent_id];
          delete payload[m.compent_id];
        }
      });
      payload.salary_assignment = {
        salary_structure_id: payload.salary_structure_id,
        components: salary_detail,
        attendence_salary_type_id: payload.attendence_salary_type_id,
      };
      delete payload.attendence_salary_type_id;
      delete payload.salary_structure_id;
    }
    if (!!employee_detail?.projects && employee_detail?.projects.length > 0) {
      payload.projects = [
        {
          ...employee_detail?.projects[0],
          customer_id: payload.customer_id,
          project_id: payload.project_id,
          level1_id: payload.level1_id,
          level2_id: payload.level2_id,
          level3_id: payload.level3_id,
          level4_id: payload.level4_id,
          level5_id: payload.level5_id,
          post_id: payload.post_id,
          reporting_manager: payload.reporting_manager,
        },
      ];
      delete payload.customer_id;
      delete payload.project_id;
      delete payload.level1_id;
      delete payload.level2_id;
      delete payload.level3_id;
      delete payload.level4_id;
      delete payload.level5_id;
      delete payload.post_id;
      delete payload.reporting_manager;
      delete payload.salary_detail;
    }
    let additional_field: any[] = [];
    let has_error: boolean = false
    if (
      !!project_detail &&
      project_detail.project_fields &&
      project_detail.project_fields.length > 0
    ) {
      project_detail.project_fields.forEach((m) => {
        if (!!payload[m.field_name.split(" ").join("_").replace("'", "")]) {
          let addlf1: any = payload[m.field_name.split(" ").join("_").replace("'", "")];
          let addlf2: any = payload[m.field_name.split(" ").join("_").replace("'", "")+"_file"];
          if(!!m.uploadRequired && !addlf2){
            has_error = true
            enqueueSnackbar(`${m.field_name} document is required.`, {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            return true
          } else {
          additional_field.push({
            [m.field_name.split(" ").join("_").replace("'", "")]: addlf1,
            [m.field_name.split(" ").join("_").replace("'", "")+"_file"]: !!addlf2 ? addlf2: null,
          });
          delete payload[m.field_name.split(" ").join("_").replace("'", "")];
          delete payload[m.field_name.split(" ").join("_").replace("'", "")+"_file"];
        } 
        }
      });
    }
    payload.additional_field = additional_field;
    if (
      payload.documents_details.length <= 0 ||
      payload.documents_details.find((m: any) => m.document_type == "AADHAR")
        ?.document_image == ""
    ) {
      enqueueSnackbar("Aadhar document is mandatory", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }else if(!has_error){
      Submit(payload);
    }
  };

  useEffect(() => {
    if (project_detail?.projectmanager_id) {
      setapplicatbleDeg(project_detail?.applicable_designation);
    }
  }, [project_detail]);

  const fileHandleEvent = (event: any, type: string) => {
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${
        event.target.files[0].name
      }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `NEW_CAD`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            let doc = JSON.parse(JSON.stringify(documents));
            if (!!doc.find((m: any) => m.document_type == type)) {
              doc.find((m: any) => m.document_type == type).document_image =
                file_name;
            } else {
              doc.push({
                document_type: type,
                document_no: "",
                document_image: file_name,
              });
            }

            SetDocument(doc);
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
  };
  // const downloadDoc = (type: string) => {
  //   if (
  //     !!employee_detail &&
  //     employee_detail.documents_details &&
  //     employee_detail.documents_details.find((m) => m.document_type === type)
  //   ) {
  //     let doc = employee_detail.documents_details.find(
  //       (m) => m.document_type === type
  //     );
  //     Axios({
  //       url: baseUrl + environment.urls.downloadFileUrl,
  //       method: "POST",
  //       responseType: "blob", // Important
  //       headers: {
  //         Authorization: "Bearer " + user?.accessToken,
  //       },
  //       data: {
  //         employee_id: `NEW_CAD`,
  //         fileName: doc?.document_image,
  //       },
  //     })
  //       .then((response) => {
  //         FileDownload(response.data, doc?.document_image);
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar("Something went wrong!", {
  //           variant: "error",
  //         });
  //       });
  //   }
  // };
  const downloadDoc = (type: string) => {
    if (!!documents.find((m: any) => m.document_type == type).document_image) {
      let doc = documents.find((m: any) => m.document_type == type);
      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: `NEW_CAD`,
          fileName: doc?.document_image,
        },
      })
        .then((response) => {
          FileDownload(response.data, doc?.document_image);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };
  useEffect(() => {
    if (!!employee_detail) {
      OrganizationSelected(employee_detail.organization_id);
      GetReportingManager(
        environment.global_veriable.ho_employees_type_id.includes(
          employee_detail.employee_type_id
        )
          ? null
          : employee_detail.projects[0].project_id,
        employee_detail.organization_id
      );
      if (!!employee_detail.projects && employee_detail.projects.length > 0) {
        OnChangeCustomer(employee_detail.projects[0].customer_id);
        OnprojectSelect(employee_detail.projects[0].project_id);
      }

      if (
        !!employee_detail.salary_detail &&
        employee_detail.salary_detail.length > 0
      ) {
        SlaryStructureChange(
          employee_detail.salary_detail[0].salary_structure_id
        );
      }
      setTimeout(() => {
        setValue("candidate_name", employee_detail.candidate_name);
        setValue("father_name", employee_detail.father_name);
        setValue("email_id", employee_detail.email_id);
        setValue("mobile_no", employee_detail.mobile_no);
        setValue("date_of_birth", employee_detail.date_of_birth);
        setValue("gender", employee_detail.gender);
        setValue("marital_status", employee_detail.marital_status);
        setValue(
          "aadhar_no",
          employee_detail.documents_details.find(
            (m) => m.document_type == "AADHAR"
          )?.document_no
        );
        setValue(
          "epic_no",
          employee_detail.documents_details.find(
            (m) => m.document_type == "VOTAR"
          )?.document_no
        );
        setValue(
          "pan_no",
          employee_detail.documents_details.find(
            (m) => m.document_type == "PAN"
          )?.document_no
        );
        setValue("religion", employee_detail.religion);
        setValue(
          "address_line1",
          employee_detail.present_address.address_line1
        );
        setValue(
          "address_line2",
          employee_detail.present_address.address_line2
        );
        setValue("district", employee_detail.present_address.district);
        setValue("city", employee_detail.present_address.city);
        setValue("state", employee_detail.present_address.state);
        setValue("country", employee_detail.present_address.country);
        setValue("pin", employee_detail.present_address.pin);
        setValue(
          "paddress_line1",
          employee_detail.permanent_address.address_line1
        );
        setValue(
          "paddress_line2",
          employee_detail.permanent_address.address_line2
        );
        setValue("pdistrict", employee_detail.permanent_address.district);
        setValue("pcity", employee_detail.permanent_address.city);
        setValue("pstate", employee_detail.permanent_address.state);
        setValue("pcountry", employee_detail.permanent_address.country);
        setValue("ppin", employee_detail.permanent_address.pin);
        setValue("uan_no", employee_detail.uan_no);
        setValue("esic_no", employee_detail.esic_no);
        setValue("designation_id", employee_detail.designation_id);
        setValue("account_no", employee_detail.account_no);
        setValue("ifsc_no", employee_detail.ifsc_no);
        setValue("bank_name", employee_detail.bank_name);
        setValue("branch_name", employee_detail.branch_name);
        setValue("shift_id", employee_detail.shift_id);
        setValue("ifsc_no", employee_detail.ifsc_no);
        setValue("department_id", employee_detail.department_id);
        setValue("organization_id", employee_detail.organization_id);
        setValue("employee_type_id", employee_detail.employee_type_id);
        setValue("base_location", employee_detail.base_location);
        setValue("blood_group", employee_detail.blood_group);
        setValue("emergency_contact", employee_detail.emergency_contact);
        setValue("relation", employee_detail.relation);
        if (
          !!employee_detail.projects &&
          employee_detail.projects.length > 0 &&
          employee_detail.projects[0]?.employee_supervisor_id
        ) {
          setValue(
            "reporting_manager",
            employee_detail.projects[0].employee_supervisor_id
          );
        }
        if (
          employee_detail.salary_detail &&
          employee_detail.salary_detail.length > 0
        ) {
          setValue(
            "salary_structure_id",
            employee_detail.salary_detail[0].salary_structure_id
          );
          setValue(
            "attendence_salary_type_id",
            employee_detail.projects[0].attendence_salary_type_id
          );
          employee_detail.salary_detail.forEach((m) => {
            setValue(m.compent_id.toString(), m.componet_calculated_value);
          });
        }
        setaddEduDetail(employee_detail.education);
        setaddExpDetail(employee_detail.experience);
        setAddProfessionalDetails(employee_detail.professional_details);
        SetDocument(employee_detail.documents_details);
      }, 1000);
    }
  }, [employee_detail]);

  const AddlfileHandleEvent= (event: any, type: string)=>{
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${event.target.files[0].name
      }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `NEW_CAD`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            setValue(type, file_name);
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
    
  }
    const downloadImage = (file_name: any) => {
      if(!!file_name){
        console.log(file_name);
        
        Axios({
          url: baseUrl + environment.urls.downloadFileUrl,
          method: "POST",
          responseType: "blob", // Important
          headers: {
            Authorization: "Bearer " + user?.accessToken,
          },
          data: {
            employee_id: `NEW_CAD`,
            fileName: file_name,
          },
        })
          .then(async (response) => {
            await FileDownload(response.data, file_name);
          })
          .catch((error) => {
            enqueueSnackbar("Something went wrong!", {
              variant: "error",
            });
          });
      }
  };
  return (
    <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
            style={{ marginLeft: 15 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            Candidate Details
            {/* {!!department ? "Department Details" : "Add Department"} */}
          </Typography>
        </Grid>
      </Grid>
      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "98%",
          marginLeft: "1%",
          marginRight: "1%",
        }}
      ></Divider>
      <Dialog
        open={submit_flag}
        onClose={() => setsubmitFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update this candidate's application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setsubmitFlag(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => Submit(submit_payload)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <form>
        <FormFields
          applicabledeg={applicabledeg}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          department_list={department_list}
          designation_list={designation_list}
          documents={documents}
          post_list={post_list}
          project_list={project_list}
          addEduDetail={addEduDetail}
          user={user}
          fileHandleEvent={fileHandleEvent}
          watch={watch}
          addExpDetail={addExpDetail}
          employee_type_list={employee_type_list}
          customer_list={customer_list}
          employee_timing_list={employee_timing_list}
          organization_list={organization_list}
          OrganizationSelected={OrganizationSelected}
          ReportingManagerList={ReportingManagerList}
          employee_detail={employee_detail}
          salary_structure_list={salary_structure_list}
          setaddEduDetail={setaddEduDetail}
          SetDocument={SetDocument}
          setaddExpDetail={setaddExpDetail}
          OnChangeCustomer={(data: number) =>
            OnChangeCustomer(data, +getValues("organization_id"))
          }
          OnprojectSelect={OnprojectSelect}
          project_detail={project_detail}
          SlaryStructureChange={SlaryStructureChange}
          salary_structure={salary_structure}
          attendence_salary_type={attendence_salary_type}
          downloadDoc={downloadDoc}
          edit_candidate={edit_candidate}
          bank_list={bank_list}
          project_levels={project_levels}
          setValue={setValue}
          getValues={getValues}
          id2={id2}
          state_list={state_list}
          addProfessionalDetails={addProfessionalDetails}
          setAddProfessionalDetails={setAddProfessionalDetails}
          AddlfileHandleEvent={AddlfileHandleEvent}
          downloadImage={downloadImage}
        />
      </form>
    </Paper>
  );
}

export default EmployeeDetailsView;

interface EmployeeDetailsViewProps {
  edit_candidate: boolean;
  employee_detail?: ViewEmployee;
  organization_list: any[];
  designation_list: any[];
  employee_type_list: any[];
  department_list: any[];
  employee_timing_list: any[];
  customer_list: any[];
  project_list: any[];
  post_list: any[];
  OrganizationSelected?: any;
  user?: userDetails;
  Submit?: any;
  GetReportingManager?: any;
  ReportingManagerList: ReportingManagerList[];
  salary_structure_list: SalaryStructureList[];
  OnChangeCustomer?: any;
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  BeginApiCallAction?: any;
  CreateCandidateSuccessAction?: any;
  bank_list: bankList[];
  project_levels?: ProjectLevels;
  id2?: number;
  state_list: StateList[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
