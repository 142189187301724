import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { environment } from "../../environment";
import PromotionList from "./PromotionList";
import AddPromotion from "./AddPromotion";
import PendingPromotionList from "./PendingPromotionList";
import PromotionBatchDetailView from "./PromotionDetails/PromotionBatchDetailView";
import PromotionDetails from "./PromotionDetails";

const Promotion = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="promotion-list" element={<PromotionList />} />
        <Route path="add-promotion" element={<AddPromotion />} />
        <Route path="approve-promotion" element={<PendingPromotionList />} />
        <Route path="promotion-details-view/:id" element={<PromotionDetails />} />


        <Route
          path="/"
          element={
            <Navigate to={`/${pathname.split("/")[1]}/promotion-list`} />
          }
        />
      </Routes>
    </>
  );
};

export default Promotion;
