import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PermanentAddress,
  PresentAddress,
} from "../../../models/employeeModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import EmployeeAddressDetailsPopover from "./EmployeeAddressDetailsPopover";
import { StateList } from "../../../models/customerModel";

function EmployeeAddressDetailsView({
  present_address,
  permanent_address,
  Submit,
  state_list
}: EmployeeAddressDetailsViewProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onSubmit = (data: any) => {
    console.log(data);
    let payload: any = {
      // ...data,
      permanent_address: {
        address_id: permanent_address?.address_id,
        address_line1: data.paddress_line1,
        address_line2: data.paddress_line2,
        city: data.pcity,
        state: data.pstate,
        country: data.pcountry,
        pin: data.ppin,
        district: data.pdistrict,
      },
      present_address: {
        address_id: present_address?.address_id,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        city: data.city,
        state: data.state,
        country: data.country,
        pin: data.pin,
        district: data.district,
      },
    };
    Submit(payload);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            //paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  //mb: 10,
                }}
              >
                Address Details
              </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="end"
            alignItems="center"
            padding={0}
          >
            <Tooltip title="Edit">
              <IconButton onClick={handleClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <EmployeeAddressDetailsPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              permanent_address={permanent_address}
              present_address={present_address}
              SubmitPopData={onSubmit}
              state_list={state_list}
            />
          </Grid>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: 17,
              fontFamily: "Poppins, sans-serif",
              textAlign: "left",
              mt: -4,
              // mb: 1,
              color: "#626974",
            }}
          >
            Present Address
          </Typography>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Flat,House No.,Building,Apartment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.address_line1}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Area,Street,Sector,Village
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {!!present_address?.address_line2
                      ? present_address?.address_line2
                      : "N/A"}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    City
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.city}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    District
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.district}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    State
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.state}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.country}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Pincode
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {present_address?.pin}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: 17,
              fontFamily: "Poppins, sans-serif",
              textAlign: "left",
              mt: 0.5,
              mb: 0.5,
              color: "#626974",
            }}
          >
            Permanent Address
          </Typography>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Flat,House No.,Building,Apartment
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.address_line1}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Area,Street,Sector,Village
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {!!present_address?.address_line2
                      ? permanent_address?.address_line2
                      : "N/A"}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    City
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.city}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    District
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.district}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    State
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.state}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.country}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={1}
              paddingBottom={2}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "90%",
                  bgcolor: "#F1F6F5",
                  pt: "1%",
                  pb: "1%",
                  borderRadius: 3,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      pl: "5%",
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                    }}
                  >
                    Pincode
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}>
                    {permanent_address?.pin}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="fullWidth" flexItem />
    </>
  );
}

export default EmployeeAddressDetailsView;

interface EmployeeAddressDetailsViewProps {
  present_address?: PresentAddress;
  permanent_address?: PermanentAddress;
  Submit?: any;
  state_list: StateList[];
}
