import {
  Paper,
  Grid,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { ProjectClosurePendingList } from "../../../models/customerModel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProjectClosureApprovalSection from "./ProjectClosureApprovalSection";

function ProjectClosurePendingApprovalView({
  pending_project_closure,
  FinalApprovedSubmit
}: ProjectClosurePendingApprovalViewProps) {
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<ProjectClosurePendingList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openProjectClosurePopup, setOpenProjectClosurePopup] =
    React.useState(false);
  const [openProjectClosureSelectedList, setOpenProjectClosureSelectedList] =
    useState("");
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!pending_project_closure
      ? pending_project_closure.filter((row: ProjectClosurePendingList) => {
          return (
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.initiatedBy?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!pending_project_closure) {
      cancelSearch();
    }
  }, [pending_project_closure]);
  const column1: any[] = [
    "Project Name",
    "Initiated By",
    "Project Start Date",
    "Project End Date",
    "Status",
    "Action",
  ];
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const AcceptPendingProjectClosure = (data: any) => {
    setOpenProjectClosurePopup(true);
    setOpenProjectClosureSelectedList(data);
  };
  return (
    <>
      <Paper
        elevation={5}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, paddingTop: 2 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.project_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="center" key={column1[1]}>
                              {row.initiatedBy +
                                " (" +
                                row.initiated_employee_name +
                                ")"}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {row.project_start_date
                                ? moment(row.project_start_date)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[4]}>
                              {row.project_end_date
                                ? moment(row.project_end_date)
                                    .format("DD-MM-YYYY")
                                    .toUpperCase()
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center" key={column1[6]}>
                              {row.eventStatus}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[7]}>
                              <Tooltip
                                title=" Edit & View"
                                onClick={() => AcceptPendingProjectClosure(row)}
                              >
                                <IconButton>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ProjectClosureApprovalSection
        open={openProjectClosurePopup}
        setOpen={setOpenProjectClosurePopup}
        openProjectClosureSelectedList={openProjectClosureSelectedList}
        FinalApprovedSubmit={FinalApprovedSubmit}
      />
    </>
  );
}

export default ProjectClosurePendingApprovalView;
interface ProjectClosurePendingApprovalViewProps {
  pending_project_closure: ProjectClosurePendingList[];
  FinalApprovedSubmit?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

