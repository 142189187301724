import React from "react";
import { connect } from "react-redux";
import { BatchByDeg } from "../../../models/payrollModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { GetBatchListByDegAction } from "../../../stores/actions/payrollActions";
import PendingApprovalView from "./PendingApprovalView";

const PendingApproval = ({
  BatchByDeg,
  GetBatchListByDegAction,
  user
}: PendingApprovalProps) => {
  React.useEffect(() => {
    GetBatchListByDegAction({ approverDesigId: user?.designation,eventType:8});
  }, []);
  return <PendingApprovalView 
  BatchByDeg={BatchByDeg}
  />;
};

const mapStateToProps = (state: StoreState) => {
  return {
    BatchByDeg: state.payroll.BatchByDeg,
    user: state.user.userDetails
  };
};

const mapDispatchToProps = {
  GetBatchListByDegAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(PendingApproval);
interface PendingApprovalProps {
  BatchByDeg: BatchByDeg[];
  GetBatchListByDegAction: any;
  user?: userDetails;
}
