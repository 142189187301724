import {
  Paper,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Divider,
  Switch,
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { ICreateLevelMaster } from "../../../../models/customerModel";
import { ILevelMaster } from "../../../../interfaces/projectDashboard.interface";
import { useSnackbar } from "notistack";

const ActionView: FC<IActionView> = ({
  AddRequest,
  levelMaster,
  editRequest,
}) => {
  const { action_type } = useParams();

  const [selectedLevel, setSelectedLevel] = useState<ILevelMaster | null>(null);
  console.log("selectedLevel : ", selectedLevel);
  useEffect(() => {
    if (Number(action_type) > 0) {
      const tlevelMaster = levelMaster.find(
        (self) => Number(self.level_key_id) === Number(action_type)
      );
      !!tlevelMaster && setSelectedLevel(tlevelMaster);
    } else {
      setSelectedLevel(null);
    }
    return () => {
      setSelectedLevel(null);
    };
  }, [action_type, levelMaster]);
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  console.log("master key : ", action_type);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  console.log("errors : ", errors);
  useEffect(() => {
    if (!!selectedLevel) {
      setValue("display_name", selectedLevel.display_name);
      setValue("level1_key_name", selectedLevel.level1_key_name);
      setValue("level1_key", selectedLevel.level1_key);
      setValue("level2_key_name", selectedLevel.level2_key_name);
      setValue("level2_key", selectedLevel.level2_key);
      setValue("level3_key_name", selectedLevel.level3_key_name);
      setValue("level3_key", selectedLevel.level3_key);
      setValue("level4_key_name", selectedLevel.level4_key_name);
      setValue("level4_key", selectedLevel.level1_key);
      setValue("level5_key_name", selectedLevel.level5_key_name);
      setValue("level5_key", selectedLevel.level5_key);
      setValue("level_key_id", action_type);
    }
  }, [selectedLevel, action_type]);

  const navigateBack = (url: any) => {
    navigate(url);
  };
  const BackToDashBoard = () => {
    navigate(-1);
    reset();
  };

  const onSubmit = (props: any) => {
    if (!props.level1_key_name || props.level1_key_name?.trim() === "") {
      enqueueSnackbar("Successfully Updated", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const formPayload = {
      display_name: props.display_name.trim(),
      level1_key_name: props.level1_key_name?.trim()
        ? props.level1_key_name?.trim()
        : null,
      level1_key: props.level1_key_name?.trim()
        ? props.level1_key_name?.trim().includes(" ")
          ? props.level1_key_name?.trim().split(" ").join("-")
          : props.level1_key_name?.trim()
        : null,
      level2_key_name: props.level2_key_name?.trim()
        ? props.level2_key_name?.trim()
        : null,
      level2_key: props.level2_key_name?.trim()
        ? props.level2_key_name?.trim().includes(" ")
          ? props.level2_key_name?.trim().split(" ").join("-")
          : props.level2_key_name?.trim()
        : null,
      level3_key_name: props.level3_key_name?.trim()
        ? props.level3_key_name?.trim()
        : null,
      level3_key: props.level3_key_name?.trim()
        ? props.level3_key_name?.trim().includes(" ")
          ? props.level3_key_name?.trim().split(" ").join("-")
          : props.level3_key_name?.trim()
        : null,
      level4_key_name: props.level4_key_name?.trim()
        ? props.level4_key_name?.trim()
        : null,
      level4_key: props.level4_key_name?.trim()
        ? props.level4_key_name?.trim().includes(" ")
          ? props.level4_key_name?.trim().split(" ").join("-")
          : props.level4_key_name?.trim()
        : null,
      level5_key_name: props.level5_key_name?.trim()
        ? props.level5_key_name?.trim()
        : null,
      level5_key: props.level5_key_name?.trim()
        ? props.level5_key_name?.trim().includes(" ")
          ? props.level5_key_name?.trim().split(" ").join("-")
          : props.level5_key_name?.trim()
        : null,
    };
    if (!selectedLevel) {
      const payload: { formPayload: ICreateLevelMaster } = {
        formPayload: formPayload as ICreateLevelMaster,
      };
      console.log("prps : ", payload);
      AddRequest(payload);
      BackToDashBoard();
    } else if (!!selectedLevel && action_type) {
      Object.assign(formPayload, { level_key_id: +action_type });
      console.log("formpayload : ", formPayload);
      editRequest({ formPayload, enqueueSnackbar });
      setTimeout(() => navigate(-1), 400);
    }
  };
  return (
    <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} marginTop={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
              }}
            >
              {!!selectedLevel ? "Level Details" : `Add Level`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ marginInline: 10, width: "90%" }}>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
            {!!selectedLevel && (
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <Switch
                  value={edit_flag}
                  onChange={(event) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>
                  Edit Level Details
                </Typography>
              </Grid>
            )}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Level Master Name
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"display_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      // type={"text"}
                      // id="standard-required"
                      // variant="outlined"
                      // className={"smaller"}
                      // style={{ width: "100%", marginLeft: "0.4%" }}
                      // size="small"
                      // inputProps={{
                      //     readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                      //   }}
                      // onChange={onChange}
                      // placeholder={"Level Master Name"}
                      // error={!!errors?.display_name}
                      // helperText={
                      //   !!errors?.display_name ? "Level Master Name required" : ""
                      // }
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100%", marginLeft: "0.4%" }}
                      size="small"
                      value={value}
                      onChange={onChange}
                      inputProps={{
                        readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                      }}
                      placeholder={"Level Master Name"}
                      error={!!errors?.display_name}
                      helperText={
                        !!errors?.display_name
                          ? "Level Master Name required"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Level One Name
                </Typography>
              </Grid>
              <Grid item xs={5.05}>
                <Controller
                  control={control}
                  name={"level1_key_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100%", marginLeft: "0.4%" }}
                      size="small"
                      value={value}
                      onChange={onChange}
                      inputProps={{
                        readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                      }}
                      placeholder={"Level One Name"}
                      error={!!errors?.level1_key_name}
                      helperText={
                        !!errors?.level1_key_name ? "Level1 Name required" : ""
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            {!!selectedLevel && !selectedLevel.level2_key_name ? (
              <></>
            ) : (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Level Two Name
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"level2_key_name"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: "0.4%" }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        inputProps={{
                          readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                        }}
                        placeholder={"Level Two Name"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {!!selectedLevel && !selectedLevel.level3_key_name ? (
              <></>
            ) : (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Level Three Name
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"level3_key_name"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: "0.4%" }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        inputProps={{
                          readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                        }}
                        placeholder={"Level Three Name"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {!!selectedLevel && !selectedLevel.level4_key_name ? (
              <></>
            ) : (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Level Four Name
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"level4_key_name"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: "0.4%" }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        inputProps={{
                          readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                        }}
                        placeholder={"Level Four Name"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {!!selectedLevel && !selectedLevel.level5_key_name ? (
              <></>
            ) : (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Level Five Name
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"level5_key_name"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: "0.4%" }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        inputProps={{
                          readOnly: !!selectedLevel ? edit_flag : !edit_flag,
                        }}
                        placeholder={"Level Five Name"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              {((edit_flag && !selectedLevel) ||
                (!edit_flag && !!selectedLevel)) && (
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  <b>{!!selectedLevel ? "Update" : "Add"}</b>
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
interface IActionView {
  AddRequest: any;
  levelMaster: ILevelMaster[];
  editRequest: any;
}
export default ActionView;
