import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FilterList, Clear } from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { environment } from "../../../environment";
import EditIcon from "@mui/icons-material/Edit";
import { TimeSheetPendingApprovalList } from "../../../models/timingSheetModel";
import TimeingSheetApproverSection from "./TimeingSheetApproverSection";

const TimingSheetApproveView = ({
  time_sheet_pending_approvalList,
  ApprovedAction,
  UpdateMonthlyTimeSheetAction,
  ClearTimingSheetFromWorklistAction,
  goToTimingSheetFromWorklistAction,
}: TimingSheetApproveViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [rows, setRows] = useState<TimeSheetPendingApprovalList[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const open = Boolean(anchorEl);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!!time_sheet_pending_approvalList) {
      setRows(time_sheet_pending_approvalList);
    }
  }, [time_sheet_pending_approvalList]);

  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Mobile No.",
    "Month",
    "Created On",
    "Action Needed",
    "Action",
  ];
  const [timeSheetApprovalList, SetTimeSheetapprovalList] = useState<
    TimeSheetPendingApprovalList[]
  >([]);
  const [all_approve, Set_Approve] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const SelectRow = (checked: boolean, row: TimeSheetPendingApprovalList) => {
    if (checked) {
      if (
        timeSheetApprovalList.length > 0 &&
        JSON.stringify(
          timeSheetApprovalList[timeSheetApprovalList.length - 1].project_id
        ) != JSON.stringify(row.project_id) &&
        JSON.stringify(
          timeSheetApprovalList[timeSheetApprovalList.length - 1].date
        ) != JSON.stringify(row.date)
      ) {
        enqueueSnackbar(`Need to select same type action.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (timeSheetApprovalList.length >= 100) {
        enqueueSnackbar(`max limit 100 exceed`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (
        timeSheetApprovalList.length > 0 &&
        timeSheetApprovalList[timeSheetApprovalList.length - 1].project_name !=
          row.project_name &&
        timeSheetApprovalList[timeSheetApprovalList.length - 1].date != row.date
      ) {
        enqueueSnackbar(`Mutiselect is only allow for same type of project.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        let events: TimeSheetPendingApprovalList[] = JSON.parse(
          JSON.stringify(timeSheetApprovalList)
        );
        events.push(row);
        SetTimeSheetapprovalList(events);
      }
    } else {
      let events: TimeSheetPendingApprovalList[] = JSON.parse(
        JSON.stringify(timeSheetApprovalList)
      );
      events = events.filter((m) => m.project_id != row.project_id);
      if (events.length == 0) Set_Approve(false);
      SetTimeSheetapprovalList(events);
    }
  };
  useEffect(() => {
    const AllSelection = async () => {
      if (all_approve) {
        let appr: TimeSheetPendingApprovalList[] = [];
        for await (const [index, ap] of rows.entries()) {
          if (index == 0) {
            appr.push(ap);
          } else {
            if (
              JSON.stringify(appr[appr.length - 1].event_action_items) ==
                JSON.stringify(ap.event_action_items) &&
              appr[appr.length - 1].project_name == ap.project_name
            ) {
              appr.push(ap);
            }
          }
          if (appr.length >= 100) {
            break;
          }
        }
        SetTimeSheetapprovalList(appr);
        enqueueSnackbar(
          `${
            appr.length > 100 ? "100" : appr.length
          } same type of action row selected`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        SetTimeSheetapprovalList([]);
      }
    };
    AllSelection();
  }, [all_approve]);
  const [openTimingSheetPopup, setOpenTimingSheetPopup] = React.useState(false);
  const AcceptTimeSheet = () => {
    setOpenTimingSheetPopup(true);
  };
  const DomValue = (st: string) => {
    return environment.event_action_items.find((m) => m.key == st)?.value;
  };
  // const [submit_payload, SetsubmitPayload] = useState<any>();
  const [searched, setSearched] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!time_sheet_pending_approvalList
      ? time_sheet_pending_approvalList.filter(
          (row: TimeSheetPendingApprovalList) => {
            return (
              row?.employee_id
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.eventStatus
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.initiatedBy
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.employee_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          }
        )
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const [final_flag, setfinalFlag] = useState<boolean>(false);
  const [submit_flaga, setsubmitFlaga] = useState<number>(0);
  useEffect(() => {
    if (!!time_sheet_pending_approvalList) {
      cancelSearch();
    }
  }, [time_sheet_pending_approvalList]);

  const [approvePayload, setapprovePayload] = useState<any>();
  const FinalApprovedSubmit = (data: any) => {
    setapprovePayload(data);
    //console.log("data", data);
  };
  const AprrovedTimingSheetAction = (data: any) => {
    // console.log(data);
    ApprovedAction(data);
  };
  const handleGotoLog = (row: TimeSheetPendingApprovalList) => {
    goToTimingSheetFromWorklistAction({
      employee_id: row.employee_id,
      month: row.date,
    });
    console.log("month : ", row.date);
    navigate({
      pathname: "/timingsheet/log-activity/",
      search: "?isworklist=1",
    });
  };
  return (
    <Paper
      elevation={3}
      sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        // style={{ textAlign: "center", paddingTop: 8 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Dialog
        open={final_flag}
        onClose={() => setfinalFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              submit_flaga == 1 ? "reject" : "approve"
            } this Timing Sheet?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setfinalFlag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (
              AprrovedTimingSheetAction(approvePayload),
              setfinalFlag(false),
              setOpenTimingSheetPopup(false),
              SetTimeSheetapprovalList([])
            )}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <TimeingSheetApproverSection
        timeSheetApprovalList={timeSheetApprovalList}
        open={openTimingSheetPopup}
        setOpen={setOpenTimingSheetPopup}
        SetTimeSheetapprovalList={SetTimeSheetapprovalList}
        DomValue={DomValue}
        FinalApprovedSubmit={FinalApprovedSubmit}
        setfinalFlag={setfinalFlag}
        setsubmitFlaga={setsubmitFlaga}
      />
      <Grid container direction={"row"} marginTop={1}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="justify" key={""}>
                      <Checkbox
                        style={{ color: "#151e27" }}
                        checked={all_approve}
                        onChange={(event) => Set_Approve(event.target.checked)}
                      />
                    </StyledTableCell>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.empLifeEventId}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            <Checkbox
                              color="primary"
                              checked={
                                !!timeSheetApprovalList.find(
                                  (m) => m.project_id == row.project_id
                                ) &&
                                !!timeSheetApprovalList.find(
                                  (m) => m.date == row.date
                                ) &&
                                !!timeSheetApprovalList.find(
                                  (m) => m.empLifeEventId == row.empLifeEventId
                                )
                              }
                              onChange={(event) =>
                                SelectRow(event.target.checked, row)
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {/* <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.employee_id}`
                                )
                              }
                            > */}
                            {row.employee_id}
                            {/* </Link> */}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.employee_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.employee_mob_no}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            {moment(row.date).format("MMM,YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[5]}>
                            {moment(row.created_on).format("DD/MM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[6]}>
                            {row.event_action_items
                              ?.filter(
                                (item, ind) =>
                                  row.event_action_items.indexOf(item) === ind
                              )
                              .map(
                                (m, index) =>
                                  DomValue(m) + (!!DomValue(m) ? ", " : "")
                              )}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[7]}>
                            <Tooltip
                              title=" Edit & View"
                              //   onClick={() => (
                              //     AttendanceDetailPayloadSuccessAction({
                              //       attendence_salary_type_id:
                              //         row.attendence_salary_type_id,
                              //       organization_id: row?.organization_id,
                              //       employee_type_id: row.employee_type_id,
                              //       project_id: row?.project_id,
                              //       project_attendence_id:
                              //         row.project_attendence_id,
                              //       division_id: row.division_id,
                              //       page_index: 0,
                              //       page_size: 2,
                              //       // editable: 0
                              //     }),
                              //     navigate(`/attendance/attendance-details/1`)
                              //   )}
                            >
                              <IconButton onClick={() => handleGotoLog(row)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#1565C0",
            paddingX: 4,
            marginRight: 10,
          }}
          disabled={timeSheetApprovalList.length <= 0}
          onClick={() => AcceptTimeSheet()}
          variant="contained"
          color="primary"
          size="large"
        >
          Approve / Reject / Send Back
        </Button>
      </Grid>
    </Paper>
  );
};

export default TimingSheetApproveView;

interface TimingSheetApproveViewProps {
  time_sheet_pending_approvalList?: TimeSheetPendingApprovalList[];
  ApprovedAction?: any;
  UpdateMonthlyTimeSheetAction?: any;
  ClearTimingSheetFromWorklistAction?: any;
  goToTimingSheetFromWorklistAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
