import React from 'react'
import { connect } from 'react-redux';
import { AllProjectBudgetList, ProjectUnbudgetList } from '../../../models/customerModel';
import { StoreState } from '../../../models/reduxModels';
import { GetProjectBudgetListAction, GetProjectUnbudgetListAction } from '../../../stores/actions/customerAction';
import ProjectBudgetingView from './ProjectBudgetingView'

function ProjectBudgeting({GetProjectBudgetListAction,
  project_budget_list,
  }:ProjectBudgetingProps) {
    React.useEffect(() => {
      GetProjectBudgetListAction();
    }, []);
  return (
    <ProjectBudgetingView
    project_budget_list={project_budget_list}
    />
  )
}


const mapStateToProps = (state: StoreState) => {
  return {
    project_budget_list: state.customer.project_budget_list,
  };
};
const mapDispatchToProps = {
  GetProjectBudgetListAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBudgeting);
interface ProjectBudgetingProps {
  GetProjectBudgetListAction: any;
  project_budget_list: AllProjectBudgetList[],
}