import { Button, Typography, Grid } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
const ImageUploader = ({
  documents,
  fileHandleEvent,
  imageChange,
  type,
  downloadDoc,
  setchangeDocAadhar,
  setchangeDocPan,
  setchangeDocVotar
}: ImageUploaderPorps) => {
  function ChangeDoc(type: string) {
    if (type == "PAN") {
      setchangeDocPan(true);
    }
    else if (type == "VOTAR") {
      setchangeDocVotar(true);
    }
    else if (type == "AADHAR") {
      setchangeDocAadhar(true);
    }
  }

  return (
    <>
      {!documents.find(
        (m: any) => m.document_type == type && !!m.document_image
      ) ? (
        <>
          <Button
            variant="outlined"
            component="label"
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            <input
              hidden
              accept="image/jpg,image/jpeg, application/pdf"
              type="file"
              onChange={(event: any) => fileHandleEvent(event, type)}
            />
            <Typography
              style={{
                fontWeight: "500",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#626974",
                // color: "#FFFF",
                paddingLeft: 10,
                // textAlign: "center"
              }}
            >
              Upload file
            </Typography>
          </Button>
        </>
      ) : (
        <Grid container direction={"row"}>
          <Grid>
            <CheckCircleIcon color="success" />
          </Grid>
          <Grid>
            {" "}
            <Typography style={{ color: "#99A09C", fontWeight: "700" }}>
              Successfully Uploaded
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Button onClick={() => downloadDoc(type)} variant="text">
              Download
            </Button>
            <Button onClick={() => (imageChange(type), ChangeDoc(type))} variant="text">
              Change
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ImageUploader;

interface ImageUploaderPorps {
  imageChange?: any;
  fileHandleEvent?: any;
  documents: any[];
  type: string;
  downloadDoc: any;
  setchangeDocAadhar: any;
  setchangeDocPan: any;
  setchangeDocVotar: any;
}
