import { UserManagementMain } from "../../models/userManagementModel";
import { UserManagementActionTypes } from "../actions/userManagementAction";
import InitialState from "./initialState";

const initialState: UserManagementMain = InitialState.userManagement;
export default function UserManagementReducer(
  state: UserManagementMain = initialState,
  action: any
) {
  switch (action.type) {
    case UserManagementActionTypes.All_Access_Control_Success_Action:
      return { ...state, access_control_master: action.payload };
    case UserManagementActionTypes.Get_All_Role_Master_List_Control_Success_Action:
      return { ...state, all_role_master: action.payload };
    case UserManagementActionTypes.Get_Role_By_ID_Success_Action:
      return { ...state, role_get_by_id: action.payload };
      case UserManagementActionTypes.User_Role_Mapping_List_Success_Action:
        return { ...state, user_role_mapping_list: action.payload };
    default:
      return state;
  }
}
