export const baseUrl = "http://svchrms.msqube.com/api/v1/";
//export const baseUrl = "http://localhost:3000/api/v1/";
//
export const environment = {
  app_version: "0.1.0",
  urls: {
    loginUrl: "login/",
    changePassword: "changepassword",
    createOrganization: "organization/create/",
    createDepartment: "organization/department/create",
    getAllOrganization: "organization/getall",
    getAllDepartment: "organization/department/getall",
    getAllDesignation: "organization/designation/getall",
    getAllServices: "organization/service/getall",
    createEmployeeTiming: "organization/workingshift/create",
    createEmployeeDesignation: "organization/designation/create",
    getAllEmployeeTimingList: "organization/workingshift/getall",
    createEmployeeType: "organization/employeetype/create",
    getAllEmployeeTypeList: "organization/employeetype/getall",
    editDepartmentList: "organization/department/edit",
    editDesignationList: "organization/designation/edit",
    editEmployeeTypeList: "organization/employeetype/edit",
    editEmployeeTimingList: "organization/workingshift/edit",
    DeleteDepertment: "organization/department/delete",
    editOrganizationList: "organization/edit",
    DeleteDesignation: "organization/designation/delete",
    DeleteEmployeeType: "organization/employeetype/delete",
    DeleteEmployeeTiming: "organization/workingshift/delete",
    AllDomainType: "organization/domain/getallbytype",
    getSalaryStructure: "organization/salarystructure/componentgetall",
    getAllCustomer: "customer/getAll",
    AddCustomerPayload: "customer/create",
    editCustomerList: "customer/edit",
    AddSalaryStructure: "organization/salarystructure/create",
    GetAllSalaryStructureList: "organization/salarystructure/getAll",
    AddCandidate: "candidate/create",
    GetSalaryStructureByID: "/organization/salarystructure/getbyid",
    getAllProject: "customer/project/getall",
    AddProjectPayload: "customer/project/create",
    getProjectViewPayload: "customer/project/getbyid/",
    SalaryStructureUpdate: "organization/salarystructure/edit",
    editProject: "customer/project/update",
    getEventcheckStatus: "candidate/getbydeg",
    getRefferal: "candidate/myreferral",
    getEmployeeView: "/candidate/getcadbyenroll",
    getAllCandidate: "/candidate/allappaliedcandidate",
    getAllEmployee: "/employee/getAllemployee/",
    getProjectDashboardDetails: "/customer/project/dashboard",
    getEmployeeAllDetails: "/employee/employeeAllDetails",
    editEmployee: "/candidate/update",
    getMainDashboard: "/organization/orgDashboard",
    getLevelDetails: "customer/getleveldetails/",
    editEmployeeDashboard: "/employee/update/EmpDetails",
    getAvailableEmp: "/customer/project/searchAvailableEmp",
    allocateEmp: "/customer/project/allocateEmp",
    getAllPaymentList: "/projectbudgeting/projectPayment/getall",
    createProjectPayment: "/projectbudgeting/projectPayment/create",
    editProjectPayment: "/projectbudgeting/projectPayment/edit",
    deleteProjectPayment: "/projectbudgeting/projectPayment/delete",
    editReportingManager: "/employee/updatereportingmanager",
    // Project Budgeting
    getProjectBudgetlist: "/projectbudgeting/project/getall",
    getProjectUnbudgetlist: "/projectbudgeting/unbudgetproject/getall",
    createProjectBudgeting: "/projectbudgeting/project/create",
    editProjectBudgeting: "/projectbudgeting/project/edit",
    getBudgetDashboardDetails: "/projectbudgeting/budgetDashboard/details",
    // POST
    AddPost: "organization/post/create/",
    getAllPost: "organization/post/getall/",
    deletePost: "organization/post/delete/",
    editPost: "organization/post/edit/",
    // salary component
    AddSalaryComponent: "organization/salarycomponent/create/",
    SalarycomponentGetAllurl: "organization/salarycomponent/getall/",
    DeleteSalaryComponent: "organization/salarycomponent/delete/",
    editSalaryComponent: "organization/salarycomponent/edit/",
    sampleSalarydownload: "salary/getDummyPdf",
    divisionGetAllUrl: "/customer/division/getall",
    subDivisionGetALLUrl: "/customer/subdivision/getall",
    //common
    fileUploadUrl: "comm/file/upload",
    downloadFileUrl: "comm/file/download",
    getAllBankList: "/comm/bank_master/getall",
    //attendance
    downloadAttendance: "employee/downloadattendence/template/",
    attendanceDashboard: "employee/attendence/dashboard/",
    attendenceUploadUrl: "employee/uploadattendence",
    attendenceStatus: "customer/project/dashboard/attendence_status",
    //attendance
    pendingAprovalList: "employee/attendencelist/bydesignation",
    uploadedattendanceexcelview: "employee/employeelist/byattendence",
    //  dummy urls
    uploadBulkEmployee: "employee/",

    reportingmanagerGetUrl: "employee/employeebyproject",
    candidateApproveUrl: "candidate/approveCandidateDetails",
    actionStatusUrl: "comm/checkActionStatus",
    updateAttendance: "employee/update/employeeattendence/",
    attendanceApproveList: "employee/attendencelist",
    payrollAvalibleEmployeeUrl: "payroll/employeeAvaialbleList",
    generatePayrollBatchUrl: "payroll/generatemonthlyPayroll",
    batchListUrl: "payroll/batchList",
    PaymentBatchDetailUrl: "payroll/employeeData",
    updateAttendanceApproval: "employee/update/attendence",
    GetSalaryCalculatedComponet: "payroll/calculatedcomponentbyemployee",
    releaseBatchUrl: "payroll/releaseBatch",
    UpdatePayrollComponent: "payroll/employeeComponentUpdate",
    BatchUpdateUrl: "payroll/batchUpdate",
    BatchByDegUrl: "payroll/batchListbydeg",
    projectAttendaceTemplate: "comm/templatefield/getall",
    getAllLevelsForProject: "comm/level_master/getall",
    getBatchDataByProject: "customer/project/dashboard/batchdata",
    getEmpPayrollByProjectId: "customer/project/dashboard/employee_payroll",
    editAssoiciationStatus:
      "customer/project/dashboard/association_status/edit",
    deleteEmployeeAttendanceFromBatch: "employee/delete/employeeattendence",
    downloadRTGSNEFT: "payroll/neftRtgsForm",
    getAllAccessControl: "users/getallaccesscontrolmaster",
    createRole: "users/createrole",
    getAllRoleMasterList: "users/getallrole",
    getRoleByID: "users/getrolebyid",
    updateRole: "users/updaterole",
    userRoleMapping: "users/userList",
    roleAssignToUser: "users/assignroletouser",
    getLevelStructure: "customer/project/getleveltreebyid",
    getLevelTargetRateValue: "customer/project/get/targetratevalue",
    downloadEmpSalarySlip: "salary/getEmployeePaySlip",
    downloadBatchWisePayslipZip: "salary/getPayslipZip",
    downloadBulkCandidateForm: "candidate/downloadbulkexcel",
    getBulkUploadCandidate: "candidate/getbulkuploadstatus",
    getBulkEmployeeUpload: "candidate/bulkcreate",
    getFilteredBulkStatus: "candidate/getbulkstatus/byIds",
    getbulkuploadreport: "candidate/bulkupload/getreport",
    getemployeelistbyexcel: "employee/getemployeelistbyexcel",

    // leave type env url

    LeaveTypeGetAll: "leave/leavetype/getall",
    GetLeaveTypeById: "leave/leavetype/byid",
    EditLeaveType: "leave/leavetype/edit",
    DeleteLeaveType: `leave/leavetype/byid/delete`,
    createLeaveType: `leave/leavetype/create`,

    // organization holiday env url

    addHoliday: "leave/addholiday",
    deleteHoliday: "leave/deleteholiday",
    holidayList: "leave/holidaylist",
    holidayById: "leave/holiday/byid",
    editHoliday: "leave/editholiday",

    // leave management env url

    createLeaveManagement: "leave/leavemanagement/create",
    getAllLeaveManagement: "leave/leavemanagement/getall",
    editLeaveManagement: "leave/leavemanagement/edit",
    deleteLeaveManagement: "leave/leavemanagement/delete",
    designationWiseLeaveCount: "leave/getnoofleave/designationwise",
    leaveDetailsDesignationWise: "leave/designationwise/leavedetails",
    designationwiseleavedetails: "leave/designationwise/leavedetails",
    getEmployeeByDesignation:
      "leave/leaveapplication/getEmployee/bydesignation",
    calculateLeaveDays: "leave/leaveapplication/calculate/leavedays",
    getLeaveTypeAmountData: "leave/leaveapplication/getleave",
    applyLeave: "leave/leaveapplication/applyLeave",
    getLeaveDetails: "leave/leaveapplication/getleavedata",
    getAllLeaveApprovalList: "leave/leaveapplication/getleavebydeg",
    updateLeaveApproveStatus: "leave/leaveapplication/update",
    leaveDetailsData: "leave/leaveapplication/getleavedatedetails",
    allLeaveList: "leave/leavemanagement/getallleave/",
    updateLeave: "leave/leaveapplication/edit",
    getLeaveDetailsForDashboard: "leave/leaveapplication/getDetails/dashboard",
    // Timing Sheet env url
    getTimesheetList: "/projectbudgeting/timesheetprojectget",
    getTimeSheetPendingApprovalList: "/projectbudgeting/pendingapproval",
    updateTimingSheetApproveStatus: "/projectbudgeting/approverequest",
    updateMonthlyTimeSheet: "projectbudgeting/updatemonthlytimeshift",
    initiateTimeSheet: "projectbudgeting/initiatetimesheet",
    getAllStates: "comm/states",
    employeeSearchByName: "employee/searchbyemployeeidorname",
    cancelLeave: `leave/leaveapplication/cancelleave`,
    getStateByAddressId: `employee/getStateByAddress`,
    getHolidayByEmployee: `leave/leaveapplication/getholidaylistbyemployee`,
    pfesicMonthlyReportLists: "payroll/pfesicmonthlyreport",
    naturalHolidays: `leave/leaveapplication/naturalholidaylist`,
    syncSalaryStructureForEmp: "organization/syncemployeesalary",
    levelMasterGetAll: "customer/levelmaster/getall",
    //Levels env url
    getLevels: "/customer/level/getbymaster",
    addLevelMaster: "customer/levelmaster/create",
    editLevelMaster: "customer/levelmaster/update",
    getLevelByMaster: "customer/level/getbymaster",
    createLevel: "customer/level/update",
    attendanceTimeSheet: "employee/timesheet/calculateattendance",
    uploadAttendanceTimeSheet: "employee/timesheet/uploadattendancebytimesheet",
    payrollReport: "salary/salaryreport",
    thresholdParameter: "comm/parameters/getall",
    timeSheetHistory: "projectbudgeting/timesheet/getall",
    resetpasswordrequest: "users/resetpasswordrequest",
    promotionlist: "promotion/promotionlist",
    viewPromotionDetails: "promotion/promotiondetails",
    promotionPendingApproval: "promotion/promotionbydeg",
    initiatePromotion: "promotion/initiatepromotion",
    updatePromotion: "promotion/updatepromotion",
    deletePromotion: "promotion/deletepromotion",
    empAvailableListForPromotion: "promotion/availableemplist",
    activateSalaryForPromotion: "promotion/activepromotionbatch",
    consolidatedReportForProject:
      "projectbudgeting/budgetDashboard/consolidatedReport",
    monthWiseEmpSalary: "projectbudgeting/monthEmployeeSalary",
    empMonthlySalaryProjectwise: "projectbudgeting/projectEmployeeSalary",

    //Termination
    getAllEmployeeListAvailableForTermination:
      "employee/emplistfortermination/",
    initiateTermination: "employee/initiatetermination/",
    terminationInitiatedEmployeeList: "employee/terminationlistbydeg/",
    terminatedEmployeeList: "employee/terminatedemployeelist/",
    approveTermination: "employee/terminationapproval/",

    //Project Closure
    getAllAvailableProjectForTermination: "projectbudgeting/activeprojectlist/",
    initiateProjectClosure: "projectbudgeting/initiateProjectClosure/",
    projectClosureInitiatedList: "projectbudgeting/pendingProjectClosure/",
    projectClosureApproval: "projectbudgeting/completeProjectClosure/",
    completedProjectList: "projectbudgeting/completeprojectlist",

    //Project report
    consolidatedReportDownload:
      "projectbudgeting/budgetDashboard/consolidatedReportExcel",
    consolidatedMonthlyReportProjectwise:
      "projectbudgeting/budgetDashboard/consolidatedMonthEmpReport",

    // Invoice
    invoiceList: "projectbudgeting/projectPaymentinvoice/getall",
    createInvoice: "projectbudgeting/projectPaymentinvoice/create",
    editInvoice: "projectbudgeting/projectPaymentinvoice/edit",
  },
  sideNav: [
    {
      key: "dashboard",
      name: "Dashboard",
      sub_title: "",
      icon: "dashboard",
      has_submenu: false,
      url: "/dashboard/dashboard-main/",
      menuItem: [],
      sub_url: [],
    },
    {
      key: "organization_management",
      name: "Organization",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/organization/",
      sub_url: [],
      menuItem: [
        {
          key: "setup_organization",
          item_name: "Organization",
          icon: "add_circle_outline",
          url: "/organization/organization-main/",
          sub_title: "Manage your organization as per your need.",
          sub_url: ["organization/add-organization-new/"],
        },
        {
          key: "setup_department",
          item_name: "Department ",
          icon: "supervisor_account",
          url: "/organization/setup-department/",
          sub_title: "Department is a part of your organization",
          sub_url: ["/organization/add-department/"],
        },
        {
          key: "setup_employee_type",
          item_name: "Employee Type ",
          icon: "format_list_bulleted",
          url: "/organization/employee-type-list/",
          sub_title: "Customise your setup to best fit your team",
          sub_url: ["/organization/setup-employee-type/"],
        },
        {
          key: "setup_designation",
          item_name: "Designation ",
          icon: "pix",
          url: "/organization/setup-designation/",
          sub_url: ["/organization/add-designation/"],
          sub_title: "Customise your team based on their role and experience",
        },
        {
          key: "setup_post",
          item_name: "Post",
          icon: "pages",
          url: "/organization/post-list/",
          sub_url: ["/organization/add-post/"],
          sub_title: "Customise your team based on their role and experience",
        },
        {
          key: "setup_salary_structure",
          item_name: "Salary Structure",
          icon: "payments",
          url: "/organization/salary-structure-list/",
          sub_title:
            "Salary structure includes the details of the salary being offered",
          sub_url: [
            "/organization/add-salary-structure",
            "/organization/edit-salary-structure/",
          ],
        },
        {
          key: "salary_component",
          item_name: "Salary Component",
          icon: "payment",
          url: "/organization/salary-component/",
          sub_title: "Salary components are base of any structure",
          sub_url: ["/organization/add-salary-component"],
        },
        {
          key: "setup_employee_timing",
          item_name: "Employee Timing ",
          icon: "schedule",
          url: "/organization/employee-timing-list/",
          sub_title:
            "The period during which an employee is available for employment.",
          sub_url: ["/organization/add-employee-timing/"],
        },
      ],
    },
    {
      key: "employee_management",
      name: "Employee",
      icon: "group",
      has_submenu: true,
      url: "/employment/",
      sub_title: "",
      sub_url: [],
      menuItem: [
        {
          key: "employee_list",
          item_name: "Employee List",
          icon: "toc",
          url: "/employment/employee-list/",
          sub_title: "Organization's employee details list",
          sub_url: [],
        },
        {
          key: "add_employee",
          item_name: "Onboard Candidate",
          icon: "add",
          url: "/employment/add-employee/",
          sub_title: "Onboarding a new candidate ",
          sub_url: [],
        },
        {
          key: "bulk_upload_employee",
          item_name: "Candidate Bulk Upload",
          icon: "loupe",
          url: "/employment/bulk-upload-employee/",
          sub_title: "Onboarding new candidates in Bulk ",
          sub_url: [],
        },
        {
          key: "onboard_request",
          item_name: "Pending Approval",
          icon: "pending_action",
          url: "/employment/onboarding-request-app-rej/",
          sub_title:
            "Onboarding candidate's request approval or rejection list",
          sub_url: [],
        },
        {
          key: "referral_list",
          item_name: "My Referral",
          icon: "supervisor_account",
          url: "/employment/referral-list/",
          sub_title: "New Candidate referred by the user ",
          sub_url: [],
        },
        {
          key: "candidate_list",
          item_name: "All Candidate",
          icon: "collections_bookmark",
          url: "/employment/all-candidate/",
          sub_title: "candidate's details list",
          sub_url: [],
        },
        // {
        //   key: "approval_list",
        //   item_name: "Approval List",
        //   icon: "supervisor_account",
        //   url: "/employment/approval-list/",
        //   sub_title: "",
        //   sub_url: [],
        // },
      ],
    },
    {
      key: "customer_management",
      name: "Customer",
      icon: "group",
      has_submenu: true,
      url: "/customer/",
      sub_title: "",
      sub_url: [],
      menuItem: [
        {
          key: "customer_list",
          item_name: "Customer ",
          icon: "toc",
          url: "/customer/customer-main/",
          sub_title: "",
          sub_url: ["/customer/customer-details/", "/customer/add-customer/"],
        },

        {
          key: "project_list",
          item_name: "Projects",
          icon: "inventory_2",
          url: "/customer/project-main/",
          sub_title: "",
          sub_url: [
            "/customer/add-project/",
            "/customer/project-dashboard/",
            "/customer/payment-details-main/",
            "/customer/create-payment/",
          ],
        },
        {
          key: "project_budgeting",
          item_name: "Project Budgeting",
          icon: "price_change",
          url: "/customer/project-budgeting/",
          sub_title: "",
          sub_url: ["/customer/create-budgeting/"],
        },
        {
          key: "levels",
          item_name: "Levels",
          icon: "add",
          url: "/customer/levels/",
          sub_title: "Onboarding a new candidate ",
          sub_url: [
            "/customer/add-level-master/",
            "/customer/edit-level-master/",
            "/customer/add-levels/",
            "/customer/levels-data/",
            "/customer/levels/levels-master/add-edit/",
          ],
        },
        {
          key: "initiated_project_closure",
          item_name: "Initiate Project Closure",
          icon: "cloud_circle",
          url: "/customer/initiate-project-closure/",
          sub_title: "Initiate Project Closure",
          sub_url: [],
        },
        {
          key: "project_closure_approve",
          item_name: "My Work List",
          icon: "priority_high",
          url: "/customer/pending-project-closure/",
          sub_title: "Approval section of project closure",
          sub_url: [],
        },
        {
          key: "completed_project",
          item_name: "Completed Project",
          icon: "local_atm",
          url: "/customer/completed-project/",
          sub_title: "Completed project details",
          sub_url: [],
        },
      ],
    },
    {
      key: "attendance_management",
      name: "Attendance",
      icon: "group",
      has_submenu: true,
      url: "/attendance/",
      sub_title: "",
      sub_url: [],
      menuItem: [
        {
          key: "attendance_main",
          item_name: "Attendance Summary",
          icon: "summarize",
          url: "/attendance/attendance-main/",
          sub_title: "Number of employees attending organization",
          sub_url: ["/attendance/attendance-details/"],
        },
        {
          key: "attendance_upload_download",
          item_name: " Upload/Download",
          icon: "cloud_circle",
          url: "/attendance/attendance-upload-download/",
          sub_title: "Number of employees attending organization",
          sub_url: [],
        },
        {
          key: "uploaded_attendance",
          item_name: "View Batch",
          icon: "drive_folder_upload",
          url: "/attendance/uploaded-attendance/",
          sub_title:
            "Uploaded attendance section where one can view uploaded or approved attendance sheets",
          sub_url: [],
        },
        {
          key: "attendance_download",
          // key: "attendance_view",
          item_name: "View Attendance",
          icon: "toc",
          url: "/attendance/attendance-download/",
          sub_title: "Download attendance section of employees",
          sub_url: [],
        },
        {
          key: "attendance_approve",
          item_name: "My Work List",
          icon: "priority_high",
          url: "/attendance/attendance-approve/",
          sub_title: "Approval section of uploaded attendance",
          sub_url: [],
        },

        {
          key: "attendance_history",
          item_name: "All Attendance",
          icon: "apps",
          url: "/attendance/all-attendance/",
          sub_title: "All uploaded attendance section",
          sub_url: [],
        },
        {
          key: "time_sheet_attendance",
          item_name: "Attendance - Timesheet",
          icon: "punch_clock",
          url: "/attendance/time-sheet-attendance/",
          sub_title: "Attendance report from timesheet",
          sub_url: [],
        },
      ],
    },
    {
      key: "payroll",
      name: "Payroll",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/payroll/",
      sub_url: [],
      menuItem: [
        // {
        //   key: "payroll_summary",
        //   item_name: "Payroll Summary",
        //   icon: "query_stats",
        //   url: "/payroll/payload-dashboard/",
        //   sub_title: "Payroll enables the disbursement of employee salaries.",
        //   sub_url: [],
        // },
        {
          key: "register_payroll",
          item_name: "Create Payroll",
          icon: "account_balance_wallet",
          url: "/payroll/register-payroll/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "payroll_batch",
          item_name: "View Payroll",
          icon: "fact_check",
          url: "/payroll/payroll-batch/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "pending_approval",
          item_name: "My Work List",
          icon: "currency_exchange",
          url: "/payroll/pending-approval/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "all_batch",
          item_name: "All Payroll",
          icon: "density_small",
          url: "/payroll/all-batch/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "approved_batch",
          item_name: "Approved Payroll",
          icon: "price_check",
          url: "/payroll/approved-batch/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "paid_batch",
          item_name: "Paid Payroll",
          icon: "local_atm",
          url: "/payroll/paid-batch/",
          sub_title: "Show Paid Batchwise details.",
          sub_url: [],
        },
        {
          key: "cancelled_batch",
          item_name: "Cancelled Payroll",
          icon: "cancel",
          url: "/payroll/cancelled-batch/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
        {
          key: "rejected_batch",
          item_name: "Rejected Payroll",
          icon: "block",
          url: "/payroll/rejected-batch/",
          sub_title: "Payroll enables the disbursement of employee salaries.",
          sub_url: [],
        },
      ],
    },
    {
      key: "leave_management",
      name: "Leave Management",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/leave/",
      sub_url: [],
      menuItem: [
        {
          key: "organizational_holiday",
          item_name: "Organizational Holiday",
          icon: "apartment",
          url: "/leave/organizational-holiday/",
          sub_title: "Manage your organization as per your need.",
          sub_url: [],
        },
        {
          key: "leave_types",
          item_name: "Leave Types",
          icon: "casino",
          url: "/leave/leave-types/",
          sub_title: "Manage your organization as per your need.",
          sub_url: [],
        },
        {
          key: "leave_management",
          item_name: "Leave Management",
          icon: "museum",
          url: "/leave/leave-management/",
          sub_title: "Manage your organization as per your need.",
          sub_url: [],
        },
        {
          key: "employee_leave_application",
          item_name: "Employee Leave Application",
          icon: "note_add",
          url: "/leave/employee-leave-application/",
          sub_title: "Manage your organization as per your need.",
          sub_url: [],
        },
        {
          key: "leave_approval",
          item_name: "Leave Approval",
          icon: "approval",
          url: "/leave/leave-approval/",
          sub_title: "Approval section of uploaded leave",
          sub_url: [],
        },
        {
          key: "all_leave",
          item_name: "All Leave",
          icon: "copy_all",
          url: "/leave/all-leave/",
          sub_title: "All leave details ",
          sub_url: [],
        },
      ],
    },
    {
      key: "promotion",
      name: "Promotion/Revision",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/promotion/",
      sub_url: [],
      menuItem: [
        {
          key: "promotion_list",
          item_name: "Promotion/Revision List",
          icon: "more_horiz",
          url: "/promotion/promotion-list/",
          sub_title: "List of Promotions",
          sub_url: [],
        },
        {
          key: "approval_promotion_list",
          item_name: "My Work List",
          icon: "emergency_home",
          url: "/promotion/approve-promotion/",
          sub_title: "Pending Approval List of Promotions",
          sub_url: [],
        },
      ],
    },
    {
      key: "user_management",
      name: "User Management",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/usermanagement/",
      sub_url: [],
      menuItem: [
        {
          key: "role_master",
          item_name: "Role Master",
          icon: "query_stats",
          url: "/usermanagement/role-master/",
          sub_title: "Create Role of the employee...",
          sub_url: [],
        },
        {
          key: "user_role_mapping",
          item_name: "User Role Mapping",
          icon: "account_balance_wallet",
          url: "/usermanagement/user-role-mapping/",
          sub_title: "User Role Mapping for employee...",
          sub_url: [],
        },
      ],
    },
    {
      key: "timing_sheet",
      name: "Timesheet",
      icon: "activity_zone",
      has_submenu: true,
      sub_title: "",
      url: "/timingsheet/",
      sub_url: [],
      menuItem: [
        {
          key: "log_activity",
          item_name: "Timesheet",
          icon: "local_activity",
          url: "/timingsheet/log-activity/",
          sub_title: "",
          sub_url: [],
        },
        {
          key: "timing_sheet_approve",
          item_name: "My Work List",
          icon: "priority_high",
          url: "/timingsheet/timing-sheet-approve/",
          sub_title: "",
          sub_url: [],
        },
        {
          key: "view_timesheet",
          item_name: "View Timesheet",
          icon: "priority_high",
          url: "/timingsheet/view-time-sheet/",
          sub_title: "",
          sub_url: [],
        },
        {
          key: "history_timesheet",
          item_name: "Timesheet History",
          icon: "clear_all",
          url: "/timingsheet/time-sheet-history/",
          sub_title: "",
          sub_url: [],
        },
      ],
    },
    {
      key: "reports",
      name: "P&L Report",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/reports/",
      sub_url: [],
      menuItem: [
        {
          key: "pf_report",
          item_name: "PF Reports",
          icon: "more_horiz",
          url: "/reports/pf-report/",
          sub_title: "PF Reports",
          sub_url: [],
        },
        {
          key: "esic_report",
          item_name: "ESIC Reports",
          icon: "summarize",
          url: "/reports/esic-report/",
          sub_title: "ESIC Reports",
          sub_url: [],
        },
        {
          key: "ptax_report",
          item_name: "P.Tax Reports",
          icon: "dataset",
          url: "/reports/ptax-report/",
          sub_title: "P Tax Reports",
          sub_url: [],
        },
        {
          key: "payroll_report",
          item_name: "Payroll Reports",
          icon: "interests",
          url: "/reports/payroll-report/",
          sub_title: "Payroll Reports",
          sub_url: [],
        },
        {
          key: "project_consolidated",
          item_name: "Project Summary",
          icon: "candlestick_chart",
          url: "/reports/project-consolidated/",
          sub_title: "",
          sub_url: [],
        },
        {
          key: "consolidated_report",
          item_name: "Consolidated Reports",
          icon: "summarize",
          url: "/reports/consolidated-report/",
          sub_title: "Consolidated Reports",
          sub_url: [],
        },
      ],
    },
    {
      key: "termination",
      name: "Termination",
      icon: "corporate_fare",
      has_submenu: true,
      sub_title: "",
      url: "/termination/",
      sub_url: [],
      menuItem: [
        {
          key: "employee_list_for_termination",
          item_name: "Employee List For Termination",
          icon: "person_cancel",
          url: "/termination/employee_list_for_termination/",
          sub_title: "Employee List For Termination",
          sub_url: [],
        },
        {
          key: "pending_approval",
          item_name: "Pending Approval",
          icon: "more_horiz",
          url: "/termination/pending_approval/",
          sub_title: "Pending Approval",
          sub_url: [],
        },
        {
          key: "terminated_emp_list",
          item_name: "Terminated List",
          icon: "network_ping",
          url: "/termination/terminated_emp_list/",
          sub_title: "Terminated Employee List",
          sub_url: [],
        },
      ],
    },
  ],
  global_veriable: {
    emp_type_for_project_specific: 13,
    OnBoardingeventType: 1,
    ho_employe_type_id: 1,
    ho_employees_type_id: [1],
    ho_project_name: "HO Internal",
    officialEmployee: [1, 2, 3],
    managerLevelDeg: [13],
    superAdminLevel: [1, 2, 3],
    exceptSupTypeLevelDeg: [4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  event_action_items: [
    { key: "salary_assignment", value: "Assign Salary" },
    { key: "reporting_manager", value: "Assign Reporting Manager" },
    { key: "user_creation", value: "" },
    { key: "intermediate_approval", value: "Intermediate Approval" },
    { key: "final_approval", value: "Final Approval" },
    { key: "approve", value: "Approve" },
  ],
  leave_application: {
    permanent_holiday: [0],
    leave_apply_limit: 6,
    leave_difference: 180,
    sick_leave: 3,
    casual_leave: 1,
    previlage_leave: 9,
    earn_leave: 4,
    non_deltable_leave: [8, 9, 10],
    holiday_sat: [2, 4],
  },
  promotion_details: {
    revision_start: 3, // for promotion and revision both case
  },
};
