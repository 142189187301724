import {
  Box,
  Button,
  Fade,
  Grid,
  Link,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ActionEventList,
  CheckActionStatusPayload,
} from "../../../models/employeeModel";
import { StoreState } from "../../../models/reduxModels";
import { getActionStatusAction } from "../../../stores/actions/employeeAction";

const ActionStatusPopover = ({
  action_event_list,
  open,
  setOpen,
  getActionStatusAction,
  payload,
}: ActionStusPopoverProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const columns: any[] = [
    "Event Name",
    "Initiated By",
    "Initiated On",
    "Approver",
    "Approved On",
    "Status",
    "Remarks",
  ];
  useEffect(() => {
    if (open && !!payload) {
      getActionStatusAction(payload);
    }
  }, [payload]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Grid
            container
            style={{
              padding: 10,
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <Grid item>
              <Typography style={{ color: "#fff" }}>Approve Status</Typography>
            </Grid>
          </Grid>
          <Grid container style={{ width: "100%", padding: 5 }}>
            <Grid item>
              <TableContainer sx={{}}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns.map((column) => (
                        <StyledTableCellS align="justify" key={column}>
                          {column}
                        </StyledTableCellS>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!action_event_list &&
                      action_event_list.map((row) => (
                        <StyledTableRowS
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={Math.random()}
                        >
                          <StyledTableCellS align="left" key={columns[0]}>
                            {row.eventType}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                            {/* <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.initiatedBy}`
                                )
                              }
                            > */}
                              {row.initiatedBy}
                            {/* </Link> */}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[2]}>
                            {!!row.initiatedOn &&
                              moment(row.initiatedOn).format("DD-MM-yyyy")}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[3]}>
                            {row.approverName} {row?.approverId}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[4]}>
                            {!!row.approvedOn &&
                              moment(row.approvedOn).format("DD-MM-yyyy")}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[5]}>
                            {row.eventStatus}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[6]}>
                            {row.approverRemarks}
                          </StyledTableCellS>
                        </StyledTableRowS>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => setOpen(false)}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    action_event_list: state.employee.action_event_list,
  };
};
const mapDispatchToProps = {
  getActionStatusAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionStatusPopover);
interface ActionStusPopoverProps {
  open: boolean;
  setOpen?: any;
  action_event_list: ActionEventList[];
  payload?: CheckActionStatusPayload;
  getActionStatusAction?: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const StyledTableCellS: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FD6500",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRowS = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
