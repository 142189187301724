import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  Switch,
  Button,
  Checkbox,
  TextareaAutosize,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import AddIcon from "@mui/icons-material/Add";
import {
  AccessControlMasterList,
  GetRoleByID,
} from "../../../models/userManagementModel";

const AddRoleView = ({
  access_control_master,
  CreateRole,
  role_get_by_id,
  SubmitUpdatedData,
}: AddRoleViewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!role_get_by_id) {
      setValue("role_name", role_get_by_id.role_name);
      setValue("role_desc", role_get_by_id.role_desc);
      setValue(
        "control_element",
        access_control_master
          .filter((m) =>
            role_get_by_id.control_element.find(
              (n) => n.access_control_name == m.access_control_master_name
            )
          )
          .map((p) => p.access_control_master_id)
      );
    }
  }, [role_get_by_id]);
  const onSubmit = (data: any) => {
    let payload: any = {
      ...data,
      control_element: data.control_element.map((m: any) => ({
        access_control_name: access_control_master.find(
          (n) => n.access_control_master_id == m
        )?.access_control_master_name,
        access_type: access_control_master.find(
          (n) => n.access_control_master_id == m
        )?.access_type,
        action_items: ["view", "edit", "delete"],
      })),
    };

    if (!!role_get_by_id) {
      let updatePayload: any = {
        role_id: role_get_by_id?.role_id,
        ...data,
        control_element: data.control_element.map((m: any) => ({
          access_control_name: access_control_master.find(
            (n) => n.access_control_master_id == m
          )?.access_control_master_name,
          access_type: access_control_master.find(
            (n) => n.access_control_master_id == m
          )?.access_type,
          action_items: ["view", "edit", "delete"],
        })),
      };
      // console.log("updatePayload", updatePayload);

      SubmitUpdatedData(updatePayload);
    } else {
      CreateRole(payload);
    }
  };
  // console.log("role_get_by_id", role_get_by_id);

  return (
    <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/usermanagement/role-master/`)}
          // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              // fontWeight: "700",
              // fontSize: 18,
              // fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            Add Role
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      <form>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ marginInline: 10, width: "90%" }}>
            {!!role_get_by_id &&
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              >
                <Switch
                  value={edit_flag}
                  onChange={(event) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>Edit Role</Typography>
              </Grid>
            }
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 20,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    paddingTop: "3%"
                  }}
                >
                  Role Name
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"role_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      // label="Enter the Role Name"
                      className={"smaller"}
                      style={{ width: "100.9%", outline: "none" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      inputProps={{
                        readOnly: !!role_get_by_id ? edit_flag : !edit_flag,
                      }}
                      value={value}
                      placeholder={"Role Name"}
                      error={!!errors["role_name"]}
                      helperText={
                        errors.role_name &&
                        `${errors.role_name.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 20,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"role_desc"}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextareaAutosize
                      style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                      onBlur={onBlur}
                      className={"smaller"}
                      // id="standard-required"
                      onChange={onChange}
                      value={value}
                      disabled={!!role_get_by_id ? edit_flag : !edit_flag}
                      placeholder={"Description"}
                      minRows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 20,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    paddingTop: "3%"
                  }}
                >
                  Control Element
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"control_element"}
                  defaultValue={[]}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={
                        !!access_control_master ? access_control_master : []
                      }
                      style={{ width: "100.9%" }}
                      getOptionLabel={(option) => option.display_name}
                      readOnly={!!role_get_by_id ? edit_flag : !edit_flag}
                      onChange={(evemt, value) =>
                        onChange(
                          !!value
                            ? value.map((m) => m.access_control_master_id)
                            : []
                        )
                      }
                      value={
                        !!value
                          ? access_control_master.filter((m) =>
                            value.includes(m.access_control_master_id)
                          )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="standard-required"
                          className={"smaller"}
                          // variant="standard"
                          // // label="Control Element"
                          placeholder="Control Element"
                          onBlur={onBlur}
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {((edit_flag && !role_get_by_id) ||
          (!edit_flag && !!role_get_by_id)) && (
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              paddingBottom={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                <b>{!!role_get_by_id ? "Update" : "Add"}</b>
              </Button>
            </Grid>
          )}
      </form>
    </Paper>
  );
};

export default AddRoleView;
interface AddRoleViewProps {
  access_control_master: AccessControlMasterList[];
  CreateRole?: any;
  role_get_by_id?: GetRoleByID;
  SubmitUpdatedData?: any;
}
