import FullCalendar from "@fullcalendar/react";
import CloseIcon from "@mui/icons-material/Close";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ListPlugin from "@fullcalendar/list";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import CalendarProjectAllocation from "./CalendarProjectAllocation";
import {
  Control,
  FieldValues,
  FieldErrorsImpl,
  UseFormGetValues,
} from "react-hook-form";
import { EmpProject } from "../../../models/timingSheetModel";
import CalendarWiseProjectAllocatioinModal from "./CalendarWiseProjectAllocationModal";
import colorGenerator from "../../../utility/colorGenerator";
import MobileCalendar from "../../../library/MobileCalendar";
import { environment } from "../../../environment";

interface CalendarwiseTimeSheetProps {
  defaultDate: string;
  setAllotedProjectModalOpen: any;
  emp_project: EmpProject[];
  control: Control<FieldValues, any>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  setValue: any;
  setError: any;
  handleSubmit: any;
  notEditable?: boolean;
  initiateTimesheetAction?: any;
  handleApprovalRequest: any;
  setShowApprobvalBtn: any;
  showApprovalBtn: boolean;
  status: string;
  setStatus: any;
  isworklist?: boolean;
  reset: any;
  notShow?: boolean;
  apiLoading?: number;
  getValues: UseFormGetValues<FieldValues>;
  mutate: boolean;
  setMutate: any;
  tempeventsData: Array<any>;
  setTempEventsData: Dispatch<SetStateAction<Array<any>>>;
  setSelectedDate: any;
  selectedDate: any;
}
const CalendarwiseTimeSheet: FC<CalendarwiseTimeSheetProps> = ({
  defaultDate,
  setAllotedProjectModalOpen,
  emp_project,
  control,
  errors,
  setValue,
  setError,
  handleSubmit,
  notEditable,
  initiateTimesheetAction,
  handleApprovalRequest,
  setShowApprobvalBtn,
  showApprovalBtn,
  status,
  setStatus,
  isworklist,
  reset,
  notShow,
  apiLoading,
  getValues,
  mutate,
  setMutate,
  setTempEventsData,
  tempeventsData,
  selectedDate,
  setSelectedDate,
}) => {
  const saveButtonRef = useRef<any>();
  const [calendarMutate, setCalendarMuate] = useState<boolean>(false);
  const matchesMobileView = useMediaQuery("(max-width:600px)");

  const [eventsData, setEventsData] = useState<any[]>([]);

  const [workingHoursData, setWorkingHoursData] = useState<any[]>([]);
  const [modalDataHours, setModalDataHours] = useState<number>(0);

  useEffect(() => {
    // seCalendarLoading(true);
    if (!!emp_project && emp_project.length > 0) {
      let holidayAndLeaveList: any[] = [];

      emp_project[0].time_tab.forEach((self, index) => {
        if (self.isHoliday && self.holidayInfo) {
          holidayAndLeaveList.push({
            date: moment(self.holidayInfo.date).format("YYYY-MM-DD"),
            title: self.holidayInfo.holiday_name,
            leaveCount: 1,
            id: `${self.time_sheet_id}`,
            color: "red",
            isBlock: true,
          });
        } else {
          if (self.isLeave && self?.emp_leave_data) {
            holidayAndLeaveList.push({
              date: moment(self.date).format("YYYY-MM-DD"),
              title: self.emp_leave_data.leave_type_name,
              leaveCount: Number(self.emp_leave_data.leave_count),
              id: `${self.time_sheet_id}`,
              isBlock: true,
              color: colorGenerator(
                self.emp_leave_data.leave_type_name,
                1,
                200
              ),
            });
          }
        }
      });
      // const updatedList = holiday_list.map((self) => ({
      //   date: self.date,
      //   id: `${self.holiday_id}`,
      //   title: self.holiday_name,
      // }));

      setEventsData(holidayAndLeaveList);
      setTempEventsData(holidayAndLeaveList);
    } else {
      // setEventsData([]);
    }
  }, [emp_project]);

  useEffect(() => {
    let dateWiseHours: any = {};

    emp_project
      .map((self) => self.time_tab)
      .flat(2)
      .forEach((_self) => {
        if (dateWiseHours[moment(_self.date).format("YYYY-MM-DD")]) {
          dateWiseHours[moment(_self.date).format("YYYY-MM-DD")] += Number(
            _self.work_hours
          );
        } else {
          dateWiseHours[moment(_self.date).format("YYYY-MM-DD")] = Number(
            _self.work_hours
          );
        }
      });
    let processedWorkingHoursData: any[] = [];
    Object.entries(dateWiseHours).forEach((self: any) => {
      if (Number(self[1]) >= 1 && Number(self[1]) <= 8) {
        processedWorkingHoursData.push({
          date: moment(self[0]).format("YYYY-MM-DD"),
          title: `   ${self[1]} Hrs `,
          id: self[0],
        });
      }
    });
    setWorkingHoursData(processedWorkingHoursData);
  }, [emp_project, mutate]);

  const [openProjectAllocateModal, setOpenProjectAllocateModal] =
    useState<boolean>(false);

  const [startingDate, setStartingDate] = useState<any>();

  const [endingDate, setEndingDate] = useState<any>();

  useEffect(() => {
    if (
      Array.isArray(emp_project) &&
      emp_project.length > 0 &&
      Array.isArray(emp_project[0].time_tab) &&
      emp_project[0].time_tab.length > 0
    ) {
      setStartingDate(emp_project[0].time_tab[0].date);
      setEndingDate(
        emp_project[0].time_tab[emp_project[0].time_tab.length - 1].date
      );
    }
  }, [emp_project]);

  const handleDateClick = async (selected: any, isCustom = false) => {
    setOpenProjectAllocateModal(false);
    const currDate = isCustom
      ? moment(selected).format("YYYY-MM-DD")
      : moment(selected.startStr).format("YYYY-MM-DD");

    if (
      emp_project[0].time_tab.find(
        (self, index) => moment(self.date).format("YYYY-MM-DD") === currDate
      )
    ) {
      if (
        tempeventsData.find(
          (self) =>
            moment(self.date).format("YYYY-MM-DD") === currDate &&
            Number(self?.leaveCount) > 0.5
        ) ||
        (new Date(currDate).getDay() === 6 &&
          environment.leave_application.holiday_sat.includes(
            Math.ceil(new Date(currDate).getDate() / 7)
          ))
      ) {
        setOpenProjectAllocateModal(false);
        return;
      }

      setSelectedDate(currDate);
      setTimeout(() => {
        setOpenProjectAllocateModal(true);
      }, 300);
    } else {
      setOpenProjectAllocateModal(false);
    }
  };
  // const eventsData = [{ id: "1", title: "Doll Purnima", date: "2023-03-06" }];
  const saveBtnClick = () => {
    if (saveButtonRef?.current) {
      // setMutate(false);
      saveButtonRef.current?.click();
      // setTimeout(() => {
      //   setMutate(true);
      // }, 3000);

      setOpenProjectAllocateModal(false);
    }
  };
  const handleYearChange = (event: any) => {};
  useEffect(() => {
    const arr: any[] = [];
    // reset();
    let tStatus: string = "";
    !!emp_project &&
      emp_project.forEach((self, pIndex) => {
        self.time_tab.forEach((_self, index) => {
          tStatus = _self.status;
          setValue(`time_tab.[${index}].work_hours`, "");
          setValue(`time_tab.[${index}].date`, "");
          setValue(`time_tab.[${index}].project_id`, "");
          setValue(`time_tab.[${index}].time_sheet_id`, "");

          arr.push({
            work_hours: _self.work_hours,
            project_id: self.project_id,
            date: _self.date,
            time_sheet_id: _self.time_sheet_id,
            status: _self.status,
          });
          // const adefd = {
          //   `time_tab.[${count}].work_hours`: count,
          //   `time_tab.[${count}].project_id`:_self.project_id,
          //   `time_tab.[${count}].date`: _self.date,
          //   `time_tab.[${count}].time_sheet_id`:
          //   _self.time_sheet_id
          // }
        });
      });

    setStatus(tStatus);
    arr.forEach((self, index) => {
      setValue(
        `time_tab.[${index}].work_hours`,
        self.work_hours
          ? self.work_hours
          : tStatus.toLowerCase() === "initial"
          ? self.work_hours
          : 0
      );
      setValue(`time_tab.[${index}].date`, self.date);
      setValue(`time_tab.[${index}].project_id`, self.project_id);
      setValue(`time_tab.[${index}].time_sheet_id`, self.time_sheet_id);
      //           `time_tab.[${count}].project_id`:_self.project_id,
      //           `time_tab.[${count}].date`: _self.date,
      //           `time_tab.[${count}].time_sheet_id`:
      //           _self.time_sheet_id
    });
  }, [emp_project, defaultDate, mutate, openProjectAllocateModal]);

  useEffect(() => {
    setMutate(true);
    setCalendarMuate(true);
  }, [eventsData.length]);

  return (
    <Paper
      sx={{
        marginInline: matchesMobileView ? 0.3 : 5,
        p: matchesMobileView ? "" : "10px 10px 50px 10px",
        borderRadius: "4px",
        marginTop: 3,

        backgroundColor: "#fff",
      }}
    >
      <Dialog
        fullWidth
        maxWidth="xl"
        open={
          openProjectAllocateModal &&
          calendarMutate &&
          mutate &&
          matchesMobileView
        }
        onClose={() => setOpenProjectAllocateModal(false)}
        sx={{ zIndex: "999 !important" }}
      >
        <DialogTitle sx={{ backgroundColor: "#1565c0", color: "#fff", p: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <p style={{ fontSize: "17px" }}>Allocated Projects</p>
              <p style={{ fontSize: "13px" }}>
                ( {moment(selectedDate).format("DD-MMM-YYYY")} )
              </p>
            </Box>
            <CloseIcon
              onClick={() => setOpenProjectAllocateModal(false)}
              sx={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{}}>
            {emp_project.map((self, index) => (
              <>
                <Grid item xs={12} my={1}>
                  <CalendarProjectAllocation
                    control={control}
                    errors={errors}
                    emp_project={self}
                    status={status}
                    isworklist={isworklist}
                    notShow={notShow}
                    notEditable={notEditable}
                    selectedDate={selectedDate}
                    pIndex={index}
                    setModalDataHours={setModalDataHours}
                  />
                </Grid>
              </>
            ))}
            <Grid
              item
              sm={12}
              mb={1}
              mt={5}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: matchesMobileView ? "column" : "row",

                width: "100%",
                gap: 1,
              }}
            >
              {(!notShow && isworklist) ||
              (!["approved", "initiate"].includes(status.toLowerCase()) &&
                !notEditable) ? (
                <>
                  <Box
                    sx={{
                      width: matchesMobileView ? "100%" : "45%",
                    }}
                  >
                    <Button
                      onClick={() => saveBtnClick()}
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 1,
                        width: "100%",
                        fontSize: "12px",
                      }}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      <b>Save</b>
                    </Button>
                  </Box>
                  {/* <Box
                    sx={{
                      width: matchesMobileView ? "100%" : "45%",
                    }}
                  >
                    {status.toLowerCase() !== "initiate" &&
                    status.toLowerCase() === "saved" &&
                    showApprovalBtn ? (
                      <Button
                        type="button"
                        onClick={() => handleApprovalRequest()}
                        sx={{
                          color: "#fff",
                          backgroundColor: "#1565C0",
                          paddingX: 1,
                          fontSize: "12px",

                          width: "100%",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        <b>Send For Approval</b>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box> */}
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* <CalendarWiseProjectAllocatioinModal
        open={openProjectAllocateModal}
        setOpen={setOpenProjectAllocateModal}
        emp_project={emp_project}
        selectedDate={selectedDate}
        control={control}
        errors={errors}
        status={status}
        isworklist={isworklist}
        notShow={notShow}
        notEditable={notEditable}
        saveBtnClick={saveBtnClick}
        modalDataHours={modalDataHours}
        setModalDataHours={setModalDataHours}
      /> */}
      <Grid container gap={matchesMobileView ? 0 : 2}>
        {mutate &&
        calendarMutate &&
        emp_project.length > 0 &&
        !matchesMobileView ? (
          <Grid
            item
            sm={matchesMobileView ? 12 : 8}
            width="100%"
            // xs={openProjectAllocateModal ? 8 : 10}
            sx={{ minHeight: "65vh", py: 5 }}
          >
            <FullCalendar
              height={matchesMobileView ? "45vh" : "65vh"}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                ListPlugin,
              ]}
              headerToolbar={{
                right: "",
              }}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={() => {}}
              eventsSet={(events) => setEventsData(events)}
              dateClick={(event) => {}}
              initialEvents={[...eventsData, ...workingHoursData]}
              datesSet={handleYearChange}
              initialDate={defaultDate}
            />
            {mutate && calendarMutate && emp_project.length > 0 ? (
              <Box
                mb={1}
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: matchesMobileView ? "column" : "row",
                  px: 2,
                  gap: 2,
                }}
              >
                {(!notShow && isworklist) ||
                (!["approved", "initiate"].includes(status.toLowerCase()) &&
                  !notEditable) ? (
                  <>
                    {/* <Box
                      sx={{
                        width: matchesMobileView ? "100%" : "45%",

                        display: "flex",
                        flexDirection: matchesMobileView ? "column" : "row",
                        justifyContent: matchesMobileView
                          ? "center"
                          : "flex-end",
                      }}
                    >
                      <Button
                        ref={saveButtonRef}
                        type="submit"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#1565C0",
                          paddingX: 1,
                          width: matchesMobileView ? "88%" : "200px",

                          fontSize: "12px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        <b>Save</b>
                      </Button>
                    </Box> */}
                    <Box
                      sx={{
                        width: matchesMobileView ? "100%" : "95%",
                        display: "flex",
                        flexDirection: matchesMobileView ? "column" : "row",
                        justifyContent: matchesMobileView ? "center" : "center",
                        // backgroundColor:'red'
                      }}
                    >
                      {status.toLowerCase() !== "initiate" &&
                      status.toLowerCase() === "saved" &&
                      showApprovalBtn ? (
                        <Button
                          type="button"
                          onClick={() => handleApprovalRequest()}
                          sx={{
                            color: "#fff",
                            backgroundColor: "#1565C0",
                            paddingX: 1,
                            fontSize: "12px",
                            width: matchesMobileView ? "88%" : "200px",
                          }}
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          <b>Send For Approval</b>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <></>
        )}
        {mutate &&
        calendarMutate &&
        emp_project.length > 0 &&
        matchesMobileView ? (
          <Grid
            item
            sm={12}
            width="100%"
            // xs={openProjectAllocateModal ? 8 : 10}
            sx={{ py: 5 }}
          >
            <MobileCalendar
              defaultDate={defaultDate}
              handleDateClick={handleDateClick}
              initialEvents={[...tempeventsData, ...workingHoursData]}
            />
            {mutate && calendarMutate && emp_project.length > 0 ? (
              <Box
                mb={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: matchesMobileView ? "column" : "row",
                  px: 2,
                  gap: 2,
                  mt: 4,
                  mb: 2,
                }}
              >
                {(!notShow && isworklist) ||
                (!["approved", "initiate"].includes(status.toLowerCase()) &&
                  !notEditable) ? (
                  <>
                    <Box
                      sx={{
                        width: matchesMobileView ? "100%" : "45%",

                        display: "flex",
                        flexDirection: matchesMobileView ? "column" : "row",
                        justifyContent: matchesMobileView
                          ? "center"
                          : "flex-end",
                      }}
                    >
                      <Button
                        type="submit"
                        ref={saveButtonRef}
                        sx={{
                          color: "#fff",
                          backgroundColor: "#1565C0",
                          paddingX: 1,
                          width: matchesMobileView ? "88%" : "200px",

                          fontSize: "12px",
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        <b>Save</b>
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        width: matchesMobileView ? "100%" : "45%",

                        display: "flex",
                        margin: "0 0px 10px 0",
                        flexDirection: matchesMobileView ? "column" : "row",
                        justifyContent: matchesMobileView ? "center" : "",
                      }}
                    >
                      {status.toLowerCase() !== "initiate" &&
                      status.toLowerCase() === "saved" &&
                      showApprovalBtn ? (
                        <Button
                          type="button"
                          onClick={() => handleApprovalRequest()}
                          sx={{
                            color: "#fff",
                            backgroundColor: "#1565C0",
                            paddingX: 1,
                            fontSize: "12px",
                            width: matchesMobileView ? "88%" : "200px",
                          }}
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          <b>Send For Approval</b>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <></>
        )}
        {openProjectAllocateModal &&
        calendarMutate &&
        mutate &&
        !matchesMobileView ? (
          <>
            <Grid
              item
              sm={3.6}
              sx={{
                mt: "86px",
                height: "70vh",
                overflow: "auto",
                px: 2,
              }}
            >
              <Typography
                style={{
                  // color: "#50535A",
                  // fontWeight: "bold",
                  // fontFamily: "Poppins, sans-serif",
                  // fontSize: 18,
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 20,
                  textAlign: "center",
                  borderBottom: "1px solid gray",
                }}
              >
                Allocated Projects ({" "}
                {moment(selectedDate).format("DD-MMM-YYYY")} )
              </Typography>
              <Grid container sx={{}}>
                {emp_project.map((self, index) => (
                  <>
                    <Grid item xs={12} my={1}>
                      <CalendarProjectAllocation
                        control={control}
                        errors={errors}
                        emp_project={self}
                        status={status}
                        isworklist={isworklist}
                        notShow={notShow}
                        notEditable={notEditable}
                        selectedDate={selectedDate}
                        pIndex={index}
                        setModalDataHours={setModalDataHours}
                      />
                    </Grid>
                  </>
                ))}
                <Grid
                  item
                  xs={12}
                  mb={1}
                  mt={5}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {(!notShow && isworklist) ||
                  (!["approved", "initiate"].includes(status.toLowerCase()) &&
                    !notEditable) ? (
                    <>
                      <Box sx={{ width: "49%" }}>
                        <Button
                          ref={saveButtonRef}
                          type="submit"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#1565C0",
                            paddingX: 1,
                            width: "100%",
                            fontSize: "12px",
                          }}
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          <b>Save</b>
                        </Button>
                      </Box>
                      {/* <Box width="49%">
                        {status.toLowerCase() !== "initiate" &&
                        status.toLowerCase() === "saved" &&
                        showApprovalBtn ? (
                          <Button
                            type="button"
                            onClick={() => handleApprovalRequest()}
                            sx={{
                              color: "#fff",
                              backgroundColor: "#1565C0",
                              paddingX: 1,
                              fontSize: "12px",

                              width: "100%",
                            }}
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            <b>Send For Approval</b>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Box> */}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        {/* {(!notShow && isworklist) ||
        (!["approved", "initiate"].includes(status.toLowerCase()) &&
          !notEditable) ? (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                ref={saveButtonRef}
                type="submit"
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                variant="contained"
                color="primary"
                size="large"
              >
                <b>Save</b>
              </Button>
              {status.toLowerCase() !== "initiate" &&
              status.toLowerCase() === "saved" &&
              showApprovalBtn ? (
                <Button
                  type="button"
                  onClick={() => handleApprovalRequest()}
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  <b>Send For Approval</b>
                </Button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        ) : (
          <></>
        )} */}
      </Grid>
    </Paper>
  );
};

export default CalendarwiseTimeSheet;
