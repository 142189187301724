import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Background } from "reactflow";
import { Padding } from "@mui/icons-material";
import {
  Componenet,
  EmployeeProjectHistory,
  SalaryStructureHistory,
} from "../../../models/employeeModel";
import { ProjectLevels } from "../../../models/customerModel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import moment from "moment";
import { bgcolor } from "@mui/system";
import EmployeeSalaryComponent from "./EmployeeSalaryComponent";

function EmployeeSalaryStructureDetails({
  SalaryHistory,
}: EmployeeSalaryStructureDetailsProps) {
  return (
    <Carousel showThumbs={false} showArrows={true}>
      {!!SalaryHistory &&
        SalaryHistory.map((row) => (
          <Grid
            container
            direction={"column"}
            paddingTop={2}
            // key={row.salary_structure_id}
          >
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CardContent sx={{ width: "100%" }}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Grid item xs={6}>
                      <Grid container direction={"row"}>
                        <Grid item xs={2}>
                          <Avatar
                            sx={{ bgcolor: "#0060FF" }}
                            aria-label="recipe"
                          >
                            {!!row.salary_stucture_name
                              ? row.salary_stucture_name[0]
                              : "A"}
                          </Avatar>
                        </Grid>
                        <Grid item xs={7}>
                          <Grid container direction={"column"}>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#567189",
                                  textAlign: "left",
                                }}
                              >
                                {row.salary_stucture_name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#567189",
                                  textAlign: "left",
                                }}
                              >
                                {`Effective Working Days - ${
                                  !!row.effective_working_days &&
                                  row.effective_working_days < 99
                                    ? row.effective_working_days
                                    : "Full Month"
                                }`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"flex-start"}
                      >
                        <Grid item display={"flex"} justifyContent={"flex-end"}>
                          <Tooltip title="Salary Structure Status">
                            <Typography
                              sx={
                                row.status === "ACTIVE"
                                  ? { color: "green", fontWeight: 600 }
                                  : { color: "grey", fontWeight: 600 }
                              }
                            >
                              {row.status}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Grid container direction={"row"} height={25}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#567189",
                                  textAlign: "end",
                                }}
                              >
                                Assigned On
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  textAlign: "end",
                                }}
                              >
                                {moment(row.start_date).format("DD-MM-YYYY")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction={"row"} height={25}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  // pl: "5%",
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#567189",
                                  textAlign: "end",
                                }}
                              >
                                Valid Upto
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  textAlign: "end",
                                }}
                              >
                                {moment(row.end_date).format("DD-MM-YYYY")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    pb={0.5}
                    justifyContent={"space-evenly"}
                  >
                    {row.componenet
                      .filter(
                        (n, index, array) =>
                          array
                            .map((p) => p.component_type)
                            .indexOf(n.component_type) === index
                      )
                      .sort(
                        (curr, prev) =>
                          Number(curr.component_type) -
                          Number(prev.component_type)
                      )
                      .map((m) => (
                        <>
                          <Grid item xs={4} pl={1}>
                            <Grid container direction={"column"}>
                              <Grid
                                item
                                py={0.5}
                                sx={{
                                  bgcolor: "#0060FF",
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#fff",
                                  }}
                                >
                                  {m.component_type_name}
                                </Typography>
                              </Grid>
                              <EmployeeSalaryComponent
                                m={m}
                                row={row}
                                key={m.compent_id}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
    </Carousel>
  );
}

export default EmployeeSalaryStructureDetails;

interface EmployeeSalaryStructureDetailsProps {
  SalaryHistory: SalaryStructureHistory[];
}
