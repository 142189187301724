import { FC, useState, useEffect, ChangeEvent } from "react";
import {
  ILeaveDesignation,
  ILeaveManagement,
  ILeaveTypeDataDesignationWise,
} from "../../../models/leaveModel";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ILeaveType } from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";
import { AllDesignationList } from "../../../models/organizationModels";
import LeaveManagementActionModal from "./LeaveManagementActionModal";
import DeleteLeaveDialog from "./DeleteLeaveManagementDialog";
import LeaveManagementDetails from "./LeaveManagementDetails";

const LeaveManagementTable: FC<ILeaveManagementTable> = ({
  leaveDesignation,
  GetAllDesignationAction,
  designationList,
  GetAllLeaveTypeAction,
  editLeaveManagementAction,
  createLeaveManagementAction,
  deleteLeaveManagementAction,
  leaveTypeDesignationWise,
  getLeaveDetailsDesignationwiseAction,
  leave_types,
  user,
}): JSX.Element => {
  console.log("leave_types : ", leave_types);
  console.log("designation_list : ", designationList);
  const styles = {
    marginRight: "50px",
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<ILeaveDesignation[]>([]);
  const [delId, setDelId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [actionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
  const [detailsViewOpen, setDetailsViewOpen] = useState<boolean>(false);
  const [selectedDesignation, setSelectedDesgination] = useState<number>(0);
  const [leaveDetails, setLeaveDetails] = useState<ILeaveItem | null>(null);
  const [editablePayload, setIsEditablePayload] =
    useState<ILeaveDesignation | null>(null);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!leaveDesignation
      ? leaveDesignation.filter((row: ILeaveDesignation) => {
          return row.designation_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase());
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!leaveDesignation && Array.isArray(leaveDesignation)) {
      cancelSearch();
    }
  }, [leaveDesignation]);
  const column1: string[] = ["Designation Name", "Allowed Leave", "Actions"];
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  // const handleformPatch = (payload: ILeaveManagement) => {
  //   setIsEditablePayload(payload);
  //   setIsActionModalOpen(true);
  // };
  const handleDeleteRequest = (delId: number): void => {
    setDelId(delId);
    setIsOpen(true);
  };

  console.warn("leave designation : ", leaveDesignation);

  return (
    <Paper>
      <LeaveManagementActionModal
        GetAllDesignationAction={GetAllDesignationAction}
        designationList={designationList.filter((self) => {
          return leaveDesignation.find(
            (_self) => _self.designation_id !== self.designation_id
          );
        })}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        leave_types={leave_types}
        isOpen={actionModalOpen}
        setIsOpen={setIsActionModalOpen}
        createLeaveManagementAction={createLeaveManagementAction}
        user={user}
      />
      <LeaveManagementDetails
        open={detailsViewOpen}
        setOpen={setDetailsViewOpen}
        leaveTypeDesignationWise={leaveTypeDesignationWise}
        getLeaveDetailsDesignationwiseAction={
          getLeaveDetailsDesignationwiseAction
        }
        deleteLeaveManagementAction={deleteLeaveManagementAction}
        selectedDesignation={selectedDesignation}
        leaveDetails={leaveDetails}
        designationList={designationList}
        GetAllDesignationAction={GetAllDesignationAction}
        createLeaveManagementAction={createLeaveManagementAction}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        editLeaveManagementAction={editLeaveManagementAction}
        leaveTypes={leave_types}
        user={user}
      />
      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={10} my={2}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid item xs={12}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "98%", borderRadius: 1, mx: 2 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.designation_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.designation_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.no_of_leave ? row.no_of_leave : 0}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            <IconButton
                              onClick={() => (
                                setDetailsViewOpen(true),
                                setSelectedDesgination(row.designation_id),
                                setLeaveDetails({
                                  designation_name: row.designation_name,
                                  no_of_leave: Number(row.no_of_leave),
                                })
                              )}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
interface ILeaveManagementTable {
  leaveDesignation: ILeaveDesignation[];
  GetAllDesignationAction?: any;
  designationList: AllDesignationList[];
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  editLeaveManagementAction?: any;
  createLeaveManagementAction?: any;
  deleteLeaveManagementAction?: any;
  leaveTypeDesignationWise: ILeaveTypeDataDesignationWise[];
  getLeaveDetailsDesignationwiseAction?: any;
  user?: userDetails;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface ILeaveItem {
  designation_name: string;
  no_of_leave: number;
}
export default LeaveManagementTable;
