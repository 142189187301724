import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  AllProjectBudgetList,
  ProjectList,
  ProjectUnbudgetList,
} from "../../../models/customerModel";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { ReportingManagerList } from "../../../models/employeeModel";
import { style } from "@mui/system";

function CreateBudgetingView({
  project_unbuget_list,
  ReportingManagerList,
  setOrgId,
  setprojectId,
  Submit,
  SubmitUpdatedData,
  project_budget,
}: CreateBudgetingViewProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const onSubmit = (data: any) => {
    console.log("sss", data);
    if (!!project_budget) {
      let payload: AllProjectBudgetList = { ...project_budget, ...data };
      SubmitUpdatedData(payload);
    } else {
      Submit(data);
    }
  };
  useEffect(() => {
    if (!!project_budget) {
      setValue("project_id", project_budget.project_id);
      setprojectId(project_budget.project_id);
      setOrgId(project_budget.org_id);
      setValue("bid_price_with_gst", project_budget.bid_price_with_gst);
      setValue("bid_price_without_gst", project_budget.bid_price_without_gst);
      setValue(
        "profitability_percentage",
        project_budget.profitability_percentage
      );
      setValue("duration", project_budget.duration);
      setValue("lead_id", project_budget.lead_id);
      setValue("working_hour_perday", project_budget.working_hour_perday);
      setValue("field_one_name", project_budget.field_one_name);
      setValue("field_two_name", project_budget.field_two_name);
      setValue("field_three_name", project_budget.field_three_name);
      setValue("field_four_name", project_budget.field_four_name);
      setValue("field_five_name", project_budget.field_five_name);
      setValue("field_six_name", project_budget.field_six_name);
      setValue("field_seven_name", project_budget.field_seven_name);
      setValue("field_eight_name", project_budget.field_eight_name);
    }
  }, [project_budget]);
  return (
    <>
      {" "}
      <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
        <Grid
          container
          direction={"row"}
          // justifyContent={"center"}
          // alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} marginTop={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
              }}
            >
              {!!project_budget
                ? "Project Budgeting Details"
                : "Create Budgeting"}
            </Typography>
          </Grid>
        </Grid>
        <form>
          <Grid container justifyContent={"center"}>
            <Grid item style={{ marginInline: 10, width: "90%" }}>
              <Divider
                style={{
                  borderColor: "#9E9E9E",
                  opacity: 0.7,
                  width: "90%",
                  marginLeft: "5%",
                }}
              ></Divider>
              {!!project_budget && (
                <Grid
                  container
                  justifyContent="end"
                  alignItems="center"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Switch
                    value={edit_flag}
                    onChange={(event) => SetEditFlag(!event.target.checked)}
                  />
                  <Typography style={{ color: "#A2A2A2" }}>
                    Edit Project Budgeting
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"

                    // width={"100%"}
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                          // textAlign: "center"
                        }}
                      >
                        Project Name
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"project_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={project_unbuget_list}
                            keys={"project_id"}
                            label={"project_name"}
                            onBlur={onBlur}
                            style={{ width: "100%" }}
                            readOnly={!!project_budget ? edit_flag : !edit_flag}
                            onChange={(value: any) => (
                              onChange(value),
                              setOrgId(
                                project_unbuget_list.find(
                                  (m) => m.project_id == value
                                )?.org_id
                              ),
                              setprojectId(+value)
                            )}
                            placeholder={"Search Project"}
                            value={!!value ? value : ""}
                            errors={!!errors["project_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"

                    // width={"100%"}
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                          // textAlign: "center"
                        }}
                      >
                        Bid Price(With GST)
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"bid_price_with_gst"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            // InputProps={{ inputProps: { min: 1 } }}
                            placeholder={"Bid Price(With GST)"}
                            error={!!errors["bid_price_with_gst"]}
                            helperText={
                              errors.bid_price_with_gst &&
                              `${errors.bid_price_with_gst.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

                // width={"100%"}
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"

                    // width={"100%"}
                  >
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                          // textAlign: "center"
                        }}
                      >
                        Bid Price(Without GST)
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: 6 }}>
                      <Controller
                        control={control}
                        name={"bid_price_without_gst"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            // InputProps={{ inputProps: { min: 1 } }}
                            placeholder={"Bid Price(Without GST)"}
                            error={!!errors["bid_price_without_gst"]}
                            helperText={
                              errors.bid_price_without_gst &&
                              `${errors.bid_price_without_gst.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid
                      item
                      xs={3}
                      //justifyContent="space-between"
                      //alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                        }}
                      >
                        Estimated Efforts(In days)
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"duration"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            // InputProps={{ inputProps: { min: 1 } }}
                            placeholder={"Duration(In days)"}
                            error={!!errors["duration"]}
                            helperText={
                              errors.duration &&
                              `${errors.duration.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid
                      item
                      xs={3}
                      //justifyContent="space-between"
                      //alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                        }}
                      >
                        Profitability(%)
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: 6 }}>
                      <Controller
                        control={control}
                        name={"profitability_percentage"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            // InputProps={{ inputProps: { min: 1 } }}
                            placeholder={"Profitability(%)"}
                            error={!!errors["profitability_percentage"]}
                            helperText={
                              errors.profitability_percentage &&
                              `${errors.profitability_percentage.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid
                      item
                      xs={3}
                      //justifyContent="space-between"
                      //alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                        }}
                      >
                        Working Hour(Per day)
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"working_hour_perday"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            value={value}
                            // InputProps={{ inputProps: { min: 1, max: 8 } }}
                            placeholder={"Working Hour(Per day)"}
                            error={!!errors["working_hour_perday"]}
                            helperText={
                              errors.working_hour_perday &&
                              `${errors.working_hour_perday.message}* This field is Required`
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid
                      item
                      xs={3}
                      //justifyContent="space-between"
                      //alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                        }}
                      >
                        Timesheet Approver
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"lead_id"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={ReportingManagerList}
                            keys={"employee_id"}
                            readOnly={!!project_budget ? edit_flag : !edit_flag}
                            label={"employee_id"}
                            extras={"employee_name"}
                            extras2={"designation_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Project Lead"}
                            value={!!value ? value : ""}
                            errors={errors.lead_id}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                        }}
                      >
                        Expense 1
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"field_one_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 1"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 20 }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                        }}
                      >
                        Expense 2
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: 5 }}>
                      <Controller
                        control={control}
                        name={"field_two_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 2"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                        }}
                      >
                        Expense 3
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"field_three_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 3"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                        }}
                      >
                        Expense 4
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ marginLeft: 5 }}>
                      <Controller
                        control={control}
                        name={"field_four_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 4"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "0%",
                        }}
                      >
                        Expense 5
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        control={control}
                        name={"field_five_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 5"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "7%",
                        }}
                      >
                        Expense 6
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={{marginLeft:5}}>
                      <Controller
                        control={control}
                        name={"field_six_name"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100%" }}
                            inputProps={{
                              readOnly: !!project_budget
                                ? edit_flag
                                : !edit_flag,
                            }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Expense 6"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="start" alignItems="center">
                    
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "0%",
                          }}
                        >
                          Expense 7
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name={"field_seven_name"}
                          defaultValue={""}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              inputProps={{
                                readOnly: !!project_budget
                                  ? edit_flag
                                  : !edit_flag,
                              }}
                              onBlur={onBlur}
                              size="small"
                              onChange={onChange}
                              value={value}
                              placeholder={"Expense 7"}
                            />
                          )}
                        />
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid item xs={6}>
                <Grid
                container
                justifyContent="start"
                alignItems="center"
                
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "7%",
                    }}
                  >
                    Expense 8
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{marginLeft:5}}>
                  <Controller
                    control={control}
                    name={"field_eight_name"}
                    defaultValue={""}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: !!project_budget ? edit_flag : !edit_flag,
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Expense 8"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
                </Grid>
              </Grid>

             
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            {((edit_flag && !project_budget) ||
              (!edit_flag && !!project_budget)) && (
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                <b>{!!project_budget ? "Update" : "Add"}</b>
              </Button>
            )}
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default CreateBudgetingView;

interface CreateBudgetingViewProps {
  project_unbuget_list: ProjectUnbudgetList[];
  ReportingManagerList: ReportingManagerList[];
  setOrgId?: any;
  setprojectId?: any;
  Submit?: any;
  SubmitUpdatedData?: any;
  project_budget?: AllProjectBudgetList;
}


