import React, { useEffect } from "react";
import PromotionBatchDetailView from "./PromotionBatchDetailView";
import { connect } from "react-redux";
import { ViewPromotion } from "../../../models/promotionModel";
import { StoreState } from "../../../models/reduxModels";
import { DeletePromotionAction, ViewPromotionAction } from "../../../stores/actions/promotionAction";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

function PromotionDetails({
  ViewPromotionAction,
  view_promotion,
  DeletePromotionAction
}: PromotionDetailsProps) {
  const { id } = useParams();
  console.log("id", id);

  useEffect(() => {
    ViewPromotionAction({
      promotion_salary_batch_id: id,
    });
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const DiscardEmploye =(data: any)=>{
    let payload: any = {
      data1: data,
      data2: {
        employee_id: null,
        promotion_status: null,
        revision_status: null,
        new_designation_id: null,
        new_salary_structure_id: null,
      },
      enqueueSnackbar: enqueueSnackbar,
    };
    DeletePromotionAction(payload);
  }
  return (
    <PromotionBatchDetailView
      view_promotion={view_promotion}
      ViewPromotionAction={ViewPromotionAction}
      DiscardEmploye={DiscardEmploye}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    view_promotion: state.promotion.view_promotion,
  };
};

const mapDispatchToProps = {
  ViewPromotionAction,
  DeletePromotionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetails);
interface PromotionDetailsProps {
  ViewPromotionAction?: any;
  view_promotion?: ViewPromotion[];
  DeletePromotionAction?: any;
}
