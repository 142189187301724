import { createTheme } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";

const HrmsLightTheme = createTheme({
  palette: createPalette({}),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", "Poppins"',
  },
});

export default HrmsLightTheme;
