import React from "react";
import { connect } from "react-redux";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  AttendanceTimeSheetListAction,
  UploadAttendanceTimeSheetAction,
} from "../../../stores/actions/attendanceAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import AttendanceTimeSheetView from "./AttendanceTimeSheetView";
import { AttendanceTimeSheetList } from "../../../models/attendanceModel";
import { userDetails } from "../../../models/userModels";
import { GetAllProjectAction, GetProjectViewAction } from "../../../stores/actions/customerAction";
import { ProjectList, ViewProject } from "../../../models/customerModel";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { goToTimingSheetFromWorklistAction } from "../../../stores/actions/timingSheetAction";

function AttendanceTimeSheet({
  GetAllOrganizationAction,
  organization_list,
  SidenavAutoCloseOpen,
  AttendanceTimeSheetListAction,
  attendance_timesheet_list,
  UploadAttendanceTimeSheetAction,
  GetAllEmployeeTypeAction,
  employee_type_list,
  user,
  project_detail,
  project_list,
  GetProjectViewAction,
  goToTimingSheetFromWorklistAction
}: AttendanceTimeSheetProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllProjectAction({
        organization_id: null,
      });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const Submit = (data: any) => {
    AttendanceTimeSheetListAction({
      ...data,
    });
  };
  const UploadTimesheet = (data: any) => {
    UploadAttendanceTimeSheetAction({
      data:data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    }
    );

  };
  const ProjectSelect = (data: number) => {
    if (!!data) {
      GetProjectViewAction(+data);
    }
  };
  return (
    <AttendanceTimeSheetView
      organization_list={organization_list}
      Submit={Submit}
      attendance_timesheet_list={attendance_timesheet_list}
      UploadTimesheet={UploadTimesheet}
      employee_type_list={employee_type_list}
      user={user}
      project_detail={project_detail}
      project_list={project_list}
      ProjectSelect={ProjectSelect}
      goToTimingSheetFromWorklistAction={goToTimingSheetFromWorklistAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    attendance_timesheet_list: state.attendance.attendance_timesheet_list,
    employee_type_list: state.organization.employee_type_list,
    user: state.user.userDetails,
    project_detail: state.customer.project_detail,
    project_list: state.customer.project_list,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  AttendanceTimeSheetListAction,
  UploadAttendanceTimeSheetAction,
  SidenavAutoCloseOpen,
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  GetProjectViewAction,
  goToTimingSheetFromWorklistAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceTimeSheet);

interface AttendanceTimeSheetProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  SidenavAutoCloseOpen?: any;
  AttendanceTimeSheetListAction?: any;
  UploadAttendanceTimeSheetAction?: any;
  GetAllEmployeeTypeAction?: any;
  attendance_timesheet_list: AttendanceTimeSheetList[];
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
  project_detail?: ViewProject;
  project_list: ProjectList[];
  GetProjectViewAction?: any;
  goToTimingSheetFromWorklistAction?: any;
}
