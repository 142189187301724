import React, { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { environment } from "../../environment";
import { Grid, Typography, Divider } from "@mui/material";
import EmployeeList from "../employment/EmployeeList";
import OrganizationalHoliday from "./OrganizationalHoliday";
import LeaveTypes from "./LeaveTypes";
import LeaveManagement from "./LeaveManagement";
import LeaveApplication from "./LeaveApplication";
import LeaveApproval from "./LeaveApproval";
import AllLeave from "./AllLeave";

const Leave: FC = (): JSX.Element => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };

  return (
    <>
      {!!getTitle() && (
        <Grid sx={{ marginX: 5, py: 2.5 }}>
          <Typography
            style={{
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route
          path="organizational-holiday"
          element={<OrganizationalHoliday />}
        />
        <Route path="leave-types" element={<LeaveTypes />} />
        <Route path="leave-management" element={<LeaveManagement />} />
        <Route path="leave-approval" element={<LeaveApproval />} />
        <Route path="all-leave" element={<AllLeave />} />
        <Route
          path="employee-leave-application"
          element={<LeaveApplication />}

        />
      </Routes>
    </>
  );
};

export default Leave;
