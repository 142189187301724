import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FC, Dispatch, SetStateAction } from "react";

const DeleteLeaveDialog: FC<IDeleteLeaveDialog> = ({
  open,
  setOpen,
  delRequest,
}): JSX.Element => {
  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are You Sure</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you delete then you can't revert this
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={delRequest}>Yes</Button>
        <Button onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface IDeleteLeaveDialog {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  delRequest?: any;
}

export default DeleteLeaveDialog;
