import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../../models/reduxModels";
import {
  IEmployeeList,
  TimingSheetList,
} from "../../../../models/timingSheetModel";
import { userDetails } from "../../../../models/userModels";
import {
  clearTimeSheetAction,
  GetTimingSheetListAction,
  searchByNameEmpAction,
  UpdateMonthlyTimeSheetAction,
} from "../../../../stores/actions/timingSheetAction";
import LogActivityView from "../../LogActivity/LogActivityView";

function LogActivity({
  GetTimingSheetListAction,
  timing_sheet_list,
  clearTimeSheetAction,
  UpdateMonthlyTimeSheetAction,
  user,
  employeeList,
  searchByNameEmpAction,
}: LogActivityProps) {
  const Submit = (props: any) => {
    console.log("submit   ", props);
    GetTimingSheetListAction({
      employee_id: props?.employee_id,
      month: props?.month,
    });
  };
  console.log(timing_sheet_list);
  return (
    <LogActivityView
      Submit={Submit}
      timing_sheet_list={timing_sheet_list}
      UpdateMonthlyTimeSheetAction={UpdateMonthlyTimeSheetAction}
      clearTimeSheetAction={clearTimeSheetAction}
      user={user}
      isLogView={true}
      notEditable
      employeeList={employeeList}
      searchByNameEmpAction={searchByNameEmpAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    timing_sheet_list: state.timingSheet.timing_sheet_list,
    user: state.user.userDetails,
    employeeList: state.timingSheet.employeeList,
  };
};
const mapDispatchToProps = {
  GetTimingSheetListAction,
  UpdateMonthlyTimeSheetAction,
  clearTimeSheetAction,
  searchByNameEmpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogActivity);

interface LogActivityProps {
  GetTimingSheetListAction: any;
  timing_sheet_list?: TimingSheetList;
  user?: userDetails;
  UpdateMonthlyTimeSheetAction?: any;
  clearTimeSheetAction?: any;
  employeeList: IEmployeeList[];
  searchByNameEmpAction?: any;
}
