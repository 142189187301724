import { Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { ReportingManagerList } from "../../../models/employeeModel";
import { SalaryComponentEditAction } from "../../../stores/actions/organizationActions";

const ReportingManagerDefine = ({
  control,
  errors,
  ReportingManagerList,
}: ReportingManagerDefineProps) => {
  return (
    <Paper
      sx={{
        flexGrow: 1,
        borderRadius: 1,
        border: "1px solid #E0E0E0",
        margin: 2,
      }}
    >
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
      >
        <Grid item xs={5} justifyContent="space-between" alignItems="start">
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            Reporting Manager
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={5.5} style={{ marginLeft: -5 }}>
          <Controller
            control={control}
            name={"reporting_manager"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <Autocompletes
                hasInfo={false}
                option={ReportingManagerList}
                keys={"employee_id"}
                label={"employee_id"}
                extras={"employee_name"}
                extras2={"designation_name"}
                onBlur={onBlur}
                onChange={(value: any) => (
                  onChange(value),
                  SalaryComponentEditAction({ salary_structure_id: +value })
                )}
                placeholder={"Reporting Manager"}
                value={!!value ? value : ""}
                errors={errors.reporting_manager}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportingManagerDefine;
interface ReportingManagerDefineProps {
  control?: any;
  errors?: any;
  ReportingManagerList: ReportingManagerList[];
}
