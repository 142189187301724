import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OrganizationALlList } from "../../../models/organizationModels";
import { CustomerList, StateList } from "../../../models/customerModel";
// let navigateTo = useNavigate();
// const navigate = (url: any) => {
//   navigateTo(url);
// };

function AddCustomerView({
  organization_list,
  Submit,
  customer,
  SubmitUpdatedData,
  state_list,
}: AddCustomerViewProps) {
  const countryOptions: any[] = [
    {
      key: "India",
      value: "India",
    },
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const onSubmit = (data: any) => {
    if (!!customer) {
      let payload: CustomerList = { ...customer, ...data };
      SubmitUpdatedData(payload);
    } else {
      Submit(data);
    }
  };
  useEffect(() => {
    if (!!customer) {
      setValue("organization_id", customer.org_id);
      setValue("customer_name", customer.customer_name);
      setValue("customer_type", customer.customer_type);
      setValue("gst_no", customer.gst_no);
      setValue("customer_desc", customer.customer_desc);
      setValue("address_line1", customer.address_line1);
      setValue("address_line2", customer.address_line2);
      setValue("city", customer.city);
      setValue("district", customer.district);
      setValue("state", customer.state);
      setValue("country", customer.country);
      setValue("pin", customer.pin);
      setValue("pan_no", customer.pan_no);
      setValue("aadhar_no", customer.aadhar_no);
    }
  }, [customer]);
  return (
    <Paper elevation={5} sx={{ marginTop: 2, marginInline: 4 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 8 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(-1)}
            //onClick={() => navigate(`/customer/customer-main/`)}
            // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            {!!customer ? "Customer Details" : "Add Customer"}
          </Typography>
        </Grid>
      </Grid>
      <form>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item style={{ marginInline: 5, width: "97%" }}>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid item xs={10} marginTop={1} marginBottom={2}>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 17,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 10,
                }}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 5 }}
              // width={"100%"}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      Organization
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={organization_list}
                          keys={"organization_id"}
                          label={"organization_name"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Search Organization"}
                          value={!!value ? value : ""}
                          errors={!!errors["organization_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                        // textAlign: "center"
                      }}
                    >
                      Customer Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"customer_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"Customer Name"}
                          error={!!errors["customer_name"]}
                          helperText={
                            errors.customer_name &&
                            `${errors.customer_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      Customer Type
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: 4 }}>
                    <Controller
                      control={control}
                      name={"customer_type"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"Customer Type"}
                          error={!!errors["customer_type"]}
                          helperText={
                            errors.customer_type &&
                            `${errors.customer_type.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      GST No
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"gst_no"}
                      defaultValue={""}
                      rules={{
                        required: false,
                        pattern: {
                          value:
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                          message: "Invalid GST No.",
                        },
                      }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"GST No"}
                          error={!!errors["gst_no"]}
                          helperText={
                            errors.gst_no && `${errors.gst_no.message}`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      PAN No
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: 4 }}>
                    <Controller
                      control={control}
                      name={"pan_no"}
                      defaultValue={""}
                      rules={{
                        required: false,
                        pattern: {
                          value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Invalid PAN",
                        },
                      }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"PAN No"}
                          error={!!errors["pan_no"]}
                          helperText={
                            errors.pan_no && `${errors.pan_no.message}`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
         
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Aadhar No
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"aadhar_no"}
                      defaultValue={""}
                      rules={{
                        required: false,
                        maxLength: 12,
                        minLength: 12,
                      }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"number"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={"Aadhar No"}
                          error={!!errors["aadhar_no"]}
                          helperText={
                            errors.aadhar_no && `${errors.aadhar_no.message}`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid
                item
                xs={1.5}
                //justifyContent="space-between"
                //alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "7%",
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: 4 }}>
                <Controller
                  control={control}
                  name={"customer_desc"}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextareaAutosize
                    style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                      onBlur={onBlur}
                      className={"smaller"}
                      // id="standard-required"
                      onChange={onChange}
                      value={value}
                      placeholder={"Description"}
                      minRows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid item xs={10} marginTop={1} marginBottom={2}>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 17,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 10,
                }}
              >
                Address Details
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      Address Line 1
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"address_line1"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"Address Line 1"}
                          error={!!errors["address_line1"]}
                          helperText={
                            errors.address_line1 &&
                            `${errors.address_line1.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                        // textAlign: "center"
                      }}
                    >
                      Address Line 2
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"address_line2"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"Address Line 2"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"

                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      City
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"city"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"City"}
                          error={!!errors["city"]}
                          helperText={
                            errors.city &&
                            `${errors.city.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                        // textAlign: "center"
                      }}
                    >
                      District
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"district"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          placeholder={"District"}
                          error={!!errors["district"]}
                          helperText={
                            errors.district &&
                            `${errors.district.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      State
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"state"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={state_list}
                          style={{ width: "100%" }}
                          keys={"state_name"}
                          label={"state_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"State"}
                          value={!!value ? value : ""}
                          errors={!!errors["state"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"

                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                        // textAlign: "center"
                      }}
                    >
                      Country
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"country"}
                      defaultValue={"India"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={countryOptions}
                          keys={"key"}
                          label={"value"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          //readOnly={!!customer ? edit_flag : !edit_flag}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Country"}
                          value={!!value ? value : ""}
                          errors={errors.country}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"

                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={3}
                    //justifyContent="space-between"
                    //alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                        // textAlign: "center"
                      }}
                    >
                      Pin
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"pin"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          onBlur={onBlur}
                          // disabled={!!department ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          value={value}
                          style={{ width: "100%" }}
                          // label={"Department Name"}
                          placeholder={"Pin"}
                          error={!!errors["pin"]}
                          helperText={
                            errors.pin &&
                            `${errors.pin.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
              marginRight: 10,
            }}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            size="large"
          >
            <b>{!!customer ? "Update" : "Add"}</b>
          </Button>
        </Grid>
      </form>
    </Paper>
  );
}

export default AddCustomerView;
interface AddCustomerViewProps {
  Submit?: any;
  customer?: CustomerList;
  organization_list: OrganizationALlList[];
  SubmitUpdatedData?: any;
  state_list: StateList[];
}



