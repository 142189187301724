import { Cookies } from "react-cookie";
import { OrganizationAddService } from "../../services/organizationService";
import {
  BatchByDeg,
  batchUpdateService,
  DiscardEmployeeFromBatch,
  GeneratePayRollBatch,
  GetBatchList,
  GetCalcualculatedSalaryComponenet,
  PayBatchDetail,
  PayrollAvailableEmployee,
  PayrollComponenetUpdate,
} from "../../services/payrollService";
import { BeginApiCallAction, ApiCallErrorAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";

export enum PayrollActionTypes {
  Payroll_Available_Employee_List_Success_Action = "[PAYROLL] Payroll Available Employee List Success Action",
  Payroll_Generate_Batch_Success_Action = "[PAYROLL] Payroll Generate Batch Success Action",
  Get_Batch_List_Success_Action = "[PAYROLL] Get Batch List Success Action",
  Get_Batch_Detail_Success_Action = "[PAYROLL] Get Batch Detail Success Action",
  Get_Calculated_Salary_Componenet_Success_Action = "[PAYROLL] Get Calculated Salary Componenet SuccessAction",
  DisCard_Employee_From_Batch_Success_Action = "[PAYROLL] DisCard Employee From Batch Success Action",
  Payroll_Componet_Update_Success_Action = "[PAYROLL] Payroll Componet Update Success Action",
  Update_Batch_Status_Success_Action = "[PAYROLL] Update Batch Status Success Action",
  Get_Batch_List_BY_DEG_Success_Action = "[PAYROLL] Get Batch List BY Deg Success Action",
}

export const PayrollAvailableEmployeeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return PayrollAvailableEmployee(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(PayrollAvailableEmployeeSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const PayrollAvailableEmployeeSuccessAction = (data: any) => {
  return {
    type: PayrollActionTypes.Payroll_Available_Employee_List_Success_Action,
    payload: data,
  };
};

export const GeneratePayrollBatch = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return GeneratePayRollBatch(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar(
            `Payment batch id ${response?.data?.data?.batchId} successfully created.`,
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
          payload.navigateTo("/payroll/payroll-batch/");
          dispatch(GeneratePayrollBatchSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GeneratePayrollBatchSuccessAction = () => {
  return {
    type: PayrollActionTypes.Payroll_Generate_Batch_Success_Action,
  };
};

export const GetBatchListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return GetBatchList(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetBatchListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetBatchListSuccessAction = (payload: any) => {
  return {
    type: PayrollActionTypes.Get_Batch_List_Success_Action,
    payload: payload,
  };
};

export const GetBatchDetailAction = (payload: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return PayBatchDetail(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetBatchDetailSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetBatchDetailSuccessAction = (payload: any) => {
  return {
    type: PayrollActionTypes.Get_Batch_Detail_Success_Action,
    payload: payload,
  };
};

export const GetCalculatedSalaryComponent = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return GetCalcualculatedSalaryComponenet(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetCalcualculatedSalaryComponenetSuccess(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetCalcualculatedSalaryComponenetSuccess = (payload: any) => {
  return {
    type: PayrollActionTypes.Get_Calculated_Salary_Componenet_Success_Action,
    payload: payload,
  };
};

export const DiscardEmployeeFromBatchAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return DiscardEmployeeFromBatch(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetBatchDetailAction(payload.data.batchId));
          payload.enqueueSnackbar(`successfully updated.`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(DiscardEmployeeFromBatchSuccess());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DiscardEmployeeFromBatchSuccess = () => {
  return {
    type: PayrollActionTypes.DisCard_Employee_From_Batch_Success_Action,
  };
};

export const PayrollComponenetUpdateAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return PayrollComponenetUpdate(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetCalculatedSalaryComponent({
              batch_id: payload.data.batch_id,
              employee_id: payload.data.employee_id,
            })
          );
          payload.enqueueSnackbar(`successfully updated.`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(GetBatchDetailAction(payload.data.batch_id));
          dispatch(PayrollComponenetUpdateSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const PayrollComponenetUpdateSuccessAction = () => {
  return {
    type: PayrollActionTypes.Payroll_Componet_Update_Success_Action,
  };
};

export const UpdateBatchAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return batchUpdateService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(-1);
          payload.enqueueSnackbar(`successfully updated.`, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(UpdateBatchSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdateBatchSuccessAction = () => {
  return {
    type: PayrollActionTypes.Update_Batch_Status_Success_Action,
  };
};

export const GetBatchListByDegAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Fatching Details...",
        type: 2,
      })
    );
    return BatchByDeg(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetBatchListByDegSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetBatchListByDegSuccessAction = (payload: any) => {
  return {
    type: PayrollActionTypes.Get_Batch_List_BY_DEG_Success_Action,
    payload: payload,
  };
};
