import React from "react";
import LevelsDataView from "./LevelsDataView";
import { connect } from "react-redux";
import { StoreState } from "../../../../models/reduxModels";
import { LevelTree, LevelsList } from "../../../../models/customerModel";
import {
  GetLocationLevelsAction,
  createLevelAction,
  getLevelDetailsAction,
} from "../../../../stores/actions/customerAction";
import { useParams } from "react-router-dom";
import { ILevelMaster } from "../../../../interfaces/projectDashboard.interface";
import { getLevelByMasterAction } from "../../../../services/customerService";

const LevelsData = ({
  GetLocationLevelsAction,
  getLevelDetailsAction,
  location_levels,
  level_master,
  level_tree,
  createLevelAction,
}: LevelsDataProps) => {
  const { master_id } = useParams();
  console.log(master_id, "asd");

  React.useEffect(() => {
    GetLocationLevelsAction({ level_key_id: master_id });
  }, []);
  console.log(location_levels);
  return (
    <LevelsDataView
      level_tree={level_tree}
      createLevelAction={createLevelAction}
      location_levels={location_levels}
      getLevelDetailsAction={getLevelDetailsAction}
      getLevelByMasterAction={getLevelByMasterAction}
      level_master={level_master.find(
        (m) => !!master_id && m.level_key_id == +master_id
      )}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    location_levels: state.customer.location_levels,
    level_master: state.customer.level_master,
    level_tree: state.customer.level_tree,
  };
};
const mapDispatchToProps = {
  GetLocationLevelsAction,
  getLevelByMasterAction,
  getLevelDetailsAction,
  createLevelAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelsData);

interface LevelsDataProps {
  GetLocationLevelsAction: any;
  location_levels: LevelsList[];
  level_master: ILevelMaster[];
  getLevelByMasterAction?: any;
  getLevelDetailsAction?: any;
  level_tree?: LevelTree;
  createLevelAction?: any;
}
