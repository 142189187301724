import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExperienceDetail1 } from "../../../models/employeeModel";

function EmployeeEditExperienceDetailsPopover({
  anchorEl,
  setAnchorEl,
  experience_details,
  SubmitPopData,
  setaddExpDetail,
  OnChangeExperience,
  DeleteExpAction,
  addExpDetail,
  addExperienceDetails,
}: EmployeeEditExperienceDetailsPopover) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const columns2: any[] = [
    "Company Name",
    "Designation",
    "Start Date",
    "End Date",
    "Yearly CTC",
    "Remarks",
    "Action",
  ];
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!!experience_details) {
      setaddExpDetail(experience_details);
    }
  }, [experience_details]);
  const onSubmit = (data: any) => {
    handleClose();
    SubmitPopData(data);
  };
  return (
    <>
      {" "}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>
              {" "}
              Educational Details
            </Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              item
              marginLeft={9}
              xs={10.5}
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  //   style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns2.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!addExpDetail &&
                      addExpDetail.map((row: any, index: number) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={columns2[0]}>
                            {/* {row.organization_name} */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "company_name",
                                    index
                                  )
                                }
                                inputProps={{ maxLength: 80}}
                                value={row.company_name}
                                placeholder={"Company Name"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[2]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "designation",
                                    index
                                  )
                                }
                                inputProps={{ maxLength: 80}}
                                value={row.designation}
                                placeholder={"Designation"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[3]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "started_at",
                                    index
                                  )
                                }
                                value={row.started_at}
                                placeholder={"Started At"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[4]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "ended_at",
                                    index
                                  )
                                }
                                value={row.ended_at}
                                placeholder={"Ended At"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[5]}>
                            {/* 80 */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"number"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "ctc",
                                    index
                                  )
                                }
                                onInput={(e: any) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 7);
                                }}
                                value={row.ctc}
                                placeholder={"Yearly CTC"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[6]}>
                            {/* Remarks */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeExperience(
                                    value.target.value,
                                    "remarks",
                                    index
                                  )
                                }
                                inputProps={{ maxLength: 80}}
                                value={row.remarks}
                                placeholder={"Remarks"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[7]}>
                            {addExpDetail.length > 0 && (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => DeleteExpAction(index)}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={3}>
                <Button
                  onClick={() => addExperienceDetails()}
                  size="medium"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default EmployeeEditExperienceDetailsPopover;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3A98B9",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface EmployeeEditExperienceDetailsPopover {
  anchorEl?: any;
  setAnchorEl?: any;
  experience_details: ExperienceDetail1[];
  SubmitPopData?: any;
  setaddExpDetail?: any;
  OnChangeExperience?: any;
  DeleteExpAction?: any;
  addExpDetail?: any;
  addExperienceDetails?: any;
}
