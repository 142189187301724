import { baseUrl, environment } from "../environment";
import {
  AllLeaveListPayload,
  ApproveLeaveStatusPayload,
  IAddHolidayTyPayload,
  IApplyLeavePayload,
  ICalculateLeaveAmount,
  ICancelLeavePayload,
  ICreateLeaveManagementPayload,
  ICreateLeaveTypePayload,
  IEditHolidayTyPayload,
  IEditLeaveManagementPayload,
  IEditLeavePayload,
  IGetLeaveAmountPayload,
  LeaveApprovalList,
  LeaveApprovalListPayload,
} from "../models/leaveModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});

// *********************************   leave type service family   *******************************

export const GetAllLeaveTypeService = (orgId?: number) => {
  if (!!orgId)
    return serviceClient.get(`${environment.urls.LeaveTypeGetAll}/${orgId}`);
  return serviceClient.get(`${environment.urls.LeaveTypeGetAll}`);
};
export const GetLeaveTypeByIdService = (leaveId: number) => {
  return serviceClient.get(`${environment.urls.GetLeaveTypeById}/${leaveId}`);
};
export const EditLeavelTypeService = (payload: any) => {
  return serviceClient.post(`${environment.urls.EditLeaveType}`, payload);
};
export const deleteLeaveTypeService = (leaveTypeId: number) => {
  return serviceClient.post(`${environment.urls.DeleteLeaveType}`, {
    leave_type_id: leaveTypeId,
  });
};
export const createLeaveTypeService = (payload: ICreateLeaveTypePayload) => {
  return serviceClient.post(`${environment.urls.createLeaveType}`, payload);
};

// **************************   organizations holiday service family   *************************

export const createHolidayService = (payload: IAddHolidayTyPayload) => {
  return serviceClient.post(`${environment.urls.addHoliday}`, payload);
};

export const editHolidayService = (payload: IEditHolidayTyPayload) => {
  return serviceClient.post(`${environment.urls.editHoliday}`, payload);
};

export const getAllHolidaysService = (
  orgId?: number,
  year?: number,
  selectedStates?: string
) => {
  if (!!orgId && !!year) {
    return serviceClient.get(
      `${environment.urls.holidayList}/${orgId}?year=${year}&states=${
        selectedStates ? selectedStates : ""
      }`
    );
  }
  if (!!orgId && !year)
    return serviceClient.get(
      `${environment.urls.holidayList}/${orgId}?states=${
        selectedStates ? selectedStates : ""
      }`
    );

  if (!!year && !orgId) {
    return serviceClient.get(
      `${environment.urls.holidayList}?year=${year}&states=${
        selectedStates ? selectedStates : ""
      }`
    );
  }
  return serviceClient.get(
    `${environment.urls.holidayList}?states=${
      selectedStates ? selectedStates : ""
    }`
  );
};

export const getHolidayByIdService = (holidayId: number) => {
  return serviceClient.get(`${environment.urls.holidayById}/${holidayId}`);
};

export const deleteHolidayService = (holidayId: number) => {
  return serviceClient.post(`${environment.urls.deleteHoliday}`, {
    holiday_id: holidayId,
  });
};

// ******************************  leave management service family  ***********************************
export const getAllLeaveCountDesignationWise = (orgId?: number) => {
  if (!!orgId)
    return serviceClient.get(
      `${environment.urls.designationWiseLeaveCount}/${orgId}`
    );
  return serviceClient.get(`${environment.urls.designationWiseLeaveCount}`);
};
export const getLeaveDetailsDesignationwiseService = (leaveId: number) => {
  return serviceClient.get(
    `${environment.urls.leaveDetailsDesignationWise}/${leaveId}`
  );
};
export const createLeaveManagementService = (
  payload: ICreateLeaveManagementPayload
) => {
  return serviceClient.post(
    `${environment.urls.createLeaveManagement}`,
    payload
  );
};

export const editLeaveManagementService = (
  payload: IEditLeaveManagementPayload
) => {
  return serviceClient.post(`${environment.urls.editLeaveManagement}`, payload);
};

export const deleteLeaveManagementService = (payload: number) => {
  return serviceClient.post(`${environment.urls.deleteLeaveManagement}`, {
    leave_management_id: payload,
  });
};

export const getAllLeaveManagementService = (orgId?: number) => {
  if (orgId)
    return serviceClient.get(
      `${environment.urls.getAllLeaveManagement}/${orgId}`
    );
  return serviceClient.get(`${environment.urls.getAllLeaveManagement}`);
};

export const getDesignationWiseLeaveService = (descId: number) => {
  return serviceClient.get(
    `${environment.urls.designationwiseleavedetails}/${descId}`
  );
};

export const getEmployeeByDesignationService = (descId: number) => {
  return serviceClient.get(
    `${environment.urls.getEmployeeByDesignation}/${descId}`
  );
};

export const getLeaveAmountService = (payload: IGetLeaveAmountPayload) => {
  return serviceClient.post(
    `${environment.urls.getLeaveTypeAmountData}`,
    payload
  );
};

export const calculateLeaveDaysService = (payload: ICalculateLeaveAmount) => {
  return serviceClient.post(`${environment.urls.calculateLeaveDays}`, payload);
};

export const applyLeaveService = (payload: IApplyLeavePayload) => {
  return serviceClient.post(`${environment.urls.applyLeave}`, payload);
};
export const getLeaveDetailsService = (employee_id: string) => {
  return serviceClient.get(
    `${environment.urls.getLeaveDetails}/${employee_id}`
  );
};
export const getAllLeaveApprovalListService = (
  payload: LeaveApprovalListPayload
) => {
  return serviceClient.post(
    `${environment.urls.getAllLeaveApprovalList}`,
    payload
  );
};
export const UpdateLeaveApprovalStatusService = (
  payload: ApproveLeaveStatusPayload
) => {
  return serviceClient.post(
    `${environment.urls.updateLeaveApproveStatus}`,
    payload
  );
};

export const LeaveCalendarDetailsService = (employeeId: string) => {
  return serviceClient.get(
    `${environment.urls.leaveDetailsData}/${employeeId}`
  );
};

export const getAllLeaveListService = (payload: AllLeaveListPayload) => {
  return serviceClient.post(`${environment.urls.allLeaveList}`, payload);
};

export const editAllLeaveService = (payload: IEditLeavePayload) => {
  return serviceClient.post(`${environment.urls.updateLeave}`, payload);
};

export const getLeaveDetailsForDashboardService = (
  empId: string,
  year: number
) => {
  return serviceClient.get(
    `${environment.urls.getLeaveDetailsForDashboard}/${empId}/${year}`
  );
};

export const cancelLeaveService = (payload: ICancelLeavePayload) => {
  return serviceClient.post(`${environment.urls.cancelLeave}`, payload);
};


export const getStateByAddressIdService = (addressId: number) => {
  return serviceClient.get(
    `${environment.urls.getStateByAddressId}/${addressId}`
  );
};

export const getHolidayByEmployeeService = ({
  employee_id,
  org_id,
}: {
  employee_id: string;
  org_id: string;
}) => {
  return serviceClient.get(
    `${environment.urls.getHolidayByEmployee}/${employee_id}/${org_id}`
  );
};

export const naturalHolidayListService = (employeeId: string) => {
  return serviceClient.get(`${environment.urls.naturalHolidays}/${employeeId}`);
};