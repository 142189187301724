import {
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { watch } from "fs";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import ImageUploader from "./ImageUploader";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  bankList,
  ReportingManagerList,
  ViewEmployee,
} from "../../../models/employeeModel";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import BasicDetails from "./BasicDetails";
import AddressDetails from "./AddressDetails";
import PfDetails from "./PfDetails";
import BankingDetail from "./BankingDetail";
import EducationDetails from "./EducationDetails";
import ExperienceDetails from "./ExperienceDetails";
import OrganizationalDetails from "./OrganizationalDetails";
import { ProjectLevels, StateList, ViewProject } from "../../../models/customerModel";
import { userDetails } from "../../../models/userModels";
import ProfessionalQualification from "./ProfessionalDetails";
function FormFields({
  applicabledeg,
  control,
  errors,
  handleSubmit,
  onSubmit,
  designation_list,
  documents,
  watch,
  fileHandleEvent,
  addEduDetail,
  post_list,
  project_list,
  department_list,
  addExpDetail,
  customer_list,
  employee_type_list,
  employee_timing_list,
  organization_list,
  OrganizationSelected,
  ReportingManagerList,
  employee_detail,
  salary_structure_list,
  setaddEduDetail,
  SetDocument,
  setaddExpDetail,
  OnChangeCustomer,
  OnprojectSelect,
  project_detail,
  SlaryStructureChange,
  salary_structure,
  attendence_salary_type,
  downloadDoc,
  edit_candidate,
  bank_list,
  user,
  project_levels,
  setValue,
  getValues,
  id2,
  state_list,
  addProfessionalDetails,
setAddProfessionalDetails,
AddlfileHandleEvent,
downloadImage
}: fromFieldsProps) {
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid item style={{ marginInline: 10, width: "98%" }}>
          <BasicDetails
            documents={documents}
            control={control}
            errors={errors}
            fileHandleEvent={fileHandleEvent}
            SetDocument={SetDocument}
            downloadDoc={downloadDoc}
            edit_candidate={edit_candidate}
            user={user}
            edit_flag={edit_flag}
            SetEditFlag={SetEditFlag}
            employee_detail={employee_detail}
            id2={id2}
          />

          <AddressDetails
            control={control}
            errors={errors}
            edit_candidate={edit_candidate}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
            state_list={state_list}
          />

          <PfDetails
            control={control}
            errors={errors}
            edit_candidate={edit_candidate}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
          />
          <BankingDetail
            control={control}
            errors={errors}
            edit_candidate={edit_candidate}
            bank_list={bank_list}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
          />
          <EducationDetails
            addEduDetail={addEduDetail}
            setaddEduDetail={setaddEduDetail}
            edit_candidate={edit_candidate}
            employee_detail={employee_detail}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
          />
          <ProfessionalQualification
            addProfessionalDetails={addProfessionalDetails}
            setAddProfessionalDetails={setAddProfessionalDetails}
            edit_candidate={edit_candidate}
            employee_detail={employee_detail}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
          />
          <ExperienceDetails
            addExpDetail={addExpDetail}
            setaddExpDetail={setaddExpDetail}
            edit_candidate={edit_candidate}
            employee_detail={employee_detail}
            edit_flag={edit_flag}
            user={user}
            id2={id2}
          />
          {/* <ExpectedCtc
            control={control}
            errors={errors}
            edit_candidate={edit_candidate}
          /> */}
          <OrganizationalDetails
            ReportingManagerList={ReportingManagerList}
            applicabledeg={applicabledeg}
            department_list={department_list}
            designation_list={designation_list}
            employee_timing_list={employee_timing_list}
            employee_type_list={employee_type_list}
            organization_list={organization_list}
            salary_structure_list={salary_structure_list}
            OrganizationSelected={OrganizationSelected}
            control={control}
            employee_detail={employee_detail}
            errors={errors}
            watch={watch}
            user={user}
            project_levels={project_levels}
            customer_list={customer_list}
            OnChangeCustomer={OnChangeCustomer}
            project_list={project_list}
            OnprojectSelect={OnprojectSelect}
            project_detail={project_detail}
            post_list={post_list}
            SlaryStructureChange={SlaryStructureChange}
            salary_structure={salary_structure}
            attendence_salary_type={attendence_salary_type}
            edit_candidate={edit_candidate}
            setValue={setValue}
            edit_flag={edit_flag}
            getValues={getValues}
            id2={id2}
            state_list={state_list}
            documents={documents}
            fileHandleEvent={fileHandleEvent}
            SetDocument={SetDocument}
            downloadDoc={downloadDoc}
            AddlfileHandleEvent={AddlfileHandleEvent}
            downloadImage={downloadImage}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        {(!!id2 && +id2 == 1
          ? true
          : !!user &&
            environment.global_veriable.superAdminLevel.includes(
              user.designation
            )
          ? !edit_flag
          : edit_candidate) && (
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
              marginRight: 10,
            }}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            size="large"
          >
            Update Application
          </Button>
        )}
      </Grid>
    </>
  );
}

export default FormFields;

interface fromFieldsProps {
  handleSubmit?: any;
  onSubmit?: any;
  errors?: any;
  control?: any;
  applicabledeg?: any;
  documents: any[];
  designation_list: any[];
  watch?: any;
  fileHandleEvent?: any;
  addEduDetail?: any;
  post_list: any[];
  project_list: any[];
  department_list: any[];
  addExpDetail: any[];
  employee_type_list: any[];
  customer_list: any[];
  employee_timing_list: any[];
  organization_list: any[];
  OrganizationSelected?: any;
  ReportingManagerList: ReportingManagerList[];
  employee_detail?: ViewEmployee;
  salary_structure_list: SalaryStructureList[];
  setaddEduDetail?: any;
  SetDocument?: any;
  setaddExpDetail?: any;
  OnChangeCustomer?: any;
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  downloadDoc?: any;
  edit_candidate: boolean;
  bank_list: bankList[];
  user?: userDetails;
  project_levels?: ProjectLevels;
  setValue?: any;
  getValues: any;
  id2?: number;
  state_list: StateList[];
  addProfessionalDetails?: any;
  setAddProfessionalDetails?: any;
  AddlfileHandleEvent?: any;
  downloadImage?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
