import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CalculatedComponenet } from "../../../models/payrollModel";
import SendIcon from "@mui/icons-material/Send";
const PriceBreakUpPopover = ({
  open,
  handleClose,
  GetSalaryComponet,
  id,
  CalculatedComponenet,
  SaveComponenet,
  edit,
}: PriceBreakUpPopoverprops) => {
  useEffect(() => {
    if (open && !!id) {
      GetSalaryComponet(id);
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item xs={10} marginTop={1} justifyContent={"center"}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20
                // textAlign: "center",
              }}
            >
              Salary Breakup
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "90%",
            marginLeft: "5%",
            marginBottom: 10,
          }}
        ></Divider>
        <Grid style={{ margin: 20 }}>
          {!!CalculatedComponenet &&
            CalculatedComponenet.map((item, index) => (
              <ComponenetItm
                SaveComponenet={SaveComponenet}
                index={index}
                item={item}
                key={index}
                edit={edit}
              />
            ))}
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
            }}
            onClick={() => handleClose(false)}
            variant="contained"
            color="primary"
            size="large"
          >
            Close
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PriceBreakUpPopover;
interface PriceBreakUpPopoverprops {
  handleClose?: any;
  open: boolean;
  id?: string;
  GetSalaryComponet?: any;
  CalculatedComponenet: CalculatedComponenet[];
  SaveComponenet?: any;
  edit: number;
}

const ComponenetItm = ({
  item,
  index,
  SaveComponenet,
  edit,
}: ComponenetItmProps) => {
  const [value, SetValue] = useState<number | undefined>();
  useEffect(() => {
    SetValue(undefined);
  }, [item]);
  console.log(item);

  return (
    <Grid
      container
      justifyContent="start"
      alignItems="center"
      style={{
        backgroundColor: index % 2 == 0 ? "#C0E3FF" : "#ffff",
        padding: 8,
      }}
    // width={"100%"}
    >
      <Grid item xs={1} style={{}}>
        <Typography
          style={{
            fontWeight: "500",
            fontSize: 16,
            fontFamily: "Poppins, sans-serif",
            color: "#626974",
            paddingLeft: "10%",
            // textAlign: "center"
          }}
        >
          {index + 1}.
        </Typography>
      </Grid>
      <Grid item xs={4} style={{}}>
        <Typography
          style={{
            fontWeight: "500",
            fontSize: 16,
            fontFamily: "Poppins, sans-serif",
            color: "#626974",
            paddingLeft: "10%",
            // textAlign: "center"
          }}
        >
          {index + 1} {item.display_name} ( {item.component_type_name} )
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          type={"number"}
          id="standard-required"
          variant="outlined"
          className={"smaller"}
          style={{ width: "100.9%" }}
          label={"Default Amount"}
          // onBlur={onBlur}
          size="small"
          // onChange={onChange}
          value={item.default_calculated_value}
          placeholder={"Default Amount"}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={2} style={{ marginLeft: 5 }}>
        <TextField
          type={"number"}
          id="standard-required"
          variant="outlined"
          className={"smaller"}
          style={{ width: "100.9%" }}
          label={"Actual Amount"}
          // onBlur={onBlur}
          size="small"
          // onChange={onChange}
          value={item.actual_calculated_value}
          placeholder={"Actual Amount"}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      {!item.calculation && (edit == 1 || edit == 2) && (
        <Grid item xs={2} style={{ marginLeft: 5 }}>
          <TextField
            type={"number"}
            id="standard-required"
            variant="outlined"
            className={"smaller"}
            style={{ width: "100.9%" }}
            label={"Revised Amount"}
            // onBlur={onBlur}
            size="small"
            onChange={(value: any) => SetValue(value.target.value)}
            value={value}
          />
        </Grid>
      )}
      {!item.calculation && !!value && (edit == 1 || edit == 2) && (
        <Grid
          item
          xs={11.1}
          style={{
            marginLeft: 5,
            width: "100%",
            margin: "0.7rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => (
              SaveComponenet({
                [item.component_id]: +value,
              }),
              SetValue(undefined)
            )}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Update
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
interface ComponenetItmProps {
  item: CalculatedComponenet;
  index: number;
  SaveComponenet?: any;
  edit: number;
}
