import React from "react";
import CreateInvoiceView from "./CreateInvoiceView";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateInvoiceAction,
  EditInvoiceAction,
} from "../../../stores/actions/invoiceAction";
import { useSnackbar } from "notistack";
import { InvoiceListResponse } from "../../../models/invoiceModel";

function CreateInvoice({
  user,
  CreateInvoiceAction,
  accessToken,
  invoice_list_details,
  EditInvoiceAction,
}: CreateInvoiceProps) {
  const { id } = useParams();
  const { id2 } = useParams();
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const CreateInvoice = (data: any) => {
    let payload: any = {
      invoice_date: data.payment_date,
      status: "DRAFT",
      invoice_no: data.invoice_no,
      invoice_amount: data.invoice_amount,
      remarks: data.remarks,
      project_id: id,
      invoice_file: data.documents,
    };
    console.log("payload", payload);
    CreateInvoiceAction({
      data: payload,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
      pageID: id,
      data2: {
        page_index: 0,
        page_size: 10,
        project_id: id,
      },
    });
  };
  const UpdateInvoice = (data: any) => {
    let payload: any = {
      invoice_date: data.payment_date,
      status: data.status,
      invoice_no: data.invoice_no,
      invoice_amount: data.invoice_amount,
      remarks: data.remarks,
      project_id: id,
      invoice_file: data.documents,
      project_payment_invoice_id: !!data.project_payment_invoice_id
        ? data.project_payment_invoice_id
        : null,
    };
    console.log("payload", payload);
    EditInvoiceAction({
      data: payload,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
      pageID: id,
      data2: {
        page_index: 0,
        page_size: 10,
        project_id: id,
      },
    });
  };
  return (
    <CreateInvoiceView
      user={user}
      id={id}
      CreateInvoice={CreateInvoice}
      accessToken={accessToken}
      invoice_details={
        !!invoice_list_details?.invoice_list &&
        invoice_list_details?.invoice_list?.length > 0
          ? invoice_list_details?.invoice_list?.find(
              (m: any) => !!id2 && m?.project_payment_invoice_id == +id2
            )
          : undefined
      }
      UpdateInvoice={UpdateInvoice}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
    accessToken: state.user.userDetails?.accessToken,
    invoice_list_details: state.invoice.invoice_list_details,
  };
};
const mapDispatchToProps = {
  CreateInvoiceAction,
  EditInvoiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);

interface CreateInvoiceProps {
  user?: userDetails;
  CreateInvoiceAction?: any;
  EditInvoiceAction?: any;
  accessToken?: string;
  invoice_list_details?: InvoiceListResponse;
}
