import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Login";

const LoginRegistration = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/"
        element={<Navigate to={`/${pathname.split("/")[1]}/login`} />}
      />
    </Routes>
  );
};

export default LoginRegistration;
