import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeeTimingList, OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { CreateEmployeeTimingAction, EditEmployeeTimingAction, GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import SetupEmployeeTimingView from "./SetupEmployeeTimingView";

function SetupEmployeeTiming({
  organization_list,
  GetAllOrganizationAction,
  CreateEmployeeTimingAction,
  employee_timing_list,
  EditEmployeeTimingAction
}: SetupEmployeeTimingProps) {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const SubmitUpdatedData = (data: any) => {
    EditEmployeeTimingAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const { id } = useParams();
  const Submit = (data: any) => {
    data.org_id = data.organization_id;
    CreateEmployeeTimingAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
  })};
  React.useEffect(() => {
    GetAllOrganizationAction();
  }, []);
  return (
    <SetupEmployeeTimingView
    employeeTime={employee_timing_list.find((m) => !!id && m.shift_id == +id)}
      organization_list={organization_list}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_timing_list: state.organization.employee_timing_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  CreateEmployeeTimingAction,
  EditEmployeeTimingAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupEmployeeTiming);

interface SetupEmployeeTimingProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  CreateEmployeeTimingAction?: any;
  employee_timing_list:EmployeeTimingList[];
  EditEmployeeTimingAction?:any;
}
