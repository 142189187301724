import React, { Dispatch, FC, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper, useMediaQuery } from "@mui/material";

interface TimeSheetTabProps {
  selectTab: string;
  setSelectTab: any;
}
const TimeSheetTab: FC<TimeSheetTabProps> = ({ selectTab, setSelectTab }) => {
  console.log("selectTab: ", selectTab);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectTab(newValue);
  };
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  return (
    <Paper
      elevation={3}
      sx={{
        marginInline: matchesMobileView ? 0.2 : 3,
        marginTop: 2,
      }}
    >
      <TabContext value={"1"}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList onChange={handleChange}>
            <Grid container direction="row">
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tab
                  label="Show calendar wise"
                  value="calendar"
                  onClick={() => setSelectTab("calendar")}
                  sx={{
                    width: "100%",
                    color: selectTab === "calendar" ? "#0060FF" : "",
                    borderBottom:
                      selectTab === "calendar" ? "2px solid #0060FF" : "",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tab
                  label="Show  All"
                  value="showall"
                  onClick={() => setSelectTab("showall")}
                  sx={{
                    width: "100%",
                    color: selectTab === "showall" ? "#0060FF" : "",
                    borderBottom:
                      selectTab === "showall" ? "2px solid #0060FF" : "",
                  }}
                />
              </Grid>
            </Grid>
          </TabList>
        </Box>
      </TabContext>
    </Paper>
  );
};

export default TimeSheetTab;
