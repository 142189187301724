import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { environment } from "../../../environment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { userDetails } from "../../../models/userModels";
const PaymentDetailsView = ({
  project_payment_list,
  DeleteAction,
  id,
  project_list,
  user,
}: PaymentDetailsViewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = useState<ProjectPaymentList[]>([]);
  const styles = {
    marginRight: "50px",
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!project_payment_list
      ? project_payment_list.filter((row: ProjectPaymentList) => {
          return (
            row?.payment_desc
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.status?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!project_payment_list) {
      cancelSearch();
    }
  }, [project_payment_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [delete_flag, setdeleteFlag] = useState<boolean>(false);
  const [delete_payload, SetdeletePayload] = useState<any>();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Payment Date",
    "Description",
    "Payment Amount(With GST)",
    "Payment Amount(Without GST)",
    "Status",
    "Created On",
    "Action",
  ];
  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Dialog
        open={delete_flag}
        onClose={() => setdeleteFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Payment Details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setdeleteFlag(false)}>
            No
          </Button>
          <Button
            onClick={() => (DeleteAction(delete_payload), setdeleteFlag(false))}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={12} paddingBottom={2}>
          <Grid
            container
            style={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Grid></Grid>
            <Grid>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  color: "#626974",
                }}
              >
                Payment Information of{" "}
                {project_list.find((m) => m.project_id === +id)?.project_name}
              </Typography>
            </Grid>
            <Grid style={{}}>
              <Tooltip title="Project Dashboard">
                <IconButton
                  style={{ color: "#1565C0" }}
                  onClick={() =>
                    navigateTo(`/customer/project-dashboard/${+id}`)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Show Invoice Details">
                <IconButton
                  style={{ color: "#1565C0" }}
                  onClick={() => navigateTo(`/customer/invoice-data/${+id}`)}
                >
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
              {!!user &&
                environment.global_veriable.officialEmployee.includes(
                  user.designation
                ) && (
                  <Tooltip
                    title="Budget Dashboard"
                    onClick={() =>
                      navigateTo(`/customer/budget-dashboard/${+id}`)
                    }
                  >
                    <IconButton>
                      <DashboardIcon style={{ color: "#1565C0" }} />
                    </IconButton>
                  </Tooltip>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => navigate(`/customer/create-payment/${+id}/0`)}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>
      </Grid>
      <Grid marginTop={2}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.project_payment_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {moment(row.payment_date).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.payment_desc}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                          }}
                        >
                          &#8377; {row.project_payment_with_gst}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        <span
                          style={{
                            fontFamily: "Arial",
                            fontWeight: "bold",
                          }}
                        >
                          &#8377; {row.project_payment_without_gst}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        <Typography
                          style={{
                            color: row.status === "ACTIVE" ? "green" : "red",
                            padding: 0,
                          }}
                        >
                          {row.status}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.created_on
                          ? moment(row.created_on)
                              .format("DD/MM/YYYY HH:mm:ss a")
                              .toUpperCase()
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        <Tooltip
                          title="View"
                          onClick={() =>
                            navigate(
                              `/customer/create-payment/${row.project_id}/${row.project_payment_id}`
                            )
                          }
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton>
                            <DeleteIcon
                              color="error"
                              sx={{ cursor: "pointer" }}
                              onClick={() => (
                                setdeleteFlag(true),
                                SetdeletePayload({
                                  project_payment_id: row.project_payment_id,
                                })
                              )}
                            />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              {!!rows && rows.length <= 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <div style={{ color: "red", textAlign: "center" }}>
                      No Data Found{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[50, 100, 500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Paper>
  );
};

export default PaymentDetailsView;

interface PaymentDetailsViewProps {
  project_payment_list: ProjectPaymentList[];
  DeleteAction?: any;
  id?: any;
  project_list: ProjectList[];
  user?: userDetails;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
