import { Divider, Grid } from "@mui/material";
import { log } from "console";
import moment from "moment";
import React, { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { EmployeeAttendanceReport } from "../../../models/employeeModel";
function EmployeeAttendanceDetails({ option }: EmployeeAttendanceDetailsProps) {
  //   const options = [{
  //     name:"Jan,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Feb,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Mar,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Jun,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Jul,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Aug,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   },
  //   {
  //     name:"Sep,2023",
  //     "Present (Days)": 1,
  //     "Absent (Days)": 5,
  //     "Extra Duty (Days)": 5
  //   }
  // ]
  return (
    <>
      <ResponsiveContainer width="100%" height="20%" aspect={3}>
        <BarChart width={500} height={400} data={option}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Present (Days)" fill="#00C49F" />
          <Bar dataKey="Absent (Days)" fill="#F55050" />
          <Bar dataKey="Extra Duty (Days)" fill="#FFBB28" />
          <Brush dataKey="name" height={30} stroke="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      
    </>
  );
}

export default EmployeeAttendanceDetails;
interface EmployeeAttendanceDetailsProps {
  option: any[];
}
