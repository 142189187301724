import { Box } from "@mui/material";
import { FC, useEffect } from "react";
import {
  ILeaveDesignation,
  ILeaveManagement,
  ILeaveType,
  ILeaveTypeDataDesignationWise,
} from "../../../models/leaveModel";
import { AllDesignationList } from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";
import LeaveManagementTable from "./LeaveManagementTable";

const LeaveManagementMain: FC<ILeaveManagementMain> = ({
  user,
  leaveDesignation,
  deleteLeaveManagementAction,
  getAllLeaveCountDesignationAction,
  editLeaveManagementAction,
  createLeaveManagementAction,
  selected_organization_leave,
  GetAllDesignationAction,
  designationList,
  getLeaveDetailsDesignationwiseAction,
  GetAllLeaveTypeAction,
  leave_types,
  leaveTypeDesignationWise,
}): JSX.Element => {
  useEffect(() => {
    if (!!selected_organization_leave) getAllLeaveCountDesignationAction();
  }, [selected_organization_leave]);
  return (
    <Box sx={{ ml: 2 }}>
      <LeaveManagementTable
        leaveDesignation={leaveDesignation}
        designationList={designationList}
        GetAllDesignationAction={GetAllDesignationAction}
        editLeaveManagementAction={editLeaveManagementAction}
        createLeaveManagementAction={createLeaveManagementAction}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        deleteLeaveManagementAction={deleteLeaveManagementAction}
        leaveTypeDesignationWise={leaveTypeDesignationWise}
        getLeaveDetailsDesignationwiseAction={
          getLeaveDetailsDesignationwiseAction
        }
        leave_types={leave_types}
        user={user}
      />
    </Box>
  );
};
interface ILeaveManagementMain {
  leaveDesignation: ILeaveDesignation[];
  user?: userDetails;
  deleteLeaveManagementAction?: any;
  editLeaveManagementAction?: any;
  createLeaveManagementAction?: any;
  getAllLeaveCountDesignationAction?: any;
  GetAllDesignationAction?: any;
  designationList: AllDesignationList[];
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  leaveTypeDesignationWise: ILeaveTypeDataDesignationWise[];
  getLeaveDetailsDesignationwiseAction?: any;
  selected_organization_leave?: number;
}

export default LeaveManagementMain;
