import React from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import DashboardMain from "./DashboardMain";

const Dashboard = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route path="dashboard-main" element={<DashboardMain />} />
      <Route
        path="/"
        element={<Navigate to={`/${pathname.split("/")[1]}/dashboard-main`} />}
      />
    </Routes>
  );
};

export default Dashboard;
