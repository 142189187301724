import { Cookies } from "react-cookie";
import { userDetails } from "../../models/userModels";
import {
  LoginService,
  resetPasswordService,
} from "../../services/userServices";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { IResetPassword } from "../../interfaces/projectDashboard.interface";

export enum UserActionTypes {
  Logout_Success_Action = "[USER] Logout Success Action",
  User_Login_Success_Action = "[USER] User Login Success Action",
  Side_nav_Auto_Close_Open = "[USER] Side Nav Auto Close Open",
}

export const LogOutAction = () => {
  return { type: UserActionTypes.Logout_Success_Action };
};

export const UserLoginAction = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Signing in. Please Wait...",
        type: 2,
      })
    );
    return LoginService(data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(userLoginSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const userLoginSuccessAction = (payload: userDetails) => {
  return { type: UserActionTypes.User_Login_Success_Action, payload: payload };
};
export const SidenavAutoCloseOpen = (payload: boolean) => {
  return { type: UserActionTypes.Side_nav_Auto_Close_Open, payload: payload };
};

export const resetPasswordAction =
  (userBody: any, enqueSnack: any) => (dispatch: any, getState: any) => {
    console.log("action called : ", userBody);
    return resetPasswordService(userBody)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(LogOutAction());
          enqueSnack.enqueueSnackbar("password reset successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

