import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  AllAvailableProjectForClosure,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllAvailableProjectForClosureAction,
  GetAllCustomerAction,
  GetAllProjectAction,
  IntiateProjectClosureAction,
} from "../../../stores/actions/customerAction";
import InitiateProjectClosureView from "./InitiateProjectClosureView";

function InitiateProjectClosure({
  GetAllProjectAction,
  project_list,
  GetAllCustomerAction,
  customer_list,
  IntiateProjectClosureAction,
  GetAllAvailableProjectForClosureAction,
  all_available_projectList_for_closure,
  total_size,
}: InitiateProjectClosureProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  useEffect(() => {
    GetAllProjectAction({ organization_id: null, customer_id: null });
    GetAllCustomerAction({
      organization_id: null,
    });
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const FilterSubmit = (props: any) => {
    GetAllAvailableProjectForClosureAction({
      customer_id: !!props.customer_id ? props.customer_id : "",
      project_id: !!props.project_id ? props.project_id : "",
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const ClearAllFilter = () => {
    GetAllAvailableProjectForClosureAction({
      customer_id: "",
      project_id: "",
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const ProjectClosureAction = (data: any) => {
    console.log("data", data);
    let payload: any = {
      project_id: data,
    };
    IntiateProjectClosureAction({
      data: payload,
      data_list: {
        customer_id: "",
        project_id: "",
        page_size: pagesize,
        page_index: pageindex,
      },
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    GetAllAvailableProjectForClosureAction({
      customer_id: "",
      project_id: "",
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pagesize, pageindex]);
  return (
    <InitiateProjectClosureView
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      ClearAllFilter={ClearAllFilter}
      FilterSubmit={FilterSubmit}
      project_list={project_list}
      customer_list={customer_list}
      GetAllProjectAction={GetAllProjectAction}
      ProjectClosureAction={ProjectClosureAction}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      all_available_projectList_for_closure={
        all_available_projectList_for_closure
      }
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    project_list: state.customer.project_list,
    customer_list: state.customer.customer_list,
    all_available_projectList_for_closure:
      state.customer.all_available_projectList_for_closure,
    total_size: state.customer.all_available_projectList_for_closure?.count,
  };
};

const mapDispatchToProps = {
  GetAllProjectAction,
  GetAllCustomerAction,
  IntiateProjectClosureAction,
  GetAllAvailableProjectForClosureAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitiateProjectClosure);

interface InitiateProjectClosureProps {
  GetAllProjectAction?: any;
  GetAllCustomerAction?: any;
  GetAllAvailableProjectForClosureAction?: any;
  IntiateProjectClosureAction?: any;
  project_list: ProjectList[];
  customer_list: CustomerList[];
  all_available_projectList_for_closure?: AllAvailableProjectForClosure;
  total_size?: number;
}
