import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { EmployeeTypeList, OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteEmployeeTypeAction,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import EmployeeTypeMainView from "./EmployeeTypeMainView";

function EmployeeTypeMain({
  GetAllOrganizationAction,
  organization_list,
  GetAllEmployeeTypeAction,
  employee_type_list,
  DeleteEmployeeTypeAction,
  SidenavAutoCloseOpen
}: EmployeeTypeMainProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllEmployeeTypeAction({ organization_id: null });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const filterData = (props: any) => {
    props.org_id = props.organization_id;
    GetAllEmployeeTypeAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
    // console.log(props.organization_id);
  };
  const Clear = () => {
    GetAllEmployeeTypeAction({
      org_id: null,
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const DeleteAction = (props: number) => {
    // props.org_id = props.organization_id;
    DeleteEmployeeTypeAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <EmployeeTypeMainView
      organization_list={organization_list}
      filterData={filterData}
      Clear={Clear}
      employee_type_list= {employee_type_list}
      DeleteAction={DeleteAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_type_list: state.organization.employee_type_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllEmployeeTypeAction,
  DeleteEmployeeTypeAction,
  SidenavAutoCloseOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTypeMain);

interface EmployeeTypeMainProps {
  GetAllOrganizationAction?: any;
  organization_list?: OrganizationALlList[];
  GetAllEmployeeTypeAction?: any;
  DeleteEmployeeTypeAction?:any;
  employee_type_list : EmployeeTypeList[]
  SidenavAutoCloseOpen?: any;
}
