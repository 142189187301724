import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  UserLoginAction,
  resetPasswordAction,
} from "../../../stores/actions/userAction";
import LoginView from "./LoginView";
import { userDetails } from "../../../models/userModels";

const Login = ({ UserLoginAction, user, resetPasswordAction }: LoginProps) => {
  const submit = (data: any) => {
    UserLoginAction(data);
  };
  return (
    <LoginView
      submit={submit}
      user={user}
      resetPasswordAction={resetPasswordAction}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  UserLoginAction,
  resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

interface LoginProps {
  UserLoginAction?: any;
  user: userDetails | undefined;
  resetPasswordAction?: any;
}
