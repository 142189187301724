import { FC, useEffect, useState } from "react";

import {
  Grid,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TablePagination,
  TableCell,
  tableCellClasses,
  Checkbox,
  TextField,
  Button,
  Box,
  Popover,
  Menu,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useSnackbar } from "notistack";
import moment from "moment";
import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  IEmpDetails,
  ApplicablePostDetail,
  Level,
  ILevelMaster,
  IDetails,
  IProjectDashboardDetails,
} from "../../../interfaces/projectDashboard.interface";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import EmployeeAllocationPopover from "./EmployeeAllocationPopover";
import { AvailableEmpList } from "../../../models/customerModel";
import { environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";

const EmployeeList: FC<IEmployeeList> = ({
  empList,
  posts,
  allEmp,
  editAssociationStatus,
  projectId,
  year,
  managerList,
  setSearchedEmp,
  searchedEmp,
  available_emp_list,
  levelDetails,
  levelMaster,
  projectDashbaordData,
  Submit,
  user,
}) => {
  if (!!year) {
    console.log("employee year : ");
  }
  const [AllocatedEmp, SetAllocatedEmp] = useState<any>([]);
  const [rows, setRows] = useState<IEmpDetails[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openMenu, setOpenMenu] = React.useState<null | HTMLElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = React.useState<string>("all");
  const [listForActivate, setListForActive] = useState<number[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleGetAllDeActivateList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const inActiveIds = rows
        .filter(
          (self) =>
            self.association_status === "ACTIVE" && self.is_supervisor === 0
        )
        .map((row) => row.association_id);
      setListForActive(inActiveIds);
    } else {
      setListForActive([]);
    }
  };
  const handleActionForDeactivate = () => {
    const payload: any = {
      enqueueSnackbar: enqueueSnackbar,
      data: {
        projectIds: projectId,
        year: !!year
          ? Number(dayjs(year).format("DD-MM-YYYY").split("-")[2])
          : 2023,
        editIds: listForActivate,
      },
    };
    if (!!listForActivate && listForActivate.length > 0) {
      editAssociationStatus(payload);
    }
    handleCloseDialog();
  };
  const handleIndividualDeActivateList = (deActiveId: number) => {
    if (listForActivate.includes(deActiveId)) {
      const newDeactiveList = listForActivate.filter(
        (self) => self !== deActiveId
      );
      setListForActive(newDeactiveList);
    } else {
      setListForActive((prev: number[]) => [...prev, deActiveId]);
    }
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
  };
  console.log("emp list : ", empList);
  const columns: string[] = [
    "Emp. ID",
    "Name",
    "Mob. No.",
    "Working Status",
    "Location",
    "Start Date",
    "End Date",
    "Post",
    "Reporting Manager",
  ];
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handlePopOverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  // console.log("emp rows 2 : ", empList);
  // console.log("emp rows : ", rows);
  const requestSearch = (searchedVal: string) => {
    let filteredRows = !!empList
      ? empList.filter(
          (row: IEmpDetails) =>
            row?.association_status
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_id
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row.employee_name
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_mob_no
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_onboarding_status
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase())
        )
      : //   return (
        //     row.association_status === "ACTIVE"
        //     // row?.association_status
        //     //   ?.toLowerCase()
        //     //   .includes(searchedVal.toLowerCase()) ||
        //     // row?.employee_id
        //     //   ?.toLowerCase()
        //     //   .includes(searchedVal.toLowerCase()) ||
        //     // row.employee_name
        //     //   .toLowerCase()
        //     //   .includes(searchedVal.toLowerCase()) ||
        //     // row?.employee_mob_no
        //     //   ?.toLowerCase()
        //     //   .includes(searchedVal.toLowerCase()) ||
        //     // row?.employee_onboarding_status
        //     //   ?.toLowerCase()
        //     //   .includes(searchedVal.toLowerCase()) ||
        //     // row?.association_status?.toLowerCase() === selectedStatus
        //   );
        // })
        [];
    filteredRows = filteredRows.filter(
      (self) =>
        self.project_id === projectId && !managerList.includes(self.employee_id)
    );
    console.log("filtered rows : ", filteredRows, searchedVal);
    setRows(filteredRows);
  };
  const filterStatusWise = () => {
    let filteredRows = !!empList
      ? empList.filter((row: IEmpDetails) => {
          return (
            row?.association_status?.toLowerCase() ===
              selectedStatus.toLowerCase() || selectedStatus === "all"
          );
        })
      : [];
    filteredRows = filteredRows.filter(
      (self) =>
        self.project_id === projectId && !managerList.includes(self.employee_id)
    );

    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!empList) {
      cancelSearch();
    }
  }, [projectId, empList]);
  useEffect(() => {
    if (!!empList && selectedStatus) filterStatusWise();
  }, [selectedStatus, empList]);
  useEffect(() => {
    if (searched.trim().length > 0) {
      requestSearch(searched);
    } else if (searched.trim().length === 0) {
      requestSearch("");
    }
  }, [searched]);

  const handleMenuClose = () => {
    setOpenMenu(null);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const onSubmit = (data: any) => {
    // console.log("ss",data);
    let payload: any = {
      post_id: data?.post_id,
      level1_id: data.level1_id,
      level2_id: data.level2_id,
      level3_id: data.level3_id,
      level4_id: data.level4_id,
      level5_id: data.level5_id,
      reporting_manager: data.employee_id,
      employee_id_list: AllocatedEmp.map((a: any) => a.employee_id),
      project_id: projectDashbaordData?.projectDetails.project_id,
    };
    Submit(payload);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // console.log("rows : ", rows);
  return (
    <Grid marginTop={1} pl={1} container>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are You Sure ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will not revert this
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleActionForDeactivate} autoFocus>
            Yes
          </Button>
          <Button onClick={handleCloseDialog}>No</Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Filter By</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid container justifyContent="start" alignItems="center" mt={2}>
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography style={{ fontWeight: 400 }}>
                  Working Status
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedStatus}
                  label="Age"
                  sx={{ width: "8rem" }}
                  onChange={handleMenuChange}
                >
                  <MenuItem value="all" onClick={handleClose}>
                    All
                  </MenuItem>
                  <MenuItem value="active" onClick={handleClose}>
                    Active
                  </MenuItem>
                  <MenuItem value="in-active" onClick={handleClose}>
                    In-Active
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end ",
                //   marginRight: "2rem",
                marginRight: "0px",
                marginTop: "13px",
                marginBottom: 1,
              }}
            >
              <Button
                size="small"
                sx={{
                  marginLeft: 1,
                }}
                variant="outlined"
              >
                filter
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Popover>
      <Typography
        variant="body1"
        textAlign={"center"}
        color="#0060FF"
        mb={1}
        width="100%"
      >
        Employee List
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="standard"
            sx={{ width: "70%", border: 0, mb: 1 }}
            placeholder="Search..."
            id="fullWidth"
            //value={searched}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearched(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={handlePopOverOpen}>
              <FilterListIcon sx={{ color: "gray", cursor: "pointer" }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {!!listForActivate && listForActivate.length > 0 ? (
        <Button
          variant="contained"
          sx={{ mb: 1, background: "#f44336", mr: 2 }}
          onClick={handleClickOpen}
        >
          De Activate
        </Button>
      ) : (
        <></>
      )}
      <Button
        variant="contained"
        sx={{ mb: 1, background: "#609966" }}
        onClick={handleClick}
      >
        Add Employee
      </Button>
      <EmployeeAllocationPopover
        anchorEl2={anchorEl2}
        setAnchorEl2={setAnchorEl2}
        setSearchedEmp={setSearchedEmp}
        searchedEmp={searchedEmp}
        available_emp_list={available_emp_list}
        levelDetails={levelDetails}
        posts={posts}
        levelMaster={levelMaster}
        projectDashbaordData={projectDashbaordData}
        SubmitPopData={onSubmit}
        AllocatedEmp={AllocatedEmp}
        SetAllocatedEmp={SetAllocatedEmp}
      />
      <TableContainer sx={{ borderRadius: 1 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          //   style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {!!rows &&
              Array.isArray(rows) &&
              rows.filter(
                (row) =>
                  row?.association_status === "ACTIVE" &&
                  row?.is_supervisor === 0
              )?.length > 0 ? (
                <StyledTableCell>
                  <Checkbox
                    color="default"
                    onChange={handleGetAllDeActivateList}
                  />
                </StyledTableCell>
              ) : (
                <StyledTableCell></StyledTableCell>
              )}

              {columns.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rows &&
              rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  console.log("ROWS : ", row);
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.project_id}
                    >
                      {row.association_status === "ACTIVE" &&
                      row.is_supervisor === 0 ? (
                        <StyledTableCell>
                          <Checkbox
                            color="default"
                            onChange={() =>
                              handleIndividualDeActivateList(row.association_id)
                            }
                            checked={listForActivate.includes(
                              row.association_id
                            )}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell></StyledTableCell>
                      )}
                      <StyledTableCell align="left" key={columns[1]}>
                        {!!user && row.association_status === "ACTIVE" && 
                        environment.global_veriable.officialEmployee.includes(
                          user.designation
                        ) ? (
                          <Link
                            to={`/employment/manage-profile/${row.employee_id}`}
                          >
                            {row.employee_id}
                          </Link>
                        ) : (
                          row.employee_id
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="left" key={columns[0]}>
                        {row.employee_name}
                        {/* Emdee */}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.employee_mob_no}
                        {/* Emdee */}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {/* {row.created_on
                        ? moment(row.created_on)
                            .format("DD/MM/YYYY HH:mm:ss a")
                            .toUpperCase()
                        : "N/A"} */}
                        <span
                          style={
                            row.association_status === "ACTIVE"
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {row.association_status}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.level1_name !== null
                          ? row.level2_name !== null
                            ? row.level3_name !== null
                              ? row.level4_name !== null
                                ? row.level5_name !== null
                                  ? row.level1_name +
                                    ">" +
                                    row.level2_name +
                                    ">" +
                                    row.level3_name +
                                    ">" +
                                    row.level4_name +
                                    ">" +
                                    row.level5_name
                                  : row.level1_name +
                                    ">" +
                                    row.level2_name +
                                    ">" +
                                    row.level3_name +
                                    ">" +
                                    row.level4_name
                                : row.level1_name +
                                  ">" +
                                  row.level2_name +
                                  ">" +
                                  row.level3_name
                              : row.level1_name + ">" + row.level2_name
                            : row.level1_name
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.start_date &&
                          moment(row.start_date).format("DD-MMM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        {row.end_date &&
                          moment(row.end_date).format("DD-MMM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[7]}>
                        {!!row.post_id &&
                          posts?.find((self) => self.post_id === row.post_id)
                            ?.post_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[8]}>
                        {/* <Link
                          to={`/employment/manage-profile/${row.employee_supervisor_id}`}
                        > */}
                        {/* <Link
                          to={`/employment/manage-profile/${row.employee_id}`}
                        > */}
                        {row.employee_supervisor_id}
                        {/* </Link> */}

                        {/* </Link> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

const styles = {
  marginRight: "50px",
};

interface IEmployeeList {
  empList: IEmpDetails[];
  posts: ApplicablePostDetail[];
  allEmp: IEmpDetails[];
  editAssociationStatus?: any;
  projectId: number;
  year: dayjs.Dayjs | null;
  managerList: string[];
  setSearchedEmp?: any;
  searchedEmp?: any;
  available_emp_list: AvailableEmpList[];
  levelDetails?: Level[];
  levelMaster?: ILevelMaster;
  projectDashbaordData?: IProjectDashboardDetails;
  Submit: any;
  user?: userDetails;
}

export default EmployeeList;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
