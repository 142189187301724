import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Backdrop,
  Fade,
  Modal,
  Box,
  Grid

} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OrganizationalDetails from "./OrganizationalDetails";
import { ReportingManagerList } from "../../../models/employeeModel";
import { watch } from "fs";
import { StateList, ViewProject } from "../../../models/customerModel";
import { SalaryStructureList, SalaryStructureEditDetails, DomainList } from "../../../models/organizationModels";
import { useForm } from "react-hook-form";
import { environment } from "../../../environment";
function BulkUploadPopover({ handleClose, open,
  salary_structure_list,
  department_list,
  employee_type_list,
  employee_timing_list,
  designation_list,
  applicabledeg,
  ReportingManagerList,
  organization_list,
  OrganizationSelected,
  customer_list,
  OnChangeCustomer,
  project_list,
  OnprojectSelect,
  project_detail,
  post_list,
  handleFormSubmit,
  SlaryStructureChange,
  salary_structure,
  attendence_salary_type, 
  state_list,
  SetDocument,
  documents,
  AddlfileHandleEvent
 }: BulkUploadPopoverProps) {
    const {
      control,
      register,
      handleSubmit,
      formState,
      setValue,
      getValues,
      setError,
      clearErrors,
      reset,
      watch,
      formState: { errors },
    } = useForm();
    const onSubmit = (props:any)=>{
      
      handleFormSubmit(props);

    }
    const handleCloseWrapper=():void=>{
      reset({
        department_id:null,
        designation_id:null,
        employee_type_id:null,
        organization_id: null,
        shift_id:null
      });
      handleClose()
    }
  return (
    // <Dialog
    //   open={open}
    //   fullWidth={true}
    //   onClose={handleClose}
    //   aria-labelledby="responsive-dialog-title"
    // >
    //   <DialogTitle id="responsive-dialog-title">Bulk Upload</DialogTitle>
    //   <DialogContent>
    //     <DialogContentText>
    //       To upload the data in bulk mode please download the sample format and
    //       fill the data and upload here.
    //     </DialogContentText>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       variant="outlined"
    //       component="label"
    //       color="primary"
    //       // onClick={handleClose}
    //       style={{ margin: 10 }}
    //       startIcon={<CloudDownloadIcon />}
    //     >
    //       <input hidden accept="image/*" type="file" />
    //       <Typography
    //         style={{
    //           fontWeight: "500",
    //           fontSize: 16,
    //           fontFamily: "Poppins, sans-serif",
    //           color: "#626974",
    //           // color: "#FFFF",
    //           paddingLeft: 10,
    //           // textAlign: "center"
    //         }}
    //       >
    //         Download Sample File
    //       </Typography>
    //     </Button>
    //    
    //   </DialogActions>
    // </Dialog>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseWrapper}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{p:2, background:'#1976d2'}}>
            <Typography variant="h5" sx={{color:'#fff'}}> Candidate bulk upload form</Typography>
            </Box>
            <Box sx={{px:2,maxHeight:"70vh",overflow:"auto"}}>
              <Box display='flex' alignItems={"center"} justifyContent="flex-end" sx={{px:"12%",mt:4 }}>
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    // onClick={handleClose}
                    onClick={()=>{}}
             
                    startIcon={<CloudDownloadIcon />}
                  >
                    <a href="/bulk_candidate_onboarding.xlsx" download style={{textDecoration:"none"}}>
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        // color: "#FFFF",
                        paddingLeft: 10,
                        // textAlign: "center"
                      }}
                    >
                      Download Sample File
                    </Typography>
                    </a>
                  </Button>
              </Box>
              <form>
            <OrganizationalDetails
              noMargin
              ReportingManagerList={ReportingManagerList}
              applicabledeg={applicabledeg}
              department_list={department_list}
              designation_list={designation_list}
              employee_timing_list={employee_timing_list}
              employee_type_list={employee_type_list}
              organization_list={organization_list}
              salary_structure_list={salary_structure_list}
              OrganizationSelected={OrganizationSelected}
              control={control}
              errors={errors}
              watch={watch}
              customer_list={customer_list}
              OnChangeCustomer={OnChangeCustomer}
              project_list={project_list}
              OnprojectSelect={OnprojectSelect}
              project_detail={project_detail}
              post_list={post_list}
              SlaryStructureChange={SlaryStructureChange}
              salary_structure={salary_structure}
              attendence_salary_type={attendence_salary_type}
              state_list={state_list}
              documents={documents}
              SetDocument={SetDocument}
              AddlfileHandleEvent={AddlfileHandleEvent}
            />
             {!!watch("organization_id") &&
        !!watch("employee_type_id") &&
        !!watch("employee_type_id") && 
      !!watch("designation_id")  ? (
           <Box display="flex" justifyContent={"flex-end"} sx={{px:"12%"}}>  
               <Button
                variant="outlined"
                component="label"
                color="primary"
                // onClick={handleClose}
                
                startIcon={<CloudUploadIcon />}
              >
                <input hidden accept=".xlsx,.xls" type="file" 
                {...register("bulk_file")} />
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    // color: "#FFFF",
                    paddingLeft: 10,
                    // textAlign: "center"
                  }}
                >
                  Upload Bulk Candidate File
                </Typography>
              </Button>
              </Box>):<></>}
              
      
              <Box display="flex" justifyContent={"flex-end"} sx={{px:"12%", mt:6, mb:3}}> 
            <Button type="button" variant="contained" sx={{px:5}}  onClick={handleSubmit(onSubmit)} size="medium" >submit</Button>
            </Box>
            </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
  );
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70vw",
  bgcolor: 'background.paper',
  boxShadow: 24,
 
};
export default BulkUploadPopover;
interface BulkUploadPopoverProps {
  handleClose?: any;
  open: boolean;
  ReportingManagerList: ReportingManagerList[];
  applicabledeg?: any;
  documents: any[];
  designation_list: any[];
  watch?: any;
  fileHandleEvent?: any;
  addEduDetail?: any;
  post_list: any[];
  project_list: any[];
  department_list: any[];
  addExpDetail: any[];
  employee_type_list: any[];
  customer_list: any[];
  employee_timing_list: any[];
  organization_list: any[];
  OrganizationSelected?: any;
  salary_structure_list: SalaryStructureList[];
  setaddEduDetail?: any;
  SetDocument?: any;
  setaddExpDetail?: any;
  OnChangeCustomer?: any;
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  handleClickOpen?: any;
  getValues?: any;
  setValue?: any;
  handleFormSubmit:any;
  state_list: StateList[];
  AddlfileHandleEvent?: any
}
