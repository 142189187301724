import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  PendingApprovalList,
  UpdateAttendaceStatusPayload,
} from "../../../models/attendanceModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  AttendanceDetailPayloadSuccessAction,
  GetAllPendingApprovalAction,
  UpdateApproveAttendanceStatusAction,
} from "../../../stores/actions/attendanceAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import AttendanceApproveView from "./AttendanceApproveView";

const AttendanceApprove = ({
  GetAllPendingApprovalAction,
  pending_approval,
  user,
  SidenavAutoCloseOpen,
  AttendanceDetailPayloadSuccessAction,
  UpdateApproveAttendanceStatusAction,
}: AttendanceApproveProps) => {
  React.useEffect(() => {
    if (!!pending_approval) {
      GetAllPendingApprovalAction({
        approverDesigId: user?.designation,
        eventType: 7,
      });
    }
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const ApprovedAction = (payload: UpdateAttendaceStatusPayload) => {
    // let Actionpayload: UpdateAttendaceStatusPayload = {
    //   remarks: payload.remarks,
    //   approved_list: [
    //     {
    //       project_attendence_id: payload.approved_list?.find(
    //         (n) => n.project_attendence_id
    //       )?.project_attendence_id,
    //       empLifeEventId: payload.approved_list?.find((n) => n.empLifeEventId)
    //         ?.empLifeEventId,
    //     },
    //   ],
    //   event_action_items: payload.event_action_items,
    //   status: payload.status,
    // };
    let payloads = {
      data1: payload,
      data2: {
        approverDesigId: user?.designation,
        eventType: 7,
      },
      enqueueSnackbar: enqueueSnackbar,
    };
    UpdateApproveAttendanceStatusAction(payloads);
  };

  return (
    <AttendanceApproveView
      pending_approval={pending_approval}
      ApprovedAction={ApprovedAction}
      AttendanceDetailPayloadSuccessAction={
        AttendanceDetailPayloadSuccessAction
      }
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    pending_approval: state.attendance.pending_approval,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  GetAllPendingApprovalAction,
  SidenavAutoCloseOpen,
  AttendanceDetailPayloadSuccessAction,
  UpdateApproveAttendanceStatusAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceApprove);

interface AttendanceApproveProps {
  GetAllPendingApprovalAction?: any;
  pending_approval: PendingApprovalList[];
  user?: userDetails;
  SidenavAutoCloseOpen?: any;
  AttendanceDetailPayloadSuccessAction?: any;
  UpdateApproveAttendanceStatusAction?: any;
}
