import {
  Modal,
  Paper,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Button,
  TableCell,
  styled,
  tableCellClasses,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "recharts";
import { ViewPromotion } from "../../../models/promotionModel";
import { SalaryStructureHistory } from "../../../models/employeeModel";

const SalaryDetailView = ({
  SalaryHistory,
  openModal,
  setopenModal,
  empDet,
}: SalaryDetailViewProps) => {
  //   const [openModal, setopenModal] = useState<boolean>(false);
  const handleModalClose = () => setopenModal(false);
  const columns: any[] = ["Salary Component Name", "Amount"];
  console.log("SalaryHistory", SalaryHistory);
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Paper
        elevation={2}
        sx={{
          marginTop: 10,
          marginInline: 15,
          marginBottom: 10,
          minWidth: "60%",
          minHeight: "60%",
          maxHeight: "80%",
          overflowY: "auto",
        }}
      >
        <Grid container direction={"column"} display={"flex"}>
          <Grid
            container
            style={{
              padding: 5,
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            direction={"row"}
          >
            <Grid item xs={6}>
              <Typography style={{ color: "#fff", paddingLeft: 5 }}>
                Employee Salary Details
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* <Typography
                sx={{ color: "#fff", textAlign: "right", paddingRight: 5 }}
              >
                Project Name : 
              </Typography> */}
            </Grid>
          </Grid>
          <Grid item xs={12} container direction={"row"} p={1}>
            <Grid item xs={6} container direction={"row"}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  Employee No.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  {empDet?.employee_id}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} container direction={"row"}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  Employee Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  {empDet?.employee_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} container direction={"row"}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  Project Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: 5,
                  }}
                >
                  {empDet?.project_name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ paddingBottom: 4, px: 1 }}>
            <TableContainer sx={{ height: 420 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {!!empDet && empDet.SalaryHistory.componenet.map((row: any) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.compent_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.display_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {row.componet_calculated_value}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {!!row.start_date
                            ? moment(row.start_date).format("DD-MM-YYYY")
                            : "N/A"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={() => handleModalClose()}
              variant="contained"
              color="primary"
              size="large"
            >
              Close
            </Button>
          </Grid> */}
        </Grid>
      </Paper>
    </Modal>
  );
};

export default SalaryDetailView;
interface SalaryDetailViewProps {
  SalaryHistory: SalaryStructureHistory[];
  setopenModal?: any;
  openModal: boolean;
  empDet?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FD6500",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
