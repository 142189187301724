import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import {
  AllDepartmentList,
  OrganizationALlList,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllOrganizationAction,
  GetAllSalaryStructureAction,
  SyncSalaryStructureForEmployeeAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import SalaryStructureListView from "./SalaryStructureListView";

const SalaryStructureMain = ({
  GetAllOrganizationAction,
  organization_list,
  GetAllSalaryStructureAction,
  salary_structure_list,
  accessToken,
  SidenavAutoCloseOpen,
  SyncSalaryStructureForEmployeeAction,
}: SalaryStructureMainProps) => {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllSalaryStructureAction({ organization_id: null });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const filterData = (props: any) => {
    GetAllSalaryStructureAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
  };
  const Clear = () => {
    GetAllSalaryStructureAction({
      organization_id: null,
    });
  };
  //   const DeleteAction = (props: number) => {
  //     DeleteDepartmentAction({
  //       data: props,
  //       enqueueSnackbar: enqueueSnackbar,
  //     });
  //   };
  const SyncEmployee = (props: any) => {
    SyncSalaryStructureForEmployeeAction({
      salary_structure_id: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <SalaryStructureListView
      organization_list={organization_list}
      filterData={filterData}
      Clear={Clear}
      // Delete={Delete}
      //   DeleteAction={DeleteAction}
      salary_structure_list={salary_structure_list}
      accessToken={accessToken}
      SyncEmployee={SyncEmployee}
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    salary_structure_list: state.organization.salary_structure_list,
    accessToken: state.user.userDetails?.accessToken,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllSalaryStructureAction,
  SidenavAutoCloseOpen,
  SyncSalaryStructureForEmployeeAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalaryStructureMain);

interface SalaryStructureMainProps {
  GetAllOrganizationAction?: any;
  GetAllSalaryStructureAction?: any;
  organization_list: OrganizationALlList[];
  salary_structure_list: SalaryStructureList[];
  accessToken?: string;
  SidenavAutoCloseOpen?: any;
  SyncSalaryStructureForEmployeeAction?: any;
}
