import { Grid, Typography, TextField, Button, Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { watch } from "fs";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { baseUrl, environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import { ProjectList } from "../../../models/customerModel";
import {
  OrganizationALlList,
  EmployeeTypeList,
  AllDepartmentList,
  EmployeeTimingList,
} from "../../../models/organizationModels";
import { userDetails } from "../../../models/userModels";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
const FileDownload = require("js-file-download");
const BulkUploadPopUp = ({
  employee_type_list,
  organization_list,
  project_list,
  user,
  accessToken,
  setOpen,
  GetAllCandidateBulkUploadListAction,
  department_list,
  employee_timing_list,
}: BulkUploadPopUpProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!user) {
      if (
        !environment.global_veriable.ho_employees_type_id.includes(
          user.employee_detail.employee_type_id
        ) ||
        environment.global_veriable.managerLevelDeg.includes(user.designation)
      ) {
        setValue("organization_id", +user.employee_detail.organization_id);
      }
      if (
        !environment.global_veriable.officialEmployee.includes(user.designation)
      ) {
        setValue("employee_type_id", user.employee_detail.employee_type_id);
        setValue("shift_id", user.employee_detail.shift_id);
        setValue("department_id", user.employee_detail.department_id);
        if (user.project_detail.length >= 1) {
          setValue("project_id", user.project_detail[0].project_id);
        }
      }
    }
  }, [user]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadField, setuploadField] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpenDownload = () => {
    if (
      !!getValues("organization_id") &&
      !!getValues("employee_type_id") &&
      (environment.global_veriable.ho_employees_type_id.includes(
        +getValues("employee_type_id")
      ) ||
        (!environment.global_veriable.ho_employees_type_id.includes(
          +getValues("employee_type_id")
        ) &&
          !!getValues("project_id")))
    ) {
      Axios({
        url: baseUrl + environment.urls.downloadBulkCandidateForm,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          org_id: +getValues("organization_id"),
          employee_type: +getValues("employee_type_id"),
          project_id: !!getValues("project_id")
            ? +getValues("project_id")
            : null,
        },
      })
        .then(async (response) => {
          if (response.status == 200) {
            FileDownload(response.data, "CandidateBulkUploadForm.xlsx");
          } else {
            enqueueSnackbar(
              !!response?.data &&
                !!JSON.parse(await response.data.text())?.message
                ? JSON.parse(await response.data.text())?.message
                : "Something went wrong!",
              {
                variant: "warning",
              }
            );
          }
        })
        .catch(async (error) => {
          enqueueSnackbar(
            !!error?.response?.data &&
              !!JSON.parse(await error.response.data.text())?.message
              ? JSON.parse(await error.response.data.text())?.message
              : "Something went wrong!",
            {
              variant: "error",
            }
          );
        });
    } else {
      enqueueSnackbar("Please Select All The Field...", {
        variant: "error",
      });
    }
  };
  const handleClickUpload = (event: any) => {
    if (
      !!accessToken &&
      !!getValues("organization_id") &&
      !!getValues("employee_type_id") &&
      (environment.global_veriable.ho_employees_type_id.includes(
        +getValues("employee_type_id")
      ) ||
        (!environment.global_veriable.ho_employees_type_id.includes(
          +getValues("employee_type_id")
        ) &&
          !!getValues("project_id")))
    ) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${
        event.target.files[0].name
      }`;
      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("fileName", file_name);
      formData.append("org_id", +getValues("organization_id"));
      formData.append(
        "project_id",
        !!getValues("project_id") ? +getValues("project_id") : null
      );
      formData.append("employee_type", +getValues("employee_type_id"));
      formData.append("shift_id", +getValues("shift_id"));
      formData.append("dept_id", +getValues("department_id"));

      BeginApiCallAction({
        count: 1,
        message: "Uploading File. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.getBulkEmployeeUpload, formData, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            enqueueSnackbar("Successfully uploaded...", {
              variant: "success",
            });
          }
          GetAllCandidateBulkUploadListAction();
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
      setOpen(false);
      if (fileInputRef.current) fileInputRef.current.value = "";
    } else {
      enqueueSnackbar("Please Select All The Field...", {
        variant: "error",
      });
    }
  };
  return (
    <>
      <Grid container justifyContent={"flex-start"}>
        {!!user &&
          environment.global_veriable.officialEmployee.includes(
            user.designation
          ) && (
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "86px",
                    }}
                  >
                    Organization
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: 30 }}>
                  <Controller
                    control={control}
                    name={"organization_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={organization_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"organization_id"}
                        label={"organization_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Organization"}
                        value={!!value ? value : ""}
                        errors={!!errors["organization_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {!!user &&
          environment.global_veriable.ho_employees_type_id.includes(
            user.employee_detail.employee_type_id
          ) &&
          !!watch("organization_id") && (
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Department
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: 12 }}>
                  <Controller
                    control={control}
                    name={"department_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={department_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"department_id"}
                        label={"department_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Department"}
                        value={!!value ? value : ""}
                        errors={!!errors["department_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {!!user &&
          environment.global_veriable.ho_employees_type_id.includes(
            user.employee_detail.employee_type_id
          ) &&
          !!watch("organization_id") &&
          !!watch("department_id") && (
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "86px",
                      width: "200px"
                      // textAlign: "center"
                    }}
                  >
                    Working Shift
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: 30 }}>
                  <Controller
                    control={control}
                    name={"shift_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={employee_timing_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"shift_id"}
                        label={"shift_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Working Shift"}
                        value={!!value ? value : ""}
                        errors={!!errors["shift_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {!!user &&
          environment.global_veriable.ho_employees_type_id.includes(
            user.employee_detail.employee_type_id
          ) &&
          !!watch("organization_id") &&
          !!watch("department_id") &&
          !!watch("shift_id") && (
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Employee Type
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: 12}}>
                  <Controller
                    control={control}
                    name={"employee_type_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        // option={employee_type_list}
                        option={employee_type_list.filter(
                          (m) =>
                            m.org_id == +watch("organization_id") &&
                            ((environment.global_veriable.managerLevelDeg.includes(
                              user.designation
                            ) &&
                              !environment.global_veriable.ho_employees_type_id.includes(
                                m.employee_type_id
                              )) ||
                              !environment.global_veriable.managerLevelDeg.includes(
                                user.designation
                              ))
                        )}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"employee_type_id"}
                        label={"employee_type_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => (
                          onChange(value), setValue("project_id", null)
                        )}
                        placeholder={"Search Employee Type"}
                        value={!!value ? value : ""}
                        errors={!!errors["employee_type_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {!!user &&
          !!watch("employee_type_id") &&
          environment.global_veriable.ho_employees_type_id.includes(
            user.employee_detail.employee_type_id
          ) &&
          !environment.global_veriable.ho_employees_type_id.includes(
            +watch("employee_type_id")
          ) && (
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Project
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"project_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={project_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"project_id"}
                        label={"project_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Project"}
                        value={!!value ? value : ""}
                        errors={!!errors["project_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
      {(!!watch("organization_id") ||
        user?.project_detail.find((r) => r.organization_id)?.organization_id) &&
        (!!watch("employee_type_id") ||
          user?.employee_detail.employee_type_id ||
          !!watch("project_id")) && (
          <Grid container justifyContent={"center"} direction={"row"}>
            <Grid item xs={6}>
              <Grid container direction={"column"}>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    backgroundColor: "#0060FF",
                    borderTopLeftRadius: 5,
                  }}
                >
                  {/* <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",
                      // color: "#626974",
                      // paddingLeft: "20%",
                      padding: 12,
                    }}
                  >
                    Download Candidate Bulk Form
                  </Typography> */}
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "5%",
                          width: "200px"
                        }}
                      >
                        Download Candidate Bulk Form
                        {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} p={2}>
                      <Button
                        variant="outlined"
                        component="label"
                        color="primary"
                        // onClick={handleClose}
                        startIcon={<CloudDownloadIcon />}
                        onClick={handleClickOpenDownload}
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            // color: "#FFFF",
                            paddingLeft: 10,
                            // textAlign: "center"
                          }}
                        >
                          Download
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction={"column"}>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    backgroundColor: "#0060FF",
                    borderTopRightRadius: 5,
                  }}
                >
                  {/* <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",
                      // color: "#626974",
                      padding: 12,
                    }}
                  >
                    Upload Candidate Bulk Form
                  </Typography> */}
                </Grid>

                <Grid item sx={{ mt: 2 }}>
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          padding: 12
                        }}
                      >
                        Upload Candidate Bulk Form
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        // type="submit"
                        variant="outlined"
                        component="label"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                      >
                        <input
                          hidden
                          // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          accept=".xlsx,.xls"
                          multiple
                          type="file"
                          ref={fileInputRef}
                          value={uploadField}
                          onChange={handleClickUpload}
                        />
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            // color: "#FFFF",
                            paddingLeft: 10,
                            // textAlign: "center"
                          }}
                        >
                          Upload
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default BulkUploadPopUp;

interface BulkUploadPopUpProps {
  organization_list: OrganizationALlList[];
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
  accessToken?: string;
  setOpen?: any;
  GetAllCandidateBulkUploadListAction?: any;
  department_list: AllDepartmentList[];
  employee_timing_list: EmployeeTimingList[];
}
