import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DomainList,
  OrganizationALlList,
  // SalaryComponentThresholdParameters,
  SalaryStructureEditDetails,
  SalaryStructureList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  CreateSalaryStructureAction,
  DomainGetAllAction,
  EditSalaryStructureAction,
  GetAllOrganizationAction,
  SalaryCalculationLoadAction,
  SalaryComponentEditAction,
  // SalaryComponentThresholdParameterAction,
  SalaryStructuregetAll,
} from "../../../stores/actions/organizationActions";
import AddSalarystructureView from "./AddSalarystructureView";
function AddSalaryStructure({
  component_list,
  domain_list,
  GetAllOrganizationAction,
  organization_list,
  user,
  CreateSalaryStructureAction,
  SalaryStructuregetAll,
  DomainGetAllAction,
  SalaryCalculationLoadAction,
  calculation_type,
  operator,
  global_key,
  operator_tye,
  SalaryComponentEditAction,
  salary_structure,
  EditSalaryStructureAction,
  // SalaryComponentThresholdParameterAction,
  // salary_compo_threshold
}: AddSalaryStructureProps) {
  const { id } = useParams();

  React.useEffect(() => {
    GetAllOrganizationAction();
    SalaryStructuregetAll();
    DomainGetAllAction();
    SalaryCalculationLoadAction();
    // SalaryComponentThresholdParameterAction({
      
    //     "parameters":["PF_Deduction","PF_Contribution", "ESIC_Deduction", "ESIC _Contribution"]
    
    // })
    if (!!id && +id > 0) {
      SalaryComponentEditAction({ salary_structure_id: +id });
    }
  }, []);
  // console.log(salary_compo_threshold);
  
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const Submit = (data: any) => {
    data.user_id = user?.userid;
    if (!!id && +id > 0) {
      data = {
        ...salary_structure,
        salary_component: salary_structure?.component,
        ...data,
      };
      delete data.component;
      data.salary_component.forEach((m: any) => {
        m.salary_structure_id = data.salary_structure_id;
      });
      EditSalaryStructureAction({
        data: data,
        navigateTo: navigateTo,
        enqueueSnackbar: enqueueSnackbar,
      });
      // console.log(data);
    } else {
      console.log(data);
      
      CreateSalaryStructureAction({
        data: data,
        navigateTo: navigateTo,
        enqueueSnackbar: enqueueSnackbar,
      });
    }
  };

  return (
    <AddSalarystructureView
      domain_list={domain_list}
      component_list={component_list}
      organization_list={organization_list}
      Submit={Submit}
      calculation_type={calculation_type}
      operator={operator}
      global_key={global_key}
      operator_tye={operator_tye}
      salary_structure={!!id && +id > 0 ? salary_structure : undefined}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    component_list: state.organization.component_list,
    domain_list: state.organization.domain_list,
    organization_list: state.organization.organization_filter_list,
    user: state.user.userDetails,
    calculation_type: state.organization.calculation_type,
    operator: state.organization.operator,
    operator_tye: state.organization.operator_tye,
    global_key: state.organization.global_key,
    salary_structure: state.organization.salary_structure_edit,
    // salary_compo_threshold: state.organization.salary_compo_threshold
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  CreateSalaryStructureAction,
  SalaryStructuregetAll,
  DomainGetAllAction,
  SalaryCalculationLoadAction,
  SalaryComponentEditAction,
  EditSalaryStructureAction,
  // SalaryComponentThresholdParameterAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSalaryStructure);

interface AddSalaryStructureProps {
  CreateSalaryStructureAction?: any;
  component_list: SalaryStuctureComponent[];
  domain_list: DomainList[];
  GetAllOrganizationAction?: any;
  organization_list?: OrganizationALlList[];
  user?: userDetails;
  SalaryStructuregetAll?: any;
  DomainGetAllAction?: any;
  SalaryCalculationLoadAction?: any;
  calculation_type: DomainList[];
  operator: DomainList[];
  operator_tye: DomainList[];
  global_key: DomainList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  EditSalaryStructureAction?: any;
  // SalaryComponentThresholdParameterAction?: any
  // salary_compo_threshold: SalaryComponentThresholdParameters[]
}
