import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import LoginRegistration from "./views/LoginRegistration";
import Employment from "./views/employment";
import Dashboard from "./views/dashboard";
import SideNav from "./views/common/SideNav";
import Footer from "./views/common/Footer";
import Header from "./views/common/Header";
import Skeletons from "./library/Skeletons";
import MessageToster from "./library/MessageToster";
import { Cookies, useCookies } from "react-cookie";
import { connect } from "react-redux";
import { StoreState } from "./models/reduxModels";
import { userDetails } from "./models/userModels";
import {
  LogOutAction,
  userLoginSuccessAction,
} from "./stores/actions/userAction";
import Organization from "./views/Organization";
import Customer from "./views/Customer";
import Attendance from "./views/Attendance";
import Payroll from "./views/Payroll";
import UserManagement from "./views/UserManagement";
import { environment } from "./environment";
import Leave from "./views/Leave";
import TimingSheet from "./views/TimingSheet";
import Reports from "./views/Reports";
import Promotion from "./views/Promotion";
import Termination from "./views/Termination";

function App({ user, userLoginSuccessAction, LogOutAction }: AppProps) {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  let cook = new Cookies();
  let location = useLocation();
  const [initialState, SetinitialState] = useState<string | undefined>(
    undefined
  );

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     if (!cook.get("user")) {
  //       logoutEvent();
  //     }
  //   }, 1000000);

  // }, [location]);

  // useEffect(() => {
  //   if (!!user) {
  //     if (!cook.get("user")) {
  //       setCookie("user", user, {
  //         maxAge: 86400,
  //         path: "/",
  //       });
  //     }
  //   } else {
  //     if (!!cook.get("user")) {
  //       userLoginSuccessAction(cookies.user);
  //     }
  //   }
  // }, [user]);
  const logoutEvent = () => {
    removeCookie("user", { path: "/" });
    LogOutAction();
  };
  useEffect(() => {
    if (!!user?.assess_control && !user?.is_reset_password) {
      if (
        user.assess_control.length > 0 &&
        user.assess_control[0].access_control_name != "dummy_menu"
      ) {
        environment.sideNav.forEach((m) => {
          if (m.has_submenu) {
            m.menuItem.forEach((n) => {
              if (n.key == user.assess_control[0].access_control_name) {
                SetinitialState(n.url);
              }
            });
          } else {
            if (m.key == user.assess_control[0].access_control_name) {
              SetinitialState(m.url);
            }
          }
        });
      } else {
        SetinitialState("/employment/manage-profile/0");
      }
    } else {
      SetinitialState("/registration-login/");
    }
  }, [user?.assess_control, user?.is_reset_password]);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <MessageToster />
      <Skeletons />
      {!!user && !!user.userid && !user.is_reset_password && (
        <div style={{ position: "sticky", width: "100%", top: 0, zIndex: 999 }}>
          <Header logoutEvent={logoutEvent} />
        </div>
      )}
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          width: "99.9%",
          display: "flex",
          height: "90%",
        }}
      >
        <div style={{ flexDirection: "row" }}>
          {!!user && !!user.userid && <SideNav />}
        </div>
        <div
          style={{
            flex: 1,
            maxHeight: "96%",
            paddingBottom: 100,
            overflowY: "scroll",
          }}
        >
          <MainRouter
            hasUser={!!user && !!user.userid && !user.is_reset_password}
            initialState={initialState}
          />
        </div>
      </div>
      <div
        style={{ position: "sticky", width: "100%", bottom: 0, zIndex: 999, backgroundColor:"red" }}
      >
        {!!user && !!user.userid && <Footer />}
      </div>
      <img
        src={require("./assets/HRMS_bg2.jpg")}
        style={{
          position: "absolute",
          height: "100vh",
          width: "99.9%",
          zIndex: -1,
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  userLoginSuccessAction,
  LogOutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const MainRouter = ({
  hasUser,
  initialState = "/registration-login/",
}: MainProps) => {
  return (
    <Routes>
      {hasUser ? (
        <>
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/employment/*" element={<Employment />} />
          <Route path="/organization/*" element={<Organization />} />
          <Route path="/customer/*" element={<Customer />} />
          <Route path="/attendance/*" element={<Attendance />} />
          <Route path="/payroll/*" element={<Payroll />} />
          <Route path="/usermanagement/*" element={<UserManagement />} />
          <Route path="/leave/*" element={<Leave />} />
          <Route path="/timingsheet/*" element={<TimingSheet />} />
          <Route path="/reports/*" element={<Reports />} />
          <Route path="/promotion/*" element={<Promotion />} />
          <Route path="/termination/*" element={<Termination />} />
        </>
      ) : (
        <>
          <Route path="/registration-login/*" element={<LoginRegistration />} />
        </>
      )}
      <Route path="/" element={<Navigate replace to={initialState} />} />
      <Route path="*" element={<Navigate replace to={initialState} />} />
    </Routes>
  );
};

interface MainProps {
  hasUser?: any;
  initialState?: string;
}

interface AppProps {
  user?: userDetails;
  userLoginSuccessAction?: any;
  LogOutAction?: any;
}
