import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { StoreState } from "../models/reduxModels";
import rootReducer from "./reducers";
export default function ConfigureStore(initialState?: StoreState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== "production",
  });
}
