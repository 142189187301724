import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  Componenet,
  SalaryStructureHistory,
} from "../../../models/employeeModel";

const EmployeeSalaryComponent = ({ m, row }: EmployeeSalaryComponentProps) => {
  return (
    <>
      {!!row &&
        row.componenet.map(
          (n) =>
            n.component_type_name === m?.component_type_name && (
              <Grid item>
                <Grid container direction={"row"}>
                  <Grid item xs={6} pl={3} pt={1}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: "Arial",
                        color: "#567189",
                        textAlign: "left",
                      }}
                    >
                      {n?.display_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pl={3} pt={1}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: "Arial",
                        color:
                          n.component_type_name === "Earnings"
                            ? "#008060"
                            : n.component_type_name === "Deductions"
                            ? "#b32d00"
                            : "black",
                        textAlign: "left",
                      }}
                    >
                      &#8377;{ n?.componet_calculated_value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
        )}
    </>
  );
};

export default EmployeeSalaryComponent;
interface EmployeeSalaryComponentProps {
  m?: Componenet;
  row?: SalaryStructureHistory;
}
