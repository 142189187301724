import {
    Box,
    Button,
    Fade,
    Grid,
    IconButton,
    Modal,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
  } from "@mui/material";
  import moment from "moment";
  import React, { useState } from "react";
  import { BudgetDashboardDetails, MonthWiseEmpSalaryinBudgeting } from "../../../models/customerModel";
  import VisibilityIcon from "@mui/icons-material/Visibility";

const MonthlySalaryPopover = ({open,
    setOpen,
    budget_details, month_wise_emp_salary_Budgeting, EmpMonthlyDisbursedSalary}:MonthlySalaryPopoverProps) => {
        const columns: any[] = ["Month", "Cost","Work Hour","Action"];
        const column1: any[] = ["Employee ID", "Employee Name","Project Name","Month","Work Hour"];
        const [empMonthlyDisbursedSalary, setEmpMonthlyDisbursedSalary] =useState<boolean>(false)
  return (
    <>
     <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={() => setOpen(false)}
    closeAfterTransition
  >
    <Fade in={open}>
      <Box sx={style}>
        <Grid
          container
          style={{
            padding: 10,
            backgroundColor: "#0060FF",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Grid item>
            <Typography style={{ color: "#fff" }}>Monthly Disbursed Salary</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ width: "100%", padding: 5 }}>
          <Grid item>
            <TableContainer sx={{}}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCellS align="justify" key={column}>
                        {column}
                      </StyledTableCellS>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!budget_details?.month_wise_Emp_sal && budget_details?.month_wise_Emp_sal.length>0 &&
                    budget_details?.month_wise_Emp_sal
                      .map((row) => (
                        <StyledTableRowS
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={moment(row.date).format("M")}
                        >
                          <StyledTableCellS align="left" key={columns[0]}>
                            {row.date
                              ? moment(row.date).format("MMM,YYYY")
                              : "N/A"}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                          &#8377;{!!row.calculated_amount ? row.calculated_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): "N/A"}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[2]}>
                          {!!row.work_hours ? row.work_hours : "N/A"}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[3]}>
                          <Tooltip
                          title="View"
                          onClick={() =>(setEmpMonthlyDisbursedSalary(true),EmpMonthlyDisbursedSalary(row.date))
                           
                          }
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                          </StyledTableCellS>
                        </StyledTableRowS>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={() => setOpen(false)}
              variant="contained"
              color="primary"
              size="large"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </Modal>

  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={empMonthlyDisbursedSalary}
    onClose={() => setEmpMonthlyDisbursedSalary(false)}
    closeAfterTransition
  >
    <Fade in={empMonthlyDisbursedSalary}>
      <Box sx={empStyle}>
        <Grid
          container
          style={{
            padding: 10,
            backgroundColor: "#0060FF",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <Grid item>
            <Typography style={{ color: "#fff" }}>Employee wise Monthly Disbursed Salary</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ width: "100%", padding: 5 }}>
          <Grid item>
            <TableContainer sx={{}}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {column1.map((column) => (
                      <StyledTableCellS align="justify" key={column}>
                        {column}
                      </StyledTableCellS>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!month_wise_emp_salary_Budgeting && month_wise_emp_salary_Budgeting.length>0 && 
                    month_wise_emp_salary_Budgeting
                      .map((row,index) => (
                        <StyledTableRowS
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCellS align="left" key={columns[0]}>
                            {row.employee_id}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                          {row.employee_name}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                          {row.project_name}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[1]}>
                          {moment(row.format_date).format('MMMM-YY')}
                          </StyledTableCellS>
                          <StyledTableCellS align="left" key={columns[2]}>
                          {row.work_hours+ " Hours"}
                          </StyledTableCellS>
                        </StyledTableRowS>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={() => setEmpMonthlyDisbursedSalary(false)}
              variant="contained"
              color="primary"
              size="large"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </Modal>
  </>
  )
}

export default MonthlySalaryPopover

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
  };
  const empStyle = {
    position: "absolute" as "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
  };
  const StyledTableCellS: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#19A7CE",
      color: "#ffff",
      fontWeight: 600,
      fontSize: 14,
      padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: { md: 14, sm: 12, xs: 8 },
      padding: "5px",
      color: "#146C94",
    },
  }));
  
  const StyledTableRowS = styled(TableRow)(({ theme }: any) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F9FAFC",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  
  interface  MonthlySalaryPopoverProps {
    open: boolean;
    setOpen?: any;
    budget_details?: BudgetDashboardDetails;
    month_wise_emp_salary_Budgeting: MonthWiseEmpSalaryinBudgeting[];
    EmpMonthlyDisbursedSalary?: any;
  }