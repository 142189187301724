import { FC, useEffect, useRef } from "react";
import {
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Box,
  Typography,
  Button,
  AccordionDetails,
  TextField,
  Autocomplete,
  IconButton,
  Link,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  TablePagination,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { watch } from "fs";
import { ProjectList } from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import { MonthNames, monthOptions } from "../../../utility/monthOption";
import { IMonthOptions } from "../../../interfaces/others.interface";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useNavigate } from "react-router-dom";
import { IPayrollReportPayload } from "../../../models/reportsModel";
import moment from "moment";
import TotalSalaryComponent from "./TotalSalaryComponent";
import { IEmployeeList } from "../../../models/timingSheetModel";
import SearchEmployee from "../../utilComponent/SearchEmployee";
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;
const PayrollReportsView: FC<IPayrollReportsView> = ({
  organization_list,
  employee_type_list,
  project_list,
  setOpenReport,
  openReport,
  payroll_list,
  totalPayrollData,
  GetPayrollReportAction,
  total_size,
  totalPayrollListForPDF,
  totalSalCountDataForPDF,
  searchByNameEmpAction,
  employeeList,
  SalaryStructuregetAll,
  component_list,
}): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const divElement = useRef<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [reportYear, setReportYear] = useState<any>(new Date());
  const [page, setPage] = useState(0);
  const [searched, setSearched] = useState<string>("");
  const [dataForPDF, setDataForPDF] = useState<any[]>([]);
  const [dynamicTableHeader, setDynamicTableHeader] = useState<any[]>([]);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const onSubmit = (props: any) => {
    console.log("props: ", props);
    const payload: IPayrollReportPayload = {
      organization_id: props.organization_id,
      months: props.months.includes(13) ? [] : props.months,
      year: props?.year ? props.year : new Date().getFullYear(),
      employee_type_id: props.employee_type_id,
      project_id: props?.project_id ? props.project_id : null,
      employeeId: props?.employeeId ? props.employeeId : null,
      isPDF: false,
      page_index: pageindex,
      page_size: pagesize,
      type: "",
    };

    GetPayrollReportAction(payload);
    GetPayrollReportAction({ ...payload, isPDF: true });
    setOpenReport(true);
    setExpanded(false);
  };

  const handleYear = (newValue: Dayjs | null) => {
    setReportYear(newValue);
    if (newValue) setValue("year", +dayjs(newValue).format("YYYY"));
  };
  useEffect(() => {
    setValue("year", Number(new Date().getFullYear()));
  }, []);

  const [rows, setRows] = useState<any[]>([]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!payroll_list
      ? payroll_list.filter((row: any) => {
          return (
            row?.employee_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_id?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!payroll_list) {
      cancelSearch();
    }
  }, [payroll_list]);
  useEffect(() => {
    const tdynamicTableHeader = component_list.filter(
      (self) => self.component_name.toLowerCase() !== "basic"
    );
    setDynamicTableHeader(tdynamicTableHeader);
  }, [component_list]);

  const columns: any[] = [
    "Project Name",
    "Employee Name",
    "Employee ID",
    "Year",
    "Month",
    "Working Days",
    "Presents",
    "Basic",
    ...dynamicTableHeader.map(
      (self) => `${self.display_name} (${self.component_type_name})`
    ),
    "Gross Salary",
    "Net Salary",
    "CTC",
  ];
  useEffect(() => {
    const tDataForPDF: any[] = [];
    Array.isArray(totalPayrollListForPDF) &&
      totalPayrollListForPDF.forEach((self) => {
        tDataForPDF.push([
          {
            text: self.project_name, //1
          },
          {
            text: self.employee_name, //2
          },
          {
            text: self.employee_id, //3
          },
          {
            text: self.year, //4
          },
          {
            text: MonthNames[+self.month], //5
          },
          {
            text: self.effective_working_days, //6
          },
          {
            text: self.present_days, //7
          },
          {
            text: self.Basic, //8
          },
          ...dynamicTableHeader.map((dyna) => ({
            text: self[dyna.component_name],
          })),
          {
            text: self.Total_Earning,
          },
          {
            text: self.net_salary,
          },
          {
            text: self.CTC,
          },
        ]);
      });
    setDataForPDF(tDataForPDF);
  }, [totalPayrollListForPDF]);
  const styles = {
    marginRight: "50px",
  };
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowsperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);

  const onDownloadPDF = () => {
    // html2canvas(document.getElementById("target")!).then((canvas) => {
    console.log("pdf hit");
    const dynamicPDFHeaders = dynamicTableHeader.map((self) => ({
      text: `${self.display_name} (${self.component_type_name})`,
      style: "tableHeader",
    }));
    html2canvas(divElement.current!)
      .then((canvas) => {
        const dataURI = canvas.toDataURL();
        const docDefinition = {
          pageMargins: [2, 2, 2, 2],
          background: {
            image: dataURI,
            width: 845,
          },
          // content: [
          //   {
          //     text: "title",
          //     fontSize: 32,
          //     bold: true,
          //     margin: [50, 50, 60, 60]
          //   }
          // ]
        };
        var dd = {
          pageOrientation: "landscape",
          pageSize: "A2",
          content: [
            {
              text: `Payroll Details  Of ${
                organization_list.find(
                  (self) =>
                    Number(getValues("organization_id")) ===
                    Number(self.organization_id)
                )?.organization_name
              } ${
                getValues("months")
                  ? ` ${
                      Array.isArray(getValues("months")) &&
                      !getValues("months").includes(13)
                        ? ` For ${getValues("months")
                            .map((_self: any) => MonthNames[+_self])
                            .join(", ")}`
                        : ``
                    }`
                  : ""
              } ${
                reportYear ? ` -  ${new Date(reportYear).getFullYear()}` : ``
              }`,
              style: "header",
            },

            {
              style: "tableExample",
              widths: ["*"],
              table: {
                body: [
                  [
                    { text: "Project Name", style: "tableHeader" }, //1
                    {
                      text: "Employee Name", //2
                      style: "tableHeader",
                    },
                    {
                      text: "Employee ID", //3
                      style: "tableHeader",
                    },
                    {
                      text: "Year", //4
                      style: "tableHeader",
                    },
                    {
                      text: "Month", //5
                      style: "tableHeader",
                    },
                    {
                      text: "Working Days", //6
                      style: "tableHeader",
                    },
                    {
                      text: "Presents", //7
                      style: "tableHeader",
                    },
                    {
                      text: "Basic", //8
                      style: "tableHeader",
                    },
                    ...dynamicPDFHeaders,
                    {
                      text: "Gross Salary",
                      style: "tableHeader",
                    },
                    {
                      text: "Net Salary",
                      style: "tableHeader",
                    },
                    {
                      text: "CTC",
                      style: "tableHeader",
                    },
                  ],
                  ...dataForPDF,
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
              },
            },
            {
              style: "tableExample",
              widths: ["*", "*"],
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    "Total Basic",
                    Number(totalSalCountDataForPDF?.Basic).toFixed(2),
                  ],
                  ...dynamicTableHeader.map((dyna, index) => {
                    return [
                      `Total ${dyna.display_name} (${dyna.component_type_name})`,
                      Number(
                        totalSalCountDataForPDF[dyna.component_name].toFixed(2)
                      ),
                    ];
                  }),

                  [
                    "Total Gross Salary",
                    Number(totalSalCountDataForPDF?.Total_Earning).toFixed(2),
                  ],
                  [
                    "Total Net Salary",
                    Number(totalSalCountDataForPDF?.net_salary).toFixed(2),
                  ],
                  ["Total CTC", Number(totalSalCountDataForPDF.CTC).toFixed(2)],
                ],
              },
            },
          ],
          styles: {
            header: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 10,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: 900,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: "black",
              width: 900,
              paddingHorizontal: 5,
            },
          },
          defaultStyle: {
            fontSize: 9,
            color: "black",
            // alignment: 'justify'
          },
        };
        console.log("dd  : ", dd);
        pdfMake.createPdf(dd as any).download("Payroll-report.pdf");
      })
      .catch((err) => console.log("error : ", err));
  };

  useEffect(() => {
    if (getValues("organization_id") && getValues("employee_type_id")) {
      const months = !!getValues("months") ? getValues("months") : [];
      const payload: IPayrollReportPayload = {
        organization_id: getValues("organization_id"),
        months: months.includes(13) ? [] : months,
        year: getValues("year") ? getValues("year") : new Date().getFullYear(),
        employee_type_id: getValues("employee_type_id"),
        project_id: getValues("project_id") ? getValues("project_id") : null,
        employeeId: getValues("employeeId") ? getValues("employeeId") : null,
        isPDF: false,
        page_index: pageindex,
        page_size: pagesize,
        type: "",
      };
      GetPayrollReportAction(payload);
    }
  }, [pageindex, pagesize]);

  useEffect(() => {
    if (!!getValues("employee_type_id"))
      searchByNameEmpAction("", getValues("employee_type_id"));
  }, [watch("employee_type_id")]);
  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5, paddingBottom:"2%" }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  // display: "flex",
                  // flexDirection: "row",
                  // width: "100%",
                  // backgroundColor: "#0060FF",
                  // justifyContent: "space-between",
                  // marginTop: -2,
                  // borderTopLeftRadius: 5,
                  // borderTopRightRadius: 5,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container direction={"row"}>
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"center"}
                      paddingTop={0}
                    >
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={4} alignItems="center">
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Organization
                                <span style={{ color: "red", fontSize: 14 }}>
                                  *
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Controller
                                control={control}
                                name={"organization_id"}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={organization_list}
                                    //readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"organization_id"}
                                    label={"organization_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Organization"}
                                    value={!!value ? value : ""}
                                    errors={errors["organization_id"]}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {!!watch("organization_id") && (
                          <Grid item xs={4} alignItems="center">
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Employee Type
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Controller
                                  control={control}
                                  name={"employee_type_id"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <Autocompletes
                                      hasInfo={false}
                                      option={employee_type_list}
                                      //readOnly={!!department ? edit_flag : !edit_flag}
                                      keys={"employee_type_id"}
                                      label={"employee_type_name"}
                                      onBlur={onBlur}
                                      onChange={(value: any) => (
                                        onChange(value),
                                        setValue("project_id", null)
                                      )}
                                      placeholder={"Search Employee Type"}
                                      value={!!value ? value : ""}
                                      errors={!!errors["employee_type_id"]}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {!!watch("employee_type_id") &&
                          !environment.global_veriable.ho_employees_type_id.includes(
                            +watch("employee_type_id")
                          ) && (
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction={"row"}
                                wrap={"nowrap"}
                                alignItems="center"
                              >
                                <Grid item xs={5}>
                                  <Typography
                                    style={{
                                      fontWeight: "500",
                                      fontSize: 16,
                                      fontFamily: "Poppins, sans-serif",
                                      color: "#626974",
                                    }}
                                  >
                                    Project
                                    <span
                                      style={{ color: "red", fontSize: 14 }}
                                    >
                                      *
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} style={{ marginLeft: -5 }}>
                                  <Controller
                                    control={control}
                                    name={"project_id"}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onBlur, value, onChange },
                                    }) => (
                                      <Autocompletes
                                        hasInfo={false}
                                        option={project_list}
                                        //readOnly={!!department ? edit_flag : !edit_flag}
                                        keys={"project_id"}
                                        label={"project_name"}
                                        onBlur={onBlur}
                                        onChange={(value: any) =>
                                          onChange(value)
                                        }
                                        placeholder={"Search Project"}
                                        value={!!value ? value : ""}
                                        errors={!!errors["project_id"]}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        {!!watch("organization_id") ? (
                          <Grid item xs={4}>
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Year
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5} style={{ marginLeft: -5 }}>
                                <Controller
                                  control={control}
                                  name={"year"}
                                  defaultValue={""}
                                  // rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DesktopDatePicker
                                      label={
                                        <span>
                                          Select Year{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      }
                                      inputFormat="YYYY"
                                      InputProps={{
                                        sx: {
                                          align: "center",
                                          outline: "none",

                                          height: "2.3rem",
                                          border: "none",
                                          ml: 1,
                                        },
                                      }}
                                      views={["year"]}
                                      value={reportYear}
                                      onChange={handleYear}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}

                        {!!watch("organization_id") ? (
                          <Grid item xs={4} pt={1}>
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Select Month
                                </Typography>
                              </Grid>
                              <Grid item xs={5} style={{ marginLeft: -5 }}>
                                <Controller
                                  control={control}
                                  name={"months"}
                                  defaultValue={[]}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={monthOptions ? monthOptions : []}
                                      style={{
                                        width: "100.9%",
                                        marginLeft: "5%",
                                      }}
                                      getOptionLabel={(option) => option.key}
                                      onChange={(
                                        evemt: any,
                                        value: IMonthOptions[]
                                      ) => {
                                        onChange(
                                          !!value
                                            ? value.map((self) => self.val)
                                            : []
                                        );
                                      }}
                                      value={
                                        !!value
                                          ? monthOptions.filter((self) =>
                                              value.includes(self.val)
                                            )
                                          : []
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          id="standard-required"
                                          className={"smaller"}
                                          variant="outlined"
                                          placeholder="Choose Month"
                                          onBlur={onBlur}
                                          size="small"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}

                        {!!watch("employee_type_id") ? (
                          <Grid item xs={4}>
                            <SearchEmployee
                              searchByNameEmpAction={searchByNameEmpAction}
                              getValues={getValues}
                              control={control}
                              employeeList={employeeList}
                              fieldName={"employeeId"}
                              marginBottom={10}
                              marginTop={8}
                              gridVal={5}
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {!!watch("organization_id") && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end ",
                        marginRight: "5.3rem",

                        marginTop: "1rem",
                        marginBottom: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          marginRight: 1,
                        }}
                        onClick={() => (
                          setOpenReport(false),
                          reset(),
                          setReportYear(new Date()),
                          setValue("year", Number(new Date().getFullYear()))
                        )}
                      >
                        Clear
                      </Button>
                      <Button
                        size="small"
                        type="submit"
                        sx={{
                          marginLeft: 1,
                        }}
                        variant="outlined"
                      >
                        filter
                      </Button>
                    </Box>
                  )}
                </form>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {totalPayrollData && openReport === true ? (
          <TotalSalaryComponent
            totalSalCount={totalPayrollData}
            totalSize={total_size}
            noBetween={true}
            component_list={component_list}
            accessFor={[
              "basic",
              "hra",
              "esic_contribution",
              "esic_deduction",
              "ptax",
              "pf_contribution",
              "pf_deduction",
              "gross_salary",
              "net_salary",
              "ctc",
            ]}
          />
        ) : (
          <></>
        )}
        {openReport === true && (
          <Paper
            sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
          >
            <Grid
              container
              direction={"row"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid item xs={3} sx={{ paddingLeft: 5 }}>
                <Button
                  // onClick={() => navigate("/organization/add-department/0")}
                  onClick={onDownloadPDF}
                  size="medium"
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                >
                  Download
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  placeholder="Search..."
                  id="fullWidth"
                  value={searched}
                  onChange={(e: any) => (
                    requestSearch(e.target.value), setSearched(e.target.value)
                  )}
                  InputProps={{
                    endAdornment:
                      !!searched && searched.length > 0 ? (
                        <IconButton
                          color="primary"
                          onClick={() => cancelSearch()}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <IconButton color="primary">
                          <SearchIcon />
                        </IconButton>
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
            <Grid item xs={12} padding={2}>
              <Grid
                container
                direction={"row"}
                width={"100%"}
                justifyContent={"end"}
                alignItems={"center"}
                ref={divElement}
              >
                <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                      "& .MuiTableRow-root:hover": {
                        // backgroundColor: "#73c6ff",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows.map((row, index) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <StyledTableCell align="left" key={columns[0]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[1]}>
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[3]}>
                              <Link
                                onClick={() =>
                                  navigate(
                                    `/employment/manage-profile/${row.employee_id}`
                                  )
                                }
                              >
                                {row.employee_id}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[4]}>
                              {row.year}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[6]}>
                              {MonthNames[+row.month]}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[7]}>
                              {row["effective_working_days"]
                                ? row["effective_working_days"]
                                : 0}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[8]}>
                              {row["present_days"] ? row["present_days"] : 0}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={columns[9]}>
                              &#8377;{row.Basic}
                            </StyledTableCell>
                            {dynamicTableHeader.map((self, index) => (
                              <StyledTableCell
                                align="left"
                                key={columns[10 + index]}
                              >
                                &#8377;{row[self.component_name]}
                              </StyledTableCell>
                            ))}
                            <StyledTableCell
                              align="left"
                              key={columns[columns.length - 3]}
                            >
                              &#8377; {row.Total_Earning}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              key={columns[columns.length - 2]}
                            >
                              &#8377;{row.net_salary}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              key={columns[columns.length - 1]}
                            >
                              &#8377;{row.CTC}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      {!!rows && rows.length <= 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={16}>
                            <div style={{ color: "red", textAlign: "center" }}>
                              No Data Found{" "}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container alignItems={"center"} justifyContent="end">
                  <Grid xs={2} textAlign="right">
                    Rows per page:
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    <NativeSelect
                      value={pagesize.toString()}
                      onChange={(event) => SetpageSize(+event.target.value)}
                    >
                      {rowsperpage.map((p) => (
                        <option
                          key={p}
                          value={p}
                          onChange={(value: any) => value(p)}
                        >
                          {p}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    <IconButton
                      onClick={() => Setpageindex(pageindex - 1)}
                      color="primary"
                      size="small"
                      disabled={pageindex <= 0}
                    >
                      <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                    </IconButton>
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    <Typography>
                      {pageindex * pagesize + 1} -{" "}
                      {!!total_size && total_size <= (pageindex + 1) * pagesize
                        ? total_size
                        : (pageindex + 1) * pagesize}
                      {" of "}
                      {total_size}
                    </Typography>
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => Setpageindex(pageindex + 1)}
                      disabled={
                        (!!total_size &&
                          total_size <= (pageindex + 1) * pagesize) ||
                        total_size === 0
                      }
                    >
                      <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid marginTop={2}></Grid>
          </Paper>
        )}
      </Paper>
    </>
  );
};

export default PayrollReportsView;

interface IPayrollReportsView {
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
  GetAllProjectAction?: any;
  GetAllEmployeeTypeAction?: any;
  setOpenReport: any;
  openReport: boolean;
  GetPayrollReportAction?: any;
  payroll_list: any[];
  totalPayrollData?: any;
  total_size?: number;
  totalPayrollListForPDF: any[];
  totalSalCountDataForPDF?: any;
  searchByNameEmpAction?: any;
  employeeList: IEmployeeList[];
  SalaryStructuregetAll?: any;
  component_list: SalaryStuctureComponent[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    // color: "#ffff",
    // fontWeight: 600,
    // fontSize: 11,
    // width: "8rem !important",
    // padding: "3px",
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: { md: 14, sm: 12, xs: 8 },
    // padding: "5px",
    // color: "#363A44",
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
