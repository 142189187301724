import { FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Grid,
  TextField,
  IconButton,
  Checkbox,
  TextareaAutosize,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { userDetails } from "./../../../models/userModels";
import { StateList } from "../../../models/customerModel";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  py: 0,
};

const stateNames: { key: string; name: string }[] = [
  {
    key: "AN",
    name: "Andaman and Nicobar Islands",
  },
  {
    key: "AP",
    name: "Andhra Pradesh",
  },
  {
    key: "AR",
    name: "Arunachal Pradesh",
  },
  {
    key: "AS",
    name: "Assam",
  },
  {
    key: "BR",
    name: "Bihar",
  },
  {
    key: "CG",
    name: "Chandigarh",
  },
  {
    key: "CH",
    name: "Chhattisgarh",
  },
  {
    key: "DH",
    name: "Dadra and Nagar Haveli",
  },
  {
    key: "DD",
    name: "Daman and Diu",
  },
  {
    key: "DL",
    name: "Delhi",
  },
  {
    key: "GA",
    name: "Goa",
  },
  {
    key: "GJ",
    name: "Gujarat",
  },
  {
    key: "HR",
    name: "Haryana",
  },
  {
    key: "HP",
    name: "Himachal Pradesh",
  },
  {
    key: "JK",
    name: "Jammu and Kashmir",
  },
  {
    key: "JH",
    name: "Jharkhand",
  },
  {
    key: "KA",
    name: "Karnataka",
  },
  {
    key: "KL",
    name: "Kerala",
  },
  {
    key: "LD",
    name: "Lakshadweep",
  },
  {
    key: "MP",
    name: "Madhya Pradesh",
  },
  {
    key: "MH",
    name: "Maharashtra",
  },
  {
    key: "MN",
    name: "Manipur",
  },
  {
    key: "ML",
    name: "Meghalaya",
  },
  {
    key: "MZ",
    name: "Mizoram",
  },
  {
    key: "NL",
    name: "Nagaland",
  },
  {
    key: "OR",
    name: "Odisha",
  },
  {
    key: "PY",
    name: "Puducherry",
  },
  {
    key: "PB",
    name: "Punjab",
  },
  {
    key: "RJ",
    name: "Rajasthan",
  },
  {
    key: "SK",
    name: "Sikkim",
  },
  {
    key: "TN",
    name: "Tamil Nadu",
  },
  {
    key: "TS",
    name: "Telangana",
  },
  {
    key: "TR",
    name: "Tripura",
  },
  {
    key: "UK",
    name: "Uttar Pradesh",
  },
  {
    key: "UP",
    name: "Uttarakhand",
  },
  {
    key: "WB",
    name: "West Bengal",
  },
];

const HolidayModal: FC<IHolidayModal> = ({
  isModalOpen,
  setModalOpen,
  AddHolidayAction,
  selectedDate,
  user,
  editablePayload,
  isEditing,
  setIsEditing,
  setEditablePayload,
  selectedOrganizationLeave,
  editHolidayAction,
  state_list,
}): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, SetIsChecked] = useState<boolean>(false);
  const [isNationalHoliday, SetIsNaitionalHoliday] = useState<boolean>(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!!editablePayload) {
      setValue("holiday_description", editablePayload.holiday_description);
      setValue("holiday_name", editablePayload.holiday_name);
      setValue("flexible_leave", Boolean(editablePayload.flexible_leave));
      setValue(
        "is_national_holiday",
        Boolean(editablePayload.is_national_holiday)
      );
      setValue("applicable_states", editablePayload.applicable_states);
      editablePayload.flexible_leave ? SetIsChecked(true) : SetIsChecked(false);
      editablePayload?.is_national_holiday
        ? SetIsNaitionalHoliday(true)
        : SetIsNaitionalHoliday(false);
    }
  }, [editablePayload]);

  const onSubmit = (formData: any) => {
    console.log("formData : ", formData);
    console.log("selectedDate", selectedDate);
    if (!isEditing) {
      const payload = {
        formData: {
          date: selectedDate,
          holiday_description: formData.holiday_description,
          holiday_name: formData.holiday_name,
          organization_id: selectedOrganizationLeave
            ? selectedOrganizationLeave
            : user?.employee_detail.organization_id,
          flexible_leave: formData?.flexible_leave
            ? formData.flexible_leave
            : false,
          is_national_holiday: formData.is_national_holiday,
          applicable_states: formData?.applicable_states
            ? formData.applicable_states
            : [],
        },
        enqueueSnackbar,
      };

      console.log("payload : ", payload);
      AddHolidayAction(payload);
    } else {
      if (editablePayload) {
        const payload = {
          formData: {
            date: editablePayload?.date,
            holiday_description: formData.holiday_description,
            holiday_name: formData.holiday_name,
            organization_id: selectedOrganizationLeave
              ? selectedOrganizationLeave
              : user?.employee_detail.organization_id,
            flexible_leave: formData?.flexible_leave
              ? formData.flexible_leave
              : false,
            holiday_id: Number(editablePayload.id),
            is_national_holiday: formData.is_national_holiday,
            applicable_states: formData?.applicable_states
              ? formData.applicable_states
              : [],
          },
          enqueueSnackbar,
        };
        editHolidayAction(payload);
      }
    }
    setModalOpen(false);
  };

  console.log("errors : ", errors);
  useEffect(() => {
    if (!isModalOpen) {
      setIsEditing(false);
      setEditablePayload(null);
      reset({
        holiday_description: "",
        holiday_name: "",
        flexible_leave: false,
        is_national_holiday: false,
      });
      SetIsChecked(false);
      SetIsNaitionalHoliday(false);
    }
  }, [isModalOpen]);

  // deleteable
  useEffect(() => {
    if (!editablePayload) {
      setValue("is_national_holiday", true);
      SetIsNaitionalHoliday(true);
    }
  }, [editablePayload]);

  useEffect(() => {
    if (isModalOpen && !editablePayload) {
      setValue("applicable_states", []);
    }
  }, [isModalOpen, editablePayload]);

  const stateNames = state_list.map((self) => self.state_name);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={isModalOpen}>
          <Box sx={style}>
            <Box
              sx={{
                background: "#0060FF",
                p: 2,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {!isEditing ? "Add Holiday" : "Edit Holiday"}
              </Typography>
              <IconButton onClick={() => handleClose()} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ py: 3, px: 5 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"holiday_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          className={"smaller"}
                          onBlur={onBlur}
                          style={{ width: "100%" }}
                          size="small"
                          label={
                            <span>
                              Holiday Name
                              <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          onChange={onChange}
                          value={value}
                          error={!!errors["holiday_name"]}
                          helperText={
                            errors.holiday_name &&
                            `${errors.holiday_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"holiday_description"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextareaAutosize
                        style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                          onBlur={onBlur}
                          id="holiday_desc"
                          className={"smaller"}
                          // id="standard-required"
                          onChange={onChange}
                          value={value}
                          placeholder={"Holiday Description"}
                          minRows={2}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box>
                      <Controller
                        control={control}
                        name={"flexible_leave"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={isChecked}
                            onChange={(event) => (
                              SetIsChecked(event.target.checked),
                              setValue(
                                "flexible_leave",
                                Boolean(event.target.checked)
                              )
                            )}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />
                    </Box>
                    <Typography>is Flexible Leave</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box>
                      <Controller
                        control={control}
                        name={"is_national_holiday"}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={isNationalHoliday}
                            onChange={(event) => (
                              SetIsNaitionalHoliday(event.target.checked),
                              setValue(
                                "is_national_holiday",
                                Boolean(event.target.checked)
                              )
                            )}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />
                    </Box>
                    <Typography>National Holiday</Typography>
                  </Grid>
                  {!watch("is_national_holiday") ? (
                    <Grid
                      item
                      xs={12}
                      mb={1}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Controller
                        control={control}
                        name={"applicable_states"}
                        defaultValue={[]}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={!!stateNames ? stateNames : []}
                            style={{ width: "100.9%" }}
                            getOptionLabel={(option) => option}
                            onChange={(evemt, value) =>
                              onChange(!!value ? value.map((self) => self) : [])
                            }
                            value={
                              !!value
                                ? stateNames.filter((self) =>
                                    value.includes(self)
                                  )
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="standard-required"
                                className={"smaller"}
                                variant="outlined"
                                placeholder="Applicable States"
                                onBlur={onBlur}
                                size="small"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="outlined" type="submit">
                    Submit
                  </Button>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
interface IEventPayload {
  date: string;
  holiday_description: string;
  organization_id: number;
  flexible_leave: boolean;
  holiday_name: string;
  is_national_holiday: boolean;
  applicable_states: string[];
  id: number;
}

interface IHolidayModal {
  isModalOpen: boolean;
  setModalOpen: any;
  AddHolidayAction?: any;
  selectedDate: string;
  user?: userDetails;
  editablePayload: IEventPayload | null;
  isEditing: boolean;
  setIsEditing: any;
  setEditablePayload: any;
  editHolidayAction?: any;
  selectedOrganizationLeave?: number;
  state_list: StateList[];
}

export default HolidayModal;
