import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PendingApprovalList } from "../../../models/attendanceModel";
import { FilterList, Clear } from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { environment } from "../../../environment";
import AttendanceApproveSection from "./AttendanceApproveSection";
import EditIcon from "@mui/icons-material/Edit";

const AttendanceApproveView = ({
  pending_approval,
  ApprovedAction,
  AttendanceDetailPayloadSuccessAction,
}: AttendanceApproveViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [rows, setRows] = useState<PendingApprovalList[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const open = Boolean(anchorEl);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!!pending_approval) {
      setRows(pending_approval);
    }
  }, [pending_approval]);

  const column1: any[] = [
    "Project",
    "Month",
    "Employee Type",
    "Total Employee",
    "Uploaded Attendance",
    "Created By",
    "Created On",
    "Action Needed",
    "Action",
  ];
  const [approvalList, SetapprovalList] = useState<PendingApprovalList[]>([]);
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const SelectRow = (checked: boolean, row: PendingApprovalList) => {
    if (checked) {
      if (
        approvalList.length > 0 &&
        JSON.stringify(
          approvalList[approvalList.length - 1].event_action_items
        ) != JSON.stringify(row.event_action_items)
      ) {
        enqueueSnackbar(`Need to select same type action.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (approvalList.length >= 100) {
        enqueueSnackbar(`max limit 100 exceed`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (
        approvalList.length > 0 &&
        (approvalList[approvalList.length - 1].project_name !=
          row.project_name ||
          approvalList[approvalList.length - 1].division_name !=
            row.division_name)
      ) {
        enqueueSnackbar(`Mutiselect is only allow for same type of project.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        let events: PendingApprovalList[] = JSON.parse(
          JSON.stringify(approvalList)
        );
        events.push(row);
        SetapprovalList(events);
      }
    } else {
      let events: PendingApprovalList[] = JSON.parse(
        JSON.stringify(approvalList)
      );
      events = events.filter((m) => m.empLifeEventId != row.empLifeEventId);
      if (events.length == 0) Set_Avpprove(false);
      SetapprovalList(events);
    }
  };
  useEffect(() => {
    const AllSelection = async () => {
      if (all_approve) {
        let appr: PendingApprovalList[] = [];
        for await (const [index, ap] of rows.entries()) {
          if (index == 0) {
            appr.push(ap);
          } else {
            if (
              JSON.stringify(appr[appr.length - 1].event_action_items) ==
                JSON.stringify(ap.event_action_items) &&
              appr[appr.length - 1].project_name == ap.project_name &&
              appr[appr.length - 1].division_name == ap.division_name
            ) {
              appr.push(ap);
            }
          }
          if (appr.length >= 100) {
            break;
          }
        }
        SetapprovalList(appr);
        enqueueSnackbar(
          `${
            appr.length > 100 ? "100" : appr.length
          } same type of action row selected`,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        SetapprovalList([]);
      }
    };
    AllSelection();
  }, [all_approve]);
  const [openAttendancePopup, setOpenAttendancePopup] = React.useState(false);
  const AcceptAttendance = () => {
    setOpenAttendancePopup(true);
  };
  const DomValue = (st: string) => {
    return environment.event_action_items.find((m) => m.key == st)?.value;
  };
  // const [submit_payload, SetsubmitPayload] = useState<any>();
  const [searched, setSearched] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!pending_approval
      ? pending_approval.filter((row: PendingApprovalList) => {
          return (
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.eventStatus
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.initiatedBy
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_type_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const [final_flag, setfinalFlag] = useState<boolean>(false);
  const [submit_flaga, setsubmitFlaga] = useState<number>(0);
  useEffect(() => {
    if (!!pending_approval) {
      cancelSearch();
    }
  }, [pending_approval]);

  const [approvePayload, setapprovePayload] = useState<any>();
  const FinalApprovedSubmit = (data: any) => {
    setapprovePayload(data);
    // console.log("data", data);
  };
  const AprrovedAttendanceAction = (data: any) => {
    // console.log(data);
    ApprovedAction(data);
  };
  return (
    <Paper
      elevation={3}
      sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        // style={{ textAlign: "center", paddingTop: 8 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Dialog
        open={final_flag}
        onClose={() => setfinalFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              submit_flaga == 1 ? "reject" : "approve"
            } this attendance?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setfinalFlag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (
              AprrovedAttendanceAction(approvePayload),
              setfinalFlag(false),
              setOpenAttendancePopup(false),
              SetapprovalList([])
            )}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AttendanceApproveSection
        approvalList={approvalList}
        open={openAttendancePopup}
        setOpen={setOpenAttendancePopup}
        SetapprovalList={SetapprovalList}
        DomValue={DomValue}
        FinalApprovedSubmit={FinalApprovedSubmit}
        setfinalFlag={setfinalFlag}
        setsubmitFlaga={setsubmitFlaga}
      />
      <Grid container direction={"row"} marginTop={1}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="justify" key={""}>
                      <Checkbox
                        style={{ color: "#ffff" }}
                        checked={all_approve}
                        onChange={(event) => Set_Avpprove(event.target.checked)}
                      />
                    </StyledTableCell>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.project_attendence_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            <Checkbox
                              color="primary"
                              checked={
                                !!approvalList.find(
                                  (m) => m.batch_id == row.batch_id
                                )
                              }
                              onChange={(event) =>
                                SelectRow(event.target.checked, row)
                              }
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.project_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.month
                              ? moment(row.month)
                                  .format("MMMM ")
                                  .toUpperCase() +
                                "'" +
                                moment(row.month).format("YY").toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.employee_type_name}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[4]}>
                            {row.total_employee_count}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[5]}>
                            {row.uploaded_employee_count}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[6]}>
                            <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.created_by}`
                                )
                              }
                            >
                              {row.created_by ? row.created_by : "N/A"}
                            </Link>
                            <br/>
                          ({row.initiated_employee_name})
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[7]}>
                            {row.created_at
                              ? moment(row.created_at)
                                  .format("DD/MM/YY ")
                                  .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[8]}>
                            {row.event_action_items
                              .filter(
                                (item, ind) =>
                                  row.event_action_items.indexOf(item) === ind
                              )
                              .map(
                                (m, index) =>
                                  DomValue(m) + (!!DomValue(m) ? ", " : "")
                              )}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[9]}>
                            <Tooltip
                              title=" Edit & View"
                              onClick={() => (
                                AttendanceDetailPayloadSuccessAction({
                                  attendence_salary_type_id:
                                    row.attendence_salary_type_id,
                                  organization_id: row?.organization_id,
                                  employee_type_id: row.employee_type_id,
                                  project_id: row?.project_id,
                                  project_attendence_id:
                                    row.project_attendence_id,
                                  division_id: row.division_id,
                                  page_index: 0,
                                  page_size: 2,
                                  // editable: 0
                                }),
                                navigate(`/attendance/attendance-details/1`)
                              )}
                            >
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#1565C0",
            paddingX: 4,
            marginRight: 10,
          }}
          disabled={approvalList.length <= 0}
          onClick={() => AcceptAttendance()}
          variant="contained"
          color="primary"
          size="large"
        >
          Approve / Reject
        </Button>
      </Grid>
    </Paper>
  );
};

export default AttendanceApproveView;

interface AttendanceApproveViewProps {
  pending_approval: PendingApprovalList[];
  ApprovedAction?: any;
  AttendanceDetailPayloadSuccessAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
