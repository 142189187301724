import { FC, useEffect, useState } from "react";
import HolidayCalendar from "./HolidayCalendar";
import {
  getAllHolidaysAction,
  AddHolidayAction,
  getHolidayById,
  editHolidayAction,
  deleteHolidayByAction,
  selectOrganization,
} from "../../../stores/actions/LeaveAction";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { IHolidayList } from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { OrganizationALlList } from "../../../models/organizationModels";
import { GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import OrganizationAutoComplete from "../OrganizationAutoComplete";
import StateAutoComplete from "./StateAutoComplete";
import { GetAllStateListAction } from "../../../stores/actions/customerAction";
import { StateList } from "../../../models/customerModel";
const OrganizationalHoliday: FC<IOrganizationHoliday> = ({
  getAllHolidaysAction,
  selectOrganization,
  GetAllOrganizationAction,
  organization_list,
  AddHolidayAction,
  getHolidayById,
  editHolidayAction,
  deleteHolidayByAction,
  selected_organization_leave,
  holiday_list,
  holiday,
  user,
  state_list,
  GetAllStateListAction,
}): JSX.Element => {
  const [calendarYear, setCalendarYear] = useState<any>(
    new Date().getFullYear()
  );
  const [selectedStates, setSelectedStates] = useState<string>("");
  useEffect(() => {
    if (!!selected_organization_leave)
      getAllHolidaysAction(
        Number(selected_organization_leave),
        Number(calendarYear),
        selectedStates
      );
  }, [selected_organization_leave, calendarYear, selectedStates]);
  useEffect(() => {
    GetAllStateListAction();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StateAutoComplete
          selectedState={selectedStates}
          setSelectedState={setSelectedStates}
          stateList={state_list}
        />
        <OrganizationAutoComplete
          organization_list={organization_list}
          user={user}
          selectOrganization={selectOrganization}
          selected_organization_leave={selected_organization_leave}
          GetAllOrganizationAction={GetAllOrganizationAction}
        />
      </Box>
      <HolidayCalendar
        holiday_list={holiday_list}
        holiday={holiday}
        getAllHolidaysAction={getAllHolidaysAction}
        AddHolidayAction={AddHolidayAction}
        editHolidayAction={editHolidayAction}
        deleteHolidayByAction={deleteHolidayByAction}
        getHolidayById={getHolidayById}
        selectedOrganizationLeave={selected_organization_leave}
        user={user}
        setCalendarYear={setCalendarYear}
        calendarYear={calendarYear}
        setSelectedState={setSelectedStates}
        selectedState={selectedStates}
        state_list={state_list}
      />
    </Box>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    holiday_list: state.leave.holiday_list,
    holiday: state.leave.holiday,
    user: state.user.userDetails,
    organization_list: state.organization.organization_filter_list,
    selected_organization_leave: state.leave.selected_organization,
    state_list: state.customer.state_list,
  };
};
const mapDispatchToProps = {
  getAllHolidaysAction,
  AddHolidayAction,
  getHolidayById,
  editHolidayAction,
  deleteHolidayByAction,
  GetAllOrganizationAction,
  selectOrganization,
  GetAllStateListAction,
};
interface IOrganizationHoliday {
  GetAllOrganizationAction?: any;
  selectOrganization?: any;
  getAllHolidaysAction?: any;
  AddHolidayAction?: any;
  getHolidayById?: any;
  editHolidayAction?: any;
  holiday_list: IHolidayList[];
  deleteHolidayByAction?: any;
  holiday?: IHolidayList;
  user?: userDetails;
  selected_organization_leave?: number;
  organization_list: OrganizationALlList[];
  GetAllStateListAction?: any;
  state_list: StateList[];
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationalHoliday);
