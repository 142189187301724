import React from 'react'
import { connect } from 'react-redux';
import { CustomerList } from '../../../models/customerModel';
import { OrganizationALlList } from '../../../models/organizationModels';
import { StoreState } from '../../../models/reduxModels';
import { GetAllCustomerAction } from '../../../stores/actions/customerAction';
import {  GetAllOrganizationAction } from '../../../stores/actions/organizationActions';
import { SidenavAutoCloseOpen } from '../../../stores/actions/userAction';
import CustomerListView from './CustomerList';
import { userDetails } from '../../../models/userModels';

function CustomerMain({
  GetAllOrganizationAction,
  GetAllCustomerAction,
  customer_list,
  organization_list,
  SidenavAutoCloseOpen,
  user
}:CustomerMainProps) {

  const filterData = (props: any) => {
    GetAllCustomerAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
  };
  const Clear = () => {
    GetAllCustomerAction({
      organization_id: null,
    });
  };
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllCustomerAction({ organization_id: null })
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  return (
    <CustomerListView
    customer_list={customer_list}
    organization_list={organization_list}
    filterData={filterData}
    Clear={Clear}
    user={user}
    />
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    customer_list: state.customer.customer_list,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllCustomerAction,
  SidenavAutoCloseOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMain);

interface CustomerMainProps {
  GetAllOrganizationAction?: any;
  GetAllCustomerAction?:any;
  customer_list: CustomerList[]
 organization_list: OrganizationALlList[];
 SidenavAutoCloseOpen?: any;
 user?: userDetails;
}