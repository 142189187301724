import { Box, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
} from "react-hook-form";
import { EmpProject } from "../../../models/timingSheetModel";

interface CalendarProjectAllocationProps {
  control: Control<FieldValues, any>;
  emp_project: EmpProject;
  pIndex: number;
  notEditable: boolean | undefined;
  status: string;
  selectedDate: any;
  isworklist?: boolean;
  notShow?: boolean;
  setModalDataHours: any;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}
const CalendarProjectAllocation: FC<CalendarProjectAllocationProps> = ({
  control,
  errors,
  emp_project,
  status,
  isworklist,
  notEditable,
  notShow,
  selectedDate,
  pIndex,
  setModalDataHours,
}) => {
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  const [currentDateData, setCurrentDateData] = useState<any>();
  const [timeIndex, setTimeIndex] = useState<number>(-1);
  console.log("timeIndex: ", timeIndex);
  console.log("currentDateData: ", currentDateData);

  console.log(
    ["approved", "initiate"].includes(currentDateData?.status.toLowerCase())
  );
  const [isHalfDay, setHalfDay] = useState<boolean>(false);

  console.log({ isworklist });
  useEffect(() => {
    let timeData = emp_project.time_tab.find(
      (self, index) =>
        new Date(self.date).getMonth() === new Date(selectedDate).getMonth() &&
        new Date(self.date).getDate() === new Date(selectedDate).getDate()
    );
    let timeDataIndex = emp_project.time_tab.findIndex(
      (self, index) =>
        new Date(self.date).getMonth() === new Date(selectedDate).getMonth() &&
        new Date(self.date).getDate() === new Date(selectedDate).getDate()
    );

    console.log("timeData", timeData);
    setTimeIndex(timeDataIndex);
    setCurrentDateData(timeData);
  }, [emp_project, selectedDate]);

  console.log({ notEditable, isworklist, notShow });
  console.log(timeIndex + pIndex * emp_project.time_tab.length);
  console.log({ status: currentDateData?.status });
  return (
    <Box
      sx={{
        display: "flex",

        justifyContent: "space-between",
        flexDirection: matchesMobileView ? "column" : "row",
        color: "gray",
        py: matchesMobileView ? 0 : 1,
      }}
    >
      <Box
        sx={{
          width: matchesMobileView ? "100%" : "40%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography>{emp_project?.project_name}</Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: matchesMobileView ? "100%" : "50%",
        }}
      >
        {timeIndex >= 0 ? (
          <Controller
            control={control}
            rules={{ max: 8 }}
            name={`time_tab.[${
              timeIndex + pIndex * emp_project.time_tab.length
            }].work_hours`}
            defaultValue={""}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                sx={{
                  backgroundColor: "white",
                  width: "100%",
                  // color: _self.isHoliday ? `#F71A31` : "gray",
                }}
                type={"number"}
                onWheel={(event: any) => {
                  return event?.target?.blur();
                }}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                // disabled={
                //   notShow
                //     ? true
                //     : !!isworklist &&
                //       status.toLowerCase() !== "approved"
                //     ? false
                //     : !!notEditable ||
                //       (!isworklist &&
                //         ["approved", "initiate"].includes(
                //           status.toLowerCase()
                //         ))
                // }
                disabled={
                  notShow
                    ? true
                    : !!isworklist &&
                      currentDateData?.status?.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(
                          currentDateData?.status.toLowerCase()
                        ))
                }
                onBlur={onBlur}
                size="small"
                onChange={(e) => {
                  if (Number(e.target.value) <= 8) {
                    onChange(e);
                  }
                }}
                value={value}
                placeholder={"0"}
                //   error={
                //     !!Array.isArray(errors?.time_tab) &&
                //     errors?.time_tab[index + pIndex * arr.length]
                //       ?.work_hours?.message
                //   }
              />
            )}
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default CalendarProjectAllocation;
