import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  EmployeeList,
  UploadedEmployeeAttendanceList,
} from "../../../models/attendanceModel";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import {
  DomainList,
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  AttendanceDashboardAction,
  AttendanceDetailPayloadSuccessAction,
  UploadeAttendanceViewAction,
} from "../../../stores/actions/attendanceAction";
import {
  GetAllCustomerAction,
  GetAllProjectAction,
} from "../../../stores/actions/customerAction";
import { GetAttendenceSalarytypeAction } from "../../../stores/actions/employeeAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import AttendanceMainView from "./AttendanceMainView";
import { ApproveAttendanceListAction } from "../../../stores/actions/attendanceAction";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";

const AttendanceMain = ({
  organization_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  customer_list,
  GetAllCustomerAction,
  project_list,
  GetAllProjectAction,
  accessToken,
  AttendanceDashboardAction,
  attendance_dashboard,
  attendence_salary_type,
  GetAttendenceSalarytypeAction,
  UploadeAttendanceViewAction,
  employee_list,
  total_count,
  AttendanceDetailPayloadSuccessAction,
  ApproveAttendanceListAction,
  attendance_approve_list,
  CreateCandidateSuccessAction,
}: AttendanceMainProps) => {
  let navigateTo = useNavigate();
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllCustomerAction({
      organization_id: null,
    });
    GetAllProjectAction({
      organization_id: null,
    });
    GetAttendenceSalarytypeAction();
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const [detailsData, setdetailsData] = useState<any>();
  const Submit = (data: any) => {
    // console.log("set attendance option ", data);
    setdetailsData(data);
    AttendanceDashboardAction({
      data: data,
    });
  };
  const ExcelDetailsView = (data: any) => {
    AttendanceDetailPayloadSuccessAction(data);
    navigateTo(`/attendance/attendance-details/0`);
  };

  const historyDetails = (g: any) => {
    // console.log(detailsData);
    ApproveAttendanceListAction({
      ...detailsData,
      // division_id: g,
      // project_id: attendance_dashboard?.project_id,
    });
  };
  return (
    <AttendanceMainView
      organization_list={organization_list}
      employee_type_list={employee_type_list}
      customer_list={customer_list}
      project_list={project_list}
      accessToken={accessToken}
      Submit={Submit}
      attendance_dashboard={attendance_dashboard}
      attendence_salary_type={attendence_salary_type}
      ExcelDetailsView={ExcelDetailsView}
      attendance_approve_list={attendance_approve_list}
      historyDetails={historyDetails}
      CreateCandidateSuccessAction={CreateCandidateSuccessAction}
      AttendanceDetailPayloadSuccessAction={
        AttendanceDetailPayloadSuccessAction
      }
    />
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_type_list: state.organization.employee_type_list,
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    accessToken: state.user.userDetails?.accessToken,
    attendance_dashboard: state.attendance.attendance_dashboard,
    attendence_salary_type: state.employee.attendence_salary_type,
    // upload_attendance_view: state.attendance.upload_attendance_view,
    employee_list: state.attendance.employee_list,
    total_count: state.attendance.total_count,
    attendance_approve_list: state.attendance.attendance_approve_list,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllEmployeeTypeAction,
  GetAllCustomerAction,
  GetAllProjectAction,
  AttendanceDashboardAction,
  GetAttendenceSalarytypeAction,
  UploadeAttendanceViewAction,
  AttendanceDetailPayloadSuccessAction,
  ApproveAttendanceListAction,
  CreateCandidateSuccessAction,
  SidenavAutoCloseOpen,
};
export default connect(mapStateToProps, mapDispatchToProps)(AttendanceMain);
interface AttendanceMainProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  customer_list: CustomerList[];
  project_list: ProjectList[];
  GetAllOrganizationAction?: any;
  GetAllEmployeeTypeAction?: any;
  GetAllCustomerAction?: any;
  GetAllProjectAction?: any;
  accessToken?: string;
  AttendanceDashboardAction?: any;
  attendance_dashboard?: AttendanceDashboardView[];
  attendence_salary_type: DomainList[];
  GetAttendenceSalarytypeAction?: any;
  UploadeAttendanceViewAction?: any;
  employee_list: UploadedEmployeeAttendanceList[];
  total_count?: number;
  AttendanceDetailPayloadSuccessAction?: any;
  ApproveAttendanceListAction?: any;
  attendance_approve_list: AttendanceApprovalList[];
  CreateCandidateSuccessAction?: any;
  SidenavAutoCloseOpen?: any;
}
