import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateProjectPaymentAction,
  EditPaymentDetailsAction,
} from "../../../stores/actions/customerAction";
import CreatePaymentView from "./CreatePaymentView";
import { InvoiceListAction } from "../../../stores/actions/invoiceAction";
import { InvoiceListResponse } from "../../../models/invoiceModel";

const CreatePayment = ({
  project_payment_list,
  CreateProjectPaymentAction,
  EditPaymentDetailsAction,
  project_list,
  InvoiceListAction,
  invoice_list_details
}: CreatePaymentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { id2 } = useParams();
  let navigateTo = useNavigate();
  const Submit = (data: any) => {
    console.log('data submit', data);
    
    if (!!id && +id != 0) {
      CreateProjectPaymentAction({
        data: { ...data, project_id: +id },
        navigateTo: navigateTo,
        enqueueSnackbar: enqueueSnackbar,
      });
    }
  };
  const SubmitUpdatedData = (data: any) => {
    console.log('data update', data);
    EditPaymentDetailsAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  useEffect(() => {
    InvoiceListAction({
      project_id: id,
      page_index: 0,
      page_size: 1000
    })
  }, [project_payment_list])
  // console.log('project_payment_list', project_payment_list);
  
  return (
    <CreatePaymentView
      project_payment={project_payment_list.find(
        (m) => !!id2 && m.project_payment_id == +id2
      )}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
      project_list={project_list}
      id={id}
      invoice_list_details={invoice_list_details}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    project_payment_list: state.customer.project_payment_list,
    project_list: state.customer.project_list,
    invoice_list_details: state.invoice.invoice_list_details,
  };
};
const mapDispatchToProps = {
  CreateProjectPaymentAction,
  EditPaymentDetailsAction,
  InvoiceListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayment);
interface CreatePaymentProps {
  project_payment_list: ProjectPaymentList[];
  CreateProjectPaymentAction?: any;
  EditPaymentDetailsAction?: any;
  project_list: ProjectList[];
  InvoiceListAction?: any
  invoice_list_details?: InvoiceListResponse
}
