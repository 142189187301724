import { userMain } from "../../models/userModels";
import { UserActionTypes } from "../actions/userAction";
import InitialState from "./initialState";

const initialState: userMain = InitialState.user;
export default function UserReducer(
  state: userMain = initialState,
  action: any
) {
  switch (action.type) {
    case UserActionTypes.Logout_Success_Action:
      return { ...initialState };
    case UserActionTypes.User_Login_Success_Action:
      return { ...state, userDetails: action.payload };
    case UserActionTypes.Side_nav_Auto_Close_Open:
      return { ...state, sideNavClose: action.payload };
    default:
      return state;
  }
}
