import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ErrorModel } from "../../models/errorModels";
import { StoreState } from "../../models/reduxModels";

const MessageToster = ({ error }: MessageTosterProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const ShowMessage = async (
    data: string,
    type: "default" | "error" | "success" | "warning" | "info"
  ) => {
    await enqueueSnackbar(data, {
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };
  useEffect(() => {
    if (!!error && !!error.message) {
      ShowMessage(error.message, "error");
    }
  }, [error]);
  return <div></div>;
};

const mapStateToProps = (state: StoreState) => {
  return {
    error: state.error.error,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MessageToster);

interface MessageTosterProps {
  error?: ErrorModel;
}
