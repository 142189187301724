import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ProjectList } from "../../../models/customerModel";
import { EmpList, IEmployeePDFList } from "../../../models/employeeModel";
import {
  AllDepartmentList,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import {
  getAllEmployeeAction,
  resetpasswordrequestAction,
} from "../../../stores/actions/employeeAction";
import {
  GetAllDepartmentAction,
  GetAllEmployeeTypeAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import EmployeeListview from "./EmployeeListview";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { userDetails } from "../../../models/userModels";

function AllEmployee({
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  GetAllDepartmentAction,
  employee_type_list,
  project_list,
  department_list,
  getAllEmployeeAction,
  employee_list,
  total_size,
  SidenavAutoCloseOpen,
  searchByNameEmpAction,
  searchEmployeeList,
  accessToken,
  user,
  resetpasswordrequestAction,
}: AllEmployeeProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [dataPayload, setDataPayload] = useState<any>({});
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);

  const filterData = (props: any) => {
    const payload = {
      employee_id:
        !!props.employee_id && props.employee_id != ""
          ? props.employee_id
          : null,
      department_id:
        !!props.department_id && props.department_id != ""
          ? props.department_id
          : null,
      employee_type_id:
        !!props.employee_type_id && props.employee_type_id != ""
          ? props.employee_type_id
          : null,
      employee_onboarding_status:
        !!props.employee_onboarding_status &&
        props.employee_onboarding_status != ""
          ? props.employee_onboarding_status
          : null,
      employee_name:
        !!props.employee_name && props.employee_name != ""
          ? props.employee_name
          : null,
      employee_mob_no:
        !!props.employee_mob_no && props.employee_mob_no != ""
          ? props.employee_mob_no
          : null,
      employee_email:
        !!props.employee_email && props.employee_email != ""
          ? props.employee_email
          : null,
      esic_no: !!props.esic_no && props.esic_no != "" ? props.esic_no : null,
      uan_no: !!props.uan_no && props.uan_no != "" ? props.uan_no : null,
      employee_dob:
        !!props.employee_dob && props.employee_dob != ""
          ? props.employee_dob
          : null,
      project_id:
        !!props.project_id && props.project_id != "" ? props.project_id : null,
    };
    setDataPayload(payload);
    getAllEmployeeAction({
      employee_id:
        !!props.employee_id && props.employee_id != ""
          ? props.employee_id
          : null,
      department_id:
        !!props.department_id && props.department_id != ""
          ? props.department_id
          : null,
      employee_type_id:
        !!props.employee_type_id && props.employee_type_id != ""
          ? props.employee_type_id
          : null,
      employee_onboarding_status:
        !!props.employee_onboarding_status &&
        props.employee_onboarding_status != ""
          ? props.employee_onboarding_status
          : null,
      employee_name:
        !!props.employee_name && props.employee_name != ""
          ? props.employee_name
          : null,
      employee_mob_no:
        !!props.employee_mob_no && props.employee_mob_no != ""
          ? props.employee_mob_no
          : null,
      employee_email:
        !!props.employee_email && props.employee_email != ""
          ? props.employee_email
          : null,
      esic_no: !!props.esic_no && props.esic_no != "" ? props.esic_no : null,
      uan_no: !!props.uan_no && props.uan_no != "" ? props.uan_no : null,
      employee_dob:
        !!props.employee_dob && props.employee_dob != ""
          ? props.employee_dob
          : null,
      project_id:
        !!props.project_id && props.project_id != "" ? props.project_id : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const Clear = () => {
    const payload = {
      employee_id: null,
      department_id: null,
      employee_type_id: null,
      employee_onboarding_status: null,
      employee_name: null,
      employee_mob_no: null,
      employee_email: null,
      esic_no: null,
      uan_no: null,
      employee_dob: null,
      project_id: null,
    };
    setDataPayload(payload);
    getAllEmployeeAction({
      employee_id: null,
      department_id: null,
      employee_type_id: null,
      employee_onboarding_status: null,
      employee_name: null,
      employee_mob_no: null,
      employee_email: null,
      esic_no: null,
      uan_no: null,
      employee_dob: null,
      project_id: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  useEffect(() => {
    getAllEmployeeAction({
      employee_id: !!getValues("employee_id") ? getValues("employee_id") : null,
      department_id: !!getValues("department_id")
        ? getValues("department_id")
        : null,
      employee_type_id: !!getValues("employee_type_id")
        ? getValues("employee_type_id")
        : null,
      employee_onboarding_status: !!getValues("employee_onboarding_status")
        ? getValues("employee_onboarding_status")
        : null,
      employee_name: !!getValues("employee_name")
        ? getValues("employee_name")
        : null,
      employee_mob_no: !!getValues("employee_mob_no")
        ? getValues("employee_mob_no")
        : null,
      employee_email: !!getValues("employee_email")
        ? getValues("employee_email")
        : null,
      esic_no: !!getValues("esic_no") ? getValues("esic_no") : null,
      uan_no: !!getValues("uan_no") ? getValues("uan_no") : null,
      employee_dob: !!getValues("employee_dob")
        ? getValues("employee_dob")
        : null,
      project_id: !!getValues("project_id") ? getValues("project_id") : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pageindex, pagesize]);
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllProjectAction({
      organization_id: null,
    });
    GetAllDepartmentAction({
      organization_id: null,
    });
    Clear();
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  //console.log(total_size);

  return (
    <EmployeeListview
      department_list={department_list}
      employee_type_list={employee_type_list}
      project_list={project_list}
      employee_list={employee_list}
      filterData={filterData}
      Clear={Clear}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
      searchByNameEmpAction={searchByNameEmpAction}
      searchEmployeeList={searchEmployeeList}
      getValues={getValues}
      accessToken={accessToken}
      dataPayload={dataPayload}
      resetpasswordrequestAction={resetpasswordrequestAction}
      user={user}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    employee_type_list: state.organization.employee_type_list,
    project_list: state.customer.project_list,
    department_list: state.organization.department_list,
    employee_list: state.employee.employee_list,
    total_size: state.employee.employee_count,
    searchEmployeeList: state.timingSheet.employeeList,
    accessToken: state.user.userDetails?.accessToken,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  GetAllDepartmentAction,
  getAllEmployeeAction,
  SidenavAutoCloseOpen,
  searchByNameEmpAction,
  resetpasswordrequestAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(AllEmployee);
interface AllEmployeeProps {
  employee_type_list: EmployeeTypeList[];
  project_list: ProjectList[];
  department_list: AllDepartmentList[];
  employee_list: EmpList[];
  GetAllEmployeeTypeAction?: any;
  GetAllProjectAction?: any;
  GetAllDepartmentAction?: any;
  getAllEmployeeAction?: any;
  total_size?: number;
  SidenavAutoCloseOpen?: any;
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  accessToken?: string;
  resetpasswordrequestAction?: any;
  user: userDetails | undefined;
}
