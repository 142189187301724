import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StateList } from "../../../models/customerModel";
import { bankList } from "../../../models/employeeModel";
import {
  OrganizationALlList,
  ServiceOfferList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { GetAllStateListAction } from "../../../stores/actions/customerAction";
import { GetAllBankListAction } from "../../../stores/actions/employeeAction";
import {
  CreateOrganizationAction,
  EditOrganizationAction,
  GetAllOrganizationAction,
  GetAllServiceOfferedAction,
} from "../../../stores/actions/organizationActions";
import AddNewOrganization from "./AddNewOrganization";

function AddOrganization({
  CreateOrganizationAction,
  GetAllServiceOfferedAction,
  GetAllOrganizationAction,
  service_list,
  organization_list,
  EditOrganizationAction,
  GetAllBankListAction,
  bank_list,
  state_list,
  GetAllStateListAction
}: AddOrganizationProps) {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const SubmitUpdatedData = (data: any) => {
    EditOrganizationAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const { id } = useParams();
  const Submit = (data: any) => {
    
    
    CreateOrganizationAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  React.useEffect(() => {
    GetAllServiceOfferedAction();
    GetAllOrganizationAction();
    GetAllBankListAction();
    GetAllStateListAction();
  }, []);
  return (
    <Grid>
      <AddNewOrganization
        Submit={Submit}
        service_list={service_list}
        organization_list={organization_list}
        bank_list={bank_list}
        organization={organization_list.find(
          (m) => !!id && m.organization_id == +id
        )}
        SubmitUpdatedData={SubmitUpdatedData}
        state_list={state_list}
      />
    </Grid>
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    service_list: state.organization.service_list,
    bank_list: state.employee.bank_list,
    state_list: state.customer.state_list
  };
};
const mapDispatchToProps = {
  CreateOrganizationAction,
  GetAllServiceOfferedAction,
  GetAllOrganizationAction,
  EditOrganizationAction,
  GetAllBankListAction,
  GetAllStateListAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);

interface AddOrganizationProps {
  CreateOrganizationAction?: any;
  GetAllServiceOfferedAction?: any;
  service_list: ServiceOfferList[];
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  EditOrganizationAction?: any;
  bank_list: bankList[];
  GetAllBankListAction: any;
  state_list: StateList[];
  GetAllStateListAction?: any
}
