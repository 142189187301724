import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import {
  CustomerList,
  ProjectList,
  ViewProject,
} from "../../../models/customerModel";
import { ApplicableEmployeeList } from "../../../models/payrollModel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import { environment } from "../../../environment";
function RegisterPayrollView({
  organization_list,
  employee_type_list,
  project_list,
  onSubmit,
  ProjectSelect,
  project_detail,
  ApplicableEmployeeList,
  approvalList,
  SetapprovalList,
  CreateBatch,
  PayrollAvailableEmployeeSuccessAction,
}: RegisterPayrollViewProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const [empType, setempType] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [rows, setRows] = useState<ApplicableEmployeeList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const styles = {
    marginRight: "50px",
  };
  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation",
    "Project",
    "Location",
    "Present Days",
    "Action",
  ];
  const submit = (data: any) => {
    data.year = moment(data.month).format("YYYY").toString();
    data.month = +moment(data.month).format("MM");
    setExpanded(false);
    onSubmit(data);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!ApplicableEmployeeList
      ? ApplicableEmployeeList.filter((row: ApplicableEmployeeList) => {
        return (
          row?.employee_type_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_id
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.project_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.present_days
            ?.toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!ApplicableEmployeeList) {
      cancelSearch();
    }
  }, [ApplicableEmployeeList]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const SelectRow = (ev: boolean, row: ApplicableEmployeeList) => {
    if (ev) {
      SetapprovalList([...approvalList, row]);
      if ([...approvalList, row].length == ApplicableEmployeeList.length) {
        Set_Avpprove(true);
      }
    } else {
      let det: ApplicableEmployeeList[] = JSON.parse(
        JSON.stringify(approvalList)
      );
      det = det.filter((m) => m.employee_id != row.employee_id);
      SetapprovalList(det);
      Set_Avpprove(false);
    }
  };
  const allSelected = (ev: boolean) => {
    Set_Avpprove(ev);
    if (ev) {
      SetapprovalList(ApplicableEmployeeList);
    } else {
      SetapprovalList([]);
    }
  };
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  useEffect(() => {
    if (submit_flag == true) {
      setChecked1(true);
      setChecked2(true);
      setChecked3(true);
    }
  }, [submit_flag]);
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Dialog
            open={submit_flag}
            onClose={() => setsubmitFlag(false)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  backgroundColor: "#0060FF",
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#fff",
                  textAlign: "center",
                  borderRadius: 3,
                }}
              >
                Calculation Required
              </DialogContentText>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 5, marginBottom: 10 }}
                direction={"row"}
              >
                <Grid item xs={4} container direction={"row"}>
                  <Checkbox
                    checked={checked1}
                    onChange={
                      (event) => setChecked1(event.target.checked)
                      // !event.target.checked && setValue("pfFlag", null)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography
                    style={{
                      paddingLeft: 5,
                      paddingTop: 10,
                      // color: "#A2A2A2",
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    PF
                  </Typography>
                </Grid>
                <Grid item xs={4} container direction={"row"}>
                  <Checkbox
                    checked={checked2}
                    onChange={
                      (event) => setChecked2(event.target.checked)
                      // !event.target.checked && setValue("esicFlag", null)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography
                    style={{
                      paddingLeft: 5,
                      paddingTop: 10,
                      // color: "#A2A2A2",
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    ESIC
                  </Typography>
                </Grid>
                <Grid item xs={4} container direction={"row"}>
                  <Checkbox
                    checked={checked3}
                    onChange={
                      (event) => setChecked3(event.target.checked)
                      // !event.target.checked && setValue("ptaxFlag", null)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography
                    style={{
                      paddingLeft: 5,
                      paddingTop: 10,
                      // color: "#A2A2A2",
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    P.Tax
                  </Typography>
                </Grid>
              </Grid>
              <DialogContentText
                sx={{
                  fontWeight: "600",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Are you sure you want Create this batch?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setsubmitFlag(false)}>
                No
              </Button>
              <Button
                autoFocus
                onClick={() => (
                  !!getValues("month") &&
                  CreateBatch({
                    employeeAttendanceId: approvalList.map(
                      (m) => m.employee_attendance_id
                    ),
                    month: +moment(getValues("month")).format("MM"),
                    year: +moment(getValues("month"))
                      .format("YYYY")
                      .toString(),
                    projectId: approvalList[0].project_id,
                    pfFlag: checked1 == true ? 1 : 0,
                    esicFlag: checked2 == true ? 1 : 0,
                    ptaxFlag: checked3 == true ? 1 : 0,
                  }),
                  setsubmitFlag(false),
                  SetapprovalList([])
                )}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Organization
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"organizationId"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={organization_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"organization_id"}
                            label={"organization_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => (
                              onChange(value),
                              PayrollAvailableEmployeeSuccessAction([]),
                              SetapprovalList([])
                            )}
                            placeholder={"Search Organization"}
                            value={!!value ? value : ""}
                            errors={!!errors["organizationId"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!!watch("organizationId") && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list.filter(
                                (t) =>
                                  t.org_id == +watch("organizationId") &&
                                  (empType.length <= 0 ||
                                    !!empType.includes(t.employee_type_id))
                              )}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value),
                                setValue("projectId", null),
                                setValue("divisionId", null),
                                PayrollAvailableEmployeeSuccessAction([]),
                                SetapprovalList([])
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                              errors={!!errors["employee_type_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!!watch("employee_type_id") &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Project Name
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"projectId"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={project_list}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"project_id"}
                                label={"project_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value),
                                  ProjectSelect(value),
                                  PayrollAvailableEmployeeSuccessAction([]),
                                  SetapprovalList([])
                                )}
                                placeholder={"Search Project"}
                                value={!!value ? value : ""}
                                errors={!!errors["projectId"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {/* {!!watch("employee_type_id") &&
                  +watch("employee_type_id") != 12 &&
                  !!watch("projectId") &&
                  !!project_detail?.division &&
                  project_detail?.division.length > 0 && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Division Name
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"divisionId"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={
                                  !!project_detail?.division
                                    ? project_detail?.division
                                    : []
                                }
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"division_id"}
                                label={"division_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value),
                                  PayrollAvailableEmployeeSuccessAction([]),
                                  SetapprovalList([])
                                )}
                                placeholder={"Search Division"}
                                value={!!value ? value : ""}
                                errors={!!errors["divisionId"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            value={value}
                            InputProps={{
                              onBlur: onBlur,
                              size: "small",
                              style: {
                                width: "125%",
                              },
                            }}
                            onChange={(newValue) => (
                              onChange(newValue),
                              PayrollAvailableEmployeeSuccessAction([]),
                              SetapprovalList([])
                            )}
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(submit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
            <TextField
              size="small"
              style={{ width: "80%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction={"row"}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-root:hover": {
                      // backgroundColor: "#73c6ff",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="justify" key={""}>
                        <Checkbox
                          style={{ color: "#ffff" }}
                          checked={all_approve}
                          onChange={(event) =>
                            allSelected(event.target.checked)
                          }
                        />
                      </StyledTableCell>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows
                        // .slice(
                        //   page * rowsPerPage,
                        //   page * rowsPerPage + rowsPerPage
                        // )
                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.employee_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              <Checkbox
                                color="primary"
                                checked={
                                  !!approvalList.find(
                                    (m) => m.employee_id == row.employee_id
                                  )
                                }
                                onChange={(event) =>
                                  SelectRow(event.target.checked, row)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[1]}>
                              {row.employee_id}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[2]}>
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {row.designation_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[4]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[5]}>
                              {row.level1_name !== null
                                ? row.level2_name !== null
                                  ? row.level3_name !== null
                                    ? row.level4_name !== null
                                      ? row.level5_name !== null
                                        ? row.level1_name +
                                        ">" +
                                        row.level2_name +
                                        ">" +
                                        row.level3_name +
                                        ">" +
                                        row.level4_name +
                                        ">" +
                                        row.level5_name
                                        : row.level1_name +
                                        ">" +
                                        row.level2_name +
                                        ">" +
                                        row.level3_name +
                                        ">" +
                                        row.level4_name
                                      : row.level1_name +
                                      ">" +
                                      row.level2_name +
                                      ">" +
                                      row.level3_name
                                    : row.level1_name + ">" + row.level2_name
                                  : row.level1_name
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[6]}>
                              {row.present_days}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[7]}>
                              <Tooltip
                                title="View"
                                onClick={() =>
                                  navigate(
                                    `/employment/manage-profile/${row.employee_id}`
                                  )
                                }
                              >
                                <IconButton>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={8}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
              marginRight: 10,
            }}
            disabled={approvalList.length <= 0}
            onClick={() => setsubmitFlag(true)}
            variant="contained"
            color="primary"
            size="large"
          >
            Create Batch
          </Button>
        </Grid>
      </Paper>
    </>
  );
}

export default RegisterPayrollView;

interface RegisterPayrollViewProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  project_list: ProjectList[];
  onSubmit?: any;
  ProjectSelect?: any;
  project_detail?: ViewProject;
  ApplicableEmployeeList: ApplicableEmployeeList[];
  SetapprovalList?: any;
  approvalList: ApplicableEmployeeList[];
  CreateBatch?: any;
  PayrollAvailableEmployeeSuccessAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
