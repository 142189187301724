import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { StateList } from "../../../models/customerModel";

const StateAutoComplete: FC<IStateAutoComplete> = ({
  selectedState,
  setSelectedState,
  stateList,
}): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (watch("applicable_states")) {
      //selectOrganization(getValues("applicable_states"));
      const applicable_states = getValues("applicable_states");
      setSelectedState(applicable_states.join(","));
    }
  }, [watch("applicable_states")]);

  const stateNames = stateList.map((self) => self.state_name);

  return (
    <Box
      sx={{
        width: "25%",
        mx: 5,
        mt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "gray",
      }}
    >
      <Typography style={{
        color: "#132437",
        fontWeight: 600,
        fontFamily: "Alef, sans-serif"
      }}>Filter By State : </Typography>
      <Controller
        control={control}
        name={"applicable_states"}
        defaultValue={[]}
        rules={{ required: true }}
        render={({ field: { onBlur, value, onChange } }) => (
          <Autocomplete
            multiple
            id="tags-standard"
            options={!!stateNames ? stateNames : []}
            style={{ width: "100.9%" }}
            getOptionLabel={(option) => option}
            onChange={(evemt, value) =>
              onChange(!!value ? value.map((self) => self) : [])
            }
            value={
              !!value ? stateNames.filter((self) => value.includes(self)) : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id="standard-required"
                className={"smaller"}
                variant="outlined"
                placeholder="Applicable States"
                onBlur={onBlur}
                size="small"
              />
            )}
          />
        )}
      />
    </Box>
  );
};

interface IStateAutoComplete {
  selectedState: string;
  setSelectedState: any;
  stateList: StateList[];
}
export default StateAutoComplete;
