import { FC, useState, useEffect } from "react";
import PayrollReportsView from "./PayrollReportsView";
import { useForm } from "react-hook-form";
import { StoreState } from "../../../models/reduxModels";
import {
  GetPayrollReportAction,
  PFESICReportListsAction,
} from "../../../stores/actions/reportsAction";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  SalaryStructuregetAll,
} from "../../../stores/actions/organizationActions";
import { connect } from "react-redux";
import { ProjectList } from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import {
  IPayrollReportDetails,
  ITotalPayrollSal,
} from "../../../models/reportsModel";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";

const PayrollReports: FC<IPayrollReports> = ({
  organization_list,
  employee_type_list,
  project_list,
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  GetAllOrganizationAction,
  payroll_list,
  GetPayrollReportAction,
  totalPayrollData,
  total_size,
  totalPayrollListForPDF,
  totalSalCountDataForPDF,
  searchByNameEmpAction,
  employeeList,
  SalaryStructuregetAll,
  component_list,
}): JSX.Element => {
  const [openReport, setOpenReport] = useState<boolean>(false);
  useEffect(() => {
    SalaryStructuregetAll();
  }, []);

  return (
    <PayrollReportsView
      project_list={project_list}
      employee_type_list={employee_type_list}
      organization_list={organization_list}
      GetAllEmployeeTypeAction={GetAllEmployeeTypeAction}
      GetAllProjectAction={GetAllProjectAction}
      GetAllOrganizationAction={GetAllOrganizationAction}
      setOpenReport={setOpenReport}
      openReport={openReport}
      GetPayrollReportAction={GetPayrollReportAction}
      payroll_list={payroll_list}
      totalPayrollData={totalPayrollData}
      total_size={total_size}
      totalPayrollListForPDF={totalPayrollListForPDF}
      totalSalCountDataForPDF={totalSalCountDataForPDF}
      employeeList={employeeList}
      searchByNameEmpAction={searchByNameEmpAction}
      SalaryStructuregetAll={SalaryStructuregetAll}
      component_list={component_list}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    project_list: state.customer.project_list,
    total_size: state.reports.count,
    employee_type_list: state.organization.employee_type_list,
    organization_list: state.organization.organization_filter_list,
    payroll_list: state.reports.payrollReport,
    totalPayrollData: state.reports.totalSalCount,
    totalPayrollListForPDF: state.reports.reportForPDF,
    totalSalCountDataForPDF: state.reports.totalSalCountForPDF,
    employeeList: state.timingSheet.employeeList,
    component_list: state.organization.component_list,
  };
};

const mapDispatchToProps = {
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  GetPayrollReportAction,
  searchByNameEmpAction,
  SalaryStructuregetAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReports);

interface IPayrollReports {
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
  GetAllProjectAction?: any;
  GetAllEmployeeTypeAction?: any;
  GetPayrollReportAction?: any;
  payroll_list: IPayrollReportDetails[];
  totalPayrollData?: ITotalPayrollSal;
  total_size?: number;
  totalPayrollListForPDF: IPayrollReportDetails[];
  totalSalCountDataForPDF?: ITotalPayrollSal;
  searchByNameEmpAction?: any;
  employeeList: IEmployeeList[];
  SalaryStructuregetAll?: any;
  component_list: SalaryStuctureComponent[];
}
