import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectLevels, StateList } from "../../../models/customerModel";
import {
  bankList,
  DesignationNameWithDetail,
  DocumentsDetail,
  EducationDetail1,
  EmpList,
  EmployeeAllDetail,
  EmployeeAttendanceReport,
  EmployeeProjectHistory,
  ExperienceDetail1,
  PermanentAddress,
  PresentAddress,
  ProfessionalDetail1,
  ReportingManagerDetail,
  SalaryDetail1,
  SalaryStructureHistory,
} from "../../../models/employeeModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { GetAllProjectLevelsAction, GetAllStateListAction } from "../../../stores/actions/customerAction";
import { EditEmployeeDashboardAction, GetAllBankListAction, ReportingManagerEditAction, getAllEmployeeAction, getEmployeeAllDetailsAction } from "../../../stores/actions/employeeAction";
import ManageProfileView from "./ManageProfile";
import { resetPasswordAction } from "../../../stores/actions/userAction";
import {
  getLeaveAmountAction,
  getLeaveDetailsForDashboardAction,
} from "../../../stores/actions/LeaveAction";
import {
  ILeaveAmountData,
  ILeaveApplicationDetailsDashboard,
  ILeaveTableData,
} from "../../../models/leaveModel";
function ManageProfile({
  getEmployeeAllDetailsAction,
  employeeDetails,
  present_address,
  permanent_address,
  documents_details,
  education_details,
  experience_details,
  user,
  ProjectAssociationWithLevelName,
  designation_name_with_details,
  EmployeeAttendanceReport,
  SalaryDetail,
  SalaryHistory,
  accessToken,
  resetPasswordAction,
  EditEmployeeDashboardAction,
  GetAllBankListAction,
  bank_list,
  getLeaveAmountAction,
  leaveAmountData,
  leaveDetailsForDashboard,
  getLeaveDetailsForDashboardAction,
  state_list,
  GetAllStateListAction,
  professional_details,
  reporting_manager_details,
  getAllEmployeeAction,
  employee_list,
  ReportingManagerEditAction
}: ManageProfileProps) {
  const { id } = useParams();
  const [isReportingManagerChange, setIsReportingManagerChange] = useState<boolean>(false);
  React.useEffect(() => {
    if (!!id && +id === 0) {
      getEmployeeAllDetailsAction({ employee_id: user?.employeeId });
    } else {
      getEmployeeAllDetailsAction({ employee_id: id });
    }
    GetAllBankListAction();
    GetAllStateListAction();
    getAllEmployeeAction()
  }, []);

  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const Submit = (data: any) => {
    EditEmployeeDashboardAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
    //console.log("data",data);
    setTimeout(() => {
      if (!!id && +id === 0) {
        getEmployeeAllDetailsAction({ employee_id: user?.employeeId });
      } else {
        getEmployeeAllDetailsAction({ employee_id: id });
      }
    }, 1000);
  };
  const onReportingManagerSubmit = (data: any) => {
    let payload: any = {
      ...data,
      employee_id: !!id && +id === 0 ? user?.employeeId : id
    }
    ReportingManagerEditAction({
      data: payload,
      enqueueSnackbar: enqueueSnackbar,
    })
    setIsReportingManagerChange(false)
    setTimeout(() => {
      if (!!id && +id === 0) {
        getEmployeeAllDetailsAction({ employee_id: user?.employeeId });
      } else {
        getEmployeeAllDetailsAction({ employee_id: id });
      }
    }, 1000);
  }
  return (
    <ManageProfileView
      documents_details={documents_details}
      present_address={present_address}
      permanent_address={permanent_address}
      employeeDetails={employeeDetails}
      education_details={education_details}
      experience_details={experience_details}
      ProjectAssociationWithLevelName={ProjectAssociationWithLevelName}
      designation_name_with_details={designation_name_with_details}
      EmployeeAttendanceReport={EmployeeAttendanceReport}
      SalaryDetail={SalaryDetail}
      user={user}
      Submit={Submit}
      SalaryHistory={SalaryHistory}
      accessToken={accessToken}
      resetPasswordAction={resetPasswordAction}
      bank_list={bank_list}
      getLeaveAmountAction={getLeaveAmountAction}
      leaveAmountData={leaveAmountData}
      leaveDetailsForDashboard={leaveDetailsForDashboard}
      getLeaveDetailsForDashboardAction={getLeaveDetailsForDashboardAction}
      empId={id}
      state_list={state_list}
      professional_details={professional_details}
      reporting_manager_details={reporting_manager_details}
      employee_list={employee_list}
      onReportingManagerSubmit={onReportingManagerSubmit}
      setIsReportingManagerChange={setIsReportingManagerChange}
      isReportingManagerChange={isReportingManagerChange}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    employeeDetails: state.employee.employeeDetails,
    present_address: state.employee.present_address,
    permanent_address: state.employee.permanent_address,
    documents_details: state.employee.documents_details,
    education_details: state.employee.education_details,
    experience_details: state.employee.experience_details,
    ProjectAssociationWithLevelName:
      state.employee.ProjectAssociationWithLevelName,
    project_levels: state.customer.project_levels,
    designation_name_with_details: state.employee.designation_name_with_details,
    EmployeeAttendanceReport: state.employee.EmployeeAttendanceReport,
    SalaryDetail: state.employee.SalaryDetail,
    user: state.user.userDetails,
    SalaryHistory: state.employee.SalaryHistory,
    accessToken: state.user.userDetails?.accessToken,
    bank_list: state.employee.bank_list,
    leaveAmountData: state.leave.leave_amount_data,
    leaveDetailsForDashboard: state.leave.leaveDetails,
    state_list: state.customer.state_list,
    professional_details: state.employee.professional_details,
    reporting_manager_details: state.employee.reporting_manager_details,
    employee_list: state.employee.employee_list,
  };
};
const mapDispatchToProps = {
  getEmployeeAllDetailsAction,
  resetPasswordAction,
  EditEmployeeDashboardAction,
  GetAllBankListAction,
  getLeaveAmountAction,
  getLeaveDetailsForDashboardAction,
  GetAllStateListAction,
  getAllEmployeeAction,
  ReportingManagerEditAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProfile);

interface ManageProfileProps {
  getEmployeeAllDetailsAction?: any;
  employeeDetails?: EmployeeAllDetail;
  present_address?: PresentAddress;
  permanent_address?: PermanentAddress;
  documents_details: DocumentsDetail[];
  education_details: EducationDetail1[];
  experience_details: ExperienceDetail1[];
  ProjectAssociationWithLevelName: EmployeeProjectHistory[];
  designation_name_with_details: DesignationNameWithDetail[];
  EmployeeAttendanceReport: EmployeeAttendanceReport[];
  SalaryDetail?: SalaryDetail1[];
  user?: userDetails;
  SalaryHistory: SalaryStructureHistory[];
  accessToken?: string;
  resetPasswordAction?: any;
  EditEmployeeDashboardAction?: any;
  GetAllBankListAction: any;
  bank_list: bankList[];
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  getLeaveDetailsForDashboardAction?: any;
  leaveDetailsForDashboard: ILeaveApplicationDetailsDashboard[];
  state_list: StateList[];
  GetAllStateListAction: any;
  professional_details: ProfessionalDetail1[];
  reporting_manager_details: ReportingManagerDetail[];
  getAllEmployeeAction?: any
  employee_list: EmpList[];
  ReportingManagerEditAction?: any
}
