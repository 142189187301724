import Cookies from "universal-cookie";
import {
  ConsolidatedMonthlyReportProjectwiseService,
  PFESICReportListsService,
  PayrollReportService,
} from "../../services/reportsService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
import { IPayrollReportPayload } from "../../models/reportsModel";

export enum ReportsActionsTypes {
  PF_ESIC_Report_Lists_Success_Action = "[REPORTS] PF ESIC Report Lists Success Action",
  PAYROLL_REPORT_LIST_SUCCESS_ACTION = "[REPORTS] Payroll Report List Success Action",
  PAYROLL_REPORT_LIST_FOR_PDF_SUCCESS_ACTION = "[REPORTS] Payroll Report List For Pdf Success Action",
  Consolidated_Monthly_Report_Projectwise_SUCCESS_ACTION = "[REPORTS] Consolidated Monthly Report Projectwise Success Action",
}

export const PFESICReportListsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "PF ESIC REPORT LISTS. Please Wait...",
        type: 2,
      })
    );
    return PFESICReportListsService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          console.log(response.data);

          dispatch(
            PFESICReportListsSuccessAction({
              list: response.data.list,
              count: response.data.count,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const PFESICReportListsSuccessAction = (data: any) => {
  return {
    type: ReportsActionsTypes.PF_ESIC_Report_Lists_Success_Action,
    payload: data,
  };
};

// payroll data action
export const GetPayrollReportAction =
  (payload: IPayrollReportPayload) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "PF ESIC REPORT LISTS. Please Wait...",
        type: 2,
      })
    );
    return PayrollReportService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          if (payload.isPDF) {
            dispatch({
              type: ReportsActionsTypes.PAYROLL_REPORT_LIST_FOR_PDF_SUCCESS_ACTION,
              payload: {
                reportForPDF: response.data.salaryReportData,
                totalSalCountForPDF: response.data.totalSalCount,
              },
            });
          } else {
            dispatch({
              type: ReportsActionsTypes.PAYROLL_REPORT_LIST_SUCCESS_ACTION,
              payload: {
                salaryReportData: response.data.salaryReportData,
                totalSalCount: response.data.totalSalCount,
                count: response.data.count,
              },
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
// Consolidated Monthly Report Projectwise

export const ConsolidatedMonthlyReportProjectwiseAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Consolidated Monthly Report Projectwise. Please Wait...",
        type: 2,
      })
    );
    return ConsolidatedMonthlyReportProjectwiseService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            ConsolidatedMonthlyReportProjectwiseSuccessAction(response.data)
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ConsolidatedMonthlyReportProjectwiseSuccessAction = (data: any) => {
  return {
    type: ReportsActionsTypes.Consolidated_Monthly_Report_Projectwise_SUCCESS_ACTION,
    payload: data,
  };
};