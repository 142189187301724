import { Grid, Typography, TextField, Divider, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PfDetails = ({ control, errors, edit_candidate,edit_flag,user,id2}: PfDetailsProps) => {
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "7%",
                // marginBottom: 20,
              }}
            >
              PF & ESIC Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      // textAlign: "center"
                    }}
                  >
                    UAN No.
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"uan_no"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      minLength: 12,
                      maxLength: 12,
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "78.9%",
                          marginLeft: "26.5%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                        }}
                        value={value}
                        placeholder={"UAN No."}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

                // width={"100%"}
              >
                <Grid
                  item
                  xs={3}
                  // justifyContent="space-between"
                  // alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    ESIC No.
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"esic_no"}
                    defaultValue={""}
                    rules={{ required: false, minLength: 17, maxLength: 17 }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "89.0%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                        }}
                        value={value}
                        placeholder={"ESIC No."}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default PfDetails;
interface PfDetailsProps {
  errors?: any;
  control?: any;
  edit_candidate: boolean;
  edit_flag?: boolean;
  user?: userDetails;
  id2?: number;
}
