import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../../library/AutoComplete";
import { ILevelMaster } from "../../../../interfaces/projectDashboard.interface";
import { LevelTree, LevelsList } from "../../../../models/customerModel";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditLevels: FC<IEditLevels> = ({
  open,
  setOpen,
  levelTree,
  levelMaster,
  getLevelDetailsAction,
  createLevelAction,
  editablePayload,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  console.log("errors : ", errors);

  const onSubmit = (props: any) => {
    console.log("propos submit : ", props);
    const payload = {
      formData: {
        level_key_id: !!levelMaster ? +levelMaster?.level_key_id : 0,
        level1_id: props.level1_id ? props.level1_id : null,
        level2_id: props.level2_id ? props.level2_id : null,
        level3_id: props.level3_id ? props.level3_id : null,
        level4_id: props.level4_id ? props.level4_id : null,
        level5_id: props.level5_id ? props.level5_id : null,
        level1_name: props.level1_name ? props.level1_name : null,
        level2_name: props.level2_name ? props.level2_name : null,
        level3_name: props.level3_name ? props.level3_name : null,
        level4_name: props.level4_name ? props.level4_name : null,
        level5_name: props.level5_name ? props.level5_name : null,
      },
      enqueueSnackbar,
    };
    createLevelAction(payload);
    setOpen(false);
  };
  useEffect(() => {
    if (!!editablePayload) {
      setValue("level1_name", editablePayload.level1_name);
      setValue("level1_id", editablePayload.level1_id);
      setValue("level2_id", editablePayload.level2_id);
      setValue("level2_name", editablePayload.level2_name);
      setValue("level3_id", editablePayload.level3_id);
      setValue("level3_name", editablePayload.level3_name);
      setValue("level4_id", editablePayload.level4_id);
      setValue("level4_name", editablePayload.level4_name);
      setValue("level5_id", editablePayload.level5_id);
      setValue("level5_name", editablePayload.level5_name);
    }
  }, [editablePayload]);
  useEffect(() => {
    if (!!open && !!levelMaster) {
      getLevelDetailsAction(Number(levelMaster.level_key_id));
      console.log("getLevelDetailsAction fired");
    }
  }, [open, levelMaster]);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={style}>
          <Box
            sx={{
              p: 1,
              background: "#0060FF",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="white"
            >
              Add Levels
            </Typography>
            <IconButton sx={{ color: "#fff" }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={5} justifyContent="space-between" alignItems="start">
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "20%",
                  // textAlign: "center"
                }}
              >
                {!!levelMaster && levelMaster.level1_key_name}
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid
              item
              xs={5.5}
              style={{ marginLeft: -5, position: "relative" }}
            >
              <Controller
                control={control}
                name={"level1_name"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "100.9%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    placeholder={"Level 1"}
                    error={!!errors.level_name}
                  />
                )}
              />
            </Grid>
          </Grid>
          {!!levelMaster &&
          !!levelMaster.level2_key_name &&
          levelMaster.level2_key_name.length > 0 ? (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  {levelMaster.level2_key_name}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                style={{ marginLeft: -5, position: "relative" }}
              >
                <Controller
                  control={control}
                  name={"level2_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Level 2"}
                      error={!!errors.level2_name}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {!!levelMaster &&
          !!levelMaster.level3_key_name &&
          levelMaster.level3_key_name.length > 0 ? (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{
                marginTop: 15,
                marginBottom: 10,
                position: "relative",
              }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  {levelMaster.level3_key_name}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                style={{ marginLeft: -5, position: "relative" }}
              >
                <Controller
                  control={control}
                  name={"level3_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Level 3"}
                      error={!!errors.level3_name}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {!!levelMaster &&
          !!levelMaster.level4_key_name &&
          levelMaster.level4_key_name.length > 0 ? (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  {levelMaster.level4_key_name}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                style={{ marginLeft: -5, position: "relative" }}
              >
                <Controller
                  control={control}
                  name={"level4_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Level 4"}
                      error={!!errors.level4_name}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {!!levelMaster &&
          !!levelMaster.level5_key_name &&
          levelMaster.level5_key_name.length > 0 ? (
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  {levelMaster.level5_key_name}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                style={{ marginLeft: -5, position: "relative" }}
              >
                <Controller
                  control={control}
                  name={"level5_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Level 5"}
                      error={!!errors.level5_name}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            container
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid
              item
              xs={10.5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                sx={{ ml: 8 }}
              >
                Close
              </Button>
              <Button type="submit" variant="outlined">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

interface IEditLevels {
  open: boolean;
  setOpen: any;
  levelTree?: LevelTree;
  levelMaster?: ILevelMaster;
  createLevelAction?: any;
  getLevelDetailsAction?: any;
  editablePayload?: LevelsList | null;
}

export default EditLevels;
