import {
  Paper,
  Grid,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
  styled,
  TableCell,
  TablePagination,
  Link,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { BatchByDeg } from "../../../models/payrollModel";
import { useEffect, useState } from "react";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function PendingApprovalView({ BatchByDeg }: PendingApprovalViewProps) {
  let navigateTo = useNavigate();
  const styles = {
    marginRight: "50px",
  };
  const [rows, setRows] = useState<BatchByDeg[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const column1: any[] = [
    "Batch ID",
    "Project",
    "Month",
    "Total Employee",
    "Total Income",
    "Total Deduction",
    "Total Contribution",
    "Net Payable",
    "Created By",
    "Created On",
    "Action",
  ];
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!BatchByDeg
      ? BatchByDeg.filter((row: BatchByDeg) => {
          return (
            row?.batch_id?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.division_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.created_by
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.batch_status_name
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!BatchByDeg) {
      cancelSearch();
    }
  }, [BatchByDeg]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <Paper
      elevation={3}
      sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
    >
      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.batch_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.batch_id}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.project_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {!!row?.month &&
                              moment(
                                `${row.month}-01-${row.year} 00:00:00`
                              ).format("MMMM/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[3]}>
                            {row.emp_count}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.total_income}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[5]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#b32d00",
                              }}
                            >
                              &#8377; {row.total_deduction}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[6]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                              }}
                            >
                              &#8377; {row.total_contribution}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[7]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.net_payable}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[8]}>
                            <Link
                              onClick={() =>
                                navigateTo(
                                  `/employment/manage-profile/${row.created_by}`
                                )
                              }
                            >
                              {row.created_by}
                            </Link>
                            <br/>
                          ({row.initiated_employee_name})
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[9]}>
                            {!!row.created_on
                              ? moment(row.created_on).format(
                                  "DD/MM/YYYY HH:mm:ss a"
                                )
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[10]}>
                            <Tooltip title="View">
                              <IconButton
                                onClick={() =>
                                  navigateTo(
                                    `/payroll/batch-detail/${row.batch_id}/2`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PendingApprovalView;
interface PendingApprovalViewProps {
  BatchByDeg: BatchByDeg[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
