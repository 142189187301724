import React from "react";
import {
  CustomerList,
  ProjectList,
  ViewProject,
} from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { BatchList } from "../../../models/payrollModel";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllCustomerAction,
  GetAllProjectAction,
  GetProjectViewAction,
} from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import { GetBatchListAction } from "../../../stores/actions/payrollActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import CancelledBatchView from "./CancelledBatchView";

function CancelledBatch({
  organization_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  customer_list,
  GetAllCustomerAction,
  project_list,
  GetAllProjectAction,
  GetBatchListAction,
  GetProjectViewAction,
  project_detail,
  batch_list,
  SidenavAutoCloseOpen,
}: CancelledBatchProps) {
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
    GetAllCustomerAction({
      organization_id: null,
    });
    GetAllProjectAction({
      organization_id: null,
    });
    GetBatchListAction({
      projectId: null,
      divisionId: null,
      batchId: null,
      batchStatus: 7,
      month: null,
      year: null,
    });
    SidenavAutoCloseOpen(true);
  }, []);
  const ProjectSelect = (data: number) => {
    if (!!data) {
      GetProjectViewAction(+data);
    }
  };
  const filter = (data: any) => {
    GetBatchListAction({
      projectId: !!data.projectId ? data.projectId : null,
      divisionId: !!data.divisionId ? data.divisionId : null,
      batchId: !!data.batchId ? data.batchId : null,
      batchStatus: 7,
      month: !!data.month ? data.month : null,
      year: !!data.year ? data.year : null,
    });
  };
  const CancleFilter = () => {
    GetBatchListAction({
      projectId: null,
      divisionId: null,
      batchId: null,
      batchStatus: 4,
      month: null,
      year: null,
    });
  };
  return (
    <CancelledBatchView
      employee_type_list={employee_type_list}
      organization_list={organization_list}
      customer_list={customer_list}
      project_list={project_list}
      ProjectSelect={ProjectSelect}
      project_detail={project_detail}
      batch_list={batch_list}
      Sudmit={filter}
      CancleFilter={CancleFilter}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_type_list: state.organization.employee_type_list,
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    project_detail: state.customer.project_detail,
    batch_list: state.payroll.batch_list,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllEmployeeTypeAction,
  GetAllCustomerAction,
  GetAllProjectAction,
  GetBatchListAction,
  GetProjectViewAction,
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelledBatch);

interface CancelledBatchProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  customer_list: CustomerList[];
  project_list: ProjectList[];
  GetAllOrganizationAction?: any;
  GetAllEmployeeTypeAction?: any;
  GetAllCustomerAction?: any;
  GetAllProjectAction?: any;
  SidenavAutoCloseOpen?: any;
  GetBatchListAction?: any;
  GetProjectViewAction?: any;
  project_detail?: ViewProject;
  batch_list: BatchList[];
}
