import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { EmployeeAllDetail } from "../../../models/employeeModel";

function EmployeeEditPfEsicDetailspopover({
  anchorEl2,
  setAnchorEl2,
  employeeDetails,
  SubmitPopData,
}: EmployeeEditPfEsicDetailspopoverProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const open = Boolean(anchorEl2);
  const handleClose = () => {
    setAnchorEl2(null);
  };
  useEffect(() => {
    if (!!employeeDetails) {
      setValue("uan_no", employeeDetails.uan_no);
      setValue("esic_no", employeeDetails.esic_no);
    }
  }, [employeeDetails]);
  const onSubmit = (data: any) => {
    handleClose();
    SubmitPopData(data);
  };
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>
              {" "}
              PF & ESIC Details
            </Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            >
              <Grid item xs={5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  UAN No.
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"uan_no"}
                  defaultValue={""}
                  rules={{ required: false , minLength: 12, maxLength: 12}}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      onChange={onChange}
                      value={value}
                      placeholder={"UAN No."}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            >
              <Grid
                item
                xs={5}
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  ESIC No.
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"esic_no"}
                  defaultValue={""}
                  rules={{ required: false , minLength: 17, maxLength: 17}}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 17);
                      }}
                      value={value}
                      placeholder={"ESIC No."}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default EmployeeEditPfEsicDetailspopover;

interface EmployeeEditPfEsicDetailspopoverProps {
  anchorEl2?: any;
  setAnchorEl2?: any;
  employeeDetails?: EmployeeAllDetail;
  SubmitPopData?: any;
}
