import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  NativeSelect,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  AllAvailableProjectForClosure,
  AllAvailableProjectForClosureList,
  CustomerList,
  ProjectList,
} from "../../../models/customerModel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function InitiateProjectClosureView({
  control,
  errors,
  getValues,
  handleSubmit,
  setValue,
  watch,
  ClearAllFilter,
  FilterSubmit,
  project_list,
  customer_list,
  GetAllProjectAction,
  ProjectClosureAction,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
  all_available_projectList_for_closure,
}: InitiateProjectClosureViewProps) {
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [prjClosurePayload, SetprjclosurePayload] = useState<any>();
  const [openModal, setopenModal] = useState<boolean>(false);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const styles = {
    marginRight: "50px",
  };
  const onSubmit = (data: any) => {
    FilterSubmit(data);
  };
  const ClearFilter = () => {
    ClearAllFilter();
  };
  useEffect(() => {
    GetAllProjectAction({
      organization_id: null,
      customer_id: !!getValues("customer_id") ? getValues("customer_id") : null,
    });
  }, [watch("customer_id")]);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<AllAvailableProjectForClosureList[]>([]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!all_available_projectList_for_closure
      ? all_available_projectList_for_closure.data.filter(
          (row: AllAvailableProjectForClosureList) => {
            return (
              row?.project_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              row?.customer_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          }
        )
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const column1: any[] = [
    "Project Name",
    "Customer Name",
    "Created On",
    "Action",
  ];
  useEffect(() => {
    if (!!all_available_projectList_for_closure) {
      cancelSearch();
    }
  }, [all_available_projectList_for_closure]);
  useEffect(() => {
    if (!!all_available_projectList_for_closure) {
      setRows(all_available_projectList_for_closure.data);
    }
  }, [all_available_projectList_for_closure]);
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setopenModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Project Closure"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close this Project permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setopenModal(false)}>
            No
          </Button>
          <Button
            onClick={() => (
              ProjectClosureAction(prjClosurePayload), setopenModal(false)
            )}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  // backgroundColor: "#0060FF",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{padding:0, margin:0}}>
              <form>
                <Grid container justifyContent={"flex-start"} mt={1.5}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Customer Name
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"customer_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={customer_list}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"customer_id"}
                              label={"customer_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), setValue("project_id", null)
                              )}
                              placeholder={"Search Customer Name"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Project Name
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"project_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={project_list}
                              // option={[]}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"project_id"}
                              label={"project_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Project Name"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    padding={3}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={ClearFilter}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper
        elevation={5}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, paddingTop: 2 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.project_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.project_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.customer_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {moment(row.created_on).format("DD-MM-YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            <Tooltip
                              title="Closure"
                              onClick={() => (
                                setopenModal(true),
                                SetprjclosurePayload(row.project_id)
                              )}
                            >
                              <IconButton>
                                <CancelIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              <Grid container alignItems={"center"} justifyContent="end">
                <Grid xs={2} textAlign="right">
                  Rows per page:
                </Grid>
                <Grid xs={1} textAlign="center">
                  <NativeSelect
                    value={pagesize.toString()}
                    onChange={(event) => SetpageSize(+event.target.value)}
                  >
                    {rowperpage.map((p) => (
                      <option
                        key={p}
                        value={p}
                        onChange={(value: any) => value(p)}
                      >
                        {p}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    onClick={() => Setpageindex(pageindex - 1)}
                    color="primary"
                    size="small"
                    disabled={pageindex <= 0}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <Typography>
                    {pageindex * pagesize + 1} -{" "}
                    {!!total_size && total_size <= (pageindex + 1) * pagesize
                      ? total_size
                      : (pageindex + 1) * pagesize}
                    {" of "}
                    {total_size}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => Setpageindex(pageindex + 1)}
                    disabled={
                      (!!total_size &&
                        total_size <= (pageindex + 1) * pagesize) ||
                      total_size === 0
                    }
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default InitiateProjectClosureView;
interface InitiateProjectClosureViewProps {
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  watch?: any;
  FilterSubmit?: any;
  ClearAllFilter?: any;
  project_list: ProjectList[];
  customer_list: CustomerList[];
  GetAllProjectAction?: any;
  ProjectClosureAction?: any;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  all_available_projectList_for_closure?: AllAvailableProjectForClosure;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
