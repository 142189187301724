import {
  Grid,
  Checkbox,
  Typography,
  ButtonGroup,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSnackbar } from "notistack";
import { SalaryStructureEditDetails } from "../../../models/organizationModels";
import { Controller } from "react-hook-form";

function OffDays({
  m,
  off_days,
  setOff_days,
  getValues,
  setValue,
  watch,
  edit_flag,
  salary_structure,
}: OffDaysProps) {
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // const ReduceCount = () => {
  //   if (+getValues(m.key) < 1) {
  //     enqueueSnackbar("You can not select less than 0", {
  //       variant: "warning",
  //       anchorOrigin: {
  //         vertical: "top",
  //         horizontal: "right",
  //       },
  //     });
  //   } else {
  //     setValue(m.key, +getValues(m.key) - 1);
  //   }
  // };
  useEffect(() => {
    setValue(m.key, null);
    if (!!salary_structure && !!m?.key) {
      let actualvalue =
        m?.key == "sun_day"
          ? salary_structure.sun_day
          : m?.key == "mon_day"
          ? salary_structure.mon_day
          : m?.key == "tues_day"
          ? salary_structure.tues_day
          : m?.key == "wednes_day"
          ? salary_structure.wednes_day
          : m?.key == "thurs_day"
          ? salary_structure.thurs_day
          : m?.key == "fri_day"
          ? salary_structure.fri_day
          : salary_structure.satur_day;
      if (!!actualvalue) {
        setValue(m.key, actualvalue);
      }
      setChecked(getValues(m.key) != null);
    }
  }, [salary_structure]);
  // const AddCount = () => {
  //   if (+getValues(m.key) >= 5) {
  //     enqueueSnackbar("You can not select more than 5", {
  //       variant: "warning",
  //       anchorOrigin: {
  //         vertical: "top",
  //         horizontal: "right",
  //       },
  //     });
  //   } else {
  //     setValue(m.key, +getValues(m.key) + 1);
  //   }
  // };
  //   console.log(getValues(m.key));
  const offDayWeek: any[] = [
    { key: "All", value: 0 },
    { key: "First", value: 1 },
    { key: "Second", value: 2 },
    { key: "Third", value: 3 },
    { key: "Fourth", value: 4 },
    { key: "Fifth", value: 5 },
  ];
  return (
    <Grid
      container
      direction={"row"}
      key={m.key.toString()}
      py={0.5}
      alignItems={"center"}
      justifyContent="space-between"
    >
      <Grid item xs={3} container direction={"row"}>
        <Checkbox
          disabled={edit_flag}
          checked={checked}
          onChange={(event) => {
            if (event.target.checked == true) {
              setValue(m.key, []);
            } else {
              setValue(m.key, null);
            }
            setChecked(event.target.checked);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          pt={1.2}
          style={{
            color: "#A2A2A2",
            fontWeight: "500",
            fontSize: 15,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {m.value}
        </Typography>
      </Grid>

      {getValues(m.key) != null && (
        <Grid item xs={9} container direction={"row"}>
          {/* <ButtonGroup>
            <Button
              size="small"
              aria-label="reduce"
              sx={{ height: 35 }}
              onClick={() => {
                ReduceCount();
              }}
              disabled={edit_flag}
            >
              <RemoveIcon fontSize="small" />
            </Button>
            <TextField
              size="small"
              type={"text"}
              id="standard-required"
              variant="outlined"
              className={"smaller"}
              color={"secondary"}
              sx={{ width: 70 }}
              inputProps={{
                readOnly: true,
              }}
              value={!watch(m.key) ? "ALL" : watch(m.key)}
            ></TextField>
            <Button
              size="small"
              aria-label="increase"
              sx={{ height: 35 }}
              onClick={() => {
                AddCount();
              }}
              disabled={edit_flag}
            >
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup> */}

          <Autocomplete
            multiple
            id="tags-standard"
            options={offDayWeek}
            getOptionLabel={(option) => option.key}
            // readOnly={!!project ? edit_flag : !edit_flag}
            style={{ width: "100%" }}
            onChange={(evemt, value) =>
              // onChange(!!value ? value.map((m) => m.post_id) : [])
              setValue(
                m.key,
                value.map((m) => m.value).filter((b) => !!b)
              )
            }
            value={
              !!watch(m.key)
                ? watch(m.key).length == 0
                  ? [{ key: "All", value: 0 }]
                  : offDayWeek.filter((n) => watch(m.key).includes(n.value))
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id="standard-required"
                className={"smaller"}
                variant="outlined"
                placeholder="Off Days"
                size="small"
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default OffDays;

interface OffDaysProps {
  m: any;
  off_days: [];
  setOff_days?: any;
  setValue?: any;
  getValues?: any;
  watch?: any;
  edit_flag?: any;
  salary_structure?: SalaryStructureEditDetails;
}
