import { Grid, Typography, TextField, Divider, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import { bankList } from "../../../models/employeeModel";
import { userDetails } from "../../../models/userModels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BankingDetail = ({ control, errors ,edit_candidate,
  bank_list,edit_flag,user,id2}: BankingDetailProps) => {
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "7%",
                // marginBottom: 20,
              }}
            >
              Banking Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

                // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      // textAlign: "center"
                    }}
                  >
                    Bank Name.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"bank_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={bank_list}
                        keys={"bank_name"}
                        label={"bank_name"}
                        style={{
                          width: "78.9%",
                          marginLeft: "26.5%",
                        }}
                        onBlur={onBlur}
                        readOnly={
                          !!id2 && +id2 == 1
                            ? false
                            : !!user &&
                              environment.global_veriable.superAdminLevel.includes(
                                user.designation
                              )
                            ? edit_flag
                            : !edit_candidate
                        }
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Bank Name"}
                        value={!!value ? value : ""}
                        errors={errors.bank_name}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    Branch Name.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"branch_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "89.0%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                          maxLength: 40,
                        }}
                        value={value}
                        placeholder={"Branch Name"}
                        error={!!errors["branch_name"]}
                        helperText={
                          errors.branch_name &&
                          `${errors.branch_name.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

                // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      // textAlign: "center"
                    }}
                  >
                    A/c No.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"account_no"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "78.9%",
                          marginLeft: "26.5%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                        }}
                        value={value}
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 15);
                        }}
                        placeholder={"Account No"}
                        error={!!errors["account_no"]}
                        helperText={
                          errors.account_no &&
                          `${errors.account_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                // width={"100%"}
              >
                <Grid
                  item
                  xs={3}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    IFSC Code
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"ifsc_no"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "89.0%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        inputProps={{
                          readOnly:
                            !!id2 && +id2 == 1
                              ? false
                              : !!user &&
                                environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                )
                              ? edit_flag
                              : !edit_candidate,
                          maxLength: 11,
                        }}
                        value={value}
                        placeholder={"IFSC Code"}
                        error={!!errors["ifsc_no"]}
                        helperText={
                          errors.ifsc_no &&
                          `${errors.ifsc_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default BankingDetail;

interface BankingDetailProps {
  errors?: any;
  control?: any;
  edit_candidate: boolean;
  bank_list: bankList[];
  edit_flag?: boolean;
  user?: userDetails;
  id2?: number;
}
