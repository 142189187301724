import { baseUrl, environment } from "../environment";
import {
  AttandanceDetailPayload,
  AttendanceApprovalPayload,
  AttendanceDashboardPayload,
  AttendanceTimeSheetCalculationPayload,
  DeleteEmployeeAttendanceBatchPayload,
  PendingApprovalListPayload,
  UpdateAttendaceStatusPayload,
  UpdateAttendancePayload,
  UploadAttendanceTimeSheetPayload,
  uploadAttendanceViewForVariable,
} from "../models/attendanceModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const AttendanceDashboardService = (
  data: AttendanceDashboardPayload
) => {
  return serviceClient.post(environment.urls.attendanceDashboard, data);
};
export const PendingApprovalListService = (
  data: PendingApprovalListPayload
) => {
  return serviceClient.post(environment.urls.pendingAprovalList, data);
};
export const UploadedAttendanceViewService = (
  data: AttandanceDetailPayload
) => {
  // console.log(data, "fom service");

  return serviceClient.post(environment.urls.uploadedattendanceexcelview, data);
};
export const UpdateAttendanceService = (data: UpdateAttendancePayload) => {
  return serviceClient.post(environment.urls.updateAttendance, data);
};
export const AttendanceApprovalListService = (
  data: AttendanceApprovalPayload
) => {
  return serviceClient.post(environment.urls.attendanceApproveList, data);
};
export const UpdateAttendanceApprovalStatusService = (
  data: UpdateAttendaceStatusPayload
) => {
  return serviceClient.post(environment.urls.updateAttendanceApproval, data);
};
export const DeleteEmployeeFromAttendanceBatchService = (
  data: DeleteEmployeeAttendanceBatchPayload
) => {
  return serviceClient.post(
    environment.urls.deleteEmployeeAttendanceFromBatch,
    data
  );
};
export const AttendanceTimeSheetListService = (
  data: AttendanceTimeSheetCalculationPayload
) => {
  return serviceClient.post(environment.urls.attendanceTimeSheet, data);
};
export const UploadAttendanceTimeSheetService = (
  data: UploadAttendanceTimeSheetPayload
) => {
  return serviceClient.post(environment.urls.uploadAttendanceTimeSheet, data);
};
