import { stat } from 'fs';
import React from 'react'
import { connect } from 'react-redux';
import { CustomerList, ProjectList } from '../../../models/customerModel';
import { StoreState } from '../../../models/reduxModels';
import { GetAllCustomerAction, GetAllProjectAction, GetProjectViewAction } from '../../../stores/actions/customerAction';
import ProjectMainView from './ProjectMainView'
import { userDetails } from '../../../models/userModels';

function ProjectMain({
  project_list,
  customer_list,
  GetAllProjectAction,
  GetAllCustomerAction,
  user
}:ProjectMainProps) {
  const filterData = (props: any) => {
    GetAllProjectAction({
      customer_id:
      !!props.customer_id && props.customer_id != ""
        ? props.customer_id
        : null,
        organization_id: null
    });
  console.log(props);
  };
  const Clear = () => {
    GetAllProjectAction({ organization_id: null,customer_id: null });
  };
  React.useEffect(() => {
    GetAllProjectAction({ organization_id: null,customer_id: null })
    GetAllCustomerAction()
  }, []);
  return (
    <ProjectMainView
    project_list={project_list}
    customer_list={customer_list}
    filterData={filterData}
    Clear={Clear}
    user={user}
    />
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    user: state.user.userDetails,
  };
};
const mapDispatchToProps = {
  GetAllProjectAction,
  GetAllCustomerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMain);
interface ProjectMainProps {
  project_list: ProjectList[];
  GetAllProjectAction?: any;
  GetAllCustomerAction?: any;
  customer_list ?: CustomerList[];
  user?: userDetails;
}