import React, { useEffect, useState } from "react";
import EmployeeListForTerminationView from "./EmployeeListForTerminationView";
import { useForm } from "react-hook-form";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import {
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
} from "../../../stores/actions/organizationActions";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import {
  getAllEmployeeAction,
  getEmployeeAllDetailsAction,
} from "../../../stores/actions/employeeAction";
import { ProjectList } from "../../../models/customerModel";
import {
  AllDesignationList,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import { EmpList } from "../../../models/employeeModel";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";
import {
  EmployeeListAvailableForTerminationAction,
  InitiateTerminationAction,
} from "../../../stores/actions/terminationAction";
import { EmployeeListAvailableForTermination } from "../../../models/terminationModel";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";

function EmployeeListForTermination({
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  getEmployeeAllDetailsAction,
  designation_list,
  employee_list,
  employee_type_list,
  project_list,
  searchByNameEmpAction,
  searchEmployeeList,
  EmployeeListAvailableForTerminationAction,
  emp_available_for_termination,
  getAllEmployeeAction,
  total_size,
  InitiateTerminationAction,
  user
}: EmployeeListForTerminationProps) {
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  useEffect(() => {
    GetAllDesignationAction({ organization_id: null });
    GetAllProjectAction({ organization_id: null, customer_id: null });
    GetAllEmployeeTypeAction({ organization_id: null });
  }, []);
  useEffect(() => {
    EmployeeListAvailableForTerminationAction({
      employee_id: "",
      designation_id: "",
      employee_type_id: "",
      project_id: "",
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pagesize, pageindex]);

  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const FilterSubmit = (props: any) => {
    EmployeeListAvailableForTerminationAction({
      employee_id: !!props.employee_id ? props.employee_id : "",
      designation_id: !!props.designation_id ? props.designation_id : "",
      employee_type_id: !!props.employee_type_id ? props.employee_type_id : "",
      project_id: !!props.project_id ? props.project_id : "",
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const ClearAllFilter = () => {
    EmployeeListAvailableForTerminationAction({
      employee_id: "",
      designation_id: "",
      employee_type_id: "",
      project_id: "",
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const initiatePayload = (data: any) => {
    InitiateTerminationAction({
      data: data,
      enqueueSnackbar: enqueueSnackbar,
      list_data: {
        employee_id: "",
        designation_id: "",
        employee_type_id: "",
        project_id: "",
        page_size: pagesize,
        page_index: pageindex,
      },
    });
  };

  return (
    <EmployeeListForTerminationView
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      getEmployeeAllDetailsAction={getEmployeeAllDetailsAction}
      employee_list={employee_list}
      designation_list={designation_list}
      project_list={project_list}
      employee_type_list={employee_type_list}
      searchByNameEmpAction={searchByNameEmpAction}
      searchEmployeeList={searchEmployeeList}
      FilterSubmit={FilterSubmit}
      ClearAllFilter={ClearAllFilter}
      emp_available_for_termination={emp_available_for_termination}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      initiatePayload={initiatePayload}
      user={user}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    searchEmployeeList: state.timingSheet.employeeList,
    designation_list: state.organization.designation_list,
    project_list: state.customer.project_list,
    employee_type_list: state.organization.employee_type_list,
    employee_list: state.employee.employee_list,
    total_size: state.termination.emp_available_for_termination?.count,
    emp_available_for_termination:
      state.termination.emp_available_for_termination,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  searchByNameEmpAction,
  GetAllDesignationAction,
  getEmployeeAllDetailsAction,
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  getAllEmployeeAction,
  EmployeeListAvailableForTerminationAction,
  InitiateTerminationAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeListForTermination);

interface EmployeeListForTerminationProps {
  GetAllProjectAction?: any;
  GetAllDesignationAction?: any;
  GetAllEmployeeTypeAction?: any;
  getEmployeeAllDetailsAction?: any;
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  employee_list: EmpList[];
  designation_list: AllDesignationList[];
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  EmployeeListAvailableForTerminationAction?: any;
  emp_available_for_termination?: EmployeeListAvailableForTermination;
  getAllEmployeeAction?: any;
  total_size?: number;
  InitiateTerminationAction?: any;
  user?: userDetails;
}
