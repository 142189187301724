import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  NativeSelect,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { border } from "@mui/system";
import EmployeeProjectDetailsView from "./EmployeeProjectDetailsView";

import {
  bankList,
  DesignationNameWithDetail,
  DocumentsDetail,
  EducationDetail1,
  EmpList,
  EmployeeAllDetail,
  EmployeeAttendanceReport,
  EmployeeProjectHistory,
  ExperienceDetail1,
  PermanentAddress,
  PresentAddress,
  ProfessionalDetail1,
  ReportingManagerDetail,
  SalaryDetail1,
  SalaryStructureHistory,
} from "../../../models/employeeModel";
import EmployeeAttendanceDetails from "./EmployeeAttendanceDetails";
import EmployeeSalaryDetails from "./EmployeeSalaryDetails";
import { red } from "@mui/material/colors";
import { ProjectLevels, StateList } from "../../../models/customerModel";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import { userDetails } from "../../../models/userModels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmployeeBankDetails from "./EmployeeBankDetails";
import EmployeeExperienceDetails from "./EmployeeExperienceDetails";
import EmployeeEducationalDetails from "./EmployeeEducationalDetails";
import EmployeeSalaryStructureDetails from "./EmployeeSalaryStructureDetails";
import Autocompletes from "../../../library/AutoComplete";
import ImageUploader from "../AddEmployee/ImageUploader";
import EmployeeEditBasicDetailsPopover from "./EmployeeEditBasicDetailsPopover";
import ChangePasswordModal from "./ChangePasswordModal";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import { baseUrl, environment } from "../../../environment";
import { CreateCandidateSuccessAction } from "../../../stores/actions/employeeAction";
import { useSnackbar } from "notistack";
import EmployeeBasicDetailsView from "./EmployeeBasicDetailsView";
import EmployeeAddressDetailsView from "./EmployeeAddressDetailsView";
import EmployeeEditPfEsicDetailspopover from "./EmployeeEditPfEsicDetailspopover";
import {
  ILeaveAmountData,
  ILeaveApplicationDetailsDashboard,
  ILeaveTableData,
} from "../../../models/leaveModel";
import ApplicableLeave from "../../Leave/LeaveApplication/ApplicableLeave";
import LeaveRecord from "./LeaveRecord";
import EmergencyContactInformation from "./EmergencyContactInformation";
import EmployeeProfessionalDetails from "./EmployeeProfessionalDetails";

function ManageProfileView({
  employeeDetails,
  present_address,
  permanent_address,
  documents_details,
  education_details,
  experience_details,
  ProjectAssociationWithLevelName,
  designation_name_with_details,
  EmployeeAttendanceReport,
  SalaryDetail,
  user,
  SalaryHistory,
  accessToken,
  Submit,
  resetPasswordAction,
  bank_list,
  getLeaveAmountAction,
  leaveAmountData,
  getLeaveDetailsForDashboardAction,
  leaveDetailsForDashboard,
  empId,
  state_list,
  professional_details,
  reporting_manager_details,
  employee_list,
  onReportingManagerSubmit,
  isReportingManagerChange,
  setIsReportingManagerChange
}: ManageProfileViewProps) {
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [changeDocAadhar, setchangeDocAadhar] = useState<boolean>(false);
  const [changeDocPan, setchangeDocPan] = useState<boolean>(false);
  const [changeDocVotar, setchangeDocVotar] = useState<boolean>(false);
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const onSubmit = (data: any) => {
    let payload: any = {
      // ...data,
      basicDetails: {
        employee_id: employeeDetails?.employee_id,
        employee_name: data?.employee_name,
        father_name: data?.father_name,
        employee_email: data?.employee_email,
        employee_mob_no: data?.employee_mob_no,
        employee_marital_status: data?.employee_marital_status,
        religion: data?.religion,
        base_location: data?.base_location,
        blood_group: data?.blood_group,
        // emergency_contact: data?.emergency_contact,
        // relation: data?.relation
      },
      documents_details: [],
    };
    if (!!data.aadhar_no && data.aadhar_no != "") {
      payload.documents_details.push(
        {
          document_id: documents_details.find((m) => m.document_type === "AADHAR")
            ?.document_id,
          document_type: "AADHAR",
          document_no: data.aadhar_no,
          document_image: "",
        });
    }
    if (!!data.epic_no && data.epic_no != "") {
      payload.documents_details.push({
        document_id: documents_details.find((m) => m.document_type === "VOTAR")
          ?.document_id,
        document_type: "VOTAR",
        document_no: data.epic_no,
        document_image: "",
      });
    }
    if (!!data.pan_no && data.pan_no != "") {
      payload.documents_details.push({
        document_id: documents_details.find((m) => m.document_type === "PAN")
          ?.document_id,
        document_type: "PAN",
        document_no: data.pan_no,
        document_image: "",
      });
    }
    payload.documents_details.forEach((m: any) => {
      m.document_image = !!documents.find(
        (n) => m.document_type == n.document_type
      )
        ? documents.find((n) => m.document_type == n.document_type)
          .document_image
        : "";
    });
    // delete payload.epic_no;
    // delete payload.pan_no;
    if (
      payload.documents_details.find((m: any) => m.document_type == "AADHAR").length <= 0 ||
      payload.documents_details.find((m: any) => m.document_type == "AADHAR")
        ?.document_image == ""
    ) {
      enqueueSnackbar("Aadhar document is mandatory", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      Submit(payload);
    }
    // Submit(payload);
    // console.log(payload);
  };
  const onSubmit2 = (data: any) => {
    // console.log("asd", data);
    let payload: any = {
      // ...data,
      pf_esic: {
        employee_id: employeeDetails?.employee_id,
        uan_no: data?.uan_no,
        esic_no: data?.esic_no,
      },
    };
    Submit(payload);
  };
  const onSubmit3 = (data: any) => {
    let payload: any = {
      // ...data,
      basicDetails: {
        employee_id: employeeDetails?.employee_id,
        emergency_contact: data?.emergency_contact,
        relation: data?.relation
      },
    };
    Submit(payload);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const [dateForLeave, setDateForLeave] = useState<any>(new Date());
  const [option, Setoption] = useState<any[]>([]);
  const [documents, SetDocument] = useState<any[]>([]);


  useEffect(() => {
    FilterData();
  }, [EmployeeAttendanceReport]);
  useEffect(() => {
    FilterData();
  }, [selectedDate]);

  const FilterData = () => {
    if (!!EmployeeAttendanceReport && EmployeeAttendanceReport.length > 0) {
      let data = EmployeeAttendanceReport.filter(
        (m) =>
          new Date(m.month).getFullYear() ==
          new Date(selectedDate).getFullYear()
      ).map((self) => ({
        name: moment(self?.month).format("MMM,YYYY"),
        "Present (Days)": self.present_days,
        "Absent (Days)": self.absent_days,
        "Extra Duty (Days)": self.extra_duty_days,
      }));
      Setoption(data);
    }
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!employeeDetails && designation_name_with_details) {
      getLeaveAmountAction({
        employee_id: employeeDetails.employee_id,
        year: new Date().getFullYear(),
      });
    } else if (!!user) {
      getLeaveAmountAction({
        year: new Date().getFullYear(),
        employee_id: user?.employee_detail.employee_id,
      });
    }
  }, [user, employeeDetails, designation_name_with_details]);

  useEffect(() => {
    console.log("selectedDate = " + new Date(dateForLeave).getFullYear());
    if (dateForLeave)
      if (empId === "0" && !!user) {
        getLeaveDetailsForDashboardAction(
          user.employee_detail.employee_id,
          new Date(dateForLeave).getFullYear()
        );
      } else if (empId) {
        getLeaveDetailsForDashboardAction(
          empId,
          new Date(dateForLeave).getFullYear()
        );
      }
  }, [user, empId]);

  const handleDateForLeave = (newValue: any) => {
    setDateForLeave(newValue);
    console.log("year for leave", new Date(newValue).getFullYear());
    if (empId === "0" && !!user) {
      console.log("service hit 1");
      getLeaveAmountAction({
        employee_id: user?.employee_detail.employee_id,
        year: new Date(newValue).getFullYear(),
      });
    } else if (empId && employeeDetails) {
      console.log("service hit 2");
      getLeaveAmountAction({
        employee_id: empId,
        year: new Date(newValue).getFullYear(),
      });
    }
  };
  const handleModalClose = () => {
    setIsReportingManagerChange(false);
  };
  return (
    <Grid container direction={"column"} width={"100%"} marginBottom={20}>
      <ChangePasswordModal
        isModalOpen={passwordModalOpen}
        setIsModalOpen={setPasswordModalOpen}
        resetPasswordAction={resetPasswordAction}
        user={user}
      />
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-evenly"}
      //sx={{ position: "relative", width: "100%" }}
      >
        <Grid item xs={4}>
          <Grid container justifyContent={"start"} direction={"row"}>
            <Grid item xs={1}>
              <IconButton onClick={() => navigate(-1)} sx={{ ml: -5, mt: -5 }}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  ml: -3,
                  //mb: 10,
                }}
              >
                Basic Details
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Edit">
                <IconButton onClick={handleClick}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <EmployeeEditBasicDetailsPopover
                user={user}
                documents={documents}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                employeeDetails={employeeDetails}
                documents_details={documents_details}
                // fileHandleEvent={fileHandleEvent}
                SetDocument={SetDocument}
                // downloadDoc={downloadDoc}
                SubmitPopData={onSubmit}
                state_list={state_list}
                setchangeDocAadhar={setchangeDocAadhar}
                setchangeDocPan={setchangeDocPan}
                setchangeDocVotar={setchangeDocVotar}
              />
            </Grid>
          </Grid>
          <EmployeeBasicDetailsView
            employeeDetails={employeeDetails}
            documents_details={documents_details}
            user={user}
          />
          <EmergencyContactInformation
            employeeDetails={employeeDetails}
            onSubmit3={onSubmit3}
          />
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <EmployeeAddressDetailsView
            present_address={present_address}
            permanent_address={permanent_address}
            Submit={Submit}
            state_list={state_list}
          />
          <EmployeeBankDetails
            employeeDetails={employeeDetails}
            bank_list={bank_list}
            Submit={Submit}
          />
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <Accordion
            elevation={0}
            disableGutters
            sx={{
              "&:before": {
                display: "",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid
                container
                justifyContent={"start"}
                //alignItems={""}
                direction={"row"}
              //paddingTop={1}
              >
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      color: "#626974",
                      //mb: 10,
                    }}
                  >
                    PF & ESIC Details
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                padding={0}
              >
                <Tooltip title="Edit">
                  <IconButton onClick={handleClick2}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <EmployeeEditPfEsicDetailspopover
                  anchorEl2={anchorEl2}
                  setAnchorEl2={setAnchorEl2}
                  employeeDetails={employeeDetails}
                  SubmitPopData={onSubmit2}
                />
              </Grid>
              <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                direction={"column"}
                marginTop={-1}
              >
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  paddingTop={1}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      width: "90%",
                      bgcolor: "#F1F6F5",
                      pt: "1%",
                      pb: "1%",
                      borderRadius: 3,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          pl: "5%",
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                        }}
                      >
                        UAN No.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                      >
                        {!!employeeDetails?.uan_no
                          ? employeeDetails?.uan_no
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  paddingTop={1}
                  paddingBottom={2}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      width: "90%",
                      bgcolor: "#F1F6F5",
                      pt: "1%",
                      pb: "1%",
                      borderRadius: 3,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          pl: "5%",
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                        }}
                      >
                        ESIC No.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                      >
                        {!!employeeDetails?.esic_no
                          ? employeeDetails?.esic_no
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <EmployeeEducationalDetails
            education_details={education_details}
            Submit={Submit}
            employeeDetails={employeeDetails}
          />
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <EmployeeProfessionalDetails
            professional_details={professional_details}
            Submit={Submit}
            employeeDetails={employeeDetails}
          />
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <EmployeeExperienceDetails
            experience_details={experience_details}
            Submit={Submit}
            employeeDetails={employeeDetails}
          />
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Grid item xs={7.5}>
          {(!documents_details.find((m) => m.document_type === "AADHAR")
            ?.document_image ||
            !documents_details.find((m) => m.document_type === "VOTAR")
              ?.document_image ||
            !documents_details.find((m) => m.document_type === "PAN")
              ?.document_image) && (
              <Alert severity="error" sx={{ position: "initial", width: "100%" }}>
                Please complete all information
              </Alert>
            )}
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  //mb: 10,
                }}
              >
                Designation Details
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {/* <Tooltip
                title="Edit"
              // onClick={() =>
              //   navigate(
              //     `/customer/add-project/${row.project_id}/${row.customer_id}`
              //   )
              // }
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
          </Grid>
          {!!designation_name_with_details &&
            designation_name_with_details.map((row) => (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                key={row.designation_id}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon
                        sx={{
                          color: row.status === 1 ? "#3F979B" : "grey",
                          size: "small",
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={row.designation_name}
                    secondary={
                      moment(row.start_date).format("MMM Do YYYY") +
                      " to " +
                      moment(row.end_date).format("MMM Do YYYY")
                    }
                  />
                </ListItem>
                {!!designation_name_with_details &&
                  designation_name_with_details.length > 1 && (
                    <Divider variant="inset" component="li" />
                  )}
              </List>
            ))}
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          {!!reporting_manager_details && reporting_manager_details.length > 0 &&
            <Grid
              container
              justifyContent={"start"}
              //alignItems={""}
              direction={"row"}
              paddingTop={1}
            >
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: 18,
                    fontFamily: "Poppins, sans-serif",
                    textAlign: "left",
                    color: "#626974",
                    //mb: 10,
                  }}
                >
                  Reporting Manager
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {
          !!user &&
          environment.global_veriable.superAdminLevel.includes(
            user.designation
          ) && (
                <Tooltip
                  title="Edit"
                  onClick={() => {
                    setIsReportingManagerChange(true)
                  }
                  }
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                )}
              </Grid>
            </Grid>
          }
          {!!reporting_manager_details && reporting_manager_details.length > 0 &&
            reporting_manager_details.map((row) => (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                key={row.employee_id}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon
                        sx={{
                          color: "#3F979B",
                          size: "small",
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={row.employee_name}
                    secondary={""}
                  />

                </ListItem>
                {!!reporting_manager_details &&
                  reporting_manager_details.length > 1 && (
                    <Divider variant="inset" component="li" />
                  )}
              </List>

            ))}
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          {!!ProjectAssociationWithLevelName &&
            ProjectAssociationWithLevelName.length > 0 && (
              <>
                <Grid
                  container
                  justifyContent={"start"}
                  //alignItems={""}
                  direction={"row"}
                  paddingTop={1}
                >
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: 18,
                        fontFamily: "Poppins, sans-serif",
                        textAlign: "left",
                        color: "#626974",
                        //mb: 10,
                      }}
                    >
                      Project Details
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <EmployeeProjectDetailsView
                  ProjectAssociationWithLevelName={
                    ProjectAssociationWithLevelName
                  }
                />
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  flexItem
                  sx={{ mt: 4 }}
                />
              </>
            )}
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                }}
              >
                Attendance Details
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent="end"
            paddingTop={2}
            paddingBottom={1}
          >
            <Grid xs={4} textAlign="center">
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // textAlign: "center"
                }}
              >
                Select Year :
              </Typography>
            </Grid>
            <Grid xs={4} textAlign="center">
              <DatePicker
                views={["year"]}
                value={selectedDate}
                InputProps={{
                  size: "small",
                  sx: {
                    width: "100%",
                  },
                }}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </Grid>
          </Grid>
          <EmployeeAttendanceDetails option={option} />
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            flexItem
            sx={{ pt: 10, mt: 5 }}
          />
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                }}
              >
                Salary Structure Details
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <EmployeeSalaryStructureDetails SalaryHistory={SalaryHistory} />
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            flexItem
          // sx={{ pt: 10, mt: 5 }}
          />
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            paddingTop={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                }}
              >
                Salary Details
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <EmployeeSalaryDetails
            SalaryDetail={SalaryDetail}
            accessToken={accessToken}
          />

          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            paddingTop={1}
          >
            {empId !== "0" &&
              !!employeeDetails &&
              environment.global_veriable.ho_employees_type_id.includes(
                employeeDetails.employee_type_id
              ) ? (
              <>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      color: "#626974",
                    }}
                  >
                    Leave Details
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    pr={2}
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        marginRight: 5,
                        // textAlign: "center"
                      }}
                    >
                      Select Year :
                    </Typography>

                    <DatePicker
                      views={["year"]}
                      value={dateForLeave}
                      InputProps={{
                        size: "small",
                        sx: {
                          width: "100%",
                        },
                      }}
                      onChange={(newValue) => handleDateForLeave(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                  <ApplicableLeave
                    leaveAmountData={leaveAmountData}
                    isDashboard
                  />
                </Grid>
                <LeaveRecord
                  getLeaveDetailsForDashboardAction={
                    getLeaveDetailsForDashboardAction
                  }
                  leaveDetailsForDashboard={leaveAmountData}
                />
              </>
            ) : (
              <></>
            )}
            {!!empId &&
              empId === "0" &&
              !!user &&
              environment.global_veriable.ho_employees_type_id.includes(
                user?.employee_detail?.employee_type_id
              ) ? (
              <>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "left",
                      color: "#626974",
                    }}
                  >
                    Leave Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    pr={2}
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        marginRight: 5,
                        // textAlign: "center"
                      }}
                    >
                      Select Year :
                    </Typography>

                    <DatePicker
                      views={["year"]}
                      value={dateForLeave}
                      InputProps={{
                        size: "small",
                        sx: {
                          width: "100%",
                        },
                      }}
                      onChange={(newValue) => handleDateForLeave(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ApplicableLeave
                    leaveAmountData={leaveAmountData}
                    isDashboard
                  />
                </Grid>
                <LeaveRecord
                  getLeaveDetailsForDashboardAction={
                    getLeaveDetailsForDashboardAction
                  }
                  leaveDetailsForDashboard={leaveAmountData}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
          {/* <Typography
            sx={{
              fontWeight: "700",
              fontSize: 12,
              fontFamily: "Poppins, sans-serif",
              textAlign: "left",
              color: "#626974",
            }}
          >
            <span
              className="hoverMe"
              onClick={() => setPasswordModalOpen(true)}
            >
              Change your password?
            </span>
          </Typography> */}
        </Grid>

        {/* <Grid
          container
          justifyContent={"start"}
          //alignItems={""}
          direction={"row"}
          paddingTop={1}
          mt={15}
        >
          <Grid item xs={4.5}></Grid>
          <Grid item xs={6} mt={1}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: 12,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                color: "#626974",
              }}
            >
              <span
                className="hoverMe"
                onClick={() => setPasswordModalOpen(true)}
              >
                Change your password?
              </span>
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>

      <Modal
        open={isReportingManagerChange}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper
          elevation={2}
          sx={{
            marginY: '20%',
            marginInline: '20%',
            // marginBottom: 10,
            minWidth: "40%",
            minHeight: "20%",
            // position: "absolute",
            // marginLeft: `calc(40% - ${Card}px)`,
          }}
        >
          <Grid
            container
            direction={"column"}
            display={"flex"}
          // justifyContent={"center"}
          //style={{ padding: 1, margin: 2 }}
          >
            <Grid
              container
              style={{
                padding: 10,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Grid item>
                <Typography style={{ color: "#fff" }}>Reporting Manager Details</Typography>
              </Grid>
            </Grid>
            <form>
              <Grid item xs={12} sx={{ padding: 1, paddingBottom: 4 }}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  paddingTop={1}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography style={{ fontWeight: 400 }}>
                      Reporting Manager <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"reporting_manager_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={employee_list || []}
                          keys={"employee_id"}
                          label={"employee_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Reporting Manager"}
                          value={!!value ? value : ""}
                          errors={errors.reporting_manager_id}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  //   marginRight: "2rem",
                  marginRight: "0px",
                  marginTop: "13px",
                  marginBottom: 1,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                  }}
                  onClick={handleSubmit(onReportingManagerSubmit)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </Grid>
        </Paper>
      </Modal>
    </Grid>

  );
}

export default ManageProfileView;

interface ManageProfileViewProps {
  employeeDetails?: EmployeeAllDetail;
  present_address?: PresentAddress;
  permanent_address?: PermanentAddress;
  documents_details: DocumentsDetail[];
  education_details: EducationDetail1[];
  experience_details: ExperienceDetail1[];
  ProjectAssociationWithLevelName: EmployeeProjectHistory[];
  designation_name_with_details: DesignationNameWithDetail[];
  EmployeeAttendanceReport: EmployeeAttendanceReport[];
  SalaryDetail?: SalaryDetail1[];
  user?: userDetails;
  SalaryHistory: SalaryStructureHistory[];
  accessToken?: string;
  Submit?: any;
  resetPasswordAction?: any;
  bank_list: bankList[];
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  getLeaveDetailsForDashboardAction?: any;
  leaveDetailsForDashboard: ILeaveApplicationDetailsDashboard[];
  empId?: string;
  state_list: StateList[];
  professional_details: ProfessionalDetail1[];
  reporting_manager_details: ReportingManagerDetail[];
  employee_list: EmpList[];
  onReportingManagerSubmit?: any
  isReportingManagerChange: boolean
  setIsReportingManagerChange?: any
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 12, sm: 11, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
