import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import {
  IDetails,
  IPayrollData,
} from "../../../interfaces/projectDashboard.interface";
import Organization from "./../../Organization/index";
import moment from "moment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useNavigate } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import { environment } from "../../../environment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { userDetails } from "../../../models/userModels";
const ProjectDetails: FC<IProjectDetails> = ({
  details,
  payrollData,
  onDownloadPDF,
  downloadImage,
  setDocsDialogOpen,
  user,
}) => {
  let navigateTo = useNavigate();

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "auto",
        border: "1px solid #42a5f5",
      }}
    >
      <Box bgcolor="#42a5f5" p={1}>
        <Grid container flexDirection={"row"} justifyContent={"space-between"}>
          <Grid style={{}}></Grid>
          <Typography textAlign={"center"} color="#fff">
            {" "}
            Project Details
          </Typography>
          <Grid style={{}}>
            <Tooltip title="Show Invoice Details">
              <IconButton
                style={{ color: "#fff" }}
                onClick={() =>
                  navigateTo(`/customer/invoice-data/${details?.project_id}`)
                }
              >
                <ReceiptIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Show Payment Details">
              <IconButton
                style={{ color: "#fff" }}
                onClick={() =>
                  navigateTo(
                    `/customer/payment-details-main/${details?.project_id}`
                  )
                }
              >
                <PaymentsIcon />
              </IconButton>
            </Tooltip>
            {!!user &&
              environment.global_veriable.officialEmployee.includes(
                user.designation
              ) && (
                <Tooltip
                  title="Budget Dashboard"
                  onClick={() =>
                    navigateTo(
                      `/customer/budget-dashboard/${details?.project_id}`
                    )
                  }
                >
                  <IconButton>
                    <DashboardIcon style={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              )}
          </Grid>
        </Grid>
      </Box>
      <Box p={2}>
        <Grid container justifyContent="space-between">
          <Grid item md={12} lg={6} justifyContent={"space-between"}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Organization Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.organization_name : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Organization Email
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.organization_email : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Official Contact No.
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.official_contact_no : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Customer Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.customer_name : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Customer Type
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.customer_type : "N/A"}
                </Typography>
              </Grid>
            </Grid> */}

            <Grid container mt={1}>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Project Name
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.project_name : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Project Status
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography
                  style={
                    !!details && details.project_status === "ACTIVE"
                      ? { fontWeight: 800, color: "green" }
                      : { fontWeight: 800, color: "red" }
                  }
                >
                  {!!details ? details.project_status : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Project Code
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.project_code : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Download Project Report
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Tooltip title="Download Project Report">
                  <IconButton onClick={onDownloadPDF}>
                    <SaveAltIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Project Co-Partner
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details?.project_copartner
                    ? details.project_copartner
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} lg={6} justifyContent={"space-between"}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>Start Date</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details
                    ? moment(new Date(details.start_date)).format("DD-MMM-YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>End Date</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {" "}
                  {!!details
                    ? moment(new Date(details.end_date)).format("DD-MMM-YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Total Active Employee
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.no_of_active_employees : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Total In-Active Employee
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.no_of_in_active_employees : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Work Order No.
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details ? details.work_order_no : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  No. Of Manager
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography>
                  {!!details ? details.projectmanager_id.length : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Total Cost <small>( for current year)</small>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!payrollData ? (
                    <span
                      style={{
                        fontFamily: "Arial",
                        fontWeight: "bold",
                        color: "#008060",
                      }}
                    >
                      <CurrencyRupeeIcon sx={{ fontSize: "15px" }} />
                      {payrollData.totalCost.toFixed(2)}{" "}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Work Order Document
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Button onClick={() => setDocsDialogOpen(true)} variant="text">
                  Download Document
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              paddingTop={2}
            >
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  Project Partner
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Typography style={{ fontWeight: 400 }}>
                  {!!details?.project_partner ? details.project_partner : "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
interface IProjectDetails {
  details?: IDetails;
  payrollData?: IPayrollData;
  downloadImage: any;
  onDownloadPDF: any;
  setDocsDialogOpen?: any;
  user?: userDetails;
}
export default ProjectDetails;
