export const DAYS = [
  "sun_day",
  "mon_day",
  "tues_day",
  "wednes_day",
  "thurs_day",
  "fri_day",
  "satur_day",
];

export const DAYSPOSITION: any = {
  sun_day: 0,
  mon_day: 1,
  tues_day: 2,
  wednes_day: 3,
  thurs_day: 4,
  fri_day: 5,
  satur_day: 6,
};

export const isNaturalHoliday = (
  employeeId: string,
  date: any,
  naturalHoliday: any[]
) => {
  try {
    // const naturalHoliday = await this.getNaturalHolidayByEmployee(employeeId, next);
    if (Array.isArray(naturalHoliday) && naturalHoliday.length > 0) {
      const nHolidays = Object.entries(naturalHoliday[0]).filter(
        ([key, value], index: number) => DAYS.includes(key) && value !== null
      );
      const holidays = nHolidays.map((self) => {
        return { [DAYSPOSITION[self[0]]]: self[1] };
      });
      console.log("holidays: ", holidays);
      const isHoliday = holidays.find(
        (self) => Number(Object.keys(self)[0]) === new Date(date).getDay()
      );
      console.log("isHoliday: ", isHoliday);
      // const isHoliday = holidays.find(self => Number(Object(self).keys()[0]) === new Date(date).getDay());
      // console.log('isHoliday : ', isHoliday);
      if (!isHoliday) return false;
      const day = new Date(date).getDate();
      const weekDay = new Date(date).getDay();
      const weekNum = Math.ceil(day / 7);
      const isWeek = Object.values(isHoliday)[0] as any[];
      console.log("isWeek : ", isWeek);
      console.log("weeknum : ", weekNum);
      if (Array.isArray(isWeek) && isWeek.length === 0) return true;
      return isWeek.includes(weekNum);

      // const holidayList = getHolidayOfMonths(startDate, endDate, holidayType);
    }
  } catch (error) {
    console.log("error in natural holiday : ", error);
  }
};
