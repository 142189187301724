import {
  Grid,
  Typography,
  TextField,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import ImageUploader from "./ImageUploader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BasicDetails = ({
  control,
  errors,
  documents,
  fileHandleEvent,
  SetDocument,
}: BasicDetailsProps) => {
  const religion: any[] = [
    {
      key: "Hinduism",
      value: "Hinduism",
    },
    {
      key: "Muslims",
      value: "Muslims",
    },
    {
      key: "Sikhs",
      value: "Sikhs",
    },
    {
      key: "Christians",
      value: "Christians",
    },
    {
      key: "Buddhists",
      value: "Buddhists",
    },
    {
      key: "Zoroastrians",
      value: "Zoroastrians",
    },
  ];
  const BloodGroup: any[] = [
    {
      key: "A+",
      value: "A+",
    },
    {
      key: "A-",
      value: "A-",
    },
    {
      key: "B+",
      value: "B+",
    },
    {
      key: "B-",
      value: "B-",
    },
    {
      key: "O+",
      value: "O+",
    },
    {
      key: "O-",
      value: "O-",
    },
    {
      key: "AB+",
      value: "AB+",
    },
    {
      key: "AB-",
      value: "AB-",
    },
  ];
  const MaritalStatus: any[] = [
    {
      key: "Married",
      value: "Married",
    },
    {
      key: "Widowed",
      value: "Widowed",
    },
    {
      key: "Separated",
      value: "Separated",
    },
    {
      key: "Divorced",
      value: "Divorced",
    },
    {
      key: "Single",
      value: "Single",
    },
  ];
  const genderOption: any[] = [
    {
      key: "Male",
      value: "Male",
    },
    {
      key: "Female",
      value: "Female",
    },
    {
      key: "Other",
      value: "Other",
    },
  ];
  const imageChange = (type: string) => {
    let docs = JSON.parse(JSON.stringify(documents));
    docs = docs.filter((m: any) => m.document_type != type);
    SetDocument(docs);
  };
  const [actAadhar, setactAadhar] = useState("");
  const [actVotar, setactVotar] = useState("");
  const [actPan, setactPan] = useState("");
  return (
    <>
      {/* <Grid
        container
        justifyContent="end"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30, marginLeft: "-4%" }}
      ></Grid> */}
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%"

                // marginBottom: 20,
              }}
            >
              Basic Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}

          // width={"100%"}
          >
            <Grid item xs={2.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "30%",
                  // textAlign: "center"
                  width: "150%"
                }}
              >
                Name of Candidate
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <Controller
                control={control}
                name={"candidate_name"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "91%", marginLeft: "10%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{ maxLength: 100 }}
                    value={value}
                    placeholder={"Name of Candidate"}
                    error={!!errors["candidate_name"]}
                    helperText={
                      errors.candidate_name &&
                      `${errors.candidate_name.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid
              item
              xs={2.5}
            // justifyContent="space-between"
            // alignItems="start"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "30%",
                  // textAlign: "center"
                }}
              >
                Father's Name
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={8.5}>
              <Controller
                control={control}
                name={"father_name"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "91%", marginLeft:"10%" }}
                    onBlur={onBlur}
                    size="small"
                    inputProps={{ maxLength: 100 }}
                    onChange={onChange}
                    value={value}
                    placeholder={"Father's Name"}
                    error={!!errors["father_name"]}
                    helperText={
                      errors.father_name &&
                      `${errors.father_name.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid
                  item
                  xs={3}
                // justifyContent="space-between"
                // alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      // textAlign: "center"
                      width: "150%"
                    }}
                  >
                    Email ID
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    control={control}
                    name={"email_id"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                        message: "Invalid Email ID",
                      },
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"email"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "66%",
                          marginLeft: "53%",
                        }}
                        onBlur={onBlur}
                        inputProps={{ maxLength: 90 }}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Email ID"}
                        error={!!errors["email_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    Mobile No.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"mobile_no"}
                    defaultValue={""}
                    rules={{ required: true, minLength: 10, maxLength: 12 }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "89.0%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        onChange={onChange}
                        value={value}
                        placeholder={"Mobile No"}
                        error={!!errors["mobile_no"]}
                        helperText={
                          errors.mobile_no &&
                          `${errors.mobile_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "37%",
                      width: "150%"
                      // textAlign: "center"
                    }}
                  >
                    Date of Birth
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"date_of_birth"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"date"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        sx={{
                          width: "58%",
                          marginLeft: "34%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        placeholder={"Date of Birth"}
                        error={!!errors["date_of_birth"]}
                        helperText={
                          errors.date_of_birth &&
                          `${errors.date_of_birth.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    Gender
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"gender"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={genderOption}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Gender"}
                        value={!!value ? value : ""}
                        errors={errors.gender}
                        style={{
                          width: "89.0%",
                          marginLeft: "0%",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "37%",
                      width: "150%"
                      // textAlign: "center"
                    }}
                  >
                    Marital Status
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"marital_status"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={MaritalStatus}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Marital Status"}
                        value={!!value ? value : ""}
                        errors={errors.marital_status}
                        style={{
                          width: "58.2%",
                          marginLeft: "32.4%",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    Aadhar
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"aadhar_no"}
                    defaultValue={""}
                    rules={{ required: true, minLength: 12, maxLength: 12 }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "88.7%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        onChange={(newValue) => (
                          onChange(newValue),
                          setactAadhar(newValue.target.value)
                        )}
                        value={value}
                        placeholder={"Aadhar No."}
                        error={!!errors["aadhar_no"]}
                        helperText={
                          errors.aadhar_no &&
                          `${errors.aadhar_no.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!!actAadhar && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 1,
                  width: "92%",
                }}
              >
                <ImageUploader
                  documents={documents}
                  type={"AADHAR"}
                  fileHandleEvent={fileHandleEvent}
                  imageChange={imageChange}
                  showEnd
                />
              </Box>
            )}
          </Grid>

          {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
      >
        <Grid
          item
          xs={5}
          // justifyContent="space-between"
          // alignItems="start"
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            Aadhar Upload
            <span style={{ color: "red", fontSize: 14 }}>*</span>
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"AADHAR"}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
          />
        </Grid>
      </Grid> */}
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          // width={"100%"}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "37%",
                      width: "150%"
                      // textAlign: "center"
                    }}
                  >
                    Blood Group
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"blood_group"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={BloodGroup}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Blood Group"}
                        value={!!value ? value : ""}
                        style={{
                          width: "58%",
                          marginLeft: "34%",
                        }}
                        errors={errors.blood_group}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    PAN
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"pan_no"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                        message: "Invalid PAN No.",
                      },
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "88.2%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        inputProps={{ maxLength: 10 }}
                        size="small"
                        onChange={(newValue) => (
                          onChange(newValue), setactPan(newValue.target.value)
                        )}
                        value={value}
                        placeholder={"PAN No."}
                        error={!!errors["pan_no"]}
                        helperText={errors.pan_no && `${errors.pan_no.message}`}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!!actPan && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "92%",
                  mt: 1,
                }}
              >
                <ImageUploader
                  documents={documents}
                  type={"PAN"}
                  fileHandleEvent={fileHandleEvent}
                  imageChange={imageChange}
                  showEnd
                />
              </Box>
            )}
          </Grid>
          {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
      // width={"100%"}
      >
        <Grid item xs={5}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            EPIC Upload
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"VOTAR"}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
          />
        </Grid>
      </Grid> */}

          {/* <Grid
        container
        justifyContent="start"
        alignItems="center"
        style={{ marginTop: 15, marginBottom: 30 }}
      // width={"100%"}
      >
        <Grid item xs={5}>
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              paddingLeft: "20%",
              // textAlign: "center"
            }}
          >
            PAN Upload
          </Typography>
        </Grid>
        <Grid item xs={5.5}>
          <ImageUploader
            documents={documents}
            type={"PAN"}
            fileHandleEvent={fileHandleEvent}
            imageChange={imageChange}
          />
        </Grid>
      </Grid> */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      // textAlign: "center"
                    }}
                  >
                    Religion
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"religion"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={religion}
                        keys={"key"}
                        label={"value"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Religion"}
                        value={!!value ? value : ""}
                        errors={errors.religion}
                        style={{
                          width: "58%",
                          marginLeft: "45%",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="start" alignItems="center">
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    EPIC
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"epic_no"}
                    defaultValue={""}
                    rules={{
                      required: false,
                      pattern: {
                        value: /^([a-zA-Z]){3}([0-9]){7}?$/,
                        message: "Invalid EPIC No.",
                      },
                    }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "88.2%",
                          marginLeft: "0%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={(newValue) => (
                          onChange(newValue), setactVotar(newValue.target.value)
                        )}
                        value={value}
                        inputProps={{ maxLength: 10 }}
                        placeholder={"EPIC No."}
                        error={!!errors["epic_no"]}
                        helperText={
                          errors.epic_no && `${errors.epic_no.message}`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!!actVotar && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "92%",
                  mt: 1,
                }}
              >
                <ImageUploader
                  documents={documents}
                  type={"VOTAR"}
                  fileHandleEvent={fileHandleEvent}
                  imageChange={imageChange}
                />
              </Box>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%",
                // marginBottom: 20,
              }}
            >
              Emergency Contact Information
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            sx={{ marginBottom: 3 }}
          >
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "50%",
                      width: "260px"
                      // textAlign: "center"
                    }}
                  >
                    Contact No.
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"emergency_contact"}
                    defaultValue={""}
                    rules={{ required: true, minLength: 10, maxLength: 12 }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "80.0%",
                          marginLeft: "22.5%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onInput={(e: any) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                        onChange={onChange}
                        value={value}
                        // inputProps={{ maxLength: 12 }}
                        placeholder={" Contact No."}
                        error={!!errors["emergency_contact"]}
                        helperText={
                          errors.emergency_contact &&
                          `${errors.emergency_contact.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"

              // width={"100%"}
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "0%",
                      // textAlign: "center"
                    }}
                  >
                    Relationship
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    control={control}
                    name={"relation"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{
                          width: "88.2%",
                          marginLeft: "5%",
                        }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        inputProps={{ maxLength: 50 }}
                        placeholder={"Relationship"}
                        error={!!errors["relation"]}
                        helperText={
                          errors.relation &&
                          `${errors.relation.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
    </>
  );
};

export default BasicDetails;
interface BasicDetailsProps {
  errors?: any;
  control?: any;
  documents: any[];
  fileHandleEvent?: any;
  SetDocument?: any;
}
