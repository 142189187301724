import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
  Button,
  Divider,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EducationDetails = ({
  addEduDetail,
  setaddEduDetail,
}: EducationDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns1: any[] = [
    "Course Name",
    "Board Name",
    "Subject",
    "Year of Passing",
    "Total Marks",
    "Marks Obtained",
    "Grade",
    "Action",
  ];
  const addEducationalDetails = () => {
    if (
      !addEduDetail[addEduDetail.length - 1].course_name ||
      !addEduDetail[addEduDetail.length - 1].board_name ||
      !addEduDetail[addEduDetail.length - 1].passing_year ||
      !addEduDetail[addEduDetail.length - 1].total_marks ||
      !addEduDetail[addEduDetail.length - 1].marks_obtained ||
      !addEduDetail[addEduDetail.length - 1].grade
    ) {
      enqueueSnackbar("Please fill add all detail before adding new row.", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
      Temp = [
        ...Temp,
        {
          course_name: null,
          board_name: null,
          subject: null,
          passing_year: null,
          total_marks: null,
          marks_obtained: null,
          grade: null,
        },
      ];
      setaddEduDetail(Temp);
    }
  };
  const OnChangeEducation = (value: string, row: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
    Temp[index][row] = value;
    setaddEduDetail(Temp);
  };
  const DeleteEduAction = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addEduDetail));
    let eduDet: any[] = [];
    Temp.forEach((element: any, indexs: any) => {
      if (index != indexs) {
        eduDet.push(JSON.parse(JSON.stringify(element)));
      }
    });
    setaddEduDetail([]);
    setTimeout(() => {
      setaddEduDetail(eduDet);
    }, 200);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20,
                marginLeft: "8%",
                // marginBottom: 20,
              }}
            >
              Educational Qualification
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            item
            marginLeft={11}
            xs={10}
            style={{ marginTop: 15, marginBottom: 30 }}
          >
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
              //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!addEduDetail &&
                    addEduDetail.map((row: any, index: number) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell align="left" key={columns1[0]}>
                          {/* {row.organization_name} */}
                          {/* Emdee */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "course_name",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 70 }}
                              value={row.course_name}
                              placeholder={"Course Name"}
                            // error={!!errors["project_name"]}
                            // helperText={
                            //   errors.project_name &&
                            //   `${errors.project_name.message}* This field is Required`
                            // }
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[1]}>
                          {/* {row.department_name} */}
                          {/* Active */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "board_name",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 70 }}
                              value={row.board_name}
                              placeholder={"Board Name"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[2]}>
                          {/* {row.department_name} */}
                          {/* Active */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "subject",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 70 }}
                              value={row.subject}
                              placeholder={"Subject"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[3]}>
                          {/* {row.department_desc} */}
                          {/* Active */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"month"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "passing_year",
                                  index
                                )
                              }
                              value={row.passing_year}
                              placeholder={"Passing Year"}
                            // error={!!errors["project_name"]}
                            // helperText={
                            //   errors.project_name &&
                            //   `${errors.project_name.message}* This field is Required`
                            // }
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[4]}>
                          {/* {row.department_type} */}
                          {/* Active */}
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"number"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "total_marks",
                                  index
                                )
                              }
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              value={row.total_marks}
                              placeholder={"Total Marks"}
                            // error={!!errors["project_name"]}
                            // helperText={
                            //   errors.project_name &&
                            //   `${errors.project_name.message}* This field is Required`
                            // }
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[5]}>
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"number"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "marks_obtained",
                                  index
                                )
                              }
                              onInput={(e: any) => {
                                e.target.value = Math.max(
                                  0,
                                  parseInt(e.target.value)
                                )
                                  .toString()
                                  .slice(0, 4);
                              }}
                              value={row.marks_obtained}
                              placeholder={"Marks Obtained"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[6]}>
                          <Grid item xs={12} style={{ marginLeft: -5 }}>
                            <TextField
                              type={"text"}
                              id="standard-required"
                              variant="outlined"
                              className={"smaller"}
                              style={{ width: "100%" }}
                              size="small"
                              onChange={(value) =>
                                OnChangeEducation(
                                  value.target.value,
                                  "grade",
                                  index
                                )
                              }
                              inputProps={{ maxLength: 20 }}
                              value={row.grade}
                              placeholder={"Grade"}
                            />
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns1[7]}>
                          {addEduDetail.length > 1 && (
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon
                                  color="error"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => DeleteEduAction(index)}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={3}>
              <Button
                onClick={() => addEducationalDetails()}
                size="medium"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default EducationDetails;
interface EducationDetailsProps {
  addEduDetail: any[];
  setaddEduDetail?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
