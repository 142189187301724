import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  TextareaAutosize,
  Divider,
  IconButton,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { AllPostList } from "../../../models/organizationModels";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
function AddPostView({
  Submit,
  post,
  SubmitUpdatedData,
  post_list,
}: AddPostViewProps) {
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };

  const onSubmit = (data: any) => {
    if (!!post) {
      let payload: AllPostList = { ...post, ...data };
      SubmitUpdatedData(payload);
    } else {
      Submit(data);
    }
  };

  useEffect(() => {
    if (!!post) {
      setValue("post_name", post.post_name);
      setValue("post_desc", post.post_desc);
    }
  }, [post]);
  return (
    <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/organization/post-list/`)}
            // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: 18,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            {!!post ? "Post Details" : "Add Post"}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>

      <form>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ marginInline: 10, width: "90%" }}>
            {!!post && (
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              >
                <Switch
                  value={edit_flag}
                  onChange={(event) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>Edit Post</Typography>
              </Grid>
            )}

            {/* <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Organization
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"organization_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={organization_list}
                        // readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"organization_id"}
                        label={"organization_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Organization"}
                        value={!!value ? value : ""}
                        errors={!!errors["organization_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid> */}
            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                  }}
                >
                  Post Name
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"post_name"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      // disabled={!!department ? edit_flag : !edit_flag}
                      inputProps={{
                        readOnly: !!post ? edit_flag : !edit_flag,
                      }}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"Post Name"}
                      // label="Enter the Post Name"
                      error={!!errors["post_name"]}
                      helperText={
                        errors.post_name &&
                        `${errors.post_name.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  control={control}
                  name={"post_desc"}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextareaAutosize
                    style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                      onBlur={onBlur}
                      className={"smaller"}
                      // id="standard-required"
                      onChange={onChange}
                      value={value}
                      disabled={!!post ? edit_flag : !edit_flag}
                      placeholder={"Description"}
                      minRows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          {((edit_flag && !post) || (!edit_flag && !!post)) && (
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              size="large"
            >
              <b>{!!post ? "Update" : "Add"}</b>
            </Button>
          )}
        </Grid>
      </form>
    </Paper>
  );
}

export default AddPostView;

interface AddPostViewProps {
  Submit?: any;
  // department?: AllDepartmentList;
  SubmitUpdatedData?: any;
  post?: AllPostList;
  post_list: AllPostList[];
}
