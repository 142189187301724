import { useState, FC, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LevelTree } from "../../../../models/customerModel";
import {
  ILevelMaster,
  Level,
} from "../../../../interfaces/projectDashboard.interface";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../../library/AutoComplete";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AddLevelsModal: FC<IAddLevelModal> = ({
  open,
  setOpen,
  levelTree,
  levelMaster,
  getLevelDetailsAction,
  createLevelAction,
}): JSX.Element => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [level2, SetLevel2] = useState<any[]>([]);
  const [level3, SetLevel3] = useState<any[]>([]);
  const [level4, SetLevel4] = useState<any[]>([]);
  const [level5, SetLevel5] = useState<any[]>([]);
  const [newLevel1, setNewLevel1] = useState<Level>({} as Level);
  const [addNewModalOpen, setAddNewModalOpen] = useState<boolean>(false);
  const [addNewModalField, setAddNewModalField] = useState<number>(0);
  const [addNewData, setAddNewData] = useState<string>("");
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const ChangeLevelOne = (data: number) => {
    const level1 = levelTree?.allLevels.find((m) => m.level1_id == +data);
    if (!!level1) setValue("level1_name", level1.level1_name);
    let level2 = levelTree?.allLevels.find(
      (m) => m.level1_id == +data
    )?.level_two;
    if (!!level2 && !Array.isArray(level2)) {
      SetLevel2([level2]);
    } else if (!!level2) {
      SetLevel2(level2);
    }
    SetLevel3([]);
    SetLevel4([]);
    SetLevel5([]);
  };
  const SelectLevel2 = (data: number) => {
    const _level2 = level2.find((m) => m.level2_id == +data);
    if (!!_level2) setValue("level2_name", _level2.level2_name);
    let level3 = level2.find((m) => m.level2_id == +data)?.level_three;
    if (!!level3 && !Array.isArray(level3)) {
      SetLevel3([level3]);
    } else if (!!level3) {
      SetLevel3(level3);
    }
    SetLevel4([]);
    SetLevel5([]);
  };
  const Level3Select = (data: number) => {
    const _level3 = level3.find((m) => m.level3_id == +data);
    if (!!_level3) setValue("level3_name", _level3.level3_name);
    let level4 = level3.find((m) => m.level3_id == +data)?.level_four;
    if (!!level4 && !Array.isArray(level4)) {
      SetLevel4([level4]);
    } else if (!!level4) {
      SetLevel4(level4);
    }
    SetLevel5([]);
  };
  const LevelFourChange = (data: number) => {
    const _level4 = level4.find((m) => m.level3_id == +data);
    if (!!_level4) setValue("level4_name", _level4.level4_name);
    let level5 = level4.find((m) => m.level3_id == +data)?.level_five;
    if (!!level5 && !Array.isArray(level5)) {
      SetLevel5([level5]);
    } else if (!!level5) {
      SetLevel5(level5);
    }
  };
  useEffect(() => {
    if (!!open && !!levelMaster) {
      getLevelDetailsAction(Number(levelMaster.level_key_id));
      console.log("getLevelDetailsAction fired");
    }
    if (!open) {
      reset();
    }
  }, [open, levelMaster]);
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (props: any) => {
    console.log("propos submit : ", props);
    const payload = {
      formData: {
        level_key_id: !!levelMaster ? +levelMaster?.level_key_id : 0,
        level1_id: Number(props.level1_id) > 0 ? Number(props.level1_id) : null,
        level2_id: Number(props.level2_id) > 0 ? Number(props.level2_id) : null,
        level3_id: Number(props.level3_id) > 0 ? Number(props.level3_id) : null,
        level4_id: Number(props.level4_id) > 0 ? Number(props.level4_id) : null,
        level5_id: Number(props.level5_id) > 0 ? Number(props.level5_id) : null,
        level1_name: props.level1_name ? props.level1_name : null,
        level2_name: props.level2_name ? props.level2_name : null,
        level3_name: props.level3_name ? props.level3_name : null,
        level4_name: props.level4_name ? props.level4_name : null,
        level5_name: props.level5_name ? props.level5_name : null,
      },
      enqueueSnackbar,
    };
    console.log("payload : ", payload);
    createLevelAction(payload);
    handleClose();
  };

  const addNewFieldReq = (levelKey: number) => {
    setAddNewModalOpen(true);
    setAddNewModalField(levelKey);
    setAddNewData("");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={style}>
            <Box
              sx={{
                p: 1,
                background: "#0060FF",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                color="white"
              >
                Add Levels
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  {!!levelMaster && levelMaster.level1_key_name}
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                style={{ marginLeft: -5, position: "relative" }}
              >
                <Controller
                  control={control}
                  name={"level1_id"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={
                        !!levelTree
                          ? [
                              ...levelTree.allLevels,
                              {
                                level1_id: -100,
                                level1_name: "Other",
                                created_on: new Date(),
                                master_key_id: !!levelMaster
                                  ? +levelMaster.level_key_id
                                  : 0,
                                updated_on: new Date(),
                              },
                            ]
                          : []
                      }
                      keys={"level1_id"}
                      label={"level1_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => (
                        onChange(value), ChangeLevelOne(+value)
                      )}
                      placeholder={
                        !!levelMaster ? levelMaster.level1_key_name : ""
                      }
                      value={!!value ? value : ""}
                      errors={errors.level1_id}
                    />
                  )}
                />
                {Number(getValues("level1_id")) === -100 ? (
                  <Controller
                    control={control}
                    name={"level1_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: 4 }}
                        size="small"
                        value={value}
                        onChange={onChange}
                        placeholder={`${
                          !!levelMaster && levelMaster.level1_key_name
                        }`}
                        error={!!errors.level1_name}
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            {(Number(getValues("level1_id")) > 0 ||
              Number(getValues("level1_id")) === -100) &&
            !!levelMaster &&
            !!levelMaster.level2_key_name &&
            levelMaster.level2_key_name.length > 0 ? (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    {levelMaster.level2_key_name}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ marginLeft: -5, position: "relative" }}
                >
                  {Number(getValues("level1_id")) > 0 &&
                  Number(levelTree?.lastKey) !== 2 ? (
                    <Controller
                      control={control}
                      name={"level2_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={[
                            ...level2,
                            {
                              level2_id: -100,
                              level2_name: "Other",
                              created_on: new Date(),
                              master_key_id: !!levelMaster
                                ? +levelMaster.level_key_id
                                : 0,
                              updated_on: new Date(),
                            },
                          ]}
                          keys={"level2_id"}
                          label={"level2_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), SelectLevel2(+value)
                          )}
                          placeholder={levelMaster.level2_key_name}
                          value={!!value ? value : ""}
                          errors={errors.level2_id}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                  {Number(getValues("level1_id")) === -100 ||
                  Number(getValues("level2_id")) === -100 ||
                  Number(levelTree?.lastKey) === 2 ? (
                    <Controller
                      control={control}
                      name={"level2_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%", marginLeft: 4 }}
                          onChange={onChange}
                          size="small"
                          value={!!value ? value : ""}
                          placeholder={`${levelMaster.level2_key_name}`}
                          error={!!errors.level1_name}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {(Number(getValues("level1_id")) === -100 ||
              Number(getValues("level2_id")) > 0 ||
              Number(getValues("level2_id")) === -100) &&
            !!levelMaster &&
            !!levelMaster.level3_key_name &&
            levelMaster.level3_key_name.length > 0 ? (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{
                  marginTop: 15,
                  marginBottom: 10,
                  position: "relative",
                }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    {levelMaster.level3_key_name}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ marginLeft: -5, position: "relative" }}
                >
                  {Number(getValues("level2_id")) > 0 &&
                  Number(levelTree?.lastKey) !== 3 ? (
                    <Controller
                      control={control}
                      name={"level3_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={[
                            ...level3,
                            {
                              level3_id: -100,
                              level3_name: "Other",
                              created_on: new Date(),
                              master_key_id: !!levelMaster
                                ? +levelMaster.level_key_id
                                : 0,
                              updated_on: new Date(),
                            },
                          ]}
                          keys={"level3_id"}
                          label={"level3_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), Level3Select(+value)
                          )}
                          placeholder={levelMaster.level3_key_name}
                          value={!!value ? value : ""}
                          errors={errors.level3_id}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                  {Number(getValues("level1_id")) === -100 ||
                  Number(getValues("level2_id")) === -100 ||
                  Number(getValues("level3_id")) === -100 ||
                  Number(levelTree?.lastKey) === 3 ? (
                    <Controller
                      control={control}
                      name={"level3_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%", marginLeft: 4 }}
                          onChange={onChange}
                          size="small"
                          value={!!value ? value : ""}
                          placeholder={`${levelMaster.level3_key_name}`}
                          error={!!errors.level3_name}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {(Number(getValues("level1_id")) === -100 ||
              Number(getValues("level2_id")) === -100 ||
              Number(getValues("level3_id")) > 0 ||
              Number(getValues("level3_id")) === -100) &&
            !!levelMaster &&
            !!levelMaster.level4_key_name &&
            levelMaster.level4_key_name.length > 0 ? (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    {levelMaster.level4_key_name}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ marginLeft: -5, position: "relative" }}
                >
                  {Number(getValues("level3_id")) > 0 &&
                  Number(levelTree?.lastKey) !== 4 ? (
                    <Controller
                      control={control}
                      name={"level4_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={[
                            ...level4,
                            {
                              level4_id: -100,
                              level4_name: "Other",
                              created_on: new Date(),
                              master_key_id: !!levelMaster
                                ? +levelMaster.level_key_id
                                : 0,
                              updated_on: new Date(),
                            },
                          ]}
                          keys={"level4_id"}
                          label={"level4_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), LevelFourChange(value)
                          )}
                          placeholder={levelMaster.level4_key_name}
                          value={!!value ? value : ""}
                          errors={errors.level4_id}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                  {Number(getValues("level1_id")) === -100 ||
                  Number(getValues("level2_id")) === -100 ||
                  Number(getValues("level3_id")) === -100 ||
                  Number(getValues("level4_id")) === -100 ||
                  Number(levelTree?.lastKey) === 4 ? (
                    <Controller
                      control={control}
                      name={"level4_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100%", marginLeft: 4 }}
                          onChange={onChange}
                          size="small"
                          value={value}
                          placeholder={`${levelMaster.level4_key_name}`}
                          error={!!errors.level4_name}
                        />
                      )}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {(Number(getValues("level1_id")) === -100 ||
              Number(getValues("level2_id")) === -100 ||
              Number(getValues("level3_id")) === -100 ||
              Number(getValues("level3_id")) > 0 ||
              Number(getValues("level4_id")) > 0 ||
              Number(getValues("level4_id")) === -100) &&
            !!levelMaster &&
            !!levelMaster.level5_key_name &&
            levelMaster.level5_key_name.length > 0 ? (
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
                // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    {levelMaster.level5_key_name}
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5.5}
                  style={{ marginLeft: -5, position: "relative" }}
                >
                  <Controller
                    control={control}
                    name={"level5_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100%", marginLeft: 4 }}
                        onChange={onChange}
                        size="small"
                        value={value}
                        placeholder={`${levelMaster.level5_key_name}`}
                        error={!!errors.level5_name}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Grid
              container
              style={{ marginTop: 15, marginBottom: 10 }}
              // width={"100%"}
            >
              <Grid
                item
                xs={10.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button variant="outlined" onClick={handleClose} sx={{ ml: 8 }}>
                  Close
                </Button>
                <Button type="submit" variant="outlined">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Modal>
    </>
  );
};
interface IAddLevelModal {
  open: boolean;
  setOpen: any;
  levelTree?: LevelTree;
  levelMaster?: ILevelMaster;
  createLevelAction?: any;
  getLevelDetailsAction?: any;
}

export default AddLevelsModal;
