import { EmployeeMain } from "../../models/employeeModel";
import { EmployeeActionTypes } from "../actions/employeeAction";
import InitialState from "./initialState";
import { CandidateBulkUpload } from "./../../models/employeeModel";

const initialState: EmployeeMain = InitialState.employee;
export default function EmployeeReducer(
  state: EmployeeMain = initialState,
  action: any
) {
  switch (action.type) {
    case EmployeeActionTypes.Get_All_Event_Staus_Success_Action:
      return {
        ...state,
        event_list: action.payload.req,
        filter: action.payload.filter,
      };
    case EmployeeActionTypes.Get_All_Refferal_Success_Action:
      return {
        ...state,
        refferal_list: action.payload.list,
        refferal_count: action.payload.count,
      };
    case EmployeeActionTypes.Get_Employee_View_Success_Action:
      return { ...state, employee_detail: action.payload };
    case EmployeeActionTypes.Get_Reporting_Manager_Success_Action:
      return { ...state, ReportingManagerList: action.payload };
    case EmployeeActionTypes.Get_All_Candidate_Success_Action:
      return {
        ...state,
        candidate_list: action.payload.list,
        candidate_count: action.payload.count,
      };
    case EmployeeActionTypes.Get_Attendence_Salary_type_Success_Action:
      return { ...state, attendence_salary_type: action.payload };
    // case EmployeeActionTypes.GET_All_Employee_WITH_PDF:
    //   return {
    //     ...state,
    //     employee_list: action.payload.list,
    //     employee_count: action.payload.count,
    //     employee_pdf_list: action.payload.pdfList, // this is dataset is for creating employee data pdf
    //   };
    case EmployeeActionTypes.Get_All_Employee_Success_Action:
      return {
        ...state,
        employee_list: action.payload.list,
        employee_count: action.payload.count,
      };
    case EmployeeActionTypes.Get_Action_Status_Success_Action:
      return { ...state, action_event_list: action.payload };
    case EmployeeActionTypes.Get_Employee_All_Details_Success_Action:
      return {
        ...state,
        documents_details: action.payload.documents_details,
        employeeDetails: action.payload.employeeDetails,
        permanent_address: action.payload.permanent_address,
        present_address: action.payload.present_address,
        education_details: action.payload.education_details,
        experience_details: action.payload.experience_details,
        ProjectAssociationWithLevelName:
          action.payload.ProjectAssociationWithLevelName,
        designation_name_with_details:
          action.payload.designation_name_with_details,
        EmployeeAttendanceReport: action.payload.EmployeeAttendanceReport,
        SalaryDetail: action.payload.SalaryDetail,
        SalaryHistory: action.payload.SalaryHistory,
        professional_details: action.payload.professional_details,
        reporting_manager_details: action.payload.reporting_manager_details
      };
    case EmployeeActionTypes.Get_All_Bank_Success_Action:
      return { ...state, bank_list: action.payload };
    case EmployeeActionTypes.Candidate_Bulk_Upload_List_Success_Action:
      return { ...state, candidateBulkUploadList: action.payload };
    case EmployeeActionTypes.GET_BULK_UPLOAD_STATUS_ACTION:
      return { ...state, candidateBulkUploadList: action.payload };
    case EmployeeActionTypes.GET_BULK_UPLOAD_STATUS_ACTION_UPDATED:
      console.log("payload : ", action.payload);
      const payloadIds = action.payload.map(
        (self: { bulk_id: any }) => self.bulk_id
      );
      const newData = state.candidateBulkUploadList.filter(
        (self, index) => !payloadIds.includes(self.bulk_id)
      );
      console.log("filter : ", newData);

      return {
        ...state,
        candidateBulkUploadList: [...action.payload, ...newData],
      };
    default:
      return state;
  }
}
