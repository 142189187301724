import React, { useEffect } from "react";
import ProjectClosurePendingApprovalView from "./ProjectClosurePendingApprovalView";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { ApprovalPendingProjectClosureAction, GetAllPendingProjectClosureListAction } from "../../../stores/actions/customerAction";
import { ProjectClosurePendingList } from "../../../models/customerModel";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";

function ProjectClosurePendingApproval({
  pending_project_closure,
  GetAllPendingProjectClosureListAction,
  user,
  ApprovalPendingProjectClosureAction
}: ProjectClosurePendingApprovalProps) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    GetAllPendingProjectClosureListAction({
      approverDesigId: user?.designation,
      eventType : 12
    })

  }, [])
  const FinalApprovedSubmit=(data: any)=>{
    console.log("data: " , data);
    ApprovalPendingProjectClosureAction({
      data: data,
      data_list: {
        approverDesigId: user?.designation,
        eventType : 12
      },
      enqueueSnackbar: enqueueSnackbar,
    })
  }
  return <ProjectClosurePendingApprovalView pending_project_closure={pending_project_closure} FinalApprovedSubmit={FinalApprovedSubmit}/>;
}

const mapStateToProps = (state: StoreState) => {
  return {
    user: state.user.userDetails,
    pending_project_closure: state.customer.pending_project_closure
  };
};

const mapDispatchToProps = {
  GetAllPendingProjectClosureListAction,
  ApprovalPendingProjectClosureAction
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectClosurePendingApproval);

interface ProjectClosurePendingApprovalProps {
  GetAllPendingProjectClosureListAction?: any;
  ApprovalPendingProjectClosureAction?: any;
  pending_project_closure: ProjectClosurePendingList[];
  user?: userDetails;
}
