import {
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { BudgetDashboardDetails } from "../../../models/customerModel";

function MonthlyExpensesPopover({
  open,
  setOpen,
  budget_details,
  selectedExpenses,
}: MonthlyExpensesPopoverProps) {
  const columns: any[] = ["Month", "Cost"];
  // console.log(selectedExpenses);
  // console.log(
  //   budget_details?.spending_details.filter((m) => m.field == selectedExpenses)
  // );
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid
              container
              style={{
                padding: 10,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Grid item>
                <Typography style={{ color: "#fff" }}>Month Wise Cost</Typography>
              </Grid>
            </Grid>
            <Grid container style={{ width: "100%", padding: 5 }}>
              <Grid item>
                <TableContainer sx={{}}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {columns.map((column) => (
                          <StyledTableCellS align="justify" key={column}>
                            {column}
                          </StyledTableCellS>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!budget_details?.spending_details && budget_details?.spending_details.length>0 &&
                        budget_details?.spending_details
                          .filter((m) => m.field == selectedExpenses)
                          .map((row) => (
                            <StyledTableRowS
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              // key={row.}
                            >
                              <StyledTableCellS align="left" key={columns[0]}>
                                {row.field
                                  ? moment(row.month).format("MMM,YYYY")
                                  : "N/A"}
                              </StyledTableCellS>
                              <StyledTableCellS align="left" key={columns[1]}>
                              &#8377;{!!row.expense ? row.expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): "N/A"}
                              </StyledTableCellS>
                            </StyledTableRowS>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  onClick={() => setOpen(false)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default MonthlyExpensesPopover;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};
const StyledTableCellS: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#19A7CE",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#146C94",
  },
}));

const StyledTableRowS = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface MonthlyExpensesPopoverProps {
  open: boolean;
  setOpen?: any;
  budget_details?: BudgetDashboardDetails;
  selectedExpenses?: any;
}
