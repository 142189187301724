import React, { FC } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  tableCellClasses,
  styled,
  Grid,
  Typography,
  Box,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { IEmployeePayroll } from "../../../models/customerModel";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Link } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { baseUrl, environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
const FileDownload = require("js-file-download");
const EmployeePayroll: FC<IEmpPayroll> = ({
  empPayroll,
  currentMonthForEmpPayroll,
  accessToken,
  setCurrentMonthForEmpPayroll,
  user,
}) => {
  const styles = {
    marginRight: "50px",
  };
  const columns: string[] = [
    "Employee ID",
    "Employee Name",
    "Month",
    "Total Income",
    "Net Payable",
    "Batch Status",
    "Action",
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const Month: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const handleDateForEmp = (newValue: Dayjs | null) => {
    setCurrentMonthForEmpPayroll(newValue);
  };
  const { enqueueSnackbar } = useSnackbar();
  const empwiseSalarySlip = (data: any, k: any, index: number) => {
    // console.log("data", data, "k", k);
    Axios({
      url: baseUrl + environment.urls.downloadEmpSalarySlip,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        employee_id: data,
        batch_id: k,
        bonus: index === 1 ? false : true
      },
    })
      .then((response) => {
        if (response.status == 200) {
          FileDownload(response.data, `${data}-salaryslip.pdf`);
        } else {
          enqueueSnackbar(
            !!response?.data?.message
              ? response?.data?.message
              : "Something went wrong...",
            {
              variant: "warning",
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error?.data?.message
            ? error?.data?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <Grid item sm={12} md={5.9}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box width="50%">
          <Typography textAlign="right" variant="body1" color="#0060FF">
            Employee Payroll for{" "}
            {dayjs(currentMonthForEmpPayroll).format("MMM-YYYY")}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="50%"
        >
          <DesktopDatePicker
            inputFormat="MMM"
            InputProps={{
              sx: { width: "10rem", outline: "none", border: "none", mb: 1 },
            }}
            views={["month"]}
            maxDate={dayjs(new Date())}
            value={currentMonthForEmpPayroll}
            onChange={handleDateForEmp}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </Box>

      <TableContainer sx={{ borderRadius: 1 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          //   style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!empPayroll &&
              empPayroll
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index: number) => (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <StyledTableCell align="left" key={columns[0]}>
                      {!!user &&
                      environment.global_veriable.officialEmployee.includes(
                        user.designation
                      ) ? (
                        <Link
                          to={`/employment/manage-profile/${row.employee_id}`}
                        >
                          {row.employee_id}
                        </Link>
                      ) : (
                        row.employee_id
                      )}
                      {/* Emdee */}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[1]}>
                      {row.employee_name}
                      {/* Emdee */}
                    </StyledTableCell>

                    <StyledTableCell align="left" key={columns[2]}>
                      {Month[Number(row.month)] + "'" + row.year}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[3]}>
                      <span
                        style={{
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          color: "#008060",
                        }}
                      >
                        &#8377; {row.total_income}
                      </span>
                      {/* Emdee */}
                    </StyledTableCell>

                    <StyledTableCell align="left" key={columns[4]}>
                      <span
                        style={{
                          fontFamily: "Arial",
                          fontWeight: "bold",
                          color: "#008060",
                        }}
                      >
                        &#8377; {row.net_payable}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[5]}>
                      {row.batch_status_name}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[6]}>
                      <Tooltip title="Download Salary Slip">
                        <IconButton
                          onClick={() =>
                            empwiseSalarySlip(row.employee_id, row.batch_id, 1)
                          }
                        >
                          <SaveAltIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Download Incentive Slip">
                        <IconButton
                          onClick={() =>
                            empwiseSalarySlip(row.employee_id, row.batch_id,2)
                          }
                        >
                          <SaveAltIcon />
                        </IconButton>
                      </Tooltip> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            {!!empPayroll && empPayroll.length <= 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={7}>
                  <div style={{ color: "red", textAlign: "center" }}>
                    No Data Found{" "}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={empPayroll.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface IEmpPayroll {
  empPayroll: IEmployeePayroll[];
  accessToken?: string;
  user?: userDetails;

  currentMonthForEmpPayroll: dayjs.Dayjs | null;
  setCurrentMonthForEmpPayroll: React.Dispatch<
    React.SetStateAction<dayjs.Dayjs | null>
  >;
}
export default EmployeePayroll;
