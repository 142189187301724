import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  NativeSelect,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState, FC } from "react";
import {
  IPayrollReportDetails,
  ITotalPayrollSal,
  PFESICReportLists,
} from "../../../models/reportsModel";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { ProjectList } from "../../../models/customerModel";
import { DatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useNavigate } from "react-router";
import { environment } from "../../../environment";
import {
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MonthNames, monthOptions } from "../../../utility/monthOption";
import { IMonthOptions } from "../../../interfaces/others.interface";
import dayjs, { Dayjs } from "dayjs";
import { IEmployeeList } from "../../../models/timingSheetModel";
import TotalSalaryComponent from "../PayrollReports/TotalSalaryComponent";
import SearchEmployee from "../../utilComponent/SearchEmployee";
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;

const PFReportsView: FC<PFReportsViewProps> = ({
  report_lists,
  Clear,
  filterData,
  project_list,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
  employee_type_list,
  organization_list,
  openReport,
  control,
  handleSubmit,
  watch,
  setValue,
  getValues,
  reset,
  payroll_list,
  totalPayrollData,
  totalPayrollListForPDF,
  totalSalCountDataForPDF,
  errors,
  expanded,
  setExpanded,
  searchByNameEmpAction,
  employeeList,
  component_list,
}) => {
  const Month: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const [rows, setRows] = useState<IPayrollReportDetails[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [dataForPDF, setDataForPDF] = useState<any[]>([]);
  const [reportYear, setReportYear] = useState<any>(new Date());
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!payroll_list
      ? payroll_list.filter((row: IPayrollReportDetails) => {
          return (
            row?.employee_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.project_name?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!payroll_list) {
      cancelSearch();
    }
  }, [payroll_list]);
  const columns: any[] = [
    "Project Name",
    "Employee Name",
    "Employee ID",
    "UAN No.",
    "Basic Salary",
    "PF Deduction",
    "PF Contribution",
    "Year",
    "Month",
  ];
  const styles = {
    marginRight: "50px",
  };
  const divElement = useRef<HTMLDivElement | null>(null);

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const filterDatas = (data: any) => {
    filterData(data);
  };
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  console.log(rows);
  useEffect(() => {
    const tList: any[] = [];
    totalPayrollListForPDF.forEach((self) => {
      tList.push([
        { text: self.project_name },
        { text: self.employee_name },
        { text: self.employee_id },
        { text: self.uan_no },
        { text: self.Basic },
        { text: self.PF_Deduction },
        { text: self.PF_Contribution },
        { text: `${Month[Number(self.month)]}/${self.year}` },
      ]);
    });
    setDataForPDF(tList);
  }, [totalPayrollListForPDF]);

  const handleYear = (newValue: Dayjs | null) => {
    setReportYear(newValue);
    if (newValue) setValue("year", +dayjs(newValue).format("YYYY"));
  };

  const onDownloadPDF = () => {
    // html2canvas(document.getElementById("target")!).then((canvas) => {
    console.log("pdf hit");
    html2canvas(divElement.current!)
      .then((canvas) => {
        const dataURI = canvas.toDataURL();
        const docDefinition = {
          pageSize: "A4",
          pageOrientation: "landscape",
          pageMargins: [2, 2, 2, 2],
          background: {
            image: dataURI,
            width: 845,
          },
          // content: [
          //   {
          //     text: "title",
          //     fontSize: 32,
          //     bold: true,
          //     margin: [50, 50, 60, 60]
          //   }
          // ]
        };
        var dd = {
          content: [
            {
              text: `Providend Fund Details  Of ${
                organization_list.find(
                  (self) =>
                    Number(getValues("organization_id")) ===
                    Number(self.organization_id)
                )?.organization_name
              } ${
                getValues("months")
                  ? ` ${
                      Array.isArray(getValues("months")) &&
                      !getValues("months").includes(13)
                        ? ` For ${getValues("months")
                            .map((_self: any) => MonthNames[+_self])
                            .join(", ")}`
                        : ``
                    }`
                  : ""
              } ${
                reportYear ? ` -  ${new Date(reportYear).getFullYear()}` : ``
              }`,
              style: "header",
            },
            {
              style: "tableExample",
              widths: ["*"],
              table: {
                body: [
                  [
                    { text: "Project Name", style: "tableHeader" },
                    {
                      text: "Employee Name",
                      style: "tableHeader",
                    },
                    {
                      text: "Employee ID",
                      style: "tableHeader",
                    },
                    {
                      text: "UAN No.",
                      style: "tableHeader",
                    },
                    {
                      text: "Basic Salary",
                      style: "tableHeader",
                    },

                    {
                      text: "PF Deduction",
                      style: "tableHeader",
                    },
                    {
                      text: "PF Contribution",
                      style: "tableHeader",
                    },
                    {
                      text: "Month",
                      style: "tableHeader",
                    },
                  ],
                  ...dataForPDF,
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            {
              style: "tableExample",
              widths: ["*", "*"],
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    "Total Basic",
                    Number(totalSalCountDataForPDF?.totalBasic).toFixed(2),
                  ],
                  [
                    "Total PF Contribution",
                    Number(
                      totalSalCountDataForPDF?.totalPFContribution
                    ).toFixed(2),
                  ],
                  [
                    "Total PF Deduction",
                    Number(totalSalCountDataForPDF?.totalPFDeduction).toFixed(
                      2
                    ),
                  ],
                ],
              },
            },
          ],
          styles: {
            header: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 10,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: 900,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: "black",
              width: 500,
              paddingHorizontal: 5,
            },
          },
          defaultStyle: {
            fontSize: 9,
            color: "black",
            // alignment: 'justify'
          },
        };
        console.log("dd  : ", dd);
        pdfMake.createPdf(dd as any).download("PF-report.pdf");
      })
      .catch((err) => console.log("error : ", err));
  };
  useEffect(() => {
    if (!!getValues("employee_type_id"))
      searchByNameEmpAction("", getValues("employee_type_id"));
  }, [watch("employee_type_id")]);

  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  // display: "flex",
                  // flexDirection: "row",
                  // width: "100%",
                  // backgroundColor: "#0060FF",
                  // justifyContent: "space-between",
                  // marginTop: -2,
                  // borderTopLeftRadius: 5,
                  // borderTopRightRadius: 5,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid>
                <form>
                  <Grid container direction={"row"}>
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"start"}
                      paddingTop={0}
                    >
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={4} alignItems="center">
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Organization
                                <span style={{ color: "red", fontSize: 14 }}>
                                  *
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"organization_id"}
                                defaultValue={""}
                                rules={{ required: true }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={organization_list}
                                    //readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"organization_id"}
                                    label={"organization_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Organization"}
                                    value={!!value ? value : ""}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {!!watch("organization_id") && (
                          <Grid item xs={4} alignItems="center">
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5.5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Employee Type
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5}>
                                <Controller
                                  control={control}
                                  name={"employee_type_id"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <Autocompletes
                                      hasInfo={false}
                                      option={employee_type_list}
                                      //readOnly={!!department ? edit_flag : !edit_flag}
                                      keys={"employee_type_id"}
                                      label={"employee_type_name"}
                                      onBlur={onBlur}
                                      onChange={(value: any) => (
                                        onChange(value),
                                        setValue("project_id", null)
                                      )}
                                      placeholder={"Search Employee Type"}
                                      value={!!value ? value : ""}
                                      errors={!!errors["employee_type_id"]}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {!!watch("employee_type_id") &&
                          !environment.global_veriable.ho_employees_type_id.includes(
                            +watch("employee_type_id")
                          ) && (
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction={"row"}
                                wrap={"nowrap"}
                                alignItems="center"
                              >
                                <Grid item xs={5.5}>
                                  <Typography
                                    style={{
                                      fontWeight: "500",
                                      fontSize: 16,
                                      fontFamily: "Poppins, sans-serif",
                                      color: "#626974",
                                    }}
                                  >
                                    Project
                                    <span
                                      style={{ color: "red", fontSize: 14 }}
                                    >
                                      *
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                                  <Controller
                                    control={control}
                                    name={"project_id"}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({
                                      field: { onBlur, value, onChange },
                                    }) => (
                                      <Autocompletes
                                        hasInfo={false}
                                        option={project_list}
                                        //readOnly={!!department ? edit_flag : !edit_flag}
                                        keys={"project_id"}
                                        label={"project_name"}
                                        onBlur={onBlur}
                                        onChange={(value: any) =>
                                          onChange(value)
                                        }
                                        placeholder={"Search Project"}
                                        value={!!value ? value : ""}
                                        errors={!!errors["project_id"]}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        {!!watch("employee_type_id") ? (
                          <Grid item xs={4}>
                            <SearchEmployee
                              searchByNameEmpAction={searchByNameEmpAction}
                              getValues={getValues}
                              control={control}
                              employeeList={employeeList}
                              fieldName={"employeeId"}
                              marginBottom={10}
                              marginTop={8}
                              gridVal={5.5}
                            />
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {!!watch("organization_id") ? (
                          <Grid item xs={4}>
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5.5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Year
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                                <Controller
                                  control={control}
                                  name={"year"}
                                  defaultValue={""}
                                  // rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DesktopDatePicker
                                      label={
                                        <span>
                                          Select Year{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      }
                                      inputFormat="YYYY"
                                      InputProps={{
                                        sx: {
                                          align: "center",
                                          outline: "none",
                                          width: "100%",

                                          height: "2.3rem",
                                          border: "none",
                                          ml: 1,
                                        },
                                      }}
                                      views={["year"]}
                                      value={reportYear}
                                      onChange={handleYear}
                                      renderInput={(
                                        params: JSX.IntrinsicAttributes &
                                          TextFieldProps
                                      ) => <TextField {...params} />}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {!!watch("organization_id") ? (
                          <Grid item xs={4} pt={1}>
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            >
                              <Grid item xs={5.5}>
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  Select Month
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                                <Controller
                                  control={control}
                                  name={"months"}
                                  defaultValue={[]}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <Autocomplete
                                      multiple
                                      id="tags-standard"
                                      options={monthOptions ? monthOptions : []}
                                      style={{
                                        width: "100.9%",
                                        marginLeft: "4%",
                                      }}
                                      getOptionLabel={(option) => option.key}
                                      onChange={(
                                        evemt: any,
                                        value: IMonthOptions[]
                                      ) => {
                                        onChange(
                                          !!value
                                            ? value.map((self) => self.val)
                                            : []
                                        );
                                      }}
                                      value={
                                        !!value
                                          ? monthOptions.filter((self) =>
                                              value.includes(self.val)
                                            )
                                          : []
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          id="standard-required"
                                          className={"smaller"}
                                          variant="outlined"
                                          placeholder="Choose Month"
                                          onBlur={onBlur}
                                          size="small"
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {!!watch("organization_id") && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end ",
                        marginRight: "2rem",
                        //marginRight: "0px",
                        // marginTop: "-2rem",
                        marginTop: "1rem",
                        marginBottom: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          marginRight: 1,
                        }}
                        onClick={() => (Clear(), reset())}
                      >
                        Clear
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          marginLeft: 1,
                        }}
                        variant="outlined"
                        onClick={handleSubmit(filterDatas)}
                      >
                        filter
                      </Button>
                    </Box>
                  )}
                </form>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      {totalPayrollData && openReport === true ? (
        <TotalSalaryComponent
          totalSalCount={totalPayrollData}
          totalSize={total_size}
          accessFor={["basic", "pf_contribution", "pf_deduction"]}
          component_list={component_list}
        />
      ) : (
        <></>
      )}
      {openReport === true && (
        <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
          <Grid
            container
            direction={"row"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Grid item xs={3} sx={{ paddingLeft: 5 }}>
              <Button
                // onClick={() => navigate("/organization/add-department/0")}
                onClick={onDownloadPDF}
                size="medium"
                variant="outlined"
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                style={{ width: "100%" }}
                placeholder="Search..."
                id="fullWidth"
                value={searched}
                onChange={(e: any) => (
                  requestSearch(e.target.value), setSearched(e.target.value)
                )}
                InputProps={{
                  endAdornment:
                    !!searched && searched.length > 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => cancelSearch()}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="primary">
                        <SearchIcon />
                      </IconButton>
                    ),
                }}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid marginTop={2}>
            <TableContainer sx={{ borderRadius: 1 }} ref={divElement}>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows.map((row, index) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.project_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.employee_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          <Link
                            onClick={() =>
                              navigate(
                                `/employment/manage-profile/${row.employee_id}`
                              )
                            }
                          >
                            {row.employee_id}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {row.uan_no}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          &#8377; {row.Basic}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[5]}>
                          &#8377; {row.PF_Deduction}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[6]}>
                          &#8377; {row.PF_Contribution}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[7]}>
                          {row.year}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[8]}>
                          {MonthNames[+row.month]}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container alignItems={"center"} justifyContent="end">
              <Grid xs={2} textAlign="right">
                Rows per page:
              </Grid>
              <Grid xs={1} textAlign="center">
                <NativeSelect
                  value={pagesize.toString()}
                  onChange={(event) => SetpageSize(+event.target.value)}
                >
                  {rowperpage.map((p) => (
                    <option
                      key={p}
                      value={p}
                      onChange={(value: any) => value(p)}
                    >
                      {p}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>
              <Grid xs={1} textAlign="center">
                <IconButton
                  onClick={() => Setpageindex(pageindex - 1)}
                  color="primary"
                  size="small"
                  disabled={pageindex <= 0}
                >
                  <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                </IconButton>
              </Grid>
              <Grid xs={1} textAlign="center">
                <Typography>
                  {pageindex * pagesize + 1} -{" "}
                  {!!total_size && total_size <= (pageindex + 1) * pagesize
                    ? total_size
                    : (pageindex + 1) * pagesize}
                  {" of "}
                  {total_size}
                </Typography>
              </Grid>
              <Grid xs={1} textAlign="center">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => Setpageindex(pageindex + 1)}
                  disabled={
                    (!!total_size &&
                      total_size <= (pageindex + 1) * pagesize) ||
                    total_size === 0
                  }
                >
                  <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default PFReportsView;
interface PFReportsViewProps {
  report_lists: PFESICReportLists[];
  filterData?: any;
  Clear?: any;
  project_list: ProjectList[];
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  employee_type_list: EmployeeTypeList[];
  organization_list: OrganizationALlList[];
  openReport?: boolean;
  control?: any;
  handleSubmit?: any;
  watch?: any;
  setValue?: any;
  getValues?: any;
  reset?: any;

  payroll_list: IPayrollReportDetails[];
  totalPayrollData?: ITotalPayrollSal;
  totalPayrollListForPDF: IPayrollReportDetails[];
  totalSalCountDataForPDF?: ITotalPayrollSal;
  errors: any;
  expanded: string | false;
  setExpanded: any;
  searchByNameEmpAction?: any;
  employeeList: IEmployeeList[];
  component_list: SalaryStuctureComponent[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    // color: "#ffff",
    // fontWeight: 600,
    // fontSize: 14,
    // padding: "5px",
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: { md: 14, sm: 12, xs: 8 },
    // padding: "5px",
    // color: "#363A44",
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
