import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Background } from "reactflow";
import { Padding } from "@mui/icons-material";
import { EmployeeProjectHistory } from "../../../models/employeeModel";
import { ProjectLevels } from "../../../models/customerModel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import moment from "moment";
import { Link } from "react-router-dom";

function EmployeeProjectDetailsView({
  ProjectAssociationWithLevelName,
}: EmployeeProjectDetailsViewProps) {
  return (
    <Carousel showThumbs={false} showArrows={true}>
      {!!ProjectAssociationWithLevelName &&
        ProjectAssociationWithLevelName.map((row) => (
          <Grid
            container
            direction={"column"}
            paddingTop={2}
            key={row.level_key_id}
          >
            <Grid item xs={12}>
              <Card
                variant="elevation"
                sx={{
                  backgroundColor: "#F6F7F9",
                  textAlign: "left",
                  padding: 0,
                  //paddingLeft: "10%",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    padding={0}
                    paddingBottom={3}
                  >
                    <Grid
                      container
                      sx={{
                        height: 45,
                        width: "55%",
                        backgroundColor: "#609EA2",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                        borderBottomRightRadius: 10,
                        borderTopRightRadius: 10,
                        ml: "-16px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontWeight: 700,
                          wordWrap: "break-word",
                        }}
                      >
                        {row.project_name}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        height: 45,
                        width: "15%",
                        justifyContent: "center",
                        alignItems: "center",
                        ml: "30%",
                        borderRadius: 10,
                      }}
                    >
                      <Tooltip title="Project Status">
                        <Typography
                          sx={
                            row.association_status === "ACTIVE"
                              ? { color: "green" }
                              : { color: "grey" }
                          }
                        >
                          {row.association_status}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    padding={0}
                  >
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          Project Start Date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {moment(row.start_date).format("DD-MM-YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          Project End Date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 300,
                            pl: "5%",
                          }}
                        >
                          {row.end_date
                            ? moment(row.end_date).format("DD-MM-YYYY")
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          Project Manager
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.project_supervisor
                            ? row.project_supervisor
                            : "---"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    padding={0}
                  >
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          {row.level1_key_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.level1_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          {row.level2_key_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.level2_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          {row.level3_key_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.level3_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    padding={0}
                  >
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          {row.level4_key_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.level4_name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            pl: "5%",
                            fontFamily: "Poppins, sans-serif",
                            color: "#567189",
                          }}
                        >
                          {row.level5_key_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 16, fontWeight: 300, pl: "5%" }}
                        >
                          {row.level5_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
    </Carousel>
  );
}

export default EmployeeProjectDetailsView;

interface EmployeeProjectDetailsViewProps {
  ProjectAssociationWithLevelName: EmployeeProjectHistory[];
  project_levels?: ProjectLevels;
}
