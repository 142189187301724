import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { watch } from "fs";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import ProjectExpences from "./ProjectExpences";
import EmployeeProjectActivity from "./EmployeeProjectActivity";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  IEmployeeList,
  IWorkSheet,
  TimingSheetList,
} from "../../../models/timingSheetModel";
import { userDetails } from "./../../../models/userModels";
import { useSnackbar } from "notistack";
import { debounceFn } from "../../../utility/debouncing";
import TimeSheetTab from "./TimeSheetTab";
import CalendarwiseTimeSheet from "./CalendarwiseTimeSheet";
import AllotedProjectsModal from "./AllotedProjectsModal";
import { log } from "console";

function LogActivityView({
  Submit,
  timing_sheet_list,
  user,
  clearTimeSheetAction,
  initiateTimesheetAction,
  UpdateMonthlyTimeSheetAction,
  notEditable,
  isworklist,
  worklist,
  ClearTimingSheetFromWorklistAction,
  goToTimingSheetFromWorklistAction,
  isLogView,
  employeeList,
  notShow,
  searchByNameEmpAction,
  apiLoading,
}: LogActivityViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const formControl = useForm();
  const saveButtonRef = useRef<any>();
  const [mutate, setMutate] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [showApprovalBtn, setShowApprobvalBtn] = useState<boolean>(true);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [searchMonth, setSearchMonth] = useState<string>("");
  const [showTabView, setShowTabView] = useState<string>("calendar");
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<number[]>([]);
  const [tempeventsData, setTempEventsData] = useState<any[]>([]);

  const [selectedDate, setSelectedDate] = useState<any>();
  const [allotedProjectModalOpen, setAllotedProjectModalOpen] =
    useState<boolean>(false);
  const [searchEmpNameQuery, setSearchEmpQuery] = useState<string>("");
  const [defaultDate, setDefaultDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  console.log("defaultDate: ", defaultDate);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const onSubmit = (data: any) => {
    console.log({ data });
    clearTimeSheetAction();
    formControl.reset();
    data.year = moment(data.month).format("YYYY").toString();
    data.month = moment(data.month).format("MM").toString();
    data.month =
      data.month.length < 2 && Number(data.month) <= 9
        ? `0${data.month}`
        : `${data.month}`;
    let temp: any = data.year + "-" + data.month;
    delete data.year;
    const payload: any = { month: temp };
    if (isworklist || isLogView) {
      payload.employee_id = data.employee_id;
      if (isLogView) setEmployeeId(data.employee_id);
    } else {
      payload.employee_id = user?.employee_detail.employee_id;
    }
    console.log("payload month : ", payload);
    setDefaultDate(`${payload.month}-01`);

    Submit(payload);
    setExpanded(false);
    reset({ time_tab: [] });
  };
  useEffect(() => {
    if (!!worklist) {
      clearTimeSheetAction();
      onSubmit({ employee_id: worklist.employee_id, month: worklist.month });
      setExpanded(false);
    }
    return () => {
      !!ClearTimingSheetFromWorklistAction &&
        ClearTimingSheetFromWorklistAction();
    };
  }, [isworklist]);

  const onSubmitForm = (data: any) => {
    console.error("data : ", data);
    const isHalfDay = tempeventsData.find(
      (self) =>
        moment(self.date).format("YYYY-MM-DD") === selectedDate &&
        Number(self?.leaveCount) === 0.5
    );
    console.log("isHalfDayrrr : ", isHalfDay);
    const limit = Boolean(isHalfDay) ? 4 : 8;
    console.log("limit : ", limit);

    const keyMapper: any = {};
    let cIndex = 0;
    let flag = false;
    let minusFlag = false;
    for (const payload of data.time_tab) {
      if (Number(payload?.work_hours) < 0) {
        minusFlag = true;

        break;
      }
      console.log("key", payload);

      if (
        moment(payload.date).format("YYYY-MM-DD") === selectedDate &&
        keyMapper[payload?.date]
      ) {
        keyMapper[payload.date] =
          Number(keyMapper[payload.date]) + Number(payload.work_hours);
        if (keyMapper[payload.date] > limit) {
          console.log("error show : ", keyMapper[payload.date]);
          flag = true;

          // setFocus(`time_tab[${cIndex}].work_hours`);
          // setError(
          //   `time_tab.[0].work_hours`,
          //   { type: "focus" },
          //   { shouldFocus: true }
          // );
          break;
        }
      } else {
        if (moment(payload.date).format("YYYY-MM-DD") === selectedDate)
          keyMapper[payload.date] = Number(payload.work_hours);
      }
      cIndex++;
    }
    console.log("keymapper : ", keyMapper);

    if (flag) {
      // formControl.reset();
      enqueueSnackbar(`Cannot be more than ${limit} hours`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (minusFlag) {
      enqueueSnackbar("invalid input", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    setMutate(false);
    console.log("keyMapper map : ", keyMapper);
    const expenses: any[] = [];
    if (data.monthly_expenses) {
      data.monthly_expenses.forEach((self: any, index: number) => {
        if (self?.field_one_name) {
          expenses.push({
            field: self.field_one_name,
            expense: +self.field_one_val,
            comments: self?.field_one_comment ? self?.field_one_comment : "",
            project_id: self.project_id,
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_one_id,
          });
        }
        if (self?.field_two_name) {
          expenses.push({
            field: self.field_two_name,
            expense: +self.field_two_val,
            project_id: self.project_id,
            comments: self?.field_two_comment ? self?.field_two_comment : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),

            monthly_expenses_id: self?.monthly_expenses_two_id,
          });
        }
        if (self?.field_three_name) {
          expenses.push({
            field: self.field_three_name,
            expense: +self.field_three_val,
            project_id: self.project_id,
            comments: self?.field_three_comment
              ? self?.field_three_comment
              : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_three_id,
          });
        }
        if (self?.field_four_name) {
          expenses.push({
            field: self.field_four_name,
            expense: +self.field_four_val,
            project_id: self.project_id,
            comments: self?.field_four_comment ? self?.field_four_comment : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_four_id,
          });
        }
        if (self?.field_five_name) {
          expenses.push({
            field: self.field_five_name,
            expense: +self.field_five_val,
            project_id: self.project_id,
            comments: self?.field_five_comment ? self?.field_five_comment : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_five_id,
          });
        }
        if (self?.field_six_name) {
          expenses.push({
            field: self.field_six_name,
            expense: +self.field_six_val,
            project_id: self.project_id,
            comments: self?.field_six_comment ? self?.field_six_comment : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_six_id,
          });
        }
        if (self?.field_seven_name) {
          expenses.push({
            field: self.field_seven_name,
            expense: +self.field_seven_val,
            project_id: self.project_id,
            comments: self?.field_seven_comment
              ? self?.field_seven_comment
              : "",
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_seven_id,
          });
        }
        if (self?.field_eight_name) {
          expenses.push({
            field: self.field_eight_name,
            expense: Number(self.field_eight_val),
            comments: self?.field_eight_comment
              ? self?.field_eight_comment
              : "",
            project_id: self.project_id,
            month_year: moment(data.time_tab[0].date).format("YYYY-MM"),
            monthly_expenses_id: self?.monthly_expenses_eight_id,
          });
        }
      });
    }

    const time_sheet: any[] = [];
    data.time_tab.forEach((self: any, index: number) => {
      time_sheet.push({
        project_id: self.project_id,
        time_sheet_id: self.time_sheet_id,
        work_hours: Number(self.work_hours),
        date: self.date,
      });
    });
    const payload = {
      employee_id: isworklist
        ? worklist?.employee_id
        : !!isLogView
        ? employeeId
        : user?.employee_detail.employee_id,
      time_sheet: time_sheet,
      monthly_expenses: expenses,
      month: moment(data.time_tab[0].date).format("YYYY-MM"),
    };
    // console.log("payload : ", payload);
    const formPayload = {
      formData: payload,
      enqueueSnackbar,
    };
    console.log("update payload 22: ", formPayload);

    UpdateMonthlyTimeSheetAction(formPayload);
    setTimeout(() => {
      setMutate(true);
    }, 4000);
    setShowApprobvalBtn(true);
  };
  const handleApprovalRequest = () => {
    console.log("parent hit");
    initiateTimesheetAction({
      employee_id: user?.employee_detail.employee_id,

      month: moment(timing_sheet_list?.emp_project[0]?.time_tab[0].date).format(
        "YYYY-MM"
      ),
      enqueueSnackbar,
    });
  };
  useEffect(() => {
    return () => {
      clearTimeSheetAction();
      formControl.reset();
    };
  }, []);

  // useEffect(() => {
  //   if (isLogView) {
  //     setTimeout(() => {
  //       searchByNameEmpAction(searchEmpNameQuery);
  //     }, 3000);
  //   }
  // }, [searchEmpNameQuery, isLogView]);
  console.log("searchEmpNameQuery ", searchEmpNameQuery);

  useEffect(() => {
    console.log("getValues employee : ", getValues("employee_id"));
  }, [watch("employee_id")]);

  const handleSearch = (searchVal: string): any => {
    return searchByNameEmpAction(searchVal);
  };
  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log(searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal);
    }, 2000);
  };

  useEffect(() => {
    handleSearch("");
  }, []);

  console.log(
    " timing_sheet_list.prject_expence : ",
    timing_sheet_list?.prject_expence
  );
  useEffect(() => {
    if (
      !!timing_sheet_list?.emp_project &&
      Array.isArray(timing_sheet_list?.emp_project)
    ) {
      const projectIds: number[] = timing_sheet_list?.emp_project.map(
        (self) => self.project_id
      );
      setSelectedProjectsIds([...projectIds]);
    }
  }, [timing_sheet_list]);

  const saveBtnClick = () => {
    if (saveButtonRef?.current) {
      // setMutate(false);
      saveButtonRef.current?.click();
      // setTimeout(() => {
      //   setMutate(true);
      // }, 3000);
    }
  };
  return (
    <>
      <AllotedProjectsModal
        open={allotedProjectModalOpen}
        handleClose={() => setAllotedProjectModalOpen(false)}
        projects={timing_sheet_list?.emp_project}
        selectedProjectsIds={selectedProjectsIds}
        setSelectedProjectsIds={setSelectedProjectsIds}
      />{" "}
      {!isworklist ? (
        <Paper
          elevation={3}
          sx={{
            marginTop: matchesMobileView ? 1 : 3,
            marginInline: matchesMobileView ? 0.5 : 5,
          }}
        >
          <Grid
            container
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Accordion
              disableGutters
              elevation={0}
              expanded={expanded === "panel"}
              onChange={handleChange1("panel")}
              style={{
                backgroundColor: "transparent",
                width: "100%",
                borderRadius: 5,
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: 0, margin: 0 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    backgroundColor: "#42a5f5",
                    justifyContent: "space-between",
                    marginTop: -2,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                  onClick={() => handleChange1("panel1")}
                >
                  <Grid
                    justifyContent={"center"}
                    alignSelf={"center"}
                    style={{ paddingLeft: "20px" }}
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#fff",

                        textAlign: "center",
                      }}
                    >
                      Filter
                    </Typography>
                  </Grid>
                  <Grid
                    justifyContent={"flex-end"}
                    alignContent={"flex-end"}
                    style={{ marginRight: "2%" }}
                  >
                    <Button
                      variant="text"
                      endIcon={<FilterListIcon />}
                      sx={{ color: "#fff" }}
                      onClick={() => handleChange1("panel1")}
                    ></Button>
                  </Grid>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0 }}>
                <Grid
                  container
                  justifyContent={"flex-start"}
                  sx={{
                    mx: 0,
                    px: 0,
                  }}
                >
                  <Grid item xs={matchesMobileView ? 12 : isLogView ? 3.5 : 6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        sm={5}
                        justifyContent="space-between"
                        alignItems="start"
                        sx={{}}
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            width: "100%",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Select Month
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        sx={{ marginLeft: matchesMobileView ? 1 : -1 }}
                        className="datepicker"
                      >
                        <Controller
                          control={control}
                          name={"month"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <DatePicker
                              views={["year", "month"]}
                              inputFormat="MM-YYYY"
                              value={value}
                              InputProps={{
                                onBlur: onBlur,
                                size: "small",
                                style: {
                                  width: "125%",
                                },
                              }}
                              onChange={(newValue) => {
                                onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} helperText={null} />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {isLogView ? (
                    <Grid
                      item
                      xs={matchesMobileView ? 12 : isLogView ? 3.5 : 6}
                    >
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          sm={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              width: "100%",
                              // textAlign: "center"
                            }}
                          >
                            Select Employee
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          sx={{ marginLeft: -1 }}
                          className="datepicker"
                        >
                          <Controller
                            control={control}
                            name={"employee_id"}
                            defaultValue={""}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={employeeList}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"employee_id"}
                                label={"employee_name"}
                                onInputChange={(e: any) => {
                                  if (e?.target?.value) GetCall(e.target.value);
                                }}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Search Employee"}
                                value={!!value ? value : ""}
                                errors={!!errors["employee_id"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    sm={12}
                    px={matchesMobileView ? 5 : 0}
                    marginTop={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        // marginRight: 10,
                      }}
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      View
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Paper>
      ) : (
        <></>
      )}
      {!!timing_sheet_list && (
        <>
          <form onSubmit={formControl.handleSubmit(onSubmitForm)}>
            <Grid container direction={"row"} px={matchesMobileView ? 0.5 : 2}>
              {!!timing_sheet_list &&
              timing_sheet_list.prject_expence &&
              Array.isArray(timing_sheet_list.prject_expence) &&
              timing_sheet_list.prject_expence.length > 0 ? (
                <Grid
                  item
                  sm={12}
                  sx={{
                    backgroundColor: "#42a5f5",
                    marginTop: 3,
                    marginInline: matchesMobileView ? 0 : 3,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    color: "#fff",
                    paddingInline: matchesMobileView ? 0 : 3,
                    paddingVertical: 5,
                    fontWeight: 600,

                    width: "100%",

                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    direction={"row"}
                    width="100%"
                    px={matchesMobileView ? 0.5 : 2}
                    mx={0}
                  >
                    <Grid
                      item
                      xs={3}
                      sx={{
                        width: "35%",
                        padding: "10px 0px",
                        borderRight: "2px solid white",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Project Name
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        width: "85%",
                        padding: "10px 0px 10px 40px",
                        fontWeight: 600,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Project Expences
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}

              {!!timing_sheet_list &&
                timing_sheet_list.prject_expence.map(
                  (m, index: number, array) => (
                    <Grid item xs={12}>
                      <ProjectExpences
                        indexVal={index}
                        control={formControl.control}
                        errors={formControl.formState.errors}
                        setValue={formControl.setValue}
                        getValues={formControl.getValues}
                        reset={formControl.reset}
                        m={m}
                        notEditable={notEditable}
                        status={status}
                        setStatus={setStatus}
                        isworklist={isworklist}
                        notShow={notShow}
                        totalLength={timing_sheet_list.prject_expence.length}
                        selectedProjectsIds={selectedProjectsIds}
                      />
                    </Grid>
                  )
                )}
              {(!notShow && isworklist) ||
              (!["approved", "initiate"].includes(status.toLowerCase()) &&
                !notEditable) ? (
                <Grid
                  width={"100%"}
                  container
                  justifyContent="center"
                  alignItems={"center"}
                  margin={2}
                >
                  <Button
                    type="submit"
                    ref={saveButtonRef}
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 4,
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    <b>Save</b>
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Grid container direction={"row"} px={matchesMobileView ? 0 : 2}>
              <Grid item xs={12} px="0">
                <TimeSheetTab
                  selectTab={showTabView}
                  setSelectTab={setShowTabView}
                />
              </Grid>
            </Grid>
            {showTabView === "showall" ? (
              <Box>
                {!!timing_sheet_list?.emp_project &&
                timing_sheet_list?.emp_project?.length !== 0 ? (
                  <Grid
                    container
                    direction={"row"}
                    px={matchesMobileView ? 0 : 2}
                  >
                    {/* {!!timing_sheet_list &&
            timing_sheet_list.emp_project.map((n) => ( */}

                    <Grid item xs={12} px="0" mx="">
                      <EmployeeProjectActivity
                        setAllotedProjectModalOpen={setAllotedProjectModalOpen}
                        setError={formControl.setError}
                        control={formControl.control}
                        errors={formControl.formState.errors}
                        reset={formControl.reset}
                        handleSubmit={formControl.handleSubmit}
                        emp_project={
                          !!timing_sheet_list
                            ? timing_sheet_list?.emp_project
                            : []
                        }
                        setValue={formControl.setValue}
                        notEditable={notEditable}
                        initiateTimesheetAction={initiateTimesheetAction}
                        handleApprovalRequest={handleApprovalRequest}
                        showApprovalBtn={showApprovalBtn}
                        setShowApprobvalBtn={setShowApprobvalBtn}
                        status={status}
                        setStatus={setStatus}
                        isworklist={isworklist}
                        notShow={notShow}
                        selectedProjectsIds={selectedProjectsIds}
                        setSelectedProjectsIds={setSelectedProjectsIds}
                      />
                    </Grid>
                    {/* ))} */}
                  </Grid>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <CalendarwiseTimeSheet
                defaultDate={defaultDate}
                setAllotedProjectModalOpen={setAllotedProjectModalOpen}
                setError={formControl.setError}
                control={formControl.control}
                errors={formControl.formState.errors}
                reset={formControl.reset}
                handleSubmit={formControl.handleSubmit}
                emp_project={
                  !!timing_sheet_list ? timing_sheet_list?.emp_project : []
                }
                setValue={formControl.setValue}
                notEditable={notEditable}
                initiateTimesheetAction={initiateTimesheetAction}
                handleApprovalRequest={handleApprovalRequest}
                showApprovalBtn={showApprovalBtn}
                setShowApprobvalBtn={setShowApprobvalBtn}
                status={status}
                setStatus={setStatus}
                isworklist={isworklist}
                notShow={notShow}
                getValues={getValues}
                apiLoading={apiLoading}
                mutate={mutate}
                setMutate={setMutate}
                tempeventsData={tempeventsData}
                setTempEventsData={setTempEventsData}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
              />
            )}
            {!timing_sheet_list?.emp_project ||
            timing_sheet_list?.emp_project?.length === 0 ? (
              <Typography textAlign="center" mt={5} color="gray">
                No project Allocated
              </Typography>
            ) : (
              <></>
            )}
          </form>
        </>
        // </Paper>
      )}
    </>
  );
}

export default LogActivityView;

interface LogActivityViewProps {
  Submit?: any;
  timing_sheet_list?: TimingSheetList;
  user?: userDetails;
  UpdateMonthlyTimeSheetAction?: any;
  clearTimeSheetAction?: any;
  notEditable?: boolean;
  initiateTimesheetAction?: any;
  isworklist?: boolean;
  ClearTimingSheetFromWorklistAction?: any;
  goToTimingSheetFromWorklistAction?: any;
  worklist?: IWorkSheet;
  isLogView?: boolean;
  employeeList: IEmployeeList[];
  searchByNameEmpAction?: any;
  notShow?: boolean;
  apiLoading?: number;
}
