import {
  Button,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
  Handle,
} from "reactflow";
import "reactflow/dist/style.css";
import { OrganizationALlList } from "../../../models/organizationModels";
import CustomeNode from "./CustomeNode";
const nodeTypes = { CustomeNode: CustomeNode };
function OrganizationListView({
  organization_list,
}: OrganizationListViewProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [level, Setlevel] = useState<any[]>([]);

  useEffect(() => {
    const CreateNode = async () => {
      if (!!organization_list && organization_list.length > 0) {
        let node: any[] = [];
        let edge: any[] = [];
        for await (const m of organization_list) {
          const { x, y }: any = await CreateLevel(m);
          node.push({
            id: m.organization_id.toString(),
            position: {
              x: !!x ? x : 0,
              y: !!y ? y : 0,
            },
            data: m,

            type: "CustomeNode",
          });
          if (!!m.organization_parent_id) {
            edge.push({
              id: Math.random().toString(),
              target: m.organization_id.toString(),
              source: m.organization_parent_id.toString(),
              type: "step",
              style: { strokeWidth: 4 },
            });
          }
        }
        setEdges(edge);
        setNodes(node);
      }
    };
    if (!!organization_list && organization_list.length > 0) {
      Setlevel([]);
      CreateNode();
    }
  }, [organization_list]);

  const CreateLevel = async (m: OrganizationALlList) => {
    let pos: any = {};
    if (!m.organization_parent_id) {
      if (level.length > 0) {
        pos.x = !!level[0] ? level[0].x + 450 : 450;
        pos.y = !!level[0] ? level[0].y : 0;
        let upos = level;
        upos[0] = pos;
        Setlevel(upos);
      } else {
        pos.x = 0;
        pos.y = 0;
        let upos: any[] = [pos];
        Setlevel(upos);
      }
    } else {
      let lev = await levelpos(m.organization_parent_id, 1);
      if (!!level[lev]) {
        pos = level[lev];
        pos.x = pos.x + 450;
        let upos = level;
        upos[lev] = pos;
        Setlevel(upos);
      } else {
        pos.x = 0;
        pos.y = 220 * lev;
        let upos: any[] = level;
        upos[lev] = pos;
        Setlevel(upos);
      }
    }
    return pos;
  };
  function levelpos(x: number, level: number): any {
    let par = organization_list.find((m) => m.organization_id == x);
    if (!par?.organization_parent_id) {
      return level + 1;
    } else {
      return level + levelpos(par.organization_parent_id, level);
    }
  }
  const OnClickNode = (n: any) => {
    navigate(`/organization/add-organization-new/${n.data.organization_id}`);
    // console.log(n.data);
  };

  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  return (
    <Paper
      elevation={2}
      sx={{
        marginX: 5,
        marginTop: 3,
        marginBottom: 1.4,
        padding: 2,
        height: "75%",
        paddingBottom: 5,
        // background: "rgb(213,229,255)",
        background:
          "linear-gradient(131deg, rgba(239,249,255,0.9836309523809523) 10%, rgba(255,255,255,1) 99%)",
        border: "4px solid #ffff",
      }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"start"}
        alignItems={"center"}
      >
        <Grid item xs={3}>
          <Button
            onClick={() => navigate("/organization/add-organization-new/0")}
            size="medium"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add Organization
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "center",
            paddingBottom: 10,
          }}
        >
          <Typography
            style={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#50535A",
              fontFamily: "Poppins, sans-serif",
              marginBottom: 10,
            }}
          >
            Organization Hierarchy
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid style={{ height: "100%" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          minZoom={0.5}
          onNodeClick={(event: React.MouseEvent, node: any) =>
            OnClickNode(node)
          }
        ></ReactFlow>
      </Grid>
    </Paper>
  );
}

export default OrganizationListView;

interface OrganizationListViewProps {
  organization_list: OrganizationALlList[];
}
