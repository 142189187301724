import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";

const AllowLeaveApply: FC<IAllowedApply> = ({
  open,
  setOpen,
  aggreFunc,
  setClose,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Leave Application Request"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit this Leave Application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={setClose}>No</Button>
          <Button onClick={aggreFunc} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface IAllowedApply {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  aggreFunc: any;
  setClose: any;
}
export default AllowLeaveApply;
