import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  NativeSelect,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { ProjectList } from "../../../models/customerModel";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  AllDepartmentList,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import { EmpList, IEmployeePDFList } from "../../../models/employeeModel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { baseUrl, environment } from "../../../environment";
import { IEmployeeList } from "../../../models/timingSheetModel";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";
import ResetPasswordAlert from "./ResetPasswordAlert";

const FileDownload = require("js-file-download");
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;

const EmployeeListview = ({
  employee_type_list,
  project_list,
  department_list,
  employee_list,
  filterData,
  Clear,
  pageindex,
  pagesize,
  rowperpage,
  total_size,
  SetpageSize,
  Setpageindex,
  control,
  handleSubmit,
  reset,
  searchByNameEmpAction,
  searchEmployeeList,
  getValues,
  accessToken,
  dataPayload,
  user,
  resetpasswordrequestAction,
}: EmployeeListviewProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<EmpList[]>([]);
  const styles = {
    marginRight: "50px",
  };
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Mobile No.",
    "Joining On",
    "Employee Type",
    "Department",
    "Project",

    //"Action",
  ];

  if (
    !!user &&
    environment.global_veriable.superAdminLevel.includes(user.designation)
  ) {
    columns.push("Action");
  }
  const [expanded, setExpanded] = useState<string | false>(false);
  const [employeePDFList, setEmployeePDFList] = useState<any[]>([]);
  const [resetEmployeeId, setResetEmployeeId] = useState<string>("");
  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const filterDatas = (data: any) => {
    filterData(data);
    //handleClose();
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!employee_list
      ? employee_list.filter((row: EmpList) => {
        return (
          row?.employee_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_id
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_mob_no
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_dob?.toLowerCase().includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!employee_list) {
      cancelSearch();
    }
  }, [employee_list]);
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };

  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log(searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, "");
    }, 2000);
  };

  useEffect(() => {
    searchByNameEmpAction("", "");
  }, []);

  // get Employee list in excel
  const getEmployeeReport = () => {
    Axios({
      url: baseUrl + environment.urls.getemployeelistbyexcel,
      method: "POST",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/vnd.ms-excel",
      },
      data: dataPayload,
    })
      .then(async (response) => {
        if (response.status == 200) {
          await FileDownload(response.data, "employee-report.xlsx");
        } else {
          enqueueSnackbar(
            !!response?.data?.message
              ? response?.data?.message
              : "Something went wrong...",
            {
              variant: "warning",
            }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error?.data?.message
            ? error?.data?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };
  const resetPasswordAction = () => {
    resetpasswordrequestAction(resetEmployeeId, enqueueSnackbar);
    setResetDialogOpen(false);
  };
  const resetPaswordCancel = () => {
    setResetEmployeeId("");
    setResetDialogOpen(false);
  };
  const openResetPasswordModal = (empId: string) => {
    setResetEmployeeId(empId);
    setResetDialogOpen(true);
  };

  return (
    <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <ResetPasswordAlert
        open={resetDialogOpen}
        handleAccept={resetPasswordAction}
        handleClose={resetPaswordCancel}
      />

      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} paddingBottom={3}>
          <Accordion
            disableGutters
            elevation={1}
            expanded={expanded === "panel1"}
            onChange={handleChange1("panel1")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "4%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid style={{ margin: 5 }}>
                <form>
                  <Grid
                    container
                    direction={"row"}
                  // padding={2}
                  // justifyContent={"center"}
                  // alignItems={"center"}
                  // paddingLeft={"6%"}
                  // marginBottom={"-2%"}
                  // marginTop={"-3%"}
                  >
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"start"}
                      paddingTop={0}
                    >
                      <Grid container direction={"row"} margin={1}>
                        <Grid item xs={4} alignItems="center">
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee ID
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"employee_id"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    //label={"Employee Id"}
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee ID"}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee Name
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"employee_id"}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={searchEmployeeList}
                                    //   readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"employee_id"}
                                    label={"employee_name"}
                                    onInputChange={(e: any) => {
                                      if (e?.target?.value)
                                        GetCall(e.target.value);
                                    }}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Employee"}
                                    value={!!value ? value : ""}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee Type
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5.5}
                              style={{ marginLeft: -5, marginTop: -5 }}
                            >
                              <Controller
                                control={control}
                                name={"employee_type_id"}
                                defaultValue={""}
                                rules={{ required: false }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={employee_type_list}
                                    //readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"employee_type_id"}
                                    label={"employee_type_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Employee Type"}
                                    value={!!value ? value : ""}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"} margin={1}>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee Mob No.
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"employee_mob_no"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee Mob No."}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee Email
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"employee_email"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee Email"}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Project
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5.5}
                              style={{ marginLeft: -5, marginTop: -5 }}
                            >
                              <Controller
                                control={control}
                                name={"project_id"}
                                defaultValue={""}
                                rules={{ required: false }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={project_list}
                                    //readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"project_id"}
                                    label={"project_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Project"}
                                    value={!!value ? value : ""}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"} margin={1}>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee ESIC No.
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"esic_no"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee ESIC No."}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Department
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5.5}
                              style={{ marginLeft: -5, marginTop: -5 }}
                            >
                              <Controller
                                control={control}
                                name={"department_id"}
                                defaultValue={""}
                                rules={{ required: false }}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <Autocompletes
                                    hasInfo={false}
                                    option={department_list}
                                    //readOnly={!!department ? edit_flag : !edit_flag}
                                    keys={"department_id"}
                                    label={"department_name"}
                                    onBlur={onBlur}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Search Department"}
                                    value={!!value ? value : ""}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee UAN No.
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"uan_no"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"text"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee UAN No."}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"} margin={1}>
                        <Grid item xs={4}>
                          <Grid
                            container
                            direction={"row"}
                            wrap={"nowrap"}
                            alignItems="center"
                          >
                            <Grid item xs={5.5}>
                              <Typography
                                style={{
                                  fontWeight: "500",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Employee DOB.
                              </Typography>
                            </Grid>
                            <Grid item xs={5.5}>
                              <Controller
                                control={control}
                                name={"employee_dob"}
                                rules={{ required: false }}
                                defaultValue={""}
                                render={({
                                  field: { onBlur, value, onChange },
                                }) => (
                                  <TextField
                                    type={"date"}
                                    id="standard-required"
                                    variant="outlined"
                                    className={"smaller"}
                                    style={{ width: "100.9%" }}
                                    onBlur={onBlur}
                                    size="small"
                                    value={!!value ? value : ""}
                                    onChange={(value: any) => onChange(value)}
                                    placeholder={"Employee DOB."}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={8}>
                            <Grid
                              container
                              direction={"row"}
                              wrap={"nowrap"}
                              alignItems="center"
                            ></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end ",
                      marginRight: "2rem",
                      //marginRight: "0px",
                      marginTop: "-2rem",
                      marginBottom: 1,
                    }}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={() => (Clear(), reset())}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Clear
                    </Button>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      onClick={handleSubmit(filterDatas)}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      filter
                    </Button>
                  </Box>
                </form>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={3}>
          <Button
            size="medium"
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
            onClick={getEmployeeReport}
          >
            Download
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>

        <Grid item xs={12} marginTop={2} ref={pdfRef}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
            //   style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows.map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.employee_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        <Link
                          onClick={() =>
                            navigate(
                              `/employment/manage-profile/${row.employee_id}`
                            )
                          }
                        >
                          {row.employee_id}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.employee_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.employee_mob_no}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {moment.utc(row.created_on).format("DD-MM-yyyy")}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.employee_type_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.department_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        {row.project_name !=
                          environment.global_veriable.ho_project_name
                          ? row.project_name
                          : ""}
                      </StyledTableCell>
                      {!!user &&
                        environment.global_veriable.superAdminLevel.includes(
                          user.designation
                        ) ? (
                        <StyledTableCell align="left" key={columns[7]}>
                          <Tooltip
                            title="Reset Password"
                            onClick={() =>
                              openResetPasswordModal(row.employee_id)
                            }
                          >
                            <IconButton>
                              <LockResetIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      ) : (
                        <></>
                      )}

                      {/* <StyledTableCell align="left" key={columns[7]}>
                        <Tooltip
                          title="View"
                          onClick={() =>
                            navigate(`/employment/manage-profile/${row.employee_id}`)
                          }
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems={"center"} justifyContent="end" color={"black"}>
            <Grid xs={2} textAlign="right">
              Rows per page:
            </Grid>
            <Grid xs={1} textAlign="center">
              <NativeSelect
                value={pagesize.toString()}
                onChange={(event) => SetpageSize(+event.target.value)}
              >
                {rowperpage.map((p) => (
                  <option key={p} value={p} onChange={(value: any) => value(p)}>
                    {p}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                onClick={() => Setpageindex(pageindex - 1)}
                color="primary"
                size="small"
                disabled={pageindex <= 0}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography>
                {pageindex * pagesize + 1} -{" "}
                {!!total_size && total_size <= (pageindex + 1) * pagesize
                  ? total_size
                  : (pageindex + 1) * pagesize}
                {" of "}
                {total_size}
              </Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                color="primary"
                size="small"
                onClick={() => Setpageindex(pageindex + 1)}
                disabled={
                  (!!total_size && total_size <= (pageindex + 1) * pagesize) ||
                  total_size === 0
                }
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmployeeListview;

interface EmployeeListviewProps {
  employee_type_list: EmployeeTypeList[];
  project_list: ProjectList[];
  department_list: AllDepartmentList[];
  employee_list: EmpList[];
  filterData?: any;
  Clear?: any;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  control?: any;
  handleSubmit?: any;
  reset?: any;
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  getValues: any;
  accessToken?: string;
  dataPayload: any;
  resetpasswordrequestAction?: any;
  user: userDetails | undefined;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
