import {
  Paper,
  Grid,
  Button,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetAllRoleMasterList } from "../../../models/userManagementModel";

const RoleMasterView = ({all_role_master}:RoleMasterViewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Role Name",
    "Description",
    // "Created By",
    "Created On",
    // "Update By", 
    // "Updated On",
    "Action",
  ];
  const [rows, setRows] = useState<GetAllRoleMasterList[]>([]);
  const [searched, setSearched] = useState<string>("");
    const requestSearch = (searchedVal: string) => {
      const filteredRows = !!all_role_master
        ? all_role_master.filter((row: GetAllRoleMasterList) => {
            return (
              row?.role_name
                ?.toLowerCase()
                .includes(searchedVal.toLowerCase()) 
            );
          })
        : [];
      setRows(filteredRows);
    };
    const cancelSearch = () => {
      setSearched("");
      requestSearch("");
    };
    useEffect(() => {
      if (!!all_role_master) {
        cancelSearch();
      }
    }, [all_role_master]);
  return (
    <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={3}>
          <Button
            onClick={() => navigate("/usermanagement/add-role/0")}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            // onChange={(e: any) => (
            //   requestSearch(e.target.value), setSearched(e.target.value)
            // )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton
                    color="primary"
                    //    onClick={() => cancelSearch()}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid marginTop={2}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            //   style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.role_id}
              >
                <StyledTableCell align="left" key={columns[0]}>
                  {row.role_name}
                </StyledTableCell>
                <StyledTableCell align="left" key={columns[1]}>
                  {row.role_desc}
                </StyledTableCell>
                {/* <StyledTableCell align="left" key={columns[2]}>
                  {row.created_by}
                </StyledTableCell> */}
                <StyledTableCell align="left" key={columns[2]}>
                  {row.created_on
                        ? moment(row.created_on)
                            .format("DD/MM/YYYY ")
                            .toUpperCase()
                        : "N/A"}
                </StyledTableCell>
                {/* <StyledTableCell align="left" key={columns[4]}>
                  {row.updated_by}
                </StyledTableCell> */}
                {/* <StyledTableCell align="left" key={columns[3]}>
                  {row.updated_on
                        ? moment(row.updated_on)
                            .format("DD/MM/YYYY ")
                            .toUpperCase()
                        : "N/A"}
                </StyledTableCell> */}
                <StyledTableCell align="left" key={columns[3]}>
                  <Tooltip
                        title="View"
                        onClick={() =>
                          navigate(
                            `/usermanagement/add-role/${row.role_id}`
                          )
                        }
                      >
                        <IconButton>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                  {/* <Tooltip title="Edit">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip> */}
                  {/* <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            color="error"
                            sx={{ cursor: "pointer" }}
                            onClick={() => (
                              setdeleteFlag(true),
                              SetdeletePayload({
                                department_id: row.department_id,
                                organization_id: row.organization_id,
                              })
                            )}
                          />
                        </IconButton>
                      </Tooltip> */}
                </StyledTableCell>
              </StyledTableRow>
              ))}
              {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={4}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </Grid>
    </Paper>
  );
};

export default RoleMasterView;
interface RoleMasterViewProps{
    all_role_master: GetAllRoleMasterList[];
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
