import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Slide,
  Grid,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TextareaAutosize,
  TextField,
  Link,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Controller, useForm } from "react-hook-form";
import {
  AllDesignationList,
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { environment } from "../../../environment";
import {
  PendingApprovalList,
  UpdateAttendaceStatusPayload,
} from "../../../models/attendanceModel";
import {
  PromotionApprovalList,
  ViewPromotion,
} from "../../../models/promotionModel";
import Autocompletes from "../../../library/AutoComplete";
import { EmpList } from "../../../models/employeeModel";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import dayjs, { Dayjs } from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
const PromotionApproveSection = ({
  open,
  setOpen,
  approvalList,
  SetapprovalList,
  FinalApprovedSubmit,
  DomValue,
  setfinalFlag,
  setsubmitFlaga,
  salary_structure_list,
  SalaryComponentEditAction,
  designation_list,
  salary_structure,
  view_promotion,
  pending_promtions,
  GetAllDesignationAction,
  GetAllSalaryStructureAction,
  RemoveEmpPromotion,
}: PromotionApproveSectionProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation",
    "Salary Structure Name",
    "Action",
  ];
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    GetAllDesignationAction({ organization_id: null });
    GetAllSalaryStructureAction({ organization_id: null });
  }, []);
  const onApproveSubmit = (data: any) => {
    console.log("data", data);

    let component: any = {};
    if (
      !!view_promotion &&
      view_promotion.length > 0 &&
      !!view_promotion[0].components &&
      view_promotion[0].components.length > 0
    ) {
      for (let item of view_promotion[0].components) {
        if (!item.calculation) {
          component = {
            ...component,
            [item.compent_id]: +data[item.compent_id],
          };
        }
      }
    }

    let prom_start_month = new Date(data.promotion_start_date).getMonth() + 1;
    let prom_start_year = new Date(data.promotion_start_date).getFullYear();
    let prom_start_date = `${prom_start_year}-${prom_start_month}-01`;

    let rev_start_month = new Date(data.revision_start_date).getMonth() + 1;
    let rev_start_year = new Date(data.revision_start_date).getFullYear();
    let rev_start_date = `${rev_start_year}-${rev_start_month}-01`;

    let end_prom = new Date(getValues("promotion_end_date"));
    let prom_end_date = new Date(
      end_prom.getFullYear(),
      end_prom.getMonth() + 1,
      0
    );

    let end_rev = new Date(data.revision_end_date);
    let rev_end_date = new Date(
      end_rev.getFullYear(),
      end_rev.getMonth() + 1,
      0
    );

    let payload: any = {
      promotion_end_date: !!getValues("promotion_end_date")
        ? moment(prom_end_date).format("YYYY-MM-DD")
        : null,
      promotion_start_date: !!getValues("promotion_start_date")
        ? prom_start_date
        : null,
      revision_start_date: !!getValues("revision_start_date")
        ? rev_start_date
        : null,
      revision_end_date: !!getValues("revision_end_date")
        ? moment(rev_end_date).format("YYYY-MM-DD")
        : null,
      promotion_salary_batch_id: view_promotion[0].batch_id,
      employee_id: view_promotion.map((m) => m.employee_id),
      batch_status: 2,
      designation_id: !!view_promotion[0].new_designation_id
        ? view_promotion[0].new_designation_id
        : null,
      promotion_status: !!view_promotion[0].promotion_status
        ? view_promotion[0].promotion_status
        : view_promotion[0].revision_status,
      empLifeEventId: !!pending_promtions &&
        pending_promtions.length > 0 && [
          pending_promtions.find(
            (f) => f.promotion_salary_batch_id === view_promotion[0].batch_id
          )?.empLifeEventId,
        ],
      salary_assignment: {
        salary_structure_id: data.salary_structure_id,
        components: component,
      },
    };
    console.log("payload...", payload);
    FinalApprovedSubmit(payload);
  };
  const onRejectSubmit = (data: any) => {
    console.log("data", data);

    let component: any = {};
    if (
      !!view_promotion &&
      view_promotion.length > 0 &&
      !!view_promotion[0].components &&
      view_promotion[0].components.length > 0
    ) {
      for (let item of view_promotion[0].components) {
        if (!item.calculation) {
          component = {
            ...component,
            [item.compent_id]: +data[item.compent_id],
          };
        }
      }
    }

    let prom_start_month = new Date(data.promotion_start_date).getMonth() + 1;
    let prom_start_year = new Date(data.promotion_start_date).getFullYear();
    let prom_start_date = `${prom_start_year}-${prom_start_month}-01`;

    let rev_start_month = new Date(data.revision_start_date).getMonth() + 1;
    let rev_start_year = new Date(data.revision_start_date).getFullYear();
    let rev_start_date = `${rev_start_year}-${rev_start_month}-01`;

    let end_prom = new Date(getValues("promotion_end_date"));
    let prom_end_date = new Date(
      end_prom.getFullYear(),
      end_prom.getMonth() + 1,
      0
    );

    let end_rev = new Date(data.revision_end_date);
    let rev_end_date = new Date(
      end_rev.getFullYear(),
      end_rev.getMonth() + 1,
      0
    );

    let payload: any = {
      promotion_end_date: !!getValues("promotion_end_date")
        ? moment(prom_end_date).format("YYYY-MM-DD")
        : null,
      promotion_start_date: !!getValues("promotion_start_date")
        ? prom_start_date
        : null,
      revision_start_date: !!getValues("revision_start_date")
        ? rev_start_date
        : null,
      revision_end_date: !!getValues("revision_end_date")
        ? moment(rev_end_date).format("YYYY-MM-DD")
        : null,
      promotion_salary_batch_id: view_promotion[0].batch_id,
      employee_id: view_promotion.map((m) => m.employee_id),
      batch_status: 4,
      designation_id: !!view_promotion[0].new_designation_id
        ? view_promotion[0].new_designation_id
        : null,
      promotion_status: !!view_promotion[0].promotion_status
        ? view_promotion[0].promotion_status
        : view_promotion[0].revision_status,
      empLifeEventId: !!pending_promtions &&
        pending_promtions.length > 0 && [
          pending_promtions.find(
            (f) => f.promotion_salary_batch_id === view_promotion[0].batch_id
          )?.empLifeEventId,
        ],
      salary_assignment: {
        salary_structure_id: data.salary_structure_id,
        components: component,
      },
    };
    console.log("payload...", payload);
    FinalApprovedSubmit(payload);
  };

  const ClearOnClose = () => {
    setOpen(false);
    setValue("employee_id", "");
    setValue("promotion_start_date", "");
    setValue("promotion_end_date", "");
    setValue("revision_start_date", "");
    setValue("revision_end_date", "");
    setValue("designation_id", "");
    setValue("salary_structure_id", "");
    reset();
  };
  useEffect(() => {
    if (
      !!view_promotion &&
      Array.isArray(view_promotion) &&
      view_promotion.length > 0 &&
      Array.isArray(view_promotion[0].components) &&
      !!view_promotion[0].components
    ) {
      setValue("designation_id", view_promotion[0].new_designation_id);
      setValue("promotion_start_date", view_promotion[0].promotion_start_date);
      setValue("promotion_end_date", view_promotion[0].promotion_end_date);
      if (view_promotion[0].components.length > 0) {
        setValue(
          "salary_structure_id",
          view_promotion[0].new_salary_structure_id
        );
        setValue("revision_start_date", view_promotion[0].revision_start_date);
        setValue("revision_end_date", view_promotion[0].revision_end_date);
        view_promotion[0].components.forEach((m) => {
          if (!m.calculation) {
            setValue(m.compent_id.toString(), m.componet_calculated_value);
          }
        });
      } else {
        setValue("salary_structure_id", null);
        setValue("revision_start_date", null);
        setValue("revision_end_date", null);
      }
    }
  }, [view_promotion]);

  const handleRevisionStartDate = (newVal: Date) => {
    const rev_start_date = new Date();
    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(
      getValues("revision_start_date")
    ).getTime();
    const diff = Math.abs(selectedDateTime - currentDateTime);
    let new_rev_date = new Date(diff).getMonth();

    if (new_rev_date >= environment.promotion_details.revision_start) {
      setValue("revision_start_date", view_promotion[0].revision_start_date);
      return enqueueSnackbar("You can not plan more than 04 months before", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else if (
      new Date(getValues("revision_start_date")).getMonth() + 1 <
      rev_start_date.getMonth() + 1
    ) {
      setValue("revision_start_date", view_promotion[0].revision_start_date);
      return enqueueSnackbar("You can not select this month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("revision_start_date", newVal);
    }
  };
  const handlePromotionStartDate = (newVal: Date) => {
    const rev_start_date = new Date();
    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(
      getValues("promotion_start_date")
    ).getTime();
    const diff = Math.abs(selectedDateTime - currentDateTime);
    let new_rpm_date = new Date(diff).getMonth();

    if (new_rpm_date >= environment.promotion_details.revision_start) {
      setValue("promotion_start_date", view_promotion[0].promotion_start_date);
      return enqueueSnackbar("You can not plan more than 04 months before", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else if (
      new Date(getValues("promotion_start_date")).getMonth() + 1 <
      rev_start_date.getMonth() + 1
    ) {
      setValue("promotion_start_date", view_promotion[0].promotion_start_date);
      return enqueueSnackbar("You can not select this month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("promotion_start_date", newVal);
    }
  };
  const RemoveEmpPromotionBatch = (data: any) => {
    let payload: any = {
      promotion_salary_batch_id:
        !!view_promotion &&
        view_promotion.length > 0 &&
        view_promotion[0]?.batch_id,
      promotion_revision_id: [data.promotion_revision_id],
    };
    RemoveEmpPromotion(payload);
    // console.log("payload: ", payload);
  };

  const handlePromotionEndDate = (newVal: Date) => {
    let now = new Date(getValues("promotion_start_date"));
    const selectedStartDate = new Date(
      getValues("promotion_start_date")
    ).getMonth();
    const selectedEndDate = new Date(
      getValues("promotion_end_date")
    ).getMonth();
    const selectedStartYear = new Date(
      getValues("promotion_start_date")
    ).getFullYear();
    const selectedEndYear = new Date(
      getValues("promotion_end_date")
    ).getFullYear();
    // let endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    if (
      selectedStartDate >= selectedEndDate &&
      selectedStartYear >= selectedEndYear
    ) {
      setValue("promotion_end_date", view_promotion[0].promotion_end_date);
      return enqueueSnackbar("You can not select before start month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("promotion_end_date", newVal);
    }
  };
  const handleRevisionEndDate = (newVal: Date) => {
    let now = new Date(getValues("revision_start_date"));
    const selectedStartDate = new Date(
      getValues("revision_start_date")
    ).getMonth();
    const selectedStartYear = new Date(
      getValues("revision_start_date")
    ).getFullYear();
    const selectedEndDate = new Date(getValues("revision_end_date")).getMonth();
    const selectedEndYear = new Date(
      getValues("revision_end_date")
    ).getFullYear();
    let endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    if (
      selectedStartDate >= selectedEndDate &&
      selectedStartYear >= selectedEndYear
    ) {
      setValue("revision_end_date", view_promotion[0].revision_end_date);
      return enqueueSnackbar("You can not select before start month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("revision_end_date", newVal);
    }
  };
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      minHeight: 20,
    },
  });
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={ClearOnClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#0060FF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Verify and Approve
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container height={"92%"} flexGrow={1} flexDirection={"row"}>
        <Grid
          item
          xs={4.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              margin: 2,
            }}
          >
            <Grid
              container
              flexDirection={"row"}
            >
              <Grid item xs={4} style={{ padding: 5 }}>
                <Typography style={{ fontWeight: "600" }}>
                  Project Name
                </Typography>
              </Grid>
              
            </Grid>
          </Paper>

          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              border: "1px solid #E0E0E0",
              margin: 2,
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!view_promotion &&
                    Array.isArray(view_promotion) &&
                    view_promotion.length > 0 &&
                    view_promotion.map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.employee_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          <Link
                            onClick={() =>
                              navigateTo(
                                `/employment/manage-profile/${row.employee_id}`
                              )
                            }
                          >
                            {row.employee_id}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.employee_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.designation_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {row.salary_stucture_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          <Tooltip
                            title="Remove"
                            onClick={() => RemoveEmpPromotionBatch(row)}
                          >
                            <IconButton color="error">
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid
          item
          xs={7.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          <div style={{ margin: 5, padding: 10 }}>
            <form>
              {!!view_promotion &&
                Array.isArray(view_promotion) &&
                view_promotion.length > 0 &&
                !!view_promotion[0].new_designation_id && (
                  <Paper
                    elevation={3}
                    sx={{ marginTop: 5, marginInline: 1, padding: 2 }}
                  >
                    <Grid container justifyContent={"flex-start"} mt={1.5}>
                      <Grid item xs={10}>
                        <Typography
                          style={{
                            fontWeight: "800",
                            fontSize: 18,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "4%",
                            paddingBottom: 5,
                          }}
                        >
                          Promotion Section
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <CustomWidthTooltip title="You need to select another designation, it should not same as the current designation.. ">
                          <IconButton color="default">
                            <InfoIcon />
                          </IconButton>
                        </CustomWidthTooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              New Designation
                            </Typography>
                          </Grid>
                          <Grid item xs={6.5} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"designation_id"}
                              rules={{ required: false }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={designation_list}
                                  //   readOnly={!!department ? edit_flag : !edit_flag}
                                  keys={"designation_id"}
                                  label={"designation_name"}
                                  onBlur={onBlur}
                                  onChange={(value: any) => onChange(value)}
                                  placeholder={"Search Designation"}
                                  value={!!value ? value : ""}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {!!watch("designation_id") && (
                        <>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              style={{ marginTop: 8, marginBottom: 10 }}
                              // width={"100%"}
                            >
                              <Grid
                                item
                                xs={5}
                                justifyContent="space-between"
                                alignItems="start"
                              >
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                    paddingLeft: "20%",
                                    // textAlign: "center"
                                  }}
                                >
                                  Promotion Start From
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                                <Controller
                                  control={control}
                                  name={"promotion_start_date"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DatePicker
                                      views={["year", "month"]}
                                      value={value}
                                      InputProps={{
                                        onBlur: onBlur,
                                        size: "small",
                                        sx: {
                                          width: "125%",
                                          marginLeft: 1,
                                        },
                                      }}
                                      onChange={
                                        (newValue) => (
                                          onChange(newValue),
                                          handlePromotionStartDate(newValue)
                                        )
                                        // setPromotionStartDate(newValue)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={null}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              style={{ marginTop: 8, marginBottom: 10 }}
                              // width={"100%"}
                            >
                              <Grid
                                item
                                xs={5}
                                justifyContent="space-between"
                                alignItems="start"
                              >
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                    paddingLeft: "20%",
                                    // textAlign: "center"
                                  }}
                                >
                                  Promotion End Date
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                                <Controller
                                  control={control}
                                  name={"promotion_end_date"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DatePicker
                                      views={["year", "month"]}
                                      value={value}
                                      InputProps={{
                                        onBlur: onBlur,
                                        size: "small",
                                        sx: {
                                          width: "125%",
                                          marginLeft: 1,
                                        },
                                      }}
                                      onChange={(newValue) => (
                                        onChange(newValue),
                                        // setPromotionEndDate(newValue)
                                        handlePromotionEndDate(newValue)
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={null}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Paper>
                )}
              {!!view_promotion &&
                Array.isArray(view_promotion) &&
                view_promotion.length > 0 &&
                Array.isArray(view_promotion[0].components) &&
                !!view_promotion[0].components &&
                view_promotion[0].components.length > 0 && (
                  <Paper
                    elevation={3}
                    sx={{ marginTop: 5, marginInline: 1, padding: 2 }}
                  >
                    <Grid container justifyContent={"flex-start"} mt={1.5}>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontWeight: "800",
                            fontSize: 18,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "4%",
                            paddingBottom: 5,
                          }}
                        >
                          Revision Section
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              New Salary Structure
                            </Typography>
                          </Grid>
                          <Grid item xs={6.5} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"salary_structure_id"}
                              rules={{ required: false }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <Autocompletes
                                  hasInfo={false}
                                  option={
                                    !!salary_structure_list &&
                                    salary_structure_list.length > 0
                                      ? salary_structure_list
                                      : []
                                  }
                                  //   readOnly={!!department ? edit_flag : !edit_flag}
                                  keys={"salary_structure_id"}
                                  label={"salary_stucture_name"}
                                  onBlur={onBlur}
                                  // readOnly={readOnly}
                                  disable={true}
                                  onChange={(value: any) => (
                                    onChange(value),
                                    !!value &&
                                      SalaryComponentEditAction({
                                        salary_structure_id: +value,
                                      })
                                  )}
                                  placeholder={"Search Salary Structure"}
                                  value={!!value ? value : ""}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {!!watch("salary_structure_id") && (
                        <>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              style={{ marginTop: 8, marginBottom: 10 }}
                              // width={"100%"}
                            >
                              <Grid
                                item
                                xs={5}
                                justifyContent="space-between"
                                alignItems="start"
                              >
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                    paddingLeft: "20%",
                                    // textAlign: "center"
                                  }}
                                >
                                  Revision Start Date
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                                {/* <Controller
                            control={control}
                            name={"revision_start_date"}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100.9%" }}
                                onBlur={onBlur}
                                size="small"
                                onChange={onChange}
                                value={value}
                                placeholder={"Revision Start Date"}
                                error={!!errors["revision_start_date"]}
                                helperText={
                                  errors.revision_start_date &&
                                  `${errors.revision_start_date.message}* This field is Required`
                                }
                              />
                            )}
                          /> */}
                                <Controller
                                  control={control}
                                  name={"revision_start_date"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DatePicker
                                      views={["year", "month"]}
                                      value={value}
                                      InputProps={{
                                        onBlur: onBlur,
                                        size: "small",
                                        sx: {
                                          width: "125%",
                                          marginLeft: 1,
                                        },
                                      }}
                                      onChange={(newValue) => (
                                        onChange(newValue),
                                        // setRevisionStartDate(newValue),
                                        handleRevisionStartDate(newValue)
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={null}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid
                              container
                              justifyContent="start"
                              alignItems="center"
                              style={{ marginTop: 8, marginBottom: 10 }}
                              // width={"100%"}
                            >
                              <Grid
                                item
                                xs={5}
                                justifyContent="space-between"
                                alignItems="start"
                              >
                                <Typography
                                  style={{
                                    fontWeight: "500",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                    paddingLeft: "20%",
                                    // textAlign: "center"
                                  }}
                                >
                                  Revision End Date
                                  <span style={{ color: "red", fontSize: 14 }}>
                                    *
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                                {/* <Controller
                            control={control}
                            name={"revision_end_date"}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100.9%" }}
                                onBlur={onBlur}
                                size="small"
                                onChange={onChange}
                                value={value}
                                placeholder={"Revision End Date"}
                                error={!!errors["revision_end_date"]}
                                helperText={
                                  errors.revision_end_date &&
                                  `${errors.revision_end_date.message}* This field is Required`
                                }
                              />
                            )}
                          /> */}
                                <Controller
                                  control={control}
                                  name={"revision_end_date"}
                                  defaultValue={""}
                                  rules={{ required: true }}
                                  render={({
                                    field: { onBlur, value, onChange },
                                  }) => (
                                    <DatePicker
                                      views={["year", "month"]}
                                      value={value}
                                      InputProps={{
                                        onBlur: onBlur,
                                        size: "small",
                                        sx: {
                                          width: "150%",
                                          marginLeft: 1,
                                        },
                                      }}
                                      onChange={(newValue) => (
                                        onChange(newValue),
                                        handleRevisionEndDate(newValue)
                                        // setRevisionEndDate(newValue)
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={null}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {!!watch("salary_structure_id") && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              style={{
                                fontWeight: "800",
                                fontSize: 18,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "4%",
                                paddingBottom: 5,
                              }}
                            >
                              Salary Details
                            </Typography>
                          </Grid>
                          {!!view_promotion &&
                            Array.isArray(view_promotion) &&
                            Array.isArray(view_promotion[0].components) &&
                            view_promotion[0].components.length > 0 &&
                            view_promotion[0].components.map(
                              (item, index) =>
                                !item.calculation && (
                                  <Grid item xs={6}>
                                    <Grid
                                      key={item.compent_id}
                                      container
                                      justifyContent="start"
                                      alignItems="center"
                                      style={{ marginTop: 5, marginBottom: 10 }}
                                      // width={"100%"}
                                    >
                                      <Grid
                                        item
                                        xs={5}
                                        justifyContent="space-between"
                                        alignItems="start"
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "500",
                                            fontSize: 16,
                                            fontFamily: "Poppins, sans-serif",
                                            color: "#626974",
                                            paddingLeft: "20%",
                                            // textAlign: "center"
                                          }}
                                        >
                                          {item.display_name}

                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 14,
                                            }}
                                          >
                                            *
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6.5}
                                        style={{ marginLeft: -5 }}
                                      >
                                        <Controller
                                          control={control}
                                          name={item.compent_id.toString()}
                                          defaultValue={""}
                                          rules={{ required: true }}
                                          render={({
                                            field: { onBlur, value, onChange },
                                          }) => (
                                            <TextField
                                              type={"number"}
                                              id="standard-required"
                                              variant="outlined"
                                              className={"smaller"}
                                              style={{ width: "100.9%" }}
                                              onBlur={onBlur}
                                              size="small"
                                              onChange={onChange}
                                              value={value}
                                              placeholder={item.display_name}
                                              error={
                                                !!errors[
                                                  item.compent_id.toString()
                                                ]
                                              }
                                              helperText={
                                                errors[
                                                  item.compent_id.toString()
                                                ] && `* This field is Required`
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                        </>
                      )}
                    </Grid>
                  </Paper>
                )}
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 20, marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid item xs={3} justifyContent="center" alignItems="start">
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "40%",
                        // textAlign: "center"
                      }}
                    >
                      Remarks
                    </Typography>
                  </Grid>
                  <Grid item xs={8.5} sx={{ marginLeft: -1, paddingRight: 5 }}>
                    <Controller
                      control={control}
                      name={"remarks"}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          multiline
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          label={"Enter Remarks"}
                          // error={!!errors["remarks"]}
                          // helperText={
                          //   errors.remarks &&
                          //   `${errors.remarks.message}* This field is Required`
                          // }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Grid item xs={3}>
                  <Button
                    sx={{
                      color: "#fff",
                      //backgroundColor: "#1565C0",
                      paddingX: 4,
                      marginRight: 10,
                    }}
                    onClick={handleSubmit(onRejectSubmit)}
                    variant="contained"
                    color="error"
                    size="large"
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 4,
                      marginRight: 10,
                    }}
                    onClick={handleSubmit(onApproveSubmit)}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PromotionApproveSection;

interface PromotionApproveSectionProps {
  open: boolean;
  setOpen?: any;
  approvalList: ViewPromotion[];
  SetapprovalList?: any;
  DomValue?: any;
  FinalApprovedSubmit?: any;
  setfinalFlag?: any;
  setsubmitFlaga?: any;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  designation_list: AllDesignationList[];
  salary_structure?: SalaryStructureEditDetails;
  view_promotion: ViewPromotion[];
  pending_promtions: PromotionApprovalList[];
  GetAllDesignationAction?: any;
  GetAllSalaryStructureAction?: any;
  RemoveEmpPromotion?: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
