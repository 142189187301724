import {
  Paper,
  Grid,
  Button,
  TextField,
  IconButton,
  Popover,
  Typography,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  NativeSelect,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AllLeaveList, Datum, ILeaveType } from "../../../models/leaveModel";
import moment from "moment";
import { AttendanceDetailPayloadSuccessAction } from "../../../stores/actions/attendanceAction";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CheckActionStatusPayload } from "../../../models/employeeModel";
import ActionStatusPopover from "../../employment/ActionStatusPopover";
import SearchEmployee from "../../utilComponent/SearchEmployee";
import { IEmployeeList } from "../../../models/timingSheetModel";

const AllLeaveView = ({
  organization_list,
  leave_types,
  Submit,
  all_leave_list,
  SetpageSize,
  Setpageindex,
  pageindex,
  pagesize,
  rowperpage,
  total_count,
  onClear,
  searchByNameEmpAction,
  employeeList,
}: AllLeaveViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const [popoverPayload, SetpopoverPayload] = useState<
    CheckActionStatusPayload | undefined
  >(undefined);
  const onSubmit = (data: any) => {
    setOpenDetails(true);
    setExpanded(false);
    console.log(data);
    Submit(data);
  };
  const leave_status: any[] = [
    { leave_status_key: "APPLIED", leave_status_name: "APPLIED" },
    { leave_status_key: "REJECT", leave_status_name: "REJECT" },
    { leave_status_key: "APPROVED", leave_status_name: "APPROVED" },
  ];
  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Start Date",
    "End Date",
    "Leave Applied",
    "Used Leave",
    "Available Leave",
    "Leave Type",
    "Leave Status",
    "Action",
  ];
  const [rows, setRows] = useState<Datum[]>([]);
  const [searched, setSearched] = useState<string>("");
  const styles = {
    marginRight: "50px",
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  console.log("dfsdfsfdg", rows);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!all_leave_list
      ? all_leave_list?.filter((row: Datum) => {
        return (
          row?.leave_status
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_id
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.leave_type_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!all_leave_list) {
      cancelSearch();
    }
  }, [all_leave_list]);

  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log(searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, "");
    }, 2000);
  };

  useEffect(() => {
    searchByNameEmpAction("", "");
  }, []);

  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Organization
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"organization_id"}
                        // defaultValue={`${
                        //   attendance_dashboard?.organization_id
                        //     ? attendance_dashboard.organization_id
                        //     : ""
                        // }`}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={organization_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"organization_id"}
                            label={"organization_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Organization"}
                            value={!!value ? value : ""}
                            errors={!!errors["organization_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Leave Type
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"leave_type_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={leave_types}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"leave_type_id"}
                            label={"leave_type_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Leave Types"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Leave Status
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"leave_status"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={leave_status}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"leave_status_key"}
                            label={"leave_status_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Leave Status"}
                            value={!!value ? value : ""}
                          ></Autocompletes>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      {/* <Controller
                        control={control}
                        name={"employee_id"}
                        defaultValue={""}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            // disabled={!!department ? edit_flag : !edit_flag}
                            //   inputProps={{
                            //     readOnly: !!department ? edit_flag : !edit_flag,
                            //   }}
                            size="small"
                            onChange={onChange}
                            value={value}
                            // label={"Department Name"}
                            placeholder={"Employee ID"}
                          />
                        )}
                      /> */}

                      <Controller
                        control={control}
                        name={"employee_id"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={employeeList}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"employee_id"}
                            label={"employee_name"}
                            onInputChange={(e: any) => {
                              if (e?.target?.value) GetCall(e.target.value);
                            }}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Employee"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
                direction={"row"}
              >
                <Grid item xs={2}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 6,
                      // marginRight: 10,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    View
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 6,
                      // marginRight: 10,
                    }}
                    onClick={() => (onClear(), setExpanded(false))}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <ActionStatusPopover
        open={openStatus}
        setOpen={SetopenStatus}
        payload={popoverPayload}
      />
      <Paper
        elevation={3}
        sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
            <TextField
              size="small"
              sx={{ width: "80%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction={"row"}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-root:hover": {
                      // backgroundColor: "#73c6ff",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.leave_application_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.created_by}`
                                )
                              }
                            >
                              {row.employee_id}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.employee_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.start_date
                              ? moment(row.start_date)
                                .format("DD-MM-YY ")
                                .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.end_date
                              ? moment(row.end_date)
                                .format("DD-MM-YY ")
                                .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>

                          <StyledTableCell align="center" key={column1[4]}>
                            {+row.leave_count}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[5]}>
                            {+row.used_count}
                            {/* {+row.used_count - +row.leave_count} */}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[6]}>
                            {+row.total_leave}
                            {/* {row.leave_status === "APPROVED" ? +row.total_leave + +row.leave_count : +row.total_leave} */}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[7]}>
                            {row.leave_type_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[8]}>
                            {row.leave_status}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[9]}>
                            <Tooltip
                              title="Show Approve Status"
                              onClick={() => (
                                SetpopoverPayload({
                                  employeeId: row.leave_application_id,
                                  employeeTypeId: row.employee_type_id,
                                  eventType: 9,
                                }),
                                SetopenStatus(true)
                              )}
                            >
                              <IconButton>
                                <ContentPasteSearchIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={8}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems={"center"} justifyContent="end">
          <Grid xs={2} textAlign="right">
            Rows per page:
          </Grid>
          <Grid xs={1} textAlign="center">
            <NativeSelect
              value={pagesize.toString()}
              onChange={(event) => SetpageSize(+event.target.value)}
            >
              {rowperpage.map((p) => (
                <option key={p} value={p} onChange={(value: any) => value(p)}>
                  {p}
                </option>
              ))}
            </NativeSelect>
          </Grid>
          <Grid xs={1} textAlign="center">
            <IconButton
              onClick={() => Setpageindex(pageindex - 1)}
              color="primary"
              size="small"
              disabled={pageindex <= 0}
            >
              <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
            </IconButton>
          </Grid>
          <Grid xs={1} textAlign="center">
            <Typography>
              {pageindex * pagesize + 1} -{" "}
              {!!total_count && total_count <= (pageindex + 1) * pagesize
                ? total_count
                : (pageindex + 1) * pagesize}
              {" of "}
              {total_count}
            </Typography>
          </Grid>
          <Grid xs={1} textAlign="center">
            <IconButton
              color="primary"
              size="small"
              onClick={() => Setpageindex(pageindex + 1)}
              disabled={
                (!!total_count && total_count <= (pageindex + 1) * pagesize) ||
                total_count === 0
              }
            >
              <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AllLeaveView;
interface AllLeaveViewProps {
  organization_list?: any;
  leave_types: ILeaveType[];
  Submit?: any;
  all_leave_list?: Datum[];
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_count?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  onClear?: any;
  employeeList: IEmployeeList[];
  searchByNameEmpAction: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
