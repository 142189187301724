import { bu } from "@fullcalendar/core/internal-common";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  LinearProgress,
  linearProgressClasses,
  makeStyles,
  Stack,
  styled,
  Tab,
  TextField,
  Typography,
  Tooltip as Tool,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from "recharts";
import {
  BudgetDashboardDetails,
  EmpMonthlySalaryProjectwise,
  MonthWiseEmpSal,
  MonthWiseEmpSalaryinBudgeting,
} from "../../../models/customerModel";
import colorGenerator from "../../../utility/colorGenerator";
import ActivityCostCard from "./ActivityCostCard";
import MonthlyExpensesPopover from "./MonthlyExpensesPopover";
import MonthlySalaryPopover from "./MonthlySalaryPopover";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { environment } from "../../../environment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { userDetails } from "../../../models/userModels";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
const BudgetDashboardView = ({
  budget_details,
  month_wise_emp_salary_Budgeting,
  EmpSalaryMonthProject,
  emp_salary_month_projectwise,
  EmpMonthlyDisburseSalary,
  setCurrentYear,
  currentYear,
  user,
}: BudgetDashboardViewProps) => {
  useEffect(() => {
    newSpendingData();
    newIncomeData();
    newData();
  }, [budget_details]);
  let navigateTo = useNavigate();
  //const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];
  console.log(budget_details?.month_wise_Emp_sal);
  const [option, Setoption] = useState<any[]>([]);
  const [option2, Setoption2] = useState<any[]>([]);
  const [option3, Setoption3] = useState<any[]>([]);

  // useEffect(() => {
  //   newIncomeData();
  // }, [budget_details?.income_details]);
  const newIncomeData = () => {
    if (
      !!budget_details?.income_details &&
      budget_details?.income_details.length > 0
    ) {
      let data: any[] = [];
      let objMapper: any = {};
      budget_details.income_details.forEach((self) => {
        const temp = moment(self.payment_date).format("MMM,YYYY");
        if (objMapper[temp]) {
          const findIndex = data.findIndex((_self: any) => {
            return _self.month === temp;
          });
          data[findIndex].Payment_Amount += +self.project_payment_without_gst;
        } else {
          objMapper[temp] = 1;
          data.push({
            month: moment(temp).format("MMM,YYYY"),
            Income: +self.project_payment_without_gst,
          });
        }
      });
      Setoption(data);
    }
  };
  console.log(option, "income");

  const newSpendingData = () => {
    const tempData: any[] = [];
    let objMapper: any = {};
    if (
      !!budget_details?.spending_details &&
      budget_details?.spending_details.length > 0
    ) {
      budget_details?.spending_details.forEach((self) => {
        if (objMapper[self.month]) {
          const findIndex = tempData.findIndex(
            (_self) => _self.month === _self.month
          );
          tempData[findIndex] = {
            ...tempData[findIndex],
            [self.field]: self.expense,
          };
        } else {
          objMapper[self.month] = 1;
          let calculateSal = budget_details.month_wise_Emp_sal.find(
            (_bself) =>
              moment(_bself.date).format("YYYY-MM") ===
              moment(self.month).format("YYYY-MM")
          );
          if (!!calculateSal) {
            tempData.push({
              month: moment(self.month).format("MMM,YYYY"),
              [self.field]: self.expense,
              "Employee Salary": calculateSal.calculated_amount,
            });
          } else {
            tempData.push({
              month: moment(self.month).format("MMM,YYYY"),
              [self.field]: self.expense,
            });
          }
        }
      });
    } else if (
      !!budget_details?.month_wise_Emp_sal &&
      budget_details?.month_wise_Emp_sal.length > 0
    ) {
      budget_details?.month_wise_Emp_sal.forEach((m) => {
        tempData.push({
          month: moment(m.date).format("MMM,YYYY"),
          "Employee Salary": m.calculated_amount,
        });
      });
    }
    console.log("tempData : ", tempData);

    Setoption2(tempData);
  };
  // useEffect(() => {
  //   newSpendingData();
  // }, [budget_details?.spending_details]);
  const newData = () => {
    const tempData: any[] = [];
    let objMapper: any = {};
    if (
      !!budget_details?.spending_details &&
      budget_details?.spending_details.length > 0
    ) {
      budget_details?.spending_details.forEach((self) => {
        if (objMapper[self.month]) {
          const findIndex = tempData.findIndex(
            (_self) => _self.month === _self.month
          );
          tempData[findIndex] = {
            ...tempData[findIndex],
            [self.field]: self.expense,
          };
        } else {
          objMapper[self.month] = 1;
          // let incomeByMonth = option.find(
          //   (_iself) =>
          //     moment(_iself.month).format("MMM,YYYY") ===
          //     moment(self.month).format("MMM,YYYY")
          // );
          const calculateSal: MonthWiseEmpSal | undefined =
            budget_details.month_wise_Emp_sal.find(
              (_bself) =>
                moment(_bself.date).format("MMM,YYYY") ===
                moment(self.month).format("MMM,YYYY")
            );
          if (!!calculateSal) {
            tempData.push({
              month: moment(self.month).format("MMM,YYYY"),
              [self.field]: self.expense,
              "Employee Salary": calculateSal.calculated_amount,
            });
            // } else if (!!calculateSal) {
            //   tempData.push({
            //     month: moment(self.month).format("MMM,YYYY"),
            //     [self.field]: self.expense,
            //     "Employee Salary": calculateSal,
            //   });
          } else {
            tempData.push({
              month: moment(self.month).format("MMM,YYYY"),
              [self.field]: self.expense,
            });
          }
        }
      });
    } else if (
      !!budget_details?.month_wise_Emp_sal &&
      budget_details.month_wise_Emp_sal.length > 0
    ) {
      budget_details.month_wise_Emp_sal.forEach((m) => {
        if (objMapper[moment(m.date).format("MMM,YYYY")]) {
          const findIndex = budget_details.month_wise_Emp_sal.findIndex(
            (_bself) =>
              moment(_bself.date).format("MMM,YYYY") ===
              moment(m.date).format("MMM,YYYY")
          );
          tempData[findIndex]["Employee Salary"] += +m.calculated_amount;
        } else {
          objMapper[moment(m.date).format("MMM,YYYY")] = 1;
          tempData.push({
            month: moment(m.date).format("MMM,YYYY"),
            "Employee Salary": +m.calculated_amount,
          });
        }
      });
    }
    if (
      !!budget_details?.income_details &&
      budget_details?.income_details.length > 0
    ) {
      let data: any[] = [];
      let objMapper: any = {};
      budget_details.income_details.forEach((self) => {
        const temp = moment(self.payment_date).format("MMM,YYYY");
        if (objMapper[temp]) {
          const findIndex = data.findIndex((_self: any) => {
            return _self.month === temp;
          });
          data[findIndex].Payment_Amount += +self.project_payment_without_gst;
        } else {
          objMapper[temp] = 1;
          data.push({
            month: moment(temp).format("MMM,YYYY"),
            Income: +self.project_payment_without_gst,
          });
        }
      });
      data.forEach((oSelf, index) => {
        const incomeDataIndex = tempData.findIndex(
          (tSelf, index) =>
            moment(tSelf.month).format("MMM,YYYY") ===
            moment(oSelf.month).format("MMM,YYYY")
        );
        console.log(incomeDataIndex);
        if (incomeDataIndex >= 0) {
          tempData[incomeDataIndex].Income = oSelf.Income;
        } else {
          tempData.push({
            month: moment(oSelf.month).format("MMM,YYYY"),
            Income: oSelf.Income,
          });
        }
      });
    }
    // option2.forEach((Self, index) => {
    //   const salarDataIndex = tempData.findIndex(
    //     (tSelf, index) => moment(tSelf.month).format("MMM,YYYY") ===
    //       moment(Self.).format("MMM,YYYY")
    //   );
    //   console.log(incomeDataIndex);
    //   if (incomeDataIndex >= 0) {
    //     tempData[incomeDataIndex].Income = oSelf.Income;
    //   } else {
    //     tempData.push({
    //       month: moment(oSelf.month).format("MMM,YYYY"),
    //       Income: oSelf.Income,
    //     });
    //   }
    // });
    console.log(option, "incomeData");
    console.log(tempData, "Mergedata");
    Setoption3(tempData);
  };
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const loss_profit_per = (
    (+(
      (!!budget_details?.budgetDetails.total_income_without_gst
        ? +budget_details?.budgetDetails.total_income_without_gst
        : 0) -
      +(!!budget_details?.budgetDetails.total_spending
        ? budget_details?.budgetDetails.total_spending
        : 0)
    ).toFixed(2) /
      +(!!budget_details?.budgetDetails.total_income_without_gst
        ? budget_details?.budgetDetails.total_income_without_gst
        : 0)) *
    100
  ).toFixed(2);
  const actual_profit_loss = (
    (!!budget_details?.budgetDetails.total_income_without_gst
      ? +budget_details?.budgetDetails.total_income_without_gst
      : 0) -
    +(!!budget_details?.budgetDetails.total_spending
      ? budget_details?.budgetDetails.total_spending
      : 0)
  )
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const EmpMonthlyDisbursedSalary = (data: any) => {
    console.log("EmpMonthlyDisbursedSalary", data);
    let month = new Date(data).getMonth() + 1;
    let payload: any = {
      project_id:
        !!budget_details &&
        !!budget_details?.budgetDetails &&
        budget_details?.budgetDetails.project_id,
      month: month,
    };
    // console.log("payload", payload);
    EmpMonthlyDisburseSalary(payload);
  };

  const handleCurrentYearForDashboard = (newValue: Dayjs | null) => {
    setCurrentYear(newValue);
  };
  return (
    <>
      <Container sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Box display="flex" alignItems="center" px={1} mt={1} width="100%">
              <Typography
                sx={{ color: "#A2A2A2", fontSize: "16", mr: 2 }}
                variant="body2"
              >
                Select Year
              </Typography>
              <DesktopDatePicker
                inputFormat="YYYY"
                InputProps={{
                  sx: { width: "100%", outline: "none", border: "none" },
                }}
                views={["year"]}
                maxDate={dayjs(new Date())}
                value={currentYear}
                onChange={handleCurrentYearForDashboard}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            {" "}
            <Typography textAlign="center" color="#3E54AC" variant="h4">
              Budget Details of {budget_details?.budgetDetails.project_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Tool title="Project Dashboard">
              <IconButton
                style={{ color: "#1565C0" }}
                onClick={() =>
                  navigateTo(
                    `/customer/project-dashboard/${budget_details?.budgetDetails.project_id}`
                  )
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Tool>
            <Tool title="Show Invoice Details">
              <IconButton
                style={{ color: "#1565C0" }}
                onClick={() =>
                  navigateTo(
                    `/customer/invoice-data/${budget_details?.budgetDetails.project_id}`
                  )
                }
              >
                <ReceiptIcon />
              </IconButton>
            </Tool>
            <Tool
              title="Show Payment Details"
              onClick={() =>
                navigateTo(
                  `/customer/payment-details-main/${budget_details?.budgetDetails.project_id}`
                )
              }
            >
              <IconButton>
                <PaymentsIcon style={{ color: "#1565C0" }} />
              </IconButton>
            </Tool>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" flexWrap={"wrap"}>
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876, #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                    noWrap
                  >
                    Bid Price (With GST)
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    &#8377;
                    {!!budget_details?.budgetDetails.bid_price_with_gst
                      ? budget_details?.budgetDetails.bid_price_with_gst
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                    {"  "}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876   , #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                    noWrap
                  >
                    Bid Price (Without GST)
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    &#8377;
                    {!!budget_details?.budgetDetails.bid_price_without_gst
                      ? budget_details?.budgetDetails.bid_price_without_gst
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 18,
                      fontFamily: "Poppins, sans-serif",
                      color: "#F6F1E9",
                      textAlign: "center",
                    }}
                    paddingTop={2}
                  >
                    Expected Profit{"  "}
                    {budget_details?.budgetDetails.profitability_percentage}%
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876   , #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    Estimated Effort (Hour)
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    {!!budget_details?.budgetDetails.total_work_hour
                      ? budget_details?.budgetDetails.total_work_hour
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876   , #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    Consume Effort (Hour)
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    {!!budget_details?.budgetDetails.total_completion_hour
                      ? budget_details?.budgetDetails.total_completion_hour
                      : 0}
                  </Typography>
                </CardContent>
              </Card>
              <MonthlySalaryPopover
                open={openStatus}
                setOpen={SetopenStatus}
                budget_details={budget_details}
                month_wise_emp_salary_Budgeting={
                  month_wise_emp_salary_Budgeting
                }
                EmpMonthlyDisbursedSalary={EmpMonthlyDisbursedSalary}
              />
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876   , #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    Total Disbursed Salary
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    &#8377;
                    {!!budget_details?.budgetDetails.totalEMPCost
                      ? (+budget_details?.budgetDetails.totalEMPCost.toFixed(2))
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                  </Typography>
                </CardContent>
                {!!budget_details?.month_wise_Emp_sal &&
                  budget_details?.month_wise_Emp_sal.length > 0 && (
                    <CardActions sx={{ alignItems: "center" }}>
                      <Button
                        size="small"
                        sx={{ color: "#F6F1F1" }}
                        onClick={() => SetopenStatus(true)}
                      >
                        Monthly Disbursed Salary
                      </Button>
                    </CardActions>
                  )}
              </Card>
              <Card
                sx={{
                  minWidth: 200,
                  margin: 1,
                  background:
                    "linear-gradient(to right bottom, #2b5876   , #4e4376)",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    Additional Expenses
                  </Typography>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ fontWeight: "bold" }}
                  >
                    &#8377;
                    {(+(
                      !!budget_details?.budgetDetails.total_spending
                        ? budget_details?.budgetDetails.total_spending -
                          +budget_details?.budgetDetails.totalEMPCost
                        : 0
                    ).toFixed(2))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} flexWrap={"wrap"}>
              <Card
                sx={{
                  background:
                    "linear-gradient(to right bottom, #ddd6f3 , #faaca8)",
                  minWidth: 300,
                  margin: 1,
                }}
              >
                <CardContent>
                  {/* <Grid container direction={"row"} pt={0.5}>
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 17,
                          fontFamily: "Poppins, sans-serif",
                          color: "#1A5F7A",
                          // textAlign: "center",
                          mb: 1,
                        }}
                      >
                        Expected Profit Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {!!budget_details?.budgetDetails
                        .profitability_percentage &&
                        budget_details?.budgetDetails.bid_price_with_gst && (
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: 17,
                              fontFamily: "Poppins, sans-serif",
                              color: "#002B5B",
                              // textAlign: "center",
                            }}
                          >
                            {" "}
                            &#8377;
                            {(budget_details?.budgetDetails
                              .profitability_percentage *
                              budget_details?.budgetDetails
                                .bid_price_with_gst) /
                              100}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      !!budget_details?.budgetDetails.profitability_percentage
                        ? +budget_details?.budgetDetails
                            .profitability_percentage
                        : 0
                    }
                    sx={{
                      "& .MuiLinearProgress-bar1Determinate": {
                        backgroundColor: "#0EA293",
                      },
                    }}
                  />
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    style={{ marginTop: 2 }}
                  >
                    <Typography style={{ color: "#9A208C" }}>
                      {budget_details?.budgetDetails.profitability_percentage}%
                    </Typography>
                  </Grid> */}
                  <>
                    <Grid container direction={"row"} pt={0.5}>
                      <Grid item xs={8}>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: 17,
                            fontFamily: "Poppins, sans-serif",
                            color: "#1A5F7A",
                            // textAlign: "center",
                            mb: 1,
                          }}
                        >
                          Actual Profit/Loss Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: 17,
                            fontFamily: "Poppins, sans-serif",
                            color:
                              !!actual_profit_loss &&
                              +actual_profit_loss.replaceAll(",", "") > 0
                                ? "#002B5B"
                                : "red",
                            // textAlign: "center",
                          }}
                        >
                          {" "}
                          &#8377;
                          {actual_profit_loss}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <BorderLinearProgress
                      variant="determinate"
                      value={!!loss_profit_per ? Math.abs(+loss_profit_per) : 0}
                      sx={{
                        "& .MuiLinearProgress-bar1Determinate": {
                          backgroundColor:
                            +loss_profit_per > 0 ? "#0EA293" : "#E21818",
                        },
                      }}
                    /> */}
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      style={{ marginTop: 2 }}
                    >
                      {!!budget_details?.budgetDetails
                        .total_income_without_gst && (
                        <Typography style={{ color: "#9A208C" }}>
                          {+loss_profit_per}%
                        </Typography>
                      )}
                    </Grid>
                  </>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 300, margin: 1 }}>
                <CardContent>
                  <Grid container direction={"row"} pt={0.5}>
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 17,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // textAlign: "center",
                          mb: 1,
                        }}
                      >
                        Total Income
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 17,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // textAlign: "center",
                        }}
                      >
                        {" "}
                        &#8377;
                        {!!budget_details?.budgetDetails
                          .total_income_without_gst
                          ? budget_details?.budgetDetails.total_income_without_gst
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      !!budget_details?.budgetDetails.total_income_per
                        ? +budget_details?.budgetDetails.total_income_per
                        : 0
                    }
                    sx={{
                      "& .MuiLinearProgress-bar1Determinate": {
                        backgroundColor: "#083AA9",
                      },
                    }}
                  />
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    style={{ marginTop: 2 }}
                  >
                    <Typography style={{ color: "#867070" }}>
                      {budget_details?.budgetDetails.total_income_per}%
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 300, margin: 1 }}>
                <CardContent>
                  <Grid container direction={"row"} pt={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 17,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // textAlign: "center",
                          mb: 1,
                        }}
                      >
                        Total Spending
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 20,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // textAlign: "center",
                        }}
                      >
                        {" "}
                        &#8377;
                        {!!budget_details?.budgetDetails.total_spending
                          ? Number(budget_details?.budgetDetails.total_spending)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""}
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  {/* <BorderLinearProgress
                    variant="determinate"
                    value={
                      !!budget_details?.budgetDetails.total_spending_per
                        ? +budget_details?.budgetDetails.total_spending_per
                        : 0
                    }
                    sx={{
                      "& .MuiLinearProgress-bar1Determinate": {
                        backgroundColor: "#EA5455",
                      },
                    }}
                  />
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    style={{ marginTop: 2 }}
                  >
                    <Typography style={{ color: "#867070" }}>
                      {budget_details?.budgetDetails.total_spending_per}%
                    </Typography>
                  </Grid> */}
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 300, margin: 1 }}>
                <CardContent>
                  <Grid container direction={"row"} pt={0.5}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 17,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // textAlign: "center",
                          mb: 1,
                        }}
                      >
                        Project Completion
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: 20,
                          fontFamily: "Poppins, sans-serif",
                          color: "#867070",
                          // textAlign: "center",
                        }}
                      >
                        {" "}
                        {budget_details?.budgetDetails.project_completion_per}%
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  {/* <BorderLinearProgress
                    variant="determinate"
                    value={
                      !!budget_details?.budgetDetails.project_completion_per
                        ? +budget_details?.budgetDetails.project_completion_per
                        : 0
                    }
                    sx={{
                      "& .MuiLinearProgress-bar1Determinate": {
                        backgroundColor: "#539165",
                      },
                    }}
                  /> */}
                </CardContent>
              </Card>
            </Stack>
            {/* </CardContent>
            </Card> */}
          </Grid>
          <Box pt={3} width="100%">
            <Card variant="outlined" elevation={3} sx={{ minHeight: 400 }}>
              <CardContent>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="All" value="1" />
                      <Tab label="Income" value="2" />
                      <Tab label="Spending" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <ResponsiveContainer width="95%" height={400}>
                      <BarChart
                        height={400}
                        data={option3}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {!!budget_details?.budgetDetails.totalSpendingDetails &&
                          budget_details?.budgetDetails.totalSpendingDetails.map(
                            (m, index) => (
                              <Bar
                                dataKey={m.component_name}
                                stackId="a"
                                /*fill={COLORS[index]} */ fill={colorGenerator(
                                  String(index),
                                  0,
                                  210
                                )}
                              />
                            )
                          )}
                        <Bar
                          dataKey="Employee Salary"
                          stackId="a"
                          /*fill={COLORS[index]} */ fill="#FF8042"
                        />
                        <Bar
                          dataKey="Income"
                          stackId="b"
                          /*fill={COLORS[index]} */ fill="#82ca9d"
                        />
                        <Brush dataKey={"month"} height={30} stroke="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </TabPanel>
                  <TabPanel value="2">
                    <ResponsiveContainer width="95%" height={400}>
                      <BarChart
                        //width={800}
                        height={400}
                        data={option}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Income" stackId="a" fill="#539165" />
                        <Brush
                          //dataKey={moment("payment_date")).format("MMM,YYYY")}
                          dataKey={"month"}
                          height={30}
                          stroke="#8884d8"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </TabPanel>
                  <TabPanel value="3">
                    <ResponsiveContainer width="95%" height={400}>
                      <BarChart
                        // width={800}
                        height={400}
                        data={option2}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {!!budget_details?.budgetDetails.totalSpendingDetails &&
                          budget_details?.budgetDetails.totalSpendingDetails.map(
                            (m, index) => (
                              <Bar
                                dataKey={m.component_name}
                                stackId="a"
                                /*fill={COLORS[index]} */ fill={colorGenerator(
                                  String(index),
                                  0,
                                  210
                                )}
                              />
                            )
                          )}
                        <Bar
                          dataKey="Employee Salary"
                          stackId="a"
                          fill="#FF8042"
                        />
                        <Brush dataKey={"month"} height={30} stroke="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Box>
          <Box pt={3}>
            <ActivityCostCard
              budget_details={budget_details}
              EmpSalaryMonthProject={EmpSalaryMonthProject}
              emp_salary_month_projectwise={emp_salary_month_projectwise}
            />
          </Box>
          {/* <Grid item xs={8}></Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default BudgetDashboardView;

interface BudgetDashboardViewProps {
  budget_details?: BudgetDashboardDetails;
  month_wise_emp_salary_Budgeting: MonthWiseEmpSalaryinBudgeting[];
  EmpSalaryMonthProject?: any;
  emp_salary_month_projectwise: EmpMonthlySalaryProjectwise[];
  EmpMonthlyDisburseSalary?: any;
  setCurrentYear?: any;
  currentYear?: any;
  user?: userDetails;
}
