import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../library/AutoComplete";
import { IEmployeeList } from "../../models/timingSheetModel";

const SearchEmployee: FC<ISearchEmployee> = ({
  searchByNameEmpAction,
  getValues,
  employeeList,
  control,
  fieldName,
  labelPadding,
  marginTop,
  marginBottom,
  gridVal,
}): JSX.Element => {
  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log(searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, getValues("employee_type_id"));
    }, 2000);
  };
  return (
    <Grid
      container
      //   alignItems="center"
      //   style={{ marginTop: marginTop, marginBottom: marginBottom }}
      alignItems="center"

      // width={"100%"}
    >
      <Grid item xs={gridVal} justifyContent="flex-start">
        <Typography
          style={{
            fontWeight: "500",
            fontSize: 16,
            fontFamily: "Poppins, sans-serif",
            color: "#626974",
            padding: labelPadding ? `${labelPadding}%` : "0",

            // textAlign: "center"
          }}
        >
          Select Employee
        </Typography>
      </Grid>
      <Grid item xs={gridVal} className="datepicker">
        <Controller
          control={control}
          name={`${fieldName}`}
          defaultValue={""}
          render={({ field: { onBlur, value, onChange } }) => (
            <Autocompletes
              hasInfo={false}
              option={employeeList}
              //   readOnly={!!department ? edit_flag : !edit_flag}
              keys={"employee_id"}
              label={"employee_name"}
              onInputChange={(e: any) => {
                if (e?.target?.value) GetCall(e.target.value);
              }}
              onBlur={onBlur}
              onChange={(value: any) => onChange(value)}
              placeholder={"Search Employee"}
              value={!!value ? value : ""}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

interface ISearchEmployee {
  searchByNameEmpAction: any;
  getValues: any;
  control: any;
  employeeList: IEmployeeList[];
  fieldName: string;
  labelPadding?: number;
  marginTop: number;
  marginBottom: number;
  gridVal: number;
}

export default SearchEmployee;
