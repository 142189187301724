import { Dispatch, FC, SetStateAction } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ILeaveCalendar, IHolidayList } from "../../../models/leaveModel";
import { Divider, Grid } from "@mui/material";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const CalendarModal: FC<ICalendarModal> = ({
  open,
  setOpen,
  leave_calendar,
  holiday,
  modalType,
}): JSX.Element => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("selectedLeaveDetails : ", leave_calendar);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                background: "#3788d8",
                p: 1.5,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {modalType === "Leave" ? "Leave Details" : "Holiday Details"}
                </Typography>
              </Box>
              <Box>
                <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box py={2} width="80%" mx="auto">
              {modalType === "Leave" && !!leave_calendar ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6} my={1}>
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ fontSize: "1rem" }}
                      >
                        Leave Type Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {leave_calendar?.leave_type_name
                          ? leave_calendar.leave_type_name
                          : leave_calendar?.title}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={6} my={1}>
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ fontSize: "1rem" }}
                      >
                        Current Status
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {leave_calendar.leave_status}{" "}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} my={1}>
                      <Typography fontSize="1rem" color="gray">
                        Starting At
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {moment(leave_calendar.start_date).format(
                          "DD-MMM-YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={6} my={1}>
                      <Typography fontSize="1rem" color="gray">
                        Total No. Of Leave
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {leave_calendar.leave_count}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={6} my={1}>
                      <Typography fontSize="1rem" color="gray">
                        Current Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {moment(leave_calendar.date).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={6} my={1}>
                      <Typography fontSize="1rem" color="gray">
                        Ending At
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {moment(leave_calendar.end_date).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} py={1}>
                    <Grid item xs={6} my={1}>
                      <Typography fontSize="1rem" color="gray">
                        Applied At
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {moment(leave_calendar.created_on).format(
                          "DD-MMM-YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {modalType === "Holiday" && !!holiday ? (
                <>
                  <Grid container spacing={2} p={1}>
                    <Grid item xs={6} my={1}>
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ fontSize: "1rem" }}
                      >
                        Holiday Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {holiday.holiday_name.toLocaleUpperCase()}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} p={1}>
                    <Grid item xs={6} my={1}>
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ fontSize: "1rem" }}
                      >
                        Flxible
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {holiday.flexible_leave ? "YES" : "NO"}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                  <Grid container spacing={2} p={1}>
                    <Grid item xs={6} my={1}>
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ fontSize: "1rem" }}
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={6} my={1}>
                      <Typography
                        fontSize="1rem"
                        color="gray"
                        textAlign="right"
                      >
                        {holiday.holiday_description}
                      </Typography>
                    </Grid>
                    <Divider sx={{ width: "98%", mx: 2 }} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Box mt={5} display="flex" justifyContent="center" alignItems="center">
                <Button variant="outlined" onClick={() => setOpen(false)}>
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

interface ICalendarModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  leave_calendar: ILeaveCalendar | null;
  holiday: IHolidayList | null;
  modalType: string;
}
export default CalendarModal;
