import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Autocompletes from "../../../library/AutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  AttendanceApprovalList,
  AttendanceTimeSheetList,
  PendingApprovalList,
} from "../../../models/attendanceModel";
import moment from "moment";
import { FilterList } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import "../AttendanceMain/Attendance.css";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
import { ProjectList, ViewProject } from "../../../models/customerModel";

function AttendanceTimeSheetView({
  organization_list,
  Submit,
  attendance_timesheet_list,
  UploadTimesheet,
  employee_type_list,
  user,
  project_detail,
  project_list,
  ProjectSelect,
  goToTimingSheetFromWorklistAction,
}: AttendanceTimeSheetViewProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [generateList, SetgenerateList] = useState<AttendanceTimeSheetList[]>(
    []
  );
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const column1: any[] = [
    "Employee No",
    "Employee Name",
    "Total Days",
    "Present Days",
    "Holidays",
    "Absent Days",
    "Projects",
    "Action",
  ];
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<AttendanceTimeSheetList[]>([]);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!attendance_timesheet_list
      ? attendance_timesheet_list.filter((row: AttendanceTimeSheetList) => {
          return (
            row?.employee_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.employee_id?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!attendance_timesheet_list) {
      cancelSearch();
    }
  }, [attendance_timesheet_list]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [empType, setempType] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const onSubmit = (data: any) => {
    data.year = moment(data.month).format("YYYY").toString();
    data.month = +moment(data.month).format("MM");
    setOpenDetails(true);
    Submit({ ...data, month: +data.month, year: +data.year });
    setExpanded(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const SelectRow = (checked: boolean, row: AttendanceTimeSheetList) => {
    if (checked) {
      if (row.present_days == 0) {
        enqueueSnackbar(`Present days can't be 0`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (row.present_days > row.total_days) {
        enqueueSnackbar(`Present days can't be greater than Total days`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        let events: AttendanceTimeSheetList[] = JSON.parse(
          JSON.stringify(generateList)
        );
        events.push(row);
        SetgenerateList(events);
      }
    } else {
      let events: AttendanceTimeSheetList[] = JSON.parse(
        JSON.stringify(generateList)
      );
      events = events.filter((m) => m.employee_id !== row.employee_id);
      if (events.length == 0) Set_Avpprove(false);
      SetgenerateList(events);
    }
  };
  useEffect(() => {
    const AllSelection = async () => {
      if (all_approve) {
        const filteredRow = rows.filter((self) => self.present_days !== 0);
        SetgenerateList(filteredRow);
        console.log(filteredRow);
      }
    };
    AllSelection();
  }, [all_approve]);
  const AcceptAttendanceTimeSheet = () => {
    UploadTimesheet(generateList);
  };
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Organization
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"organization_id"}
                        // defaultValue={`${
                        //   attendance_dashboard?.organization_id
                        //     ? attendance_dashboard.organization_id
                        //     : ""
                        // }`}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={organization_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"organization_id"}
                            label={"organization_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Organization"}
                            value={!!value ? value : ""}
                            errors={!!errors["organization_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!!watch("organization_id") && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list.filter(
                                (t) =>
                                  t.org_id == +watch("organization_id") &&
                                  (empType.length <= 0 ||
                                    !!empType.includes(t.employee_type_id))
                              )}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), setValue("project_id", null)
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                              errors={!!errors["employee_type_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!!user &&
                  !!watch("employee_type_id") &&
                  environment.global_veriable.ho_employees_type_id.includes(
                    +user.employee_detail.employee_type_id
                  ) &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Project Name
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"project_id"}
                            // defaultValue={`${
                            //   attendance_dashboard?.project_id
                            //     ? attendance_dashboard.project_id
                            //     : ""
                            // }`}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={project_list}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"project_id"}
                                label={"project_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), ProjectSelect(value)
                                )}
                                placeholder={"Search Project"}
                                value={!!value ? value : ""}
                                errors={!!errors["project_id"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4.25}
                      style={{ marginLeft: -14 }}
                      className="datepicker"
                    >
                      {/* <DatePicker
                          value={
                            moment(selectedDate).format("MMMM").toUpperCase() +
                            "/" +
                            moment(selectedDate).format("YYYY").toUpperCase()
                          }
                          dateFormat="MM/yyyy"
                          onChange={(date: Date) => setSelectedDate(date)}
                          showMonthYearPicker
                          placeholderText="Please Select Month"
                          selected={selectedDate}
                        /> */}
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            value={value}
                            InputProps={{
                              onBlur: onBlur,
                              size: "small",
                              style: {
                                width: "125%",
                              },
                            }}
                            onChange={
                              (newValue) => onChange(newValue)
                              // PayrollAvailableEmployeeSuccessAction([]),
                              // SetapprovalList([])
                            }
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      {openDetails == true && (
        <Paper
          elevation={3}
          sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
              <TextField
                size="small"
                sx={{ width: "80%" }}
                placeholder="Search..."
                id="fullWidth"
                value={searched}
                // onChange={(e: any) => (
                //   requestSearch(e.target.value), setSearched(e.target.value)
                // )}
                InputProps={{
                  endAdornment:
                    !!searched && searched.length > 0 ? (
                      <IconButton
                        color="primary"
                        // onClick={() => cancelSearch()}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="primary">
                        <SearchIcon />
                      </IconButton>
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction={"row"} marginTop={1}>
            <Grid item xs={12} padding={2}>
              <Grid
                container
                direction={"row"}
                width={"100%"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="justify" key={""}>
                          <Checkbox
                            style={{ color: "#ffff" }}
                            checked={all_approve}
                            onChange={(event) =>
                              Set_Avpprove(event.target.checked)
                            }
                          />
                        </StyledTableCell>
                        {column1.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows
                          // .slice(
                          //   page * rowsPerPage,
                          //   page * rowsPerPage + rowsPerPage
                          // )
                          .map((row) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.employee_id}
                              style={{
                                backgroundColor:
                                  row.present_days === 0
                                    ? "#ffcccc"
                                    : row.present_days <= row.total_days
                                    ? "#8fbc8f"
                                    : "",
                              }}
                              //   color="red"
                            >
                              <StyledTableCell align="left" key={column1[0]}>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    !!generateList.find(
                                      (m) => m.employee_id == row.employee_id
                                    )
                                  }
                                  onChange={(event) =>
                                    SelectRow(event.target.checked, row)
                                  }
                                />
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[1]}>
                                {row.employee_id}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[2]}>
                                {row.employee_name}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[3]}>
                                {row.total_days}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[4]}>
                                {row.present_days}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[8]}>
                                {row.holidays}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[5]}>
                                {row.absent_days}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[6]}>
                                {row.projects}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[7]}>
                                {row.present_days === 0 ? (
                                  ""
                                ) : (
                                  <Tooltip
                                    title=" Edit & View"
                                    onClick={() => (
                                      goToTimingSheetFromWorklistAction({
                                        employee_id: row.employee_id,
                                        month: `${row.year}-${
                                          row.month > 9
                                            ? row.month
                                            : "0" + row.month
                                        }-01`,
                                      }),
                                      navigate({
                                        pathname: "/timingsheet/log-activity/",
                                        search: "?isworklist=1",
                                      })
                                    )}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      {!!rows && rows.length <= 0 && (
                        <StyledTableRow>
                          <StyledTableCell colSpan={10}>
                            <div style={{ color: "red", textAlign: "center" }}>
                              No Data Found{" "}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  style={styles}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              disabled={generateList.length <= 0}
              onClick={() => AcceptAttendanceTimeSheet()}
              variant="contained"
              color="primary"
              size="large"
            >
              Generate Batch
            </Button>
          </Grid>
        </Paper>
      )}
    </>
  );
}

export default AttendanceTimeSheetView;

interface AttendanceTimeSheetViewProps {
  organization_list: OrganizationALlList[];
  Submit?: any;
  attendance_timesheet_list: AttendanceTimeSheetList[];
  UploadTimesheet?: any;
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
  project_detail?: ViewProject;
  project_list: ProjectList[];
  ProjectSelect?: any;
  goToTimingSheetFromWorklistAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
