import {
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ProjectList, ProjectPaymentList } from "../../../models/customerModel";
import moment from "moment";
import Autocompletes from "../../../library/AutoComplete";
import { InvoiceListResponse } from "../../../models/invoiceModel";
import AutoCompleteMulti from "../../../library/AutoCompleteMulti";

const CreatePaymentView = ({
  project_payment,
  Submit,
  SubmitUpdatedData,
  project_list,
  id,
  invoice_list_details,
}: CreatePaymentViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const onSubmit = (data: any) => {
    console.log("sss", data);
    if (!!project_payment) {
      let payload: ProjectPaymentList = { ...project_payment, ...data };
      SubmitUpdatedData(payload);
    } else {
      Submit(data);
    }
  };
  useEffect(() => {
    if (!!project_payment) {
      setValue(
        "payment_date",
        moment.utc(project_payment.payment_date).format("yyyy-MM-DD")
      );
      setValue(
        "project_payment_with_gst",
        project_payment.project_payment_with_gst
      );
      setValue(
        "project_payment_without_gst",
        project_payment.project_payment_without_gst
      );
      setValue("payment_desc", project_payment.payment_desc);
      // setValue(
      //   "project_payment_invoice_id",
      //   !!project_payment.project_payment_invoice_id ? JSON.parse(project_payment.project_payment_invoice_id) : []
      // );
      // console.log(Object.keys(project_payment.project_payment_invoice_id)  )
      if (!!project_payment.project_payment_invoice_id) {
        let temp: any[] = Object.keys(project_payment.project_payment_invoice_id)?.map((m: any) => !!project_payment.project_payment_invoice_id && project_payment.project_payment_invoice_id[m])
        console.log('temp', temp);
        setValue(
          "project_payment_invoice_id", temp || []

        );
      }

    }
  }, [project_payment]);

  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 5, marginInline: 10 }}>
        <Grid
          container
          direction={"row"}
          // justifyContent={"center"}
          // alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} marginTop={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
              }}
            >
              {!!project_payment
                ? "Payment Details"
                : `Add Payment Information of ${project_list.find((m) => m.project_id === +id)?.project_name
                }`}
            </Typography>
          </Grid>
        </Grid>
        <form>
          <Grid container justifyContent={"center"}>
            <Grid item style={{ marginInline: 10, width: "90%" }}>
              <Divider
                style={{
                  borderColor: "#9E9E9E",
                  opacity: 0.7,
                  width: "90%",
                  marginLeft: "5%",
                }}
              ></Divider>
              {!!project_payment && (
                <Grid
                  container
                  justifyContent="end"
                  alignItems="center"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Switch
                    value={edit_flag}
                    onChange={(event) => SetEditFlag(!event.target.checked)}
                  />
                  <Typography style={{ color: "#A2A2A2" }}>
                    Edit Payment Details
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Payment Date
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"payment_date"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"date"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        inputProps={{
                          readOnly: !!project_payment ? edit_flag : !edit_flag,
                        }}
                        // InputProps={{ inputProps: { min: 1 } }}
                        placeholder={"Payment_Date)"}
                        error={!!errors["payment_date"]}
                        helperText={
                          errors.payment_date &&
                          `${errors.payment_date.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Invoice No.
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"project_payment_invoice_id"}
                    defaultValue={""}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <AutoCompleteMulti
                        hasInfo={false}
                        option={invoice_list_details?.invoice_list || []}
                        keys={"project_payment_invoice_id"}
                        label={"invoice_no"}
                        onBlur={onBlur}
                        readOnly={!!project_payment ? edit_flag : !edit_flag}
                        style={{ width: "100.8%", marginLeft: -5 }}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Invoice No."}
                        value={!!value ? value : ""}
                        errors={!!errors["project_payment_invoice_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Payment Amount(With GST)
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"project_payment_with_gst"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        inputProps={{
                          readOnly: !!project_payment ? edit_flag : !edit_flag,
                        }}
                        // InputProps={{ inputProps: { min: 1 } }}
                        placeholder={"Payment Amount(With GST)"}
                        error={!!errors["project_payment_with_gst"]}
                        helperText={
                          errors.project_payment_with_gst &&
                          `${errors.project_payment_with_gst.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Payment Amount(Without GST)
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"project_payment_without_gst"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        onChange={onChange}
                        value={value}
                        inputProps={{
                          readOnly: !!project_payment ? edit_flag : !edit_flag,
                        }}
                        // InputProps={{ inputProps: { min: 1 } }}
                        placeholder={"Payment Amount(Without GST)"}
                        error={!!errors["payment_amount"]}
                        helperText={
                          errors.project_payment_without_gst &&
                          `${errors.project_payment_without_gst.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 30 }}
              // width={"100%"}
              >
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={5.05}>
                  <Controller
                    control={control}
                    name={"payment_desc"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextareaAutosize
                        style={{
                          width: "96%",
                          height: 100,
                          resize: "none",
                          fontSize: "15px",
                          padding: "2%",
                          outline: "none",
                          border: "1px solid rgba(19,36,55,0.5)",
                          borderRadius: "5px",
                        }}
                        className={"smaller"}
                        placeholder={"Description"}
                        minRows={2}
                        value={value}
                        readOnly={!!project_payment ? edit_flag : !edit_flag}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              {((edit_flag && !project_payment) ||
                (!edit_flag && !!project_payment)) && (
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 4,
                      marginRight: 10,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    <b>{!!project_payment ? "Update" : "Add"}</b>
                  </Button>
                )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default CreatePaymentView;
interface CreatePaymentViewProps {
  project_payment?: ProjectPaymentList;
  Submit?: any;
  SubmitUpdatedData?: any;
  project_list: ProjectList[];
  id: any;
  invoice_list_details?: InvoiceListResponse;
}
