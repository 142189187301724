import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Slide,
  Grid,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Controller, useForm } from "react-hook-form";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { environment } from "../../../environment";
import {
  PendingApprovalList,
  UpdateAttendaceStatusPayload,
} from "../../../models/attendanceModel";
const AttendanceApproveSection = ({
  open,
  setOpen,
  approvalList,
  SetapprovalList,
  FinalApprovedSubmit,
  DomValue,
  setfinalFlag,
  setsubmitFlaga,
}: AttendanceApproveSectionProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const columns: any[] = ["Project", "Month", "Uploaded Attendance", "Action"];
  const [remarks, SetRemarks] = useState<string>("");
  const [Mystep, SetStep] = useState<string[]>([]);
  const removeAttendanceBatch = (row: PendingApprovalList) => {
    let temp: PendingApprovalList[] = JSON.parse(JSON.stringify(approvalList));
    temp = temp.filter((m) => m.empLifeEventId != row.empLifeEventId);
    SetapprovalList(temp);
    if (temp.length <= 0) setOpen(false);
  };
  const onSubmit = async (data: any, k: number, p: number) => {
    // console.log(data, k);
    let payload: UpdateAttendaceStatusPayload = {
      remarks: data,
      approved_list: approvalList.map((m) => ({
        project_attendence_id: m.project_attendence_id,
        empLifeEventId: m.empLifeEventId,
      })),
      event_action_items: approvalList[0].event_action_items,
      status: k,
    };
    FinalApprovedSubmit(payload);
    setfinalFlag(true);
    setsubmitFlaga(p);
    // console.log(payload);
  };
  useEffect(() => {
    if (open && approvalList.length > 0) {
      SetStep(approvalList[0].event_action_items);
      reset();
    }
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#0060FF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Verify and Approve
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container height={"92%"} flexGrow={1} flexDirection={"row"}>
        <Grid
          item
          xs={5.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          {approvalList[0]?.project_name !=
            environment.global_veriable.ho_project_name && (
            <Paper
              sx={{
                flexGrow: 1,
                borderRadius: 1,
                border: "1px solid #E0E0E0",
                margin: 2,
              }}
            >
              <Grid
                container
                flexDirection={"row"}
                style={{ border: "1px solid #E0E0E0" }}
              >
                <Grid item xs={4} style={{ padding: 5 }}>
                  <Typography style={{ fontWeight: "600" }}>
                    Project Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ backgroundColor: "#F4F7F6", padding: 5 }}
                >
                  <Typography>{approvalList[0]?.project_name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              border: "1px solid #E0E0E0",
              margin: 2,
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!approvalList &&
                    approvalList.map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.project_attendence_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.project_name}
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" key={columns[1]}>
                          {row.division_name}
                        </StyledTableCell> */}
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.month}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          {row.uploaded_employee_count}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          <Tooltip
                            title="Remove"
                            onClick={() => removeAttendanceBatch(row)}
                          >
                            <IconButton color="error">
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid
          item
          xs={6.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          <div style={{ margin: 5, padding: 10 }}>
            <Grid
              style={{ width: "50%", backgroundColor: "#FF6400", padding: 5 }}
            >
              <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                Remarks
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 5 }}>
              {/* <Controller
                control={control}
                name={"remarks"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => ( */}
              <TextField
                multiline
                variant="outlined"
                // className={"smaller"}
                style={{ width: "100%" }}
                // onBlur={onBlur}
                size="small"
                value={remarks}
                onChange={(event) => SetRemarks(event.target.value)}
                error={!!errors.remarks}
                placeholder={"Remarks..."}
                InputProps={{
                  inputComponent: TextareaAutosize,
                  style: { minHeight: 80 },
                }}
                //helperText={!!errors.remarks && `* This field is Required`}
              />
              {/* )}
              /> */}
            </Grid>
          </div>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Grid item xs={3}>
              <Button
                sx={{
                  color: "#fff",
                  //backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => onSubmit(remarks, 2, 1)}
                variant="contained"
                color="error"
                size="large"
                disabled={!remarks || remarks == ""}
              >
                Reject
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => onSubmit(remarks, 1, 2)}
                variant="contained"
                color="primary"
                size="large"
              >
                Approve
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AttendanceApproveSection;

interface AttendanceApproveSectionProps {
  open: boolean;
  setOpen?: any;
  approvalList: PendingApprovalList[];
  SetapprovalList?: any;
  DomValue?: any;
  FinalApprovedSubmit?: any;
  setfinalFlag?: any;
  setsubmitFlaga?: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
