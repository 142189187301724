import { Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { LoadingState } from "../../models/loadingModels";
import { StoreState } from "../../models/reduxModels";

const Skeletons = ({ loading }: LoadingProps) => {
  const [state, SetState] = useState<number>(0);
  useEffect(() => {
    if (!!loading && loading.count > 0 && loading.type > 0) {
      SetState(loading.type);
    } else {
      SetState(0);
    }
  }, [loading]);
  return (
    <>
      {state == 1 ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            width: "calc(100% - 300px)",
            height: "100%",
            top: 0,
            left: 300,
            right: 0,
            bottom: 0,
            backgroundColor: "#ffff",
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={30}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={2.2}>
              <Skeleton
                variant="circular"
                width={150}
                height={150}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={8.8}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={250}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={150}
                style={{ margin: 10 }}
              />
            </Grid>
            <Grid item xs={5.5}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={150}
                style={{ margin: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={30}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={30}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={30}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
            <Grid item xs={11}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={5}
                style={{ marginTop: 10 }}
              />
            </Grid>
          </Grid>
        </div>
      ) : state == 2 ? (
        <div
          style={{
            position: "fixed",
            display: "block",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 100px)",
              left: "calc(50% - 100px)",
              height: "200px",
              width: "200px",
              textAlign: "center",
            }}
          >
            <ReactLoading
              type={"cylon"}
              color={"#fff"}
              height={"70%"}
              width={"90%"}
            />
            <div style={{ color: "#fff" }}>{loading?.message}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    loading: state.loading,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Skeletons);

interface LoadingProps {
  loading: LoadingState;
}
