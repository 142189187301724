import { InvoiceMain } from "../../models/invoiceModel";
import { InvoiceActionTypes } from "../actions/invoiceAction";
import InitialState from "./initialState";

const initialState: InvoiceMain = InitialState.invoice;
export default function InvoiceReducer(
  state: InvoiceMain = initialState,
  action: any
) {
  switch (action.type) {
    case InvoiceActionTypes.Invioce_List_Success_Action:
      return { ...state, invoice_list_details: action.payload };
    case InvoiceActionTypes.Edit_Invioce_Success_Action:
      return { ...state, invoice_edit: action.payload };
    default:
      return state;
  }
}
