import React from "react";
import { connect } from "react-redux";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { AttendanceDetailPayloadSuccessAction } from "../../../stores/actions/attendanceAction";
import {
  GetAllCustomerAction,
  GetAllProjectAction,
} from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import AttendanceUploadView from "./AttendanceUploadView";

const AttendanceUpload = ({
  organization_list,
  GetAllOrganizationAction,
  GetAllProjectAction,
  project_list,
  GetAllCustomerAction,
  customer_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  accessToken, 
  user,
  AttendanceDetailPayloadSuccessAction
}: AttendanceUploadProps) => {
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllProjectAction({
      organization_id: null,
      customer_id: null,
    });
    GetAllCustomerAction({
      organization_id: null,
    });
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
  }, []);
  return (
    <AttendanceUploadView
      organization_list={organization_list}
      project_list={project_list}
      customer_list={customer_list}
      employee_type_list={employee_type_list}
      accessToken={accessToken}
      user={user}
      AttendanceDetailPayloadSuccessActions={AttendanceDetailPayloadSuccessAction}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    project_list: state.customer.project_list,
    customer_list: state.customer.customer_list,
    employee_type_list: state.organization.employee_type_list,
    accessToken: state.user.userDetails?.accessToken,
    user: state.user.userDetails
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllProjectAction,
  GetAllCustomerAction,
  GetAllEmployeeTypeAction,
  AttendanceDetailPayloadSuccessAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceUpload);

interface AttendanceUploadProps {
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
  GetAllProjectAction?: any;
  GetAllCustomerAction?: any;
  GetAllEmployeeTypeAction?: any;
  project_list: ProjectList[];
  customer_list: CustomerList[];
  employee_type_list: EmployeeTypeList[];
  accessToken?: string;
  user?: userDetails;
  AttendanceDetailPayloadSuccessAction?: any;
}
