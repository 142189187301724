import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  AllDepartmentList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteDepartmentAction,
  GetAllDepartmentAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import DepartmentView from "./DepartmentView";

function DepartmentMain({
  GetAllOrganizationAction,
  organization_list,
  GetAllDepartmentAction,
  department_list,
  DeleteDepartmentAction,
  SidenavAutoCloseOpen
}: DepartmentMainProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllDepartmentAction({ organization_id: null });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const filterData = (props: any) => {
    GetAllDepartmentAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
  };
  const Clear = () => {
    GetAllDepartmentAction({
      organization_id: null,
    });
  };
  const DeleteAction = (props: number) => {
    DeleteDepartmentAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <DepartmentView
      organization_list={organization_list}
      department_list={department_list}
      filterData={filterData}
      Clear={Clear}
      // Delete={Delete}
      DeleteAction={DeleteAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    department_list: state.organization.department_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllDepartmentAction,
  DeleteDepartmentAction,
  SidenavAutoCloseOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentMain);

interface DepartmentMainProps {
  GetAllOrganizationAction?: any;
  organization_list?: OrganizationALlList[];
  GetAllDepartmentAction?: any;
  department_list: AllDepartmentList[];
  DeleteDepartmentAction?: any;
  SidenavAutoCloseOpen?: any;
}
