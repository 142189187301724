import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import Autocompletes from "../../../library/AutoComplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  AllDepartmentList,
  AllDesignationList,
  AllPostList,
  DomainList,
  EmployeeTimingList,
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import BulkUploadPopover from "./BulkUploadPopover";
import {
  CustomerList,
  DivisionList,
  ProjectLevels,
  ProjectList,
  StateList,
  SubDivisionList,
  ViewProject,
} from "../../../models/customerModel";
import { useSnackbar } from "notistack";
import { baseUrl, environment } from "../../../environment";
import Axios from "axios";
import { userDetails } from "../../../models/userModels";
import ImageUploader from "./ImageUploader";
import FormFields from "./FormFields";
import {
  ViewEmployee,
  ReportingManagerList,
  bankList,
} from "../../../models/employeeModel";
import { log } from "console";

function AddEmployeeview({
  Submit,
  organization_list,
  designation_list,
  employee_type_list,
  department_list,
  employee_timing_list,
  customer_list,
  project_list,
  post_list,
  user,
  BeginApiCallAction,
  CreateCandidateSuccessAction,
  OrganizationSelected,
  ReportingManagerList,
  attendence_salary_type,
  salary_structure_list,
  OnChangeCustomer,
  OnprojectSelect,
  SlaryStructureChange,
  project_detail,
  salary_structure,
  bank_list,
  project_levels,
  state_list,
}: AddEmployeeviewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = React.useState<boolean>(false);
  const [checked, setChecked] = useState(false);
  const [applicabledeg, setapplicatbleDeg] = useState<number[]>([]);
  const [documents, SetDocument] = useState<any[]>([]);
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);
  const [submit_payload, SetsubmitPayload] = useState<any>();
  const [addEduDetail, setaddEduDetail] = useState<any>([
    {
      course_name: null,
      board_name: null,
      // remarks: null,
      subject: null,
      passing_year: null,
      total_marks: null,
      marks_obtained: null,
    },
  ]);
  const [addExpDetail, setaddExpDetail] = useState<any>([
    {
      company_name: null,
      project_name: null,
      designation: null,
      started_at: null,
      ended_at: null,
      ctc: null,
      remarks: null,
    },
  ]);
  const [addProfessionalQualification, setAddProfessionalQualification] = useState<any>([
    {
      course_name: null,
      start_date: null,
      end_date: null,
      remarks: null,
    },
  ]);
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const onSubmit = (data: any) => {
    let payload: any = {
      ...data,
      present_address: {
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        city: data.city,
        district: data.district,
        state: data.state,
        country: data.country,
        pin: data.pin,
      },
      permanent_address: {
        address_line1: data.paddress_line1,
        address_line2: data.paddress_line2,
        city: data.pcity,
        district: data.pdistrict,
        state: data.pstate,
        country: data.pcountry,
        pin: data.ppin,
      },
      experience:
        addExpDetail.length == 1
          ? !!addExpDetail[0].company_name
            ? addExpDetail
            : []
          : addExpDetail.length > 1
            ? addExpDetail
            : [],
      education:
        addEduDetail.length == 1
          ? !!addEduDetail[0].course_name
            ? addEduDetail
            : []
          : addEduDetail.length > 1
            ? addEduDetail
            : [],
      professional_details:
        addProfessionalQualification.length == 1
          ? !!addProfessionalQualification[0].course_name
            ? addProfessionalQualification
            : []
          : addProfessionalQualification.length > 1
            ? addProfessionalQualification
            : [],
      documents_details: [],
    };
    delete payload.address_line1;
    delete payload.address_line2;
    delete payload.city;
    delete payload.district;
    delete payload.state;
    delete payload.country;
    delete payload.pin;
    delete payload.paddress_line1;
    delete payload.paddress_line2;
    delete payload.pcity;
    delete payload.pdistrict;
    delete payload.pstate;
    delete payload.pcountry;
    delete payload.ppin;
    if (!!payload.aadhar_no) {
      payload.documents_details.push({
        document_type: "AADHAR",
        document_no: payload.aadhar_no,
        document_image: "",
      });
    }
    let additional_field: any[] = [];
    let has_error: boolean = false
    if (
      !!project_detail &&
      project_detail.project_fields &&
      project_detail.project_fields.length > 0
    ) {
      project_detail.project_fields.forEach((m) => {
        if (!!payload[m.field_name.split(" ").join("_").replace("'", "")]) {
          let addlf1: any = payload[m.field_name.split(" ").join("_").replace("'", "")];
          let addlf2: any = payload[m.field_name.split(" ").join("_").replace("'", "") + "_file"];
          if (!!m.uploadRequired && !addlf2) {
            has_error = true
            enqueueSnackbar(`${m.field_name} document is required.`, {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            return true
          } else {
            additional_field.push({
              [m.field_name.split(" ").join("_").replace("'", "")]: addlf1,
              [m.field_name.split(" ").join("_").replace("'", "") + "_file"]: !!addlf2 ? addlf2 : null,
            });
            delete payload[m.field_name.split(" ").join("_").replace("'", "")];
            delete payload[m.field_name.split(" ").join("_").replace("'", "") + "_file"];
          }
        }
      });
    }
    payload.additional_field = additional_field;
    if (!!payload.epic_no) {
      payload.documents_details.push({
        document_type: "VOTAR",
        document_no: payload.epic_no,
        document_image: "",
      });
    }
    if (!!payload.pan_no) {
      payload.documents_details.push({
        document_type: "PAN",
        document_no: payload.pan_no,
        document_image: "",
      });
    }
    payload.documents_details.forEach((m: any) => {
      m.document_image = !!documents.find(
        (m) => m.document_type == m.document_type
      )
        ? documents.find((m) => m.document_type == m.document_type)
          .document_image
        : "";
    });
    delete payload.aadhar_no;
    delete payload.epic_no;
    delete payload.pan_no;
    payload.projects = [
      {
        customer_id: payload.customer_id,
        project_id: payload.project_id,
        post_id: payload.post_id,
        reporting_manager: payload.reporting_manager,
        level1_id: payload.level1_id,
        level2_id: payload.level2_id,
        level3_id: payload.level3_id,
        level4_id: payload.level4_id,
        level5_id: payload.level5_id,
      },
    ];
    delete payload.customer_id;
    delete payload.project_id;
    delete payload.level1_id;
    delete payload.level2_id;
    delete payload.level3_id;
    delete payload.level4_id;
    delete payload.level5_id;
    delete payload.post_id;
    delete payload.reporting_manager;
    delete payload.salary_detail;
    if (
      payload.documents_details.length <= 0 ||
      payload.documents_details.find((m: any) => m.document_type == "AADHAR")
        ?.document_image == ""
    ) {
      enqueueSnackbar("Aadhar document is mandatory", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else if (!has_error) {
      SetsubmitPayload(payload);
      setsubmitFlag(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (project_detail?.projectmanager_id) {
      setapplicatbleDeg(project_detail?.applicable_designation);
    }
  }, [project_detail]);

  const fileHandleEvent = (event: any, type: string) => {
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${event.target.files[0].name
        }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `NEW_CAD`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            let doc = JSON.parse(JSON.stringify(documents));
            if (!!doc.find((m: any) => m.document_type == type)) {
              doc.find((m: any) => m.document_type == type).document_image =
                file_name;
            } else {
              doc.push({
                document_type: type,
                document_no: "",
                document_image: file_name,
              });
            }

            SetDocument(doc);
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const bulkUploadEmployee = (formsData: any) => {
    if (!!user) {
      console.log(
        "submit : ",
        Object.entries(formsData).filter(
          ([key, value]: [key: string, value: any]) => key !== "bulk_file"
        )
      );
      const formData = new FormData();
      Object.entries(formsData)
        .filter(
          ([key, value]: [key: string, value: any]) => key !== "bulk_file"
        )
        .map(([key, value]: [key: string, value: any]) => {
          console.log("form data hit");
          formData.append(key, value);
        });
      if (formsData?.bulk_file) {
        const file: any = formsData?.bulk_file;
        const file_name: any = `${new Date().getTime()}${file.name}`;
        console.log("file : ");
        formData.append("fileName", file_name);
        formData.append("file", file);
      }
      console.log("formData : ", formData);
      // formData.append("fileName", file_name);
      // formData.append("organization_id",formsData.organization_id)
      // formData.append("file", file);
      // BeginApiCallAction({
      //   count: 1,
      //   message: "Uploading image. Please Wait...",
      //   type: 2,
      // });
      // Axios.post(baseUrl + environment.urls.uploadBulkEmployee, formData, {
      //   headers: {
      //     Authorization: "Bearer " + user.accessToken,
      //   },
      // })
      //   .then((response) => {
      //     if (response.status == 200) {
      //       enqueueSnackbar("Employee List created", {
      //         variant: "success",
      //       });

      //     } else {
      //       enqueueSnackbar("Something wrong!", {
      //         variant: "error",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     enqueueSnackbar("Something wrong!", {
      //       variant: "error",
      //     });
      //     CreateCandidateSuccessAction();
      //   });
    }

  };
  const FileDownload = require("js-file-download");

  const AddlfileHandleEvent = (event: any, type: string) => {
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${event.target.files[0].name
        }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `NEW_CAD`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            setValue(type, file_name);
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }

  }
  const downloadImage = (file_name: any) => {
    if (!!file_name) {
      console.log(file_name);

      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: `NEW_CAD`,
          fileName: file_name,
        },
      })
        .then(async (response) => {
          await FileDownload(response.data, file_name);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };
  return (
    <Paper elevation={5} sx={{ marginTop: 5, marginInline: 10 }}>
      <BulkUploadPopover
        open={open}
        handleClose={handleClose}
        handleFormSubmit={bulkUploadEmployee}
        ReportingManagerList={ReportingManagerList}
        applicabledeg={applicabledeg}
        department_list={department_list}
        designation_list={designation_list}
        employee_timing_list={employee_timing_list}
        employee_type_list={employee_type_list}
        organization_list={organization_list}
        salary_structure_list={salary_structure_list}
        OrganizationSelected={OrganizationSelected}
        customer_list={customer_list}
        OnChangeCustomer={OnChangeCustomer}
        project_list={project_list}
        OnprojectSelect={OnprojectSelect}
        project_detail={project_detail}
        post_list={post_list}
        SlaryStructureChange={SlaryStructureChange}
        salary_structure={salary_structure}
        attendence_salary_type={attendence_salary_type}
        documents={[]}
        addExpDetail={[]}
        state_list={state_list}
      />
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/employment/employee-list/`)}
            style={{ marginLeft: 15 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            Candidate Onboarding Form
            {/* {!!department ? "Department Details" : "Add Department"} */}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "98%",
          marginLeft: "1%",
          marginRight: "1%",
        }}
      ></Divider>
      <Dialog
        open={submit_flag}
        onClose={() => setsubmitFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit this candidate's application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setsubmitFlag(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => Submit(submit_payload)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <form>
        <FormFields
          applicabledeg={applicabledeg}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          department_list={department_list}
          designation_list={designation_list}
          documents={documents}
          post_list={post_list}
          project_list={project_list}
          addEduDetail={addEduDetail}
          fileHandleEvent={fileHandleEvent}
          watch={watch}
          addExpDetail={addExpDetail}
          employee_type_list={employee_type_list}
          customer_list={customer_list}
          employee_timing_list={employee_timing_list}
          bank_list={bank_list}
          organization_list={organization_list}
          OrganizationSelected={OrganizationSelected}
          ReportingManagerList={ReportingManagerList}
          salary_structure_list={salary_structure_list}
          setaddEduDetail={setaddEduDetail}
          SetDocument={SetDocument}
          setaddExpDetail={setaddExpDetail}
          OnChangeCustomer={(data: number) =>
            OnChangeCustomer(data, +getValues("organization_id"))
          }
          OnprojectSelect={OnprojectSelect}
          project_detail={project_detail}
          SlaryStructureChange={SlaryStructureChange}
          salary_structure={salary_structure}
          attendence_salary_type={attendence_salary_type}
          getValues={getValues}
          setValue={setValue}
          handleClickOpen={handleClickOpen}
          project_levels={project_levels}
          user={user}
          state_list={state_list}
          addProfessionalQualification={addProfessionalQualification}
          setAddProfessionalQualification={setAddProfessionalQualification}
          AddlfileHandleEvent={AddlfileHandleEvent}
          downloadImage={downloadImage}
        />
      </form>
    </Paper>
  );
}

export default AddEmployeeview;

interface AddEmployeeviewProps {
  Submit?: any;
  organization_list: OrganizationALlList[];
  designation_list: AllDesignationList[];
  employee_type_list: EmployeeTypeList[];
  employee_timing_list: EmployeeTimingList[];
  department_list: AllDepartmentList[];
  customer_list: CustomerList[];
  project_list: ProjectList[];
  post_list: AllPostList[];
  user?: userDetails;
  BeginApiCallAction?: any;
  CreateCandidateSuccessAction?: any;
  OrganizationSelected?: any;
  ReportingManagerList: ReportingManagerList[];
  salary_structure_list: SalaryStructureList[];
  OnChangeCustomer?: any;
  OnprojectSelect?: any;
  project_detail?: ViewProject;
  SlaryStructureChange?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  bank_list: bankList[];
  project_levels?: ProjectLevels;
  state_list: StateList[];

}
