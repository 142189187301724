import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { useSnackbar } from "notistack";

const DeleteLeaveDialog: FC<IDeleteDialog> = ({
  isOpen,
  setIsOpen,
  deloption,
  setDelOption,
  deleteLeaveManagementAction,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = (): void => {
    setIsOpen(false);
  };
  const delRequest = () => {
    const payload = {
      leave_management_id: deloption?.leave_management_id,
      designation_id: deloption?.designation_id,
      enqueueSnackbar,
    };
    deleteLeaveManagementAction(payload);
    handleClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are You Sure</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you delete then you can't revert this
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={delRequest}>Yes</Button>
        <Button onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface IDeleteDialog {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  deleteLeaveManagementAction?: any;
  deloption: { leave_management_id: number; designation_id: number } | null;
  setDelOption: Dispatch<
    SetStateAction<{
      leave_management_id: number;
      designation_id: number;
    } | null>
  >;
}

export default DeleteLeaveDialog;
