import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { environment } from "../../environment";
import AddDepartment from "./AddDepartment";
import AddDesignation from "./AddDesignation";
import AddOrganization from "./AddOraganization";
import AddPost from "./AddPost";
import AddSalaryCompnent from "./AddSalaryComponent";
import AddSalaryStructure from "./AddSalaryStructure";
import DepartmentMain from "./DepartmentMain";
import EmployeeTimingMain from "./EmployeeTimingMain";
import EmployeeTypeMain from "./EmployeeTypeMain";
import OrganizationMain from "./OrganizationMain";
import PostMain from "./PostMain";
import SalaryComponent from "./SalaryComponent";
import SalaryStructureMain from "./SalaryStructureMain";
import SetupDesignation from "./SetupDesignation";
import SetupEmployeeTiming from "./SetupEmployeeTiming";
import SetupEmployeeType from "./SetupEmployeeType";

const Organization = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              // color: "#50535A",
              // fontWeight: "bold",
              // fontFamily: "Poppins, sans-serif",
              // fontSize: 18,
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              // color: "#D4D7DD",
              // fontSize: 14,
              // fontFamily: "Poppins, sans-serif",
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="organization-main" element={<OrganizationMain />} />
        <Route
          path="add-salary-structure/:id"
          element={<AddSalaryStructure />}
        />
        <Route path="add-organization-new/:id" element={<AddOrganization />} />
        <Route path="setup-department" element={<DepartmentMain />} />
        <Route path="setup-designation" element={<SetupDesignation />} />
        <Route
          path="add-employee-timing/:id"
          element={<SetupEmployeeTiming />}
        />
        <Route path="setup-employee-type/:id" element={<SetupEmployeeType />} />
        <Route path="add-department/:id" element={<AddDepartment />} />
        <Route path="add-designation/:id" element={<AddDesignation />} />
        <Route path="employee-timing-list" element={<EmployeeTimingMain />} />
        <Route path="employee-type-list" element={<EmployeeTypeMain />} />
        <Route path="salary-structure-list" element={<SalaryStructureMain />} />
        <Route path="salary-component" element={<SalaryComponent />} />
        <Route
          path="add-salary-component/:id"
          element={<AddSalaryCompnent />}
        />
        <Route path="add-post/:id" element={<AddPost />} />
        <Route path="post-list" element={<PostMain />} />
        <Route
          path="/"
          element={
            <Navigate to={`/${pathname.split("/")[1]}/organization-main`} />
          }
        />
        <Route
          path="/"
          element={
            <Navigate to={`/${pathname.split("/")[1]}/add-organization`} />
          }
        />
      </Routes>
    </>
  );
};

export default Organization;
