import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PendingApprovalList } from "../../../models/attendanceModel";
import { FilterList, Clear } from "@mui/icons-material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { environment } from "../../../environment";
import EditIcon from "@mui/icons-material/Edit";
import { PromotionApprovalList, ViewPromotion } from "../../../models/promotionModel";
import PromotionApproveSection from "./PromotionApproveSection";
import {
  AllDesignationList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";

const PendingPromotionListView = ({
  pending_promtions,
  ApprovedAction,
  salary_structure_list,
  SalaryComponentEditAction,
  designation_list,
  salary_structure,
  ListofPendingPromotion,
  view_promotion,
  GetAllDesignationAction,
  GetAllSalaryStructureAction,
  RemoveEmpPromotion
}: // AttendanceDetailPayloadSuccessAction,
PendingPromotionListViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [rows, setRows] = useState<PromotionApprovalList[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const open = Boolean(anchorEl);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!!pending_promtions) {
      setRows(pending_promtions);
    }
  }, [pending_promtions]);

  const column1: any[] = [
    "Promotion Revision Batch",
    "Employee Count",
    // "Project",
    "Initiated By",
    "Initiated On",
    "Status",
    "Action",
    // "Action",
  ];
  const [approvalList, SetapprovalList] = useState<ViewPromotion[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openPromotionPopup, setOpenPromotionPopup] = React.useState(false);
  const AcceptPendingPromotion = (data: any) => {
    setOpenPromotionPopup(true);
    ListofPendingPromotion(data)
  };
  const DomValue = (st: string) => {
    return environment.event_action_items.find((m) => m.key == st)?.value;
  };
  // const [submit_payload, SetsubmitPayload] = useState<any>();
  const [searched, setSearched] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!pending_promtions
      ? pending_promtions.filter((row: PromotionApprovalList) => {
          return (
            row?.batch_status_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.promotion_salary_batch_id
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.created_by?.toLowerCase().includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const [final_flag, setfinalFlag] = useState<boolean>(false);
  const [submit_flaga, setsubmitFlaga] = useState<number>(0);
  useEffect(() => {
    if (!!pending_promtions) {
      cancelSearch();
    }
  }, [pending_promtions]);

  const [approvePayload, setapprovePayload] = useState<any>();
  const FinalApprovedSubmit = (data: any) => {
    setapprovePayload(data);
    console.log("data", data);
    setfinalFlag(true);
  };
  const AprrovedPromotionAction = (data: any) => {
    // console.log(data);

    ApprovedAction(data);
  };

  return (
    <Paper
      elevation={3}
      sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
    >
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        // style={{ textAlign: "center", paddingTop: 8 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Dialog
        open={final_flag}
        onClose={() => setfinalFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${
              submit_flaga == 1 ? "reject" : "approve"
            } this attendance?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setfinalFlag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (
              AprrovedPromotionAction(approvePayload),
              setfinalFlag(false),
              setOpenPromotionPopup(false),
              SetapprovalList([])
            )}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <PromotionApproveSection
        approvalList={approvalList}
        open={openPromotionPopup}
        setOpen={setOpenPromotionPopup}
        SetapprovalList={SetapprovalList}
        DomValue={DomValue}
        FinalApprovedSubmit={FinalApprovedSubmit}
        setfinalFlag={setfinalFlag}
        setsubmitFlaga={setsubmitFlaga}
        salary_structure_list={salary_structure_list}
        SalaryComponentEditAction={SalaryComponentEditAction}
        designation_list={designation_list}
        salary_structure={salary_structure}
        view_promotion={view_promotion}
        pending_promtions={pending_promtions}
        GetAllDesignationAction={GetAllDesignationAction}
        GetAllSalaryStructureAction={GetAllSalaryStructureAction}
        RemoveEmpPromotion={RemoveEmpPromotion}
      />
      <Grid container direction={"row"} marginTop={1}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.promotion_salary_batch_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.promotion_salary_batch_id}
                          </StyledTableCell>
                          <StyledTableCell align="center" key={column1[1]}>
                            {row.emp_count}
                          </StyledTableCell>
                          {/* <StyledTableCell align="left" key={column1[2]}>
                            {row.project_name}
                          </StyledTableCell> */}
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.created_by +" ("+ row.created_by_name+")"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            {row.created_on
                              ? moment(row.created_on)
                                  .format("DD-MM-YYYY")
                                  .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[5]}>
                            {row.batch_status_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[6]}>
                            <Tooltip
                              title=" Edit & View"
                              onClick={() =>
                                AcceptPendingPromotion(row.promotion_salary_batch_id)
                              }
                            >
                              <IconButton>
                              <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={14}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        <Button
          sx={{
            color: "#fff",
            backgroundColor: "#1565C0",
            paddingX: 4,
            marginRight: 10,
          }}
          disabled={approvalList.length <= 0}
          onClick={() => AcceptPendingPromotion()}
          variant="contained"
          color="primary"
          size="large"
        >
          Approve / Reject
        </Button>
      </Grid> */}
    </Paper>
  );
};

export default PendingPromotionListView;

interface PendingPromotionListViewProps {
  pending_promtions: PromotionApprovalList[];
  ApprovedAction?: any;
  // AttendanceDetailPayloadSuccessAction?: any;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  designation_list: AllDesignationList[];
  salary_structure?: SalaryStructureEditDetails;
  ListofPendingPromotion?: any;
  view_promotion: ViewPromotion[];
  GetAllDesignationAction?: any;
  GetAllSalaryStructureAction?: any;
  RemoveEmpPromotion?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
