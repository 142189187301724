import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocompletes from "../../../library/AutoComplete";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Controller, useForm } from "react-hook-form";
import {
  CustomerList,
  Division,
  ILevelHierarchy,
  ILevelHierarchyMain,
  lev,
  Level,
  ProjectAttendanceTemplate,
  ProjectLevels,
  ProjectList,
  StateList,
  Subdivision,
  ViewProject,
} from "../../../models/customerModel";
import DivisionPopover from "./DivisionPopover";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  AllDesignationList,
  AllPostList,
} from "../../../models/organizationModels";
import moment from "moment";
import { ReportingManagerList } from "../../../models/employeeModel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SubDivisionPopover from "./SubDivisionPopover";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdditionalFields from "./AdditionalFields";
import TemplateCard from "./TemplateCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { TreeView, TreeItem } from "@mui/lab";
import { Project1 } from "./../../../models/employeeModel";
import HierachyModal from "./HierachyModal";
import { IProjectDashboardDetails } from "../../../interfaces/projectDashboard.interface";
import { LevelTree } from "./../../../models/customerModel";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
import { ProjectDocumentsTypes } from "../../../interfaces/others.interface";
import ProjectDocumentsModal from "./ProjectDocumentsModal";
const AddProjectView = ({
  customer_list,
  designation_list,
  post_list,
  Submit,
  project,
  customer,
  selected_customer,
  SubmitUpdatedData,
  onChangeCustomer,
  ReportingManagerList,
  Setdocument,
  document,
  fileHandleEvent,
  downloadImage,
  project_attendance_template,
  project_levels,
  fieldsMaster,
  setField,
  projectId,
  getLevelHierarchyAction,
  levelHierarchy,
  loadingCounter,
  getLevelTargetRateValueAction,
  levelsTargetGetValue,
  projectDashboardDetails,
  clearTargetRateValueAction,
  GetProjectDashboardAction,
  getLevelDetailsAction,
  levelTree,
  state_list,
  user,
  deleteDocumentHandler,
}: AddProjectViewProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [fetchedAttendanceFields, setFetchedAttendanceFields] = useState<
    number[]
  >([]);
  const open = Boolean(anchorEl);
  const [expanded, setExpanded] = useState<string[]>([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        : []
    );
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const columns: any[] = ["No.", "Division Name", "Action"];
  const [division, Setdivision] = useState<Division[]>([]);
  const [Subdivision, SetSubdivision] = useState<string | undefined>();
  const [Subdivisionpop, SetSubdivisionpop] = useState<boolean>(false);
  const [levelmaster, SetLevel] = useState<lev[]>([]);
  const [levelDialogOpen, setLevelDialogOpen] = useState<boolean>(false);
  const [docsDialogOpen, setDocsDialogOpen] = useState<boolean>(false);
  const [levelMapper, setLevelMapper] = useState<any[]>([]);
  const [showAlertForUploadDoc, setShowAlertUploadDoc] =
    useState<boolean>(false);

  console.log("project_attendance_template : ", project_attendance_template);
  // const divisionSubmit = (data: any) => {
  //   // if (!!division.find((m) => m.division_name === data.division_name)) {
  //   //   enqueueSnackbar("Division name already exists.", {
  //   //     variant: "warning",
  //   //     anchorOrigin: {
  //   //       vertical: "top",
  //   //       horizontal: "right",
  //   //     },
  //   //   });
  //   // } else {
  //   //   let div: Division[] = JSON.parse(JSON.stringify(division));
  //   //   div.push(data);
  //   //   Setdivision(div);
  //   // }
  // };

  // const deleleDivision = (division_name: string) => {
  //   let div: Division[] = JSON.parse(JSON.stringify(division));
  //   div = div.filter((m) => m.division_name != division_name);
  //   Setdivision(div);
  // };
  // const AddSubDivision = (row: Subdivision[]) => {
  //   let div: Division[] = JSON.parse(JSON.stringify(division));
  //   let subd: any = div.find((m) => m.division_name == Subdivision);
  //   subd.subdivision = row;
  //   Setdivision(div);
  // };

  // const deleteSubDivision = (row: string) => {
  //   let div: Division[] = JSON.parse(JSON.stringify(division));
  //   let target_div: Division | undefined = div.find(
  //     (m) => m.division_name === Subdivision
  //   );
  //   if (!!target_div) {
  //     target_div.subdivision = target_div.subdivision.filter(
  //       (m) => m.sub_division_name != row
  //     );
  //   }
  //   console.log(div);

  //   Setdivision(div);
  // };
  // console.log("attendance_fields", getValues("attendance_fields"));
  // console.log("levelHierarchy : ", levelHierarchy);

  useEffect(() => {
    if (!!project) {
      // console.log({ project });
      if (project?.project_fields && Array.isArray(project.project_fields)) {
        setField(project.project_fields);
      }
      setValue("customer_id", project.customer_id);
      setValue("project_name", project.project_name);
      setValue("work_order_no", project.work_order_no);
      setValue("project_code", project.project_code);
      // setValue("end_date", project.end_date);
      setValue(
        "start_date",
        moment.utc(project.start_date).format("yyyy-MM-DD")
      );
      setValue("end_date", moment.utc(project.end_date).format("yyyy-MM-DD"));
      setValue("applicable_post", project.applicable_post);
      setValue("applicable_designation", project.applicable_designation);
      setValue("projectmanager_id", project.projectmanager_id);
      setValue("project_partner", project.project_partner);
      setValue("project_copartner", project.project_copartner);
      // setValue("attendance_fields", project.attendance_fields);
      // setValue("state_name", project.state_name);
      if (
        project?.documents &&
        Array.isArray(project.documents) &&
        project.documents.length
      ) {
        Setdocument([...project.documents]);
      }
      // console.log("sdfasd", project.state_name);
    }
    console.log("project.attendance_fields", project?.attendance_fields);
    console.log("project : ", project);
  }, [project]);
  console.log("fieldsMaster", fieldsMaster);

  useEffect(() => {
    // console.log("useeffect hit");
    if (!!project && !!project_levels && project_levels?.data.length > 0) {
      setValue("level_key_id", project.level_key_id);
    }
    // console.log({ project_levels });
    // console.log(getValues("level_key_id"));
  }, [project, project_levels?.data?.length]);

  useEffect(() => {
    if (!!selected_customer) {
      setValue("customer_id", selected_customer);
      onChangeCustomer(selected_customer);
    }
  }, [selected_customer]);

  useEffect(() => {
    !!project_attendance_template &&
      setValue(
        "attendance_fields",
        project_attendance_template
          .filter((m) => m.default_flag == 1)
          .map((n) => n.attendence_template_field_id)
      );
  }, [project_attendance_template]);

  // useEffect(() => {
  //   return () => {
  //     ClearLevelsFromPorject();
  //   };
  // }, []);

  const onClikSub = (id: string | undefined) => {
    if (!!id) {
      SetSubdivision(id);
      SetSubdivisionpop(true);
    }
  };
  // const [fixedOptions, setfixedOptions]=useState<any[]>([
  //   {
  //     "attendence_template_field_id": 1,
  //     "template_field_name": "Employee ID",
  //     "default_flag": 1
  // },
  // {
  //     "attendence_template_field_id": 2,
  //     "template_field_name": "Employee Name",
  //     "default_flag": 1
  // },
  // {
  //     "attendence_template_field_id": 3,
  //     "template_field_name": "Total Days",
  //     "default_flag": 1
  // }])
  // const fixedOptions= project_attendance_template.filter((r)=>r.default_flag==1)
  const [templateFlag, settemplateFlag] = useState<boolean>(false);
  const [changedLevelValue, setChangedLevelValue] = useState<any>();
  const [filteredProjectField, setFilteredProjectField] = useState<
    ProjectAttendanceTemplate[]
  >([]);
  const LevelChange = (e: any) => {
    // console.log("ada",e.target.value)
    setChangedLevelValue(e.target.value);
    settemplateFlag(true);
  };
  // const GetLevelName = (level: number) => {
  //   let peoj = project_levels?.data?.find(
  //     (m) => m.level_key_id == getValues("level_key_id")
  //   );
  //   return !!peoj
  //     ? level == 1
  //       ? peoj.level1_key_name
  //       : level == 2
  //       ? peoj?.level2_key_name
  //       : level == 3
  //       ? peoj?.level3_key_name
  //       : level == 4
  //       ? peoj?.level4_key_name
  //       : level == 5
  //       ? peoj?.level5_key_name
  //       : ""
  //     : "";
  // };
  // const MaxLevel = () => {
  //   let level = 0;
  //   let peoj = project_levels?.data?.find(
  //     (m) => m.level_key_id == getValues("level_key_id")
  //   );
  //   if (!!peoj?.level1_key) level = level + 1;
  //   if (!!peoj?.level2_key) level = level + 1;
  //   if (!!peoj?.level3_key) level = level + 1;
  //   if (!!peoj?.level4_key) level = level + 1;
  //   if (!!peoj?.level5_key) level = level + 1;
  //   return level;
  // };
  // const AddNewLevel = (obj: any, target_lev: number) => {
  //   let lob: lev[] = JSON.parse(JSON.stringify(levelmaster));
  //   let target: any = lob.find((m) => m.level_name == obj[1]);
  //   for (let i = 1; i <= target_lev; i++) {
  //     if (i == target_lev) {
  //       target.levels.push({
  //         level_name: "",
  //         levels: [],
  //       });
  //     } else {
  //       target = target.levels?.find((m: any) => m.level_name == obj[i + 1]);
  //     }
  //   }
  //   SetLevel(lob);
  // };
  // const ChangeTextToLevel = (text: string, obj: any, target_lev: number) => {
  //   let lob: lev[] = JSON.parse(JSON.stringify(levelmaster));
  //   let target: any = lob.find((m) => m.level_name == obj[1]);
  //   for (let i = 1; i <= target_lev; i++) {
  //     if (i == target_lev) {
  //       target.level_name = text;
  //     } else {
  //       target = target.levels?.find((m: any) => m.level_name == obj[i + 1]);
  //     }
  //   }
  //   SetLevel(lob);
  // };
  // const AddNLevel1 = () => {
  //   let lob: lev[] = JSON.parse(JSON.stringify(levelmaster));
  //   lob.push({
  //     level_name: "",
  //     levels: [],
  //   });
  //   SetLevel(lob);
  // };
  // const DeleteLevel = (obj: any, target_lev: number, lavel_name: string) => {
  //   let lob: lev[] = JSON.parse(JSON.stringify(levelmaster));
  //   let target: any = lob.find((m) => m.level_name == obj[1]);
  //   console.log(obj, target_lev, lavel_name);

  //   for (let i = 1; i <= target_lev; i++) {
  //     if (i == target_lev) {
  //       target.levels = target.levels.filter(
  //         (m: any) => m.level_name != lavel_name
  //       );
  //     } else {
  //       target = target.levels?.find((m: any) => m.level_name == obj[i + 1]);
  //     }
  //   }
  //   SetLevel(lob);
  // };

  // console.log("project_levels : ", project_levels?.data);
  // console.log("errors", errors);

  const handleFormSubmit = (formData: any) => {
    // console.log("formSubmit", formData);
    if (!!projectId && Number(projectId) > 0) {
      SubmitUpdatedData({ ...formData, lastLevelKey: levelHierarchy?.lastKey });
    } else {
      Submit({ ...formData, lastLevelKey: levelHierarchy?.lastKey });
    }
  };
  const handleDialogOpenForLevelHierarchy = () => {
    // console.log("hitted or not");
    setLevelDialogOpen((prev) => !prev);
    // console.log("hitted or not", levelDialogOpen);
  };
  const getLevelData = () => {
    if (Number(getValues("level_key_id")) > 0) {
      getLevelHierarchyAction(Number(getValues("level_key_id")));
    }
  };
  useEffect(() => {
    const attendanceField = project_attendance_template.filter(
      (field) =>
        getValues("attendance_fields").includes(
          field.attendence_template_field_id
        ) &&
        (field.rate_flag === 1 ||
          field.target_flag === 1 ||
          field.incentive_rate_flag === 1)
    );
    setFilteredProjectField(attendanceField);
  }, [watch("attendance_fields"), project_attendance_template]);
  // console.log("filtered Attendance : ", filteredProjectField);
  // console.log("project_attendance_template : ", project_attendance_template);
  // console.log("filteredProjectField : ", filteredProjectField);
  // console.log("projectDashboardDetails : ", projectDashboardDetails);
  // useEffect(()=>{
  //   let levelOneMapper
  //   if(levelHierarchy?.levelsData && Array.isArray(levelHierarchy.levelsData)){
  //     let levelBody:any[] = [];
  //     switch(levelHierarchy.lastKey){
  //       case 1:

  //         setLevelMapper(levelHierarchy.levelsData);
  //         break;
  //       case 2:
  //         levelBody = levelHierarchy.levelsData.map((level_one:ILevelHierarchy)=>level_one.level_two).map((level_two)=>({...level_two,level_one:}))
  //         setLevelMapper(levelBody);
  //         break;
  //       case 3:
  //         levelBody = levelHierarchy.levelsData.map((level_one:ILevelHierarchy)=>level_one.level_two)
  //         setLevelMapper(levelBody);

  //     }
  //     if(levelHierarchy.lastKey === 1){

  //     }else{

  //     }

  //   }
  // },[])
  useEffect(() => {
    getLevelData();
    if (
      !!project &&
      !!selected_customer &&
      !!projectId &&
      Number(projectId) > 0
    ) {
      getLevelTargetRateValueAction(Number(projectId));
    }
  }, [projectId, selected_customer, project, project_levels]);
  useEffect(() => {
    if (
      !!project &&
      !!levelsTargetGetValue &&
      levelsTargetGetValue.length > 0
    ) {
      levelsTargetGetValue.forEach((self) => {
        Object.entries(self).forEach((entry) => {
          // console.log("entry : ", entry);
          setValue(`${entry[0]}`, entry[1]);
        });
      });
    }
    return clearTargetRateValueAction();
  }, [project, projectId, project_levels, levelHierarchy]);
  // console.log("projectDashboardDetails : ", projectDashboardDetails);
  useEffect(() => {
    if (Number(getValues("level_key_id"))) {
      // console.log("effect hit im");
      getLevelDetailsAction(Number(getValues("level_key_id")));
    }
  }, [watch("level_key_id")]);

  useEffect(() => {
    if (
      !!project &&
      !!project.attendance_fields &&
      Array.isArray(project.attendance_fields)
    ) {
      setFetchedAttendanceFields(project.attendance_fields);
    }
  }, [project]);
  useEffect(() => {
    if (!!project) {
      console.warn("attendance", project.attendance_fields);
      setValue("attendance_fields", project.attendance_fields);
      return () => {
        setValue("attendance_fields", "");
      };
    }
  }, [project, project?.attendance_fields, fetchedAttendanceFields]);
  console.log("edit flag ::::::::::::::::::::::::::::     ", edit_flag);
  console.log("documents length ::::::::::::::::::::::::::::     ", document);
  const fileRef = useRef<any>();
  const callback = () => {
    if (fileRef?.current) fileRef.current.value = "";
  };
  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <ProjectDocumentsModal
        isProjectExists={Boolean(project)}
        isEditMode={Boolean(project) && !edit_flag}
        documents={document}
        editDocumentHandler={fileHandleEvent}
        deleteDocumentHandler={deleteDocumentHandler}
        isOpen={docsDialogOpen}
        setIsOpen={setDocsDialogOpen}
        downloadDocument={downloadImage}
        document={document}
        showAlertForUploadDoc={showAlertForUploadDoc}
        setShowAlertUploadDoc={setShowAlertUploadDoc}
      />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {/*  */}

        <HierachyModal
          levelDetails={levelTree?.allLevels ?? []}
          levelMaster={levelTree?.levelMaster}
          levelDialogOpen={levelDialogOpen}
          handleDialogOpenForLevelHierarchy={handleDialogOpenForLevelHierarchy}
        />

        <Grid container direction={"column"}>
          <Grid
            container
            direction={"row"}
            // justifyContent={"center"}
            // alignItems={"center"}
            style={{ textAlign: "center", padding: 14 }}
          >
            <Grid item>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => navigate(-1)}
                //onClick={() => navigate(`/customer/project-main/`)}
                // style={{ marginLeft: -10 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            {/* <SubDivisionPopover
            handleClose2={SetSubdivisionpop}
            open2={Subdivisionpop}
            subdivision={
              !!division.find((m) => m.division_name == Subdivision)
                ?.subdivision
                ? division.find((m) => m.division_name == Subdivision)
                    ?.subdivision
                : []
            }
            AddSubDivision={(data: any) => AddSubDivision(data)}
            deleteSubDivision={deleteSubDivision}
            editFlag={(edit_flag && !project) || (!edit_flag && !!project)}
          /> */}
            <Grid item xs={10} marginTop={1}>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                }}
              >
                {!!project ? "Project Details" : "Add Project"}
              </Typography>
            </Grid>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid container justifyContent="start" alignItems="center">
              {!document || document.length === 0 ? (
                <Grid item style={{ marginInline: 10, width: "90%" }}>
                  <Alert
                    severity="error"
                    sx={{ position: "initial", width: "100%", my: 1 }}
                  >
                    Please Upload Work Order Document
                  </Alert>
                </Grid>
              ) : showAlertForUploadDoc ? (
                <Alert
                  severity="warning"
                  sx={{ position: "initial", width: "92.2%", my: 1 }}
                >
                  Please save/update this project to persists this newly
                  uploaded document's
                </Alert>
              ) : (
                <></>
              )}

              <Grid item style={{ marginInline: 10, width: "90%" }}>
                {!!project &&
                  !!user &&
                  environment.global_veriable.officialEmployee.includes(
                    user.designation
                  ) && (
                    <Grid
                      container
                      justifyContent="end"
                      alignItems="center"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Switch
                        value={edit_flag}
                        onChange={(event) => SetEditFlag(!event.target.checked)}
                      />
                      <Typography style={{ color: "#A2A2A2" }}>
                        Edit Project
                      </Typography>
                    </Grid>
                  )}
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: 17,
                        fontFamily: "Poppins, sans-serif",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Basic Details
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -5 }}></Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Customer Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"customer_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={customer_list}
                          style={{ width: "100.9%" }}
                          readOnly={!!project ? edit_flag : !edit_flag}
                          keys={"customer_id"}
                          label={"customer_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), onChangeCustomer(value)
                          )}
                          placeholder={"Search Customer"}
                          value={!!value ? value : ""}
                          errors={!!errors["customer_id"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"project_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          placeholder={"Project Name"}
                          error={!!errors["project_name"]}
                          helperText={
                            errors.project_name &&
                            `${errors.project_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Code
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"project_code"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          placeholder={"Project Code"}
                          error={!!errors["project_code"]}
                          helperText={
                            errors.project_code &&
                            `${errors.project_code.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      State Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"state_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={state_list}
                          style={{ width: "100.9%" }}
                          readOnly={!!project ? edit_flag : !edit_flag}
                          keys={"state_name"}
                          label={"state_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => (
                            onChange(value), onChangeCustomer(value)
                          )}
                          placeholder={"Search State Name"}
                          value={!!value ? value : ""}
                          errors={!!errors["state_name"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid> */}
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Work Order Number
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"work_order_no"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          onChange={onChange}
                          value={value}
                          placeholder={" Work Order Number"}
                          // error={!!errors["work_order_no"]}
                          // helperText={
                          //   errors.work_order_no &&
                          //   `${errors.work_order_no.message}* This field is Required`
                          // }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Work Order Document
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} alignItems="start" textAlign={"left"}>
                    <Button
                      variant="outlined"
                      component="label"
                      color="primary"
                      onClick={() => setDocsDialogOpen(true)}
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          // color: "#FFFF",
                          paddingLeft: 10,
                          // textAlign: "center"
                        }}
                      >
                        Manage Documents
                      </Typography>
                    </Button>
                    {/* {!(!!project ? edit_flag : !edit_flag) ? (
                      <Button
                        variant="outlined"
                        component="label"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                      >
                        <input
                          onChange={(event: any) =>
                            fileHandleEvent({ event, callback })
                          }
                          hidden
                          ref={fileRef}
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          type="file"
                        />
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            // color: "#FFFF",
                            paddingLeft: 10,
                            // textAlign: "center"
                          }}
                        >
                          Upload
                        </Typography>
                      </Button>
                    ) : (
                      <></>
                      // <Grid container direction={"row"} justifyContent="start">
                      //   <Grid>
                      //     <CheckCircleIcon color="success" />
                      //   </Grid>
                      //   <Grid alignItems="start">
                      //     {" "}
                      //     <Typography
                      //       style={{ color: "#99A09C", fontWeight: "700" }}
                      //     >
                      //       Successfully Uploaded
                      //     </Typography>
                      //   </Grid>
                      //   <Grid
                      //     item
                      //     xs={12}
                      //     alignItems="start"
                      //     textAlign={"left"}
                      //   >
                      //     <Button
                      //       onClick={() => downloadImage()}
                      //       variant="text"
                      //     >
                      //       Download Document
                      //     </Button>
                      //     {!(!!project ? edit_flag : !edit_flag) && (
                      //       <>
                      //         <Button
                      //           onClick={() => Setdocument(undefined)}
                      //           variant="text"
                      //         >
                      //           Change Document
                      //         </Button>
                      //       </>
                      //     )}
                      //   </Grid>
                      // </Grid>
                    )}
                    {document.length > 0 ? (
                      <Button
                        variant="outlined"
                        component="label"
                        color="primary"
                        sx={{ ml: 2 }}
                        onClick={() => setDocsDialogOpen(true)}
                        startIcon={
                          Boolean(project) && !!edit_flag ? (
                            <CloudDownloadIcon />
                          ) : (
                            <></>
                          )
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            // color: "#FFFF",

                            // textAlign: "center"
                          }}
                        >
                          {Boolean(project) && !edit_flag ? "Edit / " : ""}
                          {Boolean(project) ? "" : "Edit /"}
                          Download
                        </Typography>
                      </Button>
                    ) : (
                      <></>
                    )} */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Additional Fields
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <AdditionalFields
                      fieldsMaster={fieldsMaster}
                      project={project}
                      editFlags={edit_flag}
                      AddNewAdditionalTypes={(field: any) =>
                        setField([...fieldsMaster, field])
                      }
                      editField={setField}
                      DeleteMaster={(indexs: number) =>
                        setField(
                          fieldsMaster.filter((m, index) => index != indexs)
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Start Date
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"start_date"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"date"}
                          id="standard-required"
                          variant="outlined"
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          // disabled={!!employeeTime ? edit_flag : !edit_flag}
                          //label={"Shift Starting Time"}
                          error={!!errors["start_date"]}
                          helperText={
                            errors.start_date &&
                            `${errors.start_date.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project End Date
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"end_date"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"date"}
                          id="standard-required"
                          variant="outlined"
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          // disabled={!!employeeTime ? edit_flag : !edit_flag}
                          error={!!errors["end_date"]}
                          helperText={
                            errors.end_date &&
                            `${errors.end_date.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Applicable Posts
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"applicable_post"}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={!!post_list ? post_list : []}
                          getOptionLabel={(option) => option.post_name}
                          readOnly={!!project ? edit_flag : !edit_flag}
                          style={{ width: "100.9%" }}
                          onChange={(evemt, value) =>
                            onChange(!!value ? value.map((m) => m.post_id) : [])
                          }
                          value={
                            !!value
                              ? post_list.filter((m) =>
                                  value.includes(m.post_id)
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Applicable Posts"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Applicable Designations
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"applicable_designation"}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={!!designation_list ? designation_list : []}
                          style={{ width: "100.9%" }}
                          getOptionLabel={(option) => option.designation_name}
                          readOnly={!!project ? edit_flag : !edit_flag}
                          onChange={(evemt, value) =>
                            onChange(
                              !!value ? value.map((m) => m.designation_id) : []
                            )
                          }
                          value={
                            !!value
                              ? designation_list.filter((m) =>
                                  value.includes(m.designation_id)
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Applicable Designations"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Location Hierarchy
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"level_key_id"}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={
                            !!project_levels
                              ? project_levels.data.filter(
                                  (self) => self.org_internal === 0
                                )
                              : []
                          }
                          //   readOnly={!!department ? edit_flag : !edit_flag}
                          keys={"level_key_id"}
                          label={"display_name"}
                          onBlur={onBlur}
                          readOnly={!!project ? true : false}
                          onChange={(value: any) => (
                            onChange(value), getLevelData()
                          )}
                          placeholder={"Search Applicable Level"}
                          value={!!value ? Number(value) : ""}
                          errors={!!errors["level_key_id"]}
                        />
                      )}
                    />
                  </Grid>
                  {!!watch("level_key_id") &&
                  Number(watch("level_key_id")) > 0 ? (
                    <Grid item>
                      <VisibilityIcon
                        sx={{ ml: 2, cursor: "pointer" }}
                        onClick={handleDialogOpenForLevelHierarchy}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Applicable Field in Attendance Template
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"attendance_fields"}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo"
                          options={
                            !!project_attendance_template
                              ? project_attendance_template
                              : []
                          }
                          getOptionLabel={(option) =>
                            option.template_field_name
                          }
                          // readOnly={!!project ? edit_flag : !edit_flag}
                          readOnly={!!project ? true : false}
                          style={{ width: "100.9%" }}
                          onChange={(evemt, value) =>
                            onChange(
                              // ...fixedOptions,
                              !!value
                                ? value.map(
                                    (m) => m.attendence_template_field_id
                                  )
                                : []
                            )
                          }
                          value={
                            !!value
                              ? project_attendance_template.filter(
                                  (m) =>
                                    value.includes(
                                      m.attendence_template_field_id
                                    ) || m.default_flag === 1
                                )
                              : []
                          }
                          renderTags={(tagValue, getTagProps) => (
                            <>
                              {tagValue.map((option, index) => (
                                <Chip
                                  label={option.template_field_name}
                                  {...getTagProps({ index })}
                                  disabled={option.default_flag == 1}
                                />
                              ))}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Applicable Template Fields"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"attendance_fields"}
                      defaultValue={[]}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo"
                          options={
                            !!project_attendance_template
                              ? project_attendance_template
                              : []
                          }
                          getOptionDisabled={(option) =>
                            fetchedAttendanceFields.includes(
                              option.attendence_template_field_id
                            )
                          }
                          getOptionLabel={(option) =>
                            option.template_field_name
                          }
                          readOnly={!!project ? edit_flag : !edit_flag}
                          // readOnly={!!project ? true : false}
                          style={{ width: "100.9%" }}
                          onChange={(evemt, value) => {
                            onChange(
                              // ...fixedOptions,
                              !!value
                                ? value.map(
                                    (m) => m.attendence_template_field_id
                                  )
                                : []
                            );
                          }}
                          value={
                            !!value
                              ? project_attendance_template.filter(
                                  (m) =>
                                    value.includes(
                                      m.attendence_template_field_id
                                    ) || m.default_flag === 1
                                )
                              : []
                          }
                          renderTags={(tagValue, getTagProps) => (
                            <>
                              {tagValue.map((option, index) => (
                                <Chip
                                  label={option.template_field_name}
                                  {...getTagProps({ index })}
                                  disabled={
                                    fetchedAttendanceFields.includes(
                                      option.attendence_template_field_id
                                    ) || option.default_flag == 1
                                  }
                                />
                              ))}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Applicable Template Fields"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: 17,
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "6.2%",
                    textAlign: "left",
                  }}
                >
                  Manager Details
                </Typography>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Manager
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>

                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"projectmanager_id"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={
                            !!ReportingManagerList ? ReportingManagerList : []
                          }
                          getOptionLabel={(option) =>
                            option.employee_id +
                            " (" +
                            option.employee_name +
                            ")" +
                            "-" +
                            option.designation_name
                          }
                          readOnly={!!project ? edit_flag : !edit_flag}
                          style={{ width: "100.9%" }}
                          onChange={(evemt, value) =>
                            onChange(
                              !!value ? value.map((m) => m.employee_id) : []
                            )
                          }
                          value={
                            !!value
                              ? ReportingManagerList.filter((m) =>
                                  value.includes(m.employee_id)
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Project manager"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"project_partner"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          placeholder={"Project Partner Name"}
                          error={!!errors["project_partner"]}
                          helperText={
                            errors.project_partner &&
                            `${errors.project_partner.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 30 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "15%",
                        textAlign: "left",
                      }}
                    >
                      Project Co-Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05}>
                    <Controller
                      control={control}
                      name={"project_copartner"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          onChange={onChange}
                          value={value}
                          inputProps={{
                            readOnly: !!project ? edit_flag : !edit_flag,
                          }}
                          placeholder={"Project Co-Partner Name"}
                          error={!!errors["project_copartner"]}
                          helperText={
                            errors.project_copartner &&
                            `${errors.project_copartner.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                {filteredProjectField.length > 0 &&
                !!getValues("level_key_id") &&
                Number(getValues("level_key_id")) > 0 &&
                loadingCounter === 0 ? (
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 17,
                      fontFamily: "Poppins, sans-serif",
                      paddingLeft: "6.2%",
                      textAlign: "left",
                    }}
                  >
                    Target , Rate & Incentive Details
                  </Typography>
                ) : (
                  <></>
                )}

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{
                    marginTop: 15,
                    marginBottom: 30,
                  }}
                  // width={"100%"}
                >
                  {filteredProjectField.length > 0 &&
                  !!getValues("level_key_id") &&
                  Number(getValues("level_key_id")) > 0 &&
                  loadingCounter === 0 ? (
                    levelHierarchy?.levelsData.map((self, lIndex: number) => (
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                        >
                          <Grid item sm={12}>
                            <Box sx={{ textAlign: "left", fontWeight: 800 }}>
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  paddingLeft: "6.2%",
                                  display: "flex",
                                }}
                                color="#0060FF"
                              >
                                <span>
                                  {lIndex + 1}.{"  "} {self?.level1_name}
                                </span>
                                {self?.level2_name ? (
                                  <span
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <ChevronRightIcon />
                                    {self?.level2_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {self.level3_name ? (
                                  <span
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <ChevronRightIcon />
                                    {self?.level3_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {self?.level4_name ? (
                                  <span
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <ChevronRightIcon />
                                    {self?.level4_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {self?.level5_name ? (
                                  <span
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <ChevronRightIcon />
                                    {self?.level4_name}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid sm={5}></Grid>
                          <Grid sm={5.2}>
                            {filteredProjectField.map((field) => (
                              <Grid
                                container

                                // width={"100%"}
                              >
                                {field.rate_flag ? (
                                  <Grid
                                    item
                                    sm={12}
                                    md={3.98}
                                    justifyContent="space-between"
                                    alignItems="start"
                                    my={1}
                                  >
                                    <Controller
                                      control={control}
                                      name={`${
                                        field.attendence_template_field_id
                                      }-${self.level1_id}-${
                                        self?.level2_id
                                          ? self?.level2_id + "-"
                                          : ""
                                      }${
                                        self?.level3_id
                                          ? self.level3_id + "-"
                                          : ""
                                      }${
                                        self?.level4_id
                                          ? self.level4_id + "-"
                                          : ""
                                      }${
                                        self?.level5_id
                                          ? self.level5_id + "-"
                                          : ""
                                      }rate`}
                                      defaultValue={""}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onBlur, value, onChange },
                                      }) => (
                                        <TextField
                                          type={"number"}
                                          id="standard-required"
                                          variant="outlined"
                                          style={{ width: "95%" }}
                                          onBlur={onBlur}
                                          size="small"
                                          label={
                                            <span>
                                              {field.template_field_name} rate
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>{" "}
                                            </span>
                                          }
                                          onChange={onChange}
                                          value={value}
                                          inputProps={{
                                            readOnly: !!project
                                              ? edit_flag
                                              : !edit_flag,
                                          }}
                                          // disabled={!!employeeTime ? edit_flag : !edit_flag}
                                          error={
                                            !!errors[
                                              `${
                                                field.attendence_template_field_id
                                              }-${self.level1_id}-${
                                                self?.level2_id
                                                  ? self?.level2_id + "-"
                                                  : ""
                                              }${
                                                self?.level3_id
                                                  ? self.level3_id + "-"
                                                  : ""
                                              }${
                                                self?.level4_id
                                                  ? self.level4_id + "-"
                                                  : ""
                                              }${
                                                self?.level5_id
                                                  ? self.level5_id + "-"
                                                  : ""
                                              }rate`
                                            ]
                                          }
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {field.target_flag ? (
                                  <Grid
                                    item
                                    sm={12}
                                    md={3.98}
                                    justifyContent="space-between"
                                    alignItems="start"
                                    my={1}
                                  >
                                    <Controller
                                      control={control}
                                      name={`${
                                        field.attendence_template_field_id
                                      }-${self.level1_id}-${
                                        self?.level2_id
                                          ? self?.level2_id + "-"
                                          : ""
                                      }${
                                        self?.level3_id
                                          ? self.level3_id + "-"
                                          : ""
                                      }${
                                        self?.level4_id
                                          ? self.level4_id + "-"
                                          : ""
                                      }${
                                        self?.level5_id
                                          ? self.level5_id + "-"
                                          : ""
                                      }target`}
                                      defaultValue={""}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onBlur, value, onChange },
                                      }) => (
                                        <TextField
                                          type={"number"}
                                          id="standard-required"
                                          variant="outlined"
                                          style={{ width: "95%" }}
                                          label={
                                            <span>
                                              {field.template_field_name} target
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>{" "}
                                            </span>
                                          }
                                          onBlur={onBlur}
                                          size="small"
                                          onChange={onChange}
                                          value={value}
                                          inputProps={{
                                            readOnly: !!project
                                              ? edit_flag
                                              : !edit_flag,
                                          }}
                                          // disabled={!!employeeTime ? edit_flag : !edit_flag}
                                          error={
                                            !!errors[
                                              `${
                                                field.attendence_template_field_id
                                              }-${self.level1_id}-${
                                                self?.level2_id
                                                  ? self?.level2_id + "-"
                                                  : ""
                                              }${
                                                self?.level3_id
                                                  ? self.level3_id + "-"
                                                  : ""
                                              }${
                                                self?.level4_id
                                                  ? self.level4_id + "-"
                                                  : ""
                                              }${
                                                self?.level5_id
                                                  ? self.level5_id + "-"
                                                  : ""
                                              }target`
                                            ]
                                          }
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {field.incentive_rate_flag ? (
                                  <Grid
                                    item
                                    sm={12}
                                    md={3.98}
                                    justifyContent="space-between"
                                    alignItems="start"
                                    my={1}
                                  >
                                    <Controller
                                      control={control}
                                      name={`${
                                        field.attendence_template_field_id
                                      }-${self.level1_id}-${
                                        self?.level2_id
                                          ? self?.level2_id + "-"
                                          : ""
                                      }${
                                        self?.level3_id
                                          ? self.level3_id + "-"
                                          : ""
                                      }${
                                        self?.level4_id
                                          ? self.level4_id + "-"
                                          : ""
                                      }${
                                        self?.level5_id
                                          ? self.level5_id + "-"
                                          : ""
                                      }incentive`}
                                      defaultValue={""}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onBlur, value, onChange },
                                      }) => (
                                        <TextField
                                          type={"number"}
                                          id="standard-required"
                                          variant="outlined"
                                          style={{ width: "95%" }}
                                          label={
                                            <span>
                                              {field.template_field_name}{" "}
                                              incentive
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>{" "}
                                            </span>
                                          }
                                          onBlur={onBlur}
                                          size="small"
                                          onChange={onChange}
                                          value={value}
                                          inputProps={{
                                            readOnly: !!project
                                              ? edit_flag
                                              : !edit_flag,
                                          }}
                                          // disabled={!!employeeTime ? edit_flag : !edit_flag}
                                          error={
                                            !!errors[
                                              `${
                                                field.attendence_template_field_id
                                              }-${self.level1_id}-${
                                                self?.level2_id
                                                  ? self?.level2_id + "-"
                                                  : ""
                                              }${
                                                self?.level3_id
                                                  ? self.level3_id + "-"
                                                  : ""
                                              }${
                                                self?.level4_id
                                                  ? self.level4_id + "-"
                                                  : ""
                                              }${
                                                self?.level5_id
                                                  ? self.level5_id + "-"
                                                  : ""
                                              }incentive`
                                            ]
                                          }
                                        />
                                      )}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Divider
                          style={{
                            borderColor: "#9E9E9E",
                            opacity: 0.7,
                            width: "76.8%",
                            marginLeft: "8%",
                            marginTop: "1%",
                            marginBottom: "1%",
                          }}
                        ></Divider>
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            {((edit_flag && !project) || (!edit_flag && !!project)) && (
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                <b>{!!project ? "Update" : "Add"}</b>
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddProjectView;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface AddProjectViewProps {
  customer_list: CustomerList[];
  designation_list: AllDesignationList[];
  post_list: AllPostList[];
  Submit?: any;
  project?: ViewProject;
  customer?: CustomerList;
  selected_customer?: number;
  SubmitUpdatedData?: any;
  onChangeCustomer?: any;
  ReportingManagerList: ReportingManagerList[];
  document: ProjectDocumentsTypes[];
  Setdocument: Dispatch<SetStateAction<ProjectDocumentsTypes[]>>;
  fileHandleEvent?: any;
  downloadImage?: any;
  project_attendance_template: ProjectAttendanceTemplate[];
  project_levels?: ProjectLevels;
  fieldsMaster: any[];
  setField: any;
  projectId?: string;
  getLevelHierarchyAction?: any;
  levelHierarchy?: ILevelHierarchyMain;
  loadingCounter: number;
  getLevelTargetRateValueAction?: any;
  levelsTargetGetValue: any[];
  projectDashboardDetails?: IProjectDashboardDetails;
  clearTargetRateValueAction?: any;
  GetProjectDashboardAction?: any;
  levelTree?: LevelTree;
  getLevelDetailsAction?: any;
  state_list: StateList[];
  user?: userDetails;
  deleteDocumentHandler: any;
}

// const LevelItem = ({
//   l_pos,
//   level,
//   GetLevelName,
//   MaxLevel,
//   AddNewLevel,
//   ChangeTextToLevel,
//   DeleteLevel,
// }: LevelItemPorps) => {
//   const [expanded, Setexpanded] = useState<boolean>(true);
//   return (
//     <Grid
//       container
//       direction={"column"}
//       marginLeft={"30px"}
//       paddingTop={1}
//       paddingBottom={1}
//       position={"relative"}
//       style={{
//         borderLeft: "1px dotted #BDBDBD",
//         width: "calc(100% - 33px)",
//       }}
//     >
//       <div
//         style={{
//           borderTop: "1px dotted #BDBDBD",
//           width: 5,
//           position: "absolute",
//           left: 0,
//           top: "30px",
//         }}
//       ></div>
//       <Grid style={{ width: "100%" }}>
//         <Grid container alignItems={"start"} justifyContent={"start"}>
//           <Grid item xs={11} alignItems={"start"} justifyContent={"start"}>
//             <TextField
//               value={level.level_name}
//               onChange={(event) =>
//                 ChangeTextToLevel(
//                   event.target.value,
//                   { [l_pos]: level.level_name },
//                   l_pos
//                 )
//               }
//               type={"text"}
//               label={GetLevelName(l_pos)}
//               id="standard-required"
//               variant="outlined"
//               className={"smaller"}
//               style={{ width: MaxLevel() > l_pos ? "70%" : "85%" }}
//               placeholder={GetLevelName(l_pos)}
//               size="small"
//             ></TextField>
//             {MaxLevel() > l_pos && (
//               <>
//                 <Button
//                   variant="outlined"
//                   size="small"
//                   sx={{
//                     marginLeft: 1,
//                     width: 120,
//                     alignSelf: "start",
//                   }}
//                   onClick={() =>
//                     AddNewLevel({ [l_pos]: level.level_name }, l_pos)
//                   }
//                 >
//                   Add {GetLevelName(l_pos + 1)}
//                 </Button>
//               </>
//             )}
//             <IconButton
//               color="error"
//               onClick={() =>
//                 DeleteLevel(
//                   { [l_pos]: level.level_name },
//                   l_pos,
//                   level.level_name
//                 )
//               }
//             >
//               <DeleteIcon />
//             </IconButton>
//           </Grid>
//           <Grid xs={1}>
//             {MaxLevel() > l_pos && (
//               <IconButton
//                 color="primary"
//                 onClick={() => Setexpanded(!expanded)}
//               >
//                 {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//               </IconButton>
//             )}
//           </Grid>
//         </Grid>
//       </Grid>
//       {MaxLevel() > l_pos &&
//         expanded &&
//         level.levels?.map((m, index) => (
//           <LevelItem
//             l_pos={l_pos + 1}
//             level={m}
//             key={index}
//             GetLevelName={GetLevelName}
//             MaxLevel={MaxLevel}
//             AddNewLevel={(obj: any, l_poss: number) =>
//               AddNewLevel({ ...obj, [l_pos]: level.level_name }, l_poss)
//             }
//             ChangeTextToLevel={(text: string, obj: any, l_poss: number) =>
//               ChangeTextToLevel(
//                 text,
//                 { ...obj, [l_pos]: level.level_name },
//                 l_poss
//               )
//             }
//             DeleteLevel={(
//               text: string,
//               obj: any,
//               l_poss: number,
//               level_name: string
//             ) =>
//               DeleteLevel(
//                 { ...obj, [l_pos]: level.level_name },
//                 l_poss,
//                 level_name
//               )
//             }
//           />
//         ))}
//     </Grid>
//   );
// };

// interface LevelItemPorps {
//   l_pos: number;
//   level: lev;
//   GetLevelName?: any;
//   MaxLevel?: any;
//   AddNewLevel?: any;
//   ChangeTextToLevel?: any;
//   DeleteLevel?: any;
// }
