import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  BudgetDashboardDetails,
  EmpMonthlySalaryProjectwise,
  MonthWiseEmpSalaryinBudgeting,
} from "../../../models/customerModel";
import { StoreState } from "../../../models/reduxModels";
import {
  EmpMonthlySalaryProjectwiseAction,
  GetProjectBudgetDetailsAction,
  MonthWiseEmpSalaryinBudgetingAction,
} from "../../../stores/actions/customerAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import BudgetDashboardView from "./BudgetDashboardView";
import dayjs, { Dayjs } from "dayjs";

const BudgetDashboard = ({
  SidenavAutoCloseOpen,
  GetProjectBudgetDetailsAction,
  budget_details,
  MonthWiseEmpSalaryinBudgetingAction,
  month_wise_emp_salary_Budgeting,
  EmpMonthlySalaryProjectwiseAction,
  emp_salary_month_projectwise,
}: BudgetDashboardProps) => {
  const { id } = useParams();
  const [currentYear, setCurrentYear] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  // console.log('currentYear', dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]);

  React.useEffect(() => {
    if (!!id) {
      GetProjectBudgetDetailsAction({
        project_id: +id,
        year: Number(dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]),
      });
    }
    // MonthWiseEmpSalaryinBudgetingAction({
    //   project_id: 27,
    //   month: 4,
    // });
    SidenavAutoCloseOpen(true);
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, [currentYear]);
  // console.log(budget_details);
  // console.log("emp_salary_month_projectwise",emp_salary_month_projectwise);

  const EmpSalaryMonthProject = (data: any) => {
    console.log("data", data);
    let payload: any = {
      ...data,
      year: Number(dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]),
    };
    EmpMonthlySalaryProjectwiseAction(payload);
  };
  const EmpMonthlyDisburseSalary = (data: any) => {
    let payload: any = {
      ...data,
      year: Number(dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]),
    };
    MonthWiseEmpSalaryinBudgetingAction(payload);
  };
  return (
    <BudgetDashboardView
      budget_details={budget_details}
      month_wise_emp_salary_Budgeting={month_wise_emp_salary_Budgeting}
      EmpSalaryMonthProject={EmpSalaryMonthProject}
      emp_salary_month_projectwise={emp_salary_month_projectwise}
      EmpMonthlyDisburseSalary={EmpMonthlyDisburseSalary}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    budget_details: state.customer.budget_details,
    month_wise_emp_salary_Budgeting:
      state.customer.month_wise_emp_salary_Budgeting,
    emp_salary_month_projectwise: state.customer.emp_salary_month_projectwise,
  };
};
const mapDispatchToProps = {
  SidenavAutoCloseOpen,
  GetProjectBudgetDetailsAction,
  MonthWiseEmpSalaryinBudgetingAction,
  EmpMonthlySalaryProjectwiseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetDashboard);

interface BudgetDashboardProps {
  SidenavAutoCloseOpen?: any;
  GetProjectBudgetDetailsAction: any;
  budget_details?: BudgetDashboardDetails;
  MonthWiseEmpSalaryinBudgetingAction?: any;
  month_wise_emp_salary_Budgeting: MonthWiseEmpSalaryinBudgeting[];
  EmpMonthlySalaryProjectwiseAction?: any;
  emp_salary_month_projectwise: EmpMonthlySalaryProjectwise[];
}
