import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { AllDesignationList } from "../../../models/organizationModels";
import CloseIcon from "@mui/icons-material/Close";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  IAllLeaveManagement,
  IApplyLeavePayload,
  IDesignationWiseEmp,
  ILeaveAmountData,
  ILeaveDetailsData,
  ILeaveType,
  IdesignationWiseLeave,
  LeaveApprovalList,
  IEditLeavePayload,
  ILeaveTableData,
  INaturalHoliday,
} from "../../../models/leaveModel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { userDetails } from "./../../../models/userModels";
import ApplicableLeave from "./ApplicableLeave";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSnackbar } from "notistack";
import AllowLeaveApply from "./AllowLeaveApply";
import { environment } from "../../../environment";
import { isNaturalHoliday } from "../../../utility/naturalHoliday";

const LeaveApplicationModal: FC<ILeaveApplicationModal> = ({
  open,
  setOpen,
  user,
  selected_organization_leave,
  designation_list,
  allLeaveManagementDetails,
  leave_types,
  getDesignationWiseLeaveAction,
  GetAllLeaveTypeAction,
  clearDesignationWiseLeave,
  designationWiseLeave,
  designationWiseEmp,
  leaveAmountData,
  getEmployeeByDesignationAction,
  calculateLeaveDaysAction,
  leaveDaysCount,
  applyLeaveAction,
  resetLeaveDaysCalculationState,
  defaultStartDate,
  selectedPayload,
  isEditable,
  getLeaveAmountAction,
  getLeaveDetailsAction,
  editLeaveAction,
  setSelectedPayload,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    pb: 2,
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  // const [uDesnFromLeave, setuDescFromLeave] = useState<AllDesignationList[]>(
  //   []
  // );
  const [uLeaveType, setULeaveType] = useState<ILeaveType[]>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>("");
  const [showCalBtn, setShowcalBtn] = useState<boolean>(true);
  const [isHalfDay, setIsHalfDay] = useState<boolean>(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState<boolean>(false);
  const [openApplyDialog, setOpenApplyDialog] = useState<boolean>(false);
  const [showHalfDay, setShowHalfDay] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>();
  const [halfDayType, setHalfDayType] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [totalLeaveDaysClicked, setTotalLeaveDaysClicked] =
    useState<boolean>(false);

  // useEffect(() => {
  //   const uniqueData = allLeaveManagementDetails
  //     .map((mSelf, mIndex) => mSelf.designation_id)
  //     .filter((fSelf, fIndex, arr) => arr.indexOf(fSelf) === fIndex);
  //   console.log("unique data : ", uniqueData);
  //   const filteredOrgIds = designation_list.filter((self) =>
  //     uniqueData.includes(self.designation_id)
  //   );
  //   setuDescFromLeave(filteredOrgIds);
  // }, [allLeaveManagementDetails]);
  // useEffect(() => {
  //   if (!!user && user.employee_detail.organization_id) {
  //     getDesignationWiseLeaveAction(user.designation);
  //     GetAllLeaveTypeAction();
  //     //   getEmployeeByDesignationAction(Number(getValues("designation_id")));
  //   }
  // }, [user, open]);

  // useEffect(() => {
  //   if (designationWiseLeave.length > 0) {
  //     const filtereddLeaveId = designationWiseLeave.map(
  //       (self) => self.leave_type_id
  //     );
  //     const filterLeave = leave_types.filter((self, index: number) =>
  //       filtereddLeaveId.includes(self.leave_type_id)
  //     );
  //     console.log("filterLeave :  ", filterLeave);
  //     const validArray: any[] = [];
  //     for (const filter of filterLeave) {
  //       const isFound = leaveAmountData.find(
  //         (self) =>
  //           self.leave_type_id === filter.leave_type_id && self.remaining === 0
  //       );
  //       if (!!isFound) continue;
  //       validArray.push(filter);

  //       // for (const leaveAmount of leaveAmountData ){
  //       //   if(filter.leave_type_id === leaveAmount.leave_type_id && Number(leaveAmount.leave_amount) <=Number(leaveAmount.used_leave)) continue;
  //       //   validArray.push(filter);
  //       // }
  //     }
  //     setULeaveType(validArray);
  //   }
  // }, [designationWiseLeave, leave_types, open, leaveAmountData]);

  console.log("uLeaveType : ", uLeaveType);
  console.log("selected payload : ", selectedPayload);
  const endYearRef = useRef<any>();

  useEffect(() => {
    if (open && !isEditable) {
      setValue("leave_type_id", "");
      setValue("isHalfDay", "");
      setValue("start_date", new Date());
      setValue("end_date", "");
      setStartDate(new Date());
      setEndDate("");
      setShowcalBtn(true);
      setShowSubmitBtn(false);
      setValue("cause", "");
    }
    if (open) {
      setTotalLeaveDaysClicked(false);
      setShowcalBtn(true);
      setOnceFlag(false);
      setOpenApplyDialog(false);
    }
  }, [open]);
  useEffect(() => {
    if (!open && !isEditable) {
      setValue("end_date", "");
      setEndDate("");
      setValue("start_date", "");
      setStartDate("");
      setShowcalBtn(true);
    }
    if (!open && !isEditable) {
      setIsHalfDay(false);
      setValue("isHalfDay", false);
      setHalfDayType("");
      setValue("half_day_type", "");
    }
  }, [open]);

  useEffect(() => {
    if (defaultStartDate && !isEditable) {
      setValue("start_date", new Date(defaultStartDate));
      setStartDate(new Date(defaultStartDate));
    }
  }, [defaultStartDate, open]);

  console.log("designationWiseLeave : ", designationWiseLeave);
  console.log("leave_types : ", leave_types);
  console.log("start_Date : ", startDate);
  console.log("default start date : ", defaultStartDate);

  const handleStartDate = (newValue: Dayjs | null) => {
    setStartDate(newValue);
    if (newValue !== null) setShowcalBtn(true);

    setValue("start_date", newValue);

    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(getValues("start_date")).getTime();
    let employeeId = "";
    if (!!user && !isEditable) {
      employeeId = user?.employee_detail.employee_id;
    } else if (!!selectedPayload && !!isEditable) {
      employeeId = selectedPayload.employee_id;
    }
    const isStartDateHoliday = isNaturalHoliday(
      employeeId,
      getValues("start_date"),
      natural_holiday_list
    );
    if (isStartDateHoliday || natural_holiday_list.length === 0) {
      setValue("start_date", "");
      setStartDate("");
      return enqueueSnackbar("No leave can be taken from Holiday", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }

    console.log("selected date time : ", selectedDateTime);
    const diff = Math.abs(currentDateTime - selectedDateTime);

    let Difference_In_Days = diff / (1000 * 3600 * 24);
    Difference_In_Days++;
    console.log("difference in days : ", Difference_In_Days);
    if (Difference_In_Days >= environment.leave_application.leave_difference) {
      setValue("start_date", "");
      setStartDate("");
      return enqueueSnackbar(
        "Taking leave before or after 6 months is not allowed",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }

    setIsHalfDay(false);
    setValue("isHalfDay", false);
    !!showCalBtn && setShowSubmitBtn(true);

    if (!!user && !isEditable) {
      getLeaveAmountAction({
        year: new Date().getFullYear(),
        employee_id: user?.employee_detail.employee_id,
      });
    }

    if (!!selectedPayload && !!isEditable) {
      getLeaveAmountAction({
        year: new Date(getValues("start_date")).getFullYear(),
        employee_id: selectedPayload.employee_id,
      });
    }
  };
  const handleEndDate = (newValue: Dayjs | null) => {
    setEndDate(newValue);
    if (newValue !== null) setShowcalBtn(true);
    setValue("end_date", newValue);

    !!showCalBtn && setShowSubmitBtn(true);
    setIsHalfDay(false);
    setValue("isHalfDay", false);

    const startDateTime = new Date(getValues("start_date")).getTime();
    const endDateTime = new Date(getValues("end_date")).getTime();
    if (startDateTime - 1000 * 3600 * 24 > endDateTime) {
      setEndDate("");
      setValue("end_date", "");
      if (endYearRef && endYearRef.current.value) {
        endYearRef.current.value = "";
      }
      enqueueSnackbar("End Date must be greater than start date", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }

    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(getValues("end_date")).getTime();
    let employeeId = "";
    if (!!user && !isEditable) {
      employeeId = user?.employee_detail.employee_id;
    } else if (!!selectedPayload && !!isEditable) {
      employeeId = selectedPayload.employee_id;
    }

    const isEndDateHoliday = isNaturalHoliday(
      employeeId,
      getValues("end_date"),
      natural_holiday_list
    );
    if (isEndDateHoliday || natural_holiday_list.length === 0) {
      setValue("end_date", "");
      setStartDate("");
      return enqueueSnackbar("No leave can be taken from Holiday", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }

    const diff = Math.abs(currentDateTime - selectedDateTime);

    let Difference_In_Days = diff / (1000 * 3600 * 24);
    Difference_In_Days++;
    if (Difference_In_Days >= 180) {
      setValue("end_date", "");
      setEndDate("");
      if (endYearRef && endYearRef.current.value) {
        endYearRef.current.value = "";
      }
      return enqueueSnackbar(
        "Taking leave before or after 6 months is not allowed",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }

    const startYear = moment(getValues("start_date"))
      .format("DD-MM-YYYY")
      .split("-")[2];
    const endYear = moment(getValues("end_date"))
      .format("DD-MM-YYYY")
      .split("-")[2];
    if (!!startYear && !!endYear && Number(startYear) !== Number(endYear)) {
      setValue("end_date", "");
      setEndDate("");
      if (endYearRef && endYearRef.current.value) {
        endYearRef.current.value = "";
      }
      return enqueueSnackbar(
        "Both Start and End Date must be within same year",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    }

    if (!!user && !isEditable) {
      console.log("first 2 call");
      getLeaveAmountAction({
        year: new Date(getValues("end_date")).getFullYear(),
        employee_id: user?.employee_detail.employee_id,
      });
    }
    if (!!selectedPayload && !!isEditable) {
      console.log("second 2 call");
      getLeaveAmountAction({
        year: new Date(getValues("end_date")).getFullYear(),
        employee_id: selectedPayload.employee_id,
      });
    }
  };

  console.log(
    "date : ",
    moment(startDate).format("YYYY-MM-DD"),
    moment(endDate).format("YYYY-MM-DD")
  );
  console.log("selected", selectedPayload?.leave_type_id);

  console.log("end date value : ", endDate);
  const sendApplicationRequest = () => {
    if (!formValue) {
      return enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    const start_date = moment(formValue.start_date).format("YYYY-MM-DD");
    const end_date = moment(formValue.end_date).format("YYYY-MM-DD");
    const organization_id = Number(user?.employee_detail.organization_id);
    const employee_id = String(user?.employee_detail.employee_id);
    const designation_id = Number(user?.designation);
    console.log({ ...formValue, start_date, end_date });

    if (isEditable) {
      const applyObj: IEditLeavePayload = {
        start_date,
        end_date,
        employee_id,
        leave_type_id: Number(formValue.leave_type_id),
        designation_id,
        half_days: Boolean(formValue.isHalfDay),
        leave_application_id: selectedPayload?.leave_application_id
          ? selectedPayload?.leave_application_id
          : 0,
        leave_management_id: selectedPayload?.leave_management_id
          ? selectedPayload?.leave_management_id
          : 0,
        cause: formValue.cause,
      };
      if (formValue?.half_day_type && applyObj.half_days) {
        applyObj.half_day_type = formValue.half_day_type;
      }
      console.log("form Payload : ", applyObj);
      editLeaveAction({ enqueueSnackbar, formData: applyObj });
    } else {
      const applyObj: IApplyLeavePayload = {
        start_date,
        end_date,
        organization_id,
        employee_id,
        leave_type_id: Number(formValue.leave_type_id),
        designation_id,
        half_days: Boolean(formValue.isHalfDay),
        cause: formValue.cause,
      };
      if (formValue?.half_day_type && applyObj.half_days) {
        applyObj.half_day_type = formValue.half_day_type;
      }
      applyLeaveAction({ enqueueSnackbar, formData: applyObj });
      console.log("formPayload : ", applyObj);
    }

    setOpen(false);
  };
  const onSubmit = (formData: any) => {
    setFormValue(null);
    if (Boolean(formData.isHalfDay) && formData.half_day_type === "") {
      return enqueueSnackbar(`please choose half leave type `, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    if (Number(leaveDaysCount) === 0) {
      return enqueueSnackbar(`please verify your leave days `, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    console.log("formData", formData);
    console.log("enddate : ");
    const startDateTimeStatmp = new Date(
      moment(formData.end_date).format("YYYY-MM-DD")
    ).getTime();
    let empCreatedTimeStamp;
    if (user?.employee_detail.created_on) {
      empCreatedTimeStamp = new Date(
        user?.employee_detail.created_on
      ).getTime();
      if (empCreatedTimeStamp > startDateTimeStatmp) {
        enqueueSnackbar(`You can't take leave before your joining date `, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }
    }

    if (!!leaveAmountData && !!leaveDaysCount) {
      const isFound = leaveAmountData.leave_count.find(
        (self) => Number(self.leave_type_id) === Number(formData.leave_type_id)
      );
      if (!!isFound && isFound.remaining_count >= leaveDaysCount) {
        setFormValue(formData);
        setOpenApplyDialog(true);
        return;
      }
    }
    enqueueSnackbar(`Your remaining leave is low`, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  console.log("formValue : ", formValue);

  const handleLeaveCount = (): void => {
    if (!startDate || !endDate) {
      enqueueSnackbar(`start date or end date not selected`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");
    let employeeId = "";
    if (!!user && !isEditable) {
      employeeId = user?.employee_detail.employee_id;
    }
    if (!!selectedPayload && !!isEditable) {
      employeeId = selectedPayload.employee_id;
    }
    const isStartDateHoliday = isNaturalHoliday(
      employeeId,
      start_date,
      natural_holiday_list
    );
    const isEndDateHoliday = isNaturalHoliday(
      employeeId,
      end_date,
      natural_holiday_list
    );
    if (
      isStartDateHoliday ||
      isEndDateHoliday ||
      natural_holiday_list.length === 0
    ) {
      enqueueSnackbar(`You can't take or end your leave on Holiday`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    setTotalLeaveDaysClicked(true);
    setShowSubmitBtn(true);
    setShowcalBtn(false);

    calculateLeaveDaysAction({
      start_date,
      end_date,
      employee_id: isEditable
        ? selectedPayload?.employee_id
        : user?.employee_detail.employee_id,
    });
  };
  console.log("leaveAmountData : ", leaveAmountData);

  useEffect(() => {
    if (!!user && !isEditable && !!open && !selectedPayload) {
      console.log("first call");
      getLeaveAmountAction({
        year: !!defaultStartDate
          ? new Date(defaultStartDate).getFullYear()
          : new Date().getFullYear(),
        employee_id: user?.employee_detail.employee_id,
      });
      naturalLeaveDaysActionByEmployee(user?.employee_detail.employee_id);
    }
    if (!!selectedPayload && !!isEditable && !!open) {
      console.log("second call");
      getLeaveAmountAction({
        year: new Date(selectedPayload.start_date).getFullYear(),
        employee_id: selectedPayload.employee_id,
      });
      naturalLeaveDaysActionByEmployee(selectedPayload.employee_id);
    }
  }, [user, isEditable, selectedPayload, open]);

  // THIS COMMENT IS EXPERIMENTAL PLEASE DONT DELETE THIS
  // useEffect(() => {
  //   if (user?.employeeId && !isEditable) {
  //     getLeaveDetailsAction(user.employeeId);
  //   }
  //   if (isEditable && !!selectedPayload && selectedPayload.employee_id) {
  //     getLeaveDetailsAction(selectedPayload.employee_id);
  //   }
  // }, [user, selectedPayload]);
  const [onceFlag, setOnceFlag] = useState<boolean>(false);
  useEffect(() => {
    if (isEditable && startDate && endDate && !onceFlag) {
      handleLeaveCount();
      setOnceFlag(true);
    }
  }, [isEditable, user, startDate, endDate]);

  const handleHalfDays = (event: any) => {
    console.log("half day event :", event.target.checked);
    setIsHalfDay(event.target.checked);
    setValue("isHalfDay", Boolean(event.target.checked));
    setValue("end_date", getValues("start_date"));
    setEndDate(startDate);
    setTotalLeaveDaysClicked(true);
    setShowSubmitBtn(true);
    setShowcalBtn(false);

    if (!startDate || !endDate) {
      enqueueSnackbar(`Please Choose start Date and end Date`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");
    calculateLeaveDaysAction({
      start_date,
      end_date,
      employee_id: isEditable
        ? selectedPayload?.employee_id
        : user?.employee_detail.employee_id,
    });
    console.log("half days  show : ", isHalfDay);
    console.log("half day event ", getValues("isHalfDay"));
  };
  const handleFirstHalfDaysType = (event: any) => {
    setValue("half_day_type", "first");
    setHalfDayType("first");
  };
  const handleSecondHalfDaysType = (e: any) => {
    setValue("half_day_type", "second");
    setHalfDayType("second");
  };
  const handleDialogClose = () => {
    setOpenApplyDialog(false);
    setFormValue(null);
  };
  console.log("selected data  : ", getValues("leave_type_id"));
  useEffect(() => {
    if (!!selectedPayload && !!open) {
      setTimeout(() => {
        setValue("start_date", selectedPayload.start_date);
        setValue("end_date", selectedPayload.end_date);
        setStartDate(new Date(selectedPayload.start_date));
        setEndDate(new Date(selectedPayload.end_date));
        setValue("leave_type_id", Number(selectedPayload.leave_type_id));
        if (Number(selectedPayload.leave_count) === 0.5) {
          setValue("isHalfDay", true);
          setIsHalfDay(true);
        }
        if (
          !!selectedPayload?.half_day_type &&
          selectedPayload?.half_day_type.length > 0
        ) {
          setValue("half_day_type", selectedPayload?.half_day_type);
          setHalfDayType(selectedPayload?.half_day_type);
        }
        setValue("cause", selectedPayload.cause);
        const leaveName = leave_types.find(
          (self) =>
            Number(self.leave_type_id) === Number(selectedPayload.leave_type_id)
        );
      }, 500);
    }
    if (!open) {
      reset({
        start_date: "",
        end_date: "",
        leave_type_id: "",
      });
      setValue("leave_type_id", "");
      !!setSelectedPayload && setSelectedPayload(null);
    }
  }, [selectedPayload, open]);

  console.log(
    "leave name",
    leave_types.find(
      (self) =>
        Number(self.leave_type_id) === Number(getValues("leave_type_id"))
    )?.leave_type_name
  );

  useEffect(() => {
    console.log("getval", getValues("leave_type_id"));
    console.log("leave_types", leave_types);
    if (
      leave_types.find(
        (self) =>
          Number(self.leave_type_id) === Number(getValues("leave_type_id"))
      )?.leave_type_id === environment.leave_application.sick_leave ||
      leave_types.find(
        (self) =>
          Number(self.leave_type_id) === Number(getValues("leave_type_id"))
      )?.leave_type_id === environment.leave_application.casual_leave
    ) {
      setShowHalfDay(true);
      console.warn("fire check");
    } else {
      setShowHalfDay(false);
    }
  }, [watch("leave_type_id"), selectedPayload, leave_types]);
  useEffect(() => {
    GetAllLeaveTypeAction();
  }, []);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            sx={{
              background: "#0060FF",
              px: 3,
              py: 1,
              color: "#fff",
              fontWeight: 500,
              fontSize: "1.3rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>Apply For Leave</Box>
            <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mb={0.5} sx={{ maxHeight: "86.3vh", overflow: "auto" }}>
            <ApplicableLeave leaveAmountData={leaveAmountData} />
            <AllowLeaveApply
              open={openApplyDialog}
              setOpen={setOpenApplyDialog}
              aggreFunc={sendApplicationRequest}
              setClose={handleDialogClose}
            />
            <form>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                display="flex"
              >
                {/* <Grid item sm={10}>
                  <Typography ml={2} color="gray">
                    Select Designation <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={"designation_id"}
                    // defaultValue={`${
                    //   attendance_dashboard?.organization_id
                    //     ? attendance_dashboard.organization_id
                    //     : ""
                    // }`}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={uDesnFromLeave}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"designation_id"}
                        label={"designation_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Select Your Designation"}
                        value={!!value ? value : ""}
                        errors={!!errors["designation_id"]}
                      />
                    )}
                  />
                </Grid> */}

                <Grid item sm={10} mt={3} mb={1}>
                  <Typography ml={2} color="gray">
                    Select Leave Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={"leave_type_id"}
                    defaultValue={2}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={
                          leaveAmountData?.leave_count
                            ? leaveAmountData?.leave_count?.filter(
                                (self) =>
                                  self.remaining_count > 0 ||
                                  Number(selectedPayload?.leave_type_id) ===
                                    Number(self.leave_type_id)
                              )
                            : []
                          // !!leaveAmountData
                          //   ? leaveAmountData.leave_count.filter(
                          //       (self) => self.remaining_count > 0
                          //     )
                          //   : []
                        }
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"leave_type_id"}
                        label={"leave_type_name"}
                        disable={isEditable ? true : false}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Select Your Leave"}
                        value={!!value ? +value : ""}
                        errors={!!errors["leave_type_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography ml={2} color="gray">
                    Reason <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={"cause"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextareaAutosize
                        style={{
                          width: "98.5%",
                          marginLeft: "0.3rem",
                          borderRadius: "0.3rem",
                          height: 60,
                          padding: "0.5rem",
                          border: !!errors["cause"]
                            ? "1px solid red"
                            : "1px solid gray",
                        }}
                        required={true}
                        onBlur={onBlur}
                        id="holiday_desc"
                        className={"smaller"}
                        // id="standard-required"
                        onChange={onChange}
                        value={value}
                        placeholder={"Enter Your Reason"}
                        minRows={2}
                      />
                    )}
                  />
                  {!!errors["cause"] ? (
                    <span style={{ marginLeft: "1rem", color: "red" }}>
                      This field is required*
                    </span>
                  ) : (
                    <></>
                  )}
                </Grid>

                {/* {watch("designation_id") ? (
                  <Grid item sm={10}>
                    <Typography ml={2} color="gray">
                      Select Your Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      name={"employee_id"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={designationWiseEmp}
                          //   readOnly={!!department ? edit_flag : !edit_flag}
                          keys={"employee_id"}
                          label={"employee_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Select Your Name"}
                          value={!!value ? value : ""}
                          errors={!!errors["employee_id"]}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )} */}

                <Grid
                  item
                  sm={10}
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Controller
                    control={control}
                    name={"start_date"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <DesktopDatePicker
                        label={
                          <span>
                            Start Date <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        inputFormat="DD-MM-YYYY"
                        InputProps={{
                          sx: {
                            align: "center",
                            outline: "none",

                            height: "2.3rem",
                            border: "none",
                            ml: 1,
                          },
                        }}
                        views={["month", "day"]}
                        value={startDate}
                        onChange={handleStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={"end_date"}
                    defaultValue={""}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <DesktopDatePicker
                        ref={endYearRef}
                        label={
                          <span>
                            End Date <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        inputFormat="DD-MM-YYYY"
                        InputProps={{
                          sx: {
                            outline: "none",
                            height: "2.3rem",

                            border: "none",
                            ml: 1,
                          },
                        }}
                        views={["month", "day"]}
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </Grid>

                {showHalfDay &&
                moment(getValues("start_date")).format("DD-MM-YYYY") ===
                  moment(getValues("end_date")).format("DD-MM-YYYY") ? (
                  <Grid
                    item
                    sm={10}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Controller
                      control={control}
                      name={"isHalfDay"}
                      defaultValue={""}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Checkbox
                          checked={isHalfDay}
                          onChange={handleHalfDays}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                    />

                    <Typography>Half Day</Typography>
                  </Grid>
                ) : (
                  <></>
                )}

                {watch("isHalfDay") ? (
                  <Grid
                    item
                    sm={10}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Controller
                        control={control}
                        name={"half_day_type"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={halfDayType === "first"}
                            onChange={handleFirstHalfDaysType}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />

                      <Typography mx={2}>First Half</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Controller
                        control={control}
                        name={"half_day_type"}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Checkbox
                            checked={halfDayType === "second"}
                            onChange={handleSecondHalfDaysType}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      />

                      <Typography mx={2}>Second Half</Typography>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}

                {/* <Grid item sm={5}>
                  <Controller
                    control={control}
                    name={"end_date"}
                    defaultValue={endDate}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <DatePicker
                        views={["month", "day"]}
                        value={value}
                        InputProps={{
                          onBlur: onBlur,
                          size: "small",
                          sx: {
                            width: "116%",
                            marginLeft: 1,
                          },
                        }}
                        onChange={
                          (newValue) => (
                            onChange(newValue), setEndDate(newValue)
                          )
                          // PayrollAvailableEmployeeSuccessAction([]),
                          // SetapprovalList([])
                        }
                        renderInput={(params) => (
                          <TextField {...params} helperText={null} />
                        )}
                      />
                    )}
                  />
                </Grid> */}

                <Grid item xs={10}>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {showCalBtn ? (
                      <Button variant="contained" onClick={handleLeaveCount}>
                        {" "}
                        Calculate No Of Leave
                      </Button>
                    ) : (
                      <Box width="50%">
                        <Button
                          variant="outlined"
                          sx={{ px: 7 }}
                          onClick={() => setOpen(false)}
                        >
                          Close
                        </Button>
                      </Box>
                    )}
                    <Box>
                      {totalLeaveDaysClicked && !showCalBtn ? (
                        <Typography variant="h6" color="gray">
                          Total Leave Days :{" "}
                          {Boolean(getValues("isHalfDay"))
                            ? "Half Day"
                            : leaveDaysCount}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Box>

                    {!showCalBtn ? (
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ px: 5 }}
                      >
                        {isEditable ? "Edit" : "Apply"}
                      </Button>
                    ) : (
                      <Box width="50%"></Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

interface ILeaveApplicationModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selected_organization_leave?: number;
  designation_list: AllDesignationList[];
  allLeaveManagementDetails: IAllLeaveManagement[];
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  clearDesignationWiseLeave?: any;
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getEmployeeByDesignationAction?: any;
  user?: userDetails;
  leaveAmountData?: ILeaveTableData;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  applyLeaveAction?: any;
  resetLeaveDaysCalculationState?: any;
  defaultStartDate: string;
  isEditable?: boolean;
  selectedPayload?: LeaveApprovalList | null;
  getLeaveDetailsAction?: any;
  getLeaveAmountAction?: any;
  editLeaveAction?: any;
  setSelectedPayload?: any;
  natural_holiday_list: INaturalHoliday[];
  naturalLeaveDaysActionByEmployee?: any;
}

export default LeaveApplicationModal;
