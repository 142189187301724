import { stat } from "fs";
import React from "react";
import { connect } from "react-redux";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  PendingApprovalList,
} from "../../../models/attendanceModel";
import { ProjectList } from "../../../models/customerModel";
import { EmployeeTypeList, OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  ApproveAttendanceListAction,
  AttendanceDetailPayloadSuccessAction,
  GetAllPendingApprovalAction,
} from "../../../stores/actions/attendanceAction";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import { GetAllEmployeeTypeAction, GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import UploadedAttendanceView from "./UploadedAttendanceView";

const UploadedAttendace = ({
  GetAllOrganizationAction,
  organization_list,
  ApproveAttendanceListAction,
  attendance_approve_list,
  user,
  attendance_dashboard,
  AttendanceDetailPayloadSuccessAction,
  GetAllProjectAction,
  SidenavAutoCloseOpen,
  project_list,
employee_type_list
}: UploadedAttendaceProps) => {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);

  const Submit = (data: any) => {
    // console.log(data);
    if (!!user) {
      ApproveAttendanceListAction({
        ...data,
        created_by: user.userName,
      });
    }
  };
  return (
    <UploadedAttendanceView
      organization_list={organization_list}
      attendance_approve_list={attendance_approve_list}
      Submit={Submit}
      attendance_dashboard={attendance_dashboard}
      AttendanceDetailPayloadSuccessAction={
        AttendanceDetailPayloadSuccessAction
      }
      project_list={project_list}
      employee_type_list={employee_type_list}
      user={user}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    attendance_approve_list: state.attendance.attendance_approve_list,
    attendance_dashboard: state.attendance.attendance_dashboard,
    user: state.user.userDetails,
    project_list: state.customer.project_list,
    employee_type_list: state.organization.employee_type_list,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  ApproveAttendanceListAction,
  AttendanceDetailPayloadSuccessAction,
  GetAllProjectAction,
  SidenavAutoCloseOpen,
  GetAllEmployeeTypeAction,

};

export default connect(mapStateToProps, mapDispatchToProps)(UploadedAttendace);

interface UploadedAttendaceProps {
  GetAllOrganizationAction?: any;
  ApproveAttendanceListAction?: any;
  organization_list: OrganizationALlList[];
  attendance_approve_list: AttendanceApprovalList[];
  user?: userDetails;
  AttendanceDetailPayloadSuccessAction?: any;
  SidenavAutoCloseOpen?: any;
  attendance_dashboard?: AttendanceDashboardView[];
  GetAllProjectAction?: any;
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
}
