import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { EmpProject, PrjectExpence } from "../../../models/timingSheetModel";
import CommentIcon from "@mui/icons-material/Comment";
import { TransitionProps } from "@mui/material/transitions";

function ProjectExpences({
  m,
  control,
  errors,
  setValue,
  indexVal,
  getValues,
  reset,
  notEditable,
  notShow,
  ProjectExpences,
  status,
  isworklist,
  setStatus,
  totalLength,
  selectedProjectsIds,
}: ProjectExpencesProps) {
  // const [fieldName, setFieldName] = useState<any>("");
  // console.log("fieldName",fieldName);

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   formState,
  //   setValue,
  //   getValues,
  //   setError,
  //   clearErrors,
  //   reset,
  //   formState: { errors },
  // } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "monthly_expenses",
  });

  console.log("m", m);

  useEffect(() => {
    if (!!m) {
      setValue(`monthly_expenses[${indexVal}].project_id`, m?.project_id);
      if (m?.field_one_name) {
        console.log("field pushed");
        setValue(
          `monthly_expenses[${indexVal}].field_one_name`,
          m?.field_one_name
        );
      }
      if (m?.field_two_name)
        setValue(
          `monthly_expenses[${indexVal}].field_two_name`,
          m?.field_two_name
        );
      if (m?.field_three_name)
        setValue(
          `monthly_expenses[${indexVal}].field_three_name`,
          m?.field_three_name
        );
      if (m?.field_four_name)
        setValue(
          `monthly_expenses[${indexVal}].field_four_name`,
          m?.field_four_name
        );
      if (m?.field_five_name)
        setValue(
          `monthly_expenses[${indexVal}].field_five_name`,
          m?.field_five_name
        );
      if (m?.field_six_name)
        setValue(
          `monthly_expenses[${indexVal}].field_six_name`,
          m?.field_six_name
        );
      if (m?.field_seven_name)
        setValue(
          `monthly_expenses[${indexVal}].field_seven_name`,
          m?.field_seven_name
        );
      if (m?.field_eight_name)
        setValue(
          `monthly_expenses[${indexVal}].field_eight_name`,
          m?.field_eight_name
        );
    }
  }, [m, indexVal]);
  useEffect(() => {
    if (!!m && Array.isArray(m.expence_item)) {
      console.log("expence item : ", m.expence_item);
      console.log("expence hit : ", m);
      m.expence_item.forEach((self, index) => {
        if (m?.field_one_name.toLowerCase() === self.field.toLowerCase()) {
          console.log("hit self", self.field);
          console.log("hit expence", self.expense);
          setValue(`monthly_expenses[${indexVal}].field_one_val`, self.expense);
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_one_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_one_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_two_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(`monthly_expenses[${indexVal}].field_two_val`, self.expense);
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_two_id`,
            self.monthly_expenses_id
          );
          if (self.comments) {
            setValue(
              `monthly_expenses[${indexVal}].field_two_comment`,
              self.comments ? self.comments : ""
            );
          }
        }
        if (m?.field_three_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_three_val`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_three_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_three_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_four_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_four_val`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_four_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_four_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_five_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_five_val`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_five_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_five_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_six_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_six_name`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_six_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_six_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_seven_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_seven_name`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_seven_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_seven_comment`,
            self.comments ? self.comments : ""
          );
        }
        if (m?.field_eight_name.toLowerCase() === self.field.toLowerCase()) {
          setValue(
            `monthly_expenses[${indexVal}].field_eight_name`,
            self.expense
          );
          setValue(
            `monthly_expenses[${indexVal}].monthly_expenses_eight_id`,
            self.monthly_expenses_id
          );

          setValue(
            `monthly_expenses[${indexVal}].field_eight_comment`,
            self.comments ? self.comments : ""
          );
        }
      });
    }
  }, [m]);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = (data: any) => {
    switch (data) {
      case 1: {
        setOpen1(true);
        break;
      }
      case 2: {
        setOpen2(true);
        break;
      }
      case 3: {
        setOpen3(true);
        break;
      }
      case 4: {
        setOpen4(true);
        break;
      }
      case 5: {
        setOpen5(true);
        break;
      }
      case 6: {
        setOpen6(true);
        break;
      }
      case 7: {
        setOpen7(true);
        break;
      }
      case 8: {
        setOpen8(true);
        break;
      }
    }
  };

  const handleClose = (props: any) => {
    switch (props) {
      case 1: {
        setOpen1(false);
        break;
      }
      case 2: {
        setOpen2(false);
        break;
      }
      case 3: {
        setOpen3(false);
        break;
      }
      case 4: {
        setOpen4(false);
        break;
      }
      case 5: {
        setOpen5(false);
        break;
      }
      case 6: {
        setOpen6(false);
        break;
      }
      case 7: {
        setOpen7(false);
        break;
      }
      case 8: {
        setOpen8(false);
        break;
      }
    }
  };
  console.log("indexval : ", indexVal, totalLength);
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  // console.log("getvalues", getValues(`monthly_expenses[${indexVal}]`));
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          marginInline: matchesMobileView ? 0 : 3,
        }}
      >
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          sx={{
            backgroundColor: "white",

            width: matchesMobileView ? "100%" : "96%",
            // borderBottom: indexVal < totalLength - 1 ? "1px solid  #42a5f5" : "",
          }}
        >
          {/* <Grid
            item
            xs={12}
            justifyContent={"center"}
            alignSelf={"center"}
            style={{
              paddingLeft: "20px",
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Project Expenses of {m?.project_name}
            </Typography>
          </Grid> */}

          <Grid item xs={3.3} pt={1}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#626974",
                textAlign: "center",
              }}
            >
              {m?.project_name}
            </Typography>
          </Grid>
          <Grid item xs={8.7} pt={1} sx={{}}>
            <Grid container direction={"row"} alignItems={"center"} p={1}>
              {!!m?.field_one_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    p={matchesMobileView ? 0 : 1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_one_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_one_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            // disabled={
                            //   !!isworklist
                            //     ? false
                            //     : !!notEditable ||
                            //       status.toLowerCase() === "initiate"
                            // }

                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(1)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_two_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={matchesMobileView ? 0 : 1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_two_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_two_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(2)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_three_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_three_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_three_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(3)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_four_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_four_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_four_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(4)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_five_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_five_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_five_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(5)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_six_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_six_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_six_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(6)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_seven_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_seven_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_seven_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            size="small"
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(7)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!m?.field_eight_name && (
                <Grid item sm={6}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    p={1}
                  >
                    <Grid item sm={4}>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {m?.field_eight_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Controller
                        control={control}
                        name={`monthly_expenses[${indexVal}].field_eight_val`}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"number"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            disabled={
                              notShow
                                ? true
                                : !!isworklist &&
                                  status.toLowerCase() !== "approved"
                                ? false
                                : !!notEditable ||
                                  (!isworklist &&
                                    ["approved", "initiate"].includes(
                                      status.toLowerCase()
                                    ))
                            }
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Cost"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      sx={{
                        display: "flex",
                        justifyContent: matchesMobileView ? "flex-end" : "",

                        width: "100%",
                      }}
                    >
                      <Tooltip title="View">
                        <IconButton onClick={() => handleClickOpen(8)}>
                          <CommentIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={open1}
        // TransitionComponent={Transition}
        // keepMounted
        onClose={() => handleClose(1)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_one_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(1)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(2)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_two_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(2)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(3)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_three_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(3)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open4}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(4)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_four_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(4)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open5}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(5)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_five_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(5)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open6}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(6)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_six_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(6)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open7}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(7)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_seven_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(7)}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open8}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(8)}
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Expense Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Add Expenses Details</DialogContentText>
          <Controller
            control={control}
            name={`monthly_expenses[${indexVal}].field_eight_comment`}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                multiline
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                disabled={
                  notShow
                    ? true
                    : !!isworklist && status.toLowerCase() !== "approved"
                    ? false
                    : !!notEditable ||
                      (!isworklist &&
                        ["approved", "initiate"].includes(status.toLowerCase()))
                }
                size="small"
                onChange={onChange}
                value={value}
                placeholder={"Cost Details"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(8)}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProjectExpences;
interface ProjectExpencesProps {
  m?: PrjectExpence;
  control: Control<FieldValues, any>;
  indexVal: number;
  setValue: any;
  getValues: any;
  selectedProjectsIds: number[];
  status: string;
  setStatus: any;
  isworklist?: boolean;
  reset: any;
  notEditable?: boolean;
  notShow?: boolean;
  ProjectExpences?: boolean;
  totalLength: number;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}
