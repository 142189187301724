import React, { useEffect } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { TerminationInitiatedEmployeeList } from "../../../models/terminationModel";
import { userDetails } from "../../../models/userModels";
import { TerminationApprovalAction, TerminationInitiatedEmployeeListAction } from "../../../stores/actions/terminationAction";
import PendingApprovalTerminationView from "./PendingApprovalTerminationView";
import { useSnackbar } from "notistack";

export const PendingApprovalTermination = ({
  TerminationInitiatedEmployeeListAction,
  termination_initiated_emp_list,
  user,
  TerminationApprovalAction
}: PendingApprovalTerminationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    TerminationInitiatedEmployeeListAction({
      approverDesigId: user?.designation,
      eventType: 13,
    });
  }, []);
console.log("List", termination_initiated_emp_list);
const FinalApprovedSubmit=(data: any)=>{
  console.log("data", data);
  TerminationApprovalAction({
    data: data,
    enqueueSnackbar: enqueueSnackbar,
    data_list: {
      approverDesigId: user?.designation,
      eventType: 13,
    }
  })
  
}

  return (
    <PendingApprovalTerminationView
      termination_initiated_emp_list={termination_initiated_emp_list}
      FinalApprovedSubmit={FinalApprovedSubmit}
    />
  );
};

const mapStateToProps = (state: StoreState) => ({
  termination_initiated_emp_list:
    state.termination.termination_initiated_emp_list,
  user: state.user.userDetails,
});

const mapDispatchToProps = {
  TerminationInitiatedEmployeeListAction,
  TerminationApprovalAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingApprovalTermination);

interface PendingApprovalTerminationProps {
  TerminationInitiatedEmployeeListAction?: any;
  TerminationApprovalAction?: any;
  termination_initiated_emp_list: TerminationInitiatedEmployeeList[];
  user?: userDetails;
}
