import React, { useState } from "react";
import { TimeSheetHistoryList } from "../../../models/timingSheetModel";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import TimeSheetHistoryTable from "./TimeSheetHistoryTable";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
function HistoryCardView({
  m,
  goToTimingSheetFromWorklistAction,
  selectedMonth,
}: HistoryCardProps) {
  const navigate = useNavigate();
  const [openTable, setOpenTable] = useState<boolean>(false);
  const goToTimeSheet = (employeeID?: string, months?: any[]) => {
    console.log("employeeId : ", employeeID);
    const monthData =
      !!months && months?.length > 0
        ? moment(months[0].date).format("YYYY-MM")
        : "";
    navigate({
      pathname: "/timingsheet/log-activity/",
      search: `?isworklist=1&emp=${employeeID}`,
    });
    goToTimingSheetFromWorklistAction({
      employee_id: employeeID,
      month: `${monthData}`,
    });
  };
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid item width={isSmallDevice?"95%":"40%"} style={{ margin: 10, padding: 4}}>
        <Card
          elevation={5}
          sx={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minWidth: "25%",
            borderColor: "#132437",
            borderWidth: 1,
            borderStyle: "solid",
            ".css-9yqu2l-MuiCardContent-root:last-child ": {
              padding: "0px",
            },
          }}
        >
          <Grid container direction={"column"} display={"flex"} padding={0}>
            <CardHeader
              title={m?.employee_id}
              padding={0}
              titleTypographyProps={{
                style: {
                  fontSize: "15px",
                  fontWeight: "600",
                  fontFamily: "Poppins, sans-serif",
                  paddingLeft: 25,
                  color: "#fff",
                  textAlign: "center",
                },
              }}
              sx={{
                backgroundColor: "#132437",
                padding: 1,
                "&:.MuiCardHeader-content ": {
                  padding: 0,
                },
                "&:.MuiCardHeader-root ": {
                  padding: 0,
                },
              }}
            ></CardHeader>
            <CardContent sx={{ 
              height: "140px",
              padding: 1,
              margin: 0 }}>
              <Grid container direction={"row"} height="120%">
                <Grid item xs={3.5} container direction={"row"}>
                  <Grid item xs={12} justifyContent={"center"}>
                    <Avatar
                      sx={{ 
                      bgcolor: "#132437",
                      height: isSmallDevice ? 40 : 60,
                      width: isSmallDevice ? 40 : 60,
                    }}
                      aria-label="recipe"
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "100%",
                          fontFamily: "Poppins, sans-serif",
                          color: "#fff",
                        }}
                      >
                        {/* {!!m?.employee_name && m?.employee_name.length > 0
                          ? m?.employee_name[0]
                          : "A"} */}
                        {!!m?.total_hours ? m?.total_hours : "0"}
                      </Typography>
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} pt={1}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: 12,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                    >
                      Total Hours
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={8.5}
                  // sx={{ backgroundColor: "green" }}
                  container
                  direction={"row"}
                  height="100%"
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: 15,
                        fontFamily: "Poppins, sans-serif",
                        color: "#132437",
                      }}
                      pl={1}
                    >
                      {m?.employee_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "400",
                        fontSize: "80%",
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                      }}
                      pl={1}
                    >
                      {!!m?.projects
                        ? m.projects.length > 35
                          ? m.projects.slice(0, 12) + "..."
                          : m.projects
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      // paddingTop: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <Button variant="contained" style={{backgroundColor:"#44c4d4", width:"80%"}} onClick={() => setOpenTable(true)}>
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "65%",
                          fontFamily: "Poppins, sans-serif",
                          color: "white",
                        }}
                      >
                        View Time-Sheet
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingBottom:"5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <Button
                      variant="contained" style={{backgroundColor:"#44c4d4", width:"80%"}}
                      onClick={() => goToTimeSheet(m?.employee_id, m?.time)}
                    >
                      <Typography
                        style={{
                          fontWeight: "400",
                          fontSize: "65%",
                          fontFamily: "Poppins, sans-serif",
                          color: "white",
                        }}
                      >
                        Time Allocation
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Card>
      </Grid>
      {openTable === true && (
        <TimeSheetHistoryTable
          item={m?.time}
          openTable={openTable}
          setOpenTable={setOpenTable}
        />
      )}
    </>
  );
}

export default HistoryCardView;
interface HistoryCardProps {
  m?: TimeSheetHistoryList;
  goToTimingSheetFromWorklistAction?: any;
  selectedMonth?: string;
}
