import { organizationMain } from "../../models/organizationModels";
import { OrganizationActionTypes } from "../actions/organizationActions";
import InitialState from "./initialState";

const initialState: organizationMain = InitialState.organization;
export default function OrganizationReducer(
  state: organizationMain = initialState,
  action: any
) {
  switch (action.type) {
    case OrganizationActionTypes.Get_All_Filter_Organization_Success_Action:
      return { ...state, organization_filter_list: action.payload };
    case OrganizationActionTypes.Get_All_Department_Success_Action:
      return { ...state, department_list: action.payload };
    case OrganizationActionTypes.Get_All_Service_List_Success_Action:
      return { ...state, service_list: action.payload };
    case OrganizationActionTypes.Get_All_Designation_Success_Action:
      return { ...state, designation_list: action.payload };
    case OrganizationActionTypes.Get_All_Employee_Timing_Success_Action:
      return { ...state, employee_timing_list: action.payload };
    case OrganizationActionTypes.Get_All_Employee_Type_Success_Action:
      return { ...state, employee_type_list: action.payload };
    case OrganizationActionTypes.Domain_Get_All_Success_Action:
      return {
        ...state,
        domain_list: action.payload.component_type,
        salary_priod: action.payload.salary_priod,
      };
    case OrganizationActionTypes.Get_All_Salary_Structure_Success_Action:
      return { ...state, salary_structure_list: action.payload };
    case OrganizationActionTypes.Salary_Structure_Edit_Success_Action:
      return {
        ...state,
        salary_structure_edit: action.payload,
      };
    case OrganizationActionTypes.Salary_Component_GetAll_Success_Action:
      return { ...state, component_list: action.payload };
    case OrganizationActionTypes.Get_All_Post_Success_Action:
      return { ...state, post_list: action.payload };
    case OrganizationActionTypes.Salary_Calculation_Load_Success_Action:
      return {
        ...state,
        calculation_type: action.payload.calculation_type,
        operator: action.payload.operator,
        operator_tye: action.payload.operator_tye,
        global_key: action.payload.global_key,
      };
    case OrganizationActionTypes.Get_Main_Dashboard_Success_Action:
      return {
        ...state,
        currentMonthBirthday: action.payload.currentMonthBirthday,
        createEmpOnMonth: action.payload.createEmpOnMonth,
      };
    case OrganizationActionTypes.Salary_Component_Threshold_Parameter_Success_Action:
      return {
        ...state,
        salary_compo_threshold: action.payload,
      };
    default:
      return state;
  }
}
