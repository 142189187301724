import { baseUrl, environment } from "../environment";
import {
  DeletePromotionPayload,
  EmployeeListforPromotionPayload,
  InitiatePromotionPayload,
  PromotionApprovalPayload,
  PromotionListPayload,
  UpdatePromotionmPayload,
  ViewPromotionPayload,
} from "../models/promotionModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const PromotionListService = (data: PromotionListPayload) => {
  return serviceClient.post(environment.urls.promotionlist, data);
};
export const ViewPromotionService = (data: ViewPromotionPayload) => {
  return serviceClient.post(environment.urls.viewPromotionDetails, data);
};
export const PromotionApprovalService = (data: PromotionApprovalPayload) => {
  return serviceClient.post(environment.urls.promotionPendingApproval, data);
};
export const InitiatePromotionService = (data: InitiatePromotionPayload) => {
  return serviceClient.post(environment.urls.initiatePromotion, data);
};
export const UpdatePromotionService = (data: UpdatePromotionmPayload) => {
  return serviceClient.post(environment.urls.updatePromotion, data);
};
export const DeletePromotionService = (data: DeletePromotionPayload) => {
  return serviceClient.post(environment.urls.deletePromotion, data);
};
export const EmployeeAvailableListForPromotionService = (
  data: EmployeeListforPromotionPayload
) => {
  return serviceClient.post(
    environment.urls.empAvailableListForPromotion,
    data
  );
};
export const ActivateSalaryForPromotionService = (data: any) => {
  return serviceClient.post(environment.urls.activateSalaryForPromotion, data);
};
