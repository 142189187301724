import { Grid, Typography, TextField, Divider, AccordionDetails, Accordion, AccordionSummary } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { environment } from "../../../environment";
import Autocompletes from "../../../library/AutoComplete";
import { StateList } from "../../../models/customerModel";
import { userDetails } from "../../../models/userModels";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AddressDetails = ({
  control,
  errors,
  edit_candidate,
  edit_flag,
  user,
  id2,
  state_list
}: AddressDetailsProps) => {
  const countryOptions: any[] = [
    {
      key: "India",
      value: "India",
    },
  ];
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "7%",
                // marginBottom: 20,
              }}
            >
              Address Details
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={10} marginTop={1} marginBottom={1}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 17,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "8%",
                // marginBottom: 20,
                color: "#626974",
              }}
            >
              Present Address
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"

            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                {/* Address Line 1<span style={{ color: "red", fontSize: 14 }}>*</span> */}
                Flat,House No.,Building,Apartment
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 5 }}>
              <Controller
                control={control}
                name={"address_line1"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 100,
                    }}
                    value={value}
                    placeholder={"Flat,House No.,Building,Apartment"}
                    error={!!errors["address_line1"]}
                    helperText={
                      errors.address_line1 &&
                      `${errors.address_line1.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                {/* Address Line 2 */}
                {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                Area,Street,Sector,Village
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 4 }}>
              <Controller
                control={control}
                name={"address_line2"}
                defaultValue={""}
                rules={{ required: false }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 100,
                    }}
                    value={value}
                    placeholder={"Area,Street,Sector,Village"}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                City
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 4 }}>
              <Controller
                control={control}
                name={"city"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 40,
                    }}
                    value={value}
                    placeholder={"City"}
                    error={!!errors["city"]}
                    helperText={
                      errors.city &&
                      `${errors.city.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                District
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 4 }}>
              <Controller
                control={control}
                name={"district"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 40,
                    }}
                    value={value}
                    placeholder={"District"}
                    error={!!errors["district"]}
                    helperText={
                      errors.district &&
                      `${errors.district.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                State
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: -2 }}>
              {/* <Controller
            control={control}
            name={"state"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                inputProps={{
                  readOnly:
                   (!!id2 && +id2==1 ? false :
                    (!!user &&
                    environment.global_veriable.superAdminLevel.includes(
                      user.designation
                    )
                      ? edit_flag
                      : !edit_candidate) )
                }}
                value={value}
                placeholder={"State"}
                error={!!errors["state"]}
                helperText={
                  errors.state &&
                  `${errors.state.message}* This field is Required`
                }
              />
            )}
          /> */}
              <Controller
                control={control}
                name={"state"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={state_list}
                    style={{ width: "101.3%" }}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    keys={"state_name"}
                    label={"state_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"Search State Name"}
                    value={!!value ? value : ""}
                    errors={!!errors["state"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Country
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: -3 }}>
              <Controller
                control={control}
                name={"country"}
                defaultValue={"India"}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={countryOptions}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    style={{ width: "101.3%" }}
                    onChange={(value: any) => onChange(value)}
                    readOnly={
                      !!user &&
                      environment.global_veriable.superAdminLevel.includes(
                        user.designation
                      )
                        ? edit_flag
                        : !edit_candidate
                    }
                    placeholder={"Country"}
                    value={!!value ? value : ""}
                    errors={errors.country}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                PIN
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: 3 }}>
              <Controller
                control={control}
                name={"pin"}
                defaultValue={""}
                rules={{ required: true, minLength: 6, maxLength: 6 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                    }}
                    value={value}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    placeholder={"PIN"}
                    error={!!errors["pin"]}
                    helperText={
                      errors.pin &&
                      `${errors.pin.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} marginTop={1} marginBottom={4}>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 17,
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginLeft: "8%",
                marginBottom: 20,
                color: "#626974",
              }}
            >
              Permanent Address
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                {/* Address Line 1<span style={{ color: "red", fontSize: 14 }}>*</span> */}
                Flat,House No.,Building,Apartment
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5}>
              <Controller
                control={control}
                name={"paddress_line1"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 100,
                    }}
                    value={value}
                    placeholder={"Flat,House No.,Building,Apartment"}
                    error={!!errors["paddress_line1"]}
                    helperText={
                      errors.paddress_line1 &&
                      `${errors.paddress_line1.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                {/* Address Line 2 */}
                Area,Street,Sector,Village
              </Typography>
            </Grid>
            <Grid item xs={7.5} /*style={{ marginLeft: -5 }}*/>
              <Controller
                control={control}
                name={"paddress_line2"}
                defaultValue={""}
                rules={{ required: false }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 100,
                    }}
                    value={value}
                    placeholder={"Area,Street,Sector,Village"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                City
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} /*style={{ marginLeft: -5 }}*/>
              <Controller
                control={control}
                name={"pcity"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 40,
                    }}
                    value={value}
                    placeholder={"City"}
                    error={!!errors["pcity"]}
                    helperText={
                      errors.pcity &&
                      `${errors.pcity.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                District
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5}>
              <Controller
                control={control}
                name={"pdistrict"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"text"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                      maxLength: 40,
                    }}
                    value={value}
                    placeholder={"District"}
                    error={!!errors["pdistrict"]}
                    helperText={
                      errors.pdistrict &&
                      `${errors.pdistrict.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                State
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: -5 }}>
              {/* <Controller
            control={control}
            name={"pstate"}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field: { onBlur, value, onChange } }) => (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                onBlur={onBlur}
                size="small"
                onChange={onChange}
                inputProps={{
                  readOnly:
                    !!id2 && +id2 == 1
                      ? false
                      : !!user &&
                        environment.global_veriable.superAdminLevel.includes(
                          user.designation
                        )
                      ? edit_flag
                      : !edit_candidate,
                }}
                value={value}
                placeholder={"State"}
                error={!!errors["state"]}
                helperText={
                  errors.state &&
                  `${errors.state.message}* This field is Required`
                }
              />
            )}
          /> */}
              <Controller
                control={control}
                name={"pstate"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={state_list}
                    style={{ width: "101.3%" }}
                    readOnly={
                      !!id2 && +id2 == 1
                        ? false
                        : !!user &&
                          environment.global_veriable.superAdminLevel.includes(
                            user.designation
                          )
                        ? edit_flag
                        : !edit_candidate
                    }
                    keys={"state_name"}
                    label={"state_name"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    placeholder={"Search State Name"}
                    value={!!value ? value : ""}
                    errors={!!errors["pstate"]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                Country
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} style={{ marginLeft: -5 }}>
              <Controller
                control={control}
                name={"pcountry"}
                defaultValue={"India"}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={countryOptions}
                    keys={"key"}
                    label={"value"}
                    onBlur={onBlur}
                    onChange={(value: any) => onChange(value)}
                    readOnly={
                      !!user &&
                      environment.global_veriable.superAdminLevel.includes(
                        user.designation
                      )
                        ? edit_flag
                        : !edit_candidate
                    }
                    placeholder={"Country"}
                    value={!!value ? value : ""}
                    errors={errors.pcountry}
                    style={{ width: "101.3%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 10 }}
            // width={"100%"}
          >
            <Grid item xs={3.5}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "24%",
                  // textAlign: "center"
                }}
              >
                PIN
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={7.5} /*style={{ marginLeft: -5 }}*/>
              <Controller
                control={control}
                name={"ppin"}
                defaultValue={""}
                rules={{ required: true, minLength: 6, maxLength: 6 }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    type={"number"}
                    id="standard-required"
                    variant="outlined"
                    className={"smaller"}
                    style={{ width: "101.3%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    inputProps={{
                      readOnly:
                        !!id2 && +id2 == 1
                          ? false
                          : !!user &&
                            environment.global_veriable.superAdminLevel.includes(
                              user.designation
                            )
                          ? edit_flag
                          : !edit_candidate,
                    }}
                    value={value}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    placeholder={"PIN"}
                    error={!!errors["ppin"]}
                    helperText={
                      errors.ppin &&
                      `${errors.ppin.message}* This field is Required`
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider orientation="horizontal" variant="middle" flexItem />
      {/* <Divider
        style={{
          borderColor: "#DDDDDF",
          opacity: 0.8,
          width: "85%",
          marginLeft: "5%",
        }}
      ></Divider> */}
    </>
  );
};

export default AddressDetails;
interface AddressDetailsProps {
  errors?: any;
  control?: any;
  edit_candidate: boolean;
  edit_flag?: boolean;
  user?: userDetails;
  id2?: number;
  state_list: StateList[];
}
