import { Box } from "@mui/material";
import { FC } from "react";
import OrganizationAutoComplete from "../OrganizationAutoComplete";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import {
  IAllLeaveManagement,
  IDesignationWiseEmp,
  IHolidayList,
  ILeaveAmountData,
  ILeaveDetailsData,
  ILeaveTableData,
  ILeaveType,
  INaturalHoliday,
  IdesignationWiseLeave,
} from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import LeaveApplicationTable from "./LeaveApplicationTable";

const EmployeeLeaveApplication: FC<IEmployeeLeaveApplication> = ({
  holiday_list,
  getAllHolidaysAction,
  user,
  organizationList,
  designation_list,
  selectOrganization,
  GetAllOrganizationAction,
  GetAllDesignationAction,
  selected_organization_leave,
  allLeaveManagementDetails,
  getAllLeaveManagementData,
  getDesignationWiseLeaveAction,
  GetAllLeaveTypeAction,
  clearDesignationWiseLeave,
  designationWiseLeave,
  leave_types,
  designationWiseEmp,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  leaveAmountData,
  calculateLeaveDaysAction,
  leaveDaysCount,
  applyLeaveAction,
  getLeaveDetailsAction,
  leaveDetailsData,
  resetLeaveDaysCalculationState,
  startDate,
  setAddLeaveModal,
  addLeaveModal,
  editLeaveAction,
  setStartDate,
  cancelLeaveAction,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}): JSX.Element => {
  return (
    <Box ml={7} height="auto">
      <Box>
        <LeaveApplicationTable
          GetAllDesignationAction={GetAllDesignationAction}
          selected_organization_leave={selected_organization_leave}
          designation_list={designation_list}
          allLeaveManagementDetails={allLeaveManagementDetails}
          getAllLeaveManagementData={getAllLeaveManagementData}
          organizationList={organizationList}
          getDesignationWiseLeaveAction={getDesignationWiseLeaveAction}
          clearDesignationWiseLeave={clearDesignationWiseLeave}
          GetAllLeaveTypeAction={GetAllLeaveTypeAction}
          designationWiseLeave={designationWiseLeave}
          leave_types={leave_types}
          designationWiseEmp={designationWiseEmp}
          getEmployeeByDesignationAction={getEmployeeByDesignationAction}
          getLeaveAmountAction={getLeaveAmountAction}
          leaveAmountData={leaveAmountData}
          calculateLeaveDaysAction={calculateLeaveDaysAction}
          leaveDaysCount={leaveDaysCount}
          applyLeaveAction={applyLeaveAction}
          getLeaveDetailsAction={getLeaveDetailsAction}
          leaveDetailsData={leaveDetailsData}
          resetLeaveDaysCalculationState={resetLeaveDaysCalculationState}
          user={user}
          setAddLeaveModal={setAddLeaveModal}
          addLeaveModal={addLeaveModal}
          startDate={startDate}
          setStartDate={setStartDate}
          editLeaveAction={editLeaveAction}
          cancelLeaveAction={cancelLeaveAction}
          natural_holiday_list={natural_holiday_list}
          naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
        />
      </Box>
    </Box>
  );
};

interface IEmployeeLeaveApplication {
  holiday_list: IHolidayList[];
  getAllHolidaysAction?: any;
  user: userDetails | undefined;
  organizationList: OrganizationALlList[];
  selectOrganization?: any;
  GetAllOrganizationAction?: any;
  GetAllDesignationAction?: any;
  selected_organization_leave?: number;
  designation_list: AllDesignationList[];
  allLeaveManagementDetails: IAllLeaveManagement[];
  getAllLeaveManagementData?: any;
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  clearDesignationWiseLeave?: any;
  GetAllLeaveTypeAction?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getEmployeeByDesignationAction?: any;
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  applyLeaveAction?: any;
  getLeaveDetailsAction?: any;
  leaveDetailsData: ILeaveDetailsData[];
  resetLeaveDaysCalculationState?: any;
  setAddLeaveModal: any;
  addLeaveModal: boolean;
  startDate: string;
  editLeaveAction?: any;
  setStartDate: any;
  cancelLeaveAction?: any;
  natural_holiday_list: INaturalHoliday[];
  naturalLeaveDaysActionByEmployee?: any;
}
export default EmployeeLeaveApplication;
