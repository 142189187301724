import { Dispatch, FC, useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SetStateAction } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  ICreateLeaveManagementPayload,
  IEditLeaveManagementPayload,
  ILeaveType,
  ILeaveTypeDataDesignationWise,
} from "../../../models/leaveModel";
import AddLeaveTypeModal from "./AddLeaveTypeModal";
import { AllDesignationList } from "../../../models/organizationModels";
import LeaveManagementActionModal from "./LeaveManagementActionModal";
import { useSnackbar } from "notistack";
import { userDetails } from "./../../../models/userModels";
import DeleteLeaveManagementDialog from "./DeleteLeaveManagementDialog";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowX: "hidden"
};

const LeaveManagementDetails: FC<ILeaveDetails> = ({
  open,
  setOpen,
  user,
  leaveTypeDesignationWise,
  getLeaveDetailsDesignationwiseAction,
  editLeaveManagementAction,
  selectedDesignation,
  leaveDetails,
  GetAllDesignationAction,
  createLeaveManagementAction,
  deleteLeaveManagementAction,
  GetAllLeaveTypeAction,
  designationList,
  leaveTypes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteOption, setDeleteOption] = useState<{
    leave_management_id: number;
    designation_id: number;
  } | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editablePayload, setEditablePayload] = useState<{
    leave_type_id: number;
    leave_amount: number;
    leave_management_id: number;
  } | null>(null);
  const column1: string[] = [
    "Leave Type Name",
    "No. Of Leave",
    "Carry Forward",
    "Auto Approved",
    "Actions",
  ];

  useEffect(() => {
    if (open && selectedDesignation) {
      getLeaveDetailsDesignationwiseAction(selectedDesignation);
      GetAllDesignationAction();
      GetAllLeaveTypeAction();
    }
    if (!open) {
      setExpanded(false);
    }
  }, [open, selectedDesignation]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  console.log("leaveTypeDesignationWise details : ", leaveTypeDesignationWise);
  const createLeaveManagementDesignationWiseMethod = (
    formData: ICreateLeaveManagementPayload
  ) => {
    const payload = {
      formData: {
        ...formData,
        leave_amount: Number(formData.leave_amount),
        organization_id: Number(user?.employee_detail.organization_id),
      },
      enqueueSnackbar,
    };
    createLeaveManagementAction(payload);
  };
  const editLeaveManagementRequest = (
    formData: IEditLeaveManagementPayload
  ) => {
    const payload = {
      formData: {
        ...formData,
        leave_amount: Number(formData.leave_amount),
        organization_id: Number(user?.employee_detail.organization_id),
      },
      enqueueSnackbar,
    };
    editLeaveManagementAction(payload);
  };
  const fetchFormData = (editRow: {
    leave_type_id: number;
    leave_amount: number;
    leave_management_id: number;
  }) => {
    setEditablePayload(editRow);
    setExpanded("panel1");
  };

  const deleteManagementRequest = (delId: number, designation_id: number) => {
    setDeleteModalOpen(true);
    setDeleteOption({
      leave_management_id: delId,
      designation_id: designation_id,
    });
  };
  const [filteredLeaveTypes, setFilterLeaveTypes] = useState<ILeaveType[]>([]);
  useEffect(() => {
    const leaveTypeDesIds = leaveTypeDesignationWise.map(
      (self) => self.leave_type_id
    );
    const leaveType = leaveTypes.filter(
      (self) =>
        !leaveTypeDesIds.includes(self.leave_type_id) ||
        (!!editablePayload && editablePayload.leave_type_id)
    );
    setFilterLeaveTypes(leaveType);
  }, [leaveTypes, leaveTypeDesignationWise, editablePayload]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                background: "#0060FF",
                color: "#fff",
                p: 2,
                fontSize: "1.3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>Leave Details</Box>
              <Box>
                <IconButton sx={{ color: "#fff" }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}
            >
              <Grid container spacing={2} mx={2} mt={1}>
                <Grid xs={10.5} sx={{ mx: "auto" }}>
                  <DeleteLeaveManagementDialog
                    isOpen={deleteModalOpen}
                    setIsOpen={setDeleteModalOpen}
                    deloption={deleteOption}
                    setDelOption={setDeleteOption}
                    deleteLeaveManagementAction={deleteLeaveManagementAction}
                  />
                  <AddLeaveTypeModal
                    leaveTypes={filteredLeaveTypes}
                    // leaveTypes={leaveTypes.filter((self, index) =>
                    //   leaveTypeDesignationWise.length > index
                    //     ? (!!editablePayload &&
                    //         editablePayload.leave_type_id ===
                    //           self.leave_type_id) ||
                    //       leaveTypeDesignationWise[index].leave_type_name !==
                    //         self.leave_type_name
                    //     : self.leave_type_name
                    // )}
                    designationList={designationList}
                    leaveDetails={leaveDetails}
                    expanded={expanded}
                    handleChange={handleChange}
                    setExpanded={setExpanded}
                    editablePayload={editablePayload}
                    editLeaveManagementRequest={editLeaveManagementRequest}
                    setEditablePayload={setEditablePayload}
                    createLeaveManagementDesignationWiseMethod={
                      createLeaveManagementDesignationWiseMethod
                    }
                    leaveTypeDesignationWise={leaveTypeDesignationWise.filter(
                      (self) => self.designation_id !== selectedDesignation
                    )}
                    totalLeave={leaveTypeDesignationWise.reduce(
                      (acc, cur) => acc + cur.leave_amount,
                      0
                    )}
                    selectedDesignation={selectedDesignation}
                  />
                </Grid>
              </Grid>
              {!expanded ? (
                <Box ml={12}>
                  <Button
                    variant="outlined"
                    sx={{ mb: 1, mt: 3 }}
                    onClick={() => (
                      setExpanded("panel1"), setEditablePayload(null)
                    )}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span>Add</span>
                      <AddIcon sx={{ ml: 2 }} />
                    </Box>
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              <Grid container spacing={2} mx={1}>
                <Grid item xs={0.5}></Grid>
                <Grid
                  item
                  xs={11}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TableContainer
                    sx={{ width: "100%", borderRadius: 1, mx: 2, mb: 5 }}
                  >
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{
                        "& .MuiTableRow-root:hover": {
                          // backgroundColor: "#73c6ff",
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {column1.map((column) => (
                            <StyledTableCell align="justify" key={column}>
                              {column}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!leaveTypeDesignationWise &&
                          leaveTypeDesignationWise.map((row, index: number) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <StyledTableCell align="left" key={column1[0]}>
                                {row.leave_type_name}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[1]}>
                                {row.leave_amount}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[2]}>
                                {row.carry_forward === 1 ? (
                                  <span
                                    style={{ color: "green", fontWeight: 700 }}
                                  >
                                    Yes
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontWeight: 700 }}
                                  >
                                    No
                                  </span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[2]}>
                                {row.auto_approved === 1 ? (
                                  <span
                                    style={{ color: "green", fontWeight: 700 }}
                                  >
                                    Yes
                                  </span>
                                ) : (
                                  <span
                                    style={{ color: "red", fontWeight: 700 }}
                                  >
                                    No
                                  </span>
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[4]}>
                                <IconButton
                                  onClick={() =>
                                    fetchFormData({
                                      leave_type_id: row.leave_type_id,
                                      leave_amount: row.leave_amount,
                                      leave_management_id:
                                        row.leave_management_id,
                                    })
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    deleteManagementRequest(
                                      row.leave_management_id,
                                      row.designation_id
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        {!!leaveTypeDesignationWise &&
                          leaveTypeDesignationWise.length <= 0 && (
                            <StyledTableRow>
                              <StyledTableCell colSpan={11}>
                                <div
                                  style={{ color: "red", textAlign: "center" }}
                                >
                                  No Data Found{" "}
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                my={2}
              >
                <Button onClick={() => setOpen(false)} variant="outlined">
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ILeaveItem {
  designation_name: string;
  no_of_leave: number;
}

interface ILeaveDetails {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  leaveTypeDesignationWise: ILeaveTypeDataDesignationWise[];
  editLeaveManagementAction?: any;
  getLeaveDetailsDesignationwiseAction?: any;
  selectedDesignation: number;
  leaveDetails: ILeaveItem | null;
  designationList: AllDesignationList[];
  createLeaveManagementAction?: any;
  GetAllDesignationAction?: any;
  GetAllLeaveTypeAction?: any;
  deleteLeaveManagementAction?: any;
  leaveTypes: ILeaveType[];
  user?: userDetails;
}

export default LeaveManagementDetails;
