import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateEmployeeTypeAction,
  EditEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import SetupEmployeeTypeView from "./SetupEmployeeTypeView";

function SetupEmployeeType({
  organization_list,
  GetAllOrganizationAction,
  CreateEmployeeTypeAction,
  employee_type_list,
  EditEmployeeTypeAction
}: SetupEmployeeTypeProps) {
  React.useEffect(() => {
    GetAllOrganizationAction();
  }, []);
  let navigateTo = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const SubmitUpdatedData = (data: any) => {
    EditEmployeeTypeAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const Submit = (data: any) => {
    console.log("data", data);
    data.org_id = data.organization_id;
    CreateEmployeeTypeAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <SetupEmployeeTypeView
      employeeType={employee_type_list.find(
        (m) => !!id && m.employee_type_id == +id
      )}
      organization_list={organization_list}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    employee_type_list: state.organization.employee_type_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  CreateEmployeeTypeAction,
  EditEmployeeTypeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupEmployeeType);

interface SetupEmployeeTypeProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  CreateEmployeeTypeAction?: any;
  employee_type_list: EmployeeTypeList[];
  EditEmployeeTypeAction?: any;
}
