import { Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { ProjectwiseDetailsMonthlyReport } from "../../../models/reportsModel";

function ChartView({
  checkedDimensions,
  projectwise_details_monthly_report,
}: ChartViewProps) {
  const [options, setOption] = useState<any>(undefined);
  useEffect(() => {
    let employee_salary: any[] = [];
    let aditional_expense: any[] = [];
    let project_payment: any[] = [];
    let xAxis: any[] = [];
    if (!!projectwise_details_monthly_report && checkedDimensions?.length > 0) {
      checkedDimensions?.forEach((m) => {
        let target_data_set: any[] = JSON.parse(
          JSON.stringify(projectwise_details_monthly_report[m])
        );
        target_data_set.forEach((n) => {
          if (m == "emp_expense") {
            let t = employee_salary.find((m) => m.tx_month == n.tx_month);
            if (!!t) {
              t.employee_expense = t.employee_expense + n.employee_expense;
              t.project_name = t.project_name + "," + n.project_name;
            } else {
              employee_salary.push(n);
            }
          } else if (m == "aditional_expense") {
            let t = aditional_expense.find((m) => m.tx_month == n.tx_month);
            if (!!t) {
              t.additional_expense =
                t.additional_expense + n.additional_expense;
              t.project_name = t.project_name + "," + n.project_name;
            } else {
              aditional_expense.push(n);
            }
          } else {
            let t = project_payment.find((m) => m.tx_month == n.tx_month);
            if (!!t) {
              t.gst_payment = t.gst_payment + n.gst_payment;
              t.project_name = t.project_name + "," + n.project_name;
            } else {
              project_payment.push(n);
            }
          }
        });
        target_data_set.forEach((m) => {
          let l = xAxis.find((n) => n == m.tx_month);
          if (!l) {
            xAxis.push(m.tx_month);
          }
        });
      });
    }

    const option = {
      // title: {
      //   text: "Project Report",
      // },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: xAxis,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: `Employee Expenses`,
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: employee_salary.map((m) => m.employee_expense),
        },
        {
          name: `Additional Expenses`,
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: aditional_expense.map((m) => m.additional_expense),
        },
        {
          name: `Payment`,
          type: "line",
          data: project_payment.map((m) => m.gst_payment),
        },
      ],
    };
    setOption(option);
  }, [checkedDimensions, projectwise_details_monthly_report]);

  // if (!!checkedDimensions && checkedDimensions.length > 0) {
  //   DetForGraph = checkedDimensions.map((item: any) => item);

  //   let EmpExpenseMonth: any[] = DetForGraph.find(
  //     (m) => m?.emp_expense
  //   )?.emp_expense.map((n: any) => n?.tx_month);

  //   AdditionalExpenseMonth = DetForGraph.find(
  //     (m) => m?.aditional_expense
  //   )?.aditional_expense.map((n: any) => n?.tx_month);

  //   ProjectExpenseMonth = DetForGraph.find(
  //     (m) => m?.project_payment
  //   )?.project_payment.map((n: any) => n?.tx_month);

  //   // if (!!EmpExpenseMonth || !!AdditionalExpenseMonth || !!ProjectExpenseMonth) {
  //   //   eventFilter = [...AdditionalExpenseMonth, ...ProjectExpenseMonth, ...EmpExpenseMonth];
  //   //   MonthRangeForXAxis = eventFilter.filter((value) =>
  //   //   newCommonMonth.includes(value)
  //   //   );
  //   // }
  //   if (!!EmpExpenseMonth) {
  //     eventFilter = [...EmpExpenseMonth];
  //   } else if (!!AdditionalExpenseMonth) {
  //     eventFilter = [...AdditionalExpenseMonth];
  //   } else if (!!ProjectExpenseMonth) {
  //     eventFilter = [...ProjectExpenseMonth];
  //   } else if (!!EmpExpenseMonth && !!AdditionalExpenseMonth) {
  //     eventFilter = [...EmpExpenseMonth, ...AdditionalExpenseMonth];
  //   } else if (!!ProjectExpenseMonth && !!AdditionalExpenseMonth) {
  //     eventFilter = [...ProjectExpenseMonth, ...AdditionalExpenseMonth];
  //   } else if (!!EmpExpenseMonth && !!ProjectExpenseMonth) {
  //     eventFilter = [...ProjectExpenseMonth, ...EmpExpenseMonth];
  //   } else if (
  //     !!EmpExpenseMonth &&
  //     !!AdditionalExpenseMonth &&
  //     !!ProjectExpenseMonth
  //   ) {
  //     eventFilter = [
  //       ...AdditionalExpenseMonth,
  //       ...ProjectExpenseMonth,
  //       ...EmpExpenseMonth,
  //     ];
  //   } else {
  //   }
  //   console.log(eventFilter, "event-filter");
  // }
  // // console.log("eventFilter", eventFilter);
  // function removeusingSet(arr: any) {
  //   let outputArray = Array.from(new Set(arr));
  //   return outputArray;
  // }
  // MonthRangeForXAxis = removeusingSet(eventFilter);
  // console.log(MonthRangeForXAxis);

  // let ProjectNameViewInChart: any;
  // let ProjectName =
  //   !!DetForGraph &&
  //   (DetForGraph.find((m) => m?.emp_expense)?.emp_expense.map(
  //     (n: any) => n?.project_name
  //   ) ||
  //     DetForGraph.find((m) => m?.aditional_expense)?.aditional_expense.map(
  //       (n: any) => n?.project_name
  //     ) ||
  //     DetForGraph.find((m) => m?.project_payment)?.project_payment.map(
  //       (n: any) => n?.project_name
  //     ));

  // ProjectNameViewInChart = removeusingSet(ProjectName);
  // console.log("ProjectNameViewInChart", ProjectNameViewInChart);

  // let EmpExpensesOptions = DetForGraph.find(
  //   (m) => m?.emp_expense
  // )?.emp_expense.filter((n: any) => ProjectNameViewInChart.includes( n?.project_name)).map((m: any)=>m.employee_expense);

  // let EmpExpensesOptions = DetForGraph.find(
  //   (m) => m?.emp_expense
  // )?.emp_expense.map(
  //   (m: any) =>
  //     ProjectNameViewInChart.includes(m?.project_name) &&
  //     MonthRangeForXAxis.includes(m?.tx_month)
  // );

  // let PrjExpensesOptions = DetForGraph.find((m) => m?.project_payment)
  //   ?.project_payment.filter((n: any) =>
  //     ProjectNameViewInChart.includes(n?.project_name)
  //   )
  //   .map((m: any) => m.gst_payment);

  // let AddlExpensesOptions = DetForGraph.find((m) => m?.aditional_expense)
  //   ?.aditional_expense.filter((n: any) =>
  //     ProjectNameViewInChart.includes(n?.project_name)
  //   )
  //   .map((m: any) => m.additional_expense);

  // console.log("EmpExpensesOptions", EmpExpensesOptions);
  // console.log("PrjExpensesOptions", PrjExpensesOptions);
  // console.log("AddlExpensesOptions", AddlExpensesOptions);

  // const categories = () => {
  //   let now = new Date();
  //   let res = [];
  //   let len = 10;
  //   while (len--) {
  //     res.unshift(now.toLocaleTimeString().replace(/^\D*/, ""));
  //     now = new Date(+now - 2000);
  //   }
  //   return res;
  // };
  // const categories2 = () => {
  //   let res = [];
  //   let len = 10;
  //   while (len--) {
  //     res.push(10 - len - 1);
  //   }
  //   return res;
  // };
  // const data = () => {
  //   let res = [];
  //   let len = 10;
  //   while (len--) {
  //     res.push(Math.round(Math.random() * 1000));
  //   }
  //   return res;
  // };
  // const data2 = () => {
  //   let res = [];
  //   let len = 0;
  //   while (len < 10) {
  //     res.push(+(Math.random() * 10 + 5).toFixed(1));
  //     len++;
  //   }
  //   return res;
  // };
  // const data3 = () => {
  //   let res = [];
  //   let len = 0;
  //   while (len < 10) {
  //     res.push(+(Math.random() * 10 + 25).toFixed(1));
  //     len++;
  //   }
  //   return res;
  // };
  // const data4 = () => {
  //   let res = [];
  //   let len = 0;
  //   while (len < 10) {
  //     res.push(+(Math.random() * 10 + 35).toFixed(1));
  //     len++;
  //   }
  //   return res;
  // };
  //   const options = {
  //     title: {
  //       text: "Project Report",
  //     },
  //     tooltip: {
  //       trigger: "axis",
  //       axisPointer: {
  //         type: "cross",
  //         label: {
  //           backgroundColor: "#283b56",
  //         },
  //       },
  //     },
  //     /*
  //     color: ["#e91e63", "#354EF6"],
  //     title: {
  //       subtext: "Data from the Singapore Department of Statistics",
  //       textAlign: "left",
  //       left: "5%",
  //     },
  //     tooltip: { backgroundColor: "#555", borderWidth: 0, padding: 10 },
  //     grid: {
  //         top: 100,
  //         bottom: 150,
  //         tooltip: {
  //           trigger: "axis",
  //           axisPointer: {
  //             type: "shadow",
  //             label: {
  //               show: true,
  //               formatter: function(params: any) {
  //                 return params.value.replace("\n", "");
  //               }
  //             }
  //           }
  //         }
  //       },
  // */
  //     legend: {},
  //     toolbox: {
  //       show: true,
  //       feature: {
  //         dataView: { readOnly: false },
  //         restore: {},
  //         saveAsImage: {},
  //       },
  //     },
  //     dataZoom: {
  //       show: false,
  //       start: 0,
  //       end: 100,
  //     },
  //     xAxis: [
  //       {
  //         type: "category",
  //         boundaryGap: true,
  //         data: categories,
  //         // data: !!checkedDimensions && checkedDimensions.map((m)=>m.tx_month),
  //       },
  //       {
  //         type: "category",
  //         boundaryGap: true,
  //         data: categories2,
  //         // data: !!checkedDimensions && checkedDimensions.map((m)=>m.tx_month),
  //       },
  //     ],
  //     /*
  //     xAxis: [
  //       {
  //         axisLabel: {
  //           interval: 0,
  //           rotate: 55,
  //           textStyle: {
  //             baseline: "top",
  //             color: "#333",
  //             fontSize: 10,
  //             fontWeight: "bold",
  //           },
  //         },
  //         axisLine: { lineStyle: { color: "#aaa" }, show: true },
  //         axisTick: { show: false },
  //         data: categories2,
  //         splitLine: { show: false },
  //         type: "category",
  //       },
  //     ],
  //     */
  //     yAxis: [
  //       {
  //         type: "value",
  //         scale: true,
  //         name: "Price",
  //         max: 30,
  //         min: 0,
  //         boundaryGap: [0.2, 0.2],
  //       },
  //       {
  //         type: "value",
  //         scale: true,
  //         name: "Order",
  //         max: 1200,
  //         min: 0,
  //         boundaryGap: [0.2, 0.2],
  //       },
  //     ],
  //     /*
  //     yAxis: [
  //       {
  //         axisLabel: {
  //           textStyle: { fontSize: 10 },
  //         },
  //         axisLine: { show: false },
  //         axisTick: { show: false },
  //         name: "Population",
  //         splitLine: {
  //           lineStyle: {
  //             type: "dotted",
  //           },
  //         },
  //         type: "value",
  //       },
  //     ],
  //     */
  //     series: [
  //         {
  //           name: "Email",
  //           type: "bar",
  //           stack: "Ad",
  //           emphasis: {
  //             focus: "series",
  //           },
  //           // data: [120, 132, 101, 134, 90, 230, 210],
  //           data: data,
  //         },
  //         {
  //           name: "Union Ads",
  //           type: "bar",
  //           stack: "Ad",
  //           emphasis: {
  //             focus: "series",
  //           },
  //           data: data3
  //           // data: [220, 182, 191, 234, 290, 330, 310],
  //         },
  //         {
  //           name: "Video Ads",
  //           type: "bar",
  //           stack: "Ad",
  //           emphasis: {
  //             focus: "series",
  //           },
  //           // data: [150, 232, 201, 154, 190, 330, 410],
  //           data: data4,
  //         },
  //     //   {
  //     //     name: "Dynamic Bar",
  //     //     type: "bar",
  //     //     xAxisIndex: 1,
  //     //     yAxisIndex: 1,
  //     //     data: data,
  //     //   },
  //       {
  //         name: "Dynamic Line",
  //         type: "line",
  //         // data: [60, 72, 71, 74, 190, 130, 110],
  //         data: data2,
  //       },
  //     ],
  //   };
  console.log(options);
  return (
    <Paper elevation={5} sx={{ marginY: 1, marginX: 3, padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {!!options && (
          <ReactEcharts
            option={options}
            style={{ width: "100%", height: "450px" }}
          ></ReactEcharts>
        )}
      </Box>
    </Paper>
  );
}

export default ChartView;
interface ChartViewProps {
  checkedDimensions: any[];
  projectwise_details_monthly_report?: any;
}
