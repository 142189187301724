import { ChangeEvent, FC, useState, useEffect } from "react";
import {
  ILeaveApplicationDetailsDashboard,
  ILeaveDetailsData,
  ILeaveTableData,
  LeaveDetail,
} from "../../../models/leaveModel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  NativeSelect,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const LeaveRecord: FC<ILeaveRecord> = ({
  getLeaveDetailsForDashboardAction,
  leaveDetailsForDashboard,
}) => {
  const styles = {
    marginRight: "50px",
  };
  const [rows, setRows] = useState<LeaveDetail[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  // const requestSearch = (searchedVal: string) => {
  //   // const filteredRows = !!
  //   //   ? leaveDetailsData.filter((self) => {
  //   //       return (
  //   //         self.leave_status
  //   //           .toLowerCase()
  //   //           .includes(searchedVal.toLowerCase()) ||
  //   //         self.leave_type_name
  //   //           .toLowerCase()
  //   //           .includes(searchedVal.toLowerCase())
  //   //       );
  //   //     })
  //   //   : [];
  //   setRows([]);
  // };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  console.log("leaveDetailsForDashboard : ", leaveDetailsForDashboard);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };
  // const [selectedDate, setSelectedDate] = useState<any>(new Date());
  // useEffect(() => {
  //   console.log("selectedDate = " + new Date(selectedDate).getFullYear());
  //   if (selectedDate)
  //     getLeaveDetailsForDashboardAction(new Date(selectedDate).getFullYear());
  // }, [selectedDate]);
  const column1: string[] = [
    "Leave Type",
    "Start Date",
    "End Date",
    "Approval Status",
    "No. Of Days",
    "Reason For Leave",
  ];
  console.log(
    "    leaveDetailsForDashboard.leave_detail",
    leaveDetailsForDashboard?.leave_detail
  );
  return (
    <>
      <TableContainer sx={{ width: "98%", borderRadius: 1, mx: 2, my: 2 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            "& .MuiTableRow-root:hover": {
              // backgroundColor: "#73c6ff",
              cursor: "pointer",
            },
          }}
        >
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {column1.map((column) => (
                <StyledTableCell align="justify" key={column}>
                  {column}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!leaveDetailsForDashboard &&
              Array.isArray(leaveDetailsForDashboard.leave_detail) &&
              leaveDetailsForDashboard.leave_detail
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <StyledTableCell align="left" key={[0]}>
                      {row.leave_type_name}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={[1]}>
                      {moment(row.start_date).format("DD-MMM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={[2]}>
                      {moment(row.end_date).format("DD-MMM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={[3]}>
                      {row.leave_status}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={[4]}>
                      {row.leave_count}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={[4]}>
                      {row?.cause}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            {leaveDetailsForDashboard?.leave_detail?.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell align="center" key={[3]} colSpan={5}>
                  <Typography color="red" textAlign="center" variant="body2">
                    No Data Found
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={styles}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={leaveDetailsForDashboard?.leave_detail?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 12, sm: 11, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ILeaveRecord {
  getLeaveDetailsForDashboardAction?: any;
  leaveDetailsForDashboard?: ILeaveTableData;
}
export default LeaveRecord;
