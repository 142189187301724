import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocompletes from "../../../library/AutoComplete";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import {
  CustomerList,
  Division,
  ProjectList,
  ViewProject,
} from "../../../models/customerModel";
import moment from "moment";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";

const ViewAttendanceView = ({
  organization_list,
  project_list,
  customer_list,
  employee_type_list,
  Submit,
  project_detail,
  ProjectSelect,
  user,
}: ViewAttendanceViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  useEffect(() => {
    if (!!user) {
      if (
        !environment.global_veriable.ho_employees_type_id.includes(
          user.employee_detail.employee_type_id
        ) ||
        environment.global_veriable.managerLevelDeg.includes(user.designation)
      ) {
        setValue("organization_id", +user.employee_detail.organization_id);
      }
      if (
        !environment.global_veriable.officialEmployee.includes(user.designation)
      ) {
        setValue("employee_type_id", user.employee_detail.employee_type_id);
        if (user.project_detail.length >= 1) {
          setValue("project_id", user.project_detail[0].project_id);
        }
      }
    }
  }, [user]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  // const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const onSubmit = (data: any) => {
    // console.log(data, selectedDate);
    // setOpenDetails(true);
    data.year = moment(data.month).format("YYYY").toString();
    const stateDates = `${moment(data.month).format("MMM")}, ${data.year}`;
    console.log("stateDates : ", stateDates);
    data.month = +moment(data.month).format("MM");
    let temp: any = data.year + "-" + data.month;
    delete data.year;
    Submit({ ...data, month: temp, state: stateDates });
    // setExpanded(false);
  };
  return (
    <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Accordion
          disableGutters
          elevation={0}
          expanded={expanded === "panel"}
          onChange={handleChange1("panel")}
          style={{
            backgroundColor: "transparent",
            // marginTop: 15,
            // marginBottom: 15,
            width: "100%",
            borderRadius: 5,
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: 0, margin: 0 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#42a5f5",
                justifyContent: "space-between",
                marginTop: -2,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
              onClick={() => handleChange1("panel1")}
            >
              <Grid
                justifyContent={"center"}
                alignSelf={"center"}
                style={{ paddingLeft: "20px" }}
              >
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    // color: "#626974",
                    color: "#fff",

                    textAlign: "center",
                  }}
                >
                  Filter
                </Typography>
              </Grid>
              <Grid
                justifyContent={"flex-end"}
                alignContent={"flex-end"}
                style={{ marginRight: "2%" }}
              >
                <Button
                  variant="text"
                  endIcon={<FilterListIcon />}
                  sx={{ color: "#fff" }}
                  onClick={() => handleChange1("panel1")}
                ></Button>
              </Grid>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={"flex-start"}>
              {!!user &&
                environment.global_veriable.officialEmployee.includes(
                  user.designation
                ) && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Organization
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"organization_id"}
                          // defaultValue={`${
                          //   attendance_dashboard?.organization_id
                          //     ? attendance_dashboard.organization_id
                          //     : ""
                          // }`}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={organization_list}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"organization_id"}
                              label={"organization_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Organization"}
                              value={!!value ? value : ""}
                              errors={!!errors["organization_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {!!user &&
                environment.global_veriable.ho_employees_type_id.includes(
                  user.employee_detail.employee_type_id
                ) &&
                !!watch("organization_id") && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          // defaultValue={`${
                          //   attendance_dashboard?.employee_type_id
                          //     ? attendance_dashboard.employee_type_id
                          //     : ""
                          // }`}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list.filter(
                                (m) =>
                                  m.org_id == +watch("organization_id") &&
                                  ((environment.global_veriable.managerLevelDeg.includes(
                                    user.designation
                                  ) &&
                                    !environment.global_veriable.ho_employees_type_id.includes(
                                      m.employee_type_id
                                    )) ||
                                    !environment.global_veriable.managerLevelDeg.includes(
                                      user.designation
                                    ))
                              )}
                              // option={employee_type_list.filter(
                              //   (t) =>
                              //     t.org_id == +watch("organization_id") &&
                              //     (empType.length <= 0 ||
                              //       !!empType.includes(t.employee_type_id))
                              // )}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), setValue("project_id", null)
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                              errors={!!errors["employee_type_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {!!user &&
                !!watch("employee_type_id") &&
                environment.global_veriable.ho_employees_type_id.includes(
                  user.employee_detail.employee_type_id
                ) &&
                !environment.global_veriable.ho_employees_type_id.includes(
                  +watch("employee_type_id")
                ) && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Project Name
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"project_id"}
                          // defaultValue={`${
                          //   attendance_dashboard?.project_id
                          //     ? attendance_dashboard.project_id
                          //     : ""
                          // }`}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={project_list}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"project_id"}
                              label={"project_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), ProjectSelect(value)
                              )}
                              placeholder={"Search Project"}
                              value={!!value ? value : ""}
                              errors={!!errors["project_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 8, marginBottom: 10 }}
                // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Select Month
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    // style={{ marginLeft: -14 }}
                    className="datepicker"
                  >
                    {/* <DatePicker
                      value={
                        moment(selectedDate).format("MMMM").toUpperCase() +
                        "/" +
                        moment(selectedDate).format("YYYY").toUpperCase()
                      }
                      dateFormat="MM/yyyy"
                      onChange={(date: Date) => setSelectedDate(date)}
                      showMonthYearPicker
                      placeholderText="Please Select Month"
                      selected={selectedDate}
                    /> */}
                    <Controller
                      control={control}
                      name={"month"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <DatePicker
                          views={["year", "month"]}
                          value={value}
                          InputProps={{
                            onBlur: onBlur,
                            size: "small",
                            style: {
                              width: "125%",
                            },
                          }}
                          onChange={
                            (newValue) => onChange(newValue)
                            // PayrollAvailableEmployeeSuccessAction([]),
                            // SetapprovalList([])
                          }
                          renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 6,
                  // marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                View
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Paper>
  );
};

export default ViewAttendanceView;

interface ViewAttendanceViewProps {
  organization_list: OrganizationALlList[];
  project_list: ProjectList[];
  customer_list: CustomerList[];
  employee_type_list: EmployeeTypeList[];
  Submit?: any;
  project_detail?: ViewProject;
  ProjectSelect?: any;
  user?: userDetails;
}
