import { styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import moment from 'moment';
import React from 'react'
import { BudgetDashboardDetails } from '../../../models/customerModel';

const IncomeDetailsTable = ({budget_details}:IncomeDetailsTableProps) => {
 const columns: any[] = [
        "Payment Date",
        "Payment Amount(With GST)",
        "Payment Amount(Without GST)"
    ];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const styles = {
      marginRight: "50px",
    };
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  return (
    <>
     <TableContainer sx={{ borderRadius: 1 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!budget_details?.income_details && budget_details?.income_details.length>0 && 
                  budget_details?.income_details.map((row,index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                       {!!row.payment_date ? moment(row.payment_date).format("DD/MM/YYYY") : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                      &#8377;{!!row.project_payment_with_gst ? row.project_payment_with_gst.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                      &#8377;{!!row.project_payment_without_gst ? row.project_payment_without_gst.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {!!budget_details?.income_details && budget_details?.income_details.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={2}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={!!budget_details?.income_details ? budget_details?.income_details.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </>
  )
}

export default IncomeDetailsTable

interface IncomeDetailsTableProps {
    budget_details?: BudgetDashboardDetails;
  }

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#e6f0ff",
      color: "#010101",
      fontWeight: 600,
      fontSize: 14,
      padding: "3px",
      paddingLeft: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "0px 1px 0px 5px",
      // color: "#363A44",
      color: "#010101",
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F9FAFC",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));