import { Button, Typography, Grid } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const ImageUploader = ({
  documents,
  fileHandleEvent,
  imageChange,
  type,
  showEnd,
}: ImageUploaderPorps) => {
  return (
    <>
      {!documents.find((m: any) => m.document_type == type) ? (
        <Button
          variant="outlined"
          component="label"
          color="primary"
          startIcon={<CloudUploadIcon />}
        >
          <input
            hidden
            accept="image/jpeg, application/pdf"
            type="file"
            onChange={(event: any) => fileHandleEvent(event, type)}
          />
          <Typography
            style={{
              fontWeight: "500",
              fontSize: 16,
              fontFamily: "Poppins, sans-serif",
              color: "#626974",
              // color: "#FFFF",
              paddingLeft: 10,
              // textAlign: "center"
            }}
          >
            Upload file
          </Typography>
        </Button>
      ) : (
        <Grid
          container
          direction={"row"}
          sx={
            showEnd
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }
              : {}
          }
        >
          <Grid>
            <CheckCircleIcon color="success" />
          </Grid>
          <Grid>
            {" "}
            <Typography style={{ color: "#99A09C", fontWeight: "700" }}>
              Successfully Uploaded
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={
              showEnd
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }
                : {}
            }
          >
            <Button onClick={() => imageChange(type)} variant="text">
              Change Image
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ImageUploader;

interface ImageUploaderPorps {
  imageChange?: any;
  fileHandleEvent?: any;
  documents: any[];
  type: string;
  showEnd?: boolean;
}
