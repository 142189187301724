import { Grid, Typography, Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { batch } from "react-redux";
import { ViewPromotion } from "../../../models/promotionModel";

function PromotionBatchHeaderDetails({
  view_promotion,
}: PromotionBatchHeaderDetailsProps) {
  console.log("view_promotion", view_promotion);

  return (
    <Grid
      container
      direction={"row"}
      padding={5}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Promotion Batch No.
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.batch_id}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              New Designation Name
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.designation_name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              New Salary Structure Name
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.salary_stucture_name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Promotion Start Date
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion &&
              view_promotion[0]?.promotion_start_date != null
                ? moment(view_promotion[0]?.promotion_start_date).format(
                    "DD/MM/YYYY"
                  )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Promotion End Date
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.promotion_end_date != null
                ? moment(view_promotion[0]?.promotion_end_date).format(
                    "DD/MM/YYYY"
                  )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Revision Start Date
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion &&
              view_promotion[0]?.revision_start_date != null
                ? moment(view_promotion[0]?.revision_start_date).format(
                    "DD/MM/YYYY"
                  )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Revision End Date
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.revision_end_date != null
                ? moment(view_promotion[0]?.revision_end_date).format(
                    "DD/MM/YYYY"
                  )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Created By</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.created_by}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Created On</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion &&
                moment(view_promotion[0]?.created_on).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Updated By</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion && view_promotion[0]?.updated_by}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Updated On</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!view_promotion &&
                moment(view_promotion[0]?.updated_on).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PromotionBatchHeaderDetails;
interface PromotionBatchHeaderDetailsProps {
  view_promotion?: ViewPromotion[];
}
