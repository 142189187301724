import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, getValue } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  DomainList,
  OrganizationALlList,
  SalaryStructureEditDetails,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SalaryComponenet from "./SalaryComponent";
import OffDays from "./OffDays";

function AddSalarystructureView({
  component_list,
  domain_list,
  organization_list,
  Submit,
  calculation_type,
  operator,
  global_key,
  operator_tye,
  salary_structure,
}: AddSalarystructureViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  let myRef: any = React.createRef();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    setFocus,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  const [component_master, setComponent] = useState<EachComponent[]>([]);
  const [workingType, SetWorkingType] = useState<any[]>([
    {
      key: "Full Month",
      value: 99,
    },
    {
      key: "In Days",
      value: 26,
    },
    // {
    //   key: "Target Based",
    //   value: 88,
    // },
  ]);
  useEffect(() => {
    let master: any[] = [];
    if (!salary_structure && component_list && component_list.length > 0) {
      component_list.forEach((m) => {
        if (m.default == 1) {
          master.push({
            compent_id: m.compent_id,
            attendace_dependancy: m?.attendace_dependancy,
            salary_priod: m.salary_priod,
            default_value: null,
            component_type: m.component_type,
            seq_no:
              master.filter((n) => n.component_type == m.component_type)
                .length + 1,
            component_name: m?.component_name,
            component_type_name: m?.component_type_name,
            salary_priod_name: m?.salary_priod_name,
            calculation: undefined,
            default: m?.default,
          });
        }
      });
      setComponent(master);
    } else if (!!salary_structure && !!salary_structure.component) {
      let t: any = JSON.parse(JSON.stringify(salary_structure.component));
      setComponent(t);
      setValue("org_id", salary_structure.org_id);
      setValue("salary_stucture_name", salary_structure.salary_stucture_name);

      setValue("salary_structure_desc", salary_structure.salary_structure_desc);
      setValue(
        "effective_working_days",
        salary_structure.effective_working_days
      );
      if (
        salary_structure.effective_working_days == 99 ||
        salary_structure.effective_working_days == 88
      ) {
        setValue("working_type", salary_structure.effective_working_days);
      } else {
        setValue("working_type", 26);
      }
    }
    if (!!salary_structure) {
      SetEditFlag(true);
    } else {
      SetEditFlag(false);
    }
  }, [component_list, salary_structure]);

  const onSubmit = (data: any) => {
    if (component_master.length <= 0) {
      enqueueSnackbar("Salary structure should have atleast one component", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      if (
        component_master.find(
          (m) => m.calculation == null && m.default_value == null
        )
      ) {
        enqueueSnackbar("Default value is mandatory if rule is not present.", {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        Submit({
          salary_component: component_master,
          ...data,
          effective_working_days: +data.effective_working_days,
        });
      }
    }
  };
  // React.useEffect(() => {
  //   const firstError = (
  //     Object.keys(errors) as Array<keyof typeof errors>
  //   ).reduce<keyof typeof errors | null>((field, a) => {
  //     const fieldKey = field as keyof typeof errors;
  //     return !!errors[fieldKey] ? fieldKey : a;
  //   }, null);
  //   if (firstError) {
  //     myRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [errors, setFocus]);
  const [off_days, setOff_days] = useState<any>([]);
  const holi_days = [
    { key: "sun_day", value: "Sunday" },
    { key: "mon_day", value: "Monday" },
    { key: "tues_day", value: "Tuesday" },
    { key: "wednes_day", value: "Wednesday" },
    { key: "thurs_day", value: "Thursday" },
    { key: "fri_day", value: "Friday" },
    { key: "satur_day", value: "Saturday" },
  ];
  const OnchangeWorking = (data: number) => {
    if (data == 99) {
      setValue("effective_working_days", 99);
    } else if (data == 88) {
      setValue("effective_working_days", 88);
      holi_days.map((n) => setValue(n.key, null));
    } else {
      setValue("effective_working_days", "");
      holi_days.map((n) => setValue(n.key, null));
    }
  };

  // console.log(off_days);
  return (
    <Paper
      sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
      elevation={3}
    >
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/organization/salary-structure-list/`)}
          // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              // fontWeight: "700",
              // fontSize: 18,
              // fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            {!salary_structure && !edit_flag
              ? "Create Salary Structure"
              : !!salary_structure && edit_flag
                ? "Salary Structure"
                : "Update Salary Structure"}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>

      <Grid container justifyContent={"center"}>
        <Grid item style={{ marginInline: 10, width: "90%" }}>
          {!!salary_structure && (
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Switch
                value={edit_flag}
                onChange={(event) => SetEditFlag(!event.target.checked)}
              />
              <Typography style={{ color: "#A2A2A2" }}>
                Edit Salary Structure
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={6}>
              <Grid container justifyContent="start" alignItems="center">
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "1%",
                    }}
                  >
                    Organization Name
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    control={control}
                    name={"org_id"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={organization_list}
                        keys={"organization_id"}
                        label={"organization_name"}
                        placeholder={"Search Organization"}
                        onBlur={onBlur}
                        readOnly={edit_flag}
                        onChange={(value: any) => onChange(value)}
                        value={!!value ? value : ""}
                        errors={!!errors["org_id"]}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="left"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10, marginLeft: "-10%" }}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "1%",
                      width:"150%"
                    }}
                  >
                    Salary Structure Name
                    <span style={{ color: "red", fontSize: 14 }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6.1} style={{marginLeft: "5%"}}>
                  <Controller
                    control={control}
                    name={"salary_stucture_name"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        // label="Enter Salary Struct. Name"
                        className={"smaller"}
                        style={{ width: "120.9%" }}
                        size="small"
                        placeholder={"Salary Structure Name"}
                        error={!!errors["salary_stucture_name"]}
                        onBlur={onBlur}
                        value={value}
                        inputProps={{
                          readOnly: edit_flag,
                        }}
                        onChange={onChange}
                        helperText={
                          errors.salary_stucture_name &&
                          `${errors.salary_stucture_name.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginInline: 10, width: "90%" }}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid
              item
              xs={2.5}
              justifyContent="space-between"
              alignItems="start"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "1%",
                }}
              >
                Working Type
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={9.3}>
              <Controller
                control={control}
                name={"working_type"}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <Autocompletes
                    hasInfo={false}
                    option={workingType}
                    keys={"value"}
                    label={"key"}
                    placeholder={"Working Type"}
                    onBlur={onBlur}
                    readOnly={edit_flag}
                    onChange={(value: any) => (
                      onChange(value), OnchangeWorking(value)
                    )}
                    value={!!value ? value : ""}
                    errors={!!errors["working_type"]}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {!!watch("working_type") && watch("working_type") <= 26 && (
          <Grid item style={{ marginInline: 10, width: "90%" }}>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid
                item
                xs={2.5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "19%",
                  }}
                >
                  No. of Working Days
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={8.425}>
                <Controller
                  control={control}
                  name={"effective_working_days"}
                  defaultValue={""}
                  rules={{ required: true, max: 30 }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="standard"
                      className={"smaller"}
                      style={{ width: "100.3%", marginLeft: "0.7%" }}
                      size="small"
                      placeholder={"No of Working Days"}
                      error={!!errors["effective_working_days"]}
                      onBlur={onBlur}
                      inputProps={{
                        readOnly: edit_flag,
                      }}
                      value={value}
                      onChange={onChange}
                      helperText={
                        errors.effective_working_days &&
                        `${errors.effective_working_days.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!!watch("working_type") && watch("working_type") == 99 && (
          <>
            <Grid item style={{ marginInline: 10, width: "90%" }}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid
                  item
                  xs={2.5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                    }}
                  >
                    Off Days
                    {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                  </Typography>
                </Grid>
                <Grid item xs={8.47}>
                  {holi_days.map((m) => (
                    <OffDays
                      m={m}
                      off_days={off_days}
                      setOff_days={setOff_days}
                      setValue={setValue}
                      getValues={getValues}
                      watch={watch}
                      edit_flag={edit_flag}
                      salary_structure={salary_structure}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item style={{ marginInline: 10, width: "90%" }}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Grid
              item
              xs={2.5}
              justifyContent="space-between"
              alignItems="start"
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  paddingLeft: "20%",
                }}
              >
                Description
              </Typography>
            </Grid>
            <Grid item xs={9.44} style={{marginLeft:"0.5%"}}>
              <Controller
                control={control}
                name={"salary_structure_desc"}
                defaultValue={""}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextareaAutosize
                  style={{ width: "96%", height: 100, resize: "none", fontSize: "15px", padding: "1%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px" }}
                    className={"smaller"}
                    placeholder={"Description"}
                    minRows={2}
                    value={value}
                    readOnly={edit_flag}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "78%",
            marginLeft: "-6%",
          }}
        ></Divider>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          display={"flex"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{}}
        >
          <Grid item xs={11} style={{ marginTop: 10, marginBottom: 10 }}>
            {!!domain_list &&
              domain_list.map((row, index) => (
                <SalaryComponenet
                  component_master={component_master}
                  setComponent={setComponent}
                  key={index}
                  edit_flag={edit_flag}
                  component_type={row}
                  component={
                    !!component_list
                      ? component_list.filter(
                        (m) => m.component_type == row.domain_value
                      )
                      : []
                  }
                  calculation_type={calculation_type}
                  operator={operator}
                  global_key={global_key}
                  operator_tye={operator_tye}
                />
              ))}
          </Grid>
          {!edit_flag && (
            <Grid
              display={"flex"}
              justifyContent={"center"}
              alignSelf={"center"}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                {!!salary_structure
                  ? "Update Salary Structure"
                  : "Create Salary Structure"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AddSalarystructureView;
interface AddSalarystructureViewProps {
  component_list: SalaryStuctureComponent[];
  domain_list?: DomainList[];
  organization_list?: any;
  Submit?: any;
  calculation_type: DomainList[];
  operator: DomainList[];
  operator_tye: DomainList[];
  global_key: DomainList[];
  salary_structure?: SalaryStructureEditDetails;
}

export interface EachComponent {
  compent_id: number;
  default_value: number;
  component_type: number;
  seq_no: number;
  component_name: string;
  component_type_name: string;
  salary_priod_name: string;
  attendace_dependancy: number;
  calculation: JSON | undefined;
  default: number;
}
