import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { LevelsList, LevelTree } from "../../../../models/customerModel";
import { ILevelMaster } from "../../../../interfaces/projectDashboard.interface";
import AddLevels from "./AddLevels";
import EditLevels from "./EditLevels";

const LevelsDataView = ({
  location_levels,
  level_master,
  level_tree,
  getLevelByMasterAction,
  getLevelDetailsAction,
  createLevelAction,
}: LevelsDataViewProps) => {
  console.log(level_master);
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  //const [rows, setRows] = useState<LevelsList[]>([]);
  let columns: any[] = [
    level_master?.level1_key_name,
    level_master?.level2_key_name,
    level_master?.level3_key_name,
    level_master?.level4_key_name,
    level_master?.level5_key_name,
    "Action",
  ];
  columns = columns.filter((self) => Boolean(self));
  const count: number = columns.filter((m) => m).length;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const styles = {
    marginRight: "50px",
  };
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [editModalOpen, setEditLevelModalOpen] = useState<boolean>(false);
  const [editablePayload, setEditablePayload] = useState<LevelsList | null>(
    null
  );
  console.log("openAddModal ", openAddModal);
  useEffect(() => {
    if (!!level_master) {
      getLevelByMasterAction({ level_key_id: +level_master.level_key_id });
    }
  }, [level_master]);
  const editRequest = (row: LevelsList) => {
    setEditablePayload(row);
    setEditLevelModalOpen(true);
  };
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<LevelsList[]>([]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!level_master
      ? location_levels.filter((row: LevelsList) => {
        return (
          row?.level1_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level2_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level3_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level4_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level5_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!location_levels) {
      cancelSearch();
    }
  }, [location_levels]);
  return (
    <>
      <AddLevels
        open={openAddModal}
        setOpen={setOpenAddModal}
        levelTree={level_tree}
        levelMaster={level_master}
        createLevelAction={createLevelAction}
        getLevelDetailsAction={getLevelDetailsAction}
      />
      <EditLevels
        open={editModalOpen}
        setOpen={setEditLevelModalOpen}
        editablePayload={editablePayload}
        levelMaster={level_master}
        getLevelDetailsAction={getLevelDetailsAction}
        createLevelAction={createLevelAction}
      />
      <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={12} paddingBottom={2}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                textAlign: "center",
                color: "#626974",
              }}
            >
              Level of {level_master?.display_name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => setOpenAddModal(true)}
              size="medium"
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add New
            </Button>
          </Grid>
          <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
          </Grid>
          <Grid item xs={3}>
            <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
          </Grid>
        </Grid>
        <Grid marginTop={2}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {!!row.level1_name && (
                          <StyledTableCell align="left" key={columns[0]}>
                            {row.level1_name}
                          </StyledTableCell>
                        )}
                        {!!row.level2_name && (
                          <StyledTableCell align="left" key={columns[1]}>
                            {row.level2_name}
                          </StyledTableCell>
                        )}
                        {!!row.level3_name && (
                          <StyledTableCell align="left" key={columns[2]}>
                            {row.level3_name}
                          </StyledTableCell>
                        )}
                        {!!row.level4_name && (
                          <StyledTableCell align="left" key={columns[3]}>
                            {row.level4_name}
                          </StyledTableCell>
                        )}
                        {!!row.level5_name && (
                          <StyledTableCell align="left" key={columns[4]}>
                            {row.level5_name}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="left" key={columns[4]}>
                          <IconButton onClick={() => editRequest(row)}>
                            <EditIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                {!!location_levels && location_levels.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={count}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default LevelsDataView;
interface LevelsDataViewProps {
  location_levels: LevelsList[];
  level_master?: ILevelMaster;
  getLevelByMasterAction?: any;
  level_tree?: LevelTree;
  createLevelAction?: any;
  getLevelDetailsAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
