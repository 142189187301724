import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { color } from "d3-color";
import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { Cell, Legend, Pie, PieChart } from "recharts";
import {
  BudgetDashboardDetails,
  EmpMonthlySalaryProjectwise,
  MonthWiseEmpSalaryinBudgeting,
} from "../../../models/customerModel";
import IncomeDetailsTable from "./IncomeDetailsTable";
import MonthlyExpensesPopover from "./MonthlyExpensesPopover";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import EmpDetailsMonthlyPopover from "./EmpDetailsMonthlyPopover";

const ActivityCostCard = ({
  budget_details,
  EmpSalaryMonthProject,
  emp_salary_month_projectwise,
}: ActivityCostCardProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [newData, setNewData] = useState<any[]>([]);
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Total Salary",
    "Working Month",
    "Total Work Hour",
    "Action",
  ];
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  useEffect(() => {
    newTotalSpendingData();
  }, [budget_details?.budgetDetails.totalSpendingDetails]);
  const newTotalSpendingData = () => {
    let temp: any[] = [];
    if (
      !!budget_details?.budgetDetails.totalSpendingDetails &&
      budget_details?.budgetDetails.totalSpendingDetails.length > 0
    ) {
      temp = JSON.parse(
        JSON.stringify(budget_details?.budgetDetails.totalSpendingDetails)
      );
      temp.push({
        component_name: "Total Employee Salary",
        total_amount: !!budget_details.budgetDetails.totalEMPCost
          ? +budget_details.budgetDetails.totalEMPCost.toFixed(2)
          : 0,
      });
    } else if (!!budget_details?.budgetDetails.totalEMPCost) {
      temp.push({
        component_name: "Total Employee Salary",
        total_amount: !!budget_details.budgetDetails.totalEMPCost
          ? +budget_details.budgetDetails.totalEMPCost.toFixed(2)
          : 0,
      });
    }
    setNewData(temp);
    console.log(temp, "gfgd");
  };
  const options: any = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: !!newData ? newData.map((self) => self.component_name) : [],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  // const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];
  // const CustomTooltip = ({
  //   active,
  //   payload,
  //   label,
  // }: {
  //   active: any;
  //   payload: any;
  //   label: any;
  // }) => {
  //   if (active) {
  //     return (
  //       <div
  //         className="custom-tooltip"
  //         style={{
  //           backgroundColor: "#ffff",
  //           padding: "5px",
  //           border: "1px solid #cccc",
  //         }}
  //       >
  //         <label>{`${payload[0].name} : ${payload[0].value}`}</label>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const [selectedExpenses, SetselectedExpenses] = useState<string>("");
  const [empDetailsMonthwise, setEmpDetailsMonthwise] =
    useState<boolean>(false);
  const EmpSalaryMonth = (data: any) => {
    EmpSalaryMonthProject({
      project_id: budget_details?.budgetDetails.project_id,
      employee_id: data,
    });
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <MonthlyExpensesPopover
            open={openStatus}
            setOpen={SetopenStatus}
            budget_details={budget_details}
            selectedExpenses={selectedExpenses}
          />
          <Card variant="outlined" elevation={3} sx={{ minHeight: 300 }}>
            {!!budget_details?.budgetDetails.totalSpendingDetails &&
            budget_details?.budgetDetails.totalSpendingDetails.length > 0 ? (
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: 20,
                    fontFamily: "Poppins, sans-serif",
                    color: "#146C94",
                    textAlign: "center",
                    paddingBottom: 1,
                  }}
                >
                  Expenses Cost
                </Typography>
                <Divider orientation="horizontal" flexItem sx={{ mb: 2 }} />
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  divider={<Divider orientation="vertical" flexItem />}
                  // spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {budget_details?.budgetDetails.totalSpendingDetails[0] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[0].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails
                            .totalSpendingDetails[0].total_amount
                            ? budget_details?.budgetDetails.totalSpendingDetails[0].total_amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[0].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[1] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[1].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails.totalSpendingDetails[1].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[1].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[1].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[2] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[2].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails
                            .totalSpendingDetails[2].total_amount
                            ? budget_details?.budgetDetails.totalSpendingDetails[2].total_amount
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[2].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[3] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[3].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails.totalSpendingDetails[3].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[3].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[3].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  divider={<Divider orientation="vertical" flexItem />}
                  // spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="space-evenly"
                  alignItems="center"
                  paddingTop={2}
                >
                  {budget_details?.budgetDetails.totalSpendingDetails[4] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[4].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;{" "}
                          {!!budget_details?.budgetDetails.totalSpendingDetails[4].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[4].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[4].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[5] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[5].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails.totalSpendingDetails[5].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[5].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[5].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[6] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[6].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails.totalSpendingDetails[6].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[6].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[6].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                  {budget_details?.budgetDetails.totalSpendingDetails[7] && (
                    <Card
                      sx={{
                        minWidth: 40,
                        color: "white",
                        background:
                          "linear-gradient(to right bottom, #cc2b5e , #753a88)",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          color="white"
                        >
                          {
                            budget_details?.budgetDetails
                              .totalSpendingDetails[7].component_name
                          }
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontWeight: "bold" }}
                        >
                          &#8377;
                          {!!budget_details?.budgetDetails.totalSpendingDetails[7].total_amount ? budget_details?.budgetDetails.totalSpendingDetails[7].total_amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ alignItems: "center" }}>
                        <Button
                          size="small"
                          sx={{ color: "#AEC2B6" }}
                          onClick={() => (
                            SetopenStatus(true),
                            SetselectedExpenses(
                              budget_details?.budgetDetails
                                .totalSpendingDetails[7].component_name
                            )
                          )}
                        >
                          month wise Cost
                        </Button>
                      </CardActions>
                    </Card>
                  )}
                </Stack>
              </CardContent>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
                color="text.secondary"
              >
                <Typography textAlign="center" variant="body2">
                  Expenses Details data not exists
                </Typography>
              </Box>
            )}
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card variant="outlined" elevation={3}>
            <CardContent>
              {(!!budget_details?.budgetDetails.totalSpendingDetails &&
                budget_details?.budgetDetails.totalSpendingDetails.length >
                  0) ||
              !!budget_details?.budgetDetails.totalEMPCost ? (
                <Chart
                  options={options}
                  height={300}
                  series={newData.map((self) => self.total_amount)}
                  type="pie"
                  // width={300}
                />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  color="text.secondary"
                >
                  <Typography textAlign="center" variant="body2">
                    Spending Details data not exists
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ color: "#1A5F7A", paddingBottom: 1 }}
          >
            Project Employee Details
          </Typography>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!budget_details?.eachEmp_salary_workHr && budget_details?.eachEmp_salary_workHr.length>0 &&
                  budget_details?.eachEmp_salary_workHr.map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.employee_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.employee_id}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.employee_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        &#8377;
                        {!!row.total_sal ? row.total_sal
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","): "0"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.working_month}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.work_hour}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        <Tooltip title="View Details">
                          <IconButton
                            onClick={() => (
                              setEmpDetailsMonthwise(true),
                              EmpSalaryMonth(row.employee_id)
                            )}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {!!budget_details?.eachEmp_salary_workHr &&
                  budget_details?.eachEmp_salary_workHr.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={4}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={styles}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={
              !!budget_details?.eachEmp_salary_workHr
                ? budget_details?.eachEmp_salary_workHr.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ color: "#1A5F7A", paddingBottom: 1 }}
          >
            Received Payment Details
          </Typography>
          <IncomeDetailsTable budget_details={budget_details} />
        </Grid>
      </Grid>
      <EmpDetailsMonthlyPopover
        open={empDetailsMonthwise}
        setOpen={setEmpDetailsMonthwise}
        emp_salary_month_projectwise={emp_salary_month_projectwise}
      />
    </>
  );
};

export default ActivityCostCard;

interface ActivityCostCardProps {
  budget_details?: BudgetDashboardDetails;
  EmpSalaryMonthProject?: any;
  emp_salary_month_projectwise: EmpMonthlySalaryProjectwise[];
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
