import React, { useEffect, useState } from "react";
import TerminatedEmployeeListView from "./TerminatedEmployeeListView";
import { useForm } from "react-hook-form";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import {
  getEmployeeAllDetailsAction,
  getAllEmployeeAction,
} from "../../../stores/actions/employeeAction";
import {
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
} from "../../../stores/actions/organizationActions";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { ProjectList } from "../../../models/customerModel";
import { EmpList } from "../../../models/employeeModel";
import {
  EmployeeTypeList,
  AllDesignationList,
} from "../../../models/organizationModels";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { TerminatedEmployeeListAction } from "../../../stores/actions/terminationAction";
import { TerminatedEmployeeListing } from "../../../models/terminationModel";

function TerminatedEmployeeList({
  designation_list,
  employee_list,
  employee_type_list,
  project_list,
  searchEmployeeList,
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
  GetAllProjectAction,
  getEmployeeAllDetailsAction,
  searchByNameEmpAction,
  TerminatedEmployeeListAction,
  terminated_emp_list,
  total_size
}: TerminatedEmployeeListProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  useEffect(() => {
    GetAllDesignationAction({ organization_id: null });
    GetAllProjectAction({ organization_id: null, customer_id: null });
    GetAllEmployeeTypeAction({ organization_id: null });
  }, []);
  useEffect(() => {
    TerminatedEmployeeListAction({
      employee_id: "",
      // designation_id: "",
      project_id: "",
      termination_date: "",
      termination_status: 2,
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pagesize, pageindex]);
  const ClearAllFilter = () => {
    TerminatedEmployeeListAction({
        employee_id: "",
        // designation_id: "",
        project_id: "",
        termination_date: "",
        termination_status: 2,
        page_size: pagesize,
        page_index: pageindex,
    });
  };
  const FilterSubmit = (props: any) => {
    TerminatedEmployeeListAction({
      employee_id: !!props.employee_id ? props.employee_id : "",
      // designation_id: !!props.designation_id ? props.designation_id : "",
    //   employee_type_id: !!props.employee_type_id ? props.employee_type_id : "",
      project_id: !!props.project_id ? props.project_id : "",
      termination_date: !!props.termination_date ? props.termination_date : "",
      termination_status: 2,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  return (
    <TerminatedEmployeeListView
      control={control}
      setValue={setValue}
      getValues={getValues}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      getEmployeeAllDetailsAction={getEmployeeAllDetailsAction}
      employee_list={employee_list}
      designation_list={designation_list}
      project_list={project_list}
      employee_type_list={employee_type_list}
      searchByNameEmpAction={searchByNameEmpAction}
      searchEmployeeList={searchEmployeeList}
      ClearAllFilter={ClearAllFilter}
      FilterSubmit={FilterSubmit}
      terminated_emp_list={terminated_emp_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    searchEmployeeList: state.timingSheet.employeeList,
    designation_list: state.organization.designation_list,
    project_list: state.customer.project_list,
    employee_type_list: state.organization.employee_type_list,
    employee_list: state.employee.employee_list,
    terminated_emp_list: state.termination.terminated_emp_list,
    total_size: state.termination.terminated_emp_list?.count,
  };
};

const mapDispatchToProps = {
  searchByNameEmpAction,
  GetAllDesignationAction,
  getEmployeeAllDetailsAction,
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  getAllEmployeeAction,
  TerminatedEmployeeListAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TerminatedEmployeeList);

interface TerminatedEmployeeListProps {
  GetAllProjectAction?: any;
  GetAllDesignationAction?: any;
  GetAllEmployeeTypeAction?: any;
  getEmployeeAllDetailsAction?: any;
  TerminatedEmployeeListAction?: any;
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  employee_list: EmpList[];
  designation_list: AllDesignationList[];
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  terminated_emp_list?: TerminatedEmployeeListing;
  total_size?: number;
}
