import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Drawer,
  Fade,
  Slide,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import {
  BatchByDeg,
  BatchDetailList,
  BatchList,
  CalculatedComponenet,
  UpdatePayrollComponenet,
} from "../../../models/payrollModel";
import BatchTopCard from "./BatchTopCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PriceBreakUpPopover from "./PriceBreakUpPopover";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import Axios from "axios";
import { baseUrl, environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;

const FileDownload = require("js-file-download");
const BatchDetailView = ({
  batch,
  batch_detail,
  GetCalculatedSalaryComponent,
  CalculatedComponenet,
  DiscardEmploye,
  PayrollComponenetUpdateAction,
  ApprovedBatch,
  edit,
  BatchByDeg,
  accessToken,
  user,
}: BatchDetailViewPorps) => {
  const styles = {
    marginRight: "50px",
  };
  const divElement = useRef<HTMLDivElement | null>(null);
  const [rows, setRows] = useState<BatchDetailList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [pop_flag, Setpop_flag] = useState<boolean>(false);
  const [id, Setid] = useState<string | undefined>();
  const [discardItem, SetdiscradItem] = useState<BatchDetailList[]>([]);
  const [submit_flag, setsubmitFlag] = useState<boolean>(false);
  const [submit_flag2, setsubmitFlag2] = useState<boolean>(false);
  const [submit_flaga, setsubmitFlaga] = useState<number>(0);
  const [expectedDisbursDate, SetDisbursDate] = useState<any>(new Date());
  const [employeeId, SetemployeeId] = useState<string[]>([]);
  const [remarks, setRemarks] = useState<any>();
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const [batchDetails, setBatchDetails] = useState<any[]>([]);
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation",
    "Location",
    "Total Income",

    "Total Deduction",
    "Total Contribution",
    "Net Payable",
    "Status",
    "Action",
  ];
  console.log("batch : ", batch);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!batch_detail
      ? batch_detail.filter((row: BatchDetailList) => {
        return (
          row?.employee_id
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.designation_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (!!batch_detail) {
      cancelSearch();
    }
  }, [batch_detail]);
  useEffect(() => {
    if (!!batch && !!batch?.expected_disburesment_date) {
      SetDisbursDate(new Date(batch?.expected_disburesment_date));
    }
  }, [batch]);
  const DiscardEmployeeAction = () => {
    setsubmitFlag(false);
    SetdiscradItem([]);
    DiscardEmploye({
      batchId: batch?.batch_id,
      employeeId: discardItem.map((m) => m.employee_id),
    });
  };
  const SaveComponenet = (data: any) => {
    let payload: any = {
      data: { batch_id: batch?.batch_id, components: data, employee_id: id },
      enqueueSnackbar: enqueueSnackbar,
    };
    PayrollComponenetUpdateAction(payload);
  };
  const UpdateStatus = (n: number) => {
    let payload = {};
    switch (n) {
      case 2: {
        payload = {
          batchId: batch?.batch_id,
          batchStatus: 2,
          expectedDisbursementDate: expectedDisbursDate,
          // moment(expectedDisbursDate).format("DD/MM/YYYY ").toUpperCase()
          orgId: user?.employee_detail.organization_id,
        };
        ApprovedBatch(payload);
        break;
      }
      case 3: {
        if (!!BatchByDeg) {
          payload = {
            batchId: batch?.batch_id,
            batchStatus: 3,
            empLifeEventIds: [BatchByDeg?.empLifeEventId],
            remarks: remarks,
            event_action_items: BatchByDeg.event_action_items,
            expectedDisbursementDate: expectedDisbursDate,
          };
          ApprovedBatch(payload);
        }
        break;
      }
      case 4: {
        if (!!BatchByDeg) {
          payload = {
            batchId: batch?.batch_id,
            batchStatus: 4,
            empLifeEventIds: [BatchByDeg?.empLifeEventId],
            remarks: remarks,
            event_action_items: BatchByDeg.event_action_items,
          };
          ApprovedBatch(payload);
        }
        break;
      }
      case 5: {
        payload = {
          batchId: batch?.batch_id,
          batchStatus: 5,
          employeeId: employeeId,
        };
        ApprovedBatch(payload);
        break;
      }
      case 7: {
        payload = {
          batchId: batch?.batch_id,
          batchStatus: 7,
        };
        ApprovedBatch(payload);
        break;
      }
    }
  };
  const handleClickDownload = (type: number) => {
    // console.log(type, batch?.batch_id);

    Axios({
      url: baseUrl + environment.urls.downloadRTGSNEFT,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        batch_id: batch?.batch_id,
        flag: type,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          if (type == 1) {
            FileDownload(response.data, `${batch?.batch_id}-RTGS.zip`);
          } else {
            FileDownload(response.data, `${batch?.batch_id}-NEFT.zip`);
          }
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error.message ? error.message : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };
  const SelectRow = (ev: boolean, row: BatchDetailList) => {
    if (ev) {
      SetemployeeId([...employeeId, row.employee_id]);
      if ([...employeeId, row.employee_id].length == rows.length) {
        Set_Avpprove(true);
      }
    } else {
      let det: string[] = JSON.parse(JSON.stringify(employeeId));
      det = det.filter((m) => m != row.employee_id);
      SetemployeeId(det);
      Set_Avpprove(false);
    }
  };
  const allSelected = (ev: boolean) => {
    Set_Avpprove(ev);
    if (ev) {
      SetemployeeId(rows.map((e) => e.employee_id));
    } else {
      SetemployeeId([]);
    }
  };
  const empwiseSalarySlip = (data: any, index: number) => {
    Axios({
      url: baseUrl + environment.urls.downloadEmpSalarySlip,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        employee_id: data,
        batch_id: batch?.batch_id,
        incentive: index === 1 ? false : true,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          FileDownload(
            response.data,
            `${data}-${batch?.month + "/" + batch?.year} - salaryslip.pdf`
          );
        }
      })
      .catch(async (error) => {
        enqueueSnackbar(
          !!error?.response?.data &&
            !!JSON.parse(await error.response.data.text())?.message
            ? JSON.parse(await error.response.data.text())?.message
            : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };
  useEffect(() => {
    const tBatchDetals: any[] = [];
    const myFunc = async () => {
      if (!!batch_detail)
        for await (let bDetail of batch_detail) {
          tBatchDetals.push([
            {
              text: bDetail.employee_id,
            },
            {
              text: bDetail.employee_name,
            },
            {
              text: bDetail.designation_name,
            },
            {
              text:
                bDetail.level1_name !== null
                  ? bDetail.level2_name !== null
                    ? bDetail.level3_name !== null
                      ? bDetail.level4_name !== null
                        ? bDetail.level5_name !== null
                          ? bDetail.level1_name +
                          ">" +
                          bDetail.level2_name +
                          ">" +
                          bDetail.level3_name +
                          ">" +
                          bDetail.level4_name +
                          ">" +
                          bDetail.level5_name
                          : bDetail.level1_name +
                          ">" +
                          bDetail.level2_name +
                          ">" +
                          bDetail.level3_name +
                          ">" +
                          bDetail.level4_name
                        : bDetail.level1_name +
                        ">" +
                        bDetail.level2_name +
                        ">" +
                        bDetail.level3_name
                      : bDetail.level1_name + ">" + bDetail.level2_name
                    : bDetail.level1_name
                  : "",
            },
            { text: bDetail.total_income },

            { text: bDetail.total_deduction },
            { text: bDetail.net_payable },
            { text: bDetail.batch_status_name },
          ]);
        }
      setBatchDetails(tBatchDetals);
    };
    myFunc();
  }, [batch_detail]);

  console.log("batch_detail : ", batch_detail);

  const onDownloadPDF = () => {
    // html2canvas(document.getElementById("target")!).then((canvas) => {
    console.log("pdf hit");
    html2canvas(divElement.current!)
      .then((canvas) => {
        const dataURI = canvas.toDataURL();
        const docDefinition = {
          pageSize: "A4",
          pageOrientation: "landscape",

          pageMargins: [2, 2, 2, 2],
          background: {
            image: dataURI,
            width: 845,
          },
          // content: [
          //   {
          //     text: "title",
          //     fontSize: 32,
          //     bold: true,
          //     margin: [50, 50, 60, 60]
          //   }
          // ]
        };
        var dd = {
          pageOrientation: "landscape",
          pageSize: "A3",
          content: [
            {
              text: `Payroll Details of ${batch?.project_name} ${batch?.month
                  ? `For ${moment(
                    `${batch.month}-01-${batch.year} 00:00:00`
                  ).format("MMMM/YYYY")}`
                  : ""
                }`,
              style: "header",
            },
            {
              style: "tableExample",
              widths: ["*", "*"],

              table: {
                widths: ["*", "*"],
                body: [
                  ["Payment Payroll No", batch?.batch_id],
                  [
                    "Month",
                    batch?.month
                      ? moment(
                        `${batch.month}-01-${batch.year} 00:00:00`
                      ).format("MMMM/YYYY")
                      : "",
                  ],
                  ["Project No.", batch?.project_name],
                  ["Total No. Of Employee", batch?.emp_count],
                  [
                    "Target Disbursement Date",
                    batch?.expected_disburesment_date
                      ? moment(batch.expected_disburesment_date).format(
                        "DD-MMM-YYYY"
                      )
                      : "",
                  ],
                  ["Total Deductions", batch?.total_deduction],
                  ["Total Income", batch?.total_income],

                  ["Total Contribution", batch?.total_contribution],
                  ["Net Payable", batch?.net_payable],
                  ["Status", batch?.batch_status_name],
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                      ? "#CFD4D6"
                      : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            {
              text: `List of Employees`,
              style: "subheader",
              align: "center",
            },
            {
              style: "tableExample",

              table: {
                widths: ["*", "*", "*", "*", "*", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "Employee Id", style: "tableHeader" }, //1
                    {
                      text: "Employee Name", //2
                      style: "tableHeader",
                    },
                    {
                      text: "Designation", //3
                      style: "tableHeader",
                    },
                    {
                      text: "Location", //4
                      style: "tableHeader",
                    },
                    {
                      text: "Total Income", //5
                      style: "tableHeader",
                    },

                    {
                      text: "Total Deduction", //7
                      style: "tableHeader",
                    },
                    {
                      text: "Net Payable", //8
                      style: "tableHeader",
                    },
                    {
                      text: "Status", //9
                      style: "tableHeader",
                    },
                  ],
                  ...batchDetails,
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                      ? "#CFD4D6"
                      : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
          ],
          styles: {
            header: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 10,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: 900,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: "black",
              width: 900,
              paddingHorizontal: 5,
            },
          },
          defaultStyle: {
            fontSize: 9,
            color: "black",
            // alignment: 'justify'
          },
        };
        console.log("dd  : ", dd);
        pdfMake.createPdf(dd as any).download("payroll-batch.pdf");
      })
      .catch((err) => console.log("error : ", err));
  };

  const batchwiseSalarySlip = (data: any, index: number) => {
    Axios({
      url: baseUrl + environment.urls.downloadBatchWisePayslipZip,
      method: "POST",
      responseType: "blob", // Important
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        batch_id: data,
        employee_id: employeeId,
        bonus: index === 1 ? false : true,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          FileDownload(
            response.data,
            `${data}-` + (employeeId.length > 1 ? ".zip" : ".pdf")
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          !!error.message ? error.message : "Something went wrong!",
          {
            variant: "error",
          }
        );
      });
  };

  return (
    <Paper
      ref={divElement}
      elevation={3}
      sx={{ marginTop: 5, marginInline: 5, marginBottom: 10 }}
    >
      <Dialog
        open={submit_flag}
        onClose={() => setsubmitFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Remove Employee"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want remove those employee from this batch??
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setsubmitFlag(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => DiscardEmployeeAction()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={submit_flag2}
        onClose={() => setsubmitFlag2(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Update Payroll"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to ${submit_flaga == 2
                ? "submit"
                : submit_flaga == 3
                  ? "approve"
                  : submit_flaga == 4
                    ? "reject"
                    : submit_flaga == 5
                      ? "paid"
                      : "cancel"
              } this batch?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setsubmitFlag2(false)}>
            No
          </Button>
          <Button autoFocus onClick={() => UpdateStatus(submit_flaga)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="label"
        style={{ marginBottom: -70, marginLeft: 20 }}
        onClick={() => navigateTo(-1)}
      //onClick={() => navigate(`/customer/project-main/`)}
      >
        <ArrowBackIcon />
      </IconButton>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item xs={10} marginTop={1} justifyContent={"center"}>
          <Typography
            style={{
              // fontWeight: "700",
              // fontSize: 18,
              // fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            Payroll Details
          </Typography>
        </Grid>
      </Grid>
      <PriceBreakUpPopover
        SaveComponenet={SaveComponenet}
        GetSalaryComponet={(data: string) =>
          GetCalculatedSalaryComponent({
            batch_id: batch?.batch_id,
            employee_id: data,
          })
        }
        handleClose={Setpop_flag}
        open={pop_flag}
        id={id}
        CalculatedComponenet={CalculatedComponenet}
        edit={
          !!user &&
            edit == 3 &&
            !!environment.global_veriable.superAdminLevel.includes(
              user.designation
            )
            ? 2
            : edit
        }
      />
      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      <Grid
        container
        style={{
          height: 40,
          width: 250,
          backgroundColor: "#FD6300",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          clipPath: "polygon(100% 0, 90% 50%, 100% 100%, 0 99%, 0 0)",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px"
        }}
      >
        <Typography style={{ color: "#fff", fontWeight: 700 }}>
          Payroll Details
        </Typography>
      </Grid>
      <BatchTopCard batch={batch} onDownloadPDF={onDownloadPDF} />
      <DicardCard
        Close={() => SetdiscradItem([])}
        discardItem={discardItem}
        DiscardEmployeeAction={() => setsubmitFlag(true)}
      />
      <Grid
        container
        style={{
          height: 40,
          width: 250,
          backgroundColor: "#FD6300",
          justifyContent: "center",
          alignItems: "center",

          clipPath: "polygon(100% 0, 90% 50%, 100% 100%, 0 99%, 0 0)",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px"
        }}
      >
        <Typography style={{ color: "#fff", fontWeight: 700 }}>
          Employee List
        </Typography>
      </Grid>
      <Grid
        container
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 1 }}>
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Grid item xs={12} padding={2}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="justify" key={""}>
                      {((batch?.batch_status == 3 && edit == 3) ||
                        batch?.batch_status == 5) && (
                          <Checkbox
                            style={{ color: "#ffff" }}
                            checked={all_approve}
                            onChange={(event) =>
                              allSelected(event.target.checked)
                            }
                          />
                        )}
                    </StyledTableCell>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.employee_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {((batch?.batch_status == 3 && edit == 3) ||
                              batch?.batch_status == 5) && (
                                <Checkbox
                                  color="primary"
                                  checked={
                                    !!employeeId.find((m) => m == row.employee_id)
                                  }
                                  onChange={(event) =>
                                    SelectRow(event.target.checked, row)
                                  }
                                />
                              )}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            <Link
                              onClick={() =>
                                navigateTo(
                                  `/employment/manage-profile/${row.employee_id}`
                                )
                              }
                            >
                              {row.employee_id}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.employee_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.designation_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            {row.level1_name !== null
                              ? row.level2_name !== null
                                ? row.level3_name !== null
                                  ? row.level4_name !== null
                                    ? row.level5_name !== null
                                      ? row.level1_name +
                                      ">" +
                                      row.level2_name +
                                      ">" +
                                      row.level3_name +
                                      ">" +
                                      row.level4_name +
                                      ">" +
                                      row.level5_name
                                      : row.level1_name +
                                      ">" +
                                      row.level2_name +
                                      ">" +
                                      row.level3_name +
                                      ">" +
                                      row.level4_name
                                    : row.level1_name +
                                    ">" +
                                    row.level2_name +
                                    ">" +
                                    row.level3_name
                                  : row.level1_name + ">" + row.level2_name
                                : row.level1_name
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[5]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.total_income}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[6]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#b32d00",
                              }}
                            >
                              &#8377; {row.total_deduction}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[7]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                              }}
                            >
                              &#8377; {row.total_contribution}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[8]}>
                            <span
                              style={{
                                fontFamily: "Arial",
                                fontWeight: "bold",
                                color: "#008060",
                              }}
                            >
                              &#8377; {row.net_payable}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[9]}>
                            {row.batch_status_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[10]}>
                            <Tooltip title="View Breakup">
                              <IconButton
                                onClick={() => (
                                  Setpop_flag(true), Setid(row.employee_id)
                                )}
                              >
                                <FormatListNumberedIcon />
                              </IconButton>
                            </Tooltip>
                            {!!batch?.batch_status &&
                              batch?.batch_status === 5 && (
                                <>
                                  <Tooltip title="Download Salary Slip">
                                    <IconButton
                                      onClick={() =>
                                        empwiseSalarySlip(row.employee_id, 1)
                                      }
                                    >
                                      <SaveAltIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <Tooltip title="Download Incentive Slip">
                                    <IconButton
                                      onClick={() =>
                                        empwiseSalarySlip(row.employee_id, 2)
                                      }
                                    >
                                      <SaveAltIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                </>
                              )}
                            {(((edit == 1 || edit == 2) &&
                              !!batch?.batch_status &&
                              batch?.batch_status < 4) ||
                              (!!user &&
                                edit == 3 &&
                                !!environment.global_veriable.superAdminLevel.includes(
                                  user.designation
                                ))) && (
                                <>
                                  {!discardItem.find(
                                    (m) => m.employee_id === row.employee_id
                                  ) ? (
                                    <Tooltip title="Remove From Batch">
                                      <IconButton
                                        onClick={() =>
                                          SetdiscradItem([...discardItem, row])
                                        }
                                      >
                                        <DoDisturbOnIcon
                                          style={{ color: "#cc2900" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      <Tooltip title="Add in Batch">
                                        <IconButton
                                          onClick={() =>
                                            SetdiscradItem(
                                              discardItem.filter(
                                                (m) =>
                                                  m.employee_id != row.employee_id
                                              )
                                            )
                                          }
                                        >
                                          <AddCircleIcon
                                            style={{ color: "#005028" }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </>
                              )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      {edit > 0 && (
        <Grid
          container
          style={{
            height: 40,
            width: 250,
            backgroundColor: "#FD6300",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,

            clipPath: "polygon(100% 0, 90% 50%, 100% 100%, 0 99%, 0 0)",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px"
          }}
        >
          <Typography style={{ color: "#fff", fontWeight: 700 }}>
            Action Area
          </Typography>
        </Grid>
      )}

      {!!batch?.batch_status && batch?.batch_status < 3 && (edit == 1 || 2) && (
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
        >
          <Grid item xs={4}>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#626974",
                paddingLeft: "20%",
                // textAlign: "center"
              }}
            >
              Expected Disbursment Date
              <span style={{ color: "red", fontSize: 14 }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={expectedDisbursDate}
              InputProps={{
                size: "small",
                style: {
                  width: "100%",
                },
              }}
              inputFormat="DD-MM-YYYY"
              onChange={(newValue) => SetDisbursDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "100.9%" }}
                  helperText={null}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {batch?.batch_status == 2 && edit == 2 && (
        <Grid
          container
          justifyContent="start"
          alignItems="center"
          style={{ marginTop: 15, marginBottom: 30 }}
        // width={"100%"}
        >
          <Grid item xs={4}>
            <Typography
              style={{
                fontWeight: "500",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                color: "#626974",
                paddingLeft: "20%",
                // textAlign: "center"
              }}
            >
              Remarks
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-required"
              variant="standard"
              className={"smaller"}
              style={{ width: "100.9%" }}
              size="small"
              onChange={(event) => setRemarks(event.target.value)}
              value={remarks}
              label={"Enter Remarks Here"}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" alignItems={"center"} padding={3}>
        {batch?.batch_status == 2 && edit == 2 && (
          <>
            <Button
              sx={{
                // color: "#fff",
                // backgroundColor: "#1565C0",
                paddingX: 4,
              }}
              onClick={() => (setsubmitFlag2(true), setsubmitFlaga(4))}
              variant="contained"
              color="error"
              size="large"
              disabled={!remarks || remarks == ""}
            >
              Reject
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => (setsubmitFlag2(true), setsubmitFlaga(3))}
              variant="contained"
              color="primary"
              size="large"
            //disabled={!remarks || remarks == ""}
            >
              Approve
            </Button>
          </>
        )}
        {batch?.batch_status == 3 && edit == 3 && (
          <>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
              }}
              disabled={employeeId.length <= 0}
              onClick={() => (setsubmitFlag2(true), setsubmitFlaga(5))}
              variant="contained"
              color="primary"
              size="large"
            >
              Paid
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
                textDecoration: "none",
              }}
              onClick={() => {
                handleClickDownload(1);
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              {/* <a
                href="/RTGS.pdf"
                download
                style={{ textDecoration: "none", color: "#ffff" }}
              >
                Generate RTGS
              </a> */}
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#ffff",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Transfer
              </Typography>
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => {
                handleClickDownload(0);
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              {/* <a
                href="/RTGS.pdf"
                download
                style={{ textDecoration: "none", color: "#ffff" }}
              >
                Generate NEFT
              </a> */}
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#ffff",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Generate NEFT
              </Typography>
            </Button>
          </>
        )}
        {batch?.batch_status == 1 && edit == 1 && (
          <>
            <Button
              sx={{
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => (setsubmitFlag2(true), setsubmitFlaga(7))}
              variant="contained"
              color="error"
              size="large"
            >
              Cancel
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => (setsubmitFlag2(true), setsubmitFlaga(2))}
              variant="contained"
              disabled={!expectedDisbursDate}
              color="primary"
              size="large"
            >
              Submit
            </Button>
          </>
        )}
        {batch?.batch_status == 5 && employeeId.length > 0 && (
          <>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => batchwiseSalarySlip(batch.batch_id, 1)}
              variant="contained"
              disabled={!expectedDisbursDate}
              color="primary"
              size="large"
            >
              Download Salary Slip Zip
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginLeft: 5,
              }}
              onClick={() => batchwiseSalarySlip(batch.batch_id, 2)}
              variant="contained"
              disabled={!expectedDisbursDate}
              color="primary"
              size="large"
            >
              Download Incentive Slip Zip
            </Button>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default BatchDetailView;
interface BatchDetailViewPorps {
  batch?: BatchList;
  batch_detail: BatchDetailList[];
  GetCalculatedSalaryComponent?: any;
  CalculatedComponenet: CalculatedComponenet[];
  DiscardEmploye?: any;
  PayrollComponenetUpdateAction?: any;
  ApprovedBatch?: any;
  edit: number;
  BatchByDeg?: BatchByDeg;
  accessToken?: string;
  user?: userDetails;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DicardCard = ({
  discardItem,
  DiscardEmployeeAction,
  Close,
}: DicardCardProps) => {
  return (
    <Slide direction="left" in={!!discardItem && discardItem.length > 0}>
      <Paper
        elevation={3}
        style={{
          height: "45%",
          width: !!discardItem && discardItem.length > 0 ? 500 : 0,
          position: "absolute",
          top: 100,
          right: 15,
          overflow: "auto",
          zIndex: 999,
        }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item xs={10} marginTop={1} justifyContent={"center"}>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: 18,
                fontFamily: "Poppins, sans-serif",
                // textAlign: "center",
              }}
            >
              Remove Employee from Batch
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "90%",
            marginLeft: "5%",
          }}
        ></Divider>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-evenly"}
          style={{ backgroundColor: "#FD6300" }}
        >
          <Grid item>
            <Typography style={{ padding: 5, paddingLeft: 30, color: "#fff" }}>
              Employee ID
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ padding: 5, paddingLeft: 30, color: "#fff" }}>
              Net Payable
            </Typography>
          </Grid>
          <Divider
            style={{
              borderColor: "#9E9E9E",
              opacity: 0.7,
              width: "90%",
            }}
          ></Divider>
        </Grid>
        {discardItem.map((item, index) => (
          <>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-evenly"}
              style={{ backgroundColor: "#FBFBFB" }}
            >
              <Grid item>
                <Typography style={{ padding: 5, paddingLeft: 30 }}>
                  {item.employee_id}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ padding: 5, paddingLeft: 30 }}>
                  {item.net_payable}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              style={{
                borderColor: "#9E9E9E",
                opacity: 0.7,
                width: "90%",
                marginLeft: "5%",
              }}
            ></Divider>
          </>
        ))}
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
          style={{ position: "absolute", bottom: 10 }}
        >
          <Grid item>
            <Button variant="contained" color={"error"} onClick={() => Close()}>
              Close
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              color={"error"}
              onClick={() => DiscardEmployeeAction()}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
};

interface DicardCardProps {
  discardItem: BatchDetailList[];
  DiscardEmployeeAction?: any;
  Close?: any;
}
