import { Tabs, Tab, Box } from "@mui/material";
import React, { FC } from "react";
type DateTypeSwitch = "START-END" | "YEAR-MONTH";
interface DateTypeSwitchTabProps {
  value: DateTypeSwitch;
  handleChange: (value: DateTypeSwitch) => void;
}

const DateTypeSwitchTab: FC<DateTypeSwitchTabProps> = ({
  value,
  handleChange,
}) => {
  return (
    <Box
      sx={{
        pb: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 0,
      }}
    >
      <Tabs
        value={value}
        onChange={(e: any) =>
          handleChange(value === "START-END" ? "YEAR-MONTH" : "START-END")
        }
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "600px",
        }}
      >
        <Tab
          value={"START-END"}
          label={`Date Range Wise`}
          sx={{
            width: "40%",
            color: "#42A5F5",
          }}
        />
        <Tab
          value={"YEAR-MONTH"}
          label={`Year Wise`}
          sx={{
            width: "40%",
            color: "#42A5F5",
          }}
        />
      </Tabs>
    </Box>
  );
};

export default DateTypeSwitchTab;
