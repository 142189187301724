import { ILeaveMain } from "../../models/leaveModel";
import { ELeaveActionTypes } from "../actions/LeaveAction";
import InitialState from "./initialState";
const initialState: ILeaveMain = InitialState.leave;
export default function LeaveReducer(
  state: ILeaveMain = initialState,
  action: any
) {
  switch (action.type) {
    case ELeaveActionTypes.Get_All_Leave_Type_Action:
      return {
        ...state,
        leave_types: action.payload,
      };
    case ELeaveActionTypes.Get_Leave_Type_By_Id_Action:
      return {
        ...state,
        leave_type: action.payload,
      };
    case ELeaveActionTypes.Get_Holiday_List_Action:
      return { ...state, holiday_list: action.payload };
    case ELeaveActionTypes.Get_Holiday_By_Id_Action:
      return { ...state, holiday: action.payload };
    case ELeaveActionTypes.Get_Designation_wise_Leave_count:
      return { ...state, leave_designation: action.payload };
    case ELeaveActionTypes.Get_Designation_wise_leave_details:
      return { ...state, leave_type_designation_wise: action.payload };
    case ELeaveActionTypes.Select_organization_For_Leave:
      return { ...state, selected_organization: Number(action.payload) };
    case ELeaveActionTypes.Get_Leave_management_details:
      return { ...state, allLeaveManagement: action.payload };
    case ELeaveActionTypes.Get_Leave_Details_By_Designation:
      return { ...state, designationWiseLeave: action.payload };
    case ELeaveActionTypes.Clear_Leave_Details_By_Designation:
      return { ...state, designationWiseLeave: [] };
    case ELeaveActionTypes.Get_Employee_Details_By_Designation:
      return { ...state, designationWiseEmp: action.payload };
    case ELeaveActionTypes.Get_Leave_Amount_Action:
      return { ...state, leave_amount_data: action.payload };
    case ELeaveActionTypes.Get_Calculated_Leave_Days:
      const leave_days = action.payload;
      console.log("leave red", leave_days);
      return { ...state, calculated_leave_days: action.payload.total_days };
    case ELeaveActionTypes.Get_Leave_Details_Action:
      return { ...state, leave_details_data: action.payload };
    case ELeaveActionTypes.Get_All_Leave_Approval_List_Action:
      return { ...state, leave_approval_list: action.payload };
    case ELeaveActionTypes.Leave_Calendar_Details_Action:
      return { ...state, leave_calendar: action.payload };
    case ELeaveActionTypes.Leave_calulation_state_reset_Action:
      return { ...state, calculated_leave_days: 0 };
    case ELeaveActionTypes.Get_All_Leave_List_Action:
      return {
        ...state,
        all_leave_list: action.payload.all_leave_list,
        total_count: action.payload.total_count,
      };
    case ELeaveActionTypes.Get_Leave_Details_For_Dashboard_Action:
      return {
        ...state,
        leaveDetails: action.payload,
      };
    case ELeaveActionTypes.Get_State_By_Address_Id_Action:
      return {
        ...state,
        address: action.payload,
      };
    case ELeaveActionTypes.Get_Holiday_By_Employee_Id_Action:
      return {
        ...state,
        employee_holiday_list: action.payload,
      };
    case ELeaveActionTypes.Get_Natural_Holiday_By_Employee_Action:
      return {
        ...state,
        natural_holidays: action.payload,
      };
    default:
      return state;
  }
}
