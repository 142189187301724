import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import moment from "moment";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ListPlugin from "@fullcalendar/list";
import { useSnackbar } from "notistack";
import {
  ILeaveCalendar,
  IHolidayList,
  ILeaveDetailsData,
  IAddress,
  INaturalHoliday,
} from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CalendarModal from "./CalendarModal";
import colorGenerator from "../../../utility/colorGenerator";
import { isNaturalHoliday } from "../../../utility/naturalHoliday";
const EmployeeLeaveCalendar: FC<IEmployeeLeaveCalendar> = ({
  holidayList,
  getAllHolidaysAction,
  leave_calendar,
  LeaveCalendarDateAction,
  user,
  setAddLeaveModal,
  setStartDate,
  leaveDetailsData,
  getHolidayByEmployeeAction,
  employee_holiday_list,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}): JSX.Element => {
  console.log("holiday list:", holidayList);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [eventsData, setEventsData] = useState<IUpdatedHoliayList[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [currentEvents, setCurrentEvents] = useState<any[]>([]);
  
  const [employeeLeaveDays, setEmployeeLeaveDays] = useState<any[]>([]);
  console.log("employeeLeaveDays: ", employeeLeaveDays);
  const [calendarLoading, setCalendarLoading] = useState<boolean>(true);
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [selectedHoliday, setSelectedHoliday] = useState<IHolidayList | null>(
    null
  );
  const [ValidHoliday, setValidHoliday] = useState<IHolidayList[]>([]);
  const [selectedLeaveDetails, setSelectedLeaveDetails] =
    useState<ILeaveCalendar | null>(null);

  useEffect(() => {
    // if (showCalendar) {
    //   getAllHolidaysAction();
    // }
    if (user) {
      LeaveCalendarDateAction(user?.employeeId);
    }
    if (!!user && showCalendar) {
      console.log("service hit");
      getHolidayByEmployeeAction({
        employee_id: user.employee_detail.employee_id,
        org_id: user.employee_detail.organization_id,
      });
    }
  }, [showCalendar, user, leaveDetailsData]);
  console.log("caldnear : ", leave_calendar);
  useEffect(() => {
    if (!!user) {
      naturalLeaveDaysActionByEmployee(user?.employee_detail.employee_id);
    }
  }, [user]);
  useEffect(() => {
    setCalendarLoading(true);
    if (!!employee_holiday_list && employee_holiday_list.length > 0) {
      const updatedList = employee_holiday_list.map((self) => ({
        date: self.date,
        id: `${self.holiday_id}`,
        title: self.holiday_name,
        color: "red",
      }));
      setCurrentEvents(updatedList);
      setEventsData(updatedList);
      console.log("updatedList: ", updatedList);
    }

    if (!!leave_calendar && leave_calendar.length > 0) {
      const updated_list = leave_calendar.map((self) => ({
        date: moment(self.date).format("YYYY-MM-DD"),
        id: `${self.id}`,
        title: self.leave_type_name,
        color: colorGenerator(self.leave_type_name, 1, 200),
      }));
      console.log("updated_list: ", updated_list);
      setEmployeeLeaveDays(updated_list);
    }
    setTimeout(() => setCalendarLoading(false), 2000);
  }, [employee_holiday_list, leave_calendar]);
  const handleCalendarOpen = (): void => {
    setShowCalendar((prev) => !prev);
  };
  const handleDateClick = (selected: any): void => {
    console.log(selected);
    const currDate = selected.startStr;
    const currDateTime = new Date(currDate).getTime();

    const isleavedDate = employeeLeaveDays.find(
      (self) => new Date(self.date).getTime() === currDateTime
    );
    const isHoliday = eventsData.find(
      (self) => new Date(self.date).getTime() === currDateTime
    );
    console.log({ isleavedDate, isHoliday });
    console.log(
      "time",
      leave_calendar.map((self) => new Date(self.date).getTime())
    );
    if (isleavedDate) {
      setModalType("Leave");
      setCalendarModalOpen(true);

      const tLeaveDetails = leave_calendar.find(
        (self) => new Date(self.date).getTime() === currDateTime
      );
      console.log("tleaved", tLeaveDetails);
      console.log("leave_calendar : ", leave_calendar);
      console.log("currDateTime : ", currDateTime);
      console.log("employeeLeaveDays : ", employeeLeaveDays);
      !!tLeaveDetails && setSelectedLeaveDetails(tLeaveDetails);
    } else if (isHoliday) {
      setModalType("Holiday");
      setCalendarModalOpen(true);
      const tHoliday = employee_holiday_list.find(
        (self) => new Date(self.date).getTime() === currDateTime
      );
      !!tHoliday && setSelectedHoliday(tHoliday);
    } else {
      let employeeId = user?.employee_detail.employee_id as string;

      const isStartDateHoliday = isNaturalHoliday(
        employeeId,
        currDate,
        natural_holiday_list
      );

      console.log("isStartDateHoliday :", isStartDateHoliday);
      console.log("natural_holiday_list : ", natural_holiday_list);
      if (isStartDateHoliday || natural_holiday_list.length === 0) {
        enqueueSnackbar("No leave can be taken from Holiday", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        return;
      }

      const today = new Date().getTime();
      const diff = Math.abs(today - currDateTime);

      let Difference_In_Days = diff / (1000 * 3600 * 24);
      Difference_In_Days++;
      if (Difference_In_Days >= 180) {
        enqueueSnackbar(
          "Taking leave before or after 6 months is not allowed",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        setAddLeaveModal(true);
        setStartDate(String(currDate));
      }
    }
  };
  console.log("selectedLeaveDetails : ", selectedLeaveDetails);
  console.log("employeeLeaveDays : ", employeeLeaveDays);
  // useEffect(()=>{
  //   const tHoliday = holidayList.filter(self=>self.is_national_holiday?self.is_national_holiday:self.applicable_states.includes(user?.employee_detail.))
  // },[holidayList])
  return (
    <Box
      className={`${
        showCalendar
          ? `leaveManagementCalendar show`
          : `leaveManagementCalendar`
      }`}
      sx={{
        height: "86.3vh",
        zIndex: 555,
        background: "white",
      }}
      style={{borderTopRightRadius: "5px", borderBottomRightRadius: "5px", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}}
    >
      <CalendarModal
        setOpen={setCalendarModalOpen}
        open={calendarModalOpen}
        modalType={modalType}
        holiday={selectedHoliday}
        leave_calendar={selectedLeaveDetails}
      />
      <Box sx={{ width: "100%", height: "100%", position: "relative", backgroundColor: "transparent" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: "30%",
            right: "-5.5%",
            color: "rgb(19, 36, 55)",
          }}
          onClick={handleCalendarOpen}
        >
          {showCalendar ? (
            <ArrowCircleLeftIcon sx={{ fontSize: "3rem" }} />
          ) : (
            <ArrowCircleRightIcon sx={{ fontSize: "3rem" }} />
          )}
        </IconButton>
        {!calendarLoading ? (
          <Box display="flex" justifyContent="space-between">
            {/* calendar sidebar */}
            <Box flex="1 1 25%" p="10px" borderRadius="4px">
              <Typography
                mt={4}
                sx={{ color: "#212121" }}
                borderBottom="1px solid gray"
                variant="body1"
              >
                Holidays
              </Typography>
              <List
                id="holidayList"
                sx={{
                  maxHeight: "73vh",
                  overflow: "auto",
                  pr: 0.3,
                }}
              >
                {employee_holiday_list.map((self, index: number) => (
                  <ListItem
                    key={index}
                    sx={{
                      background: "#132437",
                      fontSize: 12,
                      color: "#FFFFFF",
                      margin: "10px 0",
                      borderRadius: "5px",
                      position: "relative",
                    }}
                  >
                    <ListItemText
                      primary={self.holiday_name}
                      secondary={
                        <Typography fontSize={12}>
                          {moment(self.date).format("DD-MMM-YYYY")}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box flex="1 1 85%" p="15px" borderRadius="4px">
              <FullCalendar
                height="70vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  ListPlugin,
                ]}
                headerToolbar={{
                  left: "prev, next today",
                  center: "title",

                  right: "dayGridMonth,listMonth",
                }}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                datesSet={(event: any) => console.log("month change", event)}
                select={handleDateClick}
                eventClick={() => {}}
                eventsSet={(events) => setCurrentEvents(events)}
                initialEvents={[...eventsData, ...employeeLeaveDays]}
              />
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            width="100%"
          >
            <Typography color="gray" textAlign="center">
              Calendar is Loading .......
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface IEmployeeLeaveCalendar {
  holidayList: IHolidayList[];
  getAllHolidaysAction?: any;
  leave_calendar: ILeaveCalendar[];
  LeaveCalendarDateAction?: any;
  user?: userDetails;
  setAddLeaveModal: any;
  setStartDate: any;
  leaveDetailsData: ILeaveDetailsData[];
  getStateByAddressIdAction?: any;
  address?: IAddress;
  getHolidayByEmployeeAction?: any;
  employee_holiday_list: IHolidayList[];
  natural_holiday_list: INaturalHoliday[];
  naturalLeaveDaysActionByEmployee?: any;
}
interface IUpdatedHoliayList {
  id: string;
  date: string;
  title: string;
  color?: string;
}
export default EmployeeLeaveCalendar;
