import {
  Paper,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  AccordionProps,
  Chip,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import colorGenerator from "../../../utility/colorGenerator";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  FieldValues,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  EmpProject,
  IHolidayInfo,
  ITimeSheetLeaveData,
} from "../../../models/timingSheetModel";
import EmpProjectDate from "./EmpProjectDate";
import InfoIcon from "@mui/icons-material/Info";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import { useLocation } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ScrollContainer from "react-indiana-drag-scroll";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

function EmployeeProjectActivity({
  emp_project,
  control,
  errors,
  setValue,
  handleSubmit,
  setError,
  notEditable,
  initiateTimesheetAction,
  handleApprovalRequest,
  setShowApprobvalBtn,
  showApprovalBtn,
  status,
  setStatus,
  isworklist,
  reset,
  setAllotedProjectModalOpen,
  notShow,
  selectedProjectsIds,
  setSelectedProjectsIds,
}: EmployeeProjectActivityProps) {
  const matchesMobileView = useMediaQuery("(max-width:600px)");
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   formState,
  //   setValue,
  //   getValues,
  //   setError,
  //   clearErrors,
  //   reset,
  //   setFocus,
  //   formState: { errors },
  // } = useForm<any>();

  // const [showApprovalBtn, setShowApprobvalBtn] = useState<boolean>(true);

  const [projectMonth, setProjectMonth] = useState<string>("");
  useEffect(() => {
    if (
      Array.isArray(emp_project) &&
      emp_project.length > 0 &&
      emp_project[0].time_tab.length > 0
    ) {
      setProjectMonth(emp_project[0].time_tab[0].date.toString());
    }
  }, [emp_project]);
  console.log(projectMonth);
  type FormValues = {
    time_tab: { work_hours: string }[];
  };
  const { fields, append } = useFieldArray({
    control,
    name: "time_tab",
  });
  console.log("errors : ", errors);
  useEffect(() => {
    const arr: any[] = [];
    // reset();
    let tStatus: string = "";
    !!emp_project &&
      emp_project.forEach((self, pIndex) => {
        self.time_tab.forEach((_self, index) => {
          tStatus = _self.status;
          setValue(`time_tab.[${index}].work_hours`, "");
          setValue(`time_tab.[${index}].date`, "");
          setValue(`time_tab.[${index}].project_id`, "");
          setValue(`time_tab.[${index}].time_sheet_id`, "");
          console.log("time_tab hit");
          arr.push({
            work_hours: _self.work_hours,
            project_id: self.project_id,
            date: _self.date,
            time_sheet_id: _self.time_sheet_id,
            status: _self.status,
          });
          // const adefd = {
          //   `time_tab.[${count}].work_hours`: count,
          //   `time_tab.[${count}].project_id`:_self.project_id,
          //   `time_tab.[${count}].date`: _self.date,
          //   `time_tab.[${count}].time_sheet_id`:
          //   _self.time_sheet_id
          // }
        });
      });
    console.log("arr : ", arr);
    setStatus(tStatus);
    arr.forEach((self, index) => {
      setValue(
        `time_tab.[${index}].work_hours`,
        self.work_hours
          ? self.work_hours
          : tStatus.toLowerCase() === "initial"
          ? self.work_hours
          : 0
      );
      setValue(`time_tab.[${index}].date`, self.date);
      setValue(`time_tab.[${index}].project_id`, self.project_id);
      setValue(`time_tab.[${index}].time_sheet_id`, self.time_sheet_id);
      //           `time_tab.[${count}].project_id`:_self.project_id,
      //           `time_tab.[${count}].date`: _self.date,
      //           `time_tab.[${count}].time_sheet_id`:
      //           _self.time_sheet_id
    });
  }, [emp_project, projectMonth]);
  console.log("emp_project : ", emp_project);
  // let count:number = 0;
  // useEffect(() => {
  //   const arr:any[]=[];

  //   !!emp_project &&
  //     emp_project.forEach((self, pIndex) => {
  //       self.time_tab.forEach((_self, index) => {
  //         count++;
  //         const adefd = {
  //           `time_tab.[${count}].work_hours`: count,
  //           `time_tab.[${count}].project_id`:_self.project_id,
  //           `time_tab.[${count}].date`: _self.date,
  //           `time_tab.[${count}].time_sheet_id`:
  //           _self.time_sheet_id
  //         }
  //       });
  //     });
  // }, [emp_project]);

  const handleApproval = () => {
    console.log("approval hit");
    handleApprovalRequest();
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openInfoHoliday, setOpenInfoHoliday] = useState<boolean>(false);
  const [anchorElForLeave, setAnchorElForLeave] =
    useState<HTMLButtonElement | null>(null);
  const [openInfoLeave, setOpenInfoLeave] = useState<boolean>(false);
  const [holidayInfo, setHolidayInfo] = useState<IHolidayInfo | null>(null);
  const [leaveInfo, setLeaveInfo] = useState<ITimeSheetLeaveData | null>(null);

  const handlePopOverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("holiday click");
    setAnchorEl(event.currentTarget);
    setOpenInfoHoliday((prev) => !prev);
    setAnchorElForLeave(null);
    setOpenInfoLeave(false);
  };
  const handlePopOverClickForLeave = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    console.log(" click leave");
    setAnchorEl(null);
    setOpenInfoHoliday(false);

    setAnchorElForLeave(event.currentTarget);
    setOpenInfoLeave((prev) => !prev);
  };
  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) return;
    var updatedList = [emp_project.find((m) => m.project_id)?.project_id];
    console.log("updatedList", updatedList);

    // const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // updatedList.forEach(
    //   (m, index) => ((m.seq_no = index + 1), (m.calculation = undefined))
    // );
    // setComponent([
    //   ...updatedList,
    //   ...component_master.filter(
    //     (m) => m.component_type != component_type?.domain_value
    //   ),
    // ]);
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = (projectId: number) => {
    console.info("You clicked the delete icon.");
    setSelectedProjectsIds((prev: number[]) => [...prev, projectId]);
  };
  return (
    <Paper
      elevation={3}
      sx={{ marginTop: 3, marginInline: matchesMobileView ? 0.5 : 3 }}
    >
      <Popper
        open={openInfoHoliday}
        anchorEl={anchorEl}
        placement={"right-start"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ p: 2, color: `#F71A31`, fontWeight: 700 }}>
                {holidayInfo?.holiday_name}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Popper
        open={openInfoLeave}
        anchorEl={anchorElForLeave}
        placement={"right-start"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ p: 2, color: "#F71A31", fontWeight: 700 }}>
                {leaveInfo?.leave_type_name}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginTop: 2,
            marginLeft: 1,
            width: "75%",
          }}
        >
          <Grid container gap={2}>
            {emp_project
              .filter((self) => !selectedProjectsIds.includes(self.project_id))
              .map((_self, index) => (
                <Grid item xs={matchesMobileView ? 12 : 2}>
                  <Chip
                    label={`${_self.project_name} is removed`}
                    onClick={handleClick}
                    onDelete={() => handleDelete(_self.project_id)}
                    color="error"
                    // sx={{
                    //   cursor: "pointer",
                    //   backgroundColor: "#1565C0",

                    //   color: "#fff",
                    //   "&:hover": {
                    //     color: "#1565C0",
                    //     fontWeight: 900,
                    //   },
                    // }}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box sx={{ marginTop: 2, marginRight: 1, width: "20%" }}>
          <Chip
            label={
              matchesMobileView ? (
                <FilterListIcon />
              ) : (
                "Filter Your Alloted Projects"
              )
            }
            variant="outlined"
            sx={{
              cursor: "pointer",
              backgroundColor: "#1565C0",

              color: "#fff",
              "&:hover": {
                color: "#132437",
                fontWeight: 900,
              },
            }}
            onClick={() => setAllotedProjectModalOpen(true)}
          />
        </Box>
      </Box>

      <Grid
        container
        style={{ backgroundImage: "linear-gradient(white,#c4f8ff, white)" }}
      >
        <Grid item xs={12}>
          <ScrollContainer hideScrollbars={false}>
            <Box
              sx={{
                paddingLeft: "1.8%",
                my: 1,
                height: 40,
                display: "flex",
                alignItems: "center",
                marginLeft: "135px",
              }}
            >
              {Array.isArray(emp_project) &&
                emp_project
                  .filter((_self) =>
                    selectedProjectsIds.includes(_self.project_id)
                  )
                  .map((self, index) => (
                    <Box
                      sx={{
                        // mt: 3,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "220px",
                        margin: "0 6px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "600",
                          width: "100%",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          textAlign: "center",
                        }}
                      >
                        {self.project_name}
                      </Typography>
                    </Box>
                  ))}
            </Box>

            <Box
              display="flex"
              sx={{
                overflow: "auto",
                // width: `${
                //   !!emp_project ? `${emp_project.length * 10}rem` : `100%`
                // }`,
                maxHeight: "600px",
                cursor: "grab",
              }}
            >
              <Box sx={{ width: "100px" }}>
                {/* <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      textAlign: "center",
                      pl: 2,
                      width: "100%",
                    }}
                  >
                    Date
                  </Typography>
                </Box> */}
                <Box

                // height={"50vh"}
                // sx={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                  {!!emp_project &&
                    Array.isArray(emp_project) &&
                    emp_project[0]?.time_tab.map((self, index) => (
                      <Box
                        width="150px"
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        justifyContent="center"
                        paddingLeft={1}
                        mb={2.85}
                        mt={0.8}
                        mr={1}
                        sx={{
                          color: "white",

                          background:
                            !!new Date(self?.date) &&
                            new Date(self.date).getFullYear() <=
                              new Date().getFullYear() &&
                            new Date(self.date).getMonth() <=
                              new Date().getMonth() &&
                            new Date(self.date).getDate() < new Date().getDate()
                              ? "transparent"
                              : "transparent",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color:
                              self.isHoliday || self.isLeave
                                ? `#F71A31`
                                : "#626974",
                          }}
                        >
                          {!!self?.date
                            ? moment(self?.date)
                                .format("DD-MMM-YY ")
                                .toUpperCase() +
                              moment(self?.date).format("ddd")
                            : "N/A"}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
              {!!emp_project &&
                emp_project
                  .filter((_self) =>
                    selectedProjectsIds.includes(_self.project_id)
                  )
                  .map((self, pIndex: number, pArr) => {
                    return (
                      <ProjectTiming
                        pIndex={pIndex}
                        self={self}
                        control={control}
                        setHolidayInfo={setHolidayInfo}
                        leaveInfo={leaveInfo}
                        setLeaveInfo={setLeaveInfo}
                        handlePopOverClick={handlePopOverClick}
                        errors={errors}
                        notEditable={notEditable}
                        isworklist={isworklist}
                        setShowApprobvalBtn={setShowApprobvalBtn}
                        notShow={notShow}
                        pArr={pArr}
                        handlePopOverClickForLeave={handlePopOverClickForLeave}
                      />
                    );
                  })}
            </Box>
          </ScrollContainer>
        </Grid>
      </Grid>

      {/* {(!notShow && isworklist) ||
      (!["approved", "initiate"].includes(status.toLowerCase()) &&
        !notEditable) ? (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          <Button
            type="submit"
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
              marginRight: 10,
            }}
            variant="contained"
            color="primary"
            size="large"
          >
            <b>Save</b>
          </Button>
          {status.toLowerCase() !== "initiate" &&
          status.toLowerCase() === "saved" &&
          showApprovalBtn ? (
            <Button
              type="button"
              onClick={handleApproval}
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              <b>Send For Approval</b>
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <></>
      )} */}
    </Paper>
  );
}

export default EmployeeProjectActivity;
interface EmployeeProjectActivityProps {
  setAllotedProjectModalOpen: any;
  emp_project: EmpProject[];
  control: Control<FieldValues, any>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  setValue: any;
  setError: any;
  handleSubmit: any;
  notEditable?: boolean;
  initiateTimesheetAction?: any;
  handleApprovalRequest: any;
  setShowApprobvalBtn: any;
  showApprovalBtn: boolean;
  status: string;
  setStatus: any;
  isworklist?: boolean;
  reset: any;
  notShow?: boolean;
  selectedProjectsIds: number[];
  setSelectedProjectsIds: any;
}

const ProjectTiming = ({
  pIndex,
  control,
  self,
  errors,
  handlePopOverClick,
  isworklist,
  notEditable,
  notShow,
  setHolidayInfo,
  setLeaveInfo,
  leaveInfo,
  setShowApprobvalBtn,
  pArr,
  handlePopOverClickForLeave,
}: IProjectTiming) => {
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid transparent`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid transparent",
  }));
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  return (
    <Box ml={pIndex === 0 ? 8 : 0}>
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{backgroundColor:"transparent"}}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{backgroundColor:"#44c4d4"}}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: 15,
              fontFamily: "Poppins, sans-serif",
              color: "white",
              textAlign: "center",
            }}
          >
            {expanded === "panel1" ? "Close Time-Sheet" : "Open Time-Sheet"}{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails> */}
      <Box
      // sx={{
      //   height: "50vh",
      //   overflowY: "-moz-initial",
      //   overflowX: "hidden",
      // }}
      >
        {self.time_tab.map((_self: any, index: any, arr: any) => (
          <Box
            // my={1}
            // mx={2}
            pb={1.45}
            mt={index === 0 ? 1 : 0}
            display="flex"
          >
            <>
              <Box mx={0.3} sx={{ position: "relative" }}>
                <Controller
                  control={control}
                  name={`time_tab.[${index + pIndex * arr.length}].work_hours`}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      sx={{
                        backgroundColor: "transparent",
                        color: _self.isHoliday ? `#F71A31` : "gray",
                      }}
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      disabled={true}
                      className={"smaller"}
                      // disabled={
                      //   notShow
                      //     ? true
                      //     : !!isworklist &&
                      //       _self.status.toLowerCase() !== "approved"
                      //     ? false
                      //     : !!notEditable ||
                      //       (!isworklist &&
                      //         ["approved", "initiate"].includes(
                      //           _self.status.toLowerCase()
                      //         ))
                      // }
                      onBlur={onBlur}
                      size="small"
                      onChange={(e) => (
                        onChange(e), setShowApprobvalBtn(false)
                      )}
                      value={value}
                      placeholder={"0"}
                      error={
                        !!Array.isArray(errors?.time_tab) &&
                        errors?.time_tab[index + pIndex * arr.length]
                          ?.work_hours?.message
                      }
                    />
                  )}
                />
                {_self.isHoliday &&
                _self.holidayInfo &&
                pIndex === pArr.length - 1 ? (
                  <IconButton
                    sx={{ position: "absolute" }}
                    onClick={(event) => (
                      handlePopOverClick(event),
                      setHolidayInfo(_self.holidayInfo as IHolidayInfo)
                    )}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
                {_self.isLeave &&
                _self.emp_leave_data &&
                pIndex === pArr.length - 1 ? (
                  <IconButton
                    sx={{ position: "absolute" }}
                    onClick={(event) => (
                      handlePopOverClickForLeave(event),
                      setLeaveInfo(_self.emp_leave_data as ITimeSheetLeaveData)
                    )}
                  >
                    <InfoIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>
            </>
          </Box>
        ))}
      </Box>
      {/* </AccordionDetails>
      </Accordion> */}
    </Box>
    //     {provided.placeholder}
    //     </div>
    //      )}
    //   </Droppable>
    // </DragDropContext>
  );
};
interface IProjectTiming {
  // handleChange?: any;
  // expanded?: any;
  pIndex?: any;
  self?: any;
  control?: any;
  setShowApprobvalBtn?: any;
  setHolidayInfo?: any;
  handlePopOverClick?: any;
  errors?: any;
  notShow?: any;
  notEditable?: any;
  isworklist?: any;
  pArr?: any;
  setLeaveInfo: React.Dispatch<
    React.SetStateAction<ITimeSheetLeaveData | null>
  >;
  leaveInfo: ITimeSheetLeaveData | null;
  handlePopOverClickForLeave: any;
}
