import { Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { environment } from "../../environment";
import AddCustomer from "./AddCustomer";
import AddProject from "./AddProject";
import BudgetDashboard from "./BudgetDashboard";
import CreateBudgeting from "./CreateBudgeting";
import CreatePayment from "./CreatePayment";
import CustomerDetails from "./CustomerDetails";
import CustomerMain from "./CustomerMain";
import PaymentDetails from "./PaymentDetailsMain.tsx";
import ProjectBudgeting from "./ProjectBudgeting";
import ProjectDashboard from "./ProjectDashboard";
import ProjectMain from "./ProjectMain";
import LevelsMaster from "./Levels";
import LevelMasterAction from "./Levels/LevelMasterAction";
import LevelsData from "./Levels/LevelsData";
import ProjectSummary from "../Reports/ProjectSummary";
import InitiateProjectClosure from "./InitiateProjectClosure";
import ProjectClosurePendingApproval from "./ProjectClosurePendingApproval";
import CompletedProject from "./CompletedProject";
import ProjectReport from "./ProjectReport";
import ProjectInvoice from "./ProjectInvoice";
import CreateInvoice from "./CreateInvoice";
import PaymentDetailsByInvoice from "./PaymentDetailsByInvoice";

const Customer = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              // color: "#50535A",
              // fontWeight: "bold",
              // fontFamily: "Poppins, sans-serif",
              // fontSize: 18,
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30,
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              // color: "#D4D7DD",
              // fontSize: 14,
              // fontFamily: "Poppins, sans-serif",
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15,
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="customer-main" element={<CustomerMain />} />
        <Route path="add-customer/:id" element={<AddCustomer />} />
        <Route path="customer-details/:id" element={<CustomerDetails />} />
        <Route path="project-main" element={<ProjectMain />} />
        <Route path="add-project/:id/:id2" element={<AddProject />} />
        <Route path="project-dashboard/:id" element={<ProjectDashboard />} />
        <Route path="project-budgeting/" element={<ProjectBudgeting />} />
        <Route path="create-budgeting/:id" element={<CreateBudgeting />} />
        <Route path="payment-details-main/:id" element={<PaymentDetails />} />
        <Route path="create-payment/:id/:id2" element={<CreatePayment />} />
        <Route path="budget-dashboard/:id" element={<BudgetDashboard />} />
        <Route path="levels-data/:master_id" element={<LevelsData />} />
        <Route path="invoice-data/:id" element={<ProjectInvoice />} />
        <Route path="invoice-create/:id/:id2" element={<CreateInvoice />} />
        <Route path="invoice-payment_details/:id/:id2" element={<PaymentDetailsByInvoice />} />

        <Route
          path="/"
          element={<Navigate to={`/${pathname.split("/")[1]}/customer-main`} />}
        />
        <Route path="levels/" element={<LevelsMaster />} />
        <Route
          path="levels/levels-master/add-edit/:action_type"
          element={<LevelMasterAction />}
        />
        {/* <Route
          path="levels/levels-master/levelsdata/:master_id"
          element={<LevelsData />}
        /> */}
        <Route
          path="levels/levels-master/levelsdata"
          element={<LevelsData />}
        />
        <Route
          path="initiate-project-closure/"
          element={<InitiateProjectClosure />}
        />
        <Route
          path="pending-project-closure/"
          element={<ProjectClosurePendingApproval />}
        />
        <Route path="completed-project/" element={<CompletedProject />} />
        <Route path="project-report/:id" element={<ProjectReport />} />
      </Routes>
    </>
  );
};

export default Customer;
