import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import Autocompletes from "../../../library/AutoComplete";
import {
  OrganizationALlList,
  ServiceOfferList,
} from "../../../models/organizationModels";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { bankList } from "../../../models/employeeModel";
import { StateList } from "../../../models/customerModel";
import { Label } from "recharts";

function AddNewOrganization({
  Submit,
  service_list,
  organization_list,
  organization,
  SubmitUpdatedData,
  bank_list,
  state_list,
}: AddNewOrganizationProps) {
  const countryOptions: any[] = [
    {
      key: "India",
      value: "India",
    },
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    console.log(data);
    if (!!organization) {
      let payload: OrganizationALlList = { ...organization, ...data };
      SubmitUpdatedData(payload);
    } else {
      Submit(data);
    }
  };
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [checked, setChecked] = useState(false);
  const [edit_flag, SetEditFlag] = useState<boolean>(true);
  useEffect(() => {
    if (!!organization) {
      setChecked(!!organization.organization_parent_id);
      setTimeout(() => {
        setValue("organization_id", organization.organization_id);
        setValue("created_at", organization.created_at);

        setValue("organization_pan", organization.organization_pan);
        setValue("official_contact_no", organization.official_contact_no);
        setValue(
          "organization_acoount_no",
          organization.organization_acoount_no
        );
        setValue("organization_addr", organization.organization_addr);
        setValue("organization_bank_name", organization.organization_bank_name);
        setValue(
          "organization_branch_name",
          organization.organization_branch_name
        );
        setValue("organization_email", organization.organization_email);
        setValue("organization_cin_no", organization.organization_cin_no);
        setValue("organization_desc", organization.organization_desc);
        setValue("organization_gst", organization.organization_gst);
        setValue("organization_ifsc_code", organization.organization_ifsc_code);
        setValue("organization_name", organization.organization_name);
        setValue("address_line1", organization.address_line1);
        setValue("address_line2", organization.address_line2);
        setValue("city", organization.city);
        setValue("state", organization.state);
        setValue("pin", organization.pin);
        setValue("country", organization.country);
        setValue("emp_prefix", organization.emp_prefix);
        setValue("district", organization.district);
        setValue("service_offer", organization.service_offer);
        setValue("organization_parent_id", organization.organization_parent_id);
      }, 200);
      // setValue("organization_name", organization.);
      // address_line1: string;
      // address_line2: string;
      // city: string;
      // state: string;
      // country: string;
      // pin: string;
    }
  }, [organization]);

  return (
    <Paper elevation={5} sx={{ margin: 5 }}>
      <Grid
        container
        direction={"row"}
        // justifyContent={"center"}
        // alignItems={"center"}
        style={{ textAlign: "center", padding: 14 }}
      >
        <Grid item>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={() => navigate(`/organization/organization-main/`)}
          // style={{ marginLeft: -10 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} marginTop={1}>
          <Typography
            style={{
              // fontWeight: "700",
              // fontSize: 18,
              // fontFamily: "Poppins, sans-serif",
              // textAlign: "center",
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 20
            }}
          >
            {!!organization ? "Organization Details" : "Add Organization"}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        style={{
          borderColor: "#9E9E9E",
          opacity: 0.7,
          width: "90%",
          marginLeft: "5%",
        }}
      ></Divider>
      <form>
        <Grid container justifyContent={"center"}>
          <Grid item style={{ marginInline: 5, width: "92%" }}>
            {!!organization && (
              <Grid
                container
                justifyContent="end"
                alignItems="center"
                style={{ marginTop: 15, marginBottom: 10 }}
              >
                <Switch
                  value={edit_flag}
                  onChange={(event) => SetEditFlag(!event.target.checked)}
                />
                <Typography style={{ color: "#A2A2A2" }}>
                  Edit Organization
                </Typography>
              </Grid>
            )}

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Checkbox
                disabled={!!organization ? edit_flag : !edit_flag}
                checked={checked}
                onChange={(event) => (
                  setChecked(event.target.checked),
                  !event.target.checked &&
                  setValue("organization_parent_id", null)
                )}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography style={{ color: "#A2A2A2" }}>
                Do you want to add Sister Concern
              </Typography>
            </Grid>
            <Grid item xs={10} marginTop={1} marginBottom={4}>
              <Typography
                style={{
                  // fontWeight: "700",
                  // fontSize: 18,
                  // fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 20,
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 20
                }}
              >
                Basic Details
              </Typography>
            </Grid>

            {!!checked && checked == true ? (
              <>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 10 }}
                >
                  <Grid item xs={6}>
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid
                        item
                        xs={3}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "7%",
                          }}
                        >
                          Sister Concern of
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"organization_parent_id"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={
                                !!organization_list ? organization_list : []
                              }
                              // disable={!!organization ? edit_flag : !edit_flag}
                              keys={"organization_id"}
                              label={"organization_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(+value)}
                              readOnly={!!organization ? edit_flag : !edit_flag}
                              // placeholder={"Search Sister Concern"}
                              value={!!value ? value.toString() : ""}
                              errors={!!errors["organization_parent_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid
                        item
                        xs={3}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "0%",
                          }}
                        >
                          Sister Concern's Name
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name={"organization_name"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              variant="standard"
                              type={"text"}
                              style={{ width: "100%" }}
                              onBlur={onBlur}
                              className={"smaller"}
                              size="small"
                              onChange={onChange}
                              inputProps={{
                                readOnly: !!organization
                                  ? edit_flag
                                  : !edit_flag,
                              }}
                              value={value}
                              // label={"Organization Name"}
                              label="Sister Concern's Name"
                              error={!!errors["organization_name"]}
                              helperText={
                                errors.organization_name &&
                                `${errors.organization_name.message}* This field is Required`
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={1.5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Sister Concern's Code
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      control={control}
                      name={"emp_prefix"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          variant="standard"
                          label="Sister Concern's Code"
                          type={"text"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          className={"smaller"}
                          size="small"
                          onChange={onChange}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          value={value}
                          // label={"Organization Name"}
                          // placeholder={"Sister Concern's Code"}
                          error={!!errors["emp_prefix"]}
                          helperText={
                            errors.emp_prefix &&
                            `${errors.emp_prefix.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginTop: 15, marginBottom: 10 }}
                >
                  <Grid item xs={6} justifyContent="start" alignItems="center">
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid
                        item
                        xs={3}
                        justifyContent="start"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "7%",
                          }}
                        >
                          Organization Name
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name={"organization_name"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"text"}
                              variant="standard"
                              label="Enter Organisation Name"
                              style={{ width: "95%", marginLeft: "5%" }}
                              onBlur={onBlur}
                              size="small"
                              className={"smaller"}
                              onChange={onChange}
                              inputProps={{
                                readOnly: !!organization
                                  ? edit_flag
                                  : !edit_flag,
                              }}
                              value={value}
                              // label={"Organization Name"}
                              // placeholder={"Organization Name"}
                              error={!!errors["organization_name"]}
                              helperText={
                                errors.organization_name &&
                                `${errors.organization_name.message}* This field is Required`
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid
                        item
                        xs={3}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "0%",
                          }}
                        >
                          Organization Code
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name={"emp_prefix"}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <TextField
                              type={"text"}
                              label="Enter Organization Code"
                              variant="standard"
                              style={{ width: "95%", marginLeft: "5%" }}
                              onBlur={onBlur}
                              size="small"
                              className={"smaller"}
                              onChange={onChange}
                              inputProps={{
                                readOnly: !!organization
                                  ? edit_flag
                                  : !edit_flag,
                              }}
                              value={value}
                              // label={"Organization Name"}
                              // placeholder={"Organization Code"}
                              error={!!errors["emp_prefix"]}
                              helperText={
                                errors.emp_prefix &&
                                `${errors.emp_prefix.message}* This field is Required`
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Divider style={{ borderColor: "#DDDDDF", opacity: 0.8, width:"80%",marginLeft:"5%"  }}></Divider> */}
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6} justifyContent="start" alignItems="center">
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Organization PAN
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_pan"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter Pan Number"
                          className={"smaller"}
                          style={{ width: "95%", marginLeft: "5%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"PAN No."}
                          error={!!errors["organization_pan"]}
                          helperText={
                            errors.organization_pan &&
                            `${errors.organization_pan.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} alignItems="center" justifyContent="start">
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Organization GST
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_gst"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter GST Number"
                          className={"smaller"}
                          style={{ width: "95%", marginLeft: "5%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"GST No."}
                          error={!!errors["organization_gst"]}
                          helperText={
                            errors.organization_gst &&
                            `${errors.organization_gst.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid
                item
                xs={1.5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "7%",
                  }}
                >
                  Organization CIN
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Controller
                  control={control}
                  name={"organization_cin_no"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="standard"
                      label="Enter CIN Number"
                      className={"smaller"}
                      style={{ width: "98%", marginLeft: "2%" }}
                      onBlur={onBlur}
                      // disabled={!!organization ? edit_flag : !edit_flag}
                      inputProps={{
                        readOnly: !!organization ? edit_flag : !edit_flag,
                      }}
                      size="small"
                      onChange={onChange}
                      value={value}
                      // label={"Department Name"}
                      // placeholder={"CIN No."}
                      error={!!errors["organization_cin_no"]}
                      helperText={
                        errors.organization_cin_no &&
                        `${errors.organization_cin_no.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Official Contact
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"official_contact_no"}
                      defaultValue={""}
                      rules={{ required: true, maxLength: 12 }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter Contact Number"
                          className={"smaller"}
                          style={{ width: "95%", marginLeft: "5%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Contact No."}
                          error={!!errors["official_contact_no"]}
                          helperText={
                            errors.official_contact_no &&
                            `${errors.official_contact_no.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Official Email ID
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_email"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          id="standard-required"
                          variant="standard"
                          label="Enter Email ID"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Email ID"}
                          error={!!errors["organization_email"]}
                          helperText={
                            errors.organization_email &&
                            `${errors.organization_email.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid
                item
                xs={1.5}
                justifyContent="space-between"
                alignItems="start"
              >
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "7%",
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Controller
                  control={control}
                  name={"organization_desc"}
                  defaultValue={""}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextareaAutosize
                      // style={{ width: "99.5%", height: 100 }}
                      style={{ width: "94%", height: 100, resize: "none", fontSize: "15px", padding: "2%", outline: "none", border: "1px solid rgba(19,36,55,0.5)", borderRadius: "5px", marginLeft: "2%" }}
                      onBlur={onBlur}
                      className={"smaller"}
                      // id="standard-required"
                      onChange={onChange}
                      value={value}
                      disabled={!!organization ? edit_flag : !edit_flag}
                      placeholder={"Description"}
                      minRows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider
              style={{
                borderColor: "#DDDDDF",
                opacity: 0.8,
                width: "80%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid item xs={10} marginTop={1} marginBottom={4}>
              <Typography
                style={{
                  // fontWeight: "700",
                  // fontSize: 18,
                  // fontFamily: "Poppins, sans-serif",
                  // textAlign: "left",
                  // marginLeft: "1%",
                  // marginBottom: 20,
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 20,
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 20
                }}
              >
                Bank Details
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Bank Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"organization_bank_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={!!bank_list ? bank_list : []}
                          style={{ width: "100%" }}
                          // disable={!!organization ? edit_flag : !edit_flag}
                          keys={"bank_name"}
                          label={"bank_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          readOnly={!!organization ? edit_flag : !edit_flag}
                          placeholder={"Bank Name"}
                          value={!!value ? value.toString() : ""}
                          errors={!!errors["organization_bank_name"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Branch Name
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_branch_name"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter Branch Name"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Branch Name"}
                          error={!!errors["organization_branch_name"]}
                          helperText={
                            errors.organization_branch_name &&
                            `${errors.organization_branch_name.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Bank A/c No.
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_acoount_no"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"number"}
                          id="standard-required"
                          variant="standard"
                          label="Enter Bank Account Number"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Bank A/c No"}
                          error={!!errors["organization_acoount_no"]}
                          helperText={
                            errors.organization_acoount_no &&
                            `${errors.organization_acoount_no.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      IFSC Code
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"organization_ifsc_code"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter IFSC Code"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"IFSC Code"}
                          error={!!errors["organization_ifsc_code"]}
                          helperText={
                            errors.organization_ifsc_code &&
                            `${errors.organization_ifsc_code.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider
              style={{
                borderColor: "#DDDDDF",
                opacity: 0.8,
                width: "80%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid item xs={10} marginTop={1} marginBottom={4}>
              <Typography
                style={{
                  // fontWeight: "700",
                  // fontSize: 18,
                  // fontFamily: "Poppins, sans-serif",
                  // textAlign: "left",
                  // marginLeft: "1%",
                  // marginBottom: 20,
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 20,
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 20
                }}
              >
                Organization Address
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Address Line 1
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"address_line1"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Address Line 1"}
                          label="Enter Address Line 1"
                          error={!!errors["address_line1"]}
                          helperText={
                            errors.address_line1 &&
                            `${errors.address_line1.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Address Line 2
                      {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"address_line2"}
                      defaultValue={""}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"Address Line 2"}
                          label="Enter Address Line 2"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      City
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"city"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter City Name"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          size="small"
                          onChange={onChange}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"City"}
                          error={!!errors["city"]}
                          helperText={
                            errors.city &&
                            `${errors.city.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      District
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"district"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          label="Enter District Name"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          // disabled={!!organization ? edit_flag : !edit_flag}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"District"}
                          error={!!errors["district"]}
                          helperText={
                            errors.district &&
                            `${errors.district.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      State
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"state"}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={state_list}
                          style={{
                            width: "100%",

                            padding: "0",
                          }}
                          readOnly={!!organization ? edit_flag : !edit_flag}
                          keys={"state_name"}
                          label={"state_name"}
                          onBlur={onBlur}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Search State Name"}
                          value={!!value ? value : ""}
                          errors={!!errors["state"]}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "0%",
                      }}
                    >
                      Country
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: -5 }}>
                    <Controller
                      control={control}
                      name={"country"}
                      defaultValue={"India"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocompletes
                          hasInfo={false}
                          option={countryOptions}
                          keys={"key"}
                          label={"value"}
                          onBlur={onBlur}
                          readOnly={!!organization ? edit_flag : !edit_flag}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Country"}
                          value={!!value ? value : ""}
                          errors={errors.country}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      PIN
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"pin"}
                      defaultValue={""}
                      rules={{ required: true, maxLength: 6 }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="standard"
                          className={"smaller"}
                          style={{ width: "100%" }}
                          onBlur={onBlur}
                          inputProps={{
                            readOnly: !!organization ? edit_flag : !edit_flag,
                          }}
                          size="small"
                          onChange={onChange}
                          value={value}
                          // label={"Department Name"}
                          // placeholder={"PIN"}
                          label="Enter the PIN"
                          error={!!errors["pin"]}
                          helperText={
                            errors.pin &&
                            `${errors.pin.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>

            <Divider
              style={{
                borderColor: "#DDDDDF",
                opacity: 0.8,
                width: "80%",
                marginLeft: "5%",
              }}
            ></Divider>
            <Grid item xs={10} marginTop={1} marginBottom={4}>
              <Typography
                style={{
                  // fontWeight: "700",
                  // fontSize: 18,
                  // fontFamily: "Poppins, sans-serif",
                  // textAlign: "left",
                  // marginLeft: "1%",
                  // marginBottom: 20,
                  textAlign: "left",
                  marginLeft: "1%",
                  marginBottom: 20,
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 20
                }}
              >
                Service Details
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid item xs={6}>
                <Grid container justifyContent="start" alignItems="center">
                  <Grid
                    item
                    xs={3}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "7%",
                      }}
                    >
                      Services Offered
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name={"service_offer"}
                      defaultValue={[]}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={!!service_list ? service_list : []}
                          getOptionLabel={(option) => option.service_name}
                          readOnly={!!organization ? edit_flag : !edit_flag}
                          onChange={(evemt, value) =>
                            onChange(
                              !!value
                                ? value.map((m) => m.service_master_id)
                                : []
                            )
                          }
                          value={
                            !!value
                              ? service_list.filter((m) =>
                                value.includes(m.service_master_id)
                              )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-required"
                              className={"smaller"}
                              variant="outlined"
                              placeholder="Services Offered"
                              onBlur={onBlur}
                              size="small"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          {((edit_flag && !organization) || (!edit_flag && !!organization)) && (
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              size="large"
            >
              <b>{!!organization ? "Update" : "Add"}</b>
            </Button>
          )}
        </Grid>
      </form>
    </Paper>
  );
}

export default AddNewOrganization;
interface AddNewOrganizationProps {
  Submit?: any;
  service_list: ServiceOfferList[];
  organization_list: OrganizationALlList[];
  organization?: OrganizationALlList;
  SubmitUpdatedData?: any;
  bank_list: bankList[];
  state_list: StateList[];
}
