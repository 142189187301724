import { AttendanceMain } from "../../models/attendanceModel";
import { AttendanceActionTypes } from "../actions/attendanceAction";
import InitialState from "./initialState";
const initialState: AttendanceMain = InitialState.attendance;
export default function Attendancereducer(
  state: AttendanceMain = initialState,
  action: any
) {
  switch (action.type) {
    case AttendanceActionTypes.Attendance_Dashboard_Success_Action:
      return {
        ...state,
        attendance_dashboard: action.payload,
        attendance_payload: undefined,
      };
    case AttendanceActionTypes.Pending_Approval_Success_Action:
      return { ...state, pending_approval: action.payload };
    // case AttendanceActionTypes.Uploaded_Attendance__View_Success_Action:
    //   return { ...state, upload_attendance_view: action.payload };
    case AttendanceActionTypes.Uploaded_Attendance__View_Success_Action:
      return {
        ...state,
        employee_list: action.payload.list,
        total_count: action.payload.count,
        attendence_fields: action.payload.attendence_fields,
      };
    case AttendanceActionTypes.Attendance_Detail_Payload_Success_Action:
      return { ...state, attendance_payload: action.payload };
    case AttendanceActionTypes.Approve_Attendance_List_Success_Action:
      return { ...state, attendance_approve_list: action.payload };
    case AttendanceActionTypes.Attendance_Time_Sheet_Success_Action:
      return { ...state, attendance_timesheet_list: action.payload };
    default:
      return state;
  }
}
