import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { environment } from "../../../environment";
import { RefferalList } from "../../../models/employeeModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { getAllRefferalAction } from "../../../stores/actions/employeeAction";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import ReferralListView from "./ReferralListView";

const ReferralList = ({
  refferal_list,
  getAllRefferalAction,
  referral_count,
  SidenavAutoCloseOpen,
}: ReferralListProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  const filterData = (props: any) => {
    getAllRefferalAction({
      enrolment_id:
        !!props.enrolment_id && props.enrolment_id != ""
          ? props.enrolment_id
          : null,
      candidate_name:
        !!props.candidate_name && props.candidate_name != ""
          ? props.candidate_name
          : null,
      mobile_no:
        !!props.mobile_no && props.mobile_no != "" ? props.mobile_no : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const Clear = () => {
    getAllRefferalAction({
      employee_id: null,
      employee_name: null,
      employee_mob_no: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  React.useEffect(() => {
    getAllRefferalAction({
      candidate_name: !!getValues("candidate_name")
        ? getValues("candidate_name")
        : null,
      enrolment_id: !!getValues("enrolment_id")
        ? getValues("enrolment_id")
        : null,
      mobile_no: !!getValues("mobile_no") ? getValues("mobile_no") : null,
      page_size: pagesize,
      page_index: pageindex,
    });
  }, [pageindex, pagesize]);
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);

  return (
    <ReferralListView
      refferal_list={refferal_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      referral_count={referral_count}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      filterData={filterData}
      Clear={Clear}
      control={control}
      handleSubmit={handleSubmit}
      reset={reset}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    refferal_list: state.employee.refferal_list,
    referral_count: state.employee.refferal_count,
  };
};
const mapDispatchToProps = {
  getAllRefferalAction,
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralList);
interface ReferralListProps {
  getAllRefferalAction?: any;
  refferal_list: RefferalList[];
  referral_count?: number;
  SidenavAutoCloseOpen?: any;
}
