import React, { useEffect, useState } from "react";
import PendingPromotionListView from "./PendingPromotionListView";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import {
  PromotionApprovalList,
  ViewPromotion,
} from "../../../models/promotionModel";
import {
  DeletePromotionAction,
  PromotionApprovalListAction,
  UpdatePromotionAction,
  ViewPromotionAction,
} from "../../../stores/actions/promotionAction";
import { userDetails } from "../../../models/userModels";
import {
  GetAllDesignationAction,
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
} from "../../../stores/actions/organizationActions";
import {
  AllDesignationList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { useSnackbar } from "notistack";

const PendingPromotionList = ({
  pending_promtions,
  PromotionApprovalListAction,
  user,
  GetAllDesignationAction,
  designation_list,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  UpdatePromotionAction,
  GetAllSalaryStructureAction,
  ViewPromotionAction,
  view_promotion,
  DeletePromotionAction
}: PendingPromotionListProps) => {
  React.useEffect(() => {
    if (!!pending_promtions) {
      PromotionApprovalListAction({
        approverDesigId: user?.designation,
        eventType: 11,
      });
    }
  }, []);
  // useEffect(() => {
  //   GetAllDesignationAction({ organization_id: null });
  // }, []);
  const { enqueueSnackbar } = useSnackbar();
  const [viewBatchID, setViewBatchID] = useState<any>("")
  const ApprovedAction = (data: any) => {
    console.log("data", data);
    let payload: any = {
      data1: { approverDesigId: user?.designation, eventType: 11 },
      data2 : data,
      enqueueSnackbar: enqueueSnackbar,
    };
    UpdatePromotionAction(payload);
  };
  // console.log("pending_promtions", pending_promtions);
  const ListofPendingPromotion = (props: any) => {
    ViewPromotionAction({
      promotion_salary_batch_id: props,
    });
    setViewBatchID(props)
  };
  const RemoveEmpPromotion=(data: any) => {
    
    let payload: any ={
      data1 : data,
      enqueueSnackbar: enqueueSnackbar,
      data3: {
        promotion_salary_batch_id: viewBatchID
      }
    }
    DeletePromotionAction(payload)
  }
  return (
    <PendingPromotionListView
      pending_promtions={pending_promtions}
      ApprovedAction={ApprovedAction}
      salary_structure_list={salary_structure_list}
      SalaryComponentEditAction={SalaryComponentEditAction}
      designation_list={designation_list}
      salary_structure={salary_structure}
      ListofPendingPromotion={ListofPendingPromotion}
      view_promotion={view_promotion}
      GetAllDesignationAction={GetAllDesignationAction}
      GetAllSalaryStructureAction={GetAllSalaryStructureAction}
      RemoveEmpPromotion={RemoveEmpPromotion}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    pending_promtions: state.promotion.pending_promtions,
    user: state.user.userDetails,
    designation_list: state.organization.designation_list,
    salary_structure_list: state.organization.salary_structure_list,
    salary_structure: state.organization.salary_structure_edit,
    view_promotion: state.promotion.view_promotion,
  };
};

const mapDispatchToProps = {
  PromotionApprovalListAction,
  GetAllDesignationAction,
  UpdatePromotionAction,
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
  ViewPromotionAction,
  DeletePromotionAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingPromotionList);
interface PendingPromotionListProps {
  pending_promtions: PromotionApprovalList[];
  PromotionApprovalListAction?: any;
  user?: userDetails;
  GetAllDesignationAction?: any;
  designation_list: AllDesignationList[];
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  UpdatePromotionAction?: any;
  GetAllSalaryStructureAction?: any;
  ViewPromotionAction?: any;
  view_promotion: ViewPromotion[];
  DeletePromotionAction?: any;
}
