import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Slide,
  Grid,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import {
  ApproveRejectPayload,
  EventList,
  ReportingManagerList,
} from "../../../models/employeeModel";
import moment from "moment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReportingManagerDefine from "./ReportingManagerDefine";
import UserCreation from "./UserCreation";
import { Controller, useForm } from "react-hook-form";
import SalaryDafination from "./SalaryDafination";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { environment } from "../../../environment";
const TaskAssignment = ({
  open,
  setOpen,
  approvalList,
  SetapprovalList,
  GetAllSalaryStructureAction,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  DomValue,
  getReportingManagerList,
  ReportingManagerList,
  ApprovedAction,
  GetAttendenceSalarytypeAction,
  attendence_salary_type,
  approve_type,
}: TaskAssignmentProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const columns: any[] = [
    "Enrollment ID",
    "Candidate Name",
    "Employee Type",
    "Designation",
    "Action",
  ];
  const [Mystep, SetStep] = useState<string[]>([]);
  const removeCandidate = (row: EventList) => {
    let temp: EventList[] = JSON.parse(JSON.stringify(approvalList));
    temp = temp.filter((m) => m.empLifeEventId != row.empLifeEventId);
    SetapprovalList(temp);
    if (temp.length <= 0) setOpen(false);
  };
  const onSubmit = async (data: any) => {
    let component: any = {};
    if (
      !!Mystep.find((m) => m == "salary_assignment") &&
      !!salary_structure &&
      !!salary_structure.component &&
      salary_structure.component.length > 0
    ) {
      for await (let m of salary_structure?.component) {
        if (!m.calculation) {
          component = {
            ...component,
            [m.compent_id]: +data[m.compent_id],
          };
        }
      }
    }
    let payload: ApproveRejectPayload = {
      candidateId: await approvalList.map((m) => m.candidate_id),
      empLifeEventIds: await approvalList.map((m) => m.empLifeEventId),
      remarks: data.remarks,
      reporting_manager: !!Mystep.find((m) => m == "reporting_manager")
        ? data.reporting_manager
        : null,
      user_creation: !!Mystep.find((m) => m == "user_creation") ? true : false,
      salary_assignment: !!Mystep.find((m) => m == "salary_assignment")
        ? {
            salary_structure_id: data.salary_structure_id,
            employee_id: null,
            components: component,
            attendence_salary_type_id: data.attendence_salary_type_id,
          }
        : null,
      status: approve_type,
    };
    ApprovedAction(payload);
  };
  useEffect(() => {
    if (open && approvalList.length > 0) {
      SetStep(approvalList[0].event_action_items);
      reset();
      getReportingManagerList({
        organization_id: approvalList[0].organization_id,
        employee_type_id: environment.global_veriable.ho_employees_type_id,
        project_id: approvalList[0].project_id,
      });
    }
  }, [open]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#0060FF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Verify and Approve
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container height={"92%"} flexGrow={1} flexDirection={"row"}>
        <Grid
          item
          xs={5.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          {approvalList[0]?.project_name !=
            environment.global_veriable.ho_project_name && (
            <Paper
              sx={{
                flexGrow: 1,
                borderRadius: 1,
                border: "1px solid #E0E0E0",
                margin: 2,
              }}
            >
              <Grid
                container
                flexDirection={"row"}
                style={{ border: "1px solid #E0E0E0" }}
              >
                <Grid item xs={4} style={{ padding: 5 }}>
                  <Typography style={{ fontWeight: "600" }}>
                    Project Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ backgroundColor: "#F4F7F6", padding: 5 }}
                >
                  <Typography>{approvalList[0]?.project_name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              border: "1px solid #E0E0E0",
              margin: 2,
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!approvalList &&
                    approvalList.map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.empLifeEventId}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.empolyeeId}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.candidate_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[2]}>
                          {row.employee_type_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          {row.designation_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[4]}>
                          <Tooltip
                            title="Remove"
                            onClick={() => removeCandidate(row)}
                          >
                            <IconButton color="error">
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid
          item
          xs={6.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          {Mystep.map(
            (item, index) =>
              item != "user_creation" &&
              item != "approve" &&
              item != "" &&
              !!approve_type &&
              approve_type == 1 && (
                <div style={{ margin: 5, padding: 5 }}>
                  <Grid
                    style={{
                      width: "50%",
                      backgroundColor: "#FF6400",
                      padding: 5,
                    }}
                  >
                    <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                      {DomValue(item)}
                    </Typography>
                  </Grid>
                  {item == "salary_assignment" && (
                    <SalaryDafination
                      org_id={
                        !!approvalList && approvalList.length > 0
                          ? approvalList[0].organization_id
                          : undefined
                      }
                      control={control}
                      errors={errors}
                      GetAllSalaryStructureAction={GetAllSalaryStructureAction}
                      salary_structure_list={salary_structure_list}
                      SalaryComponentEditAction={SalaryComponentEditAction}
                      salary_structure={salary_structure}
                      watch={watch}
                      reset={reset}
                      setValues={setValue}
                      GetAttendenceSalarytypeAction={
                        GetAttendenceSalarytypeAction
                      }
                      attendence_salary_type={attendence_salary_type}
                    />
                  )}
                  {item == "reporting_manager" && (
                    <ReportingManagerDefine
                      control={control}
                      errors={errors}
                      ReportingManagerList={ReportingManagerList}
                    />
                  )}
                  {item == "user_creation" && <UserCreation />}
                </div>
              )
          )}
          <div style={{ margin: 5, padding: 5 }}>
            <Grid
              style={{ width: "50%", backgroundColor: "#FF6400", padding: 5 }}
            >
              <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                Remarks
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: 5 }}>
              <Controller
                control={control}
                name={"remarks"}
                defaultValue={""}
                rules={{
                  required: !!approve_type ? false : true,
                }}
                render={({ field: { onBlur, value, onChange } }) => (
                  <TextField
                    multiline
                    variant="outlined"
                    // className={"smaller"}
                    style={{ width: "100%" }}
                    onBlur={onBlur}
                    size="small"
                    onChange={onChange}
                    value={value}
                    error={!!errors.remarks}
                    placeholder={"Remarks..."}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      style: { minHeight: 80 },
                    }}
                  />
                )}
              />
            </Grid>
          </div>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            {!!approve_type && approve_type == 1 ? (
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
                size="large"
              >
                Approve
              </Button>
            ) : (
              <Button
                sx={{
                  color: "#fff",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="error"
                size="large"
              >
                Reject
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TaskAssignment;

interface TaskAssignmentProps {
  open: boolean;
  setOpen?: any;
  approvalList: EventList[];
  SetapprovalList?: any;
  GetAllSalaryStructureAction?: any;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  DomValue?: any;
  getReportingManagerList?: any;
  ReportingManagerList: ReportingManagerList[];
  ApprovedAction?: any;
  GetAttendenceSalarytypeAction?: any;
  attendence_salary_type: DomainList[];
  approve_type?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
