import { TerminationMain } from "../../models/terminationModel";
import { TerminationActionTypes } from "../actions/terminationAction";
import InitialState from "./initialState";

const initialState: TerminationMain = InitialState.termination;
export default function TerminationReducer(
  state: TerminationMain = initialState,
  action: any
) {
  switch (action.type) {
    case TerminationActionTypes.Employee_List_Available_For_Termination_Success_Action:
      return { ...state, emp_available_for_termination: action.payload };
    case TerminationActionTypes.Termination_Initiated_Employee_List_Success_Action:
      return { ...state, termination_initiated_emp_list: action.payload };
    case TerminationActionTypes.Terminated_Employee_List_Success_Action:
      return { ...state, terminated_emp_list: action.payload };
    default:
      return state;
  }
}
