import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { AllDepartmentList } from "../../../models/organizationModels";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterList from "@mui/icons-material/FilterList";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DepartmentView = ({
  organization_list,
  department_list,
  filterData,
  Clear,
  Delete,
  DeleteAction,
}: DepartmentViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [expanded, setExpanded] = useState<string | false>(false);
  const [rows, setRows] = useState<AllDepartmentList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [searched, setSearched] = useState<string>("");
  const [delete_flag, setdeleteFlag] = useState<boolean>(false);
  const [delete_payload, SetdeletePayload] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!department_list
      ? department_list.filter((row: AllDepartmentList) => {
        return (
          row?.department_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.department_type
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!department_list) {
      cancelSearch();
    }
  }, [department_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const columns: any[] = [
    "Organization Name",
    "Department Name",
    "Description",
    // "Department Type",
    "Created On",
    "Action",
  ];
  const handleClose = () => {
    setAnchorEl(null);
  };
  const filterDatas = (data: any) => {
    filterData(data);
    handleClose();
  };
  return (
    <Paper sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }} elevation={5}>
      <Dialog
        open={delete_flag}
        onClose={() => setdeleteFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Department?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setdeleteFlag(false)}>
            No
          </Button>
          <Button
            onClick={() => (DeleteAction(delete_payload), setdeleteFlag(false))}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <Grid item xs={3}>
          <Button
            onClick={() => navigate("/organization/add-department/0")}
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}>
            <Tooltip title="Filter">
              <IconButton size="large" onClick={handleClick}>
                <FilterList />
              </IconButton>
            </Tooltip>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              <Grid
                container
                minWidth={500}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"column"}
              >
                <Grid
                  container
                  style={{ backgroundColor: "#0060FF", padding: 5 }}
                  justifyContent={"center"}
                  width={"100%"}
                  alignItems={"center"}
                >
                  <Typography style={{ color: "#ffff" }}>Filter By</Typography>
                </Grid>
                <Grid style={{ margin: 5, width: "90%" }}>
                  <form>
                    <Grid container justifyContent="start" alignItems="center">
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography style={{ fontWeight: 400 }}>
                          Organization
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={"organization_id"}
                          defaultValue={""}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={organization_list}
                              keys={"organization_id"}
                              label={"organization_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Organization"}
                              value={!!value ? value : ""}
                              errors={!!errors["organization_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end ",
                        //   marginRight: "2rem",
                        marginRight: "0px",
                        marginTop: "13px",
                        marginBottom: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          marginRight: 1,
                        }}
                        onClick={() => (Clear(), reset(), handleClose())}
                      >
                        Clear
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          marginLeft: 1,
                        }}
                        variant="outlined"
                        onClick={handleSubmit(filterDatas)}
                      >
                        filter
                      </Button>
                    </Box>
                  </form>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
      </Grid>
      <Grid marginTop={2}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
          //   style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.department_id}
                  >
                    <StyledTableCell align="left" key={columns[0]}>
                      {row.organization_name}
                      {/* Emdee */}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[1]}>
                      {row.department_name}
                      {/* Active */}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[2]}>
                      {row.department_desc}
                      {/* Active */}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" key={columns[3]}> */}
                    {/* {row.department_type} */}
                    {/* Active */}
                    {/* </StyledTableCell> */}
                    <StyledTableCell align="left" key={columns[4]}>
                      {row.created_on
                        ? moment(row.created_on)
                          .format("DD/MM/YYYY HH:mm:ss a")
                          .toUpperCase()
                        : "N/A"}
                      {/* 23.12.22 */}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[5]}>
                      <Tooltip
                        title="View"
                        onClick={() =>
                          navigate(
                            `/organization/add-department/${row.department_id}`
                          )
                        }
                      >
                        <IconButton>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Edit">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            color="error"
                            sx={{ cursor: "pointer" }}
                            onClick={() => (
                              setdeleteFlag(true),
                              SetdeletePayload({
                                department_id: row.department_id,
                                organization_id: row.organization_id,
                              })
                            )}
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Paper>
  );
};

export default DepartmentView;

interface DepartmentViewProps {
  organization_list?: any;
  department_list: AllDepartmentList[];
  filterData?: any;
  Clear?: any;
  Delete?: any;
  DeleteAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
