import { Box, Paper } from "@mui/material";
import React, { FC } from "react";
import LeaveTypeTable from "./LeaveTypeTable";
import {
  EditLevelTypeAction,
  GetAllLeaveTypeAction,
  GetLeaveTypeByIdAction,
  createLeaveTypeAction,
  deleteLevelTypeAction,
  selectOrganization,
} from "../../../stores/actions/LeaveAction";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { ILeaveType } from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import OrganizationAutoComplete from "../OrganizationAutoComplete";
import { OrganizationALlList } from "../../../models/organizationModels";
import { GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
const LeaveTypesMain: FC<ILeaveTypesMain> = ({
  GetAllLeaveTypeAction,
  GetLeaveTypeByIdAction,
  GetAllOrganizationAction,
  EditLevelTypeAction,
  deleteLevelTypeAction,
  createLeaveTypeAction,
  selectOrganization,
  selected_organization_leave,
  organization_list,
  leave_types,
  leave_type,
  user,
}): JSX.Element => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <OrganizationAutoComplete
          organization_list={organization_list}
          user={user}
          selectOrganization={selectOrganization}
          selected_organization_leave={selected_organization_leave}
          GetAllOrganizationAction={GetAllOrganizationAction}
        />
      </Box>
      <LeaveTypeTable
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        GetLeaveTypeByIdAction={GetLeaveTypeByIdAction}
        EditLevelTypeAction={EditLevelTypeAction}
        deleteLevelTypeAction={deleteLevelTypeAction}
        createLeaveTypeAction={createLeaveTypeAction}
        selectedOrganizationLeave={selected_organization_leave}
        selectOrganizationAction={selectOrganization}
        leave_type={leave_type}
        leave_types={leave_types}
        user={user}
      />
    </Box>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    leave_types: state.leave.leave_types,
    leave_type: state.leave.leave_type,
    user: state.user.userDetails,
    selected_organization_leave: state.leave.selected_organization,
    organization_list: state.organization.organization_filter_list,
  };
};
const mapDispatchToProps = {
  GetAllLeaveTypeAction,
  GetLeaveTypeByIdAction,
  EditLevelTypeAction,
  deleteLevelTypeAction,
  createLeaveTypeAction,
  selectOrganization,
};

interface ILeaveTypesMain {
  GetAllLeaveTypeAction?: any;
  GetLeaveTypeByIdAction?: any;
  EditLevelTypeAction?: any;
  deleteLevelTypeAction?: any;
  createLeaveTypeAction?: any;
  leave_type?: ILeaveType;
  leave_types: ILeaveType[];
  user?: userDetails;
  selectOrganization?: any;
  selected_organization_leave?: number;
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
}
export default connect(mapStateToProps, mapDispatchToProps)(LeaveTypesMain);
