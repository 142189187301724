import { Button, Typography, Grid } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { environment } from "../../../environment";
import { userDetails } from "../../../models/userModels";
const ImageUploader = ({
  documents,
  fileHandleEvent,
  imageChange,
  type,
  downloadDoc,
  edit_candidate,
  edit_flag,
  user,
  id2
}: ImageUploaderPorps) => {
  return (
    <>
      {!documents.find(
        (m: any) => m.document_type == type && !!m.document_image
      ) ? (
        <>
          {(!!id2 && +id2 == 1
            ? true
            : !!user &&
              environment.global_veriable.superAdminLevel.includes(
                user.designation
              )
            ? !edit_flag
            : edit_candidate) && (
            <Button
              variant="outlined"
              component="label"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              <input
                hidden
                accept="image/jpeg, application/pdf"
                type="file"
                onChange={(event: any) => fileHandleEvent(event, type)}
              />

              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Upload file
              </Typography>
            </Button>
          )}
        </>
      ) : (
        <Grid
          container
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid>
            <CheckCircleIcon color="success" />
          </Grid>
          <Grid wrap="nowrap">
            {" "}
            <Typography style={{ color: "#99A09C", fontWeight: "700" }}>
              Successfully Uploaded
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={() => downloadDoc(type)} variant="text">
              Download
            </Button>
            {(!!id2 && +id2 == 1
              ? true
              : !!user &&
                environment.global_veriable.superAdminLevel.includes(
                  user.designation
                )
              ? !edit_flag
              : edit_candidate) && (
              <Button onClick={() => imageChange(type)} variant="text">
                Change
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ImageUploader;

interface ImageUploaderPorps {
  imageChange?: any;
  fileHandleEvent?: any;
  documents: any[];
  type: string;
  downloadDoc: any;
  edit_candidate: boolean;
  edit_flag?: boolean;
  user?: userDetails;
  id2?: number;
}
