import { stat } from "fs";
import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AllPostList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeletePostAction,
  getAllPostAction,
} from "../../../stores/actions/organizationActions";
import PostMainView from "./PostMainView";

function PostMain({
  getAllPostAction,
  post_list,
  DeletePostAction,
}: DepartmentMainProps) {
  React.useEffect(() => {
    getAllPostAction();
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const filterData = (props: any) => {
    console.log(props);
    
    getAllPostAction({
      post_id: !!props.post_id && props.post_id != "" ? props.post_id : null,
    });
  };
  const Clear = () => {
    getAllPostAction();
  };
  const DeleteAction = (props: number) => {
    DeletePostAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <PostMainView
      post_list={post_list}
      filterData={filterData}
      Clear={Clear}
      DeleteAction={DeleteAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    post_list: state.organization.post_list,
  };
};
const mapDispatchToProps = {
  getAllPostAction,
  DeletePostAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostMain);

interface DepartmentMainProps {
  getAllPostAction?: any;
  GetAllDepartmentAction?: any;
  post_list: AllPostList[];
  DeletePostAction?: any;
}
