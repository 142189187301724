import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  PendingApprovalList,
} from "../../../models/attendanceModel";
import moment from "moment";
import { FilterList } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import "../AttendanceMain/Attendance.css";
import { ProjectList } from "../../../models/customerModel";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { CheckActionStatusPayload } from "../../../models/employeeModel";
import ActionStatusPopover from "../../employment/ActionStatusPopover";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";

const UploadedAttendanceView = ({
  organization_list,
  Submit,
  attendance_dashboard,
  attendance_approve_list,
  AttendanceDetailPayloadSuccessAction,
  project_list,
  employee_type_list,
  user,
}: UploadedAttendanceViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  useEffect(() => {
    if (!!user) {
      if (
        !environment.global_veriable.ho_employees_type_id.includes(
          +user.employee_detail.employee_type_id
        ) ||
        environment.global_veriable.managerLevelDeg.includes(user.designation)
      ) {
        setValue("organization_id", +user.employee_detail.organization_id);
      }
      if (
        !environment.global_veriable.officialEmployee.includes(user.designation)
      ) {
        setValue("employee_type_id", user.employee_detail.employee_type_id);
        if (user.project_detail.length >= 1) {
          setValue("project_id", user.project_detail[0].project_id);
        }
      }
    }
  }, [user]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const column1: any[] = [
    "Project",
    "Month",
    "Total Employee",
    "Uploaded Attendance",
    "Created By",
    "Created On",
    "Status",
    "Action",
  ];
  // console.log("attendance dashboard : ", attendance_dashboard);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<AttendanceApprovalList[]>([]);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!attendance_approve_list
      ? attendance_approve_list.filter((row: AttendanceApprovalList) => {
        return (
          row?.project_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.created_by
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          moment(row?.month)
            .format("MMMM")
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!attendance_approve_list) {
      cancelSearch();
    }
  }, [attendance_approve_list]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  // const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  // const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const onSubmit = (data: any) => {
    if (!!data.batch_id) {
      setOpenDetails(true);
      Submit({ ...data });
      setExpanded(false);
    } else {
      data.year = moment(data.month).format("YYYY").toString();
      data.month = +moment(data.month).format("MM");
      let temp: any = data.year + "-" + data.month;
      delete data.year;
      setOpenDetails(true);
      Submit({ ...data, month: temp });
      setExpanded(false);
      // console.log(data);
    }
  };
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const [popoverPayload, SetpopoverPayload] = useState<
    CheckActionStatusPayload | undefined
  >(undefined);
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                {!!user &&
                  environment.global_veriable.officialEmployee.includes(
                    user.designation
                  ) && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Organization
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.25} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"organization_id"}
                            // defaultValue={`${
                            //   attendance_dashboard?.organization_id
                            //     ? attendance_dashboard.organization_id
                            //     : ""
                            // }`}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={organization_list}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"organization_id"}
                                label={"organization_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Search Organization"}
                                value={!!value ? value : ""}
                                errors={!!errors["organization_id"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {!!user &&
                  environment.global_veriable.ho_employees_type_id.includes(
                    user.employee_detail.employee_type_id
                  ) &&
                  !!watch("organization_id") && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Employee Type
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.3} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"employee_type_id"}
                            // defaultValue={`${
                            //   attendance_dashboard?.employee_type_id
                            //     ? attendance_dashboard.employee_type_id
                            //     : ""
                            // }`}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={employee_type_list.filter(
                                  (m) =>
                                    m.org_id == +watch("organization_id") &&
                                    ((environment.global_veriable.managerLevelDeg.includes(
                                      user.designation
                                    ) &&
                                      !environment.global_veriable.ho_employees_type_id.includes(
                                        m.employee_type_id
                                      )) ||
                                      !environment.global_veriable.managerLevelDeg.includes(
                                        user.designation
                                      ))
                                )}
                                // option={employee_type_list.filter(
                                //   (t) =>
                                //     t.org_id == +watch("organization_id") &&
                                //     (empType.length <= 0 ||
                                //       !!empType.includes(t.employee_type_id))
                                // )}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"employee_type_id"}
                                label={"employee_type_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), setValue("project_id", null)
                                )}
                                placeholder={"Search Employee Type"}
                                value={!!value ? value : ""}
                                errors={!!errors["employee_type_id"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {!!user &&
                  !!watch("employee_type_id") &&
                  environment.global_veriable.ho_employees_type_id.includes(
                    user.employee_detail.employee_type_id
                  ) &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Project Name
                            <span style={{ color: "red", fontSize: 14 }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5.25} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"project_id"}
                            // defaultValue={`${
                            //   attendance_dashboard?.project_id
                            //     ? attendance_dashboard.project_id
                            //     : ""
                            // }`}
                            rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={project_list}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"project_id"}
                                label={"project_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Search Project"}
                                value={!!value ? value : ""}
                                errors={!!errors["project_id"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      // sx={{ marginLeft: -1 }}
                      className="datepicker"
                    >
                      {/* <DatePicker
                            value={
                              moment(selectedDate)
                                .format("MMMM")
                                .toUpperCase() +
                              "/" +
                              moment(selectedDate).format("YYYY").toUpperCase()
                            }
                            dateFormat="MM/yyyy"
                            onChange={(date: Date) => setSelectedDate(date)}
                            showMonthYearPicker
                            placeholderText="Please Select Month"
                            selected={selectedDate}
                          /> */}
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            value={value}
                            InputProps={{
                              onBlur: onBlur,
                              size: "small",
                              style: {
                                width: "140%",
                              },
                            }}
                            onChange={
                              (newValue) => onChange(newValue)
                              // PayrollAvailableEmployeeSuccessAction([]),
                              // SetapprovalList([])
                            }
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      {openDetails == true && (
        <Paper
          elevation={3}
          sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
              <TextField
                size="small"
                style={{ width: "80%" }}
                placeholder="Search..."
                id="fullWidth"
                value={searched}
                onChange={(e: any) => (
                  requestSearch(e.target.value), setSearched(e.target.value)
                )}
                InputProps={{
                  endAdornment:
                    !!searched && searched.length > 0 ? (
                      <IconButton
                        color="primary"
                        onClick={() => cancelSearch()}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton color="primary">
                        <SearchIcon />
                      </IconButton>
                    ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction={"row"}>
            <Grid item xs={12} padding={2}>
              <ActionStatusPopover
                open={openStatus}
                setOpen={SetopenStatus}
                payload={popoverPayload}
              />
              <Grid
                container
                direction={"row"}
                width={"100%"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                      "& .MuiTableRow-root:hover": {
                        // backgroundColor: "#73c6ff",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {column1.map((column) => (
                          <StyledTableCell align="justify" key={column}>
                            {column}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!rows &&
                        rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.project_attendence_id}
                            >
                              {/* <StyledTableCell align="left" key={column1[0]}>
                                {row.batch_id}
                              </StyledTableCell> */}
                              <StyledTableCell align="left" key={column1[0]}>
                                {row.project_name}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left" key={column1[1]}>
                                {row.division_name}
                              </StyledTableCell> */}
                              <StyledTableCell align="left" key={column1[2]}>
                                {row.month
                                  ? moment(row.month)
                                    .format("MMMM ")
                                    .toUpperCase() +
                                  "'" +
                                  moment(row.month).format("YY").toUpperCase()
                                  : "N/A"}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left" key={column1[3]}>
                                {row.attendence_salary_type}
                              </StyledTableCell> */}
                              <StyledTableCell align="center" key={column1[4]}>
                                {row.total_employee_count}
                              </StyledTableCell>
                              <StyledTableCell align="center" key={column1[5]}>
                                {row.uploaded_employee_count}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[6]}>
                                <Link
                                  onClick={() =>
                                    navigate(
                                      `/employment/manage-profile/${row.created_by}`
                                    )
                                  }
                                >
                                  {row.created_by}
                                </Link>
                                <br />({row.initiated_employee_name})
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[7]}>
                                {row.created_at
                                  ? moment(row.created_at)
                                    .format("DD/MM/YYYY")
                                    .toUpperCase()
                                  : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[8]}>
                                {row.attendence_status}
                              </StyledTableCell>
                              <StyledTableCell align="left" key={column1[9]}>
                                <Tooltip
                                  title="View"
                                  onClick={() => (
                                    AttendanceDetailPayloadSuccessAction({
                                      attendence_salary_type_id:
                                        row.attendence_salary_type_id,
                                      organization_id: row?.organization_id,
                                      employee_type_id: row.employee_type_id,
                                      project_id: row?.project_id,
                                      project_attendence_id:
                                        row.project_attendence_id,
                                      page_index: 0,
                                      page_size: 2,
                                      // editable: 0
                                    }),
                                    navigate(`/attendance/attendance-details/0`)
                                  )}
                                >
                                  <IconButton>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Show Approve Status"
                                  onClick={() => (
                                    SetpopoverPayload({
                                      employeeId: row.project_attendence_id,
                                      employeeTypeId: row.employee_type_id,
                                      eventType: 7,
                                    }),
                                    SetopenStatus(true)
                                  )}
                                >
                                  <IconButton>
                                    <ContentPasteSearchIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  style={styles}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  //count={20}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default UploadedAttendanceView;

interface UploadedAttendanceViewProps {
  organization_list: OrganizationALlList[];
  Submit?: any;
  attendance_approve_list: AttendanceApprovalList[];
  AttendanceDetailPayloadSuccessAction?: any;
  attendance_dashboard?: AttendanceDashboardView[];
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  user?: userDetails;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
