import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Paper,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { style } from "@mui/system";
import React, { useEffect, useState } from "react";
import { DomainList } from "../../../models/organizationModels";
import { EachComponent } from "./AddSalarystructureView";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const CalculationRule = ({
  Setcalculation,
  calculationflag,
  calculation_type,
  operator,
  component_master,
  global_key,
  operator_tye,
  Submit,
  calculationObj,
}: CalculationProps) => {
  const [calculationRule, SetCalciulationRule] = useState<
    CalculationJson | undefined
  >({
    key1: {
      type: 0,
      value: undefined,
    },
    key2: {
      type: 0,
      value: undefined,
    },
    operator: {
      type: 0,
      value: undefined,
    },
    level: 1,
  });
  const [level, Setlevel] = useState<number>(0);
  useEffect(() => {
    if (!!calculationObj) {
      SetCalciulationRule(calculationObj);
    } else {
      SetCalciulationRule({
        key1: {
          type: 0,
          value: undefined,
        },
        key2: {
          type: 0,
          value: undefined,
        },
        operator: {
          type: 0,
          value: undefined,
        },
        level: 1,
      });
    }
  }, [calculationObj, calculationflag]);
  useEffect(() => {
    if (!!calculationRule) {
      Setlevel(getLevel(calculationRule, 0));
    }
  }, [calculationRule]);
  const getLevel = (jes: any, lev: number): any => {
    return jes.key2?.type == 3 ? getLevel(jes.key2.value, lev + 1) : lev + 1;
  };
  const setValue = (lev: number, key: string, field: string, value: any) => {
    let js: CalculationJson | undefined = JSON.parse(
      JSON.stringify(calculationRule)
    );
    let target: any = js;
    for (let i = 1; i <= lev; i++) {
      if (i == lev) {
        target = target;
      } else {
        target = target?.key2?.value;
      }
    }
    target[key][field] = value;
    if (key == "key2" && field == "type" && value == 3) {
      target[key]["value"] = {
        key1: {
          type: 0,
          value: undefined,
        },
        key2: {
          type: 0,
          value: undefined,
        },
        operator: {
          type: 0,
          value: undefined,
        },
        level: 1,
      };
    }

    SetCalciulationRule(js);
  };
  const getValue = (lev: number, key: string, field: string) => {
    let js: CalculationJson | undefined = JSON.parse(
      JSON.stringify(calculationRule)
    );
    let target: any = js;
    for (let i = 1; i <= lev; i++) {
      if (i == lev) {
        target = target;
      } else {
        target = target?.key2?.value;
      }
    }
    return !!target && !!target[key] && !!target[key][field]
      ? target[key][field]
      : "";
  };
  return (
    <Modal
      open={calculationflag}
      onClose={() => Setcalculation(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "70%",
          height: "50%",
          backgroundColor: "#ffff",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
          paddingBottom: 5,
        }}
      >
        <Grid
          container
          style={{
            backgroundColor: "#0060FF",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography style={{ padding: 5, fontWeight: 500, color: "#ffff" }}>
            Create Rule
          </Typography>
        </Grid>
        <div
          style={{
            overflowX: "auto",
            overflowY: "auto",
            height: "85%",
          }}
        >
          {new Array(level).fill(1).map((item, index) => (
            <CalculationComponent
              key={index}
              level={index + 1}
              calculation_type={calculation_type}
              operator={operator}
              component_master={component_master}
              setValue={setValue}
              global_key={global_key}
              operator_tye={operator_tye}
              getValue={getValue}
              calculationObj={calculationObj}
            />
          ))}
        </div>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignSelf={"center"}
          marginTop={1}
        >
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 2,
              marginRight: 2,
            }}
            onClick={() => Setcalculation(false)}
            variant="contained"
            color="primary"
            size="large"
          >
            Close
          </Button>
          <Button
            sx={{
              color: "#fff",
              // backgroundColor: "#1565C0",
              paddingX: 2,
              marginRight: 2,
            }}
            onClick={() => (Submit(calculationRule), Setcalculation(false))}
            variant="contained"
            color="success"
            size="large"
          >
            Apply Rule
          </Button>
          <Button
            sx={{
              color: "#fff",
              // backgroundColor: "#1565C0",
              paddingX: 2,
              marginRight: 2,
            }}
            onClick={() => (Submit(undefined), Setcalculation(false))}
            variant="contained"
            color="error"
            size="large"
          >
            Remove Rule
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CalculationRule;

interface CalculationProps {
  calculationflag: boolean;
  Setcalculation: any;
  calculation_type: DomainList[];
  operator: DomainList[];
  component_master: EachComponent[];
  operator_tye: DomainList[];
  global_key: DomainList[];
  Submit?: any;
  calculationObj?: any;
}

const CalculationComponent = ({
  calculation_type,
  operator,
  component_master,
  setValue,
  level,
  global_key,
  operator_tye,
  getValue,
  calculationObj,
}: CalculationComponentPorps) => {
  const [key1, Setkey] = useState<number | undefined>();
  const [operators, Setoperator] = useState<number | undefined>();
  const [key2, Setkey2] = useState<number | undefined>();
  useEffect(() => {
    if (!!calculationObj) {
      Setkey(getValue(level, "key1", "type"));
      Setkey2(getValue(level, "key2", "type"));
      Setoperator(getValue(level, "operator", "type"));
    } else {
      Setkey(undefined);
      Setkey2(undefined);
      Setoperator(undefined);
    }
  }, [calculationObj]);

  return (
    <div style={{ marginLeft: (level - 1) * 10, display: "flex",  justifyContent:"center", alignItems:"center" }}>
      <Paper
        sx={{
          marginX: 1,
          marginTop: 2,
          padding: 0.5,
          width: 500,
          bacjgroundColor: "#F1F3F4",
          border: "1px solid #1565C0",
        }}
        elevation={5}
      >
        <Grid container direction={"row"} width={"100%"}>
          <Grid item xs={4} style={{ backgroundColor: "#c9ffee", padding: 5 }}>
            <Typography style={{ fontSize: 14, textAlign: "center" }}>
              Component 1
            </Typography>
            <Select
              style={{ width: "100%" }}
              value={getValue(level, "key1", "type")}
              size="small"
              onChange={(value) => (
                Setkey(+value.target.value),
                setValue(level, "key1", "type", value.target.value)
              )}
            >
              {calculation_type.map(
                (m) =>
                  m.domain_value != 3 && (
                    <MenuItem key={m.domain_id} value={m.domain_value}>
                      {m.domain_key}
                    </MenuItem>
                  )
              )}
            </Select>
            {key1 == 4 && (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                inputProps={{
                  style: {
                    height: "22px",
                  },
                }}
                size="small"
                value={getValue(level, "key1", "value")}
                // label={"Department Name"}
                placeholder={"Value"}
                onChange={(text) =>
                  setValue(level, "key1", "value", text.target.value)
                }
              />
            )}
            {key1 == 1 && (
              <Select
                style={{ width: "100%" }}
                value={getValue(level, "key1", "value")}
                size="small"
                onChange={(text) =>
                  setValue(level, "key1", "value", text.target.value)
                }
              >
                {component_master.map((m) => (
                  <MenuItem key={m.compent_id} value={m.compent_id}>
                    {m.component_name} ({m.component_type_name})
                  </MenuItem>
                ))}
              </Select>
            )}
            {key1 == 2 && (
              <Select
                style={{ width: "100%" }}
                value={getValue(level, "key1", "value")}
                size="small"
                onChange={(text) =>
                  setValue(level, "key1", "value", text.target.value)
                }
              >
                {global_key.map((m) => (
                  <MenuItem key={m.domain_id} value={m.domain_value}>
                    {m.domain_key}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid item xs={4} style={{ backgroundColor: "#cdecfa", padding: 5 }}>
            <Typography style={{ fontSize: 14, textAlign: "center" }}>
              Operator
            </Typography>
            <Select
              style={{ width: "100%" }}
              value={getValue(level, "operator", "type")}
              size="small"
              onChange={(value) => (
                Setoperator(+value.target.value),
                setValue(level, "operator", "type", value.target.value)
              )}
            >
              {operator_tye.map((m) => (
                <MenuItem key={m.domain_id} value={m.domain_value}>
                  {m.domain_key}
                </MenuItem>
              ))}
            </Select>
            {operators == 1 && (
              <Select
                style={{ width: "100%" }}
                size="small"
                value={getValue(level, "operator", "value")}
                onChange={(value) =>
                  setValue(level, "operator", "value", value.target.value)
                }
              >
                {operator.map((m) => (
                  <MenuItem key={m.domain_id} value={m.domain_value}>
                    {m.domain_key}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid item xs={4} style={{ backgroundColor: "#c9ffee", padding: 5 }}>
            <Typography style={{ fontSize: 14, textAlign: "center" }}>
              Component 2
            </Typography>
            <Select
              style={{ width: "100%" }}
              value={getValue(level, "key2", "type")}
              size="small"
              onChange={(value) => (
                Setkey2(+value.target.value),
                setValue(level, "key2", "type", value.target.value)
              )}
            >
              {calculation_type.map((m) => (
                <MenuItem key={m.domain_id} value={m.domain_value}>
                  {m.domain_key}
                </MenuItem>
              ))}
            </Select>
            {key2 == 4 && (
              <TextField
                type={"text"}
                id="standard-required"
                variant="outlined"
                className={"smaller"}
                style={{ width: "100.9%" }}
                size="small"
                inputProps={{
                  style: {
                    height: "22px",
                  },
                }}
                value={getValue(level, "key2", "value")}
                // label={"Department Name"}
                placeholder={"Value"}
                onChange={(text) =>
                  setValue(level, "key2", "value", text.target.value)
                }
              />
            )}
            {key2 == 1 && (
              <Select
                style={{ width: "100%" }}
                value={getValue(level, "key2", "value")}
                size="small"
                onChange={(text) =>
                  setValue(level, "key2", "value", text.target.value)
                }
              >
                {component_master.map((m) => (
                  <MenuItem key={m.compent_id} value={m.compent_id}>
                    {m.component_name} ({m.component_type_name})
                  </MenuItem>
                ))}
              </Select>
            )}
            {key2 == 2 && (
              <Select
                style={{ width: "100%" }}
                value={getValue(level, "key2", "value")}
                size="small"
                onChange={(text) =>
                  setValue(level, "key2", "value", text.target.value)
                }
              >
                {global_key.map((m) => (
                  <MenuItem key={m.domain_id} value={m.domain_value}>
                    {m.domain_key}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

interface CalculationComponentPorps {
  calculation_type: DomainList[];
  operator: DomainList[];
  component_master: EachComponent[];
  setValue?: any;
  level: number;
  operator_tye: DomainList[];
  global_key: DomainList[];
  getValue?: any;
  calculationObj?: any;
}

export interface CalculationJson {
  key1: CalculationJsonCom | undefined;
  operator: CalculationJsonCom | undefined;
  key2: CalculationJsonCom | undefined;
  level: number;
}
interface CalculationJsonCom {
  type: number;
  value: string | CalculationJson | undefined;
}
