import { Grid, Typography, Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { BatchList } from "../../../models/payrollModel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const BatchTopCard = ({ batch, onDownloadPDF }: BatchTopCardPorps) => {
  return (
    <Grid
      container
      direction={"row"}
      padding={5}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Payment Payroll No.
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.batch_id}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Month</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!batch?.month &&
                moment(`${batch.month}-01-${batch.year} 00:00:00`).format(
                  "MMMM/YYYY"
                )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Project</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.project_name}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Division</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.division_name}</Typography>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Total Number Of Employee
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.emp_count}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Target Disbursement Date
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {!!batch?.expected_disburesment_date
                ? moment(batch?.expected_disburesment_date).format("DD/MM/YYYY")
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Total Income</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <span
                style={{
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  color: "#008060",
                }}
              >
                &#8377; {batch?.total_income}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Total Deductions
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <span
                style={{
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  color: "#b32d00",
                }}
              >
                &#8377; {batch?.total_deduction}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>
              Total Contibution
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <span
                style={{
                  fontFamily: "Arial",
                  fontWeight: "bold",
                }}
              >
                &#8377; {batch?.total_contribution}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Net Payable</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <span
                style={{
                  fontFamily: "Arial",
                  fontWeight: "bold",
                  color: "#008060",
                }}
              >
                &#8377; {batch?.net_payable}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Created By</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.created_by}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Created On</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>
              {moment(batch?.created_on).format("DD/MM/YYYY HH:mm:ss a")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          <Grid xs={5}>
            <Typography style={{ fontWeight: 700 }}>Status</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid xs={5}>
            <Typography>{batch?.batch_status_name}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6} style={{ padding: 5 }}>
        <Grid container direction={"row"}>
          
          <Grid xs={5}>
            <Button
              // onClick={() => navigate("/organization/add-department/0")}
              onClick={onDownloadPDF}
              size="medium"
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
            >
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BatchTopCard;

interface BatchTopCardPorps {
  batch?: BatchList;
  onDownloadPDF: any;
}
