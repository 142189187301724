import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateEmployeeDesignationAction,
  EditDesignationAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import AddDesignationView from "./AddDesignationView";

const AddDesignation = ({
  GetAllOrganizationAction,
  organization_list,
  CreateEmployeeDesignationAction,
  designation_list,
  EditDesignationAction
}: AddDesignationProps) => {
  React.useEffect(() => {
    GetAllOrganizationAction();
  }, []);
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const SubmitUpdatedData = (data: any) => {
    EditDesignationAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const { id } = useParams();
  const Submit = (data: any) => {
    data.org_id = data.organization_id;
    CreateEmployeeDesignationAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <AddDesignationView
      designation={designation_list.find(
        (m) => !!id && m.designation_id == +id
      )}
      organization_list={organization_list}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    designation_list: state.organization.designation_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  CreateEmployeeDesignationAction,
  EditDesignationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDesignation);
interface AddDesignationProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  CreateEmployeeDesignationAction?: any;
  designation_list: AllDesignationList[];
  EditDesignationAction?:any;
}
