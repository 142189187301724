import { Grid, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "rgb(19, 36, 55)" }}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography style={{ color: "#ffff", padding: 4, fontSize: 14 }}>
            <a href="https://www.msqube.com/" style={{ color: "#ffff" }}>
              MSQUBE Technology Solutions Pvt. Ltd.
            </a>
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "end" }}>
          <Typography style={{ color: "#ffff", padding: 4, fontSize: 14 }}>
            Developed by{" "}
            <a style={{ color: "#ffff" }} href="https://www.msqube.com/">
              MSQube
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
