import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  Box,
  CardHeader,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Popover,
  Backdrop,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  ButtonGroup,
  SwipeableDrawer,
  Skeleton,
  Modal,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useForm, Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import {
  DomainList,
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { CustomerList, ProjectList } from "../../../models/customerModel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Attendance.css";
import { baseUrl, environment } from "../../../environment";
import Axios from "axios";
import { useSnackbar } from "notistack";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  // Division,
  UploadErrorResponse,
} from "../../../models/attendanceModel";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { userDetails } from "../../../models/userModels";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import colorGenerator from "../../../utility/colorGenerator";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ProjectWiseCardView from "./ProjectWiseCardView";
const FileDownload = require("js-file-download");

const AttendanceMainView = ({
  organization_list,
  employee_type_list,
  customer_list,
  project_list,
  accessToken,
  Submit,
  attendance_dashboard,
  user,
  attendence_salary_type,
  ExcelDetailsView,
  attendance_approve_list,
  historyDetails,
  CreateCandidateSuccessAction,
  AttendanceDetailPayloadSuccessAction,
}: AttendanceMainViewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [openUpload, setOpenUpload] = React.useState<boolean>(false);
  // const [openDownload, setDownload] = React.useState<boolean>(false);
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const [documents, SetDocument] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<any>(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (data: any) => {
    data.year = moment(data.month).format("YYYY").toString();
    data.month = +moment(data.month).format("MM");
    let temp: any = data.year + "-" + data.month;
    delete data.year;
    setOpenDetails(true);
    // console.log("data", data, "temp", data.month);
    Submit({ ...data, month: temp });
    setExpanded(false);
  };

  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  useEffect(() => {
    if (!!attendance_dashboard) {
      cancelSearch();
    }
  }, [attendance_dashboard]);
  useEffect(() => {
    if (!!attendance_dashboard) {
      setRows(attendance_dashboard);
    }
  }, [attendance_dashboard]);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<AttendanceDashboardView[]>([]);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!attendance_dashboard
      ? attendance_dashboard.filter((k) => {
        return k.project_name
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      })
      : [];
    setRows(filteredRows);
  };
  const [errorUploaded, seterrorUploaded] = useState<UploadErrorResponse[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);
  const [empType, setempType] = useState<number[]>([]);
  const [attendence_status, setAttendanceStatus] = useState<string[]>([
    "UPLOADED",
    "APPROVED",
  ]);
  const DetailExcelView = (data: any, k: any) => {
    // console.log("k",k, "data",data);

    ExcelDetailsView({
      // attendence_salary_type_id: data,
      organization_id:
        !!attendance_dashboard &&
        attendance_dashboard.find((n) => n.organization_id)?.organization_id,
      employee_type_id:
        !!attendance_dashboard &&
        attendance_dashboard.find((e) => e.employee_type_id)?.employee_type_id,
      project_id: data,
      attendence_status: attendence_status,
      month:
        moment(selectedDate).format("YYYY").toString() +
        "-" +
        moment(selectedDate).format("MM"),
      page_index: 0,
      page_size: 2,
    });
  };
  const handleErrorModalClose = () => {
    seterrorModal(false);
  };
  const columns: any[] = ["Employee No.", "Message"];
  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                        }}
                      >
                        Organization
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"organization_id"}
                        // defaultValue={`${
                        //   attendance_dashboard?.organization_id
                        //     ? attendance_dashboard.organization_id
                        //     : ""
                        // }`}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={organization_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"organization_id"}
                            label={"organization_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Organization"}
                            value={!!value ? value : ""}
                            errors={!!errors["organization_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!!watch("organization_id") && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                          <span style={{ color: "red", fontSize: 14 }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          // defaultValue={`${
                          //   attendance_dashboard?.employee_type_id
                          //     ? attendance_dashboard.employee_type_id
                          //     : ""
                          // }`}
                          rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list.filter(
                                (t) =>
                                  t.org_id == +watch("organization_id") &&
                                  (empType.length <= 0 ||
                                    !!empType.includes(t.employee_type_id))
                              )}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value), setValue("project_id", null)
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                              errors={!!errors["employee_type_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ marginLeft: -1 }}
                      className="datepicker"
                    >
                      {/* <DatePicker
                        value={
                          moment(selectedDate).format("MMMM").toUpperCase() +
                          "/" +
                          moment(selectedDate).format("YYYY").toUpperCase()
                        }
                        dateFormat="MM/yyyy"
                        onChange={(date: Date) => setSelectedDate(date)}
                        showMonthYearPicker
                        placeholderText="Please Select Month"
                        selected={selectedDate}
                      /> */}
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            value={value}
                            InputProps={{
                              onBlur: onBlur,
                              size: "small",
                              sx: {
                                width: "132%",
                              },
                            }}
                            onChange={
                              (newValue) => (
                                onChange(newValue), setSelectedDate(newValue)
                              )
                              // PayrollAvailableEmployeeSuccessAction([]),
                              // SetapprovalList([])
                            }
                            renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      {openDetails == true && (
        <Paper
          elevation={3}
          sx={{ marginTop: 2, marginInline: 5, paddingBottom: 5 }}
        >
          <>
            <Grid
              container
              direction={"row"}
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"space-evenly"}
              style={{ padding: 20, margin: 10 }}
            >
              {!!rows &&
                !!attendance_dashboard &&
                rows.map((item, index) => (
                  <ProjectWiseCardView
                    // handleClickOpenDownload={handleClickOpenDownload}
                    attendance_dashboard={attendance_dashboard}
                    item={item}
                    key={index}
                    attendence_salary_type={attendence_salary_type}
                    // fileHandleEvent={fileHandleEvent}
                    DetailExcelView={DetailExcelView}
                    attendance_approve_list={attendance_approve_list}
                    historyDetails={historyDetails}
                    AttendanceDetailPayloadSuccessAction={
                      AttendanceDetailPayloadSuccessAction
                    }
                    setAttendanceStatus={setAttendanceStatus}
                  />
                ))}
            </Grid>
          </>
        </Paper>
      )}

      <Modal
        open={errorModal}
        onClose={handleErrorModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper
          elevation={2}
          sx={{
            marginTop: 10,
            marginInline: 15,
            marginBottom: 10,
            minWidth: "50%",
            minHeight: "40%",
            // position: "absolute",
            // marginLeft: `calc(40% - ${Card}px)`,
          }}
        >
          <Grid
            container
            direction={"column"}
            display={"flex"}
          // justifyContent={"center"}
          //style={{ padding: 1, margin: 2 }}
          >
            <Grid
              container
              style={{
                padding: 10,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Grid item>
                <Typography style={{ color: "#fff" }}>Error Details</Typography>
              </Grid>
            </Grid>
            <Grid item xs={8} sx={{ padding: 1, paddingBottom: 4 }}>
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {columns.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!errorUploaded &&
                      errorUploaded.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.row_no}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {row.employee_id}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            {row.message}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => handleErrorModalClose()}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default AttendanceMainView;
interface AttendanceMainViewProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  customer_list: CustomerList[];
  project_list: ProjectList[];
  accessToken?: any;
  Submit?: any;
  attendance_dashboard?: AttendanceDashboardView[];
  user?: userDetails;
  attendence_salary_type?: DomainList[];
  ExcelDetailsView?: any;
  attendance_approve_list: AttendanceApprovalList[];
  historyDetails?: any;
  CreateCandidateSuccessAction?: any;
  AttendanceDetailPayloadSuccessAction?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
