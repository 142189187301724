import React from "react";
import { connect } from "react-redux";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
} from "../../../models/attendanceModel";
import { OrganizationALlList } from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  ApproveAttendanceListAction,
  AttendanceDetailPayloadSuccessAction,
} from "../../../stores/actions/attendanceAction";
import { GetAllOrganizationAction } from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import AllAttendanceView from "./AllAttendanceView";

function AllAttendance({
  GetAllOrganizationAction,
  organization_list,
  ApproveAttendanceListAction,
  attendance_approve_list,
  attendance_dashboard,
  AttendanceDetailPayloadSuccessAction,
  SidenavAutoCloseOpen,
}: ALlAttendaceProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  // const Submit = (data: any) => {
  //   ApproveAttendanceListAction({
  //     data,
  //   });
  // };
  // console.log(attendance_approve_list);
  
  const Submit = (data: any) => {    
    ApproveAttendanceListAction({
      ...data,
    });
  };
  return (
    <AllAttendanceView
      organization_list={organization_list}
      attendance_dashboard={attendance_dashboard}
      attendance_approve_list={attendance_approve_list}
      Submit={Submit}
      AttendanceDetailPayloadSuccessAction={
        AttendanceDetailPayloadSuccessAction
      }
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    attendance_approve_list: state.attendance.attendance_approve_list,
    attendance_dashboard: state.attendance.attendance_dashboard,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  ApproveAttendanceListAction,
  AttendanceDetailPayloadSuccessAction,
  SidenavAutoCloseOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllAttendance);

interface ALlAttendaceProps {
  GetAllOrganizationAction?: any;
  ApproveAttendanceListAction?: any;
  organization_list: OrganizationALlList[];
  attendance_approve_list: AttendanceApprovalList[];
  AttendanceDetailPayloadSuccessAction?: any;
  SidenavAutoCloseOpen?: any;
  attendance_dashboard?: AttendanceDashboardView[];
}
