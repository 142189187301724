import React, { FC, useState } from "react";
import {
  Paper,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import styled from "@emotion/styled";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tree, TreeNode } from "react-organizational-chart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ILevelMaster,
  Level,
} from "../../../interfaces/projectDashboard.interface";
import Typography from "@mui/material/Typography/Typography";
import { TreeView, TreeItem } from "@mui/lab";

const HierachyModal: FC<IHierachyModal> = ({
  levelDetails,
  levelMaster,
  levelDialogOpen,
  handleDialogOpenForLevelHierarchy,
}) => {
  const [tabValue, setTabValue] = useState<string>("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const [expanded, setExpanded] = useState<string[]>([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        : []
    );
  };
  return (
    <Dialog
      open={levelDialogOpen}
      onClose={handleDialogOpenForLevelHierarchy}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="hierarchy"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", color: "#1565C0" }}
      >
        Location Hierarchy
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TabContext value={tabValue}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="tree-view"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {levelDetails.map((self, index: number) => (
                  <Tab
                    key={index}
                    label={`${self.level1_name}`}
                    value={`${index + 1}`}
                  />
                ))}
              </TabList>
            </Box>
            {levelDetails
              .filter((self) => !!self)
              .map((levelOne, l1: number) => (
                <TabPanel value={`${l1 + 1}`}>
                  <Box display="flex">
                    <TreeView
                      aria-label="controlled"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      expanded={expanded}
                      onNodeToggle={handleToggle}
                      multiSelect
                    >
                      <TreeItem
                        nodeId="1"
                        label={`${levelOne.level1_name} (${
                          levelMaster ? levelMaster?.level1_key_name : ""
                        }) `}
                      >
                        {!!levelOne?.level_two &&
                        Array.isArray(levelOne.level_two) &&
                        levelOne.level_two.length ? (
                          levelOne.level_two
                            .filter((self) => !!self)
                            .map((levelTwo, l2: number) => {
                              if (
                                !levelTwo?.level_three ||
                                levelTwo.level_three.length === 0
                              )
                                return (
                                  <>
                                    <TreeItem
                                      nodeId="2"
                                      label={`${levelTwo.level2_name} (${
                                        levelMaster
                                          ? levelMaster?.level2_key_name
                                          : ""
                                      })`}
                                    />
                                  </>
                                );
                              return (
                                <>
                                  <TreeItem
                                    nodeId="2"
                                    label={`${levelTwo.level2_name} (${
                                      levelMaster
                                        ? levelMaster?.level2_key_name
                                        : ""
                                    }) `}
                                  >
                                    {!!levelTwo?.level_three &&
                                    Array.isArray(levelTwo.level_three) ? (
                                      levelTwo.level_three
                                        .filter((self) => !!self)
                                        .map((levelThree, l3: number) => {
                                          if (
                                            !levelThree?.level_four ||
                                            levelThree.level_four.length === 0
                                          )
                                            return (
                                              <>
                                                <TreeItem
                                                  nodeId="3"
                                                  label={`${
                                                    levelThree.level3_name
                                                  } (${
                                                    levelMaster
                                                      ? levelMaster?.level3_key_name
                                                      : ""
                                                  })`}
                                                />
                                              </>
                                            );
                                          return (
                                            <TreeItem
                                              key={l3}
                                              nodeId="3"
                                              label={`${
                                                levelThree.level3_name
                                              } (${
                                                levelMaster
                                                  ? levelMaster?.level3_key_name
                                                  : ""
                                              }) `}
                                            >
                                              {!!levelThree?.level_four &&
                                              Array.isArray(
                                                levelThree.level_four
                                              ) ? (
                                                levelThree.level_four
                                                  .filter((self) => !!self)
                                                  .map(
                                                    (levelFour, l4: number) => {
                                                      if (
                                                        !levelThree?.level_four ||
                                                        levelThree.level_four
                                                          .length === 0
                                                      )
                                                        return (
                                                          <TreeItem
                                                            key={l4}
                                                            nodeId="4"
                                                            label={`${
                                                              levelFour.level4_name
                                                            } (${
                                                              levelMaster
                                                                ? levelMaster?.level4_key_name
                                                                : ""
                                                            }) `}
                                                          />
                                                        );
                                                      return (
                                                        <TreeItem
                                                          key={l4}
                                                          nodeId="4"
                                                          label={`${
                                                            levelFour.level4_name
                                                          } (${
                                                            levelMaster
                                                              ? levelMaster?.level4_key_name
                                                              : ""
                                                          })`}
                                                        >
                                                          {!!levelFour?.level_five &&
                                                          Array.isArray(
                                                            levelFour.level_five
                                                          ) ? (
                                                            levelFour.level_five
                                                              .filter(
                                                                (self) => !!self
                                                              )
                                                              .map(
                                                                (
                                                                  levelFive,
                                                                  l5: number
                                                                ) => (
                                                                  <TreeItem
                                                                    key={l5}
                                                                    nodeId="5"
                                                                    label={`${
                                                                      levelFive.level5_name
                                                                    } (${
                                                                      levelMaster
                                                                        ? levelMaster?.level5_key_name
                                                                        : ""
                                                                    })`}
                                                                  />
                                                                )
                                                              )
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </TreeItem>
                                                      );
                                                    }
                                                  )
                                              ) : (
                                                <></>
                                              )}
                                            </TreeItem>
                                          );
                                        })
                                    ) : (
                                      <></>
                                    )}
                                  </TreeItem>
                                </>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </TreeItem>
                    </TreeView>
                  </Box>
                </TabPanel>
              ))}
          </TabContext>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogOpenForLevelHierarchy} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
interface IHierachyModal {
  levelDetails: Level[];
  levelMaster?: ILevelMaster;
  levelDialogOpen: boolean;
  handleDialogOpenForLevelHierarchy: any;
}
export default HierachyModal;
