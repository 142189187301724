import React, { FC, useState } from "react";
import { Paper, Box } from "@mui/material";
import styled from "@emotion/styled";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tree, TreeNode } from "react-organizational-chart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ILevelMaster,
  Level,
} from "../../../interfaces/projectDashboard.interface";
import Typography from "@mui/material/Typography/Typography";
import { TreeView, TreeItem } from "@mui/lab";
const LevelHierachy: FC<ILevelHierarchy> = ({
  tabValue,
  handleTabChange,
  levelDetails,
  levelMaster,
}) => {
  const [expanded, setExpanded] = useState<string[]>([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ]);
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? ["1", "2", "3", "4", "5", "6", "7", "8", "9"]
        : []
    );
  };
  return (
    <Paper
      sx={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
        height: "auto",
        border: "1px solid #42a5f5",
      }}
    >
      <Box bgcolor={"#42a5f5"} py={1}>
        <Typography textAlign="center" color={"#fff"}>
          No. of Employees (Level-wise)
        </Typography>
      </Box>
      <Box p={2}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="tree-view"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {levelDetails.map((self, index: number) => (
                <Tab
                  key={index}
                  label={`${self.level1_name}`}
                  value={`${index + 1}`}
                />
              ))}
            </TabList>
          </Box>
          {levelDetails
            .filter((self) => !!self)
            .map((levelOne, l1: number) => (
              <TabPanel value={`${l1 + 1}`}>
                <Box display="flex">
                  <TreeView
                    aria-label="controlled"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                    multiSelect
                  >
                    <TreeItem
                      nodeId="1"
                      label={
                        <span>
                          <span>{`${levelOne.level1_name} (${levelMaster?.level1_key_name})`}</span>
                          <small style={{ fontWeight: 700 }}>
                            {" "}
                            {levelOne?.total_employees
                              ? `Total Emp : ${levelOne.total_employees}`
                              : ``}{" "}
                          </small>
                        </span>
                      }
                    >
                      {!!levelOne?.level_two &&
                      Array.isArray(levelOne.level_two) &&
                      levelOne.level_two.length ? (
                        levelOne.level_two
                          .filter((self) => !!self)
                          .map((levelTwo, l2: number) => {
                            if (
                              !levelTwo?.level_three ||
                              levelTwo.level_three.length === 0
                            )
                              return (
                                <>
                                  <TreeItem
                                    nodeId="2"
                                    label={
                                      <span>
                                        <span>{`${levelTwo.level2_name} (${levelMaster?.level2_key_name})`}</span>
                                        <small style={{ fontWeight: 700 }}>
                                          {" "}
                                          {levelTwo?.total_employees
                                            ? `Total Emp : ${levelTwo.total_employees}`
                                            : ``}{" "}
                                        </small>
                                      </span>
                                    }
                                  />
                                </>
                              );
                            return (
                              <>
                                <TreeItem
                                  nodeId="2"
                                  label={
                                    <span>
                                      <span>{`${levelTwo.level2_name} (${levelMaster?.level2_key_name})`}</span>
                                      <small style={{ fontWeight: 700 }}>
                                        {" "}
                                        {levelTwo?.total_employees
                                          ? `Total Emp : ${levelTwo.total_employees}`
                                          : ``}{" "}
                                      </small>
                                    </span>
                                  }
                                >
                                  {!!levelTwo?.level_three &&
                                  Array.isArray(levelTwo.level_three) ? (
                                    levelTwo.level_three
                                      .filter((self) => !!self)
                                      .map((levelThree, l3: number) => {
                                        if (
                                          !levelThree?.level_four ||
                                          levelThree.level_four.length === 0
                                        )
                                          return (
                                            <>
                                              <TreeItem
                                                nodeId="3"
                                                label={
                                                  <span>
                                                    <span>{`${levelThree.level3_name} (${levelMaster?.level3_key_name})`}</span>
                                                    <small
                                                      style={{
                                                        fontWeight: 700,
                                                      }}
                                                    >
                                                      {" "}
                                                      {levelThree?.total_employees
                                                        ? `Total Emp : ${levelThree.total_employees}`
                                                        : ``}{" "}
                                                    </small>
                                                  </span>
                                                }
                                              />
                                            </>
                                          );
                                        return (
                                          <TreeItem
                                            key={l3}
                                            nodeId="3"
                                            label={
                                              <span>
                                                <span>{`${levelThree.level3_name} (${levelMaster?.level3_key_name})`}</span>
                                                <small
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  {" "}
                                                  {levelThree?.total_employees
                                                    ? `Total Emp : ${levelThree.total_employees}`
                                                    : ``}{" "}
                                                </small>
                                              </span>
                                            }
                                          >
                                            {!!levelThree?.level_four &&
                                            Array.isArray(
                                              levelThree.level_four
                                            ) ? (
                                              levelThree.level_four
                                                .filter((self) => !!self)
                                                .map(
                                                  (levelFour, l4: number) => {
                                                    if (
                                                      !levelThree?.level_four ||
                                                      levelThree.level_four
                                                        .length === 0
                                                    )
                                                      return (
                                                        <TreeItem
                                                          key={l4}
                                                          nodeId="4"
                                                          label={
                                                            <span>
                                                              <span>{`${levelFour.level4_name} (${levelMaster?.level4_key_name})`}</span>
                                                              <small
                                                                style={{
                                                                  fontWeight: 700,
                                                                }}
                                                              >
                                                                {" "}
                                                                {levelFour?.total_employees
                                                                  ? `Total Emp : ${levelFour.total_employees}`
                                                                  : ``}{" "}
                                                              </small>
                                                            </span>
                                                          }
                                                        />
                                                      );
                                                    return (
                                                      <TreeItem
                                                        key={l4}
                                                        nodeId="4"
                                                        label={
                                                          <span>
                                                            <span>{`${levelFour.level4_name} (${levelMaster?.level4_key_name})`}</span>
                                                            <small
                                                              style={{
                                                                fontWeight: 700,
                                                              }}
                                                            >
                                                              {" "}
                                                              {levelFour?.total_employees
                                                                ? `Total Emp : ${levelFour.total_employees}`
                                                                : ``}{" "}
                                                            </small>
                                                          </span>
                                                        }
                                                      >
                                                        {!!levelFour?.level_five &&
                                                        Array.isArray(
                                                          levelFour.level_five
                                                        ) ? (
                                                          levelFour.level_five
                                                            .filter(
                                                              (self) => !!self
                                                            )
                                                            .map(
                                                              (
                                                                levelFive,
                                                                l5: number
                                                              ) => (
                                                                <TreeItem
                                                                  key={l5}
                                                                  nodeId="5"
                                                                  label={
                                                                    <span>
                                                                      <span>{`${levelFive.level5_name} (${levelMaster?.level5_key_name})`}</span>
                                                                      <small
                                                                        style={{
                                                                          fontWeight: 700,
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {levelFive?.total_employees
                                                                          ? `Total Emp : ${levelFive.total_employees}`
                                                                          : ``}{" "}
                                                                      </small>
                                                                    </span>
                                                                  }
                                                                />
                                                              )
                                                            )
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </TreeItem>
                                                    );
                                                  }
                                                )
                                            ) : (
                                              <></>
                                            )}
                                          </TreeItem>
                                        );
                                      })
                                  ) : (
                                    <></>
                                  )}
                                </TreeItem>
                              </>
                            );
                          })
                      ) : (
                        <></>
                      )}
                    </TreeItem>
                  </TreeView>
                </Box>
                {/* <Tree
                  key={l1}
                  lineWidth={"1px"}
                  lineColor={"#1976d2"}
                  lineBorderRadius={"2rem"}
                  label={
                    <StyledNode>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "#1976d2",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {levelOne.level1_name}{" "}
                        <small style={{ fontSize: "0.7rem" }}>
                          ({levelMaster?.level1_key_name})
                        </small>
                      </span>
                      {!!levelOne?.total_employees ? (
                        <>
                          <small style={{ color: "#1976d2" }}>
                            Total Emp : {levelOne.total_employees}{" "}
                          </small>
                        </>
                      ) : (
                        <></>
                      )}
                    </StyledNode>
                  }
                >
                  {!!levelOne?.level_two &&
                  Array.isArray(levelOne.level_two) &&
                  levelOne.level_two.length ? (
                    levelOne.level_two
                      .filter((self) => !!self)
                      .map((levelTwo, l2: number) => {
                        if (
                          !levelTwo?.level_three ||
                          levelTwo.level_three.length === 0
                        )
                          return (
                            <TreeNode
                              key={l2}
                              label={
                                <StyledNode>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#1976d2",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    {levelTwo.level2_name}
                                    <small style={{ fontSize: "0.7rem" }}>
                                      ({levelMaster?.level2_key_name})
                                    </small>
                                  </span>
                                  {!!levelTwo?.total_employees ? (
                                    <>
                                      <small style={{ color: "#1976d2" }}>
                                        Total Emp : {levelTwo.total_employees}{" "}
                                      </small>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </StyledNode>
                              }
                            />
                          );
                        return (
                          <TreeNode
                            key={l2}
                            label={
                              <StyledNode>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#1976d2",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {levelTwo.level2_name}{" "}
                                  <small style={{ fontSize: "0.7rem" }}>
                                    ({levelMaster?.level2_key_name})
                                  </small>
                                </span>
                                {!!levelTwo?.total_employees ? (
                                  <>
                                    <small style={{ color: "#1976d2" }}>
                                      Total Emp : {levelTwo.total_employees}{" "}
                                    </small>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </StyledNode>
                            }
                          >
                            {!!levelTwo?.level_three &&
                            Array.isArray(levelTwo.level_three) &&
                            levelTwo.level_three.length ? (
                              levelTwo.level_three
                                .filter((self) => !!self)
                                .map((levelThree, l4: number) => {
                                  if (
                                    !levelThree?.level_four ||
                                    levelThree.level_four.length === 0
                                  )
                                    return (
                                      <TreeNode
                                        key={l4}
                                        label={
                                          <StyledNode>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                color: "#1976d2",
                                                margin: 0,
                                                padding: 0,
                                              }}
                                            >
                                              {levelThree.level3_name}{" "}
                                              <small
                                                style={{ fontSize: "0.7rem" }}
                                              >
                                                ({levelMaster?.level3_key_name})
                                              </small>
                                            </span>
                                            {!!levelThree?.total_employees ? (
                                              <>
                                                <small
                                                  style={{ color: "#1976d2" }}
                                                >
                                                  Total Emp :{" "}
                                                  {levelThree.total_employees}{" "}
                                                </small>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </StyledNode>
                                        }
                                      />
                                    );
                                  return (
                                    <TreeNode
                                      key={l4}
                                      label={
                                        <StyledNode>
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              color: "#1976d2",
                                              margin: 0,
                                              padding: 0,
                                            }}
                                          >
                                            {levelThree.level3_name}{" "}
                                            <small
                                              style={{ fontSize: "0.7rem" }}
                                            >
                                              ({levelMaster?.level3_key_name})
                                            </small>
                                          </span>
                                          {!!levelThree?.total_employees ? (
                                            <>
                                              <small
                                                style={{ color: "#1976d2" }}
                                              >
                                                Total Emp :{" "}
                                                {levelThree.total_employees}{" "}
                                              </small>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </StyledNode>
                                      }
                                    >
                                      {!!levelThree?.level_four &&
                                      Array.isArray(levelThree.level_four) &&
                                      levelThree.level_four.length ? (
                                        levelThree.level_four
                                          .filter((self) => !!self)
                                          .map((levelFour, l4: number) => {
                                            if (
                                              !levelFour?.level_five ||
                                              levelFour.level_five.length === 0
                                            )
                                              return (
                                                <TreeNode
                                                  key={l4}
                                                  label={
                                                    <StyledNode>
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                          color: "#1976d2",
                                                          margin: 0,
                                                          padding: 0,
                                                        }}
                                                      >
                                                        {levelFour.level4_name}{" "}
                                                        <small
                                                          style={{
                                                            fontSize: "0.7rem",
                                                          }}
                                                        >
                                                          (
                                                          {
                                                            levelMaster?.level4_key_name
                                                          }
                                                          )
                                                        </small>
                                                      </span>
                                                      {!!levelFour?.total_employees ? (
                                                        <>
                                                          <small
                                                            style={{
                                                              color: "#1976d2",
                                                            }}
                                                          >
                                                            Total Emp :{" "}
                                                            {
                                                              levelFour.total_employees
                                                            }{" "}
                                                          </small>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </StyledNode>
                                                  }
                                                />
                                              );
                                            return (
                                              <TreeNode
                                                key={l4}
                                                label={
                                                  <StyledNode>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        color: "#1976d2",
                                                        margin: 0,
                                                        padding: 0,
                                                      }}
                                                    >
                                                      {levelFour.level4_name}{" "}
                                                      <small
                                                        style={{
                                                          fontSize: "0.7rem",
                                                        }}
                                                      >
                                                        (
                                                        {
                                                          levelMaster?.level4_key_name
                                                        }
                                                        )
                                                      </small>
                                                    </span>
                                                    {!!levelFour?.total_employees ? (
                                                      <>
                                                        <small
                                                          style={{
                                                            color: "#1976d2",
                                                          }}
                                                        >
                                                          Total Emp :{" "}
                                                          {
                                                            levelFour.total_employees
                                                          }{" "}
                                                        </small>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </StyledNode>
                                                }
                                              >
                                                {!!levelFour?.level_five &&
                                                Array.isArray(
                                                  levelFour.level_five
                                                ) &&
                                                levelFour.level_five.length ? (
                                                  levelFour.level_five
                                                    .filter((self) => !!self)
                                                    .map(
                                                      (
                                                        levelFive,
                                                        l5: number
                                                      ) => {
                                                        return (
                                                          <TreeNode
                                                            key={l5}
                                                            label={
                                                              <StyledNode>
                                                                <span
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                    color:
                                                                      "#1976d2",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                  }}
                                                                >
                                                                  {
                                                                    levelFive.level5_name
                                                                  }{" "}
                                                                  <small
                                                                    style={{
                                                                      fontSize:
                                                                        "0.7rem",
                                                                    }}
                                                                  >
                                                                    (
                                                                    {
                                                                      levelMaster?.level5_key_name
                                                                    }
                                                                    )
                                                                  </small>
                                                                </span>
                                                                {!!levelFive?.total_employees ? (
                                                                  <>
                                                                    <small
                                                                      style={{
                                                                        color:
                                                                          "#1976d2",
                                                                      }}
                                                                    >
                                                                      Total Emp
                                                                      :{" "}
                                                                      {
                                                                        levelFive.total_employees
                                                                      }{" "}
                                                                    </small>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </StyledNode>
                                                            }
                                                          />
                                                        );
                                                      }
                                                    )
                                                ) : (
                                                  <></>
                                                )}
                                              </TreeNode>
                                            );
                                          })
                                      ) : (
                                        <></>
                                      )}
                                    </TreeNode>
                                  );
                                })
                            ) : (
                              <></>
                            )}
                          </TreeNode>
                        );
                      })
                  ) : (
                    <></>
                  )}
                </Tree> */}
              </TabPanel>
            ))}
        </TabContext>
      </Box>
    </Paper>
  );
};
const StyledNode = styled.div`
  padding: 1px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
`;

interface ILevelHierarchy {
  tabValue: string;
  handleTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  levelDetails: Level[];
  levelMaster?: ILevelMaster;
}
export default LevelHierachy;
