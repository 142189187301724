import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  TextareaAutosize,
  Button,
  TableCell,
  styled,
  tableCellClasses,
  Tooltip,
  Slide,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect } from "react";
import { environment } from "../../../environment";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { EmpList } from "../../../models/employeeModel";
import { TransitionProps } from "@mui/material/transitions";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import {
  AllDesignationList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { EmployeeList } from "../../../models/promotionModel";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import moment from "moment";
import InfoIcon from '@mui/icons-material/Info';

function AddPromotionApproveView({
  approvalList,
  open,
  SetapprovalList,
  setOpen,
  control,
  errors,
  getValues,
  setValue,
  watch,
  handleSubmit,
  designation_list,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  CreatedPromotion,
}: AddPromotionApproveViewProps) {
  const { enqueueSnackbar } = useSnackbar();
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation",
    "Project",
    "Action",
  ];
  const removeAttendanceBatch = (row: EmployeeList) => {
    let temp: EmployeeList[] = JSON.parse(JSON.stringify(approvalList));
    temp = temp.filter((m) => m.employee_id != row.employee_id);
    SetapprovalList(temp);
    if (temp.length <= 0) setOpen(false);
  };
  console.log("salary_structure", salary_structure);
  const onSubmit = (data: any) => {
    // console.log("Submit", data);
    let component: any = {};
    if (
      !!salary_structure &&
      !!salary_structure.component &&
      salary_structure.component.length > 0
    ) {
      for (let item of salary_structure?.component) {
        if (!item.calculation) {
          component = {
            ...component,
            [item.compent_id]: +data[item.compent_id],
          };
        }
      }
    }
    let prom_start_month = new Date(data.promotion_start_date).getMonth() + 1;
    let prom_start_year = new Date(data.promotion_start_date).getFullYear();
    let prom_start_date = `${prom_start_year}-${prom_start_month}-01`;

    let rev_start_month = new Date(data.revision_start_date).getMonth() + 1;
    let rev_start_year = new Date(data.revision_start_date).getFullYear();
    let rev_start_date = `${rev_start_year}-${rev_start_month}-01`;

    let end_prom = new Date(data.promotion_end_date);
    let prom_end_date = new Date(
      end_prom.getFullYear(),
      end_prom.getMonth() + 1,
      0
    );

    let end_rev = new Date(data.revision_end_date);
    let rev_end_date = new Date(
      end_rev.getFullYear(),
      end_rev.getMonth() + 1,
      0
    );

    let payload: any = {
      employee_id: approvalList.map((m) => m.employee_id),
      // promotion_start_date: data.promotion_start_date,
      promotion_start_date: !!data.promotion_start_date
        ? prom_start_date
        : null,
      promotion_end_date: !!data.promotion_end_date
        ? moment(prom_end_date).format("YYYY-MM-DD")
        : null,
      revision_start_date: !!data.revision_start_date ? rev_start_date : null,
      revision_end_date: !!data.revision_end_date
        ? moment(rev_end_date).format("YYYY-MM-DD")
        : null,
      designation_id: !!data.designation_id? data.designation_id: null,
      salary_assignment: {
        salary_structure_id: !!data.salary_structure_id
          ? data.salary_structure_id
          : null,
        components: !!data.salary_structure_id ? component : null,
        // components: component
      },
    };
    CreatedPromotion(payload);
    console.log("CreatedPromotion", payload);
  };
  const ClearOnClose = () => {
    setOpen(false);
    setValue("employee_id", null);
    setValue("promotion_start_date", null);
    setValue("promotion_end_date", null);
    setValue("revision_start_date", null);
    setValue("revision_end_date", null);
    setValue("designation_id", null);
    setValue("salary_structure_id", null);
  };

  useEffect(() => {
    if (!!salary_structure && salary_structure.component.length > 0) {
      salary_structure.component.forEach((m) => {
        if (!m.calculation) {
          setValue(m.compent_id.toString(), m.default_value);
        }
      });
    }
  }, [salary_structure]);

  const handleRevisionStartDate = (newVal: Date) => {
    const rev_start_date = new Date();
    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(
      getValues("revision_start_date")
    ).getTime();
    const diff = Math.abs(selectedDateTime - currentDateTime);
    let new_rev_date = new Date(diff).getMonth();
    let now = new Date();
    let startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    if (new_rev_date >= environment.promotion_details.revision_start) {
      setValue("revision_start_date", startDate);
      return enqueueSnackbar("You can not plan more than 04 months before", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else if (
      new Date(getValues("revision_start_date")).getMonth() + 1 <
      rev_start_date.getMonth() + 1
    ) {
      setValue("revision_start_date", startDate);
      return enqueueSnackbar(
        "You can not select previous month ",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    } else {
      setValue("revision_start_date", newVal);
    }
  };
  const handlePromotionStartDate = (newVal: Date) => {
    const rev_start_date = new Date();
    const currentDateTime = new Date().getTime();
    const selectedDateTime = new Date(
      getValues("promotion_start_date")
    ).getTime();
    const diff = Math.abs(selectedDateTime - currentDateTime);
    let new_rev_date = new Date(diff).getMonth();
    let now = new Date();
    let startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    if (new_rev_date >= environment.promotion_details.revision_start) {
      setValue("promotion_start_date", startDate);
      return enqueueSnackbar("You can not plan more than 04 months before", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else if (
      new Date(getValues("promotion_start_date")).getMonth() + 1 <
      rev_start_date.getMonth() + 1
    ) {
      setValue("promotion_start_date", startDate);
      return enqueueSnackbar("You can not select previous month  ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("promotion_start_date", newVal);
    }
  };
  const handlePromotionEndDate = (newVal: Date) => {
    let now = new Date(getValues("promotion_start_date"));
    const selectedStartDate = new Date(
      getValues("promotion_start_date")
    ).getMonth();
    const selectedEndDate = new Date(
      getValues("promotion_end_date")
    ).getMonth();
    const selectedStartYear = new Date(
      getValues("promotion_start_date")
    ).getFullYear();
    const selectedEndYear = new Date(
      getValues("promotion_end_date")
    ).getFullYear();
    let endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    if (
      selectedStartDate >= selectedEndDate &&
      selectedStartYear >= selectedEndYear
    ) {
      setValue("promotion_end_date", endDate);
      return enqueueSnackbar("You can not select before start month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("promotion_end_date", newVal);
    }
  };
  const handleRevisionEndDate = (newVal: Date) => {
    let now = new Date(getValues("revision_start_date"));
    const selectedStartDate = new Date(
      getValues("revision_start_date")
    ).getMonth();
    const selectedEndDate = new Date(getValues("revision_end_date")).getMonth();
    const selectedEndYear = new Date(
      getValues("revision_end_date")
    ).getFullYear();
    const selectedStartYear = new Date(
      getValues("revision_start_date")
    ).getFullYear();
    let endDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    if (
      selectedStartDate >= selectedEndDate &&
      selectedStartYear >= selectedEndYear
    ) {
      setValue("revision_end_date", endDate);
      return enqueueSnackbar("You can not select before start month ", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      setValue("revision_end_date", newVal);
    }
  };
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,minHeight: 20

    },
  });
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={ClearOnClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#0060FF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={ClearOnClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Verify and Approve
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container height={"92%"} flexGrow={1} flexDirection={"row"}>
        <Grid
          item
          xs={4.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          {approvalList[0]?.project_name !=
            environment.global_veriable.ho_project_name && (
            <Paper
              sx={{
                flexGrow: 1,
                borderRadius: 1,
                border: "1px solid #E0E0E0",
                margin: 2,
              }}
            >
              <Grid
                container
                flexDirection={"row"}
                style={{ border: "1px solid #E0E0E0" }}
              >
                <Grid item xs={4} style={{ padding: 5 }}>
                  <Typography style={{ fontWeight: "600" }}>
                    Project Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ backgroundColor: "#F4F7F6", padding: 5 }}
                >
                  <Typography>{approvalList[0]?.project_name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              border: "1px solid #E0E0E0",
              margin: 2,
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!approvalList &&
                    approvalList.map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.employee_id}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.employee_id}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.employee_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.designation_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.project_name}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[3]}>
                          <Tooltip
                            title="Remove"
                            onClick={() => removeAttendanceBatch(row)}
                          >
                            <IconButton color="error">
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid
          item
          xs={7.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        >
          <div style={{ margin: 5, padding: 10 }}>
            <form>
              <Paper
                elevation={3}
                sx={{ marginTop: 5, marginInline: 1, padding: 1 }}
              >
                <Grid container justifyContent={"flex-start"} mt={1.5} >
                  <Grid item xs={10}>
                    <Typography
                      style={{
                        fontWeight: "800",
                        fontSize: 18,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "4%",
                        paddingBottom: 5,
                      }}
                    >
                      Promotion Section
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={2}>
                  <CustomWidthTooltip title="You need to select another designation, it should not same as the current designation.. ">
                      <IconButton color="default">
                        <InfoIcon />
                      </IconButton>
                    </CustomWidthTooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Designation
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"designation_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={designation_list}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"designation_id"}
                              label={"designation_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => onChange(value)}
                              placeholder={"Search Designation"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {!!watch("designation_id") && (
                    <>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 8, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              Promotion Start Date
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"promotion_start_date"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <DatePicker
                                  views={["year", "month"]}
                                  value={value}
                                  InputProps={{
                                    onBlur: onBlur,
                                    size: "small",
                                    sx: {
                                      width: "125%",
                                      marginLeft: 1,
                                    },
                                  }}
                                  onChange={(newValue) => (
                                    onChange(newValue),
                                    handlePromotionStartDate(newValue)
                                    // setPromotionStartDate(newValue)
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 8, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              Promotion End Date
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"promotion_end_date"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <DatePicker
                                  views={["year", "month"]}
                                  value={value}
                                  InputProps={{
                                    onBlur: onBlur,
                                    size: "small",
                                    sx: {
                                      width: "125%",
                                      marginLeft: 1,
                                    },
                                  }}
                                  onChange={(newValue) => (
                                    onChange(newValue),
                                    // setPromotionEndDate(newValue)
                                    handlePromotionEndDate(newValue)
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
              <Paper
                elevation={3}
                sx={{ marginTop: 5, marginInline: 1, padding: 1 }}
              >
                <Grid container justifyContent={"flex-start"} mt={1.5}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontWeight: "800",
                        fontSize: 18,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "4%",
                        paddingBottom: 5,
                      }}
                    >
                      Revision Section
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          New Salary Structure
                        </Typography>
                      </Grid>
                      <Grid item xs={6.5} style={{ marginLeft: -10 }}>
                        <Controller
                          control={control}
                          name={"salary_structure_id"}
                          rules={{ required: false }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={salary_structure_list}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"salary_structure_id"}
                              label={"salary_stucture_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value),
                                !!value &&
                                  SalaryComponentEditAction({
                                    salary_structure_id: +value,
                                  })
                                // : ChangeComponent()
                              )}
                              placeholder={"Search Salary Structure"}
                              value={!!value ? value : ""}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {!!watch("salary_structure_id") && (
                    <>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 8, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              Revision Start Date
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"revision_start_date"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <DatePicker
                                  views={["year", "month"]}
                                  value={value}
                                  InputProps={{
                                    onBlur: onBlur,
                                    size: "small",
                                    sx: {
                                      width: "125%",
                                      marginLeft: 1,
                                    },
                                  }}
                                  onChange={(newValue) => (
                                    onChange(newValue),
                                    // setRevisionStartDate(newValue),
                                    handleRevisionStartDate(newValue)
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="start"
                          alignItems="center"
                          style={{ marginTop: 8, marginBottom: 10 }}
                          // width={"100%"}
                        >
                          <Grid
                            item
                            xs={5}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: 16,
                                fontFamily: "Poppins, sans-serif",
                                color: "#626974",
                                paddingLeft: "20%",
                                // textAlign: "center"
                              }}
                            >
                              Revision End Date
                              <span style={{ color: "red", fontSize: 14 }}>
                                *
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                            <Controller
                              control={control}
                              name={"revision_end_date"}
                              defaultValue={""}
                              rules={{ required: true }}
                              render={({
                                field: { onBlur, value, onChange },
                              }) => (
                                <DatePicker
                                  views={["year", "month"]}
                                  value={value}
                                  InputProps={{
                                    onBlur: onBlur,
                                    size: "small",
                                    sx: {
                                      width: "125%",
                                      marginLeft: 1,
                                    },
                                  }}
                                  onChange={(newValue) => (
                                    onChange(newValue),
                                    handleRevisionEndDate(newValue)
                                    // setRevisionEndDate(newValue)
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </>
                  )}
                  {!!watch("salary_structure_id") && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontWeight: "800",
                            fontSize: 18,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "4%",
                            paddingBottom: 5,
                          }}
                        >
                          Salary Details
                        </Typography>
                      </Grid>
                      {!!salary_structure &&
                        salary_structure.component.length > 0 &&
                        salary_structure.component.map(
                          (item, index) =>
                            !item.calculation && (
                              <Grid item xs={6}>
                                <Grid
                                  key={item.compent_id}
                                  container
                                  justifyContent="start"
                                  alignItems="center"
                                  style={{ marginTop: 5, marginBottom: 10 }}
                                  // width={"100%"}
                                >
                                  <Grid
                                    item
                                    xs={5}
                                    justifyContent="space-between"
                                    alignItems="start"
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "500",
                                        fontSize: 16,
                                        fontFamily: "Poppins, sans-serif",
                                        color: "#626974",
                                        paddingLeft: "20%",
                                        // textAlign: "center"
                                      }}
                                    >
                                      {item.display_name}
                                      <span
                                        style={{ color: "red", fontSize: 14 }}
                                      >
                                        *
                                      </span>
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6.5}
                                    style={{ marginLeft: -5 }}
                                  >
                                    <Controller
                                      control={control}
                                      name={item.compent_id.toString()}
                                      defaultValue={""}
                                      rules={{ required: true }}
                                      render={({
                                        field: { onBlur, value, onChange },
                                      }) => (
                                        <TextField
                                          type={"number"}
                                          id="standard-required"
                                          variant="outlined"
                                          className={"smaller"}
                                          style={{ width: "100.9%" }}
                                          onBlur={onBlur}
                                          size="small"
                                          onChange={onChange}
                                          value={value}
                                          placeholder={item.display_name}
                                          error={
                                            !!errors[item.compent_id.toString()]
                                          }
                                          helperText={
                                            errors[
                                              item.compent_id.toString()
                                            ] && `* This field is Required`
                                          }
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                        )}
                    </>
                  )}
                </Grid>
              </Paper>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Grid item xs={3}>
                  <Button
                    sx={{
                      color: "#fff",
                      //backgroundColor: "#1565C0",
                      paddingX: 4,
                      marginRight: 10,
                    }}
                    onClick={() => (setOpen(false), ClearOnClose())}
                    variant="contained"
                    color="error"
                    size="large"
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#1565C0",
                      paddingX: 4,
                      marginRight: 10,
                    }}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Initiate
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AddPromotionApproveView;
interface AddPromotionApproveViewProps {
  open: boolean;
  setOpen?: any;
  approvalList: EmployeeList[];
  SetapprovalList?: any;
  getValues?: any;
  control?: any;
  setValue?: any;
  watch?: any;
  errors?: any;
  handleSubmit?: any;
  designation_list: AllDesignationList[];
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  CreatedPromotion?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
