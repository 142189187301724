import { baseUrl, environment } from "../environment";
import {
  IPayrollReportPayload,
  PFESICReportListsPayload,
} from "../models/reportsModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const PFESICReportListsService = (data: PFESICReportListsPayload) => {
  return serviceClient.post(environment.urls.pfesicMonthlyReportLists, data);
};

export const PayrollReportService = (payload: IPayrollReportPayload) => {
  return serviceClient.post(environment.urls.payrollReport, payload);
};
// Consolidated Monthly Report Projectwise
export const ConsolidatedMonthlyReportProjectwiseService = (payload: IPayrollReportPayload) => {
  return serviceClient.post(environment.urls.consolidatedMonthlyReportProjectwise, payload);
};