import { Cookies } from "react-cookie";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
import {
  DeletePromotionPayload,
  InitiatePromotionPayload,
  PromotionApprovalPayload,
  PromotionListPayload,
  UpdatePromotionmPayload,
  ViewPromotionPayload,
} from "../../models/promotionModel";
import {
  ActivateSalaryForPromotionService,
  DeletePromotionService,
  EmployeeAvailableListForPromotionService,
  InitiatePromotionService,
  PromotionApprovalService,
  PromotionListService,
  UpdatePromotionService,
  ViewPromotionService,
} from "../../services/promotionService";

export enum PromotionActionTypes {
  Promotion_List_Success_Action = "[PROMOTION] Promotion List Success Action",
  View_Promotion_Success_Action = "[PROMOTION] View Promotion Success Action",
  Promotion_Approval_Success_Action = "[PROMOTION] Promotion Approval Success Action",
  Initiate_Promotion_Success_Action = "[PROMOTION] Initiate Promotion Success Action",
  Update_Promotion_Success_Action = "[PROMOTION] Update Promotion Success Action",
  Delete_Promotion_Success_Action = "[PROMOTION] Delete Promotion Success Action",
  Employee_Available_List_For_Promotion_Success_Action = "[PROMOTION] Employee_Available_List_For_Promotion Success Action",
  Activate_Salary_For_Promotion_Success_Action = "[PROMOTION] Activate Salary For Promotion Success Action",
}
export const PromotionListAction = (payload: PromotionListPayload) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Promotion List Fetching. Please Wait...",
        type: 2,
      })
    );
    return PromotionListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(PromotionListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const PromotionListSuccessAction = (data: any) => {
  return {
    type: PromotionActionTypes.Promotion_List_Success_Action,
    payload: data,
  };
};
export const ViewPromotionAction = (payload: ViewPromotionPayload) => {
  console.log("payload : ", payload);
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "View Promotion Details Fetching. Please Wait...",
        type: 2,
      })
    );
    return ViewPromotionService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ViewPromotionSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ViewPromotionSuccessAction = (data: any) => {
  return {
    type: PromotionActionTypes.View_Promotion_Success_Action,
    payload: data,
  };
};

export const PromotionApprovalListAction = (
  payload: PromotionApprovalPayload
) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Promotion Approval List Fetching. Please Wait...",
        type: 2,
      })
    );
    return PromotionApprovalService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(PromotionApprovalSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const PromotionApprovalSuccessAction = (data: any) => {
  return {
    type: PromotionActionTypes.Promotion_Approval_Success_Action,
    payload: data,
  };
};

export const InitiatePromotionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Promotion initiated Fetching. Please Wait...",
        type: 2,
      })
    );
    return InitiatePromotionService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Promotion Initiated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          payload.navigateTo("/promotion/promotion-list/");
          dispatch(ViewPromotionSuccessAction(response.data));
          dispatch(PromotionListAction(payload.data2));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const InitiatePromotionSuccessAction = () => {
  return {
    type: PromotionActionTypes.Initiate_Promotion_Success_Action,
  };
};

export const UpdatePromotionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Promotion Updated. Please Wait...",
        type: 2,
      })
    );
    return UpdatePromotionService(payload.data2)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(UpdatePromotionSuccessAction());
          dispatch(PromotionApprovalListAction(payload.data1));
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdatePromotionSuccessAction = () => {
  return {
    type: PromotionActionTypes.Update_Promotion_Success_Action,
  };
};

export const DeletePromotionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Promotion Deleted. Please Wait...",
        type: 2,
      })
    );
    return DeletePromotionService(payload.data1)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(DeletePromotionSuccessAction());
          dispatch(PromotionListAction(payload.data2));
          dispatch(ViewPromotionAction(payload.data3));
          payload.enqueueSnackbar("Successfully Deleted", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const DeletePromotionSuccessAction = () => {
  return {
    type: PromotionActionTypes.Delete_Promotion_Success_Action,
  };
};

export const EmployeeAvailableListForPromotionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Employee Available For Promotion. Please Wait...",
        type: 2,
      })
    );
    return EmployeeAvailableListForPromotionService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            EmployeeAvailableListForPromotionSuccessAction(response.data)
          );
        }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EmployeeAvailableListForPromotionSuccessAction = (data: any) => {
  return {
    type: PromotionActionTypes.Employee_Available_List_For_Promotion_Success_Action,
    payload: data,
  };
};

export const ActivateSalaryForPromotionAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Activate Salary Promotion. Please Wait...",
        type: 2,
      })
    );
    return ActivateSalaryForPromotionService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(ActivateSalaryForPromotionSuccessAction());
        }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const ActivateSalaryForPromotionSuccessAction = () => {
  return {
    type: PromotionActionTypes.Activate_Salary_For_Promotion_Success_Action,
  };
};
