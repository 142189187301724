/* eslint-disable @typescript-eslint/no-redeclare */
import { FC, useState, useEffect, ChangeEvent, SetStateAction } from "react";
import {
  IAllLeaveManagement,
  IDesignationWiseEmp,
  ILeaveAmountData,
  ILeaveDesignation,
  ILeaveDetailsData,
  ILeaveManagement,
  ILeaveTableData,
  ILeaveTypeDataDesignationWise,
  IdesignationWiseLeave,
  ICancelLeavePayload,
  INaturalHoliday,
} from "../../../models/leaveModel";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ILeaveType } from "../../../models/leaveModel";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";
import ApplicationDetail from "./ApplicationDetail";
import LeaveApplicationModal from "./LeaveApplicationModal";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import moment from "moment";
import CancelLeaveDialog from "./CancelLeaveDialog";
import "./LeaveApplicationTableStyles.css";
const LeaveApplicationTable: FC<ILeaveApplicationTable> = ({
  GetAllDesignationAction,
  selected_organization_leave,
  allLeaveManagementDetails,
  getAllLeaveManagementData,
  organizationList,
  user,
  designation_list,
  getDesignationWiseLeaveAction,
  leave_types,
  clearDesignationWiseLeave,
  GetAllLeaveTypeAction,
  designationWiseLeave,
  designationWiseEmp,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  leaveAmountData,
  calculateLeaveDaysAction,
  leaveDaysCount,
  applyLeaveAction,
  getLeaveDetailsAction,
  leaveDetailsData,
  resetLeaveDaysCalculationState,
  setAddLeaveModal,
  addLeaveModal,
  startDate,
  setStartDate,
  editLeaveAction,
  cancelLeaveAction,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}): JSX.Element => {
  const styles = {
    marginRight: "50px",
  };
  const column1: string[] = [
    "Leave Name",
    "Start Date",
    "End Date",
    "No of Leaves",
    "Approval Status",
    "Applied At",
    "Cancel",
  ];
  const enqueSnackbar = useSnackbar();

  const [rows, setRows] = useState<ILeaveDetailsData[]>([]);
  const [page, setPage] = useState(0);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);
  const [cancelPayload, setCancelPayload] =
    useState<ICancelLeavePayload | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!leaveDetailsData
      ? leaveDetailsData.filter((self) => {
          return (
            self.leave_status
              .toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            self.leave_type_name
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  // THIS COMMENT IS UNDER REVIEW PLEASE DON'T DELETE THIS
  // useEffect(() => {
  //   GetAllDesignationAction({ organization_id: selected_organization_leave });
  //   getAllLeaveManagementData();
  // }, []);

  // useEffect(() => {
  //   if (user?.employeeId) {
  //     getLeaveDetailsAction(user.employeeId);
  //   }
  // }, [user]);

  useEffect(() => {
    if (!!user && user?.employeeId) {
      getLeaveDetailsAction(user.employeeId);
    }
  }, [user]);
  useEffect(() => {
    cancelSearch();
  }, [user, leaveDetailsData]);
  const handleModalOpen = () => {
    setStartDate(new Date().toString());
    setAddLeaveModal(true);
  };

  const onCloseCancelDialog = () => {
    setIsOpenCancelDialog(false);
    setCancelPayload(null);
  };
  const handleLeaveCancelRequest = () => {
    if (!!cancelPayload) {
      cancelLeaveAction({ formData: cancelPayload, enqueSnackbar });
      onCloseCancelDialog();
    }
  };

  return (
    <div className={"TableBackground"}>
      <CancelLeaveDialog
        open={isOpenCancelDialog}
        setOpen={setIsOpenCancelDialog}
        aggreFunc={handleLeaveCancelRequest}
        setClose={onCloseCancelDialog}
      />
      <LeaveApplicationModal
        open={addLeaveModal}
        setOpen={setAddLeaveModal}
        designation_list={designation_list}
        allLeaveManagementDetails={allLeaveManagementDetails}
        getDesignationWiseLeaveAction={getDesignationWiseLeaveAction}
        clearDesignationWiseLeave={clearDesignationWiseLeave}
        designationWiseLeave={designationWiseLeave}
        GetAllLeaveTypeAction={GetAllLeaveTypeAction}
        leave_types={leave_types}
        designationWiseEmp={designationWiseEmp}
        getEmployeeByDesignationAction={getEmployeeByDesignationAction}
        user={user}
        leaveAmountData={leaveAmountData}
        calculateLeaveDaysAction={calculateLeaveDaysAction}
        leaveDaysCount={leaveDaysCount}
        applyLeaveAction={applyLeaveAction}
        resetLeaveDaysCalculationState={resetLeaveDaysCalculationState}
        defaultStartDate={startDate}
        getLeaveDetailsAction={getLeaveDetailsAction}
        getLeaveAmountAction={getLeaveAmountAction}
        editLeaveAction={editLeaveAction}
        natural_holiday_list={natural_holiday_list}
        naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
      />
      <Grid container display={"flex"} mt={3}>
        <Grid item xs={3} display="flex" justifyContent="left" mt={2} ml={2}>
          <Button variant="outlined" onClick={handleModalOpen}>
            Apply For New Leave
            <AddIcon color="primary" />
          </Button>
        </Grid>

        <Grid
          item
          xs={8}
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            size="small"
            style={{ width: "80%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction={"row"}
            width={"100%"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <TableContainer
              sx={{ width: "98%", borderRadius: 1, mx: 2, my: 2 }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "#73c6ff",
                    cursor: "pointer",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {column1.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.leave_type_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[0]}>
                            {moment(row.start_date).format("DD-MMM-YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {moment(row.end_date).format("DD-MMM-YYYY")}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.leave_count === 0.5
                              ? "Half Day"
                              : row.leave_count}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.leave_status}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            {moment(row.created_on).format("DD-MMM-YYYY")}
                          </StyledTableCell>
                          {(row.leave_status === "APPLIED" ||
                          row.leave_status === "APPROVED") &&
                          row.start_date.toString() >=
                            moment(new Date())
                              .format("YYYY-MM-DD")
                              .toString() ? (
                            <StyledTableCell align="left" key={column1[4]}>
                              <IconButton
                                sx={{ color: "red" }}
                                onClick={() => {
                                  setCancelPayload({
                                    employee_id: user?.employee_detail
                                      ?.employee_id as string,
                                    leave_application_id: String(
                                      row?.leave_application_id
                                    ),
                                  });
                                  setIsOpenCancelDialog(true);
                                }}
                              >
                                <DoNotDisturbIcon />
                              </IconButton>
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell></StyledTableCell>
                          )}

                          {/* <StyledTableCell align="left" key={column1[4]}>
                            <IconButton
                              onClick={() => (
                                setDetailsViewOpen(true),
                                setSelectedDesgination(row.designation_id),
                                setLeaveDetails({
                                  designation_name: row.designation_name,
                                  no_of_leave: Number(row.no_of_leave),
                                })
                              )}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                  {!!rows && rows.length <= 0 && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={11}>
                        <div style={{ color: "red", textAlign: "center" }}>
                          No Data Found{" "}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={styles}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ILeaveApplicationTable {
  GetAllDesignationAction?: any;
  selected_organization_leave?: number;
  designation_list: AllDesignationList[];
  organizationList: OrganizationALlList[];
  allLeaveManagementDetails: IAllLeaveManagement[];
  getAllLeaveManagementData?: any;
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  GetAllLeaveTypeAction?: any;
  clearDesignationWiseLeave?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getEmployeeByDesignationAction?: any;
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  user?: userDetails;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  applyLeaveAction?: any;
  getLeaveDetailsAction?: any;
  leaveDetailsData: ILeaveDetailsData[];
  resetLeaveDaysCalculationState?: any;
  setAddLeaveModal: any;
  addLeaveModal: boolean;
  startDate: string;
  editLeaveAction?: any;
  setStartDate: any;
  cancelLeaveAction?: any;
  natural_holiday_list: INaturalHoliday[];
  naturalLeaveDaysActionByEmployee?: any;
}
export default LeaveApplicationTable;
