import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  PendingApprovalList,
  UpdateAttendaceStatusPayload,
} from "../../../models/attendanceModel";
import {
  ApproveLeaveStatusPayload,
  IAllLeaveManagement,
  IDesignationWiseEmp,
  IHolidayList,
  ILeaveAmountData,
  ILeaveCalendar,
  ILeaveDetailsData,
  ILeaveTableData,
  ILeaveType,
  INaturalHoliday,
  IdesignationWiseLeave,
  LeaveApprovalList,
} from "../../../models/leaveModel";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  AttendanceDetailPayloadSuccessAction,
  GetAllPendingApprovalAction,
  UpdateApproveAttendanceStatusAction,
} from "../../../stores/actions/attendanceAction";
import {
  applyLeaveAction,
  calculateLeaveDaysAction,
  clearDesignationWiseLeave,
  editLeaveAction,
  getAllHolidaysAction,
  getAllLeaveApprovalListAction,
  getAllLeaveManagementData,
  GetAllLeaveTypeAction,
  getDesignationWiseLeaveAction,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  getLeaveDetailsAction,
  LeaveCalendarDateAction,
  naturalLeaveDaysActionByEmployee,
  selectOrganization,
  UpdateApproveLeaveStatusAction,
} from "../../../stores/actions/LeaveAction";
import LeaveApprovalListView from "./LeaveApprovalListView";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import {
  GetAllOrganizationAction,
  GetAllDesignationAction,
} from "../../../stores/actions/organizationActions";

const LeaveApproval = ({
  getAllLeaveApprovalListAction,
  leave_approval_list,
  UpdateApproveLeaveStatusAction,
  AttendanceDetailPayloadSuccessAction,
  designation_list,
  user,
  holiday_list,
  getAllHolidaysAction,
  GetAllDesignationAction,
  organization_list,
  designationWiseEmp,
  selectOrganization,
  selected_organization_leave,
  allLeaveManagementDetails,
  getAllLeaveManagementData,
  clearDesignationWiseLeave,
  getDesignationWiseLeaveAction,
  GetAllLeaveTypeAction,
  designationWiseLeave,
  getLeaveAmountAction,
  leaveAmountData,
  calculateLeaveDaysAction,
  leave_types,
  leaveDaysCount,
  getLeaveDetailsAction,
  applyLeaveAction,
  leaveDetailsData,
  leave_calendar,
  LeaveCalendarDateAction,
  editLeaveAction,
  natural_holiday_list,
  naturalLeaveDaysActionByEmployee,
}: LeaveApprovalProps) => {
  React.useEffect(() => {
    if (!!leave_approval_list) {
      getAllLeaveApprovalListAction({
        approverDesigId: user?.designation,
        eventType: 9,
      });
    }
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const ApprovedAction = (payload: ApproveLeaveStatusPayload) => {
    let payloads = {
      data1: payload,
      data2: {
        approverDesigId: user?.designation,
        eventType: 9,
      },
      enqueueSnackbar: enqueueSnackbar,
    };
    UpdateApproveLeaveStatusAction(payloads);
  };

  return (
    <LeaveApprovalListView
      ApprovedAction={ApprovedAction}
      AttendanceDetailPayloadSuccessAction={
        AttendanceDetailPayloadSuccessAction
      }
      leave_approval_list={leave_approval_list}
      leave_types={leave_types}
      GetAllLeaveTypeAction={GetAllLeaveTypeAction}
      designation_list={designation_list}
      holiday_list={holiday_list}
      getAllHolidaysAction={getAllHolidaysAction}
      GetAllDesignationAction={GetAllDesignationAction}
      organization_list={organization_list}
      user={user}
      selectOrganization={selectOrganization}
      selected_organization_leave={selected_organization_leave}
      allLeaveManagementDetails={allLeaveManagementDetails}
      getAllLeaveManagementData={getAllLeaveManagementData}
      getDesignationWiseLeaveAction={getDesignationWiseLeaveAction}
      designationWiseLeave={designationWiseLeave}
      clearDesignationWiseLeave={clearDesignationWiseLeave}
      designationWiseEmp={designationWiseEmp}
      getLeaveAmountAction={getLeaveAmountAction}
      leaveAmountData={leaveAmountData}
      calculateLeaveDaysAction={calculateLeaveDaysAction}
      leaveDaysCount={leaveDaysCount}
      getLeaveDetailsAction={getLeaveDetailsAction}
      applyLeaveAction={applyLeaveAction}
      leaveDetailsData={leaveDetailsData}
      leave_calendar={leave_calendar}
      LeaveCalendarDateAction={LeaveCalendarDateAction}
      editLeaveAction={editLeaveAction}
      natural_holiday_list={natural_holiday_list}
      naturalLeaveDaysActionByEmployee={naturalLeaveDaysActionByEmployee}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    leave_approval_list: state.leave.leave_approval_list,
    user: state.user.userDetails,
    leave_types: state.leave.leave_types,
    designation_list: state.organization.designation_list,

    holiday_list: state.leave.holiday_list,
    selected_organization_leave: state.leave.selected_organization,
    organization_list: state.organization.organization_filter_list,
    allLeaveManagementDetails: state.leave.allLeaveManagement,
    designationWiseLeave: state.leave.designationWiseLeave,
    designationWiseEmp: state.leave.designationWiseEmp,
    leaveAmountData: state.leave.leave_amount_data,
    leaveDaysCount: state.leave.calculated_leave_days,
    leaveDetailsData: state.leave.leave_details_data,
    leave_calendar: state.leave.leave_calendar,
    natural_holiday_list: state.leave.natural_holidays,
  };
};

const mapDispatchToProps = {
  getAllLeaveApprovalListAction,
  UpdateApproveLeaveStatusAction,
  AttendanceDetailPayloadSuccessAction,
  GetAllLeaveTypeAction,

  getAllHolidaysAction,
  GetAllOrganizationAction,
  selectOrganization,
  GetAllDesignationAction,
  getDesignationWiseLeaveAction,
  getAllLeaveManagementData,
  clearDesignationWiseLeave,
  getEmployeeByDesignationAction,
  getLeaveAmountAction,
  calculateLeaveDaysAction,
  applyLeaveAction,
  getLeaveDetailsAction,
  LeaveCalendarDateAction,
  editLeaveAction,
  naturalLeaveDaysActionByEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApproval);

interface LeaveApprovalProps {
  getAllLeaveApprovalListAction?: any;
  UpdateApproveLeaveStatusAction?: any;
  leave_approval_list: LeaveApprovalList[];
  user?: userDetails;
  AttendanceDetailPayloadSuccessAction?: any;
  GetAllLeaveTypeAction?: any;
  designation_list: AllDesignationList[];
  holiday_list: IHolidayList[];
  getAllHolidaysAction?: any;

  organization_list: OrganizationALlList[];
  selectOrganization?: any;
  GetAllOrganizationAction?: any;
  selected_organization_leave?: number;
  GetAllDesignationAction?: any;
  allLeaveManagementDetails: IAllLeaveManagement[];
  getAllLeaveManagementData?: any;
  getDesignationWiseLeaveAction?: any;
  designationWiseLeave: IdesignationWiseLeave[];
  clearDesignationWiseLeave?: any;

  getEmployeeByDesignationAction?: any;
  leave_types: ILeaveType[];
  designationWiseEmp: IDesignationWiseEmp[];
  getLeaveAmountAction?: any;
  leaveAmountData?: ILeaveTableData;
  calculateLeaveDaysAction?: any;
  leaveDaysCount?: number;
  getLeaveDetailsAction?: any;
  applyLeaveAction?: any;
  leaveDetailsData: ILeaveDetailsData[];
  leave_calendar: ILeaveCalendar[];
  LeaveCalendarDateAction?: any;
  editLeaveAction?: any;
  naturalLeaveDaysActionByEmployee?: any;
  natural_holiday_list: INaturalHoliday[];
}
