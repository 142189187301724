import { useSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import {
  AllDesignationList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  DeleteDesignationAction,
  GetAllDesignationAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import SetupDesignationView from "./SetupDesignationView";

function SetupDesignation({
  GetAllOrganizationAction,
  organization_list,
  GetAllDesignationAction,
  designation_list,
  DeleteDesignationAction,
  SidenavAutoCloseOpen
}: SetupDesignationProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllOrganizationAction();
    GetAllDesignationAction({ organization_id: null });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const filterData = (props: any) => {
    props.org_id = props.organization_id;
    GetAllDesignationAction({
      organization_id:
        !!props.organization_id && props.organization_id != ""
          ? props.organization_id
          : null,
    });
    // console.log(props.organization_id);
  };
  const Clear = () => {
    GetAllDesignationAction({
      org_id: null,
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const DeleteAction = (props: number) => {
    // props.org_id = props.organization_id;
    DeleteDesignationAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  return (
    <SetupDesignationView
      organization_list={organization_list}
      filterData={filterData}
      Clear={Clear}
      designation_list={designation_list}
      DeleteAction={DeleteAction}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    designation_list: state.organization.designation_list,
  };
};
const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllDesignationAction,
  DeleteDesignationAction,
  SidenavAutoCloseOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupDesignation);

interface SetupDesignationProps {
  GetAllOrganizationAction?: any;
  organization_list?: OrganizationALlList[];
  GetAllDesignationAction?: any;
  DeleteDesignationAction?: any;
  designation_list: AllDesignationList[];
  SidenavAutoCloseOpen?: any;
}
