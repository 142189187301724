import { IconButton, Paper } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { formatDate } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ListPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Stack,
} from "@mui/material";
import HolidayModal from "./HolidayModal";
import { useSnackbar } from "notistack";
import { IHolidayList } from "../../../models/leaveModel";
import moment from "moment";
import { userDetails } from "../../../models/userModels";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteDialog from "./DeleteDialog";
import { environment } from "../../../environment";
import { StateList } from "../../../models/customerModel";

const HolidayCalendar: FC<IHolidayCalendar> = ({
  getAllHolidaysAction,
  AddHolidayAction,
  getHolidayById,
  editHolidayAction,
  selectedOrganizationLeave,
  deleteHolidayByAction,
  holiday_list,
  holiday,
  user,
  calendarYear,
  setCalendarYear,
  setSelectedState,
  selectedState,
  state_list,
}): JSX.Element => {
  console.log("holiday list : ", holiday_list);
  const [defaultDate, setDefaultDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [openHolidayModal, setHolidayModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [calendarLoading, seCalendarLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [editablePayload, setEditablePayload] = useState<IEventPayload | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();

  // const eventsData = [{ id: "1", title: "Doll Purnima", date: "2023-03-06" }];
  const [eventsData, setEventsData] = useState<IUpdatedHoliayList[]>([]);

  const [currentEvents, setCurrentEvents] = useState<any[]>([]);
  useEffect(() => {
    seCalendarLoading(true);
    if (!!holiday_list && holiday_list.length > 0) {
      const updatedList = holiday_list.map((self) => ({
        date: self.date,
        id: `${self.holiday_id}`,
        title: self.holiday_name,
      }));
      setCurrentEvents(updatedList);
      setEventsData(updatedList);
    } else {
      setCurrentEvents([]);
      setEventsData([]);
    }
    setTimeout(() => seCalendarLoading(false), 2000);
  }, [holiday_list]);
  const handleDateFromLabel = (date: string) => {
    const isFound = holiday_list.find((self) => self.date === date);
    if (isFound) {
      setSelectedDate(date);
      setIsEditing(true);
      setEditablePayload({
        date: date,
        holiday_description: String(isFound.holiday_description),
        holiday_name: isFound.holiday_name,
        organization_id: Number(user?.employee_detail.organization_id),
        flexible_leave: Boolean(isFound.flexible_leave),
        id: Number(isFound.holiday_id),
        is_national_holiday: Boolean(isFound.is_national_holiday),
        applicable_states: isFound.applicable_states,
      });
      setHolidayModal(true);
    } else {
      setHolidayModal(true);
    }
  };
  const handleDateClick = async (selected: any) => {
    console.log("selected : ", selected.startStr);
    if (
      environment.leave_application.permanent_holiday.includes(
        new Date(selected.startStr).getDay()
      )
    ) {
      enqueueSnackbar(`You can't add holiday at permanent holiday`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    setSelectedDate(selected.startStr);
    const isFound = holiday_list.find(
      (self) => self.date === selected.startStr
    );
    if (isFound) {
      setIsEditing(true);
      setEditablePayload({
        date: selected.startStr,
        holiday_description: String(isFound.holiday_description),
        holiday_name: isFound.holiday_name,
        organization_id: Number(user?.employee_detail.organization_id),
        flexible_leave: Boolean(isFound.flexible_leave),
        id: Number(isFound.holiday_id),
        is_national_holiday: Boolean(isFound.is_national_holiday),
        applicable_states: isFound.applicable_states,
      });
      setHolidayModal(true);
    } else {
      setHolidayModal(true);
    }

    // const calendarApi = selected.view.calendar;
    // calendarApi.unselect();
    // if (title) {
    //   calendarApi.addEvent({
    //     id: `${selected.dateStr}-${title}`,
    //     title,
    //     start: selected.startStr,
    //     end: selected.endStr,
    //     allDay: selected.allDay,
    //   });
    // }
  };

  const handleYearChange = (event: any) => {
    const currYear = moment(event.start).add(1, "days").format("YYYY");
    if (currYear != calendarYear) {
      // setCalendarYear(currYear);

      if (new Date(+currYear) > calendarYear) {
        setDefaultDate(`${currYear}-01-01`);
      } else {
        setDefaultDate(`${currYear}-12-01`);
      }
    } else {
      // const mydate = moment(event.start).format("YYYY-MM-DD");
      // setDefaultDate(mydate);
    }
  };
  console.log("caldendar year : ", calendarYear);

  const deleteRequestMethod = () => {
    if (deleteId > 0) {
      const payload = { deleteId, enqueueSnackbar };
      deleteHolidayByAction(payload);
    } else {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  console.log("currentEvents : ", currentEvents);
  console.log("current year : ", defaultDate);

  console.log("eventsData : ", eventsData);

  return (
    <Paper sx={{ ml: 4, mr: 5, marginTop: 1, marginBottom: 1.4, padding: 2 }}>
      <HolidayModal
        isModalOpen={openHolidayModal}
        setModalOpen={setHolidayModal}
        AddHolidayAction={AddHolidayAction}
        editHolidayAction={editHolidayAction}
        selectedOrganizationLeave={selectedOrganizationLeave}
        selectedDate={selectedDate}
        user={user}
        editablePayload={editablePayload}
        setEditablePayload={setEditablePayload}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        state_list={state_list}
      />
      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        delRequest={deleteRequestMethod}
      />
      {!calendarLoading ? (
        <Box display="flex" justifyContent="space-between">
          {/* calendar sidebar */}
          <Box flex="1 1 25%" p="10px" borderRadius="4px">
            <Typography
              mt={4}
              sx={{ color: "#212121" }}
              borderBottom="1px solid gray"
              variant="body1"
            >
              Holidays
            </Typography>
            <List
              id="holidayList"
              sx={{
                maxHeight: "60vh",
                overflow: "auto",
                pr: 0.3,
              }}
            >
              {holiday_list.map((self, index: number) => (
                <ListItem
                  key={index}
                  onClick={() => handleDateFromLabel(self.date)}
                  sx={{
                    background: "#132437",
                    fontSize: 12,
                    color: "#FFFFFF",
                    margin: "10px 0",
                    borderRadius: "5px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <Stack>
                    <Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "-1%",
                          right: "0%",
                          color: "#fff",
                        }}
                        onClick={(event: any) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setDeleteId(Number(self.holiday_id));
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                      <ListItemText
                        style={{
                          paddingTop: "10%",
                        }}
                        primary={self.holiday_name}
                        secondary={
                          <Typography fontSize={12}>
                            {moment(self.date).format("DD-MMM-YYYY")}
                          </Typography>
                        }
                      />
                    </Box>

                    {self.is_national_holiday ? (
                      <ListItemText
                        secondary={
                          <Typography fontSize={12}>
                            National Holiday
                          </Typography>
                        }
                      />
                    ) : (
                      <ListItemText
                        secondary={
                          <Typography fontSize={12}>
                            Applicable in{" "}
                            {!!self.applicable_states &&
                              Array.isArray(self.applicable_states) &&
                              self.applicable_states.join(" , ")}
                          </Typography>
                        }
                      />
                    )}
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box flex="1 1 100%" p="10px" borderRadius="4px">
            <FullCalendar
              height="65vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                ListPlugin,
              ]}
              headerToolbar={{
                left: "prev, next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={() => {}}
              eventsSet={(events) => setCurrentEvents(events)}
              dateClick={(event) =>
                setDefaultDate(moment(new Date()).format("YYYY-MM-DD"))
              }
              initialEvents={eventsData}
              datesSet={handleYearChange}
              initialDate={defaultDate}
            />
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70vh"
          width="100%"
        >
          <Typography color="gray" textAlign="center">
            Calendar is Loading .......
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
interface IHolidayCalendar {
  getAllHolidaysAction?: any;
  AddHolidayAction?: any;
  getHolidayById?: any;
  editHolidayAction?: any;
  selectedOrganizationLeave?: number;
  deleteHolidayByAction?: any;
  holiday_list: IHolidayList[];
  holiday?: IHolidayList;
  user?: userDetails;
  calendarYear: any;
  setCalendarYear: any;
  setSelectedState: any;
  selectedState: string;
  state_list: StateList[];
}
interface IUpdatedHoliayList {
  id: string;
  date: string;
  title: string;
}
interface IEventPayload {
  date: string;
  holiday_description: string;
  organization_id: number;
  flexible_leave: boolean;
  holiday_name: string;
  is_national_holiday: boolean;
  applicable_states: string[];
  id: number;
}

export default HolidayCalendar;
