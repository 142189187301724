import { FC } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { ILeaveAmountData, ILeaveTableData } from "../../../models/leaveModel";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { width } from "@mui/system";

const ApplicableLeave: FC<IApplicableLeave> = ({
  leaveAmountData,
  isDashboard,
}) => {
  return (
    <>
      {isDashboard ? (
        <Typography textAlign="center" variant="body1" color="gray" my={1}>
          Applicable Leave Type
        </Typography>
      ) : (
        <Typography textAlign="center" variant="h6" color="gray" my={1}>
          Applicable Leave Type
        </Typography>
      )}
      <Card
        sx={{
          mx: "auto",
          p: 1,
          mb: 3,
          mt: 0.4,
          width: isDashboard ? "100%" : "82%",
        }}
      >
        <Grid container spacing={2} ml={1}>
          {!!leaveAmountData &&
          leaveAmountData.leave_count &&
          Array.isArray(leaveAmountData.leave_count) ? (
            leaveAmountData.leave_count.map((self, index) => (
              <Grid item lg={3} xl={3} sm={2}>
                {/* <Typography color="gray" fontSize="0.9rem">
              {self.leave_type_name}
            </Typography>
            <Typography color="gray" fontSize="0.9rem">
              {self.used_leave}/{self.leave_amount}
            </Typography> */}
                <Box
                  width="40%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  py={1}
                >
                  <CircularProgressbar
                    className="cProgressbar"
                    value={
                      (Number(self.use_count) / Number(self.total_count)) * 100
                    }
                    text={`${self.use_count}/${self.total_count}`}
                    strokeWidth={7}
                  />
                  <Typography variant="body2" color="gray" mt={1}>
                    {self.leave_type_name}
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <p style={{ margin: "1rem auto", textAlign: "center" }}>
              No Data Found
            </p>
          )}
        </Grid>
      </Card>
    </>
  );
};
interface IApplicableLeave {
  leaveAmountData?: ILeaveTableData;
  isDashboard?: boolean;
}
export default ApplicableLeave;
