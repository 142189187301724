import React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../models/reduxModels";
import { GetAllRoleMasterList } from "../../../models/userManagementModel";
import { GetAllRoleMasterListAction } from "../../../stores/actions/userManagementAction";
import RoleMasterView from "./RoleMasterView";

const RoleMaster = ({
  all_role_master,
  GetAllRoleMasterListAction,
}: RoleMasterProps) => {
  React.useEffect(() => {
    GetAllRoleMasterListAction();
  }, []);
  return <RoleMasterView all_role_master={all_role_master} />;
};

const mapStateToProps = (state: StoreState) => {
  return {
    all_role_master: state.userManagement.all_role_master,
  };
};
const mapDispatchToProps = {
  GetAllRoleMasterListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleMaster);
interface RoleMasterProps {
  GetAllRoleMasterListAction?: any;
  all_role_master: GetAllRoleMasterList[];
}
