import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Button,
  IconButton,
  Popover,
  Modal,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  styled,
  TableCell,
  tableCellClasses,
  Divider,
  Link,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  // Division,
} from "../../../models/attendanceModel";
import { DomainList } from "../../../models/organizationModels";
import colorGenerator from "../../../utility/colorGenerator";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { CheckActionStatusPayload } from "../../../models/employeeModel";
import ActionStatusPopover from "../../employment/ActionStatusPopover";

const ProjectWiseCardView = ({
  handleClickOpenDownload,
  attendance_dashboard,
  item,
  attendence_salary_type,
  fileHandleEvent,
  DetailExcelView,
  attendance_approve_list,
  historyDetails,
  AttendanceDetailPayloadSuccessAction,
  setAttendanceStatus,
}: ProjectWiseCardViewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [target, Settarget] = useState<number>();
  const [targetDivision, settargetDivision] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    // console.log("leave call");
    setAnchorEl(null);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: number,
    id: any
  ) => {
    Settarget(type);
    settargetDivision(id);
    setAnchorEl(event.currentTarget);
    console.log(event, type, id);
  };
  // const [openHistory, setopenHistory] = useState<boolean>(false);
  const historyDetailsClick = (index: any) => {
    // setopenHistory(true);
    setopenModal(true);
    historyDetails(index);
  };
  // const closeHistryDetails = () => {
  //   setopenHistory(false);
  // };
  const columns: any[] = ["Month", "Created By", "Created On", "Action"];
  useEffect(() => {
    if (!!attendance_approve_list) {
      setRows(attendance_approve_list);
    }
  }, [attendance_approve_list]);
  const [rows, setRows] = useState<AttendanceApprovalList[]>([]);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [openModal2, setopenModal2] = useState<boolean>(false);
  const handleModalClose = () => setopenModal(false);
  const handleModalClose2 = () => handleClose();
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const [popoverPayload, SetpopoverPayload] = useState<
    CheckActionStatusPayload | undefined
  >(undefined);

  return (
    <Grid item width={280} style={{ margin: 5 }}>
      <Card
        elevation={10}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minWidth: "25%",
          ".css-9yqu2l-MuiCardContent-root:last-child ": {
            padding: "0px",
          },
        }}
      >
        <Grid container direction={"column"} display={"flex"} padding={0}>
          <CardHeader
            title={
              // attendance_dashboard.find((t) => t.project_name)?.project_name
              item?.project_name
            }
            padding={0}
            titleTypographyProps={{
              style: {
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif",
                // color: "#003065",
                paddingLeft: 15,
                color: "#fff",
                // textAlign: "center",
              },
            }}
            sx={{
              backgroundColor: "#42a5f5",
              // marginLeft: 3,
              padding: 1,
              "&:.MuiCardHeader-content ": {
                padding: 0,
              },
              "&:.MuiCardHeader-root ": {
                padding: 0,
              },
            }}
          ></CardHeader>
          <CardContent sx={{ height: "100px", padding: 1, margin: 0 }}>
            <Grid container direction={"row"} justifyContent={"space-around"}>
              <Grid item xs={8}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{ paddingTop: 5 }}
                  >
                    <Box
                      sx={{
                        // backgroundColor: colorGenerator(String("sd"), 0, 210),
                        // backgroundColor: "#CDE990",
                        // backgroundColor:
                        //   !!attendance_dashboard &&
                        //   attendance_dashboard.find(
                        //     (f) =>
                        //       f.total_employee_count >=
                        //       +f.uploaded_employee_count
                        //   )
                        //     ? "#FFD4D4"
                        //     : "#CDE990",
                        backgroundColor:
                          !!item &&
                          item.total_employee_count ==
                            +item.approved_employee_count
                            ? "#567d02"
                            : "#e3293c",
                        height: 80,
                        width: "100%",
                        borderRadius: 3,
                        paddingX: 3,
                      }}
                    >
                      <Grid
                        container
                        direction={"column"}
                        style={{ paddingTop: 5 }}
                        onMouseMove={(event: any) => {
                          event.stopPropagation();
                          handleClick(
                            event,
                            3,
                            // attendance_dashboard.find((z) => z.project_id)
                            item?.project_id
                          );
                        }}
                        onMouseLeave={(e: any) => {
                          setTimeout(() => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleModalClose2();
                          }, 2000);
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: 14,
                              fontFamily: "Alef, sans-serif",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            Attendance
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: 32,
                              fontFamily: "Poppins, sans-serif",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {!!item && item.approved_employee_count != null
                              ? item.approved_employee_count
                              : 0}
                            /{item?.total_employee_count}
                            {/* {!!attendance_dashboard &&
                              attendance_dashboard.find(
                                (m) => m.uploaded_employee_count
                              )?.uploaded_employee_count}{" "}
                            /
                            {!!attendance_dashboard &&
                              attendance_dashboard.find(
                                (l) => l.total_employee_count
                              )?.total_employee_count} */}
                            {/* {" "}
                            {!!item?.upload_attendence_detail &&
                            item?.upload_attendence_detail.length > 0
                              ? item?.upload_attendence_detail
                                  ?.map((p) => p.uploaded_employee_count)
                                  ?.reduce((m, n) => m + n)
                              : 0}{" "}
                            / {item?.total_employee_count} */}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ paddingTop: 1, paddingLeft: 0 }}>
                    <Grid
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Button
                          variant="text"
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) =>
                            // handleClick(
                            //   event,
                            //   4,
                            //   // attendance_dashboard.find((t) => t.project_id)
                            //   //   ?.project_id
                            //   item?.project_id
                            // )
                            DetailExcelView(
                              // h.attendence_salary_type_id,
                              // targetDivision,
                              // setAttendanceStatus,
                              item?.project_id
                              // item.division_id
                            )
                          }
                        >
                          <Typography
                            style={{
                              fontWeight: "400",
                              fontSize: 13,
                              fontFamily: "Poppins, sans-serif",
                              backgroundColor: "#42a5f5",
                              width: "80px",
                              padding: "5%"
                            }}
                          >
                            <Link href="#" style={{textDecoration: "none", color: "white"}}>View</Link>
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item style={{ paddingTop: 1 }}>
                        <Button
                          variant="text"
                          onClick={() =>
                            historyDetailsClick(
                              // attendance_dashboard.find((t) => t.project_id)
                              //   ?.project_id
                              item?.project_id
                            )
                          }
                        >
                          <Typography
                            style={{
                              fontWeight: "400",
                              fontSize: 13,
                              fontFamily: "Poppins, sans-serif",
                              backgroundColor: "#42a5f5",
                              width: "80px",
                              padding: "5%"
                            }}
                          >
                            <Link href="#" style={{textDecoration: "none", color: "white"}}>Show History</Link>
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ActionStatusPopover
              open={openStatus}
              setOpen={SetopenStatus}
              payload={popoverPayload}
            />
            {/* {!!attendance_dashboard && attendance_dashboard.length > 0 && ( */}
            {!!item && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                // sx={{minWidth: 100, minHeight:500}}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <Grid
                  container
                  style={{
                    padding: 4,
                    backgroundColor: "#0060FF",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography style={{ color: "#fff", textAlign: "center" }}>
                      {/* {!!attendance_dashboard &&
                        attendance_dashboard.length > 0 &&
                        attendance_dashboard.find(
                          (q) =>
                            !!q.uploaded_employee_count &&
                            +q.uploaded_employee_count > 0
                        ) && */}
                      {!!item &&
                        (target == 3 ? "Attendance Details" : "Upload Details")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  minWidth={200}
                  minHeight={120}
                  justifyContent={"center"}
                  alignItems={"center"}
                  direction={"row"}
                >
                  <Grid
                    container
                    style={{
                      backgroundColor: "#fff",
                      padding: 5,
                      margin: 5,
                      // height: 50,
                    }}
                    justifyContent={"center"}
                    // width={"100%"}
                    alignItems={"center"}
                  >
                    {/* {!!attendance_dashboard && */}
                    {!!item &&
                      (target == 3 ? (
                        <Grid container style={{ margin: 0, width: 300 }}>
                          <Grid
                            container
                            direction={"row"}
                            style={{
                              marginLeft: 5,
                              marginTop: 2,
                              height: 20,
                            }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={8} paddingLeft={1}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 15,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Total Empolyee
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                {item.total_employee_count}
                                {/* {
                                  attendance_dashboard.find(
                                    (o) => o.total_employee_count
                                  )?.total_employee_count
                                } */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={"row"}
                            style={{
                              marginLeft: 5,
                              marginTop: 2,
                              height: 20,
                            }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={8} paddingLeft={1}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 15,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Upload Empolyee
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                {!!item.uploaded_employee_count &&
                                item.uploaded_employee_count != null
                                  ? item.uploaded_employee_count
                                  : 0}
                                {/* {
                                  attendance_dashboard.find(
                                    (q) => q.uploaded_employee_count
                                  )?.uploaded_employee_count
                                } */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={"row"}
                            style={{
                              marginLeft: 5,
                              marginTop: 2,
                              height: 20,
                            }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={8} paddingLeft={1}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 15,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Approved Attendance
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                {!!item.approved_employee_count &&
                                item.approved_employee_count != null
                                  ? item.approved_employee_count
                                  : 0}
                                {/* {
                                  attendance_dashboard.find(
                                    (p) => p.approved_employee_count
                                  )?.approved_employee_count
                                } */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={"row"}
                            style={{
                              marginLeft: 5,
                              marginTop: 2,
                              height: 20,
                            }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={8} paddingLeft={1}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 15,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                Rejected Attendance
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography
                                style={{
                                  fontWeight: "600",
                                  fontSize: 16,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#626974",
                                }}
                              >
                                {!!item.rejected_employee_count &&
                                item.rejected_employee_count != null
                                  ? item.rejected_employee_count
                                  : 0}
                                {/* {
                                  attendance_dashboard.find(
                                    (i) => i.rejected_employee_count
                                  )?.rejected_employee_count
                                } */}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Button
                          onClick={() =>
                            // navigate(`/attendance/attendance-details`)
                            DetailExcelView(
                              // h.attendence_salary_type_id,
                              targetDivision,
                              setAttendanceStatus

                              // item.division_id
                            )
                          }
                          variant="outlined"
                          component="label"
                          color="primary"
                          startIcon={<ArrowCircleUpIcon />}
                          style={{ marginRight: 4 }}
                        >
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: 14,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: 10,
                            }}
                          >
                            View All
                          </Typography>
                        </Button>
                      ))}
                  </Grid>
                </Grid>
              </Popover>
            )}
          </CardContent>
        </Grid>
      </Card>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Paper
          elevation={2}
          sx={{
            marginTop: 10,
            marginInline: 15,
            marginBottom: 10,
            minWidth: "60%",
            minHeight: "60%",
            maxHeight: "80%",
            overflowY: "auto",
            // position: "absolute",
            // marginLeft: `calc(40% - ${Card}px)`,
          }}
        >
          <Grid
            container
            direction={"column"}
            display={"flex"}
            // justifyContent={"center"}
            //style={{ padding: 1, margin: 2 }}
          >
            <Grid
              container
              style={{
                padding: 5,
                backgroundColor: "#0060FF",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
              direction={"row"}
            >
              <Grid item xs={6}>
                <Typography style={{ color: "#fff", paddingLeft: 5 }}>
                  Upload History
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{ color: "#fff", textAlign: "right", paddingRight: 5 }}
                >
                  Project Name : {item?.project_name}
                  {/* {
                    attendance_dashboard.find((r) => r.project_name)
                      ?.project_name
                  } */}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8} sx={{ paddingBottom: 4 }}>
              <TableContainer sx={{ height: 420 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {columns.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.project_attendence_id}
                        >
                          <StyledTableCell align="left" key={columns[0]}>
                            {row.month
                              ? moment(row.month).format("MMMM").toUpperCase() +
                                "'" +
                                moment(row.month).format("YY").toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[1]}>
                            <Link
                              onClick={() =>
                                navigate(
                                  `/employment/manage-profile/${row.created_by}`
                                )
                              }
                            >
                              {row.created_by}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[2]}>
                            {row.created_at
                              ? moment(row.created_at)
                                  .format("DD/MM/YYYY ")
                                  .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns[3]}>
                            <Tooltip
                              title="View"
                              onClick={() => (
                                AttendanceDetailPayloadSuccessAction({
                                  attendence_salary_type_id:
                                    row.attendence_salary_type_id,
                                  organization_id: row?.organization_id,
                                  employee_type_id: row.employee_type_id,
                                  project_id: row?.project_id,
                                  // division_id: row.division_id,
                                  project_attendence_id:
                                    row.project_attendence_id,
                                  page_index: 0,
                                  page_size: 2,
                                }),
                                navigateTo(`/attendance/attendance-details/0`)
                              )}
                            >
                              <IconButton>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Show Approve Status"
                              onClick={() => (
                                SetpopoverPayload({
                                  employeeId: row.project_attendence_id,
                                  employeeTypeId: row.employee_type_id,
                                  eventType: 7,
                                }),
                                SetopenStatus(true)
                              )}
                            >
                              <IconButton>
                                <ContentPasteSearchIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              padding={3}
            >
              <Button
                sx={{
                  color: "#fff",
                  backgroundColor: "#1565C0",
                  paddingX: 4,
                  marginRight: 10,
                }}
                onClick={() => handleModalClose()}
                variant="contained"
                color="primary"
                size="large"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </Grid>
  );
};
export default ProjectWiseCardView;
interface ProjectWiseCardViewProps {
  handleClickOpenDownload?: any;
  attendance_dashboard: AttendanceDashboardView[];
  item?: AttendanceDashboardView;
  attendence_salary_type?: DomainList[];
  fileHandleEvent?: any;
  DetailExcelView?: any;
  attendance_approve_list: AttendanceApprovalList[];
  historyDetails?: any;
  AttendanceDetailPayloadSuccessAction?: any;
  setAttendanceStatus?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FD6500",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

{
  /* <Card
        elevation={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // marginRight: "5px",
          marginTop: "13px",
          marginBottom: 1,
          marginLeft: -4,
          minWidth: "30%",
          // padding: 1,
          borderColor: "blue",
          borderWidth: 1,
          borderStyle: "solid",
          ".css-9yqu2l-MuiCardContent-root:last-child ": {
            padding: "0px",
          },
        }}
      >
        <Grid container direction={"column"} display={"flex"} padding={0}>
          <CardHeader
            title={item?.division_name}
            padding={0}
            titleTypographyProps={{
              style: {
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Poppins, sans-serif",
                // color: "#003065",
                paddingLeft: 15,
                color: "#fff",
                // textAlign: "center",
              },
            }}
            sx={{
              backgroundColor: "#003065",
              // marginLeft: 3,
              padding: 1,
              "&:.MuiCardHeader-content ": {
                padding: 0,
              },
              "&:.MuiCardHeader-root ": {
                padding: 0,
              },
            }}
          ></CardHeader>
          <CardContent sx={{ height: "103px", padding: 1, margin: 0 }}>
            <Grid container direction={"row"} justifyContent={"space-around"}>
              <Grid
                item
                xs={12}
                paddingTop={1}
                justifyContent={"center"}
                alignItems={"normal"}
                style={{ margin: 0, paddingTop: 0 }}
              >
                <Grid item xs={12} style={{ margin: 0, padding: 0 }}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-evenly"}
                    style={{ margin: 0 }}
                  >
                    <Grid item xs={5.9} style={{ margin: 0, marginTop: 15 }}>
                      {item?.division_detail_count.map(
                        (m) =>
                          !!m.attendence_salary_type_id &&
                          !!m?.count &&
                          m?.count > 0 && (
                            <Grid
                              container
                              direction={"row"}
                              style={{ marginLeft: 5, paddingTop: 2 }}
                              alignItems={"center"}
                            >
                              <Grid item xs={1}>
                                <Box
                                  sx={{
                                    backgroundColor: colorGenerator(
                                      String(m.attendence_salary_type_id),
                                      0,
                                      210
                                    ),
                                    height: 15,
                                    width: 15,
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={6} paddingLeft={1}>
                                <Typography
                                  style={{
                                    fontWeight: "600",
                                    fontSize: 15,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  {
                                    attendence_salary_type?.find(
                                      (n) =>
                                        n.domain_value ==
                                        m.attendence_salary_type_id
                                    )?.domain_key
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  style={{
                                    fontWeight: "600",
                                    fontSize: 16,
                                    fontFamily: "Poppins, sans-serif",
                                    color: "#626974",
                                  }}
                                >
                                  {m.count} /{" "}
                                  {!!item.upload_attendence_detail &&
                                  item.upload_attendence_detail.length > 0 &&
                                  item.upload_attendence_detail.filter(
                                    (p) =>
                                      p.attendence_salary_type_id ==
                                      m.attendence_salary_type_id
                                  ).length > 0
                                    ? item.upload_attendence_detail
                                        .filter(
                                          (p) =>
                                            p.attendence_salary_type_id ==
                                            m.attendence_salary_type_id
                                        )
                                        .map((n) => n.uploaded_employee_count)
                                        .reduce((q, w) => q + w)
                                    : 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                      )}
                    </Grid>
                    <Grid item xs={5.9} style={{ marginTop: 12 }}>
                      <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        style={{ marginLeft: 35 }}
                      >
                        <Grid>
                          <Typography
                            style={{
                              fontWeight: "600",
                              fontSize: 32,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            {item?.total_employee_count} /{" "}
                            {!!item?.upload_attendence_detail &&
                            item?.upload_attendence_detail.length > 0
                              ? item?.upload_attendence_detail
                                  ?.map((p) => p.uploaded_employee_count)
                                  ?.reduce((m, n) => m + n)
                              : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: 0, padding: 0 }}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"baseline"}
                    style={{ margin: 0, paddingLeft: 5, paddingRight: 5 }}
                  >
                    <Grid item xs={4.5} style={{ marginTop: 20, padding: 0 }}>
                      <Button
                        variant="text"
                        // onClick={() =>
                        //   navigate(`/attendance/attendance-details`)
                        // }
                        onClick={(event) =>
                          handleClick(event, 3, item?.division_id)
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: 13,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                          }}
                        >
                          Upload Details
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={4.5} style={{ margin: 0, padding: 0 }}>
                      <Button
                        variant="text"
                        onClick={() => historyDetailsClick(item?.division_id)}
                      >
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: 13,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                          }}
                        >
                          Upload History
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={1.5}>
                      <IconButton
                        color="primary"
                        onClick={(event) =>
                          handleClick(event, 1, item?.division_id)
                        }
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1.5}>
                      <IconButton
                        color="primary"
                        onClick={(event) =>
                          handleClick(event, 2, item?.division_id)
                        }
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </Grid>
                    {!!item?.division_detail_count &&
                      item?.division_detail_count.length > 1 && (
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                        >
                          <Grid
                            container
                            style={{
                              padding: 10,
                              backgroundColor: "#0060FF",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                            }}
                          >
                            <Grid item>
                              <Typography
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                {!!item.division_detail_count &&
                                  item.division_detail_count.length > 0 &&
                                  item.division_detail_count.find(
                                    (q) => !!q.count && q.count > 0
                                  ) &&
                                  (target == 1
                                    ? "Download Attendance Template"
                                    : target == 2
                                    ? "Upload Attendance"
                                    : "Upload Details")}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            minWidth={200}
                            justifyContent={"center"}
                            alignItems={"center"}
                            direction={"row"}
                          >
                            <Grid
                              container
                              style={{
                                backgroundColor: "#fff",
                                padding: 5,
                                margin: 5,
                                height: 50,
                              }}
                              justifyContent={"center"}
                              width={"100%"}
                              alignItems={"center"}
                            >
                              {item?.division_detail_count.map(
                                (h) =>
                                  !!h.count &&
                                  h.count > 0 &&
                                  (target == 1 ? (
                                    <Button
                                      onClick={() =>
                                        handleClickOpenDownload(
                                          h.attendence_salary_type_id
                                        )
                                      }
                                      variant="outlined"
                                      component="label"
                                      color="primary"
                                      startIcon={<CloudDownloadIcon />}
                                      style={{ marginRight: 4 }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "600",
                                          fontSize: 14,
                                          fontFamily: "Poppins, sans-serif",
                                          color: "#626974",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {
                                          attendence_salary_type?.find(
                                            (n) =>
                                              n.domain_value ==
                                              h.attendence_salary_type_id
                                          )?.domain_key
                                        }
                                      </Typography>
                                    </Button>
                                  ) : target == 2 ? (
                                    <Button
                                      variant="outlined"
                                      component="label"
                                      color="primary"
                                      startIcon={<CloudUploadIcon />}
                                      style={{ marginRight: 4 }}
                                    >
                                      <input
                                        hidden
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        multiple
                                        type="file"
                                        onChange={(event: any) =>
                                          fileHandleEvent(
                                            event,
                                            h.attendence_salary_type_id,
                                            item.division_id
                                          )
                                        }
                                      />
                                      <Typography
                                        style={{
                                          fontWeight: "600",
                                          fontSize: 14,
                                          fontFamily: "Poppins, sans-serif",
                                          color: "#626974",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {
                                          attendence_salary_type?.find(
                                            (n) =>
                                              n.domain_value ==
                                              h.attendence_salary_type_id
                                          )?.domain_key
                                        }
                                      </Typography>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        // navigate(`/attendance/attendance-details`)
                                        DetailExcelView(
                                          h.attendence_salary_type_id
                                        )
                                      }
                                      variant="outlined"
                                      component="label"
                                      color="primary"
                                      startIcon={<ArrowCircleUpIcon />}
                                      style={{ marginRight: 4 }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "600",
                                          fontSize: 14,
                                          fontFamily: "Poppins, sans-serif",
                                          color: "#626974",
                                          paddingLeft: 10,
                                        }}
                                      >
                                        {
                                          attendence_salary_type?.find(
                                            (n) =>
                                              n.domain_value ==
                                              h.attendence_salary_type_id
                                          )?.domain_key
                                        }
                                      </Typography>
                                    </Button>
                                  ))
                              )}
                            </Grid>
                          </Grid>
                        </Popover>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card> */
}
