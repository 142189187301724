import React, { FC } from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { MonthWiseData } from "../../../interfaces/projectDashboard.interface";
import dayjs from "dayjs";
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );
const Month: any = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
const getMonth = (month: string) => {
  const mIndex = Number(month.split("-")[1]);
  return Month[mIndex];
};

const MonthWiseAttendenceGraph: FC<IMonthWiseAttendenceGraph> = ({
  monthWiseAttendence,
  year,
}) => {
  //   const data = {
  //     labels,
  //     datasets: [
  //       {
  //         label: "Dataset 1",
  //         data: [140, 500, 600, 450, 230, 45],
  //         backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       },
  //       {
  //         label: "Dataset 2",
  //         data: [140, 500, 600, 450, 230, 45],
  //         backgroundColor: "rgba(53, 162, 235, 0.5)",
  //       },
  //     ],
  //   };
  const barData = monthWiseAttendence.map((self) => ({
    name: getMonth(self.month),
    working_days: Number(self.total_working_days),
    present: Number(self.total_present_days),
    absent: Number(self.total_absent_days),
  }));
  console.log("barData : ", barData);

  console.log("monthwise graph");

  return (
    <Box>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 380,
        }}
      >
        <Typography
          variant="body1"
          textAlign="center"
          gutterBottom
          sx={{ color: "#0060FF" }}
        >
          Monthwise Attendence (in days) ,{" "}
          {dayjs(year).format("DD-MMM-YYYY").split("-")[2]}
        </Typography>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={barData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="working_days" fill="#8884d8" />
            <Bar dataKey="present" fill="#66e77e" />
            <Bar dataKey="absent" fill="#ef4c6f" />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  );
};
interface IMonthWiseAttendenceGraph {
  monthWiseAttendence: MonthWiseData[];
  year: dayjs.Dayjs | null;
}
export default MonthWiseAttendenceGraph;
