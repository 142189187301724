import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { StateList } from "../../../models/customerModel";
import {
  PermanentAddress,
  PresentAddress,
} from "../../../models/employeeModel";

function EmployeeAddressDetailsPopover({
  anchorEl,
  setAnchorEl,
  present_address,
  permanent_address,
  SubmitPopData,
  state_list
}: EmployeeAddressDetailsPopoverProps) {
  const countryOptions: any[] = [
    {
      key: "India",
      value: "India",
    },
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!!present_address && !!permanent_address) {
      setValue("address_line1", present_address.address_line1);
      setValue("address_line2", present_address.address_line2);
      setValue("city", present_address.city);
      setValue("district", present_address.district);
      setValue("state", present_address.state);
      setValue("pin", present_address.pin);
      setValue("country", present_address.country);
      setValue("paddress_line1", permanent_address.address_line1);
      setValue("paddress_line2", permanent_address.address_line2);
      setValue("pcity", permanent_address.city);
      setValue("pdistrict", permanent_address.district);
      setValue("pstate", permanent_address.state);
      setValue("ppin", permanent_address.pin);
      setValue("pcountry", permanent_address.country);
    }
  }, [present_address, permanent_address]);
  const onSubmit = (data: any) => {
    handleClose();
    SubmitPopData(data);
  };
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={650}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Address Details</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            {/* <form onClick={handleSubmit(onSubmit)}> */}
            <Grid item xs={10} marginTop={1}>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 17,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "10%",
                  marginBottom: 10,
                  color: "#626974",
                }}
              >
                Present Address
              </Typography>
            </Grid>

            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Flat,House No.,Building,Apartment
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"address_line1"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{ maxLength: 100 }}
                      placeholder={"Flat,House No.,Building,Apartment"}
                      error={!!errors["address_line1"]}
                      helperText={
                        errors.address_line1 &&
                        `${errors.address_line1.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Area,Street,Sector,Village
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"address_line2"}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      inputProps={{ maxLength: 100 }}
                      value={value}
                      placeholder={"Area,Street,Sector,Village"}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  City<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"city"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      inputProps={{ maxLength: 40 }}
                      onChange={onChange}
                      value={value}
                      placeholder={"City"}
                      error={!!errors["city"]}
                      helperText={
                        errors.city &&
                        `${errors.city.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  District<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"district"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"District"}
                      inputProps={{ maxLength: 40 }}
                      error={!!errors["district"]}
                      helperText={
                        errors.district &&
                        `${errors.district.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  State<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
              <Controller
                  control={control}
                  name={"state"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={state_list}
                      style={{ width: "100.9%" }}
                      keys={"state_name"}
                      label={"state_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"State"}
                      value={!!value ? value : ""}
                      errors={!!errors["state"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Country<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"country"}
                  defaultValue={"India"}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={countryOptions}
                      keys={"key"}
                      label={"value"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Country"}
                      value={!!value ? value : ""}
                      errors={errors.country}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Pincode<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"pin"}
                  defaultValue={""}
                  rules={{ required: true, minLength: 6, maxLength: 6 }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      placeholder={"Pincode"}
                      error={!!errors["pin"]}
                      helperText={
                        errors.pin &&
                        `${errors.pin.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={10} marginTop={1}>
              <Typography
                style={{
                  fontWeight: "600",
                  fontSize: 17,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "10%",
                  marginBottom: 10,
                  color: "#626974",
                }}
              >
                Permanent Address
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Flat,House No.,Building,Apartment
                  <span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"paddress_line1"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      inputProps={{ maxLength: 100 }}
                      value={value}
                      placeholder={"Flat,House No.,Building,Apartment"}
                      error={!!errors["paddress_line1"]}
                      helperText={
                        errors.paddress_line1 &&
                        `${errors.paddress_line1.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Area,Street,Sector,Village
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"paddress_line2"}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      inputProps={{ maxLength: 100 }}
                      value={value}
                      placeholder={"Area,Street,Sector,Village"}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  City<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"pcity"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"City"}
                      inputProps={{ maxLength: 40 }}
                      error={!!errors["city"]}
                      helperText={
                        errors.city &&
                        `${errors.city.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  District<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  control={control}
                  name={"pdistrict"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"District"}
                      inputProps={{ maxLength: 40 }}
                      error={!!errors["pdistrict"]}
                      helperText={
                        errors.pdistrict &&
                        `${errors.pdistrict.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  State<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                {/* <Controller
                  control={control}
                  name={"pstate"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"text"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      placeholder={"State"}
                      error={!!errors["state"]}
                      helperText={
                        errors.state &&
                        `${errors.state.message}* This field is Required`
                      }
                    />
                  )}
                /> */}
                <Controller
                  control={control}
                  name={"pstate"}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={state_list}
                      style={{ width: "100.9%" }}
                      keys={"state_name"}
                      label={"state_name"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"State"}
                      value={!!value ? value : ""}
                      errors={!!errors["pstate"]}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Country<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} style={{ marginLeft: -5 }}>
                <Controller
                  control={control}
                  name={"pcountry"}
                  defaultValue={"India"}
                  rules={{ required: true }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <Autocompletes
                      hasInfo={false}
                      option={countryOptions}
                      keys={"key"}
                      label={"value"}
                      onBlur={onBlur}
                      onChange={(value: any) => onChange(value)}
                      placeholder={"Country"}
                      value={!!value ? value : ""}
                      errors={errors.pcountry}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="start"
              alignItems="center"
              style={{ marginTop: 5, marginBottom: 10 }}
            // width={"100%"}
            >
              <Grid item xs={5.5}>
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    fontFamily: "Poppins, sans-serif",
                    color: "#626974",
                    paddingLeft: "20%",
                    // textAlign: "center"
                  }}
                >
                  Pincode<span style={{ color: "red", fontSize: 14 }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={5.5} /*style={{ marginLeft: -5 }}*/>
                <Controller
                  control={control}
                  name={"ppin"}
                  defaultValue={""}
                  rules={{ required: true, minLength: 6, maxLength: 6 }}
                  render={({ field: { onBlur, value, onChange } }) => (
                    <TextField
                      type={"number"}
                      id="standard-required"
                      variant="outlined"
                      className={"smaller"}
                      style={{ width: "100.9%" }}
                      onBlur={onBlur}
                      size="small"
                      onChange={onChange}
                      value={value}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      placeholder={"Pincode"}
                      error={!!errors["ppin"]}
                      helperText={
                        errors.ppin &&
                        `${errors.ppin.message}* This field is Required`
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                //   marginRight: "2rem",
                marginRight: "0px",
                marginTop: "13px",
                marginBottom: 1,
              }}
            >
              <Button
                // type="submit"
                variant="outlined"
                size="small"
                sx={{
                  marginRight: 1,
                }}
                onClick={handleSubmit(onSubmit)}
              // onClick={()=>(handleSubmit(onSubmit),handleClose())}
              >
                Update
              </Button>
            </Box>
            {/* </form> */}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default EmployeeAddressDetailsPopover;

interface EmployeeAddressDetailsPopoverProps {
  anchorEl?: any;
  setAnchorEl?: any;
  present_address?: PresentAddress;
  permanent_address?: PermanentAddress;
  SubmitPopData?: any;
  state_list: StateList[];
}
