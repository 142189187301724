import { FC, useEffect, useState } from "react";
import { IBulkUploadStatus } from "../../../models/employeeModel";

const Label: FC<ILabel> = ({ row, candidateBulkUploadList }) => {
  const [dot, Setdot] = useState<string>("");
  let timer: NodeJS.Timer;
  useEffect(() => {
    if (
      !!candidateBulkUploadList &&
      candidateBulkUploadList.length > 0 &&
      !!candidateBulkUploadList.find((m) => m.upload_status == "UPLOADING")
    ) {
      if (!timer) {
        timer = setInterval(() => {
          if (dot.length >= 4) {
            Setdot(".");
          } else {
            Setdot(dot + ".");
          }
        }, 1000);
      }
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [candidateBulkUploadList, dot]);
  return (
    <div
      style={{
        width: "70%",
        backgroundColor:
          row.upload_status == "UPLOADING"
            ? "#fcba03"
            : row.upload_status == "UPLOADED"
            ? "green"
            : "red",
        textAlign: "center",
        color: "#ffff",
      }}
    >
      {row?.upload_status == "UPLOADING"
        ? "In-Progress" + dot
        : row.upload_status == "UPLOADED"
        ? "Done"
        : "Failed"}
    </div>
  );
};

interface ILabel {
  row: IBulkUploadStatus;
  candidateBulkUploadList: IBulkUploadStatus[];
}

export default Label;
