import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  NativeSelect,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  AttendanceApprovalList,
  AttendanceDashboardView,
  PendingApprovalList,
} from "../../../models/attendanceModel";
import moment from "moment";
import { FilterList } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  AllDesignationList,
  OrganizationALlList,
  SalaryStructureList,
} from "../../../models/organizationModels";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ActionStatusPopover from "../../employment/ActionStatusPopover";
import { CheckActionStatusPayload } from "../../../models/employeeModel";
import {
  PromotionListDet,
  PromotionListDetails,
  ViewPromotion,
} from "../../../models/promotionModel";
import AddIcon from "@mui/icons-material/Add";
import PromotionDetailView from "./PromotionDetailView";
import { IEmployeeList } from "../../../models/timingSheetModel";
import PromotionBatchDetailView from "../PromotionDetails/PromotionBatchDetailView";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoIcon from '@mui/icons-material/Info';

function PromotionListView({
  promotion_list,
  Submit,
  view_promotion,
  ViewPromDet,
  designation_list,
  salary_structure_list,
  FilterClear,
  control,
  getValues,
  setValue,
  errors,
  handleSubmit,
  searchEmployeeList,
  watch,
  searchByNameEmpAction,
  ViewPromotionAction,
  DeletePromBatch,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
  ActivateSalary,
}: PromotionListViewProps) {
  const navigate = useNavigate();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const styles = {
    marginRight: "50px",
  };
  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  const column1: any[] = [
    "Promotion Batch No.",
    "Created On",
    "Promotion Created By",
    "Status",
    "Action",
  ];
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<PromotionListDetails[]>([]);
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!promotion_list?.data
      ? promotion_list.data.filter((row: PromotionListDetails) => {
        return (
          row?.promotion_salary_batch_id
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.batch_status_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  useEffect(() => {
    if (!!promotion_list?.data) {
      cancelSearch();
    }
  }, [promotion_list?.data]);

  // useEffect(() => {
  //   if (!!promotion_list?.data) {
  //     setRows(promotion_list.data);
  //   }
  // }, [promotion_list?.data]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [expanded, setExpanded] = useState<string | false>("panel");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  // const [selectedDate, setSelectedDate] = React.useState<any>();
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);
  const onSubmit = (data: any) => {
    Submit(data);
    setExpanded(false);
  };
  const status: any = [
    { key: 1, value: "Initiated" },
    { key: 2, value: "Approved" },
    { key: 3, value: "Rejected" },
    { key: 7, value: "Processed" },
  ];

  const [openModal, setopenModal] = useState<boolean>(false);
  // let timmer: any;
  // const GetCall = (searchVal: string) => {
  //   console.log(searchVal);
  //   if (!!timmer) {
  //     clearTimeout(timmer);
  //   }
  //   timmer = setTimeout(() => {
  //     searchByNameEmpAction(searchVal, "");
  //   }, 2000);
  // };
  useEffect(() => {
    if (!!getValues("employee_name")) {
      setValue("employee_id", getValues("employee_name"));
    }
  }, [watch("employee_name")]);
  const [final_flag, setfinalFlag] = useState<boolean>(false);
  const [active_flag, setActive_flag] = useState<boolean>(false);

  const [approvePayload, setapprovePayload] = useState<any>();
  const DeletePromDet = (data: any) => {
    console.log("DeletePromDet", data);
    let payload: any = {
      promotion_salary_batch_id: data.promotion_salary_batch_id,
      promotion_revision_id: [],
    };
    setapprovePayload(payload);
    setfinalFlag(true);
  };
  console.log("pagesize", pagesize);
  console.log("pageindex", pageindex);
  console.log("rowperpage", rowperpage);

  const ActiveSalary = () => {
    ActivateSalary();
  };
  return (
    <>
      {/* <PromotionDetailView
        openModal={openModal}
        setopenModal={setopenModal}
        view_promotion={view_promotion}
      /> */}
      <Dialog
        open={final_flag}
        onClose={() => setfinalFlag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to do this action?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setfinalFlag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (
              DeletePromBatch(approvePayload),
              setfinalFlag(false),
              setapprovePayload("")
            )}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={active_flag}
        onClose={() => setActive_flag(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to do Active Salary Action?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setActive_flag(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => (ActiveSalary(), setActive_flag(false))}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                {/* <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee ID
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"employee_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            size="small"
                            value={!!value ? value : ""}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Employee ID"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee Name
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"employee_name"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={searchEmployeeList}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"employee_id"}
                            label={"employee_name"}
                            onInputChange={(e: any) => {
                              if (e?.target?.value) GetCall(e.target.value);
                            }}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Employee"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                      <Controller
                        control={control}
                        name={"batch_status"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={status}
                            keys={"key"}
                            label={"value"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search By Status"}
                            value={!!value ? value : ""}
                            errors={!!errors["batch_status"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Revision Status
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                      <Controller
                        control={control}
                        name={"revision_status"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={status}
                            keys={"key"}
                            label={"value"}
                            // readOnly={!!designation ? edit_flag : !edit_flag}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Revision Status"}
                            value={!!value ? value : ""}
                            errors={!!errors["revision_status"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Designation
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                      <Controller
                        control={control}
                        name={"new_designation_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={designation_list}
                            keys={"designation_id"}
                            label={"designation_name"}
                            // readOnly={!!designation ? edit_flag : !edit_flag}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Designation"}
                            value={!!value ? value : ""}
                            errors={!!errors["new_designation_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Salary Structure
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                      <Controller
                        control={control}
                        name={"new_salary_structure_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={salary_structure_list}
                            keys={"salary_structure_id"}
                            label={"salary_stucture_name"}
                            // readOnly={!!designation ? edit_flag : !edit_flag}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Salary Structure"}
                            value={!!value ? value : ""}
                            errors={!!errors["new_salary_structure_id"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    marginRight: 10,
                  }}
                  onClick={FilterClear}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Clear
                </Button>
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Filter
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>

      <Paper
        elevation={3}
        sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={3}>
            <Button
              onClick={() => navigate("/promotion/add-promotion")}
              size="medium"
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Add New Promotion
            </Button>
          </Grid>
          <Grid item xs={4.5} sx={{ marginTop: 3, marginBottom: 3 }}>
            <TextField
              size="small"
              sx={{ width: "80%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => setActive_flag(true)}
              size="medium"
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Active Salary
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Active Salary button need to activate  at the last day of month if any approved promotion or revision is present">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container direction={"row"}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-root:hover": {
                      // backgroundColor: "#73c6ff",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows.map((row) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.promotion_salary_batch_id}
                        >
                          <StyledTableCell align="left" key={column1[0]}>
                            {row.promotion_salary_batch_id}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[1]}>
                            {row.created_on
                              ? moment(row.created_on)
                                .format("DD-MM-YYYY")
                                .toUpperCase()
                              : "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[2]}>
                            {row.created_by + " (" + row.created_by_name + ")"}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[3]}>
                            {row.batch_status_name}
                          </StyledTableCell>
                          <StyledTableCell align="left" key={column1[4]}>
                            <Tooltip
                              title="Promotion Details"
                              onClick={() =>
                                navigate(
                                  `/promotion/promotion-details-view/${row.promotion_salary_batch_id}`
                                )
                              }
                            // onClick={() => (
                            //   ViewPromDet(row.promotion_revision_id),
                            //   setopenModal(true)
                            // )}
                            >
                              <IconButton>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            {row.batch_status !== 2 &&
                              row.batch_status !== 7 && (
                                <Tooltip
                                  title="Delete In-Active Batch"
                                  onClick={() => DeletePromDet(row)}
                                >
                                  <IconButton>
                                    <DeleteIcon color="warning" />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={10}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                sx={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                //count={20}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              <Grid container alignItems={"center"} justifyContent="end">
                <Grid xs={2} textAlign="right">
                  Rows per page:
                </Grid>
                <Grid xs={1} textAlign="center">
                  <NativeSelect
                    value={pagesize.toString()}
                    onChange={(event) => SetpageSize(+event.target.value)}
                  >
                    {rowperpage.map((p) => (
                      <option
                        key={p}
                        value={p}
                        onChange={(value: any) => value(p)}
                      >
                        {p}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    onClick={() => Setpageindex(pageindex - 1)}
                    color="primary"
                    size="small"
                    disabled={pageindex <= 0}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <Typography>
                    {pageindex * pagesize + 1} -{" "}
                    {!!total_size && total_size <= (pageindex + 1) * pagesize
                      ? total_size
                      : (pageindex + 1) * pagesize}
                    {" of "}
                    {total_size}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => Setpageindex(pageindex + 1)}
                    disabled={
                      (!!total_size &&
                        total_size <= (pageindex + 1) * pagesize) ||
                      total_size === 0
                    }
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default PromotionListView;

interface PromotionListViewProps {
  promotion_list?: PromotionListDet;
  Submit?: any;
  view_promotion: ViewPromotion[];
  ViewPromDet?: any;
  designation_list: AllDesignationList[];
  salary_structure_list: SalaryStructureList[];
  FilterClear?: any;
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  searchEmployeeList: IEmployeeList[];
  watch?: any;
  searchByNameEmpAction?: any;
  ViewPromotionAction?: any;
  DeletePromBatch?: any;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  ActivateSalary?: any;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
