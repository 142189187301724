import React from "react";
import { connect } from "react-redux";
import { environment } from "../../../environment";
import {
  ApproveMargePayload,
  ApproveRejectPayload,
  EventList,
  ReportingManagerList,
} from "../../../models/employeeModel";
import {
  DomainList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import {
  CandidateApproveAction,
  GetAllEventStausAction,
  GetAttendenceSalarytypeAction,
  getReportingManagerList,
} from "../../../stores/actions/employeeAction";
import {
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
} from "../../../stores/actions/organizationActions";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import OnboardingRequestAppRejView from "./OnboardingRequestAppRejView";

function OnboardingRequestApprovalReject({
  event_list,
  GetAllEventStausAction,
  user,
  GetAllSalaryStructureAction,
  salary_structure_list,
  SalaryComponentEditAction,
  salary_structure,
  getReportingManagerList,
  ReportingManagerList,
  CandidateApproveAction,
  filter,
  GetAttendenceSalarytypeAction,
  attendence_salary_type,
  SidenavAutoCloseOpen,
}: OnboardingRequestApprovalRejectProps) {
  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    GetAllEventStausAction({
      approverDesigId: user?.designation,
      eventType: environment.global_veriable.OnBoardingeventType,
      action: null,
    });
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);
  const ApprovedAction = (payload: ApproveRejectPayload, data: string) => {
    let Actionpayload: ApproveMargePayload = {
      ApproveRejectPayload: payload,
      EventStatusPayload: {
        approverDesigId: user?.designation,
        eventType: environment.global_veriable.OnBoardingeventType,
        action: data,
      },
    };
    CandidateApproveAction(Actionpayload);
  };
  const Filterdata = (data: string) => {
    GetAllEventStausAction({
      approverDesigId: user?.designation,
      eventType: environment.global_veriable.OnBoardingeventType,
      action: data,
    });
  };
  return (
    <OnboardingRequestAppRejView
      GetAllSalaryStructureAction={GetAllSalaryStructureAction}
      event_list={event_list}
      salary_structure_list={salary_structure_list}
      SalaryComponentEditAction={SalaryComponentEditAction}
      salary_structure={salary_structure}
      getReportingManagerList={getReportingManagerList}
      ReportingManagerList={ReportingManagerList}
      ApprovedAction={ApprovedAction}
      filter={filter}
      Filterdata={Filterdata}
      GetAttendenceSalarytypeAction={GetAttendenceSalarytypeAction}
      attendence_salary_type={attendence_salary_type}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    event_list: state.employee.event_list,
    user: state.user.userDetails,
    salary_structure_list: state.organization.salary_structure_list,
    salary_structure: state.organization.salary_structure_edit,
    ReportingManagerList: state.employee.ReportingManagerList,
    filter: state.employee.filter,
    attendence_salary_type: state.employee.attendence_salary_type,
  };
};
const mapDispatchToProps = {
  GetAllEventStausAction,
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
  getReportingManagerList,
  CandidateApproveAction,
  GetAttendenceSalarytypeAction,
  SidenavAutoCloseOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingRequestApprovalReject);
interface OnboardingRequestApprovalRejectProps {
  GetAllEventStausAction?: any;
  event_list: EventList[];
  user?: userDetails;
  GetAllSalaryStructureAction?: any;
  salary_structure_list: SalaryStructureList[];
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  getReportingManagerList?: any;
  ReportingManagerList: ReportingManagerList[];
  CandidateApproveAction?: any;
  filter: string[];
  GetAttendenceSalarytypeAction?: any;
  attendence_salary_type: DomainList[];
  SidenavAutoCloseOpen?: any;
}
