import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { ProjectDocumentsTypes } from "../../../interfaces/others.interface";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Box,
  DialogContentText,
  Button,
  DialogActions,
  Alert,
} from "@mui/material";

interface ProjectDocumentsProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isProjectExists: boolean;
  isEditMode: boolean;
  documents: ProjectDocumentsTypes[];
  editDocumentHandler: any;
  deleteDocumentHandler: (
    document_id?: number,
    document_image?: string
  ) => void;
  downloadDocument: any;
  document: ProjectDocumentsTypes[];
  showAlertForUploadDoc: boolean;
  setShowAlertUploadDoc: Dispatch<SetStateAction<boolean>>;
}

const ProjectDocuments: FC<ProjectDocumentsProps> = ({
  isEditMode,
  isProjectExists,
  documents,
  editDocumentHandler,
  deleteDocumentHandler,
  isOpen,
  setIsOpen,
  downloadDocument,
  setShowAlertUploadDoc,
  showAlertForUploadDoc,
}): JSX.Element => {
  const fileRef = useRef<any>();

  const callback = () => {
    if (fileRef?.current) fileRef.current.value = "";
    setShowAlertUploadDoc(true);
  };
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ m: 0, p: 0 }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#0060ff",
            px: 5,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 1,
          }}
        >
          <Typography sx={{ fontSize: 20 }}>
            Manage Work Order Documents
          </Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ my: 2, overflowX: "hidden" }}>
          {showAlertForUploadDoc ? (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Please save/update this project to persists this newly uploaded
              document's
            </Alert>
          ) : (
            <></>
          )}

          {!(!!isProjectExists ? !isEditMode : isEditMode) ? (
            <Button
              variant="outlined"
              component="label"
              sx={{ mb: 2, ml: 1, mt: 2 }}
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              <input
                onChange={(event: any) => {
                  editDocumentHandler({ event, callback });
                }}
                hidden
                ref={fileRef}
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                type="file"
              />
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // color: "#FFFF",
                  paddingLeft: 10,
                  // textAlign: "center"
                }}
              >
                Upload
              </Typography>
            </Button>
          ) : (
            <></>
            // <Grid container direction={"row"} justifyContent="start">
            //   <Grid>
            //     <CheckCircleIcon color="success" />
            //   </Grid>
            //   <Grid alignItems="start">
            //     {" "}
            //     <Typography
            //       style={{ color: "#99A09C", fontWeight: "700" }}
            //     >
            //       Successfully Uploaded
            //     </Typography>
            //   </Grid>
            //   <Grid
            //     item
            //     xs={12}
            //     alignItems="start"
            //     textAlign={"left"}
            //   >
            //     <Button
            //       onClick={() => downloadImage()}
            //       variant="text"
            //     >
            //       Download Document
            //     </Button>
            //     {!(!!project ? edit_flag : !edit_flag) && (
            //       <>
            //         <Button
            //           onClick={() => Setdocument(undefined)}
            //           variant="text"
            //         >
            //           Change Document
            //         </Button>
            //       </>
            //     )}
            //   </Grid>
            // </Grid>
          )}

          <Grid
            container
            rowGap={4.5}
            spacing={1}
            sx={{ my: 1, ml: 1 }}
            columnGap={2.6}
          >
            {documents.map((self, index, array) => (
              <FileBlock
                document={self}
                key={index}
                editDocumentHandler={editDocumentHandler}
                deleteDocumentHandler={deleteDocumentHandler}
                downloadDocHandler={downloadDocument}
                isEditMode={isEditMode}
                isProjectExists={isProjectExists}
                totalCount={array.length}
                setShowAlertUploadDoc={setShowAlertUploadDoc}
              />
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

/***
 * Individual file block
 * ***/
interface FileBlockProps {
  editDocumentHandler: any;
  deleteDocumentHandler: any;
  downloadDocHandler: any;
  document: ProjectDocumentsTypes;
  isEditMode: boolean;
  isProjectExists: boolean;
  totalCount: number;
  setShowAlertUploadDoc: Dispatch<SetStateAction<boolean>>;
}
const FileBlock: FC<FileBlockProps> = ({
  editDocumentHandler,
  deleteDocumentHandler,
  downloadDocHandler,
  document,
  isEditMode,
  isProjectExists,
  totalCount,
  setShowAlertUploadDoc,
}): JSX.Element => {
  const fileRef = useRef<any>();
  const clearVal = () => {
    if (!!fileRef && fileRef?.current) {
      fileRef.current.value = "";
      setShowAlertUploadDoc(true);
    }
  };
  const [ConfirmBoxOpen, setConfirmBoxOpen] = useState<boolean>(false);
  return (
    <Grid
      item
      xs={12}
      md={1.5}
      sx={{
        height: "200px",
        boxShadow: "0px 1px 7px 1px rgba(0,0,0,0.5)",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <DeleteDocumentConfirmBox
        open={ConfirmBoxOpen}
        onClose={setConfirmBoxOpen}
        handleConfirm={() => {
          deleteDocumentHandler({
            document_id: document.document_id,
            document_name: document.document_image,
          });
          setConfirmBoxOpen(false);
        }}
      />
      <Box display="flex" justifyContent={"center"} alignItems={"center"}>
        <DescriptionIcon sx={{ fontSize: 80, color: "gray" }} />
      </Box>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          my: 1,
          mx: 1,
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            display: "flex",
            flexWrap: "wrap",
            wordWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <p>{document.document_image}</p>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          my: 1,
          mx: 1,
          px: 1,
          position: "absolute",
          left: "0%",
          bottom: "-8%",
          borderRadius: "5px",
          width: "80.5%",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
        }}
      >
        <Box
          onClick={() => downloadDocHandler(document.document_image)}
          sx={{
            px: 2,
            //
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 0.5,
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <CloudDownloadIcon sx={{ color: "#1565c0" }} />
        </Box>
        {(isProjectExists && isEditMode) || !isProjectExists ? (
          <Fragment>
            <Box
              component="label"
              sx={{
                px: 2,
                //
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 0.5,
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <input
                ref={fileRef}
                onChange={(event: any) =>
                  editDocumentHandler({
                    event,
                    document_id: document.document_id,
                    document_name: document.document_image,
                    callback: clearVal,
                  })
                }
                hidden
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                type="file"
              />
              <EditIcon sx={{ color: "#1565c0" }} />
            </Box>
            {/* {totalCount > 1 ? (
              <Box
                // onClick={() =>
                //   deleteDocumentHandler({
                //     document_id: document.document_id,
                //     document_name: document.document_image,
                //   })
                // }
                onClick={() => setConfirmBoxOpen(true)}
                sx={{
                  px: 2,
                  // border: "1px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 0.5,
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <DeleteIcon sx={{ color: "red" }} />
              </Box>
            ) : (
              <></>
            )} */}
          </Fragment>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
};

interface DeleteDocumentConfirmBoxProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  handleConfirm: any;
}
const DeleteDocumentConfirmBox: FC<DeleteDocumentConfirmBoxProps> = ({
  open,
  onClose,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Are you sure!!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You want to permanently delete this document
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Revert</Button>
        <Button onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectDocuments;
