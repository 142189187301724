import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  AllDesignationList,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  GetAllRoleMasterList,
  UserRoleMappingList,
} from "../../../models/userManagementModel";
import {
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
} from "../../../stores/actions/organizationActions";
import {
  GetAllRoleMasterListAction,
  RoleAssignToUserAction,
  UserRoleMappingAction,
} from "../../../stores/actions/userManagementAction";
import RoleMappingView from "./RoleMappingView";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";
import { IEmployeeList } from "../../../models/timingSheetModel";
const RoleMapping = ({
  user_role_mapping_list,
  UserRoleMappingAction,
  GetAllEmployeeTypeAction,
  employee_type_list,
  designation_list,
  GetAllDesignationAction,
  RoleAssignToUserAction,
  GetAllRoleMasterListAction,
  all_role_master,
  total_size,
  searchByNameEmpAction,
  employeeList,
}: RoleMappingProps) => {
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  React.useEffect(() => {
    GetAllEmployeeTypeAction({ organization_id: null });
    GetAllDesignationAction({ organization_id: null });
    GetAllRoleMasterListAction();
  }, []);
  useEffect(() => {
    UserRoleMappingAction({ page_size: pagesize, page_index: pageindex });
  }, [pagesize, pageindex]);
  const [roleUpdateData, setRoleUpdateData] = useState<any>("");
  const RoleMappingFilter = (data: any) => {
    UserRoleMappingAction({
      employee_id: data.employee_id,
      employee_mob_no: data.employee_mob_no,
      employee_type_id: data.employee_type_id,
      designation_id: data.designation_id,
      page_size: pagesize,
      page_index: pageindex,
    });
    setRoleUpdateData({
      employee_id: data.employee_id,
      employee_mob_no: data.employee_mob_no,
      employee_type_id: data.employee_type_id,
      designation_id: data.designation_id,
      page_size: pagesize,
      page_index: pageindex,
    });
  };

  const Clear = () => {
    UserRoleMappingAction({
      employee_id: null,
      employee_mob_no: null,
      employee_type_id: null,
      designation_id: null,
      page_size: pagesize,
      page_index: pageindex,
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const RoleUpdate = (props: any) => {
    RoleAssignToUserAction({
      data: props,
      enqueueSnackbar: enqueueSnackbar,
      updateData: roleUpdateData,
    });
  };
  return (
    <RoleMappingView
      employee_type_list={employee_type_list}
      designation_list={designation_list}
      user_role_mapping_list={user_role_mapping_list}
      all_role_master={all_role_master}
      RoleMappingFilter={RoleMappingFilter}
      Clear={Clear}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      RoleUpdate={RoleUpdate}
      employeeList={employeeList}
      searchByNameEmpAction={searchByNameEmpAction}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    user_role_mapping_list: state.userManagement.user_role_mapping_list,
    employee_type_list: state.organization.employee_type_list,
    designation_list: state.organization.designation_list,
    all_role_master: state.userManagement.all_role_master,
    total_size: state.userManagement.user_role_mapping_list?.total_count,
    employeeList: state.timingSheet.employeeList,
  };
};
const mapDispatchToProps = {
  UserRoleMappingAction,
  GetAllEmployeeTypeAction,
  GetAllDesignationAction,
  RoleAssignToUserAction,
  GetAllRoleMasterListAction,
  searchByNameEmpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleMapping);
interface RoleMappingProps {
  user_role_mapping_list?: UserRoleMappingList;
  UserRoleMappingAction?: any;
  GetAllEmployeeTypeAction?: any;
  GetAllDesignationAction?: any;
  GetAllRoleMasterListAction?: any;
  employee_type_list: EmployeeTypeList[];
  designation_list: AllDesignationList[];
  RoleAssignToUserAction?: any;
  all_role_master: GetAllRoleMasterList[];
  total_size?: number;
  employeeList: IEmployeeList[];
  searchByNameEmpAction?: any;
}
