import { Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import { environment } from "../../environment";
import AllBatch from "./AllBatch";
import ApprovedBatch from "./ApprovedBatch";
import BatchDetail from "./BatchDetail";
import CancelledBatch from "./CancelledBatch";
import PaidBatch from "./PaidBatch";
import PayrollBatch from "./PayrollBatch";
import Payloaddashboard from "./PayrollDashboard";
import PendingApproval from "./PendingApproval";
import RegisterPayroll from "./RegisterPayroll";
import RejectedBatch from "./RejectedBatch";

const Payroll = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const getTitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.item_name;
    }
    if (!!item && !item?.has_submenu) {
      title = item.name;
    }
    return title;
  };
  const getSubtitle = () => {
    let title: string | any = "";
    let item = environment.sideNav.find(
      (m) => m.url.split("/")[1] == location.pathname.split("/")[1]
    );
    if (!!item && item?.has_submenu) {
      title = item.menuItem.find(
        (m) =>
          m.url == location.pathname ||
          m.sub_url.find((n) => n === location.pathname)
      )?.sub_title;
    }
    if (!!item && !item?.has_submenu) {
      title = item.sub_title;
    }
    return title;
  };
  return (
    <>
      {!!getTitle() && (
        <Grid style={{ marginLeft: 20, padding: 20 }}>
          <Typography
            style={{
              // color: "#50535A",
              // fontWeight: "bold",
              // fontFamily: "Poppins, sans-serif",
              // fontSize: 18,
              color: "#132437",
              fontWeight: 600,
              fontFamily: "Alef, sans-serif",
              fontSize: 30
            }}
          >
            {getTitle()}
          </Typography>
          <Typography
            style={{
              // color: "#D4D7DD",
              // fontSize: 14,
              // fontFamily: "Poppins, sans-serif",
              color: "#132437",
              fontWeight: 400,
              fontFamily: "Alata, sans-serif",
              fontSize: 15
            }}
          >
            {getSubtitle()}
          </Typography>
        </Grid>
      )}
      {!!getTitle() && <Divider style={{ width: "95%", marginLeft: 30 }} />}
      <Routes>
        <Route path="register-payroll" element={<RegisterPayroll />} />
        <Route path="pending-approval" element={<PendingApproval />} />
        <Route path="payroll-batch" element={<PayrollBatch />} />
        <Route path="batch-detail/:id/:id2" element={<BatchDetail />} />
        <Route path="approved-batch" element={<ApprovedBatch />} />
        <Route path="all-batch" element={<AllBatch />} />
        <Route path="paid-batch" element={<PaidBatch />} />
        <Route path="cancelled-batch" element={<CancelledBatch />} />
        <Route path="rejected-batch" element={<RejectedBatch />} />
        <Route path="payload-dashboard" element={<Payloaddashboard />} />
        <Route
          path="/"
          element={
            <Navigate to={`/${pathname.split("/")[1]}/register-payroll`} />
          }
        />
      </Routes>
    </>
  );
};

export default Payroll;
