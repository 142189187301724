import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  Button,
  AccordionDetails,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Autocompletes from "../../../library/AutoComplete";
import {
  SalaryStuctureComponent,
  DomainList,
} from "../../../models/organizationModels";
import { EachComponent } from "./AddSalarystructureView";
import EachRow from "./EachRow";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
const SalaryComponenet = ({
  component,
  component_type,
  component_master,
  setComponent,
  calculation_type,
  operator,
  global_key,
  operator_tye,
  edit_flag,
}: SalaryComponentProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [component_type_option, SetComponentTypeOption] = useState<
    number | undefined
  >(undefined);

  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) return;
    var updatedList = [
      ...component_master.filter(
        (m) => m.component_type == component_type?.domain_value
      ),
    ];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    updatedList.forEach(
      (m, index) => ((m.seq_no = index + 1), (m.calculation = undefined))
    );
    setComponent([
      ...updatedList,
      ...component_master.filter(
        (m) => m.component_type != component_type?.domain_value
      ),
    ]);
  };
  const AddNewComponet = () => {
    let res = JSON.parse(JSON.stringify(component_master));
    let com = component.find((m) => m.compent_id == component_type_option);
    res = [
      ...res,
      {
        compent_id: component_type_option,
        attendace_dependancy: com?.attendace_dependancy,
        salary_priod: com?.salary_priod,
        default_value: null,
        component_type: component_type?.domain_value,
        seq_no:
          component_master.filter(
            (m) => m.component_type == com?.component_type
          ).length + 1,
        component_name: com?.component_name,
        component_type_name: com?.component_type_name,
        salary_priod_name: com?.salary_priod_name,
        calculation: null,
        default: com?.default,
      },
    ];
    setComponent(res);
    SetComponentTypeOption(undefined);
  };
  const onChangeValue = (
    value: any,
    component_id: number,
    field_name: string
  ) => {
    let res = JSON.parse(JSON.stringify(component_master));
    let comp = res.find((m: any) => m.compent_id == component_id);
    comp[field_name] = value;
    setComponent(res);
  };
  const DeleteComponent = (id: number) => {
    let res = JSON.parse(JSON.stringify(component_master));
    let filter_list = res.filter((m: any) => m.compent_id != id);
    setComponent(filter_list);
  };
  const EvaluateRule = (rule: any): any => {
    return (
      (rule.key1?.type == 1
        ? component_master.find((m) => m.compent_id == +rule.key1?.value)
            ?.component_name
        : rule.key1?.type == 2
        ? global_key.find((m) => m.domain_value == +rule.key1?.value)
            ?.domain_key
        : rule.key1?.type == 4
        ? rule.key1?.value
        : rule.key1?.type == 3 && EvaluateRule(rule.key1?.value)) +
      " " +
      (rule.operator?.type == 1
        ? operator.find((m) => m.domain_value == +rule.operator?.value)
            ?.domain_key
        : "") +
      " " +
      (rule.key2?.type == 1
        ? component_master.find((m) => m.compent_id == +rule.key2?.value)
            ?.component_name
        : rule.key2?.type == 2
        ? global_key.find((m) => m.domain_value == +rule.key2?.value)
            ?.domain_key
        : rule.key2?.type == 4
        ? rule.key2?.value
        : rule.key2?.type == 3 && EvaluateRule(rule.key2?.value))
    );
  };

  return (
    <Accordion
      disableGutters
      elevation={2}
      expanded={expanded === "panel1"}
      onChange={handleChange1("panel1")}
      style={{
        backgroundColor: "transparent",
        marginTop: 15,
        marginBottom: 15,
        width: "100%",
        borderRadius: 5,
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            backgroundColor: "#42a5f5",
            justifyContent: "space-between",
            marginTop: -2,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          onClick={() => handleChange1("panel1")}
        >
          <Grid
            justifyContent={"center"}
            alignSelf={"center"}
            style={{ paddingLeft: "10px" }}
          >
            <Typography
              style={{
                fontWeight: "500",
                fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                // color: "#626974",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {component_type?.domain_key}
            </Typography>
          </Grid>
          <Grid
            justifyContent={"flex-end"}
            alignContent={"flex-end"}
            style={{ marginRight: "4%" }}
          >
            <Button
              variant="text"
              endIcon={<FilterListIcon />}
              sx={{ color: "#fff" }}
              onClick={() => handleChange1("panel1")}
            ></Button>
          </Grid>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {!edit_flag && (
          <Grid
            container
            display={"flex"}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 15, marginBottom: 30 }}
            // width={"100%"}
          >
            <Grid item xs={3}>
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: 16,
                  fontFamily: "Poppins, sans-serif",
                  color: "#626974",
                  // paddingLeft: "12%",
                  // textAlign: "center"
                }}
              >
                Component Name
                <span style={{ color: "red", fontSize: 14 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4.7} style={{ marginLeft: "1%"}}>
              <Autocompletes
                style={{width: "400px"}}
                hasInfo={false}
                option={component.filter(
                  (m) =>
                    !component_master.find((n) => n.compent_id == m.compent_id)
                )}
                keys={"compent_id"}
                label={"component_name"}
                extras={"component_type_name"}
                onChange={(value: any) => SetComponentTypeOption(value)}
                placeholder={"Component Name"}
                value={component_type_option}
              />
            </Grid>
            <Grid item xs={1.9} style={{ marginLeft: "10%" }}>
              <Button
                onClick={() => !!component_type_option && AddNewComponet()}
                size="medium"
                variant="outlined"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction={"row"}
          display={"flex"}
          justifyContent="center"
          alignItems="center"
          // marginX={5}
          width={"100%"}
          style={{ backgroundImage: "linear-gradient(to right, #132437 , #606065)", padding: 2, color: "#ffff" }}
        >
          <Grid
            item
            xs={2}
            style={{
              textAlign: "center",
            }}
          >
            <Typography style={{ fontSize: 14 }}>Component Name</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 14 }}>Salary Period</Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center", marginRight: -12 }}>
            <Typography style={{ fontSize: 14 }}>
              Attendance Dependency
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 14 }}>Default Value</Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 14 }}>Calculation Rule</Typography>
          </Grid>
          <Grid item xs={1} style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 14 }}>Action</Typography>
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable isDropDisabled={edit_flag} droppableId="list-container">
            {(provided: any) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {!!component_master &&
                  component_master
                    .filter(
                      (m) => m.component_type == component_type?.domain_value
                    )
                    .map((item, index) => (
                      <EachRow
                        DeleteComponent={DeleteComponent}
                        EvaluateRule={EvaluateRule}
                        calculation_type={calculation_type}
                        index={index}
                        item={item}
                        onChangeValue={onChangeValue}
                        operator={operator}
                        component_master={component_master}
                        global_key={global_key}
                        operator_tye={operator_tye}
                        key={index}
                        edit_flag={edit_flag}
                      />
                    ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Typography style={{ fontSize: 11, color: "#132437", padding:2 }}>* You can drag and drop each component and that order of the component reflects on the Payslip.</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

interface SalaryComponentProps {
  component: SalaryStuctureComponent[];
  component_type?: DomainList;
  component_master: EachComponent[];
  setComponent: any;
  calculation_type: DomainList[];
  operator: DomainList[];
  operator_tye: DomainList[];
  global_key: DomainList[];
  edit_flag: boolean;
}

export default SalaryComponenet;
