import { log } from "console";
import { baseUrl, environment } from "../environment";
import {
  AddCustomerPayload,
  AddProjectPayload,
  AllocateEmpPayload,
  BudgetDashboardDetailsPayload,
  CompletedProjectListPayload,
  ConsolidatedReportPayload,
  createBudgetingPayload,
  CreateProjectPaymentPayload,
  CustomerAllPayload,
  CustomerEditPayload,
  EmpMonthlySalaryProjectwisePayload,
  GetAllAvailableProjectForClosurePayload,
  GetLevelsPayload,
  ICreateLevelMaster,
  IntiateProjectClosurePayload,
  MonthWiseEmpSalaryinBudgetingPayload,
  PaymentInfoPayload,
  ProjectAllPayload,
  projectBudgetingEditPayload,
  ProjectClosureApprovalPayload,
  ProjectClosurePendingListPayload,
  ProjectEditPayload,
  ProjectPaymentDeletePayload,
  ProjectPaymentEditPayload,
  ProjectUnudgetListPayload,
  SearchAvailableEmpPayload,
  ViewProject,
} from "../models/customerModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const GetAllCustomerService = (data: CustomerAllPayload) => {
  return serviceClient.post(environment.urls.getAllCustomer, data);
};
export const CustomerAddService = (data: AddCustomerPayload) => {
  return serviceClient.post(environment.urls.AddCustomerPayload, data);
};
export const CustomerEditService = (data: CustomerEditPayload) => {
  return serviceClient.post(environment.urls.editCustomerList, data);
};
export const GetAllProjectService = (data: ProjectAllPayload) => {
  return serviceClient.post(environment.urls.getAllProject, data);
};
export const ProjectAddService = (data: AddProjectPayload) => {
  return serviceClient.post(environment.urls.AddProjectPayload, data);
};
export const GetProjectViewService = (data: ViewProject) => {
  return serviceClient.get(environment.urls.getProjectViewPayload + data);
};
export const ProjectEditService = (data: ProjectEditPayload) => {
  return serviceClient.post(environment.urls.editProject, data);
};
export const DivisionGetAll = () => {
  return serviceClient.get(environment.urls.divisionGetAllUrl);
};
export const SubDivisionGetAll = () => {
  return serviceClient.get(environment.urls.subDivisionGetALLUrl);
};
export const ProjectAttendaceTemplateService = () => {
  return serviceClient.get(environment.urls.projectAttendaceTemplate);
};
export const ProjectAllLevelsService = () => {
  return serviceClient.get(environment.urls.getAllLevelsForProject);
};
export const ProjectDashboardService = (projectId: number, year: number) => {
  return serviceClient.get(
    `${environment.urls.getProjectDashboardDetails}/${projectId}/${year}`
  );
};
export const attendanceForProjectDashboardService = (
  projectId: number,
  month: number,
  year: number
) => {
  return serviceClient.get(
    `${environment.urls.attendenceStatus}/${projectId}/${year}/${month}`
  );
};

export const getBatchDetailsByProjectIdService = (
  projectId: number,
  year: number,
  month: number
) => {
  return serviceClient.get(
    `${environment.urls.getBatchDataByProject}/${projectId}/${year}/${month}`
  );
};

export const getEmployeePayrollDetailsByProjectIdService = (
  projectId: number,
  year: number,
  month: number
) => {
  console.log("axios hit");
  return serviceClient.get(
    `${environment.urls.getEmpPayrollByProjectId}/${projectId}/${month}/${year}`
  );
};

export const editAssociationStatusService = (editIds: number[]) => {
  return serviceClient.post(`${environment.urls.editAssoiciationStatus}`, {
    editIds: editIds,
  });
};

export const getLevelHierarchyService = (masterKey: number) => {
  return serviceClient.get(
    `${environment.urls.getLevelStructure}/${masterKey}`
  );
};

export const getTargetRateValueService = (projectId: number) => {
  return serviceClient.get(
    `${environment.urls.getLevelTargetRateValue}/${projectId}`
  );
};

export const getLevelDetailsService = (levelKey: number) => {
  return serviceClient.get(`${environment.urls.getLevelDetails}${levelKey}`);
};

export const GetAvailableEmpService = (data: SearchAvailableEmpPayload) => {
  return serviceClient.post(environment.urls.getAvailableEmp, data);
};

export const AllocateEmpService = (data: AllocateEmpPayload) => {
  return serviceClient.post(environment.urls.allocateEmp, data);
};
export const ProjectBudgetListService = () => {
  return serviceClient.get(environment.urls.getProjectBudgetlist);
};
export const ProjectUnudgetListService = (data: ProjectUnudgetListPayload) => {
  return serviceClient.post(environment.urls.getProjectUnbudgetlist, data);
};
export const CreateBudgetingService = (data: createBudgetingPayload) => {
  return serviceClient.post(environment.urls.createProjectBudgeting, data);
};
export const ProjectBudgetingEditService = (
  data: projectBudgetingEditPayload
) => {
  return serviceClient.post(environment.urls.editProjectBudgeting, data);
};
export const ProjectPaymentListService = (data: PaymentInfoPayload) => {
  return serviceClient.post(environment.urls.getAllPaymentList, data);
};
export const CreateProjectPaymentService = (
  data: CreateProjectPaymentPayload
) => {  
    return serviceClient.post(environment.urls.createProjectPayment, data);
};
export const ProjectPaymentEditService = (data: ProjectPaymentEditPayload) => {
  return serviceClient.post(environment.urls.editProjectPayment, data);
};
export const ProjectPaymentDeleteService = (
  data: ProjectPaymentDeletePayload
) => {
  return serviceClient.post(environment.urls.deleteProjectPayment, data);
};
export const GetAllStateListService = () => {
  return serviceClient.get(environment.urls.getAllStates);
};
export const GetBudgetDashboardDetailsService = (
  data: BudgetDashboardDetailsPayload
) => {
  return serviceClient.post(environment.urls.getBudgetDashboardDetails, data);
};

export const GetLevelMasterService = () => {
  return serviceClient.get(environment.urls.levelMasterGetAll);
};

export const GetLocationLevelsService = (data: GetLevelsPayload) => {
  return serviceClient.post(environment.urls.getLevels, data);}
export const AddLevelMasterService = (payload: ICreateLevelMaster) => {
  return serviceClient.post(environment.urls.addLevelMaster, payload);
};
export const EditLevelMasterService = (payload: ICreateLevelMaster) => {
  console.log("hit servie :L ", payload);
  return serviceClient.post(environment.urls.editLevelMaster, payload);
};

export const getLevelByMasterAction = (payload: any) => {
  return serviceClient.post(environment.urls.getLevelByMaster, payload);
};

export const addLevelService = (payload: any) => {
  return serviceClient.post(environment.urls.createLevel, payload);
};
export const ConsolidatedProjectReportService = (payload: ConsolidatedReportPayload) => {
  return serviceClient.post(environment.urls.consolidatedReportForProject, payload);
};
export const MonthWiseEmpSalaryinBudgetingService = (payload:MonthWiseEmpSalaryinBudgetingPayload ) => {
  return serviceClient.post(environment.urls.monthWiseEmpSalary, payload);
};
export const EmpMonthlySalaryProjectwiseService = (payload:EmpMonthlySalaryProjectwisePayload ) => {
  return serviceClient.post(environment.urls.empMonthlySalaryProjectwise, payload);
};

//Initiated Project Closure
export const IntiateProjectClosureService = (payload:IntiateProjectClosurePayload) => {
  return serviceClient.post(environment.urls.initiateProjectClosure, payload);
};

//Get all available list for project closure
export const GetAllAvailableProjectForClosureService = (payload: GetAllAvailableProjectForClosurePayload) => {
  return serviceClient.post(environment.urls.getAllAvailableProjectForTermination, payload);
};
// All pending project closure list 
export const GetAllPendingProjectClosureListService = (payload: ProjectClosurePendingListPayload) => {
  return serviceClient.post(environment.urls.projectClosureInitiatedList, payload);
};
// All pending project closure approval requests
export const ApprovalPendingProjectClosureService = (payload: ProjectClosureApprovalPayload) => {
  return serviceClient.post(environment.urls.projectClosureApproval, payload);
};
//Completed Project List
export const CompletedProjectListService = (payload: CompletedProjectListPayload) => {
  return serviceClient.post(environment.urls.completedProjectList, payload);
};