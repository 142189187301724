import {
  Paper,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
  Button,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState, FC } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { AllProjectBudgetList } from "../../../models/customerModel";
import { ILevelMaster } from "../../../interfaces/projectDashboard.interface";

const LevelMasterView: FC<ILeMasterView> = ({
  level_master,
  GetLevelMasterAction,
  addLevelMasterAction,
}) => {

  const columns: any[] = [
    " Level Master Name",
    "Level One Name",
    "Level Two Name",
    "Level Three Name",
    "Level Four Name",
    "Level Five Name",
    // "Created On",
    "Action",
  ];
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };

  const [page, setPage] = React.useState(0);
  const [searched, setSearched] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState<ILevelMaster[]>([]);
  const styles = {
    marginRight: "50px",
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!level_master
      ? level_master.filter((row: ILevelMaster) => {
        return (
          row?.level1_key_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level2_key_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level3_key_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level4_key_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.level5_key_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.display_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!![]) {
      cancelSearch();
    }
  }, [level_master]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    GetLevelMasterAction();
  }, []);
  return (
    <Paper elevation={5} sx={{ ml: 4, mr: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
      <Grid
        container
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        mb={1}
      >
        <Grid item xs={3}>
          <Button
            onClick={() =>
              navigate("/customer/levels/levels-master/add-edit/0")
            }
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            placeholder="Search..."
            id="fullWidth"
            value={searched}
            onChange={(e: any) => (
              requestSearch(e.target.value), setSearched(e.target.value)
            )}
            InputProps={{
              endAdornment:
                !!searched && searched.length > 0 ? (
                  <IconButton color="primary" onClick={() => cancelSearch()}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                ),
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems={"end"} justifyContent={"end"}></Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer sx={{ borderRadius: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columns.map((column) => (
                  <StyledTableCell align="justify" key={column}>
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.level_key_id}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        {row.display_name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.level1_key_name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.level2_key_name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.level3_key_name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.level4_key_name ?? "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.level5_key_name ?? "N/A"}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left" key={columns[5]}>
                        {moment(row.created_on).format("DD-MMM-YYYY")}
                      </StyledTableCell> */}
                      <StyledTableCell align="left" key={columns[6]}>
                        <Tooltip
                          title="View"
                          onClick={() => navigate(`/customer/levels-data/${row.level_key_id}`)}
                        >
                          <IconButton>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Edit"
                          onClick={() =>
                            navigate(
                              `/customer/levels/levels-master/add-edit/${row.level_key_id}`
                            )
                          }
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              {!!rows && rows.length <= 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={7}>
                    <div style={{ color: "red", textAlign: "center" }}>
                      No Data Found{" "}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={styles}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Paper>
  );
};

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#0060FF",
    backgroundColor: "#e6f0ff",
    // color: "#ffff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


interface ILeMasterView {
  GetLevelMasterAction?: any;
  level_master: ILevelMaster[];
  addLevelMasterAction?: any;
}
export default LevelMasterView;
