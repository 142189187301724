import React, { useState } from "react";
import { connect } from "react-redux";
import ProjectInvoiceView from "./ProjectInvoiceView";
import { StoreState } from "../../../models/reduxModels";
import { useNavigate, useParams } from "react-router-dom";
import {
  EditInvoiceAction,
  InvoiceListAction,
} from "../../../stores/actions/invoiceAction";
import { InvoiceListResponse } from "../../../models/invoiceModel";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import { ProjectList } from "../../../models/customerModel";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";

export const ProjectInvoice = ({
  InvoiceListAction,
  invoice_list_details,
  GetAllProjectAction,
  project_list,
  total_size,
  EditInvoiceAction,
  user,
  accessToken,
}: ProjectInvoiceProps) => {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);

  React.useEffect(() => {
    InvoiceListAction({
      project_id: id,
      page_index: 0,
      page_size: 10,
    });
    GetAllProjectAction({ organization_id: null, customer_id: null });
  }, []);

  // console.log('invoice_list_details', invoice_list_details);
  const UpdateInvoice = (data: any) => {
    EditInvoiceAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
      pageID: id,
      data2: {
        page_index: pageindex,
        page_size: pagesize,
        project_id: id,
      },
    });
  };

  return (
    <ProjectInvoiceView
      id={id}
      invoice_list_details={invoice_list_details}
      project_list={project_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      UpdateInvoice={UpdateInvoice}
      user={user}
      accessToken={accessToken}
    />
  );
};

const mapStateToProps = (state: StoreState) => ({
  invoice_list_details: state.invoice.invoice_list_details,
  project_list: state.customer.project_list,
  total_size: state.invoice.invoice_list_details?.total_count,
  user: state.user.userDetails,
  accessToken: state.user.userDetails?.accessToken,
});

const mapDispatchToProps = {
  InvoiceListAction,
  GetAllProjectAction,
  EditInvoiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInvoice);

interface ProjectInvoiceProps {
  InvoiceListAction?: any;
  GetAllProjectAction?: any;
  invoice_list_details?: InvoiceListResponse;
  project_list: ProjectList[];
  total_size?: number;
  EditInvoiceAction?: any;
  user?: userDetails;
  accessToken?: any;
}
