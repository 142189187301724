import Axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl, environment } from "../../../environment";
import {
  CustomerList,
  Level,
  LevelTree,
  ProjectAttendanceTemplate,
  ProjectLevels,
  ProjectList,
  StateList,
  ViewProject,
} from "../../../models/customerModel";
import { ReportingManagerList } from "../../../models/employeeModel";
import {
  AllDesignationList,
  AllPostList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import {
  CreateProjectAction,
  EditProjectAction,
  GetAllCustomerAction,
  GetAllProjectAttendanceTemplateAction,
  GetAllProjectLevelsAction,
  GetProjectViewAction,
  getLevelHierarchyAction,
  ClearLevelsFromPorject,
  getLevelTargetRateValueAction,
  clearTargetRateValueAction,
  GetProjectDashboardAction,
  getLevelDetailsAction,
  GetAllStateListAction,
} from "../../../stores/actions/customerAction";
import {
  CreateCandidateSuccessAction,
  getReportingManagerList,
} from "../../../stores/actions/employeeAction";
import {
  GetAllDesignationAction,
  getAllPostAction,
} from "../../../stores/actions/organizationActions";
import AddProjectView from "./AddProjectView";
import { ILevelHierarchyMain } from "./../../../models/customerModel";
import { IProjectDashboardDetails } from "../../../interfaces/projectDashboard.interface";
import { ProjectDocumentsTypes } from "../../../interfaces/others.interface";
const FileDownload = require("js-file-download");
const AddProject = ({
  customer_list,
  designation_list,
  post_list,
  getAllPostAction,
  GetAllDesignationAction,
  CreateProjectAction,
  GetProjectViewAction,
  project_detail,
  GetAllCustomerAction,
  EditProjectAction,
  user,
  getReportingManagerList,
  reportingManagerList,
  BeginApiCallAction,
  CreateCandidateSuccessAction,
  GetAllProjectAttendanceTemplateAction,
  project_attendance_template,
  GetAllProjectLevelsAction,
  project_levels,
  getLevelHierarchyAction,
  level_hierchy,
  loadingCounter,
  getLevelTargetRateValueAction,
  levelsTargetGetValue,
  project_dashboard_details,
  clearTargetRateValueAction,
  GetProjectDashboardAction,
  getLevelDetailsAction,
  level_details,
  state_list,
  GetAllStateListAction,
}: AddProjectViewProps) => {
  // console.log(project_detail);
  // console.log("loading counter : ", loadingCounter);
  const [document, Setdocument] = useState<ProjectDocumentsTypes[]>([]);
  const [fieldsMaster, SetField] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { id2 } = useParams();
  // console.log({ id, id2 });
  console.log("document", document);

  let navigateTo = useNavigate();
  const Submit = (data: any) => {
    if (!!user) {
      data.user_id = user.userid;
      data.level_key_id = Number(data.level_key_id);
      data.project_fields = fieldsMaster;

      // if (!!document) {
      data.documents = document;
      CreateProjectAction({
        data: data,
        navigateTo: navigateTo,
        enqueueSnackbar: enqueueSnackbar,
      });
      console.log("form payload  create: ", data);
      // } else {
      // enqueueSnackbar("Work Order document is mandatory", {
      //   variant: "warning",
      //   anchorOrigin: {
      //     vertical: "top",
      //     horizontal: "right",
      //   },
      // });
      // }
    }
  };
  const SubmitUpdatedData = (data: any) => {
    if (!!user) {
      data.user_id = user.userid;
      data.project_id = id;
      data.project_fields = fieldsMaster;
      // if (!!document) {
      data.documents = document;
      // console.log("form payload  update: ", data);
      EditProjectAction({
        data: data,
        navigateTo: navigateTo,
        enqueueSnackbar: enqueueSnackbar,
      });
      // } else {
      //   enqueueSnackbar("Work Order document is mandatory", {
      //     variant: "warning",
      //     anchorOrigin: {
      //       vertical: "top",
      //       horizontal: "right",
      //     },
      //   });
      // }
    }
  };
  React.useEffect(() => {
    if (!!id && +id > 0) {
      GetProjectViewAction(id);
    }
    // console.log("callback hit");
    getAllPostAction();
    GetAllCustomerAction();
    GetAllProjectAttendanceTemplateAction();
    GetAllProjectLevelsAction();
    GetAllStateListAction();
  }, []);
  console.log("state_list", state_list);
  const onChangeCustomer = (id: number) => {
    if (!!id) {
      let customer = customer_list.find((m) => m.customer_id == +id);
      if (customer) {
        GetAllDesignationAction({ organization_id: customer.org_id });
        getReportingManagerList({
          organization_id: customer.org_id,
          employee_type_id: environment.global_veriable.ho_employees_type_id,
          project_id: null,
        });
      }
    }
  };

  const handleUpdateDocuments = ({
    document_id,
    document_name,
    file_name,
  }: {
    document_id?: number;
    document_name?: string;
    file_name?: string;
  }) => {
    let isDocExists: ProjectDocumentsTypes | undefined;
    let docCount = -1;
    if (document_id) {
      isDocExists = document.find((self, index) => {
        docCount = index;
        return self.document_id === document_id;
      });
    } else if (document_name) {
      isDocExists = document.find((self, index) => {
        docCount = index;
        return self.document_image === document_name;
      });
    }
    if (!!isDocExists && !!file_name) {
      Setdocument((prev) => [
        { ...isDocExists, document_image: file_name },
        ...prev.filter((self, index) => docCount !== index),
      ]);
    } else if (!!file_name) {
      Setdocument((prev) => [...prev, { document_image: file_name }]);
    } else if (!!isDocExists && docCount >= 0) {
      Setdocument((prev) => prev.filter((self, index) => docCount !== index));
    }
  };
  const fileHandleEvent = ({
    event,
    document_id,
    document_name,
    callback,
  }: {
    event: any;
    document_id?: number;
    document_name?: string;
    callback?: any;
  }) => {
    console.log("hit file upload", document_id);
    console.log("hit file upload name", document_name);
    if (!!user) {
      const file: any = event.target.files[0];
      const file_name: any = `${new Date().getTime()}${
        event.target.files[0].name
      }`;
      const formData = new FormData();
      formData.append("fileName", file_name);
      formData.append("employee_id", `WORKORDER`);
      formData.append("file", file);
      BeginApiCallAction({
        count: 1,
        message: "Uploading image. Please Wait...",
        type: 2,
      });
      Axios.post(baseUrl + environment.urls.fileUploadUrl, formData, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            handleUpdateDocuments({ document_id, document_name, file_name });
            if (callback) callback?.();
            CreateCandidateSuccessAction();
          } else {
            enqueueSnackbar("Something wrong!", {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar("Something wrong!", {
            variant: "error",
          });
          CreateCandidateSuccessAction();
        });
    }
  };
  // delete document handler
  const deleteDocumentHandler = ({
    document_id,
    document_name,
  }: {
    document_id?: number;
    document_name?: string;
  }) => {
    console.log("delete hit ", { document_id, document_name });
    handleUpdateDocuments({ document_id, document_name });
  };
  const downloadImage = (document_name: string) => {
    if (!!document_name) {
      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob", // Important
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: `WORKORDER`,
          fileName: document_name,
        },
      })
        .then(async (response) => {
          await FileDownload(response.data, document_name);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };

  // const addProjectRequest = (data: any) => {
  //   console.log("submit method hit");
  //   console.log({ data });
  //   const payload = {
  //     data: {
  //       ...data,
  //       level_key_id: Number(data.level_key_id),
  //     },
  //   };
  //   console.log({ payload });
  //   // if (division.length <= 0) {
  //   //   enqueueSnackbar("Project should have atleast one division.", {
  //   //     variant: "warning",
  //   //     anchorOrigin: {
  //   //       vertical: "top",
  //   //       horizontal: "right",
  //   //     },
  //   //   });
  //   // } else {
  //   //   if (!!project) {
  //   //     let payload: ViewProject = { ...project, ...data };
  //   //     SubmitUpdatedData({ ...payload, division: division });
  //   //   } else {
  //   //     Submit({ ...data, division: division });
  //   //   }
  //   // }
  // };
  // console.log(project_levels);
  return (
    <AddProjectView
      project={!!id && +id > 0 ? project_detail : undefined}
      customer_list={customer_list}
      designation_list={designation_list}
      post_list={post_list}
      Submit={Submit}
      selected_customer={!!id2 ? +id2 : 0}
      projectId={id}
      SubmitUpdatedData={SubmitUpdatedData}
      onChangeCustomer={onChangeCustomer}
      ReportingManagerList={reportingManagerList}
      Setdocument={Setdocument}
      document={document}
      fileHandleEvent={fileHandleEvent}
      downloadImage={downloadImage}
      project_attendance_template={project_attendance_template}
      project_levels={project_levels}
      fieldsMaster={fieldsMaster}
      setField={SetField}
      getLevelHierarchyAction={getLevelHierarchyAction}
      levelHierarchy={level_hierchy}
      loadingCounter={loadingCounter}
      getLevelTargetRateValueAction={getLevelTargetRateValueAction}
      levelsTargetGetValue={levelsTargetGetValue}
      projectDashboardDetails={project_dashboard_details}
      clearTargetRateValueAction={clearTargetRateValueAction}
      GetProjectDashboardAction={GetProjectDashboardAction}
      getLevelDetailsAction={getLevelDetailsAction}
      levelTree={level_details}
      state_list={state_list}
      user={user}
      deleteDocumentHandler={deleteDocumentHandler}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    customer_list: state.customer.customer_list,
    designation_list: state.organization.designation_list,
    post_list: state.organization.post_list,
    project_detail: state.customer.project_detail,
    user: state.user.userDetails,
    reportingManagerList: state.employee.ReportingManagerList,
    project_attendance_template: state.customer.project_attendance_template,
    project_levels: state.customer.project_levels,
    level_hierchy: state.customer.level_hierachy,
    loadingCounter: state.loading.type,
    levelsTargetGetValue: state.customer.set_level_value,
    project_dashboard_details: state.customer.project_dashboard_details,
    level_details: state.customer.level_tree,
    state_list: state.customer.state_list,
  };
};
const mapDispatchToProps = {
  getAllPostAction,
  GetAllDesignationAction,
  CreateProjectAction,
  GetProjectViewAction,
  GetAllCustomerAction,
  EditProjectAction,
  getReportingManagerList,
  BeginApiCallAction,
  CreateCandidateSuccessAction,
  GetAllProjectAttendanceTemplateAction,
  GetAllProjectLevelsAction,
  getLevelHierarchyAction,
  getLevelTargetRateValueAction,
  clearTargetRateValueAction,
  GetProjectDashboardAction,
  getLevelDetailsAction,
  GetAllStateListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);

interface AddProjectViewProps {
  customer_list: CustomerList[];
  designation_list: AllDesignationList[];
  post_list: AllPostList[];
  getAllPostAction: any;
  GetAllDesignationAction: any;
  CreateProjectAction: any;
  GetProjectViewAction: any;
  project_detail?: ViewProject;
  GetAllCustomerAction: any;
  EditProjectAction?: any;
  user?: userDetails;
  getReportingManagerList?: any;
  reportingManagerList: ReportingManagerList[];
  BeginApiCallAction?: any;
  CreateCandidateSuccessAction?: any;
  GetAllProjectAttendanceTemplateAction?: any;
  project_attendance_template: ProjectAttendanceTemplate[];
  GetAllProjectLevelsAction?: any;
  project_levels?: ProjectLevels;
  getLevelHierarchyAction?: any;
  level_hierchy?: ILevelHierarchyMain;
  loadingCounter: number;
  getLevelTargetRateValueAction?: any;
  levelsTargetGetValue: any[];
  project_dashboard_details?: IProjectDashboardDetails;
  clearTargetRateValueAction?: any;
  GetProjectDashboardAction?: any;
  getLevelDetailsAction?: any;
  level_details?: LevelTree;
  state_list: StateList[];
  GetAllStateListAction?: any;
}
