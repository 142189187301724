import { baseUrl, environment } from "../environment";
import {
  ITimeSheetUpdatePayload,
  TimeSheetHistoryPayload,
  TimeSheetPendingApprovalListPayload,
  TimingSheetPayload,
  UpdateTimingSheetStatusPayload,
} from "../models/timingSheetModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});
export const getTimingSheetListService = (data: TimingSheetPayload) => {
  return serviceClient.post(environment.urls.getTimesheetList, data);
};
export const getTimingSheetPendingApprovalListService = (
  data: TimeSheetPendingApprovalListPayload
) => {
  return serviceClient.post(
    environment.urls.getTimeSheetPendingApprovalList,
    data
  );
};
export const UpdateTimingSheetApprovalStatusService = (
  payload: UpdateTimingSheetStatusPayload
) => {
  return serviceClient.post(
    `${environment.urls.updateTimingSheetApproveStatus}`,
    payload
  );
};

export const UpdateMonthlyTimeSheetService = (
  data: ITimeSheetUpdatePayload
) => {
  return serviceClient.post(`${environment.urls.updateMonthlyTimeSheet}`, data);
};


export const initiateTimeSheetService = ({
  employee_id,
  month,
}: {
  employee_id: string;
  month: string;
}) => {
  return serviceClient.post(`${environment.urls.initiateTimeSheet}`, {
    employee_id,
    month,
  });
};

export const searchNameService = (searchQuery: string, type?: string) => {
  return serviceClient.get(
    `${environment.urls.employeeSearchByName}/?search=${searchQuery}&type=${
      type ? type : ""
    }`
  );
};
export const TimeSheetHistoryService = (
  data: TimeSheetHistoryPayload
) => {
  return serviceClient.post(`${environment.urls.timeSheetHistory}`, data);
};
