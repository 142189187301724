import { Box, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocompletes from "../../library/AutoComplete";
import { OrganizationALlList } from "../../models/organizationModels";
import { userDetails } from "./../../models/userModels";

const OrganizationAutoComplete: FC<IOrganizationAutoComplete> = ({
  user,
  organization_list,
  selectOrganization,
  selected_organization_leave,
  GetAllOrganizationAction,
}): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!!user && !selected_organization_leave) {
      const defaultOrgId = Number(user?.employee_detail.organization_id);
      selectOrganization(Number(defaultOrgId));
      setValue("organization_id", defaultOrgId);
    } else if (selected_organization_leave) {
      setValue("organization_id", selected_organization_leave);
    }
  }, [user, selected_organization_leave]);
  useEffect(() => {
    if (organization_list.length === 0) {
      GetAllOrganizationAction();
    }
  }, [organization_list]);
  useEffect(() => {
    if (watch("organization_id"))
      selectOrganization(Number(getValues("organization_id")));
  }, [watch("organization_id")]);

  const setOrganization = () => {
    console.log("orgId : ", getValues("organization_id"));
    selectOrganization(Number(getValues("organization_id")));
  };

  return (
    <Box
      sx={{
        width: "25%",
        mx: 5,
        mt: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "gray",
      }}
    >
      <Typography style={{
        color: "#132437",
        fontWeight: 600,
        fontFamily: "Alef, sans-serif"
      }}>Filter By Organization : </Typography>
      <Controller
        control={control}
        name={"organization_id"}
        defaultValue={""}
        rules={{ required: true }}
        render={({ field: { onBlur, value, onChange } }) => (
          <Autocompletes
            hasInfo={false}
            option={organization_list}
            //   readOnly={!!department ? edit_flag : !edit_flag}
            keys={"organization_id"}
            label={"organization_name"}
            onBlur={onBlur}
            onChange={(value: any) => (onChange(value), setOrganization())}
            placeholder={"Filter By Organization"}
            value={!!value ? value : ""}
          />
        )}
      />
    </Box>
  );
};
interface IOrganizationAutoComplete {
  organization_list: OrganizationALlList[];
  selectOrganization?: any;
  selected_organization_leave?: number;
  GetAllOrganizationAction?: any;
  user?: userDetails;
}

export default OrganizationAutoComplete;
