import { CustomerMain } from "../../models/customerModel";
import { CustomerActionTypes } from "../actions/customerAction";
import InitialState from "./initialState";

const initialState: CustomerMain = InitialState.customer;
export default function CustomerReducer(
  state: CustomerMain = initialState,
  action: any
) {
  switch (action.type) {
    case CustomerActionTypes.Get_All_Customer_Success_Action:
      return { ...state, customer_list: action.payload };
    // case CustomerActionTypes.Customer_Add_Success_Action:
    //   return { ...state, AddCustomerPayload: action.payload };
    // case CustomerActionTypes.Edit_Customer_Success_Action:
    //   return { ...state, CustomerEditPayload: action.payload };
    case CustomerActionTypes.Get_All_Project_Success_Action:
      return { ...state, project_list: action.payload };
    // case CustomerActionTypes.Project_Add_Success_Action:
    //   return { ...state, AddProjectPayload: action.payload };
    case CustomerActionTypes.Get_Project_View_Success_Action:
      return { ...state, project_detail: action.payload };
    // case CustomerActionTypes.Edit_Project_Success_Action:
    //   return { ...state, ProjectEditPayload: action.payload };
    case CustomerActionTypes.Division_Get_All_Success_Action:
      return { ...state, division_list: action.payload };
    case CustomerActionTypes.SubDivision_Get_All_Success_Action:
      return { ...state, subdivision_list: action.payload };
    case CustomerActionTypes.Get_All_Project_Attendance_Template_Success_Action:
      return { ...state, project_attendance_template: action.payload };
    case CustomerActionTypes.Get_All_Project_Levels_Success_Action:
      return { ...state, project_levels: action.payload };
    case CustomerActionTypes.Get_All_Project_Dashboard_Success_action:
      return { ...state, project_dashboard_details: action.payload };
    case CustomerActionTypes.Get_All_Project_Dashboard_Attendence_status_Success_action:
      return { ...state, attendence_status: action.payload };
    case CustomerActionTypes.Get_All_Project_Dashboard_Batch_Details_Action:
      return { ...state, project_dashboard_batch_details: action.payload };
    case CustomerActionTypes.Get_All_Project_Dashboard_Employee_Payroll_Action:
      return { ...state, project_employee_payroll_details: action.payload };
    case CustomerActionTypes.Get_All_Levels_For_Project_Create_Action:
      return { ...state, level_hierachy: action.payload };
    case CustomerActionTypes.Clear_All_levels_For_Project_Action:
      return { ...state, level_hierachy: undefined };
    case CustomerActionTypes.Get_Levels_Target_Rate_Value_For_Project_Action:
      return { ...state, set_level_value: action.payload };
    case CustomerActionTypes.Get_Level_Details_Action:
      return { ...state, level_tree: action.payload };
    case CustomerActionTypes.CLEAR_Levels_Target_Rate_Value_For_Project_Action:
      return { ...state, set_level_value: [] };
    case CustomerActionTypes.Get_All_Available_Emp_Success_Action:
      return { ...state, available_emp_list: action.payload };
    case CustomerActionTypes.Get_Project_Budget_List_Success_Action:
      return { ...state, project_budget_list: action.payload };
    case CustomerActionTypes.Get_Project_Unbudget_List_Success_Action:
      return { ...state, project_unbuget_list: action.payload };
    case CustomerActionTypes.Get_Project_Payment_List_Success_Action:
      return { ...state, project_payment_list: action.payload };
    case CustomerActionTypes.Get_All_State_List_Success_Action:
      return { ...state, state_list: action.payload };
    case CustomerActionTypes.Get_Project_Budget_Details_Success_Action:
      return { ...state, budget_details: action.payload };
    case CustomerActionTypes.Get_Level_Master_Details_Success_Action:
      return { ...state, level_master: action.payload };
    case CustomerActionTypes.Get_Location_Levels_Success_Action:
      return { ...state, location_levels: action.payload };
    case CustomerActionTypes.Consolidated__Project_Report_Success_Action:
      return { ...state, consolidated_report: action.payload };
    case CustomerActionTypes.Month_Wise_Employee_Salary_Budgeting_Success_Action:
      return { ...state, month_wise_emp_salary_Budgeting: action.payload };
    case CustomerActionTypes.Employee_Monthly_Salary_Projectwise_Success_Action:
      return { ...state, emp_salary_month_projectwise: action.payload };
    case CustomerActionTypes.Get_All_Available_Project_For_Closure:
      return {
        ...state,
        all_available_projectList_for_closure: action.payload,
      };
    case CustomerActionTypes.Get_All_Pending_Project_Closure_List_Success_Action:
      return {
        ...state,
        pending_project_closure: action.payload,
      };
    case CustomerActionTypes.Completed_Project_List_Success_Action:
      return {
        ...state,
        completed_project_list: action.payload,
      };
    default:
      return state;
  }
}
