import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  Link,
  Tooltip,
  TextareaAutosize,
} from "@mui/material";

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

function TerminationApprovalSection({
  open,
  setOpen,
  openTerminationSelectedList,
  FinalApprovedSubmit
}: TerminationApprovalSectionProps) {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const [remarks, SetRemarks] = useState<string>("");
  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation",
    "Last Working Days",
    "Initiated By",
    "Status",
  ];
  const ClearOnClose = () => {
    setOpen(false);
    // reset();
  };
  console.log("openTerminationSelectedList", openTerminationSelectedList);
const onSubmit=(data: any, btnClick: number)=>{
  let payload: any = {
    remarks: remarks,
    employee_termination_id: openTerminationSelectedList?.employee_termination_id,
    event_action_items: openTerminationSelectedList?.event_action_items,
    status: btnClick,
    empLifeEventIds: openTerminationSelectedList?.empLifeEventId,
  };
  FinalApprovedSubmit(payload);
  setOpen(false);
  SetRemarks("")
}
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={ClearOnClose}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "#0060FF" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Verify and Approve
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container height={"92%"} flexGrow={1} flexDirection={"row"}>
        <Grid
          item
          // xs={6.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2}}
        >
          {/* <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              margin: 2,
            }}
          > */}
            <Grid
              container
              flexDirection={"row"}
            >
              <Grid item xs={4} style={{ padding: 5 }}>
                <Typography style={{
                  color: "#132437",
                  fontWeight: 600,
                  fontFamily: "Alef, sans-serif",
                  fontSize: 30,
                  padding: 10
                 }}>
                  Terminated Employee Details
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                style={{ padding: 5 }}
              >
                {/* <Typography>{view_promotion[0].project_name}</Typography> */}
              </Grid>
            </Grid>
          {/* </Paper> */}

          <Paper
            sx={{
              flexGrow: 1,
              borderRadius: 1,
              border: "1px solid #E0E0E0",
              margin: 2,
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={openTerminationSelectedList?.employee_id}
                  >
                    <StyledTableCell align="left" key={columns[0]}>
                      <Link
                        onClick={() =>
                          navigateTo(
                            `/employment/manage-profile/${openTerminationSelectedList?.employee_id}`
                          )
                        }
                      >
                        {openTerminationSelectedList?.employee_id}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[1]}>
                      {openTerminationSelectedList?.employee_name}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[2]}>
                      {openTerminationSelectedList?.designation_name}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[3]}>
                      {moment(
                        openTerminationSelectedList?.termination_date
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[4]}>
                      {openTerminationSelectedList?.initiated_employee_name}
                    </StyledTableCell>
                    <StyledTableCell align="left" key={columns[5]}>
                      {openTerminationSelectedList?.eventStatus}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        {/* </Grid>
        <Grid
          item
          xs={5.5}
          flexGrow={1}
          justifyContent={"center"}
          height={"100%"}
          style={{ overflowY: "scroll", padding: 2 }}
        > */}
          <div style={{ margin: 5, padding: 10 }}>
            <form>
              {/* {!!view_promotion &&
                Array.isArray(view_promotion) &&
                view_promotion.length > 0 &&
                !!view_promotion[0].new_designation_id && ( */}
              <Paper
                elevation={5}
                sx={{ marginTop: 5, marginInline: 1, padding: 2 }}
              >
                <Grid container justifyContent={"flex-start"} mt={1.5}>
                  <Grid item xs={10}>
                    <Typography
                      style={{
                        fontWeight: "800",
                        fontSize: 18,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "4%",
                        paddingBottom: 5,
                      }}
                    >
                      Termination Section
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 20, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={3}
                      justifyContent="center"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Remarks
                        <span style={{ color: "red", fontSize: 14 }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={8.5}
                      sx={{ marginLeft: -1, paddingRight: 5 }}
                    >
                      <Controller
                        control={control}
                        name={"remarks"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                      //     <TextField
                      //       multiline
                      //       type={"text"}
                      //       id="standard-required"
                      //       variant="outlined"
                      //       className={"smaller"}
                      //       style={{ width: "100.9%" }}
                      //       onBlur={onBlur}
                      //       size="small"
                      //       onChange={onChange}
                      //       value={value}
                      //       placeholder={"Remarks"}
                      //       error={!!errors["remarks"]}
                      //       helperText={
                      //         errors.remarks &&
                      //         `${errors.remarks.message}* This field is Required`
                      //       }
                      //     />
                      //   )}
                      // />
                      <TextField
                      multiline
                      variant="outlined"
                      // className={"smaller"}
                      style={{ width: "100%" }}
                      // onBlur={onBlur}
                      size="small"
                      value={remarks}
                      onChange={(event) => SetRemarks(event.target.value)}
                      error={!!errors.remarks}
                      placeholder={"Remarks..."}
                      InputProps={{
                        inputComponent: TextareaAutosize,
                        style: { minHeight: 80 },
                      }}
                      //helperText={!!errors.remarks && `* This field is Required`}
                    />
                    )}
                  />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems={"center"}
                  padding={3}
                >
                  <Grid item xs={3}>
                    <Button
                      sx={{
                        color: "#fff",
                        //backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      // onClick={handleSubmit(onRejectSubmit)}
                      onClick={() => onSubmit(remarks, 3)}
                      variant="contained"
                      color="error"
                      size="large"
                      disabled={!remarks || remarks == ""}
                    >
                      Reject
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      sx={{
                        color: "#fff",
                        backgroundColor: "#1565C0",
                        paddingX: 4,
                        marginRight: 10,
                      }}
                      // onClick={handleSubmit(onApproveSubmit)}
                      onClick={() => onSubmit(remarks, 2)}
                      disabled={!remarks || remarks == ""}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Approve
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              {/* )} */}
            </form>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default TerminationApprovalSection;
interface TerminationApprovalSectionProps {
  open: boolean;
  setOpen?: any;
  openTerminationSelectedList?: any;
  FinalApprovedSubmit?: any
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
