import { Cookies } from "react-cookie";
import {
  CreateRoleService,
  GetAllAccessControlMasterService,
  GetAllRoleMasterListService,
  GetRoleByIDService,
  RoleAssignToUserService,
  UpdateRoleService,
  UserRoleMappingService,
} from "../../services/userManagementService";
import { BeginApiCallAction, ApiCallErrorAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";

export enum UserManagementActionTypes {
  All_Access_Control_Success_Action = "[USERMANAGEMENT] All Access Control Success Action",
  Create_Role_Success_Action = "[USERMANAGEMENT] Create Role Success Action",
  Get_All_Role_Master_List_Control_Success_Action = "[USERMANAGEMENT] Get All Role Master List Control Success Action",
  Get_Role_By_ID_Success_Action = "[USERMANAGEMENT] Get Role By ID Success Action",
  Update_Role_Success_Action = "[USERMANAGEMENT] Update Role Success Action",
  User_Role_Mapping_List_Success_Action = "[USERMANAGEMENT] User Role Mapping List Success Action",
  Role_Assign_To_User_Success_Action = "[USERMANAGEMENT] Role Assign To User Success Action",
}
export const GetAllAccessControlMasterAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Access Control List. Please Wait...",
        type: 2,
      })
    );
    return GetAllAccessControlMasterService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllAccessControlMasterSuccessAction(response.data));
          // console.log("response.data",response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllAccessControlMasterSuccessAction = (data: any) => {
  // console.log("response.data",data);
  return {
    type: UserManagementActionTypes.All_Access_Control_Success_Action,
    payload: data,
  };
};

export const CretaeRoleAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating Role. Please Wait...",
        type: 2,
      })
    );
    return CreateRoleService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(`/usermanagement/role-master/`);
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CretaeRoleSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CretaeRoleSuccessAction = () => {
  return {
    type: UserManagementActionTypes.Create_Role_Success_Action,
  };
};

export const GetAllRoleMasterListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Role Master List. Please Wait...",
        type: 2,
      })
    );
    return GetAllRoleMasterListService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllRoleMasterListSuccessAction(response.data));
          // console.log("response.data",response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllRoleMasterListSuccessAction = (data: any) => {
  return {
    type: UserManagementActionTypes.Get_All_Role_Master_List_Control_Success_Action,
    payload: data,
  };
};

export const GetRoleByIDAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Role By ID. Please Wait...",
        type: 2,
      })
    );
    return GetRoleByIDService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetRoleByIDSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetRoleByIDSuccessAction = (data: any) => {
  return {
    type: UserManagementActionTypes.Get_Role_By_ID_Success_Action,
    payload: data
  };
};

export const UpdateRoleAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Role Update. Please Wait...",
        type: 2,
      })
    );
    return UpdateRoleService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(`/usermanagement/role-master/`);
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(UpdateRoleSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UpdateRoleSuccessAction = () => {
  return {
    type: UserManagementActionTypes.Update_Role_Success_Action
  };
};
export const UserRoleMappingAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "User Role Mapping List. Please Wait...",
        type: 2,
      })
    );
    return UserRoleMappingService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(UserRoleMappingSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const UserRoleMappingSuccessAction = (data:any) => {
  return {
    type: UserManagementActionTypes.User_Role_Mapping_List_Success_Action,
    payload: data
  };
};

export const RoleAssignToUserAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Role Assign To User. Please Wait...",
        type: 2,
      })
    );
    return RoleAssignToUserService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(RoleAssignToUserSuccessAction());
          dispatch(UserRoleMappingAction(payload.updateData))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const RoleAssignToUserSuccessAction = () => {
  return {
    type: UserManagementActionTypes.Role_Assign_To_User_Success_Action,
  };
};