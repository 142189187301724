import { log } from "console";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { environment } from "../../../environment";
import {
  CustomerList,
  DivisionList,
  ProjectLevels,
  ProjectList,
  StateList,
  SubDivisionList,
  ViewProject,
} from "../../../models/customerModel";
import { bankList, ReportingManagerList } from "../../../models/employeeModel";
import {
  AllDepartmentList,
  AllDesignationList,
  AllPostList,
  DomainList,
  EmployeeTimingList,
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { BeginApiCallAction } from "../../../stores/actions/apiStatusActions";
import {
  GetAllCustomerAction,
  GetAllDivisionAction,
  GetAllProjectAction,
  GetAllProjectLevelsAction,
  GetAllStateListAction,
  GetProjectViewAction,
} from "../../../stores/actions/customerAction";
import {
  CreateCandidateAction,
  CreateCandidateSuccessAction,
  GetAllBankListAction,
  GetAttendenceSalarytypeAction,
  getReportingManagerList,
} from "../../../stores/actions/employeeAction";
import {
  GetAllDepartmentAction,
  GetAllDesignationAction,
  GetAllEmployeeTimingAction,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  getAllPostAction,
  GetAllSalaryStructureAction,
  SalaryComponentEditAction,
} from "../../../stores/actions/organizationActions";
import AddEmployeeview from "./AddEmployeeview";

function AddEmployee({
  CreateCandidateAction,
  GetAllOrganizationAction,
  organization_list,
  GetAllDesignationAction,
  designation_list,
  GetAllEmployeeTypeAction,
  employee_type_list,
  GetAllEmployeeTimingAction,
  GetAllDepartmentAction,
  department_list,
  employee_timing_list,
  GetAllCustomerAction,
  customer_list,
  GetAllProjectAction,
  project_list,
  post_list,
  getAllPostAction,

  user,
  BeginApiCallAction,
  CreateCandidateSuccessAction,
  ReportingManagerList,
  attendence_salary_type,
  salary_structure_list,
  GetAllSalaryStructureAction,
  GetAttendenceSalarytypeAction,
  GetProjectViewAction,
  SalaryComponentEditAction,
  getReportingManagerList,
  project_detail,
  salary_structure,
  GetAllBankListAction,
  bank_list,
  GetAllProjectLevelsAction,
  project_levels,
  state_list,
  GetAllStateListAction
}: AddEmployeeProps) {
  const apiCallBack = useCallback(() => {
    GetAllOrganizationAction();
    getAllPostAction();
    GetAttendenceSalarytypeAction();
    GetAllBankListAction();
    GetAllProjectLevelsAction();
    GetAllStateListAction()
  }, []);
  React.useEffect(() => {
    apiCallBack();
  }, []);
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const Submit = (data: any) => {
    CreateCandidateAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
    //console.log(data);
  };
 // console.log(bank_list);

  const OrganizationSelected = (id: number) => {
    GetAllDesignationAction({ organization_id: +id });
    GetAllEmployeeTypeAction({ organization_id: +id });
    GetAllEmployeeTimingAction({ organization_id: +id });
    GetAllDepartmentAction({ organization_id: +id });
    GetAllCustomerAction({ organization_id: +id });
    GetAllSalaryStructureAction({ organization_id: +id });
  };
  const OnChangeCustomer = (data: number, org: number) => {
    GetAllProjectAction({ organization_id: +org, customer_id: +data });
  };
  const OnprojectSelect = (project_id: number) => {
    if (!!project_id) {
      GetProjectViewAction(project_id);
    }
  };
  const SlaryStructureChange = (id: number) => {
    if (!!id) {
      SalaryComponentEditAction({ salary_structure_id: +id });
    }
  };
  return (
    <AddEmployeeview
      Submit={Submit}
      organization_list={organization_list}
      designation_list={designation_list}
      employee_type_list={employee_type_list}
      department_list={department_list}
      employee_timing_list={employee_timing_list}
      customer_list={customer_list}
      project_list={project_list}
      post_list={post_list}
      user={user}
      BeginApiCallAction={BeginApiCallAction}
      CreateCandidateSuccessAction={CreateCandidateSuccessAction}
      OrganizationSelected={OrganizationSelected}
      ReportingManagerList={ReportingManagerList}
      salary_structure_list={salary_structure_list}
      OnChangeCustomer={OnChangeCustomer}
      OnprojectSelect={OnprojectSelect}
      project_detail={project_detail}
      SlaryStructureChange={SlaryStructureChange}
      salary_structure={salary_structure}
      attendence_salary_type={attendence_salary_type}
      bank_list={bank_list}
      project_levels={project_levels}
      state_list={state_list}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    designation_list: state.organization.designation_list,
    employee_type_list: state.organization.employee_type_list,
    employee_timing_list: state.organization.employee_timing_list,
    department_list: state.organization.department_list,
    customer_list: state.customer.customer_list,
    project_list: state.customer.project_list,
    post_list: state.organization.post_list,
    division_list: state.customer.division_list,
    subdivision_list: state.customer.subdivision_list,
    user: state.user.userDetails,
    ReportingManagerList: state.employee.ReportingManagerList,
    salary_structure_list: state.organization.salary_structure_list,
    project_detail: state.customer.project_detail,
    salary_structure: state.organization.salary_structure_edit,
    attendence_salary_type: state.employee.attendence_salary_type,
    bank_list: state.employee.bank_list,
    project_levels: state.customer.project_levels,
    state_list: state.customer.state_list
  };
};
const mapDispatchToProps = {
  CreateCandidateAction,
  GetAllOrganizationAction,
  GetAllDesignationAction,
  GetAllEmployeeTypeAction,
  GetAllEmployeeTimingAction,
  GetAllDepartmentAction,
  GetAllCustomerAction,
  GetAllProjectAction,
  getAllPostAction,
  BeginApiCallAction,
  CreateCandidateSuccessAction,
  getReportingManagerList,
  GetAllSalaryStructureAction,
  GetProjectViewAction,
  SalaryComponentEditAction,
  GetAttendenceSalarytypeAction,
  GetAllBankListAction,
  GetAllProjectLevelsAction,
  GetAllStateListAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);

interface AddEmployeeProps {
  CreateCandidateAction?: any;
  GetAllOrganizationAction?: any;
  GetAllDesignationAction?: any;
  GetAllEmployeeTypeAction?: any;
  organization_list: OrganizationALlList[];
  designation_list: AllDesignationList[];
  employee_type_list: EmployeeTypeList[];
  GetAllEmployeeTimingAction?: any;
  employee_timing_list: EmployeeTimingList[];
  department_list: AllDepartmentList[];
  GetAllDepartmentAction?: any;
  GetAllCustomerAction?: any;
  customer_list: CustomerList[];
  GetAllProjectAction?: any;
  project_list: ProjectList[];
  getAllPostAction?: any;
  post_list: AllPostList[];
  user?: userDetails;
  BeginApiCallAction?: any;
  CreateCandidateSuccessAction?: any;
  ReportingManagerList: ReportingManagerList[];
  getReportingManagerList?: any;
  GetAllSalaryStructureAction?: any;
  salary_structure_list: SalaryStructureList[];
  GetProjectViewAction?: any;
  project_detail?: ViewProject;
  SalaryComponentEditAction?: any;
  salary_structure?: SalaryStructureEditDetails;
  attendence_salary_type: DomainList[];
  GetAttendenceSalarytypeAction?: any;
  GetAllBankListAction: any;
  bank_list: bankList[];
  GetAllProjectLevelsAction?: any;
  project_levels?: ProjectLevels;
  state_list: StateList[];
  GetAllStateListAction?: any
}
