import { FC } from "react";
import LevelMasterView from "./LevelMasterView";
import {
  GetLevelMasterAction,
  addLevelMasterAction,
} from "../../../stores/actions/customerAction";
import { StoreState } from "../../../models/reduxModels";
import { connect } from "react-redux";
import { ILevelMaster } from "../../../interfaces/projectDashboard.interface";

const LevelsMaster: FC<ILevelsMaster> = ({
  GetLevelMasterAction,
  level_master,
}): JSX.Element => {
  return (
    <LevelMasterView
      GetLevelMasterAction={GetLevelMasterAction}
      level_master={level_master}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    level_master: state.customer.level_master,
  };
};
const mapDispatchToProps = {
  GetLevelMasterAction,
};
interface ILevelsMaster {
  GetLevelMasterAction?: any;
  level_master: ILevelMaster[];
}

export default connect(mapStateToProps, mapDispatchToProps)(LevelsMaster);
