import { FC } from "react";
import ActionView from "./ActionView";
import { ICreateLevelMaster } from "../../../../models/customerModel";
import {
  EditLevelAction,
  addLevelMasterAction,
} from "../../../../stores/actions/customerAction";
import { ILevelMaster } from "../../../../interfaces/projectDashboard.interface";
import { connect } from "react-redux";
import { StoreState } from "../../../../models/reduxModels";

const LevelMasterAction: FC<ILevelsMasterAction> = ({
  addLevelMasterAction,
  EditLevelAction,
  level_master,
}) => {
  const addAction = (payload: { formPayload: ICreateLevelMaster }) => {
    addLevelMasterAction(payload);
  };
  const editAction = (payload: ICreateLevelMaster) => {
    EditLevelAction(payload);
  };

  return (
    <ActionView
      AddRequest={addLevelMasterAction}
      editRequest={editAction}
      levelMaster={level_master}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    level_master: state.customer.level_master,
  };
};
const mapDispatchToProps = {
  addLevelMasterAction,
  EditLevelAction,
};
interface ILevelsMasterAction {
  addLevelMasterAction?: any;
  level_master: ILevelMaster[];
  EditLevelAction?: any;
}

export default connect(mapStateToProps, mapDispatchToProps)(LevelMasterAction);
