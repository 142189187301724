import {
  Paper,
  Grid,
  Button,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Tooltip,
  TableCell,
  tableCellClasses,
  styled,
  Box,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  NativeSelect,
  Link,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { FilterList, Clear } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  AllDesignationList,
  EmployeeTypeList,
} from "../../../models/organizationModels";
import {
  GetAllRoleMasterList,
  User,
  UserRoleMappingList,
} from "../../../models/userManagementModel";
import { IEmployeeList } from "../../../models/timingSheetModel";

const RoleMappingView = ({
  employee_type_list,
  designation_list,
  user_role_mapping_list,
  all_role_master,
  RoleMappingFilter,
  Clear,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
  RoleUpdate,
  searchByNameEmpAction,
  employeeList,
}: RoleMappingViewProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [rows, setRows] = useState<User[]>([]);
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const styles = {
    marginRight: "50px",
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!user_role_mapping_list) {
      cancelSearch();
    }
  }, [user_role_mapping_list]);
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!user_role_mapping_list
      ? user_role_mapping_list.user.filter((row: User) => {
        return row?.department_name
          ?.toLowerCase()
          .includes(searchedVal.toLowerCase());
      })
      : [];
    setRows(filteredRows);
  };

  const columns: any[] = [
    "Employee ID",
    "Employee Name",
    "Mob No",
    "Email ID",
    "Employee Type",
    "Department Name",
    "Designation Name",
    "Role Name",
    "Action",
  ];
  const onSubmit = (data: any) => {
    RoleMappingFilter(data);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [role, setRole] = useState<any[]>([]);
  const [roleID, setRoleID] = useState<number>();
  const addRole = (props: any) => {
    let rolePayload: any = {
      role_id: role,
      userid: roleID,
    };
    RoleUpdate(rolePayload);
    setAnchorEl(null);
  };
  const ClickRole = (row: User, event: any) => {
    setRoleID(row.userid);
    setRole(row.role_list_id?.split(",").map((m) => +m));
    handleClick(event);
  };
  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log(searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, "");
    }, 2000);
  };

  useEffect(() => {
    searchByNameEmpAction("", "");
  }, []);

  return (
    <>
      <Paper elevation={5} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            // expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#42a5f5",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee ID
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      {/* <Controller
                        control={control}
                        name={"employee_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Employee ID"}
                          />
                        )}
                      /> */}
                      <Controller
                        control={control}
                        name={`employee_id`}
                        defaultValue={""}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={employeeList}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"employee_id"}
                            label={"employee_id"}
                            onInputChange={(e: any) => {
                              if (e?.target?.value) GetCall(e.target.value);
                            }}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Employee Id"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee Mob No
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"employee_mob_no"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            type={"text"}
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "100.9%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Employee Mob No"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Employee Type
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} sx={{ marginLeft: -1 }}>
                      <Controller
                        control={control}
                        name={"employee_type_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={employee_type_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"employee_type_id"}
                            label={"employee_type_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Employee Type"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Designation
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} sx={{ marginLeft: -1 }}>
                      <Controller
                        control={control}
                        name={"designation_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={designation_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"designation_id"}
                            label={"designation_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Designation"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid
                container
                display={"flex"}
                justifyContent="flex-end"
                // alignItems={"center"}
                padding={1}
                direction={"row"}
              >
                <Grid item xs={2.5}>
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  View
                </Button>
                </Grid>
                <Grid item xs={2.5}>
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "rgb(253,123,0)",
                    paddingX: 6,
                    // marginRight: 10,
                  }}
                  onClick={()=>(Clear(), reset())}
                  variant="contained"
                  color="warning"
                  size="large"
                >
                  Clear
                </Button>
                </Grid> 
                
              </Grid> */}
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  onClick={() => (Clear(), reset())}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Clear
                </Button>
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  filter
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <Paper elevation={5} sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid marginTop={2}>
          <TableContainer sx={{ borderRadius: 1 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
            //   style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((column) => (
                    <StyledTableCell align="justify" key={column}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows &&
                  rows.map((row) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.userid}
                    >
                      <StyledTableCell align="left" key={columns[0]}>
                        <Link
                          onClick={() =>
                            navigate(
                              `/employment/manage-profile/${row.employee_id}`
                            )
                          }
                        >
                          {row.employee_id}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[1]}>
                        {row.employee_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[2]}>
                        {row.employee_mob_no}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[3]}>
                        {row.employee_email}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[4]}>
                        {row.employee_type_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[5]}>
                        {row.department_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[6]}>
                        {row.designation_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[7]}>
                        {row.role_list_name}
                      </StyledTableCell>
                      <StyledTableCell align="left" key={columns[8]}>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={(event: any) => ClickRole(row, event)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {!!rows && rows.length <= 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9}>
                      <div style={{ color: "red", textAlign: "center" }}>
                        No Data Found{" "}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container alignItems={"center"} justifyContent="end" color={"black"}>
            <Grid xs={2} textAlign="right">
              Rows per page:
            </Grid>
            <Grid xs={1} textAlign="center">
              <NativeSelect
                value={pagesize.toString()}
                onChange={(event) => SetpageSize(+event.target.value)}
              >
                {rowperpage.map((p) => (
                  <option key={p} value={p} onChange={(value: any) => value(p)}>
                    {p}
                  </option>
                ))}
              </NativeSelect>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                onClick={() => Setpageindex(pageindex - 1)}
                color="primary"
                size="small"
                disabled={pageindex <= 0}
              >
                <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography>
                {pageindex * pagesize + 1} -{" "}
                {!!total_size && total_size <= (pageindex + 1) * pagesize
                  ? total_size
                  : (pageindex + 1) * pagesize}
                {" of "}
                {total_size}
              </Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <IconButton
                color="primary"
                size="small"
                onClick={() => Setpageindex(pageindex + 1)}
                disabled={
                  (!!total_size && total_size <= (pageindex + 1) * pagesize) ||
                  total_size === 0
                }
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>Select User Role</Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <form>
              <Grid container justifyContent="start" alignItems="center">
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography style={{ fontWeight: 400 }}>
                    Select Role
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={!!all_role_master ? all_role_master : []}
                    style={{ width: "100.9%" }}
                    getOptionLabel={(option) => option.role_name}
                    // readOnly={!!all_role_master ? edit_flag : !edit_flag}
                    onChange={(evemt, value) =>
                      setRole(!!value ? value.map((m) => m.role_id) : [])
                    }
                    value={
                      !!role
                        ? all_role_master.filter((m) =>
                          role.includes(m.role_id)
                        )
                        : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="standard-required"
                        className={"smaller"}
                        variant="outlined"
                        placeholder="Select Role"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end ",
                  //   marginRight: "2rem",
                  marginRight: "0px",
                  marginTop: "13px",
                  marginBottom: 1,
                }}
              >
                <Button
                  size="small"
                  sx={{
                    marginLeft: 1,
                  }}
                  variant="outlined"
                  onClick={handleSubmit(addRole)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default RoleMappingView;
interface RoleMappingViewProps {
  employee_type_list: EmployeeTypeList[];
  designation_list: AllDesignationList[];
  user_role_mapping_list?: UserRoleMappingList;
  all_role_master: GetAllRoleMasterList[];
  RoleMappingFilter?: any;
  Clear?: any;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
  RoleUpdate?: any;
  employeeList: IEmployeeList[];
  searchByNameEmpAction?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
