import { useSnackbar } from 'notistack';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerList, StateList } from '../../../models/customerModel';
import { OrganizationALlList } from '../../../models/organizationModels';
import { StoreState } from '../../../models/reduxModels';
import { CreateCustomerAction, EditCustomerAction, GetAllStateListAction } from '../../../stores/actions/customerAction';
import { GetAllOrganizationAction } from '../../../stores/actions/organizationActions';
import AddCustomerView from './AddCustomerView'

function AddCustomer({
  GetAllOrganizationAction,
  organization_list,
  CreateCustomerAction,
  customer_list,
  userid,
  EditCustomerAction,
  state_list,
  GetAllStateListAction
}: AddCustomerProps) {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  // const Submit = (data: any) => {
  //   CreateCustomerAction({
  //     data: data,
  //     navigateTo: navigateTo,
  //     enqueueSnackbar: enqueueSnackbar,
  //   });
  // };
  const Submit = (data: any) => {
    data.org_id = data.organization_id;
    data.created_by = userid;
    //data.address_id = data.organization_id;
    CreateCustomerAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData = (data: any) => {
    EditCustomerAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  // console.log(id)
  React.useEffect(() => {
    GetAllStateListAction();
    GetAllOrganizationAction();
  }, []);
  return (
    <AddCustomerView
      customer={customer_list.find((m) => !!id && m.customer_id == +id)}
      organization_list={organization_list}
      Submit={Submit}
      SubmitUpdatedData={SubmitUpdatedData}
      state_list={state_list}
    />
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    customer_list: state.customer.customer_list,
    userid: state.user.userDetails?.userid,
    state_list: state.customer.state_list
  };
};
const mapDispatchToProps = {
  CreateCustomerAction,
  GetAllOrganizationAction,
  EditCustomerAction,
  GetAllStateListAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);

interface AddCustomerProps {
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  CreateCustomerAction?: any;
  customer_list: CustomerList[];
  userid: any;
  EditCustomerAction?: any
  state_list: StateList[];
  GetAllStateListAction: any;
}
