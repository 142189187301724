import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TextField,
    IconButton,
    Button,
    Divider,
    styled,
    TableCell,
    tableCellClasses,
    Tooltip,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { ViewEmployee } from "../../../models/employeeModel";
import { userDetails } from "../../../models/userModels";
import { environment } from "../../../environment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProfessionalDetails = ({
    addProfessionalDetails,
    setAddProfessionalDetails,
    edit_candidate,
    employee_detail,
    edit_flag,
    user,
    id2
}: ProfessionalDetailsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const columns2: any[] = [
        "Course Name",
        "Start Date",
        "End Date",
        "Remarks",
        "Action",
    ];
    const addProfessionalDetailsRow = () => {
        if (addProfessionalDetails.length === 0) {
            let Temp: any = JSON.parse(JSON.stringify(addProfessionalDetails));
            Temp = [
                ...Temp,
                {
                    course_name: null,
                    start_date: null,
                    end_date: null,
                    remarks: null,
                },
            ];
            setAddProfessionalDetails(Temp);
        }
        else if (
            !addProfessionalDetails[addProfessionalDetails.length - 1].course_name ||
            !addProfessionalDetails[addProfessionalDetails.length - 1].end_date ||
            !addProfessionalDetails[addProfessionalDetails.length - 1].start_date ||
            !addProfessionalDetails[addProfessionalDetails.length - 1].remarks
        ) {
            enqueueSnackbar("Please fill add all detail before adding new row.", {
                variant: "warning",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        } else {
            let Temp: any = JSON.parse(JSON.stringify(addProfessionalDetails));
            Temp = [
                ...Temp,
                {
                    course_name: null,
                    start_date: null,
                    end_date: null,
                    remarks: null,
                },
            ];
            setAddProfessionalDetails(Temp);
        }
    };
    const OnChangeProfessional = (value: string, row: string, index: number) => {
        let Temp: any = JSON.parse(JSON.stringify(addProfessionalDetails));
        Temp[index][row] = value;
        setAddProfessionalDetails(Temp);
    };
    const DeleteProfessionalAction = (index: number) => {
        let Temp: any = JSON.parse(JSON.stringify(addProfessionalDetails));
        let proDet: any[] = [];
        Temp.forEach((element: any, indexs: any) => {
            if (index != indexs) {
                proDet.push(JSON.parse(JSON.stringify(element)));
            }
        });
        setAddProfessionalDetails([]);
    setTimeout(() => {
        setAddProfessionalDetails(proDet);
    }, 200);
    };
    return (
      <>
        <Accordion
          elevation={0}
          disableGutters
          sx={{
            "&:before": {
              display: "",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item xs={10} marginTop={1} marginBottom={1}>
              <Typography
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  marginLeft: "7%",
                  // marginBottom: 20,
                }}
              >
                Professional Details
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              marginLeft={9}
              xs={10.5}
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  //   style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns2.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!addProfessionalDetails &&
                      addProfessionalDetails.map((row: any, index: number) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={columns2[0]}>
                            {/* {row.organization_name} */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                inputProps={{
                                  readOnly:
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate,
                                  maxLength: 50,
                                }}
                                onChange={(value) =>
                                  OnChangeProfessional(
                                    value.target.value,
                                    "course_name",
                                    index
                                  )
                                }
                                value={row.course_name}
                                placeholder={"Course Name"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[2]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                inputProps={{
                                  readOnly:
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate,
                                }}
                                onChange={(value) =>
                                  OnChangeProfessional(
                                    value.target.value,
                                    "start_date",
                                    index
                                  )
                                }
                                value={row.start_date}
                                placeholder={"Start Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[3]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"date"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                inputProps={{
                                  readOnly:
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate,
                                }}
                                onChange={(value) =>
                                  OnChangeProfessional(
                                    value.target.value,
                                    "end_date",
                                    index
                                  )
                                }
                                value={row.end_date}
                                placeholder={"End Date"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[4]}>
                            {/* remarks */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                inputProps={{
                                  readOnly:
                                    !!id2 && +id2 == 1
                                      ? false
                                      : !!user &&
                                        environment.global_veriable.superAdminLevel.includes(
                                          user.designation
                                        )
                                      ? edit_flag
                                      : !edit_candidate,
                                  maxLength: 50,
                                }}
                                onChange={(value) =>
                                  OnChangeProfessional(
                                    value.target.value,
                                    "remarks",
                                    index
                                  )
                                }
                                value={row.remarks}
                                placeholder={"remarks"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns2[5]}>
                            {addProfessionalDetails.length > 1 && (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() =>
                                      DeleteProfessionalAction(index)
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={3}>
                {(!!id2 && +id2 == 1
                  ? true
                  : !!user &&
                    environment.global_veriable.superAdminLevel.includes(
                      user.designation
                    )
                  ? !edit_flag
                  : edit_candidate) && (
                  <Button
                    onClick={() => addProfessionalDetailsRow()}
                    size="medium"
                    variant="outlined"
                    sx={{ mt: 1 }}
                    startIcon={<AddIcon />}
                  >
                    Add New
                  </Button>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Divider orientation="horizontal" variant="middle" flexItem />
        {/* <Divider
                style={{
                    borderColor: "#DDDDDF",
                    opacity: 0.8,
                    width: "90%",
                    marginLeft: "5%",
                }}
            ></Divider> */}
      </>
    );
}

export default ProfessionalDetails;

interface ProfessionalDetailsProps {
    addProfessionalDetails: any[];
    setAddProfessionalDetails?: any;
    edit_candidate: boolean;
    employee_detail?: ViewEmployee;
    edit_flag?: boolean;
    user?: userDetails;
    id2?: number;
}

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0060FF",
        color: "#ffff",
        fontWeight: 600,
        fontSize: 14,
        padding: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: { md: 14, sm: 12, xs: 8 },
        padding: "5px",
        color: "#363A44",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#F9FAFC",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));