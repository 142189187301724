import {
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";

function InitiateTerminationModal({
  openModal,
  setopenModal,
  control,
  errors,
  getValues,
  handleSubmit,
  setValue,
  watch,
  empForTerminationDetails,
  initiatePayload,
}: InitiateTerminationModalProps) {
  const handleModalClose = () => {
    setopenModal(false);
    setValue("employee_id", "");
  };

  useEffect(() => {
    if (!!empForTerminationDetails) {
      setValue("employee_name", empForTerminationDetails?.employee_name);
      setValue("project_name", empForTerminationDetails?.project_name);
      setValue("designation_name", empForTerminationDetails?.designation_name);
      setValue(
        "employee_type_name",
        empForTerminationDetails?.employee_type_name
      );
      setValue("reason_for_leave", "");
      setValue("last_Working_date", "");
    }
  }, [empForTerminationDetails]);
  const onSubmit = (data: any) => {
    let t_date = new Date();
    let payload: any = {
      employee_id: !!empForTerminationDetails
        ? empForTerminationDetails?.employee_id
        : "",
      // termination_date: moment(t_date).format("YYYY-MM-DD"),
      termination_date: moment(data.last_Working_date).format("YYYY-MM-DD"),
      reason_for_leave: data.reason_for_leave,
      // last_Working_date: moment(data.last_Working_date).format("YYYY-MM-DD"),
    };
    // console.log("payload", payload);
    initiatePayload(payload);
    setopenModal(false);
  };
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Paper
        elevation={5}
        sx={{
          marginTop: 20,
          marginInline: 15,
          marginBottom: 30,
          minWidth: "60%",
          //   minHeight: "60%",
          maxHeight: "80%",
          overflowY: "auto",
          // position: "absolute",
          // marginLeft: `calc(40% - ${Card}px)`,
        }}
      >
        <Grid
          container
          direction={"column"}
          display={"flex"}
          // justifyContent={"center"}
          //style={{ padding: 1, margin: 2 }}
        >
          <Grid
            container
            style={{
              padding: 5,
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            direction={"row"}
          >
            <Grid item xs={6}>
              <Typography style={{ color: "#fff", padding: 10 }}>
                Termination Details
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ paddingBottom: 4 }}>
            {/* <form> */}
            <Grid container justifyContent={"flex-start"} mt={1.5}>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Employee Name
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"employee_name"}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          disabled
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Employee Name"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Designation
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"designation_name"}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          disabled
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Designation Name"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Employee Type
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"employee_type_name"}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          disabled
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Employee Type Name"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Project Name
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"project_name"}
                      rules={{ required: false }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          disabled
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Project Name"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Reason For Leave
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"reason_for_leave"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"text"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          multiline
                          onBlur={onBlur}
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Reason For Leave"}
                          error={!!errors["reason_for_leave"]}
                          helperText={
                            errors.reason_for_leave &&
                            `${errors.reason_for_leave.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ marginBottom: 10 }}
                  // width={"100%"}
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="space-between"
                    alignItems="start"
                  >
                    <Typography
                      style={{
                        fontWeight: "500",
                        fontSize: 16,
                        fontFamily: "Poppins, sans-serif",
                        color: "#626974",
                        paddingLeft: "20%",
                        // textAlign: "center"
                      }}
                    >
                      Last Working Date
                      <span style={{ color: "red", fontSize: 14 }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                    <Controller
                      control={control}
                      name={"last_Working_date"}
                      rules={{ required: true }}
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          type={"date"}
                          id="standard-required"
                          variant="outlined"
                          className={"smaller"}
                          style={{ width: "100.9%" }}
                          onBlur={onBlur}
                          size="small"
                          value={!!value ? value : ""}
                          onChange={(value: any) => onChange(value)}
                          placeholder={"Last Working Date"}
                          error={!!errors["last_Working_date"]}
                          helperText={
                            errors.last_Working_date &&
                            `${errors.last_Working_date.message}* This field is Required`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Initiate
                </Button>
                <Button
                  sx={{
                    color: "#fff",
                    // backgroundColor: "#1565C0",
                    backgroundColor: "#1565C0",
                    paddingX: 4,
                    marginRight: 10,
                  }}
                  onClick={() => handleModalClose()}
                  variant="contained"
                  color="error"
                  size="large"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
            {/* </form> */}
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default InitiateTerminationModal;
interface InitiateTerminationModalProps {
  openModal: boolean;
  setopenModal?: any;
  control?: any;
  setValue?: any;
  getValues?: any;
  errors?: any;
  handleSubmit?: any;
  watch?: any;
  empForTerminationDetails?: any;
  initiatePayload?: any;
}
