import { Cookies } from "react-cookie";
import { EmployeeListAvailableForTerminationService, InitiateTerminationService, TerminatedEmployeeListService, TerminationApprovalService, TerminationInitiatedEmployeeListService } from "../../services/terminationService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";

export enum TerminationActionTypes {
  Employee_List_Available_For_Termination_Success_Action = "[TERMINATION] Employee List Available For Termination Success Action",
  Terminated_Employee_List_Success_Action = "[TERMINATION] Terminated Employee List Success Action",
  Initiate_Termination_Success_Action = "[TERMINATION] Initiate Termination Success Action",
  Termination_Initiated_Employee_List_Success_Action = "[TERMINATION] Termination Initiated Employee List Success Action",
  Termination_Approval_Success_Action = "[TERMINATION] Termination Approval Success Action",
}

//Available Employee List For Termination
export const EmployeeListAvailableForTerminationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Available Employee List for Termination Fetching. Please Wait...",
        type: 2,
      })
    );
    return EmployeeListAvailableForTerminationService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(EmployeeListAvailableForTerminationSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EmployeeListAvailableForTerminationSuccessAction = (data: any) => {
  return {
    type: TerminationActionTypes.Employee_List_Available_For_Termination_Success_Action,
    payload: data,
  };
};

//Initiate termination
export const InitiateTerminationAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Initiate Termination. Please Wait...",
        type: 2,
      })
    );
    return InitiateTerminationService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(InitiateTerminationSuccessAction());
          payload.enqueueSnackbar("Successfully Termination Initiated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EmployeeListAvailableForTerminationAction(payload.list_data))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const InitiateTerminationSuccessAction = () => {
  return {
    type: TerminationActionTypes.Initiate_Termination_Success_Action
  }
};
//Termination Initiated Employee List
export const TerminationInitiatedEmployeeListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Initiate Termination. Please Wait...",
        type: 2,
      })
    );
    return TerminationInitiatedEmployeeListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(TerminationInitiatedEmployeeListServiceSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const TerminationInitiatedEmployeeListServiceSuccessAction = (data: any) => {
  return {
    type: TerminationActionTypes.Termination_Initiated_Employee_List_Success_Action,
    payload: data,
  }
};

//Terminated Employee List Service
export const TerminatedEmployeeListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Terminated Employee List Fetching. Please Wait...",
        type: 2,
      })
    );
    return TerminatedEmployeeListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(TerminatedEmployeeListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const TerminatedEmployeeListSuccessAction = (data: any) => {
  return {
    type: TerminationActionTypes.Terminated_Employee_List_Success_Action,
    payload: data,
  };
};

//Termination Approval Action
export const TerminationApprovalAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Termination Approval Fetching. Please Wait...",
        type: 2,
      })
    );
    return TerminationApprovalService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(TerminationApprovalSuccessAction());
          payload.enqueueSnackbar("Action Submitted Successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(TerminationInitiatedEmployeeListAction(payload.data_list))
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const TerminationApprovalSuccessAction = () => {
  return {
    type: TerminationActionTypes.Termination_Approval_Success_Action   
  };
};