import { PromotionMain } from "../../models/promotionModel";
import { PromotionActionTypes } from "../actions/promotionAction";
import InitialState from "./initialState";

const initialState: PromotionMain = InitialState.promotion;
export default function PromotionReducer(
  state: PromotionMain = initialState,
  action: any
) {
  switch (action.type) {
    case PromotionActionTypes.Promotion_List_Success_Action:
      return { ...state, promotion_list: action.payload };
    case PromotionActionTypes.View_Promotion_Success_Action:
      return { ...state, view_promotion: action.payload };
    case PromotionActionTypes.Promotion_Approval_Success_Action:
      return { ...state, pending_promtions: action.payload };
    case PromotionActionTypes.Employee_Available_List_For_Promotion_Success_Action:
      return { ...state, emp_list_promotions: action.payload };
    default:
      return state;
  }
}
