import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EmployeeAllDetail, ExperienceDetail1 } from "../../../models/employeeModel";
import { useSnackbar } from "notistack";
import EmployeeEditExperienceDetailsPopover from "./EmployeeEditExperienceDetailsPopover";

function EmployeeExperienceDetails({
  experience_details,
  employeeDetails,
  Submit
}: EmployeeExperienceDetailsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [addExpDetail, setaddExpDetail] = useState<any>([
    {
      company_name: null,
      designation: null,
      started_at: null,
      ended_at: null,
      ctc: null,
      remarks: null,
    },
  ]);
  const addExperienceDetails = () => {
    if (experience_details?.length === 0) {
      let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
      Temp = [
        ...Temp,
        {
          company_name: null,
          designation: null,
          started_at: null,
          ended_at: null,
          ctc: null,
          remarks: null
        },
      ];
      setaddExpDetail(Temp);
    } else if (
      !addExpDetail[addExpDetail.length - 1].company_name ||
      !addExpDetail[addExpDetail.length - 1].started_at ||
      !addExpDetail[addExpDetail.length - 1].ended_at
    ) {
      enqueueSnackbar("Please fill add all detail before adding new row.", {
        variant: "warning",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
      Temp = [
        ...Temp,
        {
          company_name: null,
          designation: null,
          started_at: null,
          ended_at: null,
          ctc: null,
          remarks: null
        },
      ];
      setaddExpDetail(Temp);
    }
  };
  const OnChangeExperience = (value: string, row: string, index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
    Temp[index][row] = value;
    setaddExpDetail(Temp);
  };
  const DeleteExpAction = (index: number) => {
    let Temp: any = JSON.parse(JSON.stringify(addExpDetail));
    let expDet: any[] = [];
    Temp.forEach((element: any, indexs: any) => {
      if (index != indexs) {
        expDet.push(JSON.parse(JSON.stringify(element)));
      }
    });
    setaddExpDetail([]);
    setTimeout(() => {
      setaddExpDetail(expDet);
    }, 200);
  };
  const onSubmit = (data: any) => {
    console.log(data);
    let payload: any = {
      experience_details:
      addExpDetail.length == 1
          ? !!addExpDetail[0].company_name
            ? addExpDetail
            : []
          : addExpDetail.length > 1
          ? addExpDetail
          : [],
      employee_id: employeeDetails?.employee_id,
    };
    Submit(payload);
  };
  return (
    <>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          "&:before": {
            display: "",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            container
            justifyContent={"start"}
            //alignItems={""}
            direction={"row"}
            //padding={1}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: 18,
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "left",
                  color: "#626974",
                  //mb: 10,
                }}
              >
                Experience Details
              </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container justifyContent="end" alignItems="center" padding={0}>
            <Tooltip title="Edit">
              <IconButton onClick={handleClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <EmployeeEditExperienceDetailsPopover
              anchorEl={anchorEl}
              experience_details={experience_details}
              setAnchorEl={setAnchorEl}
              SubmitPopData={onSubmit}
              addExpDetail={addExpDetail}
              setaddExpDetail={setaddExpDetail}
              OnChangeExperience={OnChangeExperience}
              DeleteExpAction={DeleteExpAction}
              addExperienceDetails={addExperienceDetails}
            />
          </Grid>
          {!!experience_details &&
            experience_details.map((row) => (
              <Card sx={{ minWidth: 275, maxWidth: "90%", mb: 2 }} key={row.experience_details_id}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Company Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.company_name ? row?.company_name : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Designation
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.designation ? row?.designation : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Start Date
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.started_at ? row?.started_at : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        End Date
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.ended_at ? row?.ended_at : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        CTC
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.ctc ? row?.ctc : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    paddingTop={1}
                  >
                    <Grid item xs={5.5}>
                      <Typography sx={{ fontSize: 14 }} color="#4E6E81">
                        Remarks
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        :
                      </Typography>
                    </Grid>
                    <Grid item xs={5.5}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#2E3840"
                        gutterBottom
                      >
                        {!!row?.remarks ? row?.remarks : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default EmployeeExperienceDetails;

interface EmployeeExperienceDetailsProps {
  experience_details: ExperienceDetail1[];
  Submit?: any;
  employeeDetails?: EmployeeAllDetail;
}
