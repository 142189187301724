import { baseUrl, environment } from "../environment";
import { CreateRolePayload, GetRoleByIDPayload, RoleAssignToUserPayload, UpdateRolePayload, UserRoleMappingPayload } from "../models/userManagementModel";
import RestService from "./rest";

export const serviceClient = new RestService({
    baseURL: baseUrl,
  });
  export const GetAllAccessControlMasterService = () => {
    return serviceClient.get(environment.urls.getAllAccessControl);
  };
  export const CreateRoleService = (
    data: CreateRolePayload
  ) => {
    return serviceClient.post(environment.urls.createRole, data);
  };
  export const GetAllRoleMasterListService = () => {
    return serviceClient.get(environment.urls.getAllRoleMasterList);
  };

  export const GetRoleByIDService = (
    data: GetRoleByIDPayload
  ) => {
    return serviceClient.post(environment.urls.getRoleByID, data);
  };
  export const UpdateRoleService = (
    data: UpdateRolePayload
  ) => {
    return serviceClient.post(environment.urls.updateRole, data);
  };
  export const UserRoleMappingService = (
    data: UserRoleMappingPayload
  ) => {
    return serviceClient.post(environment.urls.userRoleMapping, data);
  };
  export const RoleAssignToUserService = (
    data: RoleAssignToUserPayload
  ) => {
    return serviceClient.post(environment.urls.roleAssignToUser, data);
  };