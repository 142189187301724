import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
  tableCellClasses,
  NativeSelect,
  Link,
} from "@mui/material";
import { watch } from "fs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import { SalaryComponentEditAction } from "../../../stores/actions/organizationActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { ProjectList } from "../../../models/customerModel";
import {
  AllDesignationList,
  EmployeeTypeList,
  SalaryStructureEditDetails,
  SalaryStructureList,
} from "../../../models/organizationModels";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { EmpList, SalaryStructureHistory } from "../../../models/employeeModel";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import AddPromotionApproveView from "./AddPromotionApproveView";
import SalaryDetailView from "./SalaryDetailView";
import { environment } from "../../../environment";
import {
  EmployeeList,
  EmployeeListforPromotion,
} from "../../../models/promotionModel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const AddPromotionViewNew = ({
  searchEmployeeList,
  searchByNameEmpAction,
  project_list,
  employee_type_list,
  control,
  errors,
  getValues,
  setValue,
  watch,
  handleSubmit,
  FilterSubmit,
  employee_list,
  designation_list,
  salary_structure_list,
  salary_structure,
  SalaryComponentEditAction,
  CreatedPromotion,
  ViewPromDet,
  SalaryHistory,
  empDet,
  getEmployeeAllDetailsAction,
  ClearAllFilter,
  emp_list_promotions,
  pageindex,
  pagesize,
  rowperpage,
  SetpageSize,
  Setpageindex,
  total_size,
}: AddPromotionViewNewProps) => {
  let navigateTo = useNavigate();
  const navigate = (url: any) => {
    navigateTo(url);
  };

  const onSubmit = (data: any) => {
    FilterSubmit(data);
  };
  let timmer: any;
  const GetCall = (searchVal: string) => {
    console.log("searchVal", searchVal);
    if (!!timmer) {
      clearTimeout(timmer);
    }
    timmer = setTimeout(() => {
      searchByNameEmpAction(searchVal, "");
    }, 2000);
  };
  useEffect(() => {
    if (!!getValues("employee_name")) {
      setValue("employee_id", getValues("employee_name"));
    }
  }, [watch("employee_name")]);
  useEffect(() => {
    if (!!getValues("employee_id")) {
      getEmployeeAllDetailsAction({ employee_id: getValues("employee_id") });
    }
  }, [watch("employee_id")]);
  const column1: any[] = [
    "Employee ID",
    "Employee Name",
    "Designation Name",
    "Project Name",
    "Salary Structure",
    // "Action",
  ];
  const [approvalList, SetapprovalList] = useState<EmployeeList[]>([]);
  const [all_approve, Set_Avpprove] = useState<boolean>(false);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<EmployeeList[]>([]);
  const [openAttendancePopup, setOpenAttendancePopup] = React.useState(false);
  // console.log("rowsssss", rows, "employee_list", employee_list);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!emp_list_promotions
      ? emp_list_promotions.data.filter((row: EmployeeList) => {
        return (
          row?.project_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.designation_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.salary_stucture_name
            ?.toLowerCase()
            .includes(searchedVal.toLowerCase()) ||
          row?.employee_id?.toLowerCase().includes(searchedVal.toLowerCase())
        );
      })
      : [];
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const { enqueueSnackbar } = useSnackbar();
  const SelectRow = (checked: boolean, row: EmployeeList) => {
    if (checked) {
      if (
        approvalList.length > 0 &&
        approvalList[approvalList.length - 1].project_name != row.project_name
      ) {
        enqueueSnackbar(`Mutiselect is only allow for same type of project.`, {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else if (
        approvalList.length > 0 &&
        approvalList[approvalList.length - 1].designation_name !=
        row.designation_name
      ) {
        enqueueSnackbar(
          `Mutiselect is only allow for same type of designation.`,
          {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      } else {
        let events: EmployeeList[] = JSON.parse(JSON.stringify(approvalList));
        events.push(row);
        SetapprovalList(events);
      }
    } else {
      let events: EmployeeList[] = JSON.parse(JSON.stringify(approvalList));
      events = events.filter((m) => m.employee_id != row.employee_id);
      if (events.length == 0) Set_Avpprove(false);
      SetapprovalList(events);
    }
  };
  console.log("approvalList", approvalList);

  useEffect(() => {
    const AllSelection = async () => {
      if (all_approve) {
        let appr: EmployeeList[] = [];
        for await (const [index, ap] of rows.entries()) {
          if (index == 0) {
            appr.push(ap);
          } else {
            if (
              JSON.stringify(appr[appr.length - 1].project_name) ==
              JSON.stringify(ap.project_name) &&
              appr[appr.length - 1].designation_name == ap.designation_name
            ) {
              appr.push(ap);
            }
          }
        }
        SetapprovalList(appr);
      } else {
        SetapprovalList([]);
      }
    };
    AllSelection();
  }, [all_approve]);

  const styles = {
    marginRight: "50px",
  };
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };
  useEffect(() => {
    if (!!emp_list_promotions) {
      setRows(emp_list_promotions.data);
    }
  }, [emp_list_promotions]);
  const AcceptPromotionScreen = () => {
    setOpenAttendancePopup(true);
  };
  const [openModal, setopenModal] = useState<boolean>(false);
  console.log("salary_structure", salary_structure);
  const ClearFilter = () => {
    setValue("employee_type_id", null);
    setValue("employee_id", null);
    setValue("employee_name", null);
    setValue("project_id", null);
    setValue("designation_id", null);
    setValue("salary_structure_id", null);
    ClearAllFilter();
  };

  // useEffect(() => {
  //   if (!!emp_list_promotions?.data) {
  //     setRows(emp_list_promotions.data);
  //   }
  // }, [emp_list_promotions?.data, pageindex, pagesize]);

  return (
    <>
      <AddPromotionApproveView
        open={openAttendancePopup}
        setOpen={setOpenAttendancePopup}
        approvalList={approvalList}
        SetapprovalList={SetapprovalList}
        getValues={getValues}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        handleSubmit={handleSubmit}
        designation_list={designation_list}
        salary_structure_list={salary_structure_list}
        SalaryComponentEditAction={SalaryComponentEditAction}
        salary_structure={salary_structure}
        CreatedPromotion={CreatedPromotion}
      />
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          // justifyContent={"center"}
          // alignItems={"center"}
          style={{ textAlign: "center", padding: 14 }}
        >
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => navigate(`/promotion/promotion-list/`)}
            // style={{ marginLeft: -10 }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} marginTop={1}>
            <Typography
              style={{
                color: "#132437",
                fontWeight: 600,
                fontFamily: "Alef, sans-serif",
                fontSize: 20
              }}
            >
              Add Promotion Details
            </Typography>
          </Grid>
        </Grid>

        <Divider
          style={{
            borderColor: "#9E9E9E",
            opacity: 0.7,
            width: "90%",
            marginLeft: "5%",
          }}
        ></Divider>

        <form>
          <Grid container justifyContent={"flex-start"} mt={1.5}>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginTop: 8, marginBottom: 10 }}
              // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Employee ID
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                  <Controller
                    control={control}
                    name={"employee_id"}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"text"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        size="small"
                        value={!!value ? value : ""}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Enter Employee ID"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginBottom: 10 }}
              // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Employee Name
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                  <Controller
                    control={control}
                    name={"employee_name"}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={searchEmployeeList}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"employee_id"}
                        label={"employee_name"}
                        onInputChange={(e: any) => {
                          if (e?.target?.value) GetCall(e.target.value);
                        }}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Employee"}
                        value={!!value ? value : ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginBottom: 10 }}
              // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Designation
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                  <Controller
                    control={control}
                    name={"designation_id"}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={designation_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"designation_id"}
                        label={"designation_name"}
                        onInputChange={(e: any) => {
                          if (e?.target?.value) GetCall(e.target.value);
                        }}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Designation"}
                        value={!!value ? value : ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginBottom: 10 }}
              // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Salary Structure
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                  <Controller
                    control={control}
                    name={"salary_structure_id"}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={salary_structure_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"salary_structure_id"}
                        label={"salary_stucture_name"}
                        onInputChange={(e: any) => {
                          if (e?.target?.value) GetCall(e.target.value);
                        }}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder={"Search Salary Structure"}
                        value={!!value ? value : ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                style={{ marginBottom: 10 }}
              // width={"100%"}
              >
                <Grid
                  item
                  xs={5}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      color: "#626974",
                      paddingLeft: "20%",
                      // textAlign: "center"
                    }}
                  >
                    Employee Type
                  </Typography>
                </Grid>
                <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                  <Controller
                    control={control}
                    name={"employee_type_id"}
                    rules={{ required: false }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={employee_type_list}
                        //   readOnly={!!department ? edit_flag : !edit_flag}
                        keys={"employee_type_id"}
                        label={"employee_type_name"}
                        onBlur={onBlur}
                        onChange={(value: any) => (
                          onChange(value), setValue("project_id", null)
                        )}
                        placeholder={"Search Employee Type"}
                        value={!!value ? value : ""}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!!watch("employee_type_id") &&
              !environment.global_veriable.ho_employees_type_id.includes(
                +watch("employee_type_id")
              ) && (
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginBottom: 10 }}
                  // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Project Name
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -10 }}>
                      <Controller
                        control={control}
                        name={"project_id"}
                        rules={{ required: false }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={project_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"project_id"}
                            label={"project_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Project Name"}
                            value={!!value ? value : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={ClearFilter}
              variant="contained"
              color="primary"
              size="large"
            >
              Clear
            </Button>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              size="large"
            >
              Filter
            </Button>
          </Grid>
        </form>
      </Paper>
      <Paper
        elevation={3}
        sx={{ marginX: 5, marginTop: 3, marginBottom: 1.4, padding: 2 }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
        // style={{ textAlign: "center", paddingTop: 8 }}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              style={{ width: "100%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Grid container direction={"row"} marginTop={1}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="justify" key={""}>
                        <Checkbox
                          style={{ color: "#ffff" }}
                          checked={all_approve}
                          onChange={(event) =>
                            Set_Avpprove(event.target.checked)
                          }
                        />
                      </StyledTableCell>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows

                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.employee_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              <Checkbox
                                color="primary"
                                checked={
                                  !!approvalList.find(
                                    (m) => m.employee_id == row.employee_id
                                  )
                                }
                                onChange={(event) =>
                                  SelectRow(event.target.checked, row)
                                }
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[1]}>
                              <Link
                                onClick={() =>
                                  navigateTo(
                                    `/employment/manage-profile/${row.employee_id}`
                                  )
                                }
                              >
                                {row.employee_id}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[2]}>
                              {row.employee_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {row.designation_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[4]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[5]}>
                              {row.salary_stucture_name}
                            </StyledTableCell>
                            {/* <StyledTableCell align="left" key={column1[6]}>
                              <Tooltip
                                title="View"
                                onClick={() => (
                                  ViewPromDet(row), setopenModal(true)
                                )}
                              >
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell> */}
                          </StyledTableRow>
                        ))}
                    {!!rows && rows.length <= 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={14}>
                          <div style={{ color: "red", textAlign: "center" }}>
                            No Data Found{" "}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
              <Grid container alignItems={"center"} justifyContent="end">
                <Grid xs={2} textAlign="right">
                  Rows per page:
                </Grid>
                <Grid xs={1} textAlign="center">
                  <NativeSelect
                    value={pagesize.toString()}
                    onChange={(event) => SetpageSize(+event.target.value)}
                  >
                    {rowperpage.map((p) => (
                      <option
                        key={p}
                        value={p}
                        onChange={(value: any) => value(p)}
                      >
                        {p}
                      </option>
                    ))}
                  </NativeSelect>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    onClick={() => Setpageindex(pageindex - 1)}
                    color="primary"
                    size="small"
                    disabled={pageindex <= 0}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <Typography>
                    {pageindex * pagesize + 1} -{" "}
                    {!!total_size && total_size <= (pageindex + 1) * pagesize
                      ? total_size
                      : (pageindex + 1) * pagesize}
                    {" of "}
                    {total_size}
                  </Typography>
                </Grid>
                <Grid xs={1} textAlign="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => Setpageindex(pageindex + 1)}
                    disabled={
                      (!!total_size &&
                        total_size <= (pageindex + 1) * pagesize) ||
                      total_size === 0
                    }
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: "1.2em" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          padding={3}
        >
          <Button
            sx={{
              color: "#fff",
              backgroundColor: "#1565C0",
              paddingX: 4,
              marginRight: 10,
            }}
            disabled={approvalList.length <= 0}
            onClick={() => AcceptPromotionScreen()}
            variant="contained"
            color="primary"
            size="large"
          >
            ADD
          </Button>
        </Grid>
      </Paper>
      <SalaryDetailView
        openModal={openModal}
        setopenModal={setopenModal}
        SalaryHistory={SalaryHistory}
        empDet={empDet}
      />
    </>
  );
};

export default AddPromotionViewNew;
interface AddPromotionViewNewProps {
  searchByNameEmpAction?: any;
  searchEmployeeList: IEmployeeList[];
  project_list: ProjectList[];
  employee_type_list: EmployeeTypeList[];
  getValues?: any;
  control?: any;
  setValue?: any;
  watch?: any;
  errors?: any;
  handleSubmit?: any;
  FilterSubmit?: any;
  employee_list: EmpList[];
  designation_list: AllDesignationList[];
  salary_structure_list: SalaryStructureList[];
  salary_structure?: SalaryStructureEditDetails;
  SalaryComponentEditAction?: any;
  CreatedPromotion?: any;
  ViewPromDet?: any;
  SalaryHistory: SalaryStructureHistory[];
  empDet?: any;
  getEmployeeAllDetailsAction?: any;
  ClearAllFilter?: any;
  emp_list_promotions?: EmployeeListforPromotion;
  pagesize: number;
  pageindex: number;
  rowperpage: number[];
  total_size?: number;
  SetpageSize?: any;
  Setpageindex?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e6f0ff",
    color: "#010101",
    fontWeight: 600,
    fontSize: 14,
    padding: "3px",
    paddingLeft: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px 1px 0px 5px",
    // color: "#363A44",
    color: "#010101",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
