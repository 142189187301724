import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Time } from "../../../models/timingSheetModel";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ListPlugin from "@fullcalendar/list";

function TimeSheetHistoryTable({
  item,
  openTable,
  setOpenTable,
}: TimeSheetHistoryTableProps) {
  const handleErrorModalClose = () => {
    setOpenTable(false);
  };
  const columns: any[] = ["Date", "Working Hours"];
  const [currentEvents, setCurrentEvents] = useState<any[]>([]);
  //   const [eventsData, setEventsData] = useState<Time[]>([]);
  //   const [employeeWorkingHours, setEmployeeWorkingHours] = useState<any[]>([]);
  const [timeSheetDate, setTimeSheetDate] = useState<Date>(new Date());
  useEffect(() => {
    if (!!item && item.length > 0) {
      const updatedList = item.map((self) => ({
        date: self.date,
        id: Math.random(),
        title: self.work_hours,
        color: self.work_hours === 0 ? "#e68987" : "#40bf80",
      }));
      setCurrentEvents(updatedList);
      let timeSheetCalenderDate: any =
        !!item && item.find((m) => m.date[0])?.date;
      setTimeSheetDate(timeSheetCalenderDate);
    }
  }, [item]);
  console.log("werewrtw", timeSheetDate);

  return (
    <Modal
      open={openTable}
      onClose={handleErrorModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      sx={{ height: 50 }}
    >
      <Paper
        elevation={2}
        sx={{
          marginTop: 10,
          marginInline: 15,
          marginBottom: 10,
          minWidth: "50%",
          //   minHeight: "40%",
        }}
      >
        {/* <Grid container direction={"column"} display={"flex"}>
          <Grid
            container
            style={{
              padding: 10,
              backgroundColor: "#0060FF",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <Grid item>
              <Typography style={{ color: "#fff" }}>
                Work Hour Details
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ padding: 1, paddingBottom: 4 }}>
            <TableContainer sx={{ borderRadius: 1 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell align="justify" key={column}>
                        {column}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!item &&
                    item.map((row: any, index: any) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={Math.random()}
                      >
                        <StyledTableCell align="left" key={columns[0]}>
                          {row.date}
                        </StyledTableCell>
                        <StyledTableCell align="left" key={columns[1]}>
                          {row.work_hours}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems={"center"}
            padding={3}
          >
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#1565C0",
                paddingX: 4,
                marginRight: 10,
              }}
              onClick={() => handleErrorModalClose()}
              variant="contained"
              color="primary"
              size="large"
            >
              Close
            </Button>
          </Grid>
        </Grid> */}
        <Box flex="1 1 85%" p="15px" borderRadius="4px">
          <FullCalendar
            key={Math.random()}
            height="50vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              ListPlugin,
            ]}
            headerToolbar={{
              left: "",
              center: "title",
              right: "",
            }}
            // initialDate={new Date('01-02-2023')}
            initialDate={new Date(timeSheetDate)}
            editable={false}
            events={currentEvents}
          />
        </Box>
      </Paper>
    </Modal>
  );
}

export default TimeSheetHistoryTable;
interface TimeSheetHistoryTableProps {
  item?: Time[];
  openTable: boolean;
  setOpenTable?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FD6500",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
