import { Cookies } from "react-cookie";
import {
  IAddHolidayTyPayload,
  IApplyLeavePayload,
  ICalculateLeaveAmount,
  ICancelLeavePayload,
  ICreateLeaveManagementPayload,
  ICreateLeaveTypePayload,
  IEditHolidayTyPayload,
  IEditLeaveManagementPayload,
  IEditLeavePayload,
  IGetLeaveAmountPayload,
} from "../../models/leaveModel";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import {
  EditLeavelTypeService,
  GetAllLeaveTypeService,
  GetLeaveTypeByIdService,
  createHolidayService,
  createLeaveTypeService,
  deleteHolidayService,
  deleteLeaveTypeService,
  editHolidayService,
  getAllHolidaysService,
  getHolidayByIdService,
  createLeaveManagementService,
  editLeaveManagementService,
  deleteLeaveManagementService,
  getAllLeaveCountDesignationWise,
  getLeaveDetailsDesignationwiseService,
  getAllLeaveManagementService,
  getDesignationWiseLeaveService,
  getEmployeeByDesignationService,
  getLeaveAmountService,
  calculateLeaveDaysService,
  applyLeaveService,
  getLeaveDetailsService,
  getAllLeaveApprovalListService,
  UpdateLeaveApprovalStatusService,
  LeaveCalendarDetailsService,
  getAllLeaveListService,
  editAllLeaveService,
  getLeaveDetailsForDashboardService,
  cancelLeaveService,
  getStateByAddressIdService,
  getHolidayByEmployeeService,
  naturalHolidayListService,
} from "../../services/leaveService";
import { LogOutAction } from "./userAction";
import { ErrorModel } from "../../models/errorModels";

export enum ELeaveActionTypes {
  Get_All_Leave_Type_Action = "[Leave] Get All Customer Success Action",
  Get_Leave_Type_By_Id_Action = "[Leave] Get Leave Type By Id Action",
  Get_Holiday_List_Action = "[Holidays] Get All Holiday List Action",
  Get_Holiday_By_Id_Action = "[Holidays] Get Holiday By Id",
  Get_Leave_Management_List_Action = "[Leave] Get Leave Management List Action",
  Get_Leave_Management_By_Id_Action = "[Leave] Get Leave Management By Id Action",
  Get_Designation_wise_Leave_count = "[LEAVE] Get Designation Wise Leave Count",
  Get_Designation_wise_leave_details = "[LEAVE] Get Designation wise leave details",
  Select_organization_For_Leave = "[Leave] Select Organization For Leave",
  Get_Leave_management_details = "[Leave] Get Leave Management Details",
  Get_Leave_Details_By_Designation = "[Leave] Get Designation wise Leave Details",
  Clear_Leave_Details_By_Designation = "[Leave] Clear Designation wise Leave Details",
  Get_Employee_Details_By_Designation = "[Leave] Get Employee Details By Designation",
  Allocate_Emp_Success_Action = "Allocate_Emp_Success_Action",
  Get_Leave_Amount_Action = "[Leave] Get Leave Amount Action",
  Get_Calculated_Leave_Days = "[Leave] Get Calculated Leave Days",
  Get_Leave_Details_Action = "[Leave] Get Leave Details Action",
  Get_All_Leave_Approval_List_Action = "[Leave] Get All Leave Approval List Action",
  Update_Approve_Leave_Status_Action = "[Leave] Update Approve Leave Status Action",
  Leave_Calendar_Details_Action = "[Leave] Leave Calendar Details Action",
  Leave_calulation_state_reset_Action = "[Leave] Leave Calulation State Reset Action",
  Get_All_Leave_List_Action = "[Leave] Get All Leave List Action",
  Get_Leave_Details_For_Dashboard_Action = "[Leave] Get Leave Detail For Dashboard Action",
  Get_State_By_Address_Id_Action = "[Leave] Get_State_By_Address_Id_Action",
  Get_Holiday_By_Employee_Id_Action = "[Leave] Get Holiday By Employee Id Action",
  Get_Natural_Holiday_By_Employee_Action = "[Leave] Get Natural Holiday By Employee Action",
}

//  ****************************************     leave type actions family  *****************************************//
export const GetAllLeaveTypeAction = () => (dispatch: any, getState: any) => {
  dispatch(
    BeginApiCallAction({
      count: 1,
      message: "leave type fetching ...  Please Wait...",
      type: 2,
    })
  );
  let defaultOrgId;
  // if (getState().leave?.selected_organization) {
  //   defaultOrgId = Number(getState()?.leave?.selected_organization);
  // } else {
    defaultOrgId = Number(getState()?.user?.employee_details?.organization_id);
  // }
  console.log("defaultOrgId: " + defaultOrgId);
  return GetAllLeaveTypeService(Number(defaultOrgId))
    .then(async (response) => {
      if (response.status !== 200) {
        dispatch(ApiCallErrorAction(response.data));
      } else {
        dispatch({
          type: ELeaveActionTypes.Get_All_Leave_Type_Action,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        let cook = new Cookies();
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Please Login again to continue.",
          })
        );
        cook.remove("user", { path: "/" });
        dispatch(LogOutAction());
      } else if (error.response.status === 500) {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: error?.response?.data?.message,
          })
        );
      } else {
        dispatch(
          ApiCallErrorAction({
            errorCode: "",
            message: "Error encountered please try again later",
          })
        );
      }
    });
};

export const GetLeaveTypeByIdAction =
  (levelId: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type fetching ...  Please Wait...",
        type: 2,
      })
    );
    return GetLeaveTypeByIdService(levelId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_Type_By_Id_Action,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const EditLevelTypeAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type fetching ...  Please Wait...",
        type: 2,
      })
    );
    return EditLeavelTypeService(payload.formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllLeaveTypeAction());
          await payload.enqueueSnackbar("Leave type updated successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const deleteLevelTypeAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const leaveId: number = payload.leaveId;
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type deleting ...  Please Wait...",
        type: 2,
      })
    );
    return deleteLeaveTypeService(leaveId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllLeaveTypeAction());
          await payload.enqueueSnackbar("Leave type deleted successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const createLeaveTypeAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const formData: ICreateLeaveTypePayload = payload.formData;
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return createLeaveTypeService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllLeaveTypeAction());
          await payload.enqueueSnackbar("Leave type created successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

//  ****************************************     organizational holiday actions family  *****************************************//

export const getAllHolidaysAction =
  (orgId?: number, year?: number, selectedStates?: string) =>
  (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return getAllHolidaysService(orgId, year, selectedStates)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Holiday_List_Action,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const AddHolidayAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const formData: IAddHolidayTyPayload = payload.formData;
    const selectedOrganization = Number(
      getState()?.leave?.selected_organization
    );
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Holiday list fetching Please Wait...",
        type: 2,
      })
    );
    return createHolidayService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getAllHolidaysAction(selectedOrganization));
          await payload.enqueueSnackbar("Holiday updated successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getHolidayById =
  (holidayId: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return getHolidayByIdService(holidayId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Holiday_By_Id_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const deleteHolidayByAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const selectedOrganization = Number(
      getState()?.leave?.selected_organization
    );
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return deleteHolidayService(Number(payload.deleteId))
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getAllHolidaysAction(selectedOrganization));
          await payload.enqueueSnackbar("Holiday deleted successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const editHolidayAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const formData: IEditHolidayTyPayload = payload.formData;
    const selectedOrganization = Number(
      getState()?.leave?.selected_organization
    );
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return editHolidayService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getAllHolidaysAction(selectedOrganization));
          await payload.enqueueSnackbar("Holiday updated successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

//  ************************************       leave management actions family  **********************************  //

export const getAllLeaveManagementData =
  () => (dispatch: any, getState: any) => {
    let defaultOrgId;
    if (getState().leave?.selected_organization) {
      defaultOrgId = Number(getState().leave.selected_organization);
    } else {
      defaultOrgId = Number(getState().user?.employee_details?.organization_id);
    }
    return getAllLeaveManagementService(defaultOrgId)
      .then(async (response: any) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_management_details,
            payload: response.data.data,
          });
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getAllLeaveCountDesignationAction =
  () => (dispatch: any, getState: any) => {
    let defaultOrgId;
    if (getState().leave?.selected_organization) {
      defaultOrgId = Number(getState().leave.selected_organization);
    } else {
      defaultOrgId = Number(getState().user?.employee_details?.organization_id);
    }
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave management data fetching  Please Wait...",
        type: 2,
      })
    );
    return getAllLeaveCountDesignationWise(defaultOrgId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Designation_wise_Leave_count,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
export const getLeaveDetailsDesignationwiseAction =
  (deisgnationId: number) => (dispatch: any, getStae: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return getLeaveDetailsDesignationwiseService(deisgnationId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Designation_wise_leave_details,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const createLeaveManagementAction =
  (payload: any) => (dispatch: any, getState: any) => {
    const formData: ICreateLeaveManagementPayload = payload.formData;
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave management creating  Please Wait...",
        type: 2,
      })
    );
    return createLeaveManagementService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // dispatch(getLeaveManagementListAction());
          dispatch(
            getLeaveDetailsDesignationwiseAction(formData.designation_id)
          );

          await payload.enqueueSnackbar("Holiday updated successfully ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(getAllLeaveCountDesignationAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const editLeaveManagementAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    const formData: IEditLeaveManagementPayload = payload.formData;
    return editLeaveManagementService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            getLeaveDetailsDesignationwiseAction(formData.designation_id)
          );
          dispatch(getAllLeaveCountDesignationAction());
          await payload.enqueueSnackbar(
            "Leave managememnt updated successfully ",
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const deleteLeaveManagementAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "leave type creating  Please Wait...",
        type: 2,
      })
    );
    return deleteLeaveManagementService(Number(payload.leave_management_id))
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          if (payload?.designation_id) {
            dispatch(
              getLeaveDetailsDesignationwiseAction(payload.designation_id)
            );
          }
          dispatch(getAllLeaveCountDesignationAction());
          await payload.enqueueSnackbar(
            "Leave managememnt deleted successfully ",
            {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const selectOrganization =
  (orgId: number) => (dispatch: any, getState: any) => {
    dispatch({
      type: ELeaveActionTypes.Select_organization_For_Leave,
      payload: orgId,
    });
  };

export const getDesignationWiseLeaveAction =
  (descId: number) => (dispatch: any, getState: any) => {
    return getDesignationWiseLeaveService(descId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_Details_By_Designation,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const clearDesignationWiseLeave =
  () => (dispatch: any, getState: any) => {
    dispatch({ type: ELeaveActionTypes.Clear_Leave_Details_By_Designation });
  };

export const getEmployeeByDesignationAction =
  (descId: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Employee details fetching  Please Wait...",
        type: 2,
      })
    );
    return getEmployeeByDesignationService(descId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Employee_Details_By_Designation,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getLeaveAmountAction =
  (payload: IGetLeaveAmountPayload) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Leave details fetching  Please Wait...",
        type: 2,
      })
    );
    return getLeaveAmountService(payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_Amount_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const calculateLeaveDaysAction =
  (payload: ICalculateLeaveAmount) => (dispatch: any, getState: any) => {
    BeginApiCallAction({
      count: 1,
      message: "Leave days calculating,   Please Wait...",
      type: 2,
    });
    return calculateLeaveDaysService(payload)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          console.log(
            "ELeaveActionTypes.Get_Calculated_Leave_Days",
            response.data.data
          );
          dispatch({
            type: ELeaveActionTypes.Get_Calculated_Leave_Days,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

// apply leave action

export const getLeaveDetailsAction =
  (employee_id: string) => (dispatch: any, getState: any) => {
    BeginApiCallAction({
      count: 1,
      message: "Leave days fetching,   Please Wait...",
      type: 2,
    });
    return getLeaveDetailsService(employee_id)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_Details_Action,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const applyLeaveAction =
  (payload: any) => (dispatch: any, getState: any) => {
    BeginApiCallAction({
      count: 1,
      message: "Leave days calculating,   Please Wait...",
      type: 2,
    });
    const formData: IApplyLeavePayload = payload.formData;
    return applyLeaveService(formData)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          console.log(
            "ELeaveActionTypes.Get_Calculated_Leave_Days",
            response.data.data
          );
          payload.enqueueSnackbar("Successfully leave applied", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });

          dispatch(getLeaveDetailsAction(formData.employee_id));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };
export const getAllLeaveApprovalListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Pending/Approve Leave List. Please Wait...",
        type: 2,
      })
    );
    return getAllLeaveApprovalListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getAllLeaveApprovalListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const getAllLeaveApprovalListSuccessAction = (data: any) => {
  return {
    type: ELeaveActionTypes.Get_All_Leave_Approval_List_Action,
    payload: data,
  };
};

export const UpdateApproveLeaveStatusAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Leave Update Status. Please Wait...",
        type: 2,
      })
    );
    return UpdateLeaveApprovalStatusService(payload.data1)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(getAllLeaveApprovalListAction(payload.data2));
          dispatch(UpdateApproveLeaveStatusSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };
};

export const UpdateApproveLeaveStatusSuccessAction = () => {
  return {
    type: ELeaveActionTypes.Update_Approve_Leave_Status_Action,
  };
};

export const LeaveCalendarDateAction =
  (employeeId: string) => (dispatch: any, getState: any) => {
    return LeaveCalendarDetailsService(employeeId)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Leave_Calendar_Details_Action,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

// reset leave days calculating state
export const resetLeaveDaysCalculationState =
  () => (dispatch: any, getState: any) => {
    console.log("hit calxc");
    dispatch({ type: ELeaveActionTypes.Leave_calulation_state_reset_Action });
  };
export const GetAllLeaveListAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Get All Leave List. Please Wait...",
        type: 2,
      })
    );
    return getAllLeaveListService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          console.log("asdsas", response.data);

          dispatch(
            GetAllLeaveListSuccessAction({
              all_leave_list: response.data.data,
              total_count: response.data.total_count,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllLeaveListSuccessAction = (data: any) => {
  return {
    type: ELeaveActionTypes.Get_All_Leave_List_Action,
    payload: data,
  };
};

export const editLeaveAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Get All Leave List. Please Wait...",
        type: 2,
      })
    );
    const formData: IEditLeavePayload = payload.formData;
    return editAllLeaveService(formData)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(
            getAllLeaveApprovalListAction({
              approverDesigId: formData.designation_id,
              eventType: 9,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

export const getLeaveDetailsForDashboardAction =
  (empId: string, year: number) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Get All Leave List. Please Wait...",
        type: 2,
      })
    );
    return getLeaveDetailsForDashboardService(empId, year)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Leave_Details_For_Dashboard_Action,
            payload: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

// cancel leave
export const cancelLeaveAction =
  (payload: any) => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Get All Leave List. Please Wait...",
        type: 2,
      })
    );
    const formData: ICancelLeavePayload = payload.formData;
    return cancelLeaveService(formData)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.enqueueSnackbar(
          //   "Leave Application Cancelled successfully! ",
          //   {
          //     variant: "success",
          //     anchorOrigin: {
          //       vertical: "top",
          //       horizontal: "right",
          //     },
          //   }
          // );
          console.log("employee id : ", formData.employee_id);
          dispatch(getLeaveDetailsAction(formData.employee_id));
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response?.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          console.log("hit error", error);
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

// get state by address id
export const getStateByAddressIdAction =
  (addressId: number) => (dispatch: any, getState: any) => {
    return getStateByAddressIdService(addressId)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.enqueueSnackbar(
          //   "Leave Application Cancelled successfully! ",
          //   {
          //     variant: "success",
          //     anchorOrigin: {
          //       vertical: "top",
          //       horizontal: "right",
          //     },
          //   }
          // );
          dispatch({
            type: ELeaveActionTypes.Get_State_By_Address_Id_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response?.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          console.log("hit error", error);
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

// get holiday by employee
export const getHolidayByEmployeeAction =
  ({ employee_id, org_id }: { employee_id: string; org_id: string }) =>
  (dispatch: any, getState: any) => {
    getHolidayByEmployeeService({ employee_id, org_id })
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Holiday_By_Employee_Id_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response?.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          console.log("hit error", error);
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

export const naturalLeaveDaysActionByEmployee =
  (employeeId: string) => (dispatch: any, getState: any) => {
    return naturalHolidayListService(employeeId)
      .then(async (response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: ELeaveActionTypes.Get_Natural_Holiday_By_Employee_Action,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response?.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          console.log("hit error", error);
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };