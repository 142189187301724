import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProjectList } from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
  SalaryStuctureComponent,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  IPayrollReportDetails,
  IPayrollReportPayload,
  ITotalPayrollSal,
  PFESICReportLists,
} from "../../../models/reportsModel";
import { GetAllProjectAction } from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import {
  GetPayrollReportAction,
  PFESICReportListsAction,
} from "../../../stores/actions/reportsAction";
import PTaxReportsView from "./PTaxReportsView";
import { useForm } from "react-hook-form";
import { IEmployeeList } from "../../../models/timingSheetModel";
import { searchByNameEmpAction } from "../../../stores/actions/timingSheetAction";

const PTaxReports = ({
  PFESICReportListsAction,
  report_lists,
  GetAllProjectAction,
  project_list,
  total_size,
  employee_type_list,
  GetAllEmployeeTypeAction,
  organization_list,
  GetAllOrganizationAction,
  totalSalCountDataForPDF,
  totalPayrollListForPDF,
  totalPayrollData,
  payroll_list,
  GetPayrollReportAction,
  searchByNameEmpAction,
  employeeList,
  component_list,
}: PTaxReportsProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [pagesize, SetpageSize] = useState<number>(10);
  const [pageindex, Setpageindex] = useState<number>(0);
  const [rowperpage, Setrowperpage] = useState<number[]>([10, 20, 50, 100]);
  const [openReport, setopenReport] = useState<boolean>(false);
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  React.useEffect(() => {
    if (!!getValues("organization_id")) {
      const months = !!getValues("months") ? getValues("months") : [];
      const payload: IPayrollReportPayload = {
        organization_id: +getValues("organization_id"),
        months: months.includes(13) ? [] : months,
        year: getValues("year") ? getValues("year") : new Date().getFullYear(),
        employee_type_id: +getValues("employee_type_id"),
        project_id: +getValues("project_id"),
        employeeId: getValues("employeId"),
        isPDF: false,
        page_index: pageindex,
        page_size: pagesize,
        type: "ptax",
      };
      GetPayrollReportAction(payload);
    }
  }, [pageindex, pagesize]);
  useEffect(() => {
    GetAllProjectAction({ organization_id: null, customer_id: null });
    GetAllEmployeeTypeAction({ organization_id: null });
    GetAllOrganizationAction();
  }, []);
  // console.log(report_lists);
  const Clear = () => {
    setopenReport(false);
  };
  const filterData = (props: any) => {
    // console.log("props", props);
    console.log("secnd time hit");
    if (getValues("organization_id")) {
      SetpageSize(10);
      Setpageindex(0);
      console.log("third hit");

      const payload: IPayrollReportPayload = {
        organization_id: +props.organization_id,
        months: props.months.includes(13) ? [] : props.months,
        year: props?.year ? props.year : new Date().getFullYear(),
        employee_type_id: props.employee_type_id,
        project_id: props?.project_id ? props.project_id : null,
        employeeId: props?.employeeId ? props.employeeId : null,
        isPDF: false,
        page_index: 0,
        page_size: 10,
        type: "ptax",
      };
      GetPayrollReportAction(payload);
      GetPayrollReportAction({ ...payload, isPDF: true });
      setopenReport(true);
      setExpanded(false);
    }
  };
  // console.log(report_lists);

  return (
    <PTaxReportsView
      report_lists={report_lists}
      Clear={Clear}
      filterData={filterData}
      project_list={project_list}
      pagesize={pagesize}
      pageindex={pageindex}
      rowperpage={rowperpage}
      total_size={total_size}
      SetpageSize={SetpageSize}
      Setpageindex={Setpageindex}
      employee_type_list={employee_type_list}
      organization_list={organization_list}
      openReport={openReport}
      control={control}
      handleSubmit={handleSubmit}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      totalSalCountDataForPDF={totalSalCountDataForPDF}
      totalPayrollListForPDF={totalPayrollListForPDF}
      totalPayrollData={totalPayrollData}
      payroll_list={payroll_list}
      errors={errors}
      expanded={expanded}
      setExpanded={setExpanded}
      searchByNameEmpAction={searchByNameEmpAction}
      employeeList={employeeList}
      component_list={component_list}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    report_lists: state.reports.list,
    project_list: state.customer.project_list,
    total_size: state.reports.count,
    employee_type_list: state.organization.employee_type_list,
    organization_list: state.organization.organization_filter_list,
    payroll_list: state.reports.payrollReport,
    totalPayrollData: state.reports.totalSalCount,
    totalPayrollListForPDF: state.reports.reportForPDF,
    totalSalCountDataForPDF: state.reports.totalSalCountForPDF,
    employeeList: state.timingSheet.employeeList,
    component_list: state.organization.component_list,
  };
};

const mapDispatchToProps = {
  PFESICReportListsAction,
  GetAllProjectAction,
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
  GetPayrollReportAction,
  searchByNameEmpAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PTaxReports);

interface PTaxReportsProps {
  PFESICReportListsAction?: any;
  report_lists: PFESICReportLists[];
  GetAllProjectAction?: any;
  project_list: ProjectList[];
  total_size?: number;
  GetAllEmployeeTypeAction?: any;
  employee_type_list: EmployeeTypeList[];
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  GetPayrollReportAction?: any;

  payroll_list: IPayrollReportDetails[];
  totalPayrollData?: ITotalPayrollSal;
  totalPayrollListForPDF: IPayrollReportDetails[];
  totalSalCountDataForPDF?: ITotalPayrollSal;
  searchByNameEmpAction?: any;
  employeeList: IEmployeeList[];
  component_list: SalaryStuctureComponent[];
}
