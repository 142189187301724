import React, { FC, useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import ProjectDashboardView from "./ProjectDashboardView";
import { StoreState } from "../../../models/reduxModels";
import {
  GetProjectDashboardAction,
  GetProjectAttendanceStatusMonthWise,
  GetBatchDetailsByProjectIdAction,
  getEmployeePayrollByProjectId,
  editAssociationStatus,
  GetAvailableEmpAction,
  AllocateEmpAction,
} from "../../../stores/actions/customerAction";
import { IProjectDashboardDetails } from "../../../interfaces/projectDashboard.interface";
import { attendenceData } from "./../../../assets/tempData";
import {
  AttendenceStatus,
  AvailableEmpList,
  IBatchDetails,
  IEmployeePayroll,
} from "../../../models/customerModel";
import dayjs, { Dayjs } from "dayjs";
import { baseUrl, environment } from "../../../environment";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { userDetails } from "../../../models/userModels";
import { SidenavAutoCloseOpen } from "../../../stores/actions/userAction";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { AnyAaaaRecord } from "dns";
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfMake.vfs;
const FileDownload = require("js-file-download");

const ProjectDashboard: FC<IProjectDashboard> = ({
  GetProjectDashboardAction,
  project_dashbaord_data,
  GetProjectAttendanceStatusMonthWise,
  attendenceData,
  GetBatchDetailsByProjectIdAction,
  getEmployeePayrollByProjectId,
  dashboard_batch_details,
  employee_payroll_details,
  editAssociationStatus,
  user,
  accessToken,
  SidenavAutoCloseOpen,
  GetAvailableEmpAction,
  available_emp_list,
  AllocateEmpAction,
}) => {
  const Month: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const divElem = useRef<HTMLDivElement | null>(null);
  const [currentYear, setCurrentYear] = useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [dateForAttendence, setDateForAttendence] =
    React.useState<Dayjs | null>(dayjs(new Date()));
  const [currentMonthForBatch, setCurrentMonthForBatch] =
    React.useState<Dayjs | null>(dayjs(new Date()));
  const [currentMonthForEmpPayroll, setCurrentMonthForEmpPayroll] =
    React.useState<Dayjs | null>(dayjs(new Date()));
  const [batchCount, setBatchCount] = useState<number>(0);
  console.log("currentMonth : ", dateForAttendence);
  const { id } = useParams();
  const [document, Setdocument] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [searchedEmp, setSearchedEmp] = useState<string>("");
  const [employeeListForPDFActive, setEmployeeListForPDFActive] = useState<
    any[]
  >([]);
  const [employeeListForPDFInActive, setEmployeeListForPDFInActive] = useState<
    any[]
  >([]);
  const [levelHirerachyForPDF, setLevelHirerachyForPDF] = useState<any[]>([]);
  const [managerListForPDF, setManagerListForPDF] = useState<any[]>([]);
  const [batchDetailsForPdf, setBatchDetailsForPdf] = useState<any[]>([]);
  useEffect(() => {
    console.log("hit dashboard");
    if (!!id && Number(id) >= 1) {
      const year = Number(
        dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]
      );
      console.log(
        "hit date",
        dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]
      );
      GetProjectDashboardAction(Number(id), year);
    }
  }, [id, currentYear]);

  useEffect(() => {
    if (!!id && Number(id) >= 1 && !!dateForAttendence) {
      const month = Number(
        dayjs(dateForAttendence).format("DD-MM-YYYY").split("-")[1]
      );
      const year = Number(
        dayjs(dateForAttendence).format("DD-MM-YYYY").split("-")[2]
      );
      GetProjectAttendanceStatusMonthWise(id, year, month);
    }
  }, [id, dateForAttendence, currentYear]);
  useEffect(() => {
    if (!!id && Number(id) >= 1 && !!currentMonthForBatch) {
      const year = Number(
        dayjs(currentMonthForBatch).format("DD-MM-YYYY").split("-")[2]
      );
      const month = Number(
        dayjs(currentMonthForBatch).format("DD-MM-YYYY").split("-")[1]
      );
      if (batchCount === 1) GetBatchDetailsByProjectIdAction(id, year, month);
      else GetBatchDetailsByProjectIdAction(id, year, 0);
    }
  }, [id, currentMonthForBatch]);
  console.log("batchCount : ", batchCount);

  useEffect(() => {
    if (!!id && Number(id) >= 1 && !!currentMonthForBatch) {
      const year = Number(
        dayjs(currentMonthForEmpPayroll).format("DD-MM-YYYY").split("-")[2]
      );
      const month = Number(
        dayjs(currentMonthForEmpPayroll).format("DD-MM-YYYY").split("-")[1]
      );
      console.log("emp payroll hit ");
      getEmployeePayrollByProjectId(+id, year, month);
    }
  }, [id, currentMonthForEmpPayroll]);

  React.useEffect(() => {
    SidenavAutoCloseOpen(true);
    return function () {
      SidenavAutoCloseOpen(false);
    };
  }, []);

  const downloadImage = (image: string) => {
    if (!!image) {
      Axios({
        url: baseUrl + environment.urls.downloadFileUrl,
        method: "POST",
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + user?.accessToken,
        },
        data: {
          employee_id: `WORKORDER`,
          fileName: image,
        },
      })
        .then(async (response) => {
          await FileDownload(response.data, image);
        })
        .catch(() => {
          enqueueSnackbar("Something went wrong!", {
            variant: "error",
          });
        });
    }
  };
  const Submit = (data: any) => {
    console.log("Submit", data);
    AllocateEmpAction(data);
    setTimeout(() => {
      if (!!id && Number(id) >= 1) {
        const year = Number(
          dayjs(currentYear).format("DD-MM-YYYY").split("-")[2]
        );
        GetProjectDashboardAction(Number(id), year);
      }
    }, 1000);
  };
  React.useEffect(() => {
    if (
      !!searchedEmp &&
      searchedEmp.length > 2 &&
      searchedEmp.length % 2 === 1
    ) {
      GetAvailableEmpAction({
        project_id: project_dashbaord_data?.projectDetails.project_id,
        search_string: searchedEmp,
      });
    }
  }, [searchedEmp]);
  console.log("aaaa", available_emp_list);
  useEffect(() => {
    const AEempList: any[] = [];
    const IEempList: any[] = [];
    !!project_dashbaord_data?.projectDetails &&
      project_dashbaord_data?.projectDetails.employeeList.forEach((self) => {
        if (self.association_status.trim().toLowerCase() === "active") {
          AEempList.push([
            { text: self.employee_id, style: "defaultStyle" },
            { text: self.employee_name, style: "defaultStyle" },
            { text: self.employee_mob_no, style: "defaultStyle" },
            {
              text:
                self.level1_name !== null
                  ? self.level2_name !== null
                    ? self.level3_name !== null
                      ? self.level4_name !== null
                        ? self.level5_name !== null
                          ? self.level1_name +
                            ">" +
                            self.level2_name +
                            ">" +
                            self.level3_name +
                            ">" +
                            self.level4_name +
                            ">" +
                            self.level5_name
                          : self.level1_name +
                            ">" +
                            self.level2_name +
                            ">" +
                            self.level3_name +
                            ">" +
                            self.level4_name
                        : self.level1_name +
                          ">" +
                          self.level2_name +
                          ">" +
                          self.level3_name
                      : self.level1_name + ">" + self.level2_name
                    : self.level1_name
                  : "",
            },
            {
              text: self.start_date
                ? moment(self.start_date).format("DD-MMM-YYYY")
                : "",
              style: "defaultStyle",
            },
            {
              text: self.end_date
                ? moment(self.end_date).format("DD-MMM-YYYY")
                : "",
              style: "defaultStyle",
            },
            {
              text:
                !!self.post_id &&
                project_dashbaord_data.projectDetails.applicable_post_details?.find(
                  (_self) => _self.post_id === self.post_id
                )?.post_name
                  ? !!self.post_id &&
                    project_dashbaord_data.projectDetails.applicable_post_details?.find(
                      (_self) => _self.post_id === self.post_id
                    )?.post_name
                  : "N/A",
              style: "defaultStyle",
            },
          ]);
        } else {
          IEempList.push([
            { text: self.employee_id, style: "defaultStyle" },
            { text: self.employee_name, style: "defaultStyle" },
            { text: self.employee_mob_no, style: "defaultStyle" },
            {
              text:
                self.level1_name !== null
                  ? self.level2_name !== null
                    ? self.level3_name !== null
                      ? self.level4_name !== null
                        ? self.level5_name !== null
                          ? self.level1_name +
                            ">" +
                            self.level2_name +
                            ">" +
                            self.level3_name +
                            ">" +
                            self.level4_name +
                            ">" +
                            self.level5_name
                          : self.level1_name +
                            ">" +
                            self.level2_name +
                            ">" +
                            self.level3_name +
                            ">" +
                            self.level4_name
                        : self.level1_name +
                          ">" +
                          self.level2_name +
                          ">" +
                          self.level3_name
                      : self.level1_name + ">" + self.level2_name
                    : self.level1_name
                  : "",
              style: "defaultStyle",
            },
            {
              text: self.start_date
                ? moment(self.start_date).format("DD-MMM-YYYY")
                : "",
              style: "defaultStyle",
            },
            {
              text: self.end_date
                ? moment(self.end_date).format("DD-MMM-YYYY")
                : "",
              style: "defaultStyle",
            },
            {
              text:
                !!self.post_id &&
                project_dashbaord_data.projectDetails.applicable_post_details?.find(
                  (_self) => _self.post_id === self.post_id
                )?.post_name
                  ? !!self.post_id &&
                    project_dashbaord_data.projectDetails.applicable_post_details?.find(
                      (_self) => _self.post_id === self.post_id
                    )?.post_name
                  : "N/A",
              style: "defaultStyle",
            },
          ]);
        }
      });
    // setEmployeeListForPDF(pEempList);
    setEmployeeListForPDFActive(AEempList);
    setEmployeeListForPDFInActive(IEempList);
  }, [project_dashbaord_data]);

  useEffect(() => {
    const levelsHierarchy: any[] = [];
    if (project_dashbaord_data?.lastKey) {
      switch (project_dashbaord_data?.lastKey) {
        case 1:
          project_dashbaord_data.levels.forEach((self) => {
            levelsHierarchy.push({
              name: self.level1_name,
              empCount: self.total_employees,
            });
          });
          break;
        case 2:
          for (const lev1 of project_dashbaord_data.levels) {
            if (lev1.level_two)
              for (const lev2 of lev1.level_two) {
                levelsHierarchy.push({
                  name: `${lev1.level1_name} >> ${lev2.level2_name}`,
                  empCount: lev2.total_employees,
                });
              }
          }
          break;
        case 3:
          for (const lev1 of project_dashbaord_data.levels) {
            if (lev1.level_two)
              for (const lev2 of lev1.level_two) {
                if (lev2.level_three)
                  for (const lev3 of lev2.level_three) {
                    levelsHierarchy.push({
                      name: `${lev1.level1_name} >> ${lev2.level2_name} >> ${lev3.level3_name}`,
                      empCount: lev3.total_employees,
                    });
                  }
              }
          }
          break;
        case 4:
          for (const lev1 of project_dashbaord_data.levels) {
            if (lev1.level_two)
              for (const lev2 of lev1.level_two) {
                if (lev2.level_three)
                  for (const lev3 of lev2.level_three) {
                    if (lev3.level_four) {
                      for (const lev4 of lev3.level_four) {
                        // levelsHierarchy.push(
                        //   `${lev1.level1_name} >> ${lev2.level2_name} >> ${lev3.level3_name} >> ${lev4.level4_name}   :  ${lev4.total_employees}`
                        // );
                        levelsHierarchy.push({
                          name: `${lev1.level1_name} >> ${lev2.level2_name} >> ${lev3.level3_name} >> ${lev4.level4_name}`,
                          empCount: lev4.total_employees,
                        });
                      }
                    }
                  }
              }
          }
          break;
        case 5:
          for (const lev1 of project_dashbaord_data.levels) {
            if (lev1.level_two)
              for (const lev2 of lev1.level_two) {
                if (lev2.level_three)
                  for (const lev3 of lev2.level_three) {
                    if (lev3.level_four) {
                      for (const lev4 of lev3.level_four) {
                        if (lev4.level_five)
                          for (const lev5 of lev4.level_five) {
                            levelsHierarchy.push({
                              name: `${lev1.level1_name} >> ${lev2.level2_name} >> ${lev3.level3_name} >> ${lev4.level4_name} >> ${lev5.level5_name}`,
                              empCount: lev5.total_employees,
                            });
                          }
                      }
                    }
                  }
              }
          }
      }
    }
    setLevelHirerachyForPDF(levelsHierarchy);
  }, [project_dashbaord_data]);
  useEffect(() => {
    const dashboardDetails: any[] = [];
    if (dashboard_batch_details && Array.isArray(dashboard_batch_details)) {
      dashboard_batch_details.forEach((self) => {
        dashboardDetails.push([
          {
            text: self.batch_id,
          },
          { text: Month[Number(self.month)] },
          {
            text: Number(
              Number(self.total_income) + Number(self.total_contribution)
            ).toFixed(2),
          },
          { text: Number(self.batch_status) === 5 ? "Paid" : "N/A" },
          {
            text: self.expected_disburesment_date
              ? moment(self.expected_disburesment_date).format("DD-MMM-YYYY")
              : "",
          },
        ]);
      });
    }
    setBatchDetailsForPdf(dashboardDetails);
  }, [dashboard_batch_details]);
  useEffect(() => {
    const tManagerList: any[] = [];
    project_dashbaord_data?.managerList.forEach((self) => {
      tManagerList.push([
        { text: self.employee_id },
        { text: self.employee_name },
        { text: self.employee_email },
        { text: self.employee_mob_no },
        {
          text: self.start_date
            ? moment(self.start_date).format("DD-MMM-YYYY")
            : "",
        },
      ]);
    });
    setManagerListForPDF(tManagerList);
  }, [project_dashbaord_data]);
  console.log("levelHirerachyForPDF : ", levelHirerachyForPDF);
  console.log("dashboard_batch_details : ", dashboard_batch_details);
  console.log(
    " project_dashbaord_data?.projectDetails.employeeList : ",
    project_dashbaord_data?.projectDetails.employeeList
  );
  console.log("employeeListForPDFActive : ", employeeListForPDFActive);
  console.log("batchDetailsForPdf  : ", batchDetailsForPdf);

  const onDownloadPDF = () => {
    // html2canvas(document.getElementById("target")!).then((canvas) => {
    console.log("pdf hit");
    html2canvas(divElem.current!)
      .then((canvas) => {
        const dataURI = canvas.toDataURL();
        const docDefinition = {
          pageSize: "A4",
          pageOrientation: "landscape",
          pageMargins: [2, 2, 2, 2],
          background: {
            image: dataURI,
            width: 845,
          },
          // content: [
          //   {
          //     text: "title",
          //     fontSize: 32,
          //     bold: true,
          //     margin: [50, 50, 60, 60]
          //   }
          // ]
        };
        var dd = {
          content: [
            {
              text: `Project Details For Sport Bill For ${dayjs(
                currentYear
              ).format("YYYY")} `,
              style: "header",
            },
            {
              style: "tableExample",
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    {
                      text: "Project Details",
                      style: "tableHeader",
                      colSpan: 2,
                      alignment: "center",
                    },
                    {},
                  ],
                  [
                    "Organization Name",
                    project_dashbaord_data?.projectDetails.organization_name,
                  ],
                  [
                    "Organization Email",
                    project_dashbaord_data?.projectDetails.organization_email,
                  ],
                  [
                    "Official Contact No.",
                    project_dashbaord_data?.projectDetails.official_contact_no,
                  ],
                  [
                    "Customer Name",
                    project_dashbaord_data?.projectDetails.customer_name,
                  ],
                  [
                    "Project Name",
                    project_dashbaord_data?.projectDetails.project_name,
                  ],
                  [
                    "Project Status",
                    project_dashbaord_data?.projectDetails.project_status
                      ? "Active"
                      : "In-Active",
                  ],
                  [
                    "Project Code",
                    project_dashbaord_data?.projectDetails.project_code,
                  ],
                  [
                    "Start Date",
                    project_dashbaord_data?.projectDetails.start_date
                      ? moment(
                          project_dashbaord_data?.projectDetails.start_date
                        ).format("DD-MMM-YYYY")
                      : "",
                  ],
                  [
                    "End Date",
                    project_dashbaord_data?.projectDetails.end_date
                      ? moment(
                          project_dashbaord_data?.projectDetails.end_date
                        ).format("DD-MMM-YYYY")
                      : "",
                  ],
                  [
                    "Total Active Employee",
                    project_dashbaord_data?.projectDetails
                      .no_of_active_employees,
                  ],
                  [
                    "Total In-Active Employee",
                    project_dashbaord_data?.projectDetails
                      .no_of_in_active_employees,
                  ],
                  [
                    "Work Order No.",
                    project_dashbaord_data?.projectDetails.work_order_no,
                  ],
                  [
                    "Total Cost (for current year)",
                    Number(
                      project_dashbaord_data?.payrollData.totalCost
                    ).toFixed(2),
                  ],
                  [
                    "No of Manager",
                    project_dashbaord_data?.managerList?.length,
                  ],
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // paddingLeft: function(i, node) { return 4; },
                // paddingRight: function(i, node) { return 4; },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            //////// ////////////////////
            {
              text: `No. Of Employee (Level wise)`,
              style: "subheader",
              align: "center",
            },
            {
              style: "tableExample",
              widths: ["*", "*"],
              heights: function (row: any) {
                return 25;
              },
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    { text: "Location", style: "tableHeader" },
                    {
                      text: "No. Of Employee",
                      style: "tableHeader",
                    },
                  ],
                  ...levelHirerachyForPDF.map((__self: any) => [
                    __self.name,
                    __self.empCount,
                  ]),
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex % 2 === 0 ? "#CFD4D6" : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                paddingLeft: function (i: any, node: any) {
                  return 4;
                },
                paddingRight: function (i: any, node: any) {
                  return 4;
                },
                paddingTop: function (i: any, node: any) {
                  return 2;
                },
                paddingBottom: function (i: any, node: any) {
                  return 2;
                },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            {
              text: `Active Employee List For  ${dayjs(currentYear).format(
                "YYYY"
              )}`,
              style: "subheader",
              align: "center",
            },
            {
              style: "tableExample",
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
              table: {
                body: [
                  [
                    { text: "Emp Id", style: "tableHeader" },
                    {
                      text: "Emp Name ",
                      style: "tableHeader",
                    },
                    {
                      text: "Mobile ",
                      style: "tableHeader",
                    },
                    {
                      text: "Location",
                      style: "tableHeader",
                    },
                    {
                      text: "Start Date",
                      style: "tableHeader",
                    },
                    {
                      text: "End Date ",
                      style: "tableHeader",
                    },
                    {
                      text: "Post ",
                      style: "tableHeader",
                    },
                  ],
                  ...employeeListForPDFActive
                    .map((self) => self)
                    .map((_self) => _self)
                    .map((__self) => __self),

                  // project_dashbaord_data?.projectDetails.employeeList?.map(
                  //   (self) => ({
                  //     text: self,
                  //     style: "tableRow",
                  //   })
                  // ),
                  // [
                  //   { text: "Emp Id", style: "tableHeader" },
                  //   {
                  //     text: `${}`,

                  //   },
                  //   {
                  //     text: "Mobile ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Working Status ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Location ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Start Date",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "End Date ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Post ",
                  //     style: "tableHeader",
                  //   },
                  // ],
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // paddingLeft: function(i, node) { return 4; },
                // paddingRight: function(i, node) { return 4; },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            {
              text: `In-Active Employee List For  ${dayjs(currentYear).format(
                "YYYY"
              )}`,
              style: "subheader",
              align: "center",
            },
            {
              style: "tableExample",
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
              table: {
                body: [
                  [
                    { text: "Emp Id", style: "tableHeader" },
                    {
                      text: "Emp Name ",
                      style: "tableHeader",
                    },
                    {
                      text: "Mobile ",
                      style: "tableHeader",
                    },
                    {
                      text: "Location",
                      style: "tableHeader",
                    },
                    {
                      text: "Start Date",
                      style: "tableHeader",
                    },
                    {
                      text: "End Date ",
                      style: "tableHeader",
                    },
                    {
                      text: "Post ",
                      style: "tableHeader",
                    },
                  ],
                  ...employeeListForPDFInActive
                    .map((self) => self)
                    .map((_self) => _self)
                    .map((__self) => __self),

                  // project_dashbaord_data?.projectDetails.employeeList?.map(
                  //   (self) => ({
                  //     text: self,
                  //     style: "tableRow",
                  //   })
                  // ),
                  // [
                  //   { text: "Emp Id", style: "tableHeader" },
                  //   {
                  //     text: `${}`,

                  //   },
                  //   {
                  //     text: "Mobile ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Working Status ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Location ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Start Date",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "End Date ",
                  //     style: "tableHeader",
                  //   },
                  //   {
                  //     text: "Post ",
                  //     style: "tableHeader",
                  //   },
                  // ],
                ],
              },
              layout: {
                fillColor: function (
                  rowIndex: number,
                  node: any,
                  columnIndex: any
                ) {
                  return rowIndex === 0
                    ? "#71BFE8"
                    : rowIndex % 2 === 0
                    ? "#CFD4D6"
                    : null;
                },
                hLineWidth: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length ? 0.5 : 1;
                },
                vLineWidth: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length ? 0.5 : 1;
                },
                hLineColor: function (
                  i: number,
                  node: { table: { body: string | any[] } }
                ) {
                  return i === 0 || i === node.table.body.length
                    ? "black"
                    : "gray";
                },
                vLineColor: function (
                  i: number,
                  node: { table: { widths: string | any[] } }
                ) {
                  return i === 0 || i === node.table.widths.length
                    ? "black"
                    : "gray";
                },
                // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                // paddingLeft: function(i, node) { return 4; },
                // paddingRight: function(i, node) { return 4; },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (rowIndex, node, columnIndex) { return null; }
              },
            },
            managerListForPDF.length > 0
              ? {
                  text: `Manager Details for ${dayjs(currentYear).format(
                    "YYYY"
                  )}`,
                  style: "subheader",
                }
              : "",
            managerListForPDF.length > 0
              ? {
                  style: "tableExample",
                  widths: ["*", "*", "*", "*", "*"],
                  table: {
                    widths: ["*", "*", "*", "*", "auto"],
                    body: [
                      [
                        { text: "Emp Id", style: "tableHeader" },
                        {
                          text: "Name",
                          style: "tableHeader",
                        },
                        {
                          text: "Email ",
                          style: "tableHeader",
                        },
                        {
                          text: "Mobile No.",
                          style: "tableHeader",
                        },
                        {
                          text: "Starts At",
                          style: "tableHeader",
                        },
                      ],
                      ...managerListForPDF,
                    ],
                  },
                  layout: {
                    fillColor: function (
                      rowIndex: number,
                      node: any,
                      columnIndex: any
                    ) {
                      return rowIndex === 0
                        ? "#71BFE8"
                        : rowIndex % 2 === 0
                        ? "#CFD4D6"
                        : null;
                    },
                    hLineWidth: function (
                      i: number,
                      node: { table: { body: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.body.length ? 0.5 : 1;
                    },
                    vLineWidth: function (
                      i: number,
                      node: { table: { widths: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.widths.length
                        ? 0.5
                        : 1;
                    },
                    hLineColor: function (
                      i: number,
                      node: { table: { body: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.body.length
                        ? "black"
                        : "gray";
                    },
                    vLineColor: function (
                      i: number,
                      node: { table: { widths: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.widths.length
                        ? "black"
                        : "gray";
                    },
                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                  },
                }
              : "",

            batchDetailsForPdf.length > 0
              ? {
                  text: `Batch Details for ${dayjs(currentYear).format(
                    "YYYY"
                  )}`,
                  style: "subheader",
                }
              : "",
            batchDetailsForPdf.length > 0
              ? {
                  style: "tableExample",
                  widths: function (row: number) {
                    return row * 1;
                  },
                  table: {
                    body: [
                      [
                        { text: "Batch Id", style: "tableHeader" },
                        {
                          text: "Month",
                          style: "tableHeader",
                        },
                        {
                          text: "Total Cost ",
                          style: "tableHeader",
                        },
                        {
                          text: "Batch Status ",
                          style: "tableHeader",
                        },
                        {
                          text: "Expected Disbursment ",
                          style: "tableHeader",
                        },
                      ],
                      ...batchDetailsForPdf,
                    ],
                  },
                  layout: {
                    fillColor: function (
                      rowIndex: number,
                      node: any,
                      columnIndex: any
                    ) {
                      return rowIndex === 0
                        ? "#71BFE8"
                        : rowIndex % 2 === 0
                        ? "#CFD4D6"
                        : null;
                    },
                    hLineWidth: function (
                      i: number,
                      node: { table: { body: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.body.length ? 0.5 : 1;
                    },
                    vLineWidth: function (
                      i: number,
                      node: { table: { widths: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.widths.length
                        ? 0.5
                        : 1;
                    },
                    hLineColor: function (
                      i: number,
                      node: { table: { body: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.body.length
                        ? "black"
                        : "gray";
                    },
                    vLineColor: function (
                      i: number,
                      node: { table: { widths: string | any[] } }
                    ) {
                      return i === 0 || i === node.table.widths.length
                        ? "black"
                        : "gray";
                    },
                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                  },
                }
              : "",
          ],
          styles: {
            header: {
              fontSize: 15,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 10,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
              width: 500,
            },
            tableHeader: {
              bold: true,
              fontSize: 9,
              color: "black",
            },
          },
          defaultStyle: {
            fontSize: 9,
            color: "black",
            // alignment: 'justify'
          },
        };
        console.log("dd  : ", dd);
        pdfMake.createPdf(dd as any).download("project-dashboard-report.pdf");
      })
      .catch((err) => console.log("error : ", err));
  };

  return (
    <ProjectDashboardView
      ref={divElem}
      setCurrentYear={setCurrentYear}
      projectDashbaordData={project_dashbaord_data}
      year={currentYear}
      setDateForAttendence={setDateForAttendence}
      dateForAttendence={dateForAttendence}
      attendenceData={attendenceData}
      setCurrentMonthForBatch={setCurrentMonthForBatch}
      setCurrentMonthForEmpPayroll={setCurrentMonthForEmpPayroll}
      currentMonthForBatch={currentMonthForBatch}
      currentMonthForEmpPayroll={currentMonthForEmpPayroll}
      dashboardBatchDetails={dashboard_batch_details}
      employeePayrollDetails={employee_payroll_details}
      editAssociationStatus={editAssociationStatus}
      projectId={Number(id)}
      setBatchCount={setBatchCount}
      batchCount={batchCount}
      Setdocument={Setdocument}
      document={document}
      downloadImage={downloadImage}
      user={user}
      accessToken={accessToken}
      setSearchedEmp={setSearchedEmp}
      searchedEmp={searchedEmp}
      available_emp_list={available_emp_list}
      Submit={Submit}
      onDownloadPDF={onDownloadPDF}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    project_dashbaord_data: state.customer.project_dashboard_details,
    attendenceData: state.customer.attendence_status,
    dashboard_batch_details: state.customer.project_dashboard_batch_details,
    employee_payroll_details: state.customer.project_employee_payroll_details,
    user: state.user.userDetails,
    accessToken: state.user.userDetails?.accessToken,
    available_emp_list: state.customer.available_emp_list,
  };
};
const mapDispatchToProps = {
  GetProjectDashboardAction,
  GetProjectAttendanceStatusMonthWise,
  GetBatchDetailsByProjectIdAction,
  getEmployeePayrollByProjectId,
  editAssociationStatus,
  SidenavAutoCloseOpen,
  GetAvailableEmpAction,
  AllocateEmpAction,
};

interface IProjectDashboard {
  GetProjectDashboardAction?: any;
  project_dashbaord_data?: IProjectDashboardDetails;
  GetProjectAttendanceStatusMonthWise?: any;
  attendenceData?: AttendenceStatus[];
  GetBatchDetailsByProjectIdAction?: any;
  getEmployeePayrollByProjectId?: any;
  dashboard_batch_details: IBatchDetails[];
  employee_payroll_details: IEmployeePayroll[];
  editAssociationStatus?: any;
  user?: userDetails;
  accessToken?: string;
  SidenavAutoCloseOpen?: any;
  GetAvailableEmpAction?: any;
  available_emp_list: AvailableEmpList[];
  AllocateEmpAction: any;
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDashboard);
