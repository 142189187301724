import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  TextareaAutosize,
  Button,
  Switch,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableCell,
  tableCellClasses,
  Tooltip,
  TablePagination,
  Box,
  Popover,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Autocompletes from "../../../library/AutoComplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import {
  CustomerList,
  ProjectList,
  ViewProject,
} from "../../../models/customerModel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BatchList } from "../../../models/payrollModel";
import { useNavigate } from "react-router-dom";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { CheckActionStatusPayload } from "../../../models/employeeModel";
import ActionStatusPopover from "../../employment/ActionStatusPopover";
import { environment } from "../../../environment";

function RejectedBatchView({
  organization_list,
  employee_type_list,
  customer_list,
  project_list,
  ProjectSelect,
  project_detail,
  batch_list,
  Sudmit,
  CancleFilter,
}: RejectedBatchViewProps) {
  let navigateTo = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const styles = {
    marginRight: "50px",
  };
  const [empType, setempType] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [rows, setRows] = useState<BatchList[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState<string>("");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const [popoverPayload, SetpopoverPayload] = useState<
    CheckActionStatusPayload | undefined
  >(undefined);
  const [openStatus, SetopenStatus] = useState<boolean>(false);
  const column1: any[] = [
    "Batch No",
    "Project",
    "Division",
    "Month",
    "Total Employee",
    "Total Income",
    "Total Deduction",
    "Total Contribution",
    "Net Payable",
    "Created By",
    "Created On",
    "Action",
  ];
  const submit = (data: any) => {
    if (!!data.month) {
      data.year = moment(data.month).format("YYYY").toString();
      data.month = +moment(data.month).format("MM");
    }
    Sudmit(data);
    setExpanded(false);
  };
  const requestSearch = (searchedVal: string) => {
    const filteredRows = !!batch_list
      ? batch_list.filter((row: BatchList) => {
          return (
            row?.batch_id?.toLowerCase().includes(searchedVal.toLowerCase()) ||
            row?.division_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.project_name
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.created_by
              ?.toLowerCase()
              .includes(searchedVal.toLowerCase()) ||
            row?.batch_status_name
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
          );
        })
      : [];
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  useEffect(() => {
    if (!!batch_list) {
      cancelSearch();
    }
  }, [batch_list]);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <>
      <Paper elevation={3} sx={{ marginTop: 3, marginInline: 5 }}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded === "panel"}
            onChange={handleChange1("panel")}
            style={{
              backgroundColor: "transparent",
              // marginTop: 15,
              // marginBottom: 15,
              width: "100%",
              borderRadius: 5,
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0, margin: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  backgroundColor: "#0060FF",
                  justifyContent: "space-between",
                  marginTop: -2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                onClick={() => handleChange1("panel1")}
              >
                <Grid
                  justifyContent={"center"}
                  alignSelf={"center"}
                  style={{ paddingLeft: "20px" }}
                >
                  <Typography
                    style={{
                      fontWeight: "600",
                      fontSize: 16,
                      fontFamily: "Poppins, sans-serif",
                      // color: "#626974",
                      color: "#fff",

                      textAlign: "center",
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  justifyContent={"flex-end"}
                  alignContent={"flex-end"}
                  style={{ marginRight: "2%" }}
                >
                  <Button
                    variant="text"
                    endIcon={<FilterListIcon />}
                    sx={{ color: "#fff" }}
                    onClick={() => handleChange1("panel1")}
                  ></Button>
                </Grid>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Payment Batch ID
                        {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        control={control}
                        name={"batchId"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <TextField
                            id="standard-required"
                            variant="outlined"
                            className={"smaller"}
                            style={{ width: "125%" }}
                            onBlur={onBlur}
                            size="small"
                            onChange={onChange}
                            value={value}
                            placeholder={"Payment Batch ID"}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Organization
                        {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                      <Controller
                        control={control}
                        name={"organizationId"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <Autocompletes
                            hasInfo={false}
                            option={organization_list}
                            //   readOnly={!!department ? edit_flag : !edit_flag}
                            keys={"organization_id"}
                            label={"organization_name"}
                            onBlur={onBlur}
                            onChange={(value: any) => onChange(value)}
                            placeholder={"Search Organization"}
                            value={!!value ? value : ""}
                            errors={!!errors["organizationId"]}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!!watch("organizationId") && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      justifyContent="start"
                      alignItems="center"
                      style={{ marginTop: 8, marginBottom: 10 }}
                      // width={"100%"}
                    >
                      <Grid
                        item
                        xs={5}
                        justifyContent="space-between"
                        alignItems="start"
                      >
                        <Typography
                          style={{
                            fontWeight: "500",
                            fontSize: 16,
                            fontFamily: "Poppins, sans-serif",
                            color: "#626974",
                            paddingLeft: "20%",
                            // textAlign: "center"
                          }}
                        >
                          Employee Type
                          {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                        </Typography>
                      </Grid>
                      <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                        <Controller
                          control={control}
                          name={"employee_type_id"}
                          defaultValue={""}
                          // rules={{ required: true }}
                          render={({ field: { onBlur, value, onChange } }) => (
                            <Autocompletes
                              hasInfo={false}
                              option={employee_type_list.filter(
                                (t) =>
                                  t.org_id == +watch("organizationId") &&
                                  (empType.length <= 0 ||
                                    !!empType.includes(t.employee_type_id))
                              )}
                              //   readOnly={!!department ? edit_flag : !edit_flag}
                              keys={"employee_type_id"}
                              label={"employee_type_name"}
                              onBlur={onBlur}
                              onChange={(value: any) => (
                                onChange(value),
                                setValue("projectId", null),
                                setValue("divisionId", null)
                              )}
                              placeholder={"Search Employee Type"}
                              value={!!value ? value : ""}
                              errors={!!errors["employee_type_id"]}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!!watch("employee_type_id") &&
                  !environment.global_veriable.ho_employees_type_id.includes(
                    +watch("employee_type_id")
                  ) && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
                        // width={"100%"}
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
                              // textAlign: "center"
                            }}
                          >
                            Project Name
                            {/* <span style={{ color: "red", fontSize: 14 }}>
                        *
                      </span> */}
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"projectId"}
                            defaultValue={""}
                            // rules={{ required: true }}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={project_list}
                                //   readOnly={!!department ? edit_flag : !edit_flag}
                                keys={"project_id"}
                                label={"project_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => (
                                  onChange(value), ProjectSelect(value)
                                )}
                                placeholder={"Search Project"}
                                value={!!value ? value : ""}
                                errors={!!errors["projectId"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {/* {!!watch("employee_type_id") &&
                  +watch("employee_type_id") != 12 &&
                  !!watch("projectId") &&
                  !!project_detail?.division &&
                  project_detail?.division.length > 0 && (
                    <Grid item xs={6}>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        style={{ marginTop: 8, marginBottom: 10 }}
  
                      >
                        <Grid
                          item
                          xs={5}
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography
                            style={{
                              fontWeight: "500",
                              fontSize: 16,
                              fontFamily: "Poppins, sans-serif",
                              color: "#626974",
                              paddingLeft: "20%",
         
                            }}
                          >
                            Division Name
               
                          </Typography>
                        </Grid>
                        <Grid item xs={5.05} style={{ marginLeft: -5 }}>
                          <Controller
                            control={control}
                            name={"divisionId"}
                            defaultValue={""}
                            render={({
                              field: { onBlur, value, onChange },
                            }) => (
                              <Autocompletes
                                hasInfo={false}
                                option={
                                  !!project_detail?.division
                                    ? project_detail?.division
                                    : []
                                }
                                keys={"division_id"}
                                label={"division_name"}
                                onBlur={onBlur}
                                onChange={(value: any) => onChange(value)}
                                placeholder={"Search Division"}
                                value={!!value ? value : ""}
                                errors={!!errors["divisionId"]}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                    style={{ marginTop: 8, marginBottom: 10 }}
                    // width={"100%"}
                  >
                    <Grid
                      item
                      xs={5}
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <Typography
                        style={{
                          fontWeight: "500",
                          fontSize: 16,
                          fontFamily: "Poppins, sans-serif",
                          color: "#626974",
                          paddingLeft: "20%",
                          // textAlign: "center"
                        }}
                      >
                        Select Month
                        {/* <span style={{ color: "red", fontSize: 14 }}>*</span> */}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Controller
                        control={control}
                        name={"month"}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field: { onBlur, value, onChange } }) => (
                          <DatePicker
                            views={["year", "month"]}
                            value={value}
                            InputProps={{
                              size: "small",
                              style: {
                                width: "100%",
                              },
                            }}
                            onChange={(newValue) => onChange(newValue)}
                            renderInput={(params) => (
                              <TextField
                                sx={{ width: "100%" }}
                                {...params}
                                helperText={null}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
                padding={3}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    marginRight: 10,
                  }}
                  onClick={() => (reset(), CancleFilter())}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Clear FIlter
                </Button>
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1565C0",
                    // marginRight: 10,
                  }}
                  onClick={handleSubmit(submit)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Filter
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Paper>
      <ActionStatusPopover
        open={openStatus}
        setOpen={SetopenStatus}
        payload={popoverPayload}
      />
      <Paper
        elevation={3}
        sx={{ marginTop: 2, marginInline: 5, paddingBottom: 10 }}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          <Grid item xs={10} sx={{ marginTop: 2, marginBottom: 3 }}>
            <TextField
              size="small"
              style={{ width: "80%" }}
              placeholder="Search..."
              id="fullWidth"
              value={searched}
              onChange={(e: any) => (
                requestSearch(e.target.value), setSearched(e.target.value)
              )}
              InputProps={{
                endAdornment:
                  !!searched && searched.length > 0 ? (
                    <IconButton color="primary" onClick={() => cancelSearch()}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton color="primary">
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction={"row"}>
          <Grid item xs={12} padding={2}>
            <Grid
              container
              direction={"row"}
              width={"100%"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <TableContainer sx={{ width: "100%", borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-root:hover": {
                      // backgroundColor: "#73c6ff",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {column1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.batch_id}
                          >
                            <StyledTableCell align="left" key={column1[0]}>
                              {row.batch_id}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[1]}>
                              {row.project_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[2]}>
                              {row.division_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[3]}>
                              {!!row?.month &&
                                moment(
                                  `${row.month}-01-${row.year} 00:00:00`
                                ).format("MMMM/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell align="center" key={column1[4]}>
                              {row.emp_count}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[5]}>
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                  color: "#008060",
                                }}
                              >
                                &#8377; {row.total_income}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[6]}>
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                  color: "#b32d00",
                                }}
                              >
                                &#8377; {row.total_deduction}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[7]}>
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                }}
                              >
                                &#8377; {row.total_contribution}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[8]}>
                              <span
                                style={{
                                  fontFamily: "Arial",
                                  fontWeight: "bold",
                                  color: "#008060",
                                }}
                              >
                                &#8377; {row.net_payable}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[10]}>
                              <Link
                                onClick={() =>
                                  navigateTo(
                                    `/employment/manage-profile/${row.created_by}`
                                  )
                                }
                              >
                                {row.created_by}
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[11]}>
                              {!!row.created_on
                                ? moment(row.created_on).format(
                                    "DD/MM/YYYY HH:mm:ss a"
                                  )
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell align="left" key={column1[12]}>
                              {/* <Tooltip title="View">
                              <IconButton
                                onClick={() =>
                                  navigateTo(
                                    `/payroll/batch-detail/${row.batch_id}/3`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip> */}
                              <Tooltip
                                title="Show Approve Status"
                                onClick={() => (
                                  SetpopoverPayload({
                                    employeeId: row.batch_id,
                                    employeeTypeId: 0,
                                    eventType: 8,
                                  }),
                                  SetopenStatus(true)
                                )}
                              >
                                <IconButton>
                                  <ContentPasteSearchIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={styles}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default RejectedBatchView;
interface RejectedBatchViewProps {
  organization_list: OrganizationALlList[];
  employee_type_list: EmployeeTypeList[];
  customer_list: CustomerList[];
  project_list: ProjectList[];
  ProjectSelect?: any;
  project_detail?: ViewProject;
  batch_list: BatchList[];
  Sudmit?: any;
  CancleFilter?: any;
}
const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0060FF",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 12, sm: 11, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
