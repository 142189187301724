import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  AllDepartmentList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import {
  CreateDepartmentAction,
  EditDepartmentAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import AddDepartmentView from "./AddDepartmentView";

function AddDepartment({
  CreateDepartmentAction,
  GetAllOrganizationAction,
  organization_list,
  department_list,
  EditDepartmentAction
}: AddDepartmentProps) {
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const Submit = (data: any) => {
    CreateDepartmentAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData = (data: any) => {
    EditDepartmentAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  // console.log(id)
  React.useEffect(() => {
    GetAllOrganizationAction();
  }, []);
  return (
    <AddDepartmentView
      department={department_list.find((m) => !!id && m.department_id == +id)}
      Submit={Submit}
      organization_list={organization_list}
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
}

const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    department_list: state.organization.department_list,
  };
};
const mapDispatchToProps = {
  CreateDepartmentAction,
  GetAllOrganizationAction,
  EditDepartmentAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartment);

interface AddDepartmentProps {
  CreateDepartmentAction?: any;
  GetAllOrganizationAction?: any;
  organization_list: OrganizationALlList[];
  department_list: AllDepartmentList[];
  EditDepartmentAction?: any
}
