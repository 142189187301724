import { log } from "console";
import { Cookies } from "react-cookie";
import { ApproveMargePayload } from "../../models/employeeModel";
import {
  AddCandidateService,
  CandidateApproveReject,
  CandidateEditService,
  CheckActionStatus,
  GetAllBankListService,
  GetAllBulkUploadCandiidateListService,
  GetAllCandidateService,
  GetAllEmployeeService,
  GetAllEventStausService,
  GetAllRefferalService,
  GetEmployeeAllDetailsService,
  GetEmployeeViewService,
  ReportingManagerGet,
  getBulkUploadCandidateUpdated,
  EmployeeEditService,
  resetpasswordrequestService,
  ReportingManagerEditService,
} from "../../services/employeeService";
import { DomainGetAllService } from "../../services/organizationService";
import { ApiCallErrorAction, BeginApiCallAction } from "./apiStatusActions";
import { LogOutAction } from "./userAction";
import { getBulkUploadCandidate } from "../../services/employeeService";

export enum EmployeeActionTypes {
  Add_Candidate_Success_Action = "[EMPLOYEE] Add Candidate Success Action",
  Get_All_Event_Staus_Success_Action = "[EMPLOYEE] Event Status",
  Get_All_Refferal_Success_Action = "[EMPLOYEE] Get All Refferal Success Action",
  Get_Employee_View_Success_Action = "[EMPLOYEE] Employee View Success Action",
  Get_Reporting_Manager_Success_Action = "[EMPLOYEE] Get Reporting Manager Success Action",
  Candidate_Approve_Success_Action = "[EMPLOYEE] Candidate Approve Success Action",
  Get_All_Candidate_Success_Action = "[EMPLOYEE] Get All Candidate Success Action",
  Get_Attendence_Salary_type_Success_Action = "[EMPLOYEE] Get Attendence Salary type Success Action",
  Get_All_Employee_Success_Action = "[EMPLOYEE] Get All Employee Success Action",
  Get_Action_Status_Success_Action = "[EMPLOYEE] Get Action Status Success Action",
  Get_Employee_All_Details_Success_Action = "[EMPLOYEE] Get Employee All Details Success Action",
  Get_All_Bank_Success_Action = "[EMPLOYEE] Get All Bank Success Action",
  Edit_Candidate_Success_Action = "[EMPLOYEE] Candidate Edit Success Action",
  Candidate_Bulk_Upload_List_Success_Action = "[EMPLOYEE] Candidate Bulk Upload List Success Action",
  GET_BULK_UPLOAD_STATUS_ACTION = "[CUSTOMER] GET BULK UPLOAD STATUS ACTION",
  GET_BULK_UPLOAD_STATUS_ACTION_UPDATED = "[CUSTOMER] GET BULK UPLOAD STATUS ACTION UPDATED",
  Edit_Employee_Dashboard_Success_Action = "[EMPLOYEE] Employee Dashboard Edit Success Action",
  Reporting_Manager_Success_Action = "[EMPLOYEE] Reporting Manager Success Action",
  GET_All_Employee_WITH_PDF = "GET_All_Employee_WITH_PDF",
}

export const CreateCandidateAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Creating New Candidate. Please Wait...",
        type: 2,
      })
    );
    return AddCandidateService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo("/employment/referral-list/");
          payload.enqueueSnackbar("Successfully Created", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(CreateCandidateSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CreateCandidateSuccessAction = () => {
  return { type: EmployeeActionTypes.Add_Candidate_Success_Action };
};

export const GetAllEventStausAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "All Event Status List. Please Wait...",
        type: 2,
      })
    );
    return GetAllEventStausService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetAllEventStausSuccessAction({
              req: response.data.request_data,
              filter: response.data.filter,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllEventStausSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_All_Event_Staus_Success_Action,
    payload: data,
  };
};

export const getAllRefferalAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Refferal. Please Wait...",
        type: 2,
      })
    );
    return GetAllRefferalService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetAllRefferalSuccessAction({
              list: response.data.refferal_list,
              count: response.data.refferal_count,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllRefferalSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_All_Refferal_Success_Action,
    payload: data,
  };
};

export const GetEmployeeViewAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Please Wait...",
        type: 2,
      })
    );
    return GetEmployeeViewService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          // payload.navigateTo("/organization/setup-department");
          // payload.enqueueSnackbar("List Successfully Created", {
          //   variant: "success",
          //   anchorOrigin: {
          //     vertical: "top",
          //     horizontal: "right",
          //   },
          // });
          dispatch(GetEmployeeViewSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetEmployeeViewSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_Employee_View_Success_Action,
    payload: data,
  };
};

export const getReportingManagerList = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Please Wait...",
        type: 2,
      })
    );
    return ReportingManagerGet(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getReportingManagerListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const getReportingManagerListSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_Reporting_Manager_Success_Action,
    payload: data,
  };
};

export const CandidateApproveAction = (payload: ApproveMargePayload) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: " Please Wait...",
        type: 2,
      })
    );
    return CandidateApproveReject(payload.ApproveRejectPayload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CandidateApproveSuccessAction());
          dispatch(GetAllEventStausAction(payload.EventStatusPayload));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CandidateApproveSuccessAction = () => {
  return {
    type: EmployeeActionTypes.Candidate_Approve_Success_Action,
  };
};

export const getAllCandidateAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Candidate. Please Wait...",
        type: 2,
      })
    );
    return GetAllCandidateService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetAllCandidateSuccessAction({
              list: response.data.candidate_list,
              count: response.data.candidate_count,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllCandidateSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_All_Candidate_Success_Action,
    payload: data,
  };
};

export const GetAttendenceSalarytypeAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Salary Type. Please Wait...",
        type: 2,
      })
    );
    return DomainGetAllService({ domain_type: "attendence_salary_type" })
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAttendenceSalarytypeSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAttendenceSalarytypeSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_Attendence_Salary_type_Success_Action,
    payload: data,
  };
};
// export const GetAllEmployeeWithPDFSuccessAction = (data: any) => {
//   return {
//     type: EmployeeActionTypes.GET_All_Employee_WITH_PDF,
//     payload: data,
//   };
// };

export const getAllEmployeeAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Employee. Please Wait...",
        type: 2,
      })
    );
    return GetAllEmployeeService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetAllEmployeeSuccessAction({
              list: response.data.list,
              count: response.data.total_count,
            })
          );
          // if (isPdf) {
          //   dispatch(
          //     GetAllEmployeeWithPDFSuccessAction({
          //       list: response.data.list,
          //       count: response.data.total_count,
          //       pdfList: response.data.pdfList,
          //     })
          //   );
          // } else {
          //   dispatch(
          //     GetAllEmployeeSuccessAction({
          //       list: response.data.list,
          //       count: response.data.total_count,
          //     })
          //   );
          // }
          //console.log("666", response.data.total_count);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllEmployeeSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_All_Employee_Success_Action,
    payload: data,
  };
};

export const getActionStatusAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get Action Status. Please Wait...",
        type: 2,
      })
    );
    return CheckActionStatus(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(getActionStatusSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const getActionStatusSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_Action_Status_Success_Action,
    payload: data,
  };
};

export const getEmployeeAllDetailsAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get Employee All Details. Please Wait...",
        type: 2,
      })
    );
    return GetEmployeeAllDetailsService(payload)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(
            GetEmployeeAllDetailsSuccessAction({
              documents_details: response.data.documents_details,
              employeeDetails: response.data.employeeDetails,
              permanent_address: response.data.permanent_address,
              present_address: response.data.present_address,
              education_details: response.data.education_details,
              experience_details: response.data.experience_details,
              ProjectAssociationWithLevelName:
                response.data.ProjectAssociationWithLevelName,
              designation_name_with_details:
                response.data.designation_name_with_details,
              EmployeeAttendanceReport: response.data.EmployeeAttendanceReport,
              SalaryDetail: response.data.SalaryDetail,
              SalaryHistory: response.data.SalaryHistory,
              professional_details: response.data.professional_details,
              reporting_manager_details: response.data.reporting_manager_details
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetEmployeeAllDetailsSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_Employee_All_Details_Success_Action,
    payload: data,
  };
};

export const GetAllBankListAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Get All Bank. Please Wait...",
        type: 2,
      })
    );
    return GetAllBankListService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(GetAllBankListActionSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const GetAllBankListActionSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Get_All_Bank_Success_Action,
    payload: data,
  };
};

export const EditCandidateAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Candidate. Please Wait...",
        type: 2,
      })
    );
    return CandidateEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.navigateTo(-1);
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditCandidateSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditCandidateSuccessAction = () => {
  return {
    type: EmployeeActionTypes.Edit_Candidate_Success_Action,
  };
};

export const GetAllCandidateBulkUploadListAction = () => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Candidate Bulk Upload List. Please Wait...",
        type: 2,
      })
    );
    return GetAllBulkUploadCandiidateListService()
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch(CandidateBulkUploadListSuccessAction(response.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const CandidateBulkUploadListSuccessAction = (data: any) => {
  return {
    type: EmployeeActionTypes.Candidate_Bulk_Upload_List_Success_Action,
    payload: data,
  };
};

export const getBulkUploadStatusAction =
  () => (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Bulk Upload Status Details Loading. Please Wait...",
        type: 2,
      })
    );
    return getBulkUploadCandidate()
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: EmployeeActionTypes.GET_BULK_UPLOAD_STATUS_ACTION,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const getUpdatedBulkStatusAction =
  (bulkIds: number[]) => (dispatch: any, getState: any) => {
    return getBulkUploadCandidateUpdated(bulkIds)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          dispatch({
            type: EmployeeActionTypes.GET_BULK_UPLOAD_STATUS_ACTION_UPDATED,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };

export const EditEmployeeDashboardAction = (payload: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(
      BeginApiCallAction({
        count: 1,
        message: "Updating Employee Details. Please Wait...",
        type: 2,
      })
    );
    return EmployeeEditService(payload.data)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          payload.enqueueSnackbar("Successfully Updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          dispatch(EditEmployeeDashboardSuccessAction());
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        } else {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Error encountered please try again later",
            })
          );
        }
      });
  };
};

export const EditEmployeeDashboardSuccessAction = () => {
  return {
    type: EmployeeActionTypes.Edit_Employee_Dashboard_Success_Action,
  };
};

export const resetpasswordrequestAction =
  (employee_id: string, enqueueSnackbar: any) =>
  (dispatch: any, getState: any) => {
    resetpasswordrequestService(employee_id)
      .then(async (response) => {
        if (response.status != 200) {
          dispatch(ApiCallErrorAction(response.data));
        } else {
          enqueueSnackbar("Password reset request successfully implemented", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          let cook = new Cookies();
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: "Please Login again to continue.",
            })
          );
          cook.remove("user", { path: "/" });
          dispatch(LogOutAction());
        } else if (error.response.status === 500) {
          dispatch(
            ApiCallErrorAction({
              errorCode: "",
              message: error?.response?.data?.message,
            })
          );
        }
      });
  };

  export const ReportingManagerEditAction = (payload: any) => {
    return (dispatch: any, getState: any) => {
      dispatch(
        BeginApiCallAction({
          count: 1,
          message: "Updating Reporting Manager Details. Please Wait...",
          type: 2,
        })
      );
      return ReportingManagerEditService(payload.data)
        .then(async (response) => {
          if (response.status != 200) {
            dispatch(ApiCallErrorAction(response.data));
          } else {
            payload.enqueueSnackbar("Successfully Updated", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            dispatch(ReportingManagerEditSuccessAction());
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            let cook = new Cookies();
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Please Login again to continue.",
              })
            );
            cook.remove("user", { path: "/" });
            dispatch(LogOutAction());
          } else if (error.response.status === 500) {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: error?.response?.data?.message,
              })
            );
          } else {
            dispatch(
              ApiCallErrorAction({
                errorCode: "",
                message: "Error encountered please try again later",
              })
            );
          }
        });
    };
  };
  export const ReportingManagerEditSuccessAction = () => {
    return {
      type: EmployeeActionTypes.Reporting_Manager_Success_Action,
    };
  };