import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StoreState } from "../../../models/reduxModels";
import { AccessControlMasterList, GetAllRoleMasterList, GetRoleByID } from "../../../models/userManagementModel";
import {
  CretaeRoleAction,
  GetAllAccessControlMasterAction,
  GetAllRoleMasterListAction,
  GetRoleByIDAction,
  UpdateRoleAction,
} from "../../../stores/actions/userManagementAction";
import AddRoleView from "./AddRoleView";

const AddRole = ({
  GetAllAccessControlMasterAction,
  access_control_master,
  CretaeRoleAction,
  GetRoleByIDAction,
  role_get_by_id,
  UpdateRoleAction
}: AddRoleProps) => {
  const { id } = useParams();
  React.useEffect(() => {
    GetAllAccessControlMasterAction();   
  }, []);
  useEffect(()=>{
    if(!!id && +id >0 ){
      GetRoleByIDAction({role_id: +id})
    }
  },[id])
  let navigateTo = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const CreateRole = (data: any) => {
    // console.log("data", data);
    CretaeRoleAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    });
  };
  const SubmitUpdatedData= (data:any)=>{
    // console.log("UPdata", data);    
    UpdateRoleAction({
      data: data,
      navigateTo: navigateTo,
      enqueueSnackbar: enqueueSnackbar,
    })
  }
  return (
    <AddRoleView
      access_control_master={access_control_master}
      CreateRole={CreateRole}
      role_get_by_id={!!id && +id>0 && !!role_get_by_id? role_get_by_id: undefined }
      SubmitUpdatedData={SubmitUpdatedData}
    />
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    access_control_master: state.userManagement.access_control_master,
    role_get_by_id: state.userManagement.role_get_by_id

  };
};
const mapDispatchToProps = {
  GetAllAccessControlMasterAction,
  CretaeRoleAction,
  GetRoleByIDAction,
  UpdateRoleAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
interface AddRoleProps {
  GetAllAccessControlMasterAction?: any;
  access_control_master: AccessControlMasterList[];
  CretaeRoleAction?: any;
  GetRoleByIDAction?: any;
  role_get_by_id?: GetRoleByID;
  UpdateRoleAction?:any
}
