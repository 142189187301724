import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Autocompletes from "../../../library/AutoComplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { EducationDetail1 } from "../../../models/employeeModel";
import { useSnackbar } from "notistack";

function EmployeeEditEducationalDetailsPopover({
  anchorEl,
  setAnchorEl,
  education_details,
  SubmitPopData,
  setaddEduDetail,
  OnChangeEducation,
  DeleteEduAction,
  addEduDetail,
  addEducationalDetails
}: EmployeeEditEducationalDetailsPopoverProps) {
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns1: any[] = [
    "Course Name",
    "Board Name",
    "Subject",
    "Year of Passing",
    "Total Marks",
    "Marks Obtained",
    "Grade",
    "Action",
  ];
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (!!education_details) {
      setaddEduDetail(education_details);
    }
  }, [education_details]);
  const onSubmit = (data: any) => {
    handleClose()
    SubmitPopData(data)
  }
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Grid
          container
          minWidth={500}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Grid
            container
            style={{ backgroundColor: "#0060FF", padding: 5 }}
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <Typography style={{ color: "#ffff" }}>
              {" "}
              Educational Qualification
            </Typography>
          </Grid>
          <Grid style={{ margin: 5, width: "90%" }}>
            <Grid
              item
              marginLeft={9}
              xs={10.5}
              style={{ marginTop: 15, marginBottom: 30 }}
            >
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                //   style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {columns1.map((column) => (
                        <StyledTableCell align="justify" key={column}>
                          {column}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!addEduDetail &&
                      addEduDetail.map((row: any, index: number) => (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <StyledTableCell align="left" key={columns1[0]}>
                            {/* {row.organization_name} */}
                            {/* Emdee */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "course_name",
                                    index
                                  )
                                }
                                value={row.course_name}
                                placeholder={"Course Name"}
                                inputProps={{
                                  maxLength: 50
                                }}
                              // error={!!errors["project_name"]}
                              // helperText={
                              //   errors.project_name &&
                              //   `${errors.project_name.message}* This field is Required`
                              // }
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[1]}>
                            {/* {row.department_name} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "board_name",
                                    index
                                  )
                                }
                                inputProps={{
                                  maxLength: 50
                                }}
                                value={row.board_name}
                                placeholder={"Board Name"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[2]}>
                            {/* {row.department_name} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "subject",
                                    index
                                  )
                                }
                                inputProps={{
                                  maxLength: 50
                                }}
                                value={row.subject}
                                placeholder={"Subject"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[3]}>
                            {/* {row.department_desc} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"month"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "passing_year",
                                    index
                                  )
                                }
                                value={row.passing_year}
                                placeholder={"Passing Year"}
                              // error={!!errors["project_name"]}
                              // helperText={
                              //   errors.project_name &&
                              //   `${errors.project_name.message}* This field is Required`
                              // }
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[4]}>
                            {/* {row.department_type} */}
                            {/* Active */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"number"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "total_marks",
                                    index
                                  )
                                }
                                onInput={(e: any) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 4);
                                }}
                                value={row.total_marks}
                                placeholder={"Total Marks"}
                              // error={!!errors["project_name"]}
                              // helperText={
                              //   errors.project_name &&
                              //   `${errors.project_name.message}* This field is Required`
                              // }
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[5]}>
                            {/* {row.created_at
                              ? moment(row.created_at)
                                  .format("DD/MM/YYYY HH:mm:ss a")
                                  .toUpperCase()
                              : "N/A"} */}
                            {/* 23.12.22 */}
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"number"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "marks_obtained",
                                    index
                                  )
                                }
                                onInput={(e: any) => {
                                  e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 4);
                                }}
                                value={row.marks_obtained}
                                placeholder={"Marks Obtained"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[6]}>
                            <Grid item xs={12} style={{ marginLeft: -5 }}>
                              <TextField
                                type={"text"}
                                id="standard-required"
                                variant="outlined"
                                className={"smaller"}
                                style={{ width: "100%" }}
                                size="small"
                                inputProps={{
                                  maxLength: 20
                                }}
                                onChange={(value) =>
                                  OnChangeEducation(
                                    value.target.value,
                                    "grade",
                                    index
                                  )
                                }
                                value={row.grade}
                                placeholder={"Grade"}
                              />
                            </Grid>
                          </StyledTableCell>
                          <StyledTableCell align="left" key={columns1[7]}>
                            {addEduDetail.length > 0 && (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    color="error"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => DeleteEduAction(index)}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={3}>
                <Button
                  onClick={() => addEducationalDetails()}
                  size="medium"
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            //   marginRight: "2rem",
            marginRight: "0px",
            marginTop: "13px",
            marginBottom: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginRight: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default EmployeeEditEducationalDetailsPopover;

const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3A98B9",
    color: "#ffff",
    fontWeight: 600,
    fontSize: 14,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { md: 14, sm: 12, xs: 8 },
    padding: "5px",
    color: "#363A44",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFC",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface EmployeeEditEducationalDetailsPopoverProps {
  anchorEl?: any;
  setAnchorEl?: any;
  education_details: EducationDetail1[];
  SubmitPopData?: any;
  setaddEduDetail?: any;
  OnChangeEducation?: any;
  DeleteEduAction?: any;
  addEduDetail?: any;
  addEducationalDetails?: any;
}
