import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomerList, ProjectList, ViewProject } from "../../../models/customerModel";
import {
  EmployeeTypeList,
  OrganizationALlList,
} from "../../../models/organizationModels";
import { StoreState } from "../../../models/reduxModels";
import { userDetails } from "../../../models/userModels";
import { AttendanceDetailPayloadSuccessAction } from "../../../stores/actions/attendanceAction";
import {
  GetAllCustomerAction,
  GetAllProjectAction,
  GetProjectViewAction,
} from "../../../stores/actions/customerAction";
import {
  GetAllEmployeeTypeAction,
  GetAllOrganizationAction,
} from "../../../stores/actions/organizationActions";
import ViewAttendanceView from "./ViewAttendanceView";
import AttendanceDownloadView from "./ViewAttendanceView";
import { AddCandidatePayload } from "../../../models/employeeModel";

const ViewAttendance = ({
  organization_list,
  GetAllOrganizationAction,
  GetAllProjectAction,
  project_list,
  GetAllCustomerAction,
  customer_list,
  employee_type_list,
  GetAllEmployeeTypeAction,
  AttendanceDetailPayloadSuccessAction,
  GetProjectViewAction,
  project_detail,
  user,
}: ViewAttendanceProps) => {
  let navigateTo = useNavigate();
  React.useEffect(() => {
    GetAllOrganizationAction();
    GetAllProjectAction({
      organization_id: null,
    });
    GetAllCustomerAction({
      organization_id: null,
    });
    GetAllEmployeeTypeAction({
      organization_id: null,
    });
  }, []);
  const ProjectSelect = (data: number) => {
    if (!!data) {
      GetProjectViewAction(+data);
    }
  };
  const Submit = (data: any) => {
    // console.log(data);
    AttendanceDetailPayloadSuccessAction({
      organization_id: data.organization_id,
      employee_type_id: data.employee_type_id,
      project_id: data.project_id,
      attendence_status: ["UPLOADED", "APPROVED", "PAID"],
      month: data.month,
      // division_id: data.division_id
    });
    navigateTo(`/attendance/attendance-details/0`, { state: data.state });
  };
  console.log(project_detail);
  return (
    <ViewAttendanceView
      organization_list={organization_list}
      project_list={project_list}
      customer_list={customer_list}
      employee_type_list={employee_type_list}
      Submit={Submit}
      project_detail={project_detail}
      ProjectSelect={ProjectSelect}
      user={user}
    />
  );
};


const mapStateToProps = (state: StoreState) => {
  return {
    organization_list: state.organization.organization_filter_list,
    project_list: state.customer.project_list,
    customer_list: state.customer.customer_list,
    employee_type_list: state.organization.employee_type_list,
    project_detail: state.customer.project_detail,
    user: state.user.userDetails,
  };
};

const mapDispatchToProps = {
  GetAllOrganizationAction,
  GetAllProjectAction,
  GetAllCustomerAction,
  GetAllEmployeeTypeAction,
  AttendanceDetailPayloadSuccessAction,
  GetProjectViewAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAttendance);

interface ViewAttendanceProps {
  organization_list: OrganizationALlList[];
  GetAllOrganizationAction?: any;
  GetAllProjectAction?: any;
  GetAllCustomerAction?: any;
  GetAllEmployeeTypeAction?: any;
  project_list: ProjectList[];
  customer_list: CustomerList[];
  employee_type_list: EmployeeTypeList[];
  AttendanceDetailPayloadSuccessAction?: any;
  GetProjectViewAction?: any
  project_detail?: ViewProject;
  user?: userDetails;
}
