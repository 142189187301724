import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { environment } from "../../environment";
interface MobileCalendarProps {
  handleDateClick: any;
  defaultDate: any;
  initialEvents: any[];
}

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const MobileCalendar: FC<MobileCalendarProps> = ({
  handleDateClick,
  defaultDate,
  initialEvents,
}): JSX.Element => {
  const [calendarDates, setCalendarDates] = useState<boolean>(false);

  useEffect(() => {
    setCalendarDates((prev) => !prev);
  }, [defaultDate]);
  const date = new Date(defaultDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayone = new Date(year, month, 1).getDay();
  const lastdate = new Date(year, month + 1, 0).getDate();
  const dayend = new Date(year, month, lastdate).getDay();
  const monthlastdate = new Date(year, month, 0).getDate();
  const PrevDates: any[] = [];
  const CurrDates: any[] = [];
  const nextDates: any[] = [];

  for (let i = dayone; i > 0; i--) {
    PrevDates.push(monthlastdate - i + 1);
  }

  for (let i = 1; i <= lastdate; i++) {
    CurrDates.push(i);
  }

  for (let i = dayend; i < 6; i++) {
    nextDates.push(i - dayend + 1);
  }

  // const handleDateClick = (dates: any) => {
  //
  // };

  const handleCalendarDateClick = (datePoint: number, date: any) => {
    if (
      initialEvents.find(
        (_self) =>
          _self.date ===
          `${year}-${month + 1 <= 9 ? `0${month + 1}` : `${month + 1}`}-${
            datePoint <= 9 ? `0${datePoint}` : datePoint
          }`
      )?.isBlock
    ) {
      console.log("sorry bro");
    } else {
      handleDateClick(date, true);
    }
  };

  return (
    <Box sx={{ height: "auto" }}>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "25px",
          marginLeft: "10px",
        }}
      >
        {moment(date).format("MMMM , YYYY")}
      </Typography>
      <Grid container sx={{ my: 0.5, mx: 0.3, width: "98%" }}>
        {DAYS.map((self, index) => (
          <Grid
            item
            xs={1.71}
            key={index}
            sx={{
              height: "20px",
              border: "1px solid gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: index === 0 ? "#F71A31" : ``,
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {self}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container sx={{ my: 0.5, mx: 0.3, width: "98%" }}>
        {PrevDates.map((self, index) => (
          <Grid
            item
            xs={1.71}
            key={index}
            sx={{ height: "70px", border: "1px solid gray" }}
          >
            <Typography
              sx={{
                color:
                  new Date(year, month - 1, self).getDay() === 0
                    ? `#fca5a5`
                    : "#9ca3af",
                fontWeight: "bold",
                fontSize: "13px",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              {self}
            </Typography>
          </Grid>
        ))}
        {CurrDates.map((self, index) => (
          <Grid
            item
            xs={1.71}
            key={index}
            onClick={() =>
              handleCalendarDateClick(self, new Date(year, month, self))
            }
            sx={{ height: "70px", border: "1px solid gray" }}
          >
            <Typography
              sx={{
                color:
                  new Date(year, month, self).getDay() === 0
                    ? `#dc2626`
                    : new Date(year, month, self).getDay() === 6 &&
                      environment.leave_application.holiday_sat.includes(
                        Math.ceil(new Date(year, month, self).getDate() / 7)
                      )
                    ? "#9ca3af"
                    : "#030712",
                fontWeight: "bold",
                fontSize: "13px",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              {self}
            </Typography>
            {initialEvents.find(
              (_self) =>
                _self.date ===
                `${year}-${month + 1 <= 9 ? `0${month + 1}` : `${month + 1}`}-${
                  self <= 9 ? `0${self}` : self
                }`
            ) ? (
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  padding: "0.4px 3px",
                  color: "#fff",
                  margin: "4px 3px",
                  borderRadius: "3px",
                  overflow: "hidden",
                  backgroundColor:
                    initialEvents.find(
                      (_self) =>
                        _self.date ===
                        `${year}-${
                          month + 1 <= 9 ? `0${month + 1}` : `${month + 1}`
                        }-${self <= 9 ? `0${self}` : self}`
                    )?.color || "#3788D8",
                }}
              >
                {
                  initialEvents.find(
                    (_self) =>
                      _self.date ===
                      `${year}-${
                        month + 1 <= 9 ? `0${month + 1}` : `${month + 1}`
                      }-${self <= 9 ? `0${self}` : self}`
                  )?.title
                }
              </p>
            ) : (
              <></>
            )}
          </Grid>
        ))}
        {nextDates.map((self, index) => (
          <Grid
            item
            xs={1.71}
            key={index}
            sx={{ height: "70px", border: "1px solid gray" }}
          >
            <Typography
              sx={{
                color: "#9ca3af",
                fontWeight: "bold",
                fontSize: "13px",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              {self}
            </Typography>
          </Grid>
        ))}
        {/* {[...new Array(dateNo)].map((self, index) => (
          <Grid
            item
            xs={1.71}
            key={index}
            sx={{ height: "90px", border: "1px solid gray" }}
          >
            <Typography
              sx={{
                color: index === 0 ? "#F71A31" : ``,
                fontWeight: "bold",
                fontSize: "13px",
                marginLeft: "5px",
                marginTop: "5px",
              }}
            >
              {index + 1}
            </Typography>
          </Grid>
        ))} */}
      </Grid>
    </Box>
  );
};

export default MobileCalendar;
