import React, { FC } from "react";
import { ApplicableDesignationDetail } from "../../../interfaces/projectDashboard.interface";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import images from "../../../assets/images.png";
const DesignationList: FC<IDesignationList> = ({ designationList }) => {
  return (
    <>
      {!!designationList &&
        designationList.map((self, index) => (
          <Card
            sx={{
              my: 1,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  src={images}
                  sx={{
                    bgcolor: "#0060FF",
                    border: "3px solid text.secondary",
                  }}
                  aria-label="recipe"
                ></Avatar>
              }
              title={`${self.designation_name}`}
              subheader={`${moment(self.created_on).format("DD-MMM-YYYY")}`}
            />

            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                Created By {self.created_by}
              </Box>
            </CardContent>
          </Card>
        ))}
    </>
  );
};
interface IDesignationList {
  designationList: ApplicableDesignationDetail[];
}
export default DesignationList;
