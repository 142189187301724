import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ILeaveTypeDataDesignationWise } from "../../../models/leaveModel";
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, Dispatch, SetStateAction } from "react";

import ModalUnstyled from "@mui/base/ModalUnstyled";

import {
  Backdrop,
  Box,
  Modal,
  Fade,
  Button,
  Typography,
  Grid,
  TextField,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import Autocompletes from "../../../library/AutoComplete";
import { Controller, useForm } from "react-hook-form";
import { userDetails } from "../../../models/userModels";
import { useSnackbar } from "notistack";
import { ILeaveType } from "../../../models/leaveModel";
import { AllDesignationList } from "../../../models/organizationModels";
import { ILeaveManagement } from "./../../../models/leaveModel";

const AddLeaveTypeModal: FC<IAddLeaveTypeModal> = ({
  leaveTypes,
  designationList,
  leaveDetails,
  expanded,
  handleChange,
  leaveTypeDesignationWise,
  createLeaveManagementDesignationWiseMethod,
  editLeaveManagementRequest,
  editablePayload,
  setExpanded,
  totalLeave,
  selectedDesignation,
  setEditablePayload,
}): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,

    formState: { errors },
  } = useForm();
  const onSubmit = (formData: any) => {
    console.log("formData: ", formData);
    if (!!editablePayload) {
      editLeaveManagementRequest({
        ...formData,
        leave_management_id: editablePayload.leave_management_id,
      });
    } else {
      createLeaveManagementDesignationWiseMethod(formData);
    }
    setExpanded(false);
    reset({ leave_type_id: "", leave_amount: "" });
    setEditablePayload(null);
  };

  console.log("leaveTypes modal : ", leaveTypes);
  console.log("designationList : ", designationList);

  useEffect(() => {
    if (!expanded) {
      reset({ leave_type_id: "", leave_amount: "" });
    }
  }, [expanded]);
  const closeLeaveManageForm = () => {
    reset({ leave_type_id: "", leave_amount: "" });
    setExpanded(false);
  };
  useEffect(() => {
    setValue("designation_id", selectedDesignation);
  }, [selectedDesignation, expanded, editablePayload]);

  useEffect(() => {
    if (!expanded) {
      reset({ leave_type_id: "", leave_amount: "" });
      setEditablePayload(null);
    }
  }, [expanded]);
  useEffect(() => {
    if (!!editablePayload) {
      setValue("leave_type_id", editablePayload.leave_type_id);
      setValue("leave_amount", editablePayload.leave_amount);
    }else{
      setValue("leave_type_id", '');
      setValue("leave_amount", '');
    }
  }, [expanded, editablePayload, leaveTypes]);
  console.log("editablePayload : ", editablePayload);
  console.log("designation_id", selectedDesignation);
  console.log("designation list : ", designationList);

  return (
    <Box>
      <Accordion expanded={expanded === "panel1"}>
        <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            px={2}
            sx={{ color: "text.secondary" }}
          >
            <Typography sx={{ flexShrink: 0 }}>
              Designation :{" "}
              {leaveDetails?.designation_name
                ? leaveDetails.designation_name
                : ""}
            </Typography>
            <Typography>Allowed Leave : {totalLeave}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box width="50%" justifyContent="center" display="flex" mx="auto">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography ml={1}>Designation</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"designation_id"}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        readOnly={true}
                        option={!!designationList ? designationList : []}
                        keys={"designation_id"}
                        label={"designation_name"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder="Designation Name"
                        value={!!value ? value : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography ml={1}>
                    Leave Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"leave_type_id"}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <Autocompletes
                        hasInfo={false}
                        option={!!leaveTypes ? leaveTypes : []}
                        keys={"leave_type_id"}
                        label={"leave_type_name"}
                        style={{ width: "100.9%" }}
                        onBlur={onBlur}
                        onChange={(value: any) => onChange(value)}
                        placeholder="Leave Type Name"
                        value={!!value ? value : ""}
                        errors={!!errors["leave_type_id"]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography ml={1}>
                    Leave Amount <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={"leave_amount"}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field: { onBlur, value, onChange } }) => (
                      <TextField
                        type={"number"}
                        id="standard-required"
                        variant="outlined"
                        className={"smaller"}
                        onBlur={onBlur}
                        style={{ width: "100%" }}
                        size="small"
                        label={
                          <span>
                            leave amount
                            <span style={{ color: "red" }}>*</span>
                          </span>
                        }
                        onChange={onChange}
                        value={value}
                        placeholder={"Leave Amount"}
                        error={!!errors["leave_amount"]}
                        helperText={
                          errors.leave_amount &&
                          `${errors.leave_amount.message}* This field is Required`
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button variant="outlined" onClick={closeLeaveManageForm}>
                    Close
                  </Button>
                  <Button variant="outlined" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
interface ILeaveItem {
  designation_name: string;
  no_of_leave: number;
}
interface IAddLeaveTypeModal {
  leaveTypes: ILeaveType[];
  designationList: AllDesignationList[];
  leaveDetails: ILeaveItem | null;
  editablePayload: {
    leave_type_id: number;
    leave_amount: number;
    leave_management_id: number;
  } | null;
  expanded: string | false;
  handleChange: any;
  createLeaveManagementDesignationWiseMethod: any;
  leaveTypeDesignationWise: ILeaveTypeDataDesignationWise[];
  setExpanded: Dispatch<SetStateAction<string | false>>;
  editLeaveManagementRequest: any;
  setEditablePayload: Dispatch<
    SetStateAction<{
      leave_type_id: number;
      leave_amount: number;
      leave_management_id: number;
    } | null>
  >;
  selectedDesignation: number;
  totalLeave: number;
}

export default AddLeaveTypeModal;
