import { baseUrl, environment } from "../environment";
import {
  AddCandidatePayload,
  ApproveRejectPayload,
  CandidateEditPayload,
  CheckActionStatusPayload,
  EmployeeAllDetailsPayload,
  EmployeeAllPayload,
  EmployeeEditPayload,
  EventStatusPayload,
  ReportingManagerGetPayload,
  ReportingManagerPayload,
  ViewEmployee,
} from "../models/employeeModel";
import RestService from "./rest";

export const serviceClient = new RestService({
  baseURL: baseUrl,
});

export const AddCandidateService = (data: AddCandidatePayload) => {
  return serviceClient.post(environment.urls.AddCandidate, data);
};
export const GetAllEventStausService = (data: EventStatusPayload) => {
  return serviceClient.post(environment.urls.getEventcheckStatus, data);
};
export const GetAllRefferalService = (data: any) => {
  return serviceClient.post(environment.urls.getRefferal, data);
};
export const GetEmployeeViewService = (data: ViewEmployee) => {
  return serviceClient.post(environment.urls.getEmployeeView, data);
};

export const ReportingManagerGet = (data: ReportingManagerGetPayload) => {
  return serviceClient.post(environment.urls.reportingmanagerGetUrl, data);
};
export const CandidateApproveReject = (data: ApproveRejectPayload) => {
  return serviceClient.post(environment.urls.candidateApproveUrl, data);
};

export const GetAllCandidateService = (data: any) => {
  return serviceClient.post(environment.urls.getAllCandidate, data);
};
export const GetAllEmployeeService = (data: EmployeeAllPayload) => {
  return serviceClient.post(environment.urls.getAllEmployee, data);
};
export const CheckActionStatus = (data: CheckActionStatusPayload) => {
  return serviceClient.post(environment.urls.actionStatusUrl, data);
};
export const GetEmployeeAllDetailsService = (data: EmployeeAllDetailsPayload) => {
  return serviceClient.post(environment.urls.getEmployeeAllDetails, data);
};
export const GetAllBankListService = () => {
  return serviceClient.get(environment.urls.getAllBankList);
};
export const CandidateEditService = (data: CandidateEditPayload) => {
  return serviceClient.post(environment.urls.editEmployee, data);
};
export const GetAllBulkUploadCandiidateListService = () => {
  return serviceClient.get(environment.urls.getBulkUploadCandidate);
};

export const getBulkUploadCandidate = () => {
  return serviceClient.get(`${environment.urls.getBulkUploadCandidate}`);
};

export const getBulkUploadCandidateUpdated = (bulkIds: number[]) => {
  return serviceClient.post(`${environment.urls.getFilteredBulkStatus}`, {
    bulkIds,
  });
};

export const EmployeeEditService = (data: EmployeeEditPayload) => {
  return serviceClient.post(environment.urls.editEmployeeDashboard, data);
};


export const resetpasswordrequestService = (employeeId: string) => {
  return serviceClient.post(environment.urls.resetpasswordrequest, {
    employee_id: employeeId,
  });
};
export const ReportingManagerEditService = (data: ReportingManagerPayload) => {
  return serviceClient.post(environment.urls.editReportingManager, data);
};